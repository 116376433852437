export const observationType = {
  // POST add Observation
  POST_OBSERVATION_PENDING: "[OBSERVATION][REQUEST] Create observation",
  POST_OBSERVATION_FAILURE:
    "[OBSERVATION][RESPONSE] observation Creation Failed",
  POST_OBSERVATION_SUCCESS:
    "[OBSERVATION][RESPONSE] observation Created Successfull",

  // GET observation details
  GET_OBSERVATION_DETAILS_PENDING:
    "[OBSERVATION_DETAILS][REQUEST] ObservationDetailPage",
  GET_OBSERVATION_DETAILS_SUCCESS:
    "[OBSERVATION_DETAILS][RESPONSE] Observation Details Page Successfull",
  GET_OBSERVATION_DETAILS_FAILURE:
    "[OBSERVATION_DETAILS][RESPONSE]Observation Details Page Failure",

  // GET Open Items list
  GET_LIST_OPEN_ITEM_PENDING:
    "[OPEN_ITEM_LIST][REQUEST] OpenItemList UpdatePage",
  GET_LIST_OPEN_ITEM_SUCCESS:
    "[OPEN_ITEM_LIST][RESPONSE] OpenItemList Update Page Successfull",
  GET_LIST_OPEN_ITEM_FAILURE:
    "[OPEN_ITEM_LIST][RESPONSE] OpenItemList Update Page Failure",

  // POST update observation
  POST_UPDATE_OBSERVATION_PENDING:
    "[UPDATE_OBSERVATION][REQUEST] ObservationUpdatePage",
  POST_UPDATE_OBSERVATION_SUCCESS:
    "[UPDATE_OBSERVATION][RESPONSE] Observation Update Page Successfull",
  POST_UPDATE_OBSERVATION_FAILURE:
    "[UPDATE_OBSERVATION][RESPONSE]Observation Update Page Failure",

  // GEt observation List
  GET_LIST_OF_OBSERVATION_PENDING:
    "[LIST_OF_OBSERVATION][REQUEST] List of Observation UpdatePage",
  GET_LIST_OF_OBSERVATION_SUCCESS:
    "[LIST_OF_OBSERVATION][RESPONSE] List of Observation Update Page Successfull",
  GET_LIST_OF_OBSERVATION_EMAIL_SUCCESS:
    "[GET_LIST_OF_OBSERVATION_EMAIL_SUCCESS][RESPONSE] Send List of Observation Update Page Successfull",
  GET_LIST_OF_OBSERVATION_FAILURE:
    "[LIST_OF_OBSERVATION][RESPONSE] List of Observation Update Page Failure",
};
