import React, { useState, useEffect } from "react";
import "../../../credential/component/viewCredential.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import {
  COUNTRY_CODE,
  LANGUAGE,
  ORG_ID,
  USER_ID,
  ISwitch,
  PRODUCT_LIST,
  CLIENT_TEMPLATE_URL,
  PRODUCT_TYPE_CONSTANT,
} from "../../../../constant/constant";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import _ from "lodash";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
export default function AddClient({
  dataBack,
  editMode,
  state,
  city,
  getCity,
  addClients,
  getClientDetsilsData,
  industryType,
  Organisationid,
}) {
  const history = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isStatusId, setIsStatusId] = useState(0);
  const [fullName, setFullName] = useState("");
  const [shortName, setShortName] = useState("");
  const [indusrtyType, setIndustryType] = useState("");
  const [cities, setCities] = useState(null);
  const [product, setProduct] = useState([]);
  const [states, setStates] = useState("");
  const [noOfEmployee, setEmployee] = useState(null);
  const [language, setLanguage] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [emailId, setEmailId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [pointOfContactName, setPointOfContactName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [indusrtyTypeError, setIndustryTypeError] = useState(false);
  const [statesError, setStatesError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [productError, setProductError] = useState(false);
  const [address1Error, setaddress1Error] = useState(false);
  const [photos, setPhotos] = useState("");
  const [mediaAllowedInspection, setMediaAllowedInspection] = useState("0");
  const [emailverify, setEmailverify] = useState(false);
  const [phoneverify, setPhoneverify] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [platformTypes, setPlatformTypes] = useState({
    internal: false,
    external: false,
  });
  const [combinePdfReport, setCombinePdfReport] = useState("3");
  const [platformTypesError, setPlatformTypesError] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setIsStatusId(1);
  };
  const [selectedd, setselectedd] = useState([]);
  useEffect(() => {
    setEmployee(null);
    if (getClientDetsilsData?.data && editMode) {
      getCity(getClientDetsilsData?.data?.result?.stateId);
      if (getClientDetsilsData?.data?.result?.statusId === 1) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      {
        setFullName(getClientDetsilsData?.data?.result?.fullName);
        setShortName(getClientDetsilsData?.data?.result?.shortName);
        setIndustryType(getClientDetsilsData?.data?.result?.industryTypeId);
        setCities(getClientDetsilsData?.data?.result?.cityId);
        setProduct(
          getClientDetsilsData?.data?.result?.product?.map((item) => item.value)
        );
        setselectedd(
          getClientDetsilsData?.data?.result?.product?.map((item) => item.value)
        );
        setStates(getClientDetsilsData?.data?.result?.stateId);
        setLanguage(getClientDetsilsData?.data?.result?.preferredLanguageId);
        setWebsite(getClientDetsilsData?.data?.result?.webSite);
        setEmployee(getClientDetsilsData?.data?.result?.noOfEmployees);

        const phone = getClientDetsilsData?.data?.result?.phoneNumber?.trim();
        setPhone(
          phone && phone > 10
            ? phone.substring(phone.length - 10, phone.length)
            : phone
        );

        const zipCode = getClientDetsilsData?.data?.result?.zipCode;
        setZipCode(
          zipCode && zipCode > 10
            ? zipCode.substring(zipCode.length - 5, zipCode.length)
            : zipCode
        );
        setEmailId(getClientDetsilsData?.data?.result?.email?.trim());
        getCity(getClientDetsilsData?.data?.result?.stateId);
        setAddress1(getClientDetsilsData?.data?.result?.address1);
        setAddress2(getClientDetsilsData?.data?.result?.address2);
        setPointOfContactName(
          getClientDetsilsData?.data?.result?.pointOfContact
        );
        setIsStatusId(getClientDetsilsData?.data?.result?.statusId);
        setPhotos(getClientDetsilsData?.data?.result?.logoUrl);
        setMediaAllowedInspection(
          getClientDetsilsData?.data?.result?.isMediaAllowed == true ? 1 : 0
        );

        if (getClientDetsilsData?.data?.result?.platform?.includes(1)) {
          setPlatformTypes((prevState) => ({ ...prevState, internal: true }));
        } else {
          setPlatformTypes((prevState) => ({ ...prevState, internal: false }));
        }
        if (getClientDetsilsData?.data?.result?.platform?.includes(2)) {
          setPlatformTypes((prevState) => ({ ...prevState, external: true }));
        } else {
          setPlatformTypes((prevState) => ({ ...prevState, external: false }));
        }
      }
      setCombinePdfReport(
        getClientDetsilsData?.data?.result?.isInsCombinedPdf === true
          ? "1"
          : "3"
      );
    }
  }, [getClientDetsilsData?.data, editMode]);

  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }
  const addClient = () => {
    const selectedproducts = findValueMultipleSelect(PRODUCT_LIST, product);

    let isFieldEmpty = false;
    if (fullName.trim() === "") {
      setFullName("");
      setFullNameError(true);
      isFieldEmpty = true;
    }
    if (!indusrtyType) {
      setIndustryTypeError(true);
      isFieldEmpty = true;
    }
    if (!states) {
      setStatesError(true);
      isFieldEmpty = true;
    }
    if (!language) {
      setLanguageError(true);
      isFieldEmpty = true;
    }
    if (phone) {
      if (phone?.length != 10) {
        setPhoneverify(true);
        isFieldEmpty = true;
      }
    }
    if (emailverify == true) {
      isFieldEmpty = true;
    }
    if (address1.trim() === "") {
      setAddress1("");
      setaddress1Error(true);
      isFieldEmpty = true;
    }

    if (selectedproducts.length == 0) {
      setProductError(true);
      isFieldEmpty = true;
    }
    if (product?.includes("Storm water") || product?.includes("Stormwater")) {
      if (!platformTypes?.internal && !platformTypes?.external) {
        setPlatformTypesError(true);
        isFieldEmpty = true;
      }
    }
    if (!isFieldEmpty) {
      const sendData = {
        organisationId: Organisationid,
        fullName: fullName?.trim(),
        shortName: shortName?.trim(),
        organisationTypeId: ORG_ID(),
        industryTypeId: indusrtyType,
        stateId: states,
        cityId: cities,
        productIds: selectedproducts,
        noOfEmployees: noOfEmployee === "" ? null : noOfEmployee,
        webSite: website?.trim(),
        createdBy: USER_ID(),
        phoneNumber: phone,
        email: emailId,
        address1: address1?.trim(),
        address2: address2?.trim(),
        pointOfContact: pointOfContactName?.trim(),
        zipCode: zipCode,
        isdCode: COUNTRY_CODE,
        userId: USER_ID(),
        logoUrl: photos,
        isMediaAllowed: mediaAllowedInspection == 1 ? true : false,
        platform: [
          platformTypes.internal ? 1 : 0,
          platformTypes.external ? 2 : 0,
        ],
        isInsCombinedPdf: combinePdfReport === "1" ? true : false,
      };
      const sendData1 = {
        organisationId: Organisationid,
        fullName: fullName?.trim(),
        shortName: shortName?.trim(),
        organisationTypeId: ORG_ID(),
        businessTypeId: indusrtyType,
        stateId: states,
        cityId: cities,
        noOfEmployees: noOfEmployee === "" ? null : noOfEmployee,
        preferredLanguageId: language,
        webSite: website?.trim(),
        createdBy: USER_ID(),
        phoneNumber: phone,
        email: emailId,
        address1: address1?.trim(),
        address2: address2?.trim(),
        pointOfContact: pointOfContactName?.trim(),
        zipCode: zipCode,
        isActive: checked,
        statusId: isStatusId,
        isdCode: COUNTRY_CODE,
        logoUrl: photos,
        productIds: selectedproducts,
        isMediaAllowed: mediaAllowedInspection == 1 ? true : false,
        platform: [
          platformTypes.internal ? 1 : 0,
          platformTypes.external ? 2 : 0,
        ],
        isInsCombinedPdf: combinePdfReport === "1" ? true : false,
      };

      addClients(editMode ? sendData1 : [sendData]);
    }
  };

  const onChangeCompanyFullName = (event) => {
    setFullName(event.target.value);
  };
  const onChangeShortName = (event) => {
    setShortName(event.target.value);
  };

  const onChangeIndustryType = (event) => {
    setIndustryType(event.target.value);
  };

  const onChangeCities = (e) => {
    setCities(e.target.value);
  };

  const onChangeProduct = (event) => {
    setProductError(false);
    const {
      target: { value },
    } = event;
    setProduct(typeof value === "string" ? value.split(",") : value);
  };

  const onChangeState = (e) => {
    const stateId = e.target.value;
    getCity(stateId);
    setStates(e.target.value);
  };
  const onChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const onChangeWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const onChangeEmailIdName = (event) => {
    setEmailId(event.target.value);
    const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (event.target?.value && event.target.value.match(isValidEmail)) {
      setEmailverify(false);
    } else {
      setEmailverify(true);
    }
    setEmailId(event.target.value);
    if (event.target.value == "") {
      setEmailverify(false);
    }
  };

  const onChangePointContactName = (event) => {
    setPointOfContactName(event.target.value);
  };
  const onChangeAdress1 = (event) => {
    setAddress1(event.target.value);
  };
  const onChangeAdress2 = (event) => {
    setAddress2(event.target.value);
  };

  const handleDeleteImageURL = (urls) => {
    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
  };

  const handleFileChange = (photos) => {
    setPhotos(photos);
  };

  const handleMediaAllwedCheckbox = (e, value) => {
    setMediaAllowedInspection(e.target.value);
  };

  const handleCombinePdfReport = (e, value) => {
    setCombinePdfReport(e.target.value);
  };

  const platformTypeHandler = (event) => {
    setPlatformTypes({
      ...platformTypes,
      [event.target.name]: event.target.checked,
    });
    setPlatformTypesError(false);
  };

  useEffect(() => {
    if (!phone) {
      setPhoneverify(false);
    }
  }, [phone]);

  const handleFileChanges = (data) => {
    const clientData = data.map((x, index) => {
      x.fullName = x?.fullName?.trim();
      x.shortName = x?.shortName?.trim();
      x.pointOfContact = x?.pointOfContact?.trim();
      x.industryTypeId = parseInt(x.industryTypeId);
      x.noOfEmployees =
        x?.noOfEmployees === undefined ? null : x?.noOfEmployees?.trim();
      x.preferredLanguageId = parseInt(x.preferredLanguageId);
      x.address1 = x?.address1?.trim();
      x.address2 = x?.address2?.trim();
      x.stateId = parseInt(x.stateId);
      x.cityId = parseInt(x.cityId);
      x.zipCode = x?.zipCode?.trim();
      x.phoneNumber = x?.phoneNumber?.trim();
      x.email = x?.email?.trim();
      x.webSite = x?.webSite?.trim();
      x.logoUrl = x?.logoUrl?.trim();
      x.productIds = x?.productIds?.split(",");
      x.isMediaAllowed = _.isEmpty(x.isMediaAllowed)
        ? false
        : x.isMediaAllowed.toUpperCase() === "YES"
        ? true
        : false;
      x.platform = x?.platform?.split(",");
      x.isInsCombinedPdf = _.isEmpty(x.isInsCombinedPdf)
        ? true
        : x.isInsCombinedPdf.toUpperCase() === "YES"
        ? true
        : false;
      delete x.id;
      return x;
    });
    addClients(clientData);
  };

  const mapBulkError = (data) => {
    let clientWithError = [];
    data.forEach((x) => {
      if (
        x.fullName?.trim() === undefined ||
        null ||
        "" ||
        x.industryTypeId?.trim() === undefined ||
        null ||
        "" ||
        x.preferredLanguageId?.trim() === undefined ||
        null ||
        "" ||
        x.address1?.trim() === undefined ||
        null ||
        "" ||
        x.stateId?.trim() === undefined ||
        null ||
        "" ||
        x.productIds?.split(",") === undefined ||
        null ||
        "" ||
        (x.productIds?.split(",").includes("2") &&
          x.platform?.split(",") === undefined) ||
        null ||
        ""
      ) {
        clientWithError.push(x);
        return;
      }
    });
    return clientWithError;
  };

  return (
    <>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>
      <Grid container mt={4}>
        <Grid item lg={9} xs={12} sm={12} md={9}>
          <Grid container>
            <Grid item lg={6} xs={12} sm={12} md={4} pl={1} textAlign="left">
              <div className="heading_style">
                {editMode
                  ? "Edit Individual Client"
                  : isBulkUpload
                  ? "Add Multi Client"
                  : "Add Individual Client"}
              </div>
            </Grid>
            {!editMode ? (
              <Grid item lg={6} xs={12} sm={12} md={6} textAlign="right">
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={isBulkUpload ? "Single Client" : "Bulk Upload"}
                  varientContained={true}
                  onClick={() => setIsBulkUpload(!isBulkUpload)}
                ></Buttons>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div>
            {isBulkUpload ? (
              <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
                <BulkUpload
                  handleFileChanges={handleFileChanges}
                  mapBulkError={mapBulkError}
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop the files here, "}
                  paraText3={"or Browse"}
                  templateUrl={CLIENT_TEMPLATE_URL}
                  templateText="Download Template"
                  pocFirstNameKey="fullName"
                  pocLastNameKey="shortName"
                  pocPhoneKey="pointOfContact"
                  pocFirstNamelabel="Client Name"
                  pocLastNamelabel="Client Short Name"
                  pocPhonelabel="Point of Contact"
                />
              </Grid>
            ) : (
              <Grid container item md={9} xs={9} className="addShadowBox">
                <Grid item container spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputField
                        isRequired={true}
                        type="text"
                        label="Client Name"
                        placeholder={"Add Full Client Name Here..."}
                        value={fullName}
                        onChange={(e) => onChangeCompanyFullName(e)}
                      />
                    </FormControl>
                    {fullNameError && !fullName && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputField
                        type="text"
                        label="Client Short Name"
                        placeholder={"Add Client Short Name Here..."}
                        value={shortName}
                        onChange={(e) => onChangeShortName(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputField
                        isRequired={false}
                        type="text"
                        label="Point of Contact Name"
                        placeholder={"Add Point of Contact Name Here..."}
                        value={pointOfContactName}
                        onChange={(e) => onChangePointContactName(e)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="Industry Type" isRequired={true} />
                      <SelectMenu
                        listData={industryType}
                        value={indusrtyType}
                        onchangehandler={(e) => onChangeIndustryType(e)}
                        placeholder="Please Select"
                      />
                    </FormControl>
                    {indusrtyTypeError && !indusrtyType && (
                      <div className="errorMsg">
                        Selection is required for this field
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <NumberField
                      disabled={false}
                      isRequired={false}
                      type="text"
                      label={"Number of Employees"}
                      maxLength="10"
                      value={noOfEmployee}
                      placeholder={"Add Number of Employees Here..."}
                      onChange={(value) =>
                        value.length <= 10 ? setEmployee(value) : noOfEmployee
                      }
                    />
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels
                        label="Preferred Language"
                        isRequired={true}
                      />
                      <SelectMenu
                        listData={LANGUAGE}
                        value={language}
                        onchangehandler={(e) => onChangeLanguage(e)}
                        placeholder="Please Select"
                      />
                    </FormControl>
                    {languageError && !language && (
                      <div className="errorMsg">
                        Selection is required for this field
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={12} xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <InputField
                        isRequired={true}
                        type="text"
                        label="Address Line 1"
                        placeholder={"Add Address Line 1 Here..."}
                        value={address1}
                        onChange={(e) => onChangeAdress1(e)}
                      />
                    </FormControl>
                    {address1Error && !address1 && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={12} xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <InputField
                        isRequired={false}
                        type="text"
                        label="Address Line 2"
                        placeholder={"Add Address Line 2 Here..."}
                        value={address2}
                        onChange={(e) => onChangeAdress2(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="State" isRequired={true} />
                      <SelectMenu
                        listData={state}
                        value={states}
                        onchangehandler={(e) => onChangeState(e)}
                        placeholder="Please Select"
                      />
                    </FormControl>
                    {statesError && !states && (
                      <div className="errorMsg">
                        Selection is required for this field
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="City" isRequired={false} />
                      <SelectMenu
                        listData={city}
                        value={cities}
                        onchangehandler={(e) => onChangeCities(e)}
                        placeholder="Please Select"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth={true}>
                      <NumberField
                        disabled={false}
                        isRequired={false}
                        type="text"
                        label={"Zip Code"}
                        maxLength="5"
                        value={zipCode}
                        placeholder={"Add Zip Code Here..."}
                        onChange={(value) =>
                          value.length <= 5 ? setZipCode(value) : zipCode
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth={true}>
                      <NumberField
                        // disabled={editMode}
                        isRequired={false}
                        type="text"
                        label={"Point of Contact Phone Number"}
                        maxLength="10"
                        value={phone}
                        placeholder={`Add Phone number without ${COUNTRY_CODE}`}
                        onChange={(value) =>
                          value.length <= 10 ? setPhone(value) : phone
                        }
                      />
                    </FormControl>
                    {phoneverify && phone?.length != 10 && (
                      <div className="errorMsg">
                        Please enter 10 digit valid Phone Number
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputField
                        isRequired={false}
                        type="text"
                        label="Point of Contact Email"
                        placeholder={"Add Point of Contact Email Here..."}
                        value={emailId}
                        onChange={(e) => onChangeEmailIdName(e)}
                      />
                    </FormControl>
                    {emailverify && (
                      <div className="errorMsg">
                        Please enter a valid Email address
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <InputField
                      isRequired={false}
                      placeholder={"Add Website Here..."}
                      type="text"
                      label="Website"
                      value={website}
                      onChange={(e) => onChangeWebsite(e)}
                    />
                  </Grid>
                </Grid>
                <Grid item container mt={2} spacing={2}>
                  <Grid item lg={12} xs={12} sm={12} md={8}>
                    <FormLabels label={"Add Client Logo"} />
                    <>
                      <FormDropZone
                        paraText1={"Drop the files here ..."}
                        paraText2={"Drop Images here, "}
                        paraText3={"or Browse"}
                        handleFileChange={handleFileChange}
                        deleteImageURL={(url) => handleDeleteImageURL(url)}
                        isDynamics={true}
                        isRequired={true}
                        fileType={10}
                        isMuliple={false}
                        imageUrl={photos}
                        id="addPhoto"
                        key="addPhoto"
                        isDoc={false}
                        isImage={true}
                        allFiles={false}
                      />
                      <p class="fileTypeSupport">
                        Supported file types: images{" "}
                      </p>
                    </>
                  </Grid>
                </Grid>

                <Grid item container mt={2} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="Products" isRequired={true} />

                      <MultiSelectBox
                        placeholder={"Please Select"}
                        listData={PRODUCT_LIST}
                        onChange={(e) => onChangeProduct(e)}
                        value={product || []}
                        editMode={editMode}
                        addclientpage={true}
                        disabledValue={selectedd}
                      />
                    </FormControl>
                    {productError && (
                      <div className="errorMsg">
                        Selection is required for this field
                      </div>
                    )}
                  </Grid>
                </Grid>
                {product?.find((x) => x?.toUpperCase() === "STORMWATER") && (
                  <>
                    <Grid item container mt={2} spacing={3}>
                      <Grid item lg={6} xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabels label="Media Allowed For Stormwater Inspections" />
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="radioGroup"
                          >
                            <RadioButton
                              checked={
                                mediaAllowedInspection == "1" ? true : false
                              }
                              value="1"
                              label="Yes"
                              onChange={(e, value) =>
                                handleMediaAllwedCheckbox(e, value)
                              }
                            ></RadioButton>

                            <RadioButton
                              checked={
                                mediaAllowedInspection == "0" ? true : false
                              }
                              value="0"
                              label="No"
                              onChange={(e, value) =>
                                handleMediaAllwedCheckbox(e, value)
                              }
                            ></RadioButton>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={4}>
                        {product?.length > 0 &&
                          (product?.includes("Storm water") ||
                            product?.includes("Stormwater")) && (
                            <FormControl component="fieldset">
                              <FormLabels
                                label="Stormwater Inspection Platform"
                                isRequired={true}
                              />
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={platformTypes.internal}
                                      onChange={platformTypeHandler}
                                      name="internal"
                                    />
                                  }
                                  label="Internal"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={platformTypes.external}
                                      onChange={platformTypeHandler}
                                      name="external"
                                    />
                                  }
                                  label="External"
                                />
                              </FormGroup>
                              {platformTypesError && (
                                <p className="error">
                                  Please select stormwater inspection platform
                                </p>
                              )}
                            </FormControl>
                          )}
                      </Grid>
                    </Grid>
                    <Grid item container mt={2} spacing={3}>
                      <Grid item lg={6} xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabels label="Stormwater PDF Email Notification" />
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="radioGroup"
                          >
                            <RadioButton
                              checked={combinePdfReport === "3"}
                              value="3"
                              label="3 Reports"
                              onChange={(e, value) =>
                                handleCombinePdfReport(e, value)
                              }
                            ></RadioButton>

                            <RadioButton
                              checked={combinePdfReport === "1"}
                              value="1"
                              label="1 Report"
                              onChange={(e, value) =>
                                handleCombinePdfReport(e, value)
                              }
                            ></RadioButton>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid container mt={3} spacing={1}>
                  <Grid lg={5} xs={12} sm={6} md={4}>
                    {checkPrivileges([12, 67]) && editMode && (
                      <FormControl
                        component="fieldset"
                        variant="standard"
                        className="clientswitch"
                      >
                        <FormLabel className="clientStatus" component="status">
                          Select Client Status
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <ISwitch
                                checked={checked}
                                onChange={handleChange}
                                name="status"
                              />
                            }
                            label={checked ? "Active" : "InActive"}
                          />
                        </FormGroup>
                      </FormControl>
                    )}
                  </Grid>

                  <Grid lg={3} xs={12} sm={4} md={4}>
                    <Buttons
                      varientText={true}
                      label="Cancel"
                      onClick={() => history(-1)}
                    />
                  </Grid>

                  <Grid lg={4} xs={12} sm={4} md={4}>
                    <Buttons
                      varientContained={true}
                      label={editMode ? "Update" : "Save"}
                      onClick={() => addClient()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
