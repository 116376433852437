import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import * as lmsUserAction from "../../../../redux/actions/lmsUserAction";
import * as toolboxAction from "../../../../redux/actions/toolBoxAction";
import * as lmsReportAction from "../../../../redux/actions/lmsReportAction";
import { useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import Items from "../component/items";
import { ORG_ID, PAGE_SIZE_100, USER_ID } from "../../../../constant/constant";
const ItemsContainerLMS = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const page = location?.state?.page;
  const apiData = location?.state?.apiData;

  useEffect(() => {
    handleClear();
  }, []);

  const getLmsDashboardReportDetails = useSelector(
    (state) => state.getLmsDashboardReportDetails
  );
  const getAssignLessonUserListData = useSelector(
    (state) => state?.getAssignLessonUserList
  );
  const coursesUserAssignListData = useSelector(
    (state) => state?.getAssignCourseUserList
  );
  const coursesUserListData = useSelector((state) => state.getUserCoursesList);

  const lessionUserListData = useSelector((state) => state.getUserLessonsList);

  const getToolBoxTalkListResult = useSelector(
    (state) => state.getToolBoxTalkList
  );

  const getScheduleAssignUserStatusData = useSelector(
    (state) => state.getAssignTookBoxUser
  );

  function getMoretDetails(data, tab) {
    page === "UsersThatHaveCompleteLesson"
      ? dispatch(lmsAction.getAssignedLessonList(data))
      : page === "TrainingWithLowestParticipation" && tab === "lesson"
      ? dispatch(lmsAction.getAssignedLessonList(data))
      : page === "TrainingWithLowestParticipation" && tab === "course"
      ? dispatch(lmsAction.getAssignedList(data))
      : page === "TrainingWithLowestParticipation" && tab === "safety"
      ? dispatch(toolboxAction.scheduleUserCountStatusList(data))
      : page === "UsersWithHighestCourseCompletion"
      ? dispatch(lmsAction.getAssignedList(data))
      : (page === "UserTrainings" || page === "UserTrainingsIncomplete") &&
        tab === "course"
      ? dispatch(lmsUserAction.getUserCoursesList(data))
      : (page === "UserTrainings" || page === "UserTrainingsIncomplete") &&
        tab === "lesson"
      ? dispatch(lmsUserAction.getUserLessonsList(data))
      : (page === "UserTrainings" || page === "UserTrainingsIncomplete") &&
        tab === "safety"
      ? dispatch(toolboxAction.getToolBoxTalkList(data))
      : page === "SubcontractorCompletedTraingings" ||
        page === "UsersWithMostSafetyMeetings"
      ? dispatch(toolboxAction.getToolBoxTalkList(data))
      : console.log("");
  }
  useEffect(() => {
    console.log(apiData)
    page === "UsersThatHaveCompleteLesson"
      ? getMoretDetails({
          pageNumber: 1,
          pageSize: 100,
          lessonId: apiData?.lessonId,
          isCompleted: true,
        })
      : page === "UsersWithHighestCourseCompletion"
      ? getMoretDetails({
          pageNumber: 1,
          pageSize: 100,
          courseId: apiData?.courseId,
          isCompleted: true,
        })
      : page === "UserTrainingsIncomplete"
      ? getMoretDetails(
          {
            pageNumber: 1,
            pageSize: PAGE_SIZE_100,
            search: "",
            catIds: "",
            statusId: 2,
            userId: apiData?.userId,
            orgId: ORG_ID(),
            fromDate: apiData.startDate,
            toDate: apiData.endDate,
            divisionId:apiData?.divisionId,
          },
          "course"
        )
      : page === "UserTrainings"
      ? getMoretDetails(
          {
            pageNumber: 1,
            pageSize: PAGE_SIZE_100,
            search: "",
            catIds: "",
            statusId: 1,
            userId: apiData?.userId,
            orgId: ORG_ID(),
            fromDate: apiData.startDate,
            toDate: apiData.endDate,
            divisionId:apiData?.divisionId,
          },
          "course"
        )
      : page === "SubcontractorCompletedTraingings" ||
        page === "UsersWithMostSafetyMeetings"
      ? getMoretDetails({
          pageNumber: 1,
          pageSize: PAGE_SIZE_100,
          search: "",
          catIds: "",
          userId: apiData?.userId,
          status: "",
          tabId: 1,
        })
      : page === "TrainingWithLowestParticipation" &&
        location?.state?.type === "Course"
      ? getMoretDetails(
          {
            pageNumber: 1,
            pageSize: 100,
            courseId: apiData?.courseId,
            isCompleted: false,
          },
          "course"
        )
      : page === "TrainingWithLowestParticipation" &&
        location?.state?.type === "Lesson"
      ? getMoretDetails(
          {
            pageNumber: 1,
            pageSize: 100,
            lessonId: apiData?.lessonId,
            isCompleted: false,
          },
          "lesson"
        )
      : page === "TrainingWithLowestParticipation" &&
        location?.state?.type === "Safety Meeting"
      ? getMoretDetails(
          {
            scheduleId: apiData?.scheduleId,
            flag: 2,
            scheduleDate: "09-25-2024",
            userId: USER_ID(),
          },
          "safety"
        )
      : console.log();
  }, []);
  const userTRainingLoadin =
    coursesUserListData.isLoading ||
    lessionUserListData.isLoading ||
    getToolBoxTalkListResult.isLoading;

  function handleClear() {
    dispatch(lmsUserAction.clearCourseList());
    dispatch(lmsUserAction.clearLesssonList());
    dispatch(lmsAction.getAssignedLessonListClear());
    dispatch(lmsAction.getAssignedListClear());
    dispatch(toolboxAction.scheduleUserCountStatusClear());
    dispatch(lmsReportAction.clearDashboardDetails());
  }
  return (
    <div>
      {(getLmsDashboardReportDetails.isLoading ||
        getAssignLessonUserListData?.isLoading ||
        coursesUserAssignListData?.isLoading ||
        getScheduleAssignUserStatusData.isLoading) && <Loader />}
      <Items
        page={page}
        getLmsDashboardReportDetails={getLmsDashboardReportDetails}
        getMoretDetails={getMoretDetails}
        apiData={apiData}
        lessonUserAssignListData={getAssignLessonUserListData?.dataresult}
        coursesUserAssignListData={coursesUserAssignListData?.dataresult}
        coursesUserListData={coursesUserListData?.data}
        lessionUserListData={lessionUserListData?.data}
        getToolBoxTalkListResult={getToolBoxTalkListResult?.data}
        getScheduleAssignUserStatusData={
          getScheduleAssignUserStatusData?.data?.users
        }
        location={location}
        userTRainingLoadin={userTRainingLoadin}
        handleClear={handleClear}
      />
    </div>
  );
};

export default ItemsContainerLMS;
