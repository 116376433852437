import React, { useState } from "react";
import AddContactProject from "../components/addContactProject";
import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ORG_ID } from "../../../../constant/constant";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import Loader from "../../../../library/common/Loader";
import { ToastContainer } from "react-toastify";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../../constant/routeContant";
function AddContactProjectContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const stateId = location?.state?.stateId ?? location?.state?.projectid;
  const customerId = location?.state?.customerId;
  const customer = location?.state?.customerName;
  const [clearAddContact, setClearAddContact] = useState(false);
  const [clearUpdateContact, setClearUpdateContact] = useState(false);
  const dispatch = useDispatch();

  const getProjectContactsListAction = (data) => {
    if (data?.projectId) {
      dispatch(projectAction.getContactInProjectList(data));
    }
  };

  const getProjectContactsList = useSelector(
    (state) => state.getContactInProjectList
  );

  const editContactInProject = useSelector(
    (state) => state.editOneContactInProject
  );
  const isLoading = useSelector(
    (state) =>
      state.getContactInProjectList.isLoading ||
      state.deleteContactInProject.isLoading ||
      state.addContactInProject.isLoading
  );
  const showLoader = useSelector(
    (state) => state.updateProjectContactStatus.isLoading
  );
  const addContactInProject = useSelector((state) => state.addContactInProject);
  const addContactInProjectAction = (data) => {
    dispatch(projectAction.postAddContactInProject(data));
    setClearAddContact(true);
  };
  const selectedServiceTypeData = localStorage?.getItem("serviceType");
  const editOneContactProject = (data) => {
    dispatch(projectAction.editOneContactInProject(data));
  };
  const updateContactInProject = useSelector(
    (state) => state.updateProjectContactStatus
  );
  const handleUpdateContactInProject = (data) => {
    dispatch(projectAction.updateProjectContactStaus(data));
    setClearUpdateContact(true);
  };

  function handleAllContactSearch(event) {
    const data = {
      productId: selectedServiceTypeData,
      search: event.target.value.toLowerCase(),
      orgId: parseInt(ORG_ID()),
    };
    dispatch(swCommonAction.getContactSearchList(data));
  }

  const allContactSearch = useSelector(
    (state) => state.getSwContactSearchhList
  );
  const isContactLoading = allContactSearch?.isLoading;

  function handleAllProjectSearch(event, value) {
    dispatch(
      assetsAction.getAssetDynomoList({
        searchText: event.target.value,
        type: 5,
      })
    );
  }
  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  const deleteContacts = useSelector((state) => state.deleteContactInProject);

  const handleDeleteContact = (data) => {
    dispatch(projectAction.deleteContactInProject(data));
  };
  return (
    <>
      {checkPrivileges([12, 132, 133, 134]) ? (
        <>
          {(showLoader || isLoading) && <Loader />}
          <div>
            <AddContactProject
              clearAddContact={clearAddContact}
              clearUpdateContact={clearUpdateContact}
              getProjectContactsListAction={getProjectContactsListAction}
              getAllProjectContactsList={
                isLoading ? [] : getProjectContactsList?.data
              }
              handleAllContactSearch={handleAllContactSearch}
              isContactLoading={isContactLoading}
              handleAllProjectSearch={handleAllProjectSearch}
              allProjects={allProject?.data ?? []}
              isProjectLoading={isProjectLoading}
              contactSearch={allContactSearch?.data ?? []}
              addContactInProjectAction={addContactInProjectAction}
              isLoading={isLoading}
              addContactInProject={addContactInProject}
              handleUpdateContactInProject={handleUpdateContactInProject}
              editOneContactProject={editOneContactProject}
              updateContactInProject={updateContactInProject}
              editContactInProject={editContactInProject}
              handleDeleteContact={handleDeleteContact}
              deleteContact={deleteContacts}
              projectData={location?.state}
              stateId={stateId}
              customerId={customerId}
              customer={customer}
            />
          </div>
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default AddContactProjectContainer;
