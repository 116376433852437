import { contractorType } from "../constants/contractorType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "contractor",
  isError: false,
  isLoading: false,
};

export const createContractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.POST_CONTRACTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.POST_CONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.POST_CONTRACTOR_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case contractorType.CLEAR_CONTRACTOR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateContractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.UPDATE_CONTRACTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.UPDATE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.UPDATE_CONTRACTOR_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case contractorType.CLEAR_CONTRACTOR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const getContractorListReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.GET_CONTRACTOR_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_CONTRACTOR_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_CONTRACTOR_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const deleteContractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.DELETE_CONTRACTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.DELETE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.DELETE_CONTRACTOR_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const viewContractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.GET_CONTRACTOR_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_CONTRACTOR_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_CONTRACTOR_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createPersonalContractorReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.POST_PERSONAL_CONTRACTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.POST_PERSONAL_CONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.POST_PERSONAL_CONTRACTOR_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case contractorType.CLEAR_CONTRACTOR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updatePersonalContractorReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.UPDATE_PERSONAL_CONTRACTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.UPDATE_PERSONAL_CONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.UPDATE_PERSONAL_CONTRACTOR_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case contractorType.CLEAR_CONTRACTOR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const getPersonalContractorListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.GET_PERSONAL_CONTRACTOR_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_PERSONAL_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_PERSONAL_CONTRACTOR_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_PERSONAL_CONTRACTOR_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const deletePersonalContractorReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.DELETE_PERSONAL_CONTRACTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.DELETE_PERSONAL_CONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.DELETE_PERSONAL_CONTRACTOR_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const viewPersonalContractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getOnboardedContractorListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.GET_ONBOARDED_CONTRACTOR_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_ONBOARDED_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_ONBOARDED_CONTRACTOR_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_ONBOARDED_CONTRACTOR_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const updateOnboardedContractorListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getContractorConfigurationDetsilsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case contractorType.CLEAR_CONTRACTOR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateContractorOnboardReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.UPDATE_CONTRACTOR_ONBOARD:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.UPDATE_CONTRACTOR_ONBOARD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.UPDATE_CONTRACTOR_ONBOARD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case contractorType.CLEAR_USER:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const updateContractorOnboardStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case contractorType.CLEAR_USER:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const searchSubcontractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractorType.GET_SEARCH_SUBCONTRACTOR:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.GET_SEARCH_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.GET_SEARCH_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createContractorClientWiseReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractorType.POST_CONTRACTOR_CLIENT_WISE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contractorType.POST_CONTRACTOR_CLIENT_WISE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contractorType.POST_CONTRACTOR_CLIENT_WISE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
