import React, { useEffect, useState } from "react";
import UserGroupList from "../component/groups/userGroupList";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { toast, ToastContainer } from "react-toastify";

const GroupsListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState(false);
  const GroupListData = useSelector((state) => state.GroupListData);

  const getuserGroupLists = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(userAction.getListGroupLists(data));
  };

  const deleteGroup = (userId) => {
    dispatch(userAction.deleteUserGroup(userId));
  };

  const divisiondata = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "6",
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const deleteUserGroupData = useSelector(
    (state) => state?.deleteUserGroupData
  );
  const loading = GroupListData.isLoading || deleteUserGroupData.isLoading;
  useEffect(() => {
    if (deleteUserGroupData?.status === "SUCCESS") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
        divisionId: 0,
        allGroups: true,
        isSendMail: false,
      };
      getuserGroupLists(data);
    }
  }, [deleteUserGroupData]);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      divisionId: 0,
      allGroups: true,
      orgId: ORG_ID(),
      isSendMail: false,
    };
    getuserGroupLists(data);
  }, []);

  useEffect(() => {
    if (GroupListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Groups has been sent to registered email ID");
    }
  }, [GroupListData]);

  return (
    <>
      {checkPrivileges([12, 55, 56, 57]) ? (
        <>
          <ToastContainer />
          <UserGroupList
            GroupListData={GroupListData?.data?.result?.groupData}
            noOfRecords={GroupListData?.data?.result?.noOfRecords}
            getuserGroupLists={getuserGroupLists}
            loading={loading}
            deleteGroup={deleteGroup}
            divisiondata={divisiondata}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};
export default GroupsListContainer;
