import React, { useState, useEffect, useRef } from "react";
import AddEquipment from "../component/equipments/addEquipment";
import { useDispatch, useSelector } from "react-redux";
import * as equipmentAction from "../../../redux/actions/equipmentsAction";
import * as userAction from "../../../redux/actions/userAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import {
  ACCESS_DENIED,
  ADMIN_EQUIPMENTS,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { API_STATUS, LOGIN_ORG_ID, ORG_ID } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import {
  notifyError,
  notifyErrorEquipmentSerialNumber,
} from "../../../library/custom/toastMessage/Toast";
import DownloadCsv from "../../download_file/DownloadCsv";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ToastContainer } from "react-toastify";

export default function AddEquipmentsContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dowloadCsv = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const equipmentId = location?.state?.id;
  const editMode = location?.state?.editMode;

  const allGroups = useSelector((state) => state.searchAllGroup);
  const createEquipment = useSelector((state) => state.createEquipment);
  const createEquipmentStatus = useSelector(
    (state) => state.createEquipment.status
  );
  const updateEquipments = useSelector((state) => state.updateEquipment);
  const updateEquipmentStatus = useSelector(
    (state) => state.updateEquipment.status
  );
  const allUsers = useSelector((state) => state.allUsers);
  const equipmentData = useSelector((state) => state.equipmentDetail?.data);
  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const equipmentCategoryData = useSelector(
    (state) => state?.getDropdownDataKeyValue?.data?.equipmentCategories
  );

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo?.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;

  const addAndUpdateEquipment = (data) => {
    if (editMode) {
      dispatch(equipmentAction.updateEquipments(data));
      setSetsubmit(true);
      setIsSuccess(true);
    } else {
      setSetsubmit(true);
      dispatch(equipmentAction.postCreateEquipment(data));
    }
  };
  useEffect(() => {
    setIsSuccess(false);
    dispatch(equipmentAction.clearEquipment());
  }, []);
  useEffect(() => {
    if (equipmentId) {
      dispatch(equipmentAction.equipmentDetailAction(equipmentId));
    }
  }, [dispatch]);

  const showLoader = useSelector(
    (state) =>
      state.createEquipment?.isLoading ||
      state.updateEquipment?.isLoading ||
      state.equipmentDetail?.isLoading ||
      state.state?.isAutoSearchDataLoading
  );
  const [isUserData, setIsUserData] = useState([]);

  useEffect(() => {
    setIsUserData(allUsers?.data);
  }, [allUsers?.data]);
  function handleAllUserSearch(
    event,
    value,
    divisionId,
    assetId,
    assetType,
    priviledgeId
  ) {
    setIsUserData([]);
    dispatch(
      userAction.searchAllUsers(
        event.target.value,
        value,
        divisionId,
        assetId,
        assetType,
        priviledgeId
      )
    );
  }
  function handleAllGroupSearch(event, divisionId) {
    dispatch(userAction.searchAllGroup(event.target.value, divisionId));
  }
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [6, 10, 16],
    productId: PRODUCT_ID(),
  };
  const getDivisionData = (data) => {
    dispatch(adminAction.getDropdownDataKeyValue(data));
  };

  const isAutoSearchDataLoading = allUsers.isLoading || allGroups.isLoading;

  useEffect(() => {
    getDivisionData(getDropdownDataKeyValueData);
  }, []);

  const successScreens = (id, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Equipment Updated Successfully"
            : "Equipment Created Successfully"
        }
        route={ADMIN_EQUIPMENTS}
        label1="Equipment ID"
        label4="Equipment in "
        label2={message1}
      ></SuccessScreen>
    );
  };
  const [duplicateEquipment, setDuplicateEquipment] = useState("");
  const [setsubmit, setSetsubmit] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isSingle, setIsSingle] = useState(false);

  const ismultipleUpload = (isBulkUpload) => {
    setIsSingle(isBulkUpload);
  };

  useEffect(() => {
    if (createEquipment?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (createEquipment?.data?.records?.length <= 1 && !isSingle) {
        if (
          createEquipment?.data?.records?.[0]?.status === false &&
          setsubmit
        ) {
          notifyErrorEquipmentSerialNumber();
        }
        if (createEquipment?.data?.records?.[0]?.status === true && setsubmit) {
          setIsSuccess(true);
        }
      } else if (
        createEquipment?.data?.records?.length >= 1 &&
        isSingle &&
        setsubmit
      ) {
        setIsDownload(true);
        setTimeout(() => {
          setIsSuccess(true);
        }, 1000);
      }
    }
  }, [createEquipment]);

  useEffect(() => {
    if (createEquipment?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (createEquipment?.status === API_STATUS.SUCCESS) {
        const duplicateEquipmentList = createEquipment?.data?.records?.filter(
          (eqp) => !eqp.status
        );
        if (duplicateEquipmentList?.length >= 1 && isSingle) {
          setDuplicateEquipment(duplicateEquipmentList);
        }
      }
    }
  }, [createEquipment]);

  useEffect(() => {
    if (duplicateEquipment && duplicateEquipment?.length >= 1) {
      if (isDownload) {
        dowloadCsv?.current?.link?.click();
      }
    }
  }, [duplicateEquipment]);

  useEffect(() => {
    if (updateEquipments?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      updateEquipments?.status === API_STATUS.SUCCESS &&
      updateEquipments?.data
    ) {
      const duplicateEquipmentList = updateEquipments?.data?.records?.filter(
        (eqp) => !eqp.status
      );
      if (
        duplicateEquipmentList?.length == 1 &&
        updateEquipments?.data?.records?.[0]?.status === false &&
        setsubmit
      ) {
        notifyErrorEquipmentSerialNumber();
      } else {
        setIsSuccess(true);
      }
    }
    return () => {
      setIsSuccess(false);
    };
  }, [updateEquipments]);

  const handleonfcs = () => {
    setIsUserData([]);
  };

  return (
    <>
      {checkPrivileges([12, 52, 53]) ? (
        <>
          {(createEquipmentStatus === "SUCCESS" &&
            createEquipment?.data &&
            createEquipment?.data?.records?.[0]?.status === true) ||
          (createEquipment?.data?.records?.length >= 1 && isSuccess) ? (
            successScreens()
          ) : updateEquipmentStatus === "SUCCESS" &&
            updateEquipments?.data?.records?.[0]?.status === true &&
            updateEquipments?.data?.records?.length == 1 &&
            isSuccess ? (
            successScreens()
          ) : (
            <>
              {showLoader && <Loader />}
              <div>
                <ToastContainer />
              </div>
              <AddEquipment
                onSubmit={addAndUpdateEquipment}
                division={divisionList}
                userGroups={allGroups?.data?.groupList}
                handleAllGroupSearch={handleAllGroupSearch}
                //allUsers={allUsers && allUsers?.data}
                allUsers={isUserData}
                equipmentCategory={equipmentCategoryData}
                equipmentData={editMode ? equipmentData : ""}
                editMode={editMode}
                isLoading={isAutoSearchDataLoading}
                handleAllUserSearch={handleAllUserSearch}
                equipmentId={equipmentId}
                ismultipleUpload={ismultipleUpload}
                handleonfcs={handleonfcs}
              />
            </>
          )}
          <DownloadCsv
            ref={dowloadCsv}
            data={duplicateEquipment}
            filename={"Already Exist Equipment List"}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
