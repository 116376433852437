import { Grid } from "@mui/material";
import React from "react";
import "./accessDeniedPageStyle.scss";
import SomethingWentWrong from "../../assets/accessDeniedImg.png";
const AccessDeniedPage = () => {
  function goToDashboard() {
    window.location.href = "/dashboard";
  }
  return (
    <div className="overideAccessDeniedPage">
      <Grid className="imageDiv" item container>
        <img src={SomethingWentWrong} alt="" />
      </Grid>
      <Grid className="textDiv" item container mt={1}>
        <h1>Sorry, you do not have the privilege of this module.</h1>
        <h1>Please contact Admin.</h1>
      </Grid>
      <Grid mt={4} mb={4} className="textDiv" item container>
        <h2 onClick={() => goToDashboard()}>Go back to the Dashboard Page</h2>
      </Grid>
      {/* <Buttons label="Back/> */}
    </div>
  );
};

export default AccessDeniedPage;
