import { reprimandType } from "../constants/reprimandType";
import { API_STATUS } from "../../constant/constant";
const initialState = {
  formData: [],
  flow: "reprimand",
  isSuccess: false,
  isError: false,
  isLoading: false,
  isEmailSuccess: false,
  msg: "",
  error: "",
  reprimandList: [],
  editReprimandData: {},
  successData: {},
};
export const reprimandFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case reprimandType.POST_REPRIMAND_PENDING:
      return {
        ...state,
        msg: "",
        isSuccess: false,
        isLoading: true,
      };
    case reprimandType.POST_REPRIMAND_SUCCESS:
      return {
        ...state,
        msg: action.msg,
        isSuccess: true,
        isError: false,
        isLoading: false,
        successData: action.successData,
      };
    case reprimandType.POST_REPRIMAND_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        error: action.error,
      };
    case reprimandType.UPDATE_REPRIMAND_PENDING:
      return {
        ...state,
        msg: "",
        isSuccess: false,
        isLoading: true,
        message2: "",
      };
    case reprimandType.UPDATE_REPRIMAND_SUCCESS:
      return {
        ...state,
        msg: action.msg,
        isSuccess: true,
        isError: false,
        isLoading: false,
        successData: action.successData,
        message2: "",
      };
    case reprimandType.UPDATE_REPRIMAND_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        error: action.error,
        message2: "",
      };
    case reprimandType.REVOKE_DATA_UNMOUNT:
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isLoading: false,
        editReprimandData: {},
        msg: "",
        error: "",
        successData: {},
      };
    case reprimandType.GET_REPRIMAND_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case reprimandType.GET_REPRIMAND_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editReprimandData: action.payload,
      };
    case reprimandType.GET_REPRIMAND_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const reprimandViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case reprimandType.GET_REPRIMAND_LIST_PENDING:
      return {
        ...state,
        msg: "",
        isError: false,
        isLoading: true,
      };
    case reprimandType.GET_REPRIMAND_LIST_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        reprimandList: action.payload,
        isEmailSuccess: false,
      };
    case reprimandType.GET_REPRIMAND_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        isEmailSuccess: true,
      };
    case reprimandType.GET_REPRIMAND_LIST_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error,
      };
    default:
      return state;
  }
};

// get Reprimand Count Reducers
export const reprimandCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case reprimandType.GET_REPRIMAND_COUNT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reprimandType.GET_REPRIMAND_COUNT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reprimandType.GET_REPRIMAND_COUNT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
