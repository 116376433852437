import React, { useEffect, useState, useRef } from "react";
import "./createToolboxtalk.scss";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormDropBox from "../../../../library/custom/formDropBox/FormDropBox";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Button from "../../../../library/custom/button/Button";
import { formValidation } from "../../../../utils/incpectionHelper";
import { USER_ID } from "../../../../constant/constant";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

const CreateToolbox = ({
  categoryListData,
  editToolBoxData,
  onSubmit,
  toolboxId,
  editMode,
}) => {
  const [addPhoto, setAddPhoto] = useState("");
  const [images, setImages] = useState([]);

  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const editor = useRef(null);
  useEffect(() => {
    if (editToolBoxData) {
      let coverImagePath;
      if (editToolBoxData?.file) {
        coverImagePath = editToolBoxData?.file?.path;

        setAddPhoto(coverImagePath);
      }
      setFormValue({
        descriptionSafetyMeeting:
          editToolBoxData?.descriptionSafetyMeeting ?? "",
        textSafetyMeeting: editToolBoxData?.textSafetyMeeting ?? "",
        titleSafetyMeeting: editToolBoxData?.titleSafetyMeeting ?? "",
        categoryId: editToolBoxData?.categoryId,
        fileIds: editToolBoxData?.file?.id ? [editToolBoxData?.file?.id] : [],
        toolboxTalkId: toolboxId,
      });
    }
  }, [editToolBoxData]);
  const history = useNavigate();

  const [formValue, setFormValue] = useState({
    categoryId: "",
    titleSafetyMeeting: "",
    descriptionSafetyMeeting: "",
    textSafetyMeeting: "",
    imageUrl: "",
    fileIds: [],
    toolboxTalkId: "",
  });

  const [isTitleSafetyMeeting, setIsTitleSafetyMeeting] = useState(false);
  // function mapError() {
  //   let error = false
  //   if()
  //   {
  //     setIsTitleSafetyMeeting(true)
  //     error=true
  //   }
  //   return error
  // }
  const formHandler = (event, action) => {
    const formData = {
      categoryId: formValue?.categoryId ?? null,
      titleSafetyMeeting: formValue?.titleSafetyMeeting ?? null,
      descriptionSafetyMeeting: formValue?.descriptionSafetyMeeting ?? null,
      textSafetyMeeting: formValue?.textSafetyMeeting ?? null,
      fileIds: formValue?.fileIds ?? [],
      createdBy: USER_ID(),
      toolboxTalkId: editMode ? toolboxId : "",
    };

    const { errorKey } = formValidation(
      {
        titleSafetyMeeting: formValue.titleSafetyMeeting,
        categoryId: formValue.categoryId,
      },
      action
    );
    setErrors(errorKey);
    let isError = false;
    if (
      !formValue.titleSafetyMeeting ||
      formValue.titleSafetyMeeting.trim() === ""
    ) {
      isError = true;
      setIsTitleSafetyMeeting(true);
      setFormValue({
        ...formValue,
        titleSafetyMeeting: "",
      });
    }
    if (!formValue.categoryId) {
      isError = true;
    }

    if (!isError) {
      setIsSubmit(true);

      onSubmit(formData);
    } else {
      setIsSubmit(false);
    }
  };

  const handleTitleChange = (event, value) => {
    let fName = event.target.value;
    // let fNameWhiteSpace = fName.replace(/\s+/g, "");
    setFormValue({
      ...formValue,
      titleSafetyMeeting: fName,
    });
  };

  const handleDescChange = (event, value) => {
    let Desc = event.target.value;
    // let DescWhiteSpace = Desc.replace(/\s+/g, " ");
    setFormValue({
      ...formValue,

      descriptionSafetyMeeting: Desc,
    });
  };
  const handleTextChange = (event, value) => {
    setFormValue({
      ...formValue,

      textSafetyMeeting: event,
    });
  };

  useEffect(() => {
    setFormValue({
      ...formValue,
      fileIds: images,
    });
  }, [images]);

  const handleDeleteImageURL = (urls) => {
    setImages([]);
    setAddPhoto("");
  };

  // useEffect(() => {
  //   setAddPhoto("");
  // }, [edit]);
  return (
    <>
      <div className="overideToolbox">
        <div className="formContainer">
          {editToolBoxData ? (
            <div className="heading_style">
              <h1>Update Safety Meeting</h1>
            </div>
          ) : (
            <div className="heading_style">
              <h1>Create Safety Meeting</h1>
            </div>
          )}

          <div className="addtoolBoxContainer">
            <div className="addtoolBox">
              <Grid>
                <Grid
                  item
                  container
                  mt={1.5}
                  lg={12}
                  xs={12}
                  sm={12}
                  md={4}
                  spacing={1}
                >
                  <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabels label="Category" isRequired={true} />
                      <SelectMenu
                        listData={categoryListData}
                        value={formValue.categoryId}
                        isRequired={true}
                        placeholder="Select Category"
                        onchangehandler={(e) =>
                          setFormValue({
                            ...formValue,
                            categoryId: e.target.value,
                          })
                        }
                        isError={!!errors?.categoryId ?? false}
                        errorMsg={errors?.categoryId ?? ""}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container mt={1.5} lg={12} spacing={2}></Grid>

              <Grid mt={1.5} spacing={2} lg={12}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    disabled={false}
                    placeholder="Enter title text here..."
                    type="text"
                    label="Title"
                    value={formValue.titleSafetyMeeting}
                    onChange={(event, value) => handleTitleChange(event, value)}
                    isError={
                      (!!errors?.titleSafetyMeeting || isTitleSafetyMeeting) ??
                      false
                    }
                    errorMsg={
                      isTitleSafetyMeeting
                        ? "Please enter this required field."
                        : ""
                    }
                  />
                </FormControl>
              </Grid>

              <Grid mt={3} spacing={2} lg={12}>
                <FormLabels label={"Description"} />

                <TextArea
                  name={"issuefound"}
                  placeholder="Add Safety Meeting description text here..."
                  value={formValue.descriptionSafetyMeeting}
                  onChange={(event, value) => handleDescChange(event, value)}
                />
              </Grid>

              <Grid mt={3} spacing={2} lg={12}>
                <FormLabels label={"Add Text"} />
                <JoditEditor
                  ref={editor}
                  hidePoweredByJodit="false"
                  value={formValue.textSafetyMeeting}
                  tabIndex={1}
                  //onChange={(event, value) => handleTextChange(event, value)}
                  onBlur={(event, value) => handleTextChange(event, value)}
                  className="textjodit"
                />
              </Grid>

              <Grid mt={3} spacing={2} lg={12}>
                <FormDropZone
                  label={"Add Document/Image"}
                  paraText1={"Drop the file here ..."}
                  paraText2={"Drop document/image here, "}
                  paraText3={"or Browse"}
                  // handleFileChange={(files, uploadfile) => {
                  //   setImages(...images, uploadfile);
                  //   setAddPhoto(...addPhoto, files);
                  // }}
                  handleFileChange={(files, uploadfile) => {
                    setAddPhoto(files);
                    setImages(uploadfile);
                  }}
                  isDynamics={true}
                  fileType={10}
                  imageUrl={addPhoto}
                  deleteImageURL={(url) => handleDeleteImageURL(url)}
                  id="addPhoto"
                  key="addPhoto"
                  isMuliple={false}
                  isDoc={true}
                  isImage={false}
                />
              </Grid>

              <Grid item lg={4} xs={12} sm={12} md={4}></Grid>

              <Grid item lg={6} xs={12} sm={12} md={4}>
                <div className="adduser_btn">
                  <div
                    className="cancle_heading_style"
                    onClick={() => history(-1)}
                  >
                    Cancel
                  </div>

                  <Button
                    varientContained={true}
                    disabled={isSubmit ? true : false}
                    label={editToolBoxData ? "Update" : "publish"}
                    onClick={(e) =>
                      formHandler(e, editToolBoxData ? "update" : "submit")
                    }
                  />
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateToolbox;
