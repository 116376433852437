import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as userAction from "../../../redux/actions/userAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ADMIN_NONPRIVILEGE_USER_LIST,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifyErrorContractor,
} from "../../../../src/library/custom/toastMessage/Toast";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import AddNonPrivilegeUser from "../component/users/addNonPrivilegeUser";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const AddNonPrivilegeUserContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successScreens, setSuccessScreens] = useState(false);
  const [setsubmit, setSetsubmit] = useState(false);
  const [isConvert, setIsConvert] = useState(false);

  const adduserLists = (data, checkBoxPrivilege) => {
    setSuccessScreens(true);
    setIsConvert(checkBoxPrivilege);
    if (editMode) {
      if (checkBoxPrivilege) {
        setSetsubmit(true);
        dispatch(userAction.convertNonPrivilegeList(data));
      } else {
        setSetsubmit(true);
        dispatch(userAction.updateNonPrivilegeList(data));
      }
    } else {
      setSetsubmit(true);
      dispatch(userAction.addNonPrivilegeList(data));
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.addNonPrivilegeUserListData.isLoading ||
      state.updateNonPrivilegeUserListData.isLoading ||
      state.getNonPrivilegeUserListDetsilsData.isLoading ||
      state.convertNonPrivilegeUserListData.isLoading
  );

  const userId = location?.state?.userId;
  const editMode = location?.state?.editMode;

  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );
  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );

  const getNonPrivilegeUserListDetsilsData = useSelector(
    (state) => state.getNonPrivilegeUserListDetsilsData
  );

  const convertNonPrivilegeUserListData = useSelector(
    (state) => state.convertNonPrivilegeUserListData
  );

  const convertNonPrivilegeUserListDataStatus = useSelector(
    (state) => state.convertNonPrivilegeUserListData.status
  );

  const updateNonPrivilegeUserListData = useSelector(
    (state) => state.updateNonPrivilegeUserListData
  );
  const addNonPrivilegeUserListData = useSelector(
    (state) => state.addNonPrivilegeUserListData
  );
  const updateNonPrivilegeUserListDataStatus = useSelector(
    (state) => state.updateNonPrivilegeUserListData.status
  );
  const addNonPrivilegeUserListDataStatus = useSelector(
    (state) => state.addNonPrivilegeUserListData.status
  );
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "5,6,12",
    productId: PRODUCT_ID(),
  };

  const getNonPrivilegeListDetsil = () => {
    const data = {
      userId: userId,
      orgId: ORG_ID(),
    };
    dispatch(userAction.getNonPrivilegeListDetsils(data));
  };

  useEffect(() => {
    dispatch(userAction.clearList());
    setSuccessScreens(false);
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
    if (userId) {
      getNonPrivilegeListDetsil();
    }
  }, []);

  const SuccessScree = (id) => {
    return (
      <SuccessScreen
        isUpdate={
          editMode && isConvert
            ? "User Convert into Privileged User Successfully"
            : editMode
            ? "User Updated Successfully"
            : "User Created Successfully"
        }
        route={ADMIN_NONPRIVILEGE_USER_LIST}
        label4="Users List Screen in"
        label1={true}
        label2={true}
        id={id}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (
      addNonPrivilegeUserListData?.status === API_STATUS.FAILURE ||
      updateNonPrivilegeUserListData?.status === API_STATUS.FAILURE ||
      convertNonPrivilegeUserListData?.status === API_STATUS.FAILURE
    ) {
      notifyError();
    } else {
      if (convertNonPrivilegeUserListData?.status === API_STATUS.SUCCESS) {
        if (
          convertNonPrivilegeUserListData?.data?.responseMessages
            ?.responseCode == "HBNG006" &&
          setsubmit
        ) {
          notifyErrorContractor();
        }
        if (
          convertNonPrivilegeUserListData?.data?.responseMessages
            ?.responseCode == "HBNG001" &&
          setsubmit
        ) {
          setSuccessScreens(true);
        }
      }
    }
  }, [
    convertNonPrivilegeUserListData,
    addNonPrivilegeUserListData,
    updateNonPrivilegeUserListData,
  ]);

  return (
    <>
      {checkPrivileges([12, 64, 65]) ? (
        <>
          {convertNonPrivilegeUserListDataStatus === API_STATUS.SUCCESS &&
          convertNonPrivilegeUserListData?.data &&
          convertNonPrivilegeUserListData?.data?.responseMessages
            ?.responseCode == "HBNG001" &&
          successScreens ? (
            SuccessScree()
          ) : addNonPrivilegeUserListDataStatus === API_STATUS.SUCCESS &&
            addNonPrivilegeUserListData?.data &&
            addNonPrivilegeUserListData?.data?.responseMessages?.responseCode ==
              "HBNG001" &&
            successScreens ? (
            SuccessScree()
          ) : updateNonPrivilegeUserListDataStatus === API_STATUS.SUCCESS &&
            updateNonPrivilegeUserListData?.data &&
            successScreens ? (
            SuccessScree()
          ) : (
            <>
              {showLoader && <Loader />}
              <div>
                <ToastContainer />
              </div>
              <AddNonPrivilegeUser
                adduserLists={adduserLists}
                divisions={division}
                jobTitles={jobTitles}
                getNonPrivilegeUserListDetsilsData={
                  editMode ? getNonPrivilegeUserListDetsilsData?.data : ""
                }
                editMode={editMode}
                userId={userId}
                roles={roles}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default AddNonPrivilegeUserContainer;
