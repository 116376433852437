import React, { useEffect } from "react";
import ViewCredential from "../component/viewCredential";
import { MY_CREDENTIALS } from "../../../constant/routeContant";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as credentialAction from "../../../redux/actions/credentialAction";
import Loader from "../../../library/common/Loader";
import { API_STATUS, USER_ID } from "../../../constant/constant";

export default function ViewCredentialContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = window.location.search.slice(
    4,
    window.location.search?.length
  );
  const credentialId = location?.state?.credentialId ?? queryString;

  const viewCredentialData = useSelector(
    (state) => state?.getCredentialViewDetsilsData?.data
  );

  const showLoader = useSelector(
    (state) => state?.getCredentialViewDetsilsData?.isLoading
  );
  const isSuccess = useSelector(
    (state) => state.getCredentialViewDetsilsData.status === API_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(
      credentialAction.getCredentialViewDetsils(credentialId, USER_ID())
    );
  }, [USER_ID(), credentialId]);

  const dataBack = {
    title: "Back to Credential",
    route: MY_CREDENTIALS,
  };

  return (
    <>
      {showLoader && <Loader />}
      {isSuccess && (
        <ViewCredential
          dataBack={dataBack}
          viewCredentialData={viewCredentialData}
        />
      )}
    </>
  );
}
