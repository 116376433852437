import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";
import { checkPrivileges } from "../utils/rolesHelper";
import { get, post, deletes } from "./axiosRequest";

export const getClientList = async ({ data }) => {
  const { pageSize, page, search, productId, statusIds, isSendMail } = data;
  console.log("data", data);

  const url = `${
    getApiUrl().adminApiUrl
  }/Organisation/GetOrganisationList?searchText=${search.trim()}&isSendMail=${isSendMail}&statusIds=${statusIds}&page=${page}&pageSize=${pageSize}&productId=${productId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createClient = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Organisation/AddOrganisation`;
  console.log("createClient" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getClientDetsils = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Organisation/GetOrganisationDetail?Organisationid=${data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateClient = async ({ data }) => {
  const Orgid = data?.organisationId;
  const url = `${getApiUrl().adminApiUrl}/Organisation/UpdateOrganisation`;
  console.log("updateClient" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: Orgid,
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteClient = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}Organisation/DeleteOrganisation/${
    payload?.Organisationid
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const searchAllClients = async (serachText) => {
  if (serachText?.trim()) {
    const url = `${getApiUrl().searchApiUrl}/v1/getorganisationlist?search=${
      serachText ? serachText?.trim()?.toLowerCase() : ""
    }&status=${checkPrivileges([12]) ? 0 : 1}&productId=${PRODUCT_ID()}`;
    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};

export const getSyncWithProcore = async ({ data }) => {
  const { orgId, disconnectClient, procoreCompanyId, procoreCompanyName } =
    data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Procore/SyncProcoreCompany?orgId=${orgId}&disconnectClient=${disconnectClient}&procoreCompanyId=${procoreCompanyId}&procoreCompanyName=${procoreCompanyName}`;
  try {
    return await get(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getProcoreCompanyList = async ({ data }) => {
  const { page, pageSize } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Procore/GetCompanyList?page=${page}&pageSize=${pageSize}`;
  try {
    return await get(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
