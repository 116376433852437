import React, { useEffect, useState } from "react";
import AddPermit from "../components/addPermit";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import { useDispatch, useSelector } from "react-redux";
import * as permitAction from "../../../../redux/storm-water/actions/permitAction";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
import * as uploadFilesAction from "../../../../redux/actions/uploadFilesAction";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  SW_PERMIT_LIST,
} from "../../../../constant/routeContant";
import { ToastContainer } from "react-toastify";
import {
  notifyError,
  notifyErrorpermitalreadyExit,
} from "../../../../../src/library/custom/toastMessage/Toast";
import { API_STATUS } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
function AddPermitContainer() {
  const stateData = useSelector((state) => state.state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  const location = useLocation();

  const permitId = location?.state?.permitId;
  const editMode = location?.state?.editMode;
  const projectid = location?.state?.projectid;
  const stateId = location?.state?.stateId;
  const projectName = location?.state?.projectName;
  const projectNumber = location?.state?.projectNumber;
  const Projectstate = location?.state?.state;
  const customer = location?.state?.customerName;
  const customerId = location?.state?.customerId;
  const permitDetails = useSelector(
    (state) => state.permitDetails?.data?.result
  );

  const [successScreens, setIsSuccess] = useState(false);
  const [clearAddPermit, setClearAddPermit] = useState(false);
  const [clearEditPermit, setClearEditPermit] = useState(false);
  const [selectedClientSignature, setSelectedClientSignature] = useState("");
  const [isContractorProfileSignature, setIsContractorProfileSignature] =
    useState(false);
  const [setsubmit, setSetsubmit] = useState(false);
  const addPermit = (data) => {
    dispatch(permitAction.addPermit(data));
  };
  const data1 = {
    stateId: stateId,
  };
  const handlePermitType = (data1) => {
    dispatch(permitAction.getPermitTypePending(data1));
  };

  const uploadContractorSignatureFile = (data) => {
    setIsContractorProfileSignature(true);
    dispatch(uploadFilesAction.createFileUpload(data));
  };

  const handleDeleteSignature = () => {
    setSelectedClientSignature("");
  };
  const addPermits = useSelector((state) => state.addPermit);
  const fileUploadData = useSelector((state) => state.fileUpload.data);
  const getPermitType = useSelector((state) => state.getPermitType?.data);
  const addPermitStatus = useSelector((state) => state.addPermit.status);

  const updatePermit = useSelector((state) => state.updatePermit);
  const updatePermitStatus = useSelector((state) => state.updatePermit.status);
  const addAndUpdatePermit = async (data) => {
    setIsSuccess(true);
    if (editMode) {
      setSetsubmit(true);
      dispatch(permitAction.PostUpdatePermitPending(data));
      setClearEditPermit(true);
    } else {
      setSetsubmit(true);
      dispatch(permitAction.addPermit(data));
      setClearAddPermit(true);
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.state?.isLoading ||
      state.addPermit?.isLoading ||
      state?.updatePermit?.isLoading ||
      state?.permitDetails?.isLoading ||
      state?.getPermitType?.isLoading
  );
  const fileUploadLoader = useSelector((state) => state?.fileUpload?.isLoading);

  useEffect(() => {
    if (permitId) {
      const data = {
        permitId: permitId,
      };
      dispatch(permitAction.permitDetailPending(data));
    }
  }, [dispatch]);

  useEffect(() => {
    setSelectedClientSignature("");
    getStateData();
    handlePermitType(data1);
  }, []);

  const successScreen = (id, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Permit Updated Successfully "
            : "Permit Added Successfully"
        }
        projectData={location?.state}
        route={SW_PERMIT_LIST}
        label1="Permit ID"
        label4="Permit in "
        label2={message1}
      ></SuccessScreen>
    );
  };
  useEffect(() => {
    setIsSuccess(false);
  }, []);
  useEffect(() => {
    if (addPermits?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (addPermits?.status === API_STATUS.SUCCESS) {
        if (
          addPermits?.data?.responseMessages?.responseCode === "HBNG012" &&
          setsubmit
        ) {
          notifyErrorpermitalreadyExit();
        }
        if (
          addPermits?.data?.responseMessages?.responseCode === "HBNG001" &&
          setsubmit
        ) {
          setIsSuccess(true);
        }
      }
    }
  }, [addPermits]);
  useEffect(() => {
    if (updatePermit?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (updatePermit?.status === API_STATUS.SUCCESS) {
        if (
          updatePermit?.data?.responseMessages?.responseCode === "HBNG012" &&
          setsubmit
        ) {
          notifyErrorpermitalreadyExit();
        }
        if (
          updatePermit?.data?.responseMessages?.responseCode === "HBNG001" &&
          setsubmit
        ) {
          setIsSuccess(true);
        }
      }
    }
  }, [updatePermit]);

  return (
    <>
      {checkPrivileges([12, 126, 127]) ? (
        <>
          {fileUploadLoader && <Loader />}
          {addPermitStatus === "SUCCESS" &&
          addPermits &&
          clearAddPermit &&
          successScreens &&
          addPermits?.data?.responseMessages?.responseCode === "HBNG001" ? (
            successScreen(addPermits?.data?.id)
          ) : updatePermitStatus === "SUCCESS" &&
            updatePermit &&
            clearEditPermit &&
            successScreens &&
            updatePermit?.data?.responseMessages?.responseCode === "HBNG001" ? (
            successScreen(updatePermit?.data?.permitId, "has been Updated")
          ) : (
            <div>
              <div>
                <ToastContainer />
              </div>
              {showLoader ? (
                <Loader />
              ) : (
                <AddPermit
                  onSubmit={addAndUpdatePermit}
                  state={stateData && stateData.data?.getState}
                  addPermit={addPermit}
                  projectid={projectid}
                  editMode={editMode}
                  permitDetails={editMode ? permitDetails : ""}
                  permitId={permitId}
                  getPermitType={getPermitType}
                  stateId={stateId}
                  projectName={projectName}
                  projectNumber={projectNumber}
                  Projectstate={Projectstate}
                  clearAddPermit={clearAddPermit}
                  customer={customer}
                  customerId={customerId}
                  uploadContractorSignatureFile={uploadContractorSignatureFile}
                  fileUploadData={fileUploadData}
                  selectedClientSignature={selectedClientSignature}
                  setSelectedClientSignature={setSelectedClientSignature}
                  handleDeleteSignature={handleDeleteSignature}
                />
              )}
            </div>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default AddPermitContainer;
