
export const reprimandType = {

  // Add reprimand
  POST_REPRIMAND_PENDING: 'POST_REPRIMAND_PENDING',
  POST_REPRIMAND_SUCCESS: 'POST_REPRIMAND_SUCCESS',
  POST_REPRIMAND_FAILED: 'POST_REPRIMAND_FAILED',
// Update reprimand
  UPDATE_REPRIMAND_PENDING: 'UPDATE_REPRIMAND_PENDING',
  UPDATE_REPRIMAND_SUCCESS: 'UPDATE_REPRIMAND_SUCCESS',
  UPDATE_REPRIMAND_FAILED: 'UPDATE_REPRIMAND_FAILED',

  // unmounted revoke
  REVOKE_DATA_UNMOUNT: 'REVOKE_DATA_UNMOUNT',

  //GetReprimandList
  GET_REPRIMAND_LIST_PENDING: 'GET_REPRIMAND_LIST_PENDING',
  GET_REPRIMAND_LIST_SUCCESS: 'GET_REPRIMAND_LIST_SUCCESS',
  GET_REPRIMAND_LIST_EMAIL_SUCCESS: 'GET_REPRIMAND_LIST_EMAIL_SUCCESS',
  GET_REPRIMAND_LIST_FAILED: 'GET_REPRIMAND_LIST_FAILED',

  // GET REPRIMAND DETAILS
  GET_REPRIMAND_DETAILS_PENDING: 'GET_REPRIMAND_DETAILS_PENDING',
  GET_REPRIMAND_DETAILS_SUCCESS: 'GET_REPRIMAND_DETAILS_SUCCESS',
  GET_REPRIMAND_DETAILS_FAILED: 'GET_REPRIMAND_DETAILS_FAILED',

    // GET REPRIMAND COUNT
    GET_REPRIMAND_COUNT_PENDING: 'GET_REPRIMAND_COUNT_PENDING',
    GET_REPRIMAND_COUNT_SUCCESS: 'GET_REPRIMAND_COUNT_SUCCESS',
    GET_REPRIMAND_COUNT_FAILED: 'GET_REPRIMAND_COUNT_FAILED'
};
