import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as reportsAction from "../../../../redux/storm-water/actions/reportsAction";
import ReportMap from "../components/ReportMap";
import Loader from "../../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../../constant/routeContant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

function SwReportMapConatiner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getOrganisationMapList = useSelector(
    (state) => state.organisationMapData
  );

  const showLoader = useSelector(
    (state) => state.organisationMapData.isLoading
  );
  const getOrganisationMapListData = (data) => {
    dispatch(reportsAction.getOrganisationMapList(data));
  };

  useEffect(() => {
    getOrganisationMapListData();
  }, []);

  return (
    <>
      {checkPrivileges([12, 139, 142]) ? (
        <>
          {showLoader && <Loader />}
          <ReportMap getOrganisationMapList={getOrganisationMapList} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default SwReportMapConatiner;
