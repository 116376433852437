import React, { useState, useEffect } from "react";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import SuccessScreen from "../../../library/custom/success/successScreen";
import Loader from "../../../library/common/Loader";
import {
  ACCESS_DENIED,
  INSPECTION_ADMIN,
} from "../../../constant/routeContant";
import SetConfiguration from "../component/inspection/setConfiguration";
import { ToastContainer, toast } from "react-toastify";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { sortAlphabetically } from "../../../utils/helper";

const SetConfigurationContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const questsetId = location?.state?.questsetId;
  const assetTypeId = location?.state?.assetTypeId;
  const editMode = location?.state?.editMode;

  const [successScreens, setSuccessScreens] = useState(false);
  const defaultCatData = {
    searchText: "",
    organisationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    allowPaging: false,
  };
  const defaultSubCatData = {
    searchText: "",
    organisationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    allowPaging: false,
  };

  const defaultQuestionSetData = {
    organisationId: ORG_ID(),
    isActive: true,
  };

  const questionSetList = useSelector((state) => state.getQuestionSetList);
  const questionSetListData = (data) => {
    dispatch(incpectionAction.getQuestionSetList(data));
  };
  const getCategoriesList = useSelector((state) => state.getCategoriesList);
  const getCategoriesListData = (data) => {
    dispatch(incpectionAction.getCategoriesList(data));
  };

  const getSubCategoriesList = useSelector(
    (state) => state.getSubCategoriesList
  );
  const getSubCategoriesListData = (data) => {
    dispatch(incpectionAction.getSubCategoriesList(data));
  };

  const adminAddUpdateInspection = useSelector(
    (state) => state.adminAddUpdateInspection
  );

  const addUpdateQuestions = useSelector((state) => state.addUpdateQuestions);

  const adminUpdateInspection = useSelector(
    (state) => state.adminUpdateInspection
  );

  const adminAddUpdateCategory = useSelector(
    (state) => state.adminAddUpdateCategory
  );

  const adminAddUpdateSubcategory = useSelector(
    (state) => state.adminAddUpdateSubcategory
  );

  const createUpdateQuestions = useSelector(
    (state) => state.addUpdateQuestions
  );

  const defaultSubData = {
    type: 5,
  };

  const getInspectionAcknowledgement = useSelector(
    (state) => state.getInspectionAcknowledgement
  );
  const getInspectionAcknowledgementData = (data) => {
    dispatch(incpectionAction.getInspectionAcknowledgement(data));
  };
  const [clearCategory, setClearCategory] = useState(false);
  const [clearStatus, setClearStatus] = useState(false);
  const [clearSubCategory, setClearSubCategory] = useState(false);
  const [clearQuestion, setClearQuestion] = useState(false);

  const showLoader = useSelector(
    (state) =>
      state.adminAddUpdateInspection?.isLoading ||
      state.adminUpdateInspection?.isLoading ||
      state.getCategoriesList?.isLoading ||
      state.getSubCategoriesList?.isLoading ||
      state.getQuestionsList?.isLoading ||
      state.adminAddUpdateCategory?.isLoading ||
      state.adminAddUpdateSubcategory?.isLoading ||
      state.addUpdateQuestions?.isLoading ||
      state.getInspectionAcknowledgement?.isLoading
  );

  const onSubmit = (data) => {
    setSuccessScreens(true);
    setClearStatus(true);
    dispatch(incpectionAction.adminAddUpdateInspection(data));
  };

  const onSetSubmit = (data) => {
    setSuccessScreens(true);
    dispatch(incpectionAction.adminUpdateInspection(data));
  };

  const onCategorySubmit = (data) => {
    setClearCategory(true);
    dispatch(incpectionAction.adminAddUpdateCategory(data));
  };

  const onSubCategorySubmit = (data) => {
    setClearSubCategory(true);
    dispatch(incpectionAction.adminAddUpdateSubcategory(data));
  };

  const onQuestionSubmit = (data) => {
    setClearQuestion(true);
    dispatch(incpectionAction.addUpdateQuestions(data));
  };

  useEffect(() => {
    if (
      adminAddUpdateCategory?.status === "SUCCESS" &&
      adminAddUpdateCategory?.isLoading === false &&
      clearCategory
    ) {
      getCategoriesListData(defaultCatData);
      toast("Category Added successfully");
    }
  }, [adminAddUpdateCategory]);

  useEffect(() => {
    if (
      adminAddUpdateSubcategory?.status === "SUCCESS" &&
      adminAddUpdateSubcategory?.isLoading === false &&
      clearSubCategory
    ) {
      getSubCategoriesListData(defaultSubCatData);
      toast("Sub Category Added successfully");
    }
  }, [adminAddUpdateSubcategory]);

  useEffect(() => {
    dispatch(incpectionAction.clearAdminUpdateInspection());
    dispatch(incpectionAction.clearAdminAddUpdateInspection());
    dispatch(incpectionAction.clearCloneQuestionSet());
    questionSetListData(defaultQuestionSetData);
    getCategoriesListData(defaultCatData);
    getSubCategoriesListData(defaultSubCatData);
  }, []);

  useEffect(() => {
    if (
      adminAddUpdateInspection?.status === "SUCCESS" &&
      adminAddUpdateInspection?.isLoading === false &&
      clearStatus
    ) {
      toast("Inspection Name Added successfully");
    }
  }, [adminAddUpdateInspection]);

  useEffect(() => {
    if (
      createUpdateQuestions?.status === "SUCCESS" &&
      createUpdateQuestions?.isLoading === false &&
      clearQuestion
    ) {
      toast("Questions Added successfully");
    }
  }, [createUpdateQuestions]);

  const successScreen = (id, successMsg, label1, label4) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={INSPECTION_ADMIN}
        label1={label1}
        label4={label4}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    getInspectionAcknowledgementData(defaultSubData);
  }, []);

  const sortedQuestionsSet = sortAlphabetically(
    questionSetList?.data?.questionsSets
  );
  
  const sortedCategories = sortAlphabetically(
    getCategoriesList?.data?.keyValues?.filter((x) => x.isActive)
  );
  const sortedSubCategories = sortAlphabetically(
    getSubCategoriesList?.data?.keyValues?.filter((x) => x.isActive)
  );

  const sortedInspectionAcknowledgement = sortAlphabetically(
    getInspectionAcknowledgement?.data?.result?.filter(
      (x) => x.status
    )
  );

  return (
    <>
      {checkPrivileges([12, 120, 121, 4]) ? (
        <>
          <>{showLoader && <Loader />}</>
          {adminUpdateInspection.status === "SUCCESS" &&
          adminUpdateInspection.data &&
          successScreens ? (
            successScreen(
              adminUpdateInspection?.data?.id,
              "Inspection Configured Successfully",
              "Set Id",
              "Set In"
            )
          ) : (
            <>
              <SetConfiguration
                getCategoriesList={sortedCategories}
                getSubCategoriesList={sortedSubCategories}
                getSubCategoriesListData={getSubCategoriesListData}
                questionSetList={sortedQuestionsSet}
                onSubmit={onSubmit}
                onSetSubmit={onSetSubmit}
                questionSetListData={questionSetListData}
                adminAddUpdateInspection={adminAddUpdateInspection}
                editMode={editMode}
                questsetId={questsetId}
                assetTypeId={assetTypeId ?? "1"}
                onCategorySubmit={onCategorySubmit}
                onSubCategorySubmit={onSubCategorySubmit}
                onQuestionSubmit={onQuestionSubmit}
                addUpdateQuestions={addUpdateQuestions}
                inspectionAcknowledgement={sortedInspectionAcknowledgement}
              />
              <ToastContainer />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default SetConfigurationContainer;
