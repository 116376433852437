import { Grid } from "@mui/material";
import React from "react";
import "./accessDeniedPageStyle.scss";
import pagenotfound from "../../assets/404image.png";
import { useNavigate } from "react-router-dom";

const PageNotFoundPage = () => {
  const navigate = useNavigate();

  function goToDashboard() {
    window.location.href = "/dashboard";
  }
  return (
    <div className="overidePageNotFoundPage">
      <div className="imageDiv">
        <img src={pagenotfound} alt="" />
      </div>

      <div className="textDiv">
        <h1>Error 404: Page not found </h1>
      </div>
      <div className="textDiv pagenotfoundtest">
        <h2 onClick={() => goToDashboard()}>Go back to the Dashboard Page</h2>
      </div>
    </div>
  );
};

export default PageNotFoundPage;
