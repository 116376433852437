import { Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { InputField } from "../../../library/custom/textBox/InputField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ASSET_TYPE_JHA, USER_TYPE } from "../../../constant/constant";
import Checkbox from "../../../library/checkbox/checkbox";
import "./conductJha.scss";
import Loader from "../../../library/common/Loader";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import Buttons from "../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import AttendeeList from "./attendeeList";
import { CONDUCT_JHA_LIST } from "../../../constant/routeContant";
import FormDropZone from "../../../library/dropZone/FormDropZone";

function ConductJha({
  isProfileSignatureLoading,
  dataBack,
  templateList,
  assetOption,
  searchAsset,
  getJhaTempleteDetsilsData,
  onChnageCat,
  onChnageSubCat,
  assetType,
  projectName,
  asset,
  locations,
  templete,
  selectedSignature,
  hiddenSignatureInput,
  onChangeAssetType,
  onSelectSearchProject,
  onSelectSearchAsset,
  customAssetValue,
  onChangeLocation,
  onChangeTemplete,
  signatureUpload,
  submitData,
  isLoading,
  assetTypeError,
  projectNameError,
  templeteError,
  locationsError,
  customAssetNameError,
  catogerysError,
  showAssign,
  userType,
  handleUserType,
  OnClickAssignAttendee,
  typeOnchange,
  isAutoSearchDataLoading,
  users,
  setUsers,
  allUsers,
  addUserData,
  autoCompUser,
  userList,
  deleteData,
  attendeesignatureUpload,
  hiddenAttendeeSignatureInput,
  attendeeSignature,
  OnClickVerifyAll,
  showVerify,
  handleFileChange,
  handleDeleteImageURL,
  removeImage,
  photos,
  setImageCheck,
  addGroupUserData,
  deleteUsersGroup,
  userGroups,
  handleAllGroupSearch,
  autoComp,
  usersGroup,
  onChangeUsersGroup,
  userGroupList,
  groupUsers,
  inpectorsignature,
  assetValue,
  projectValue,
  dueJHAData,
  onChangeAdduser,
  adduser,
  addAdditionalUser,
  projectNameValue,
  onSelectUsers,
  usersValue,
}) {
  const navigate = useNavigate();
  const NavigateToList = () => {
    navigate(CONDUCT_JHA_LIST);
  };

  return (
    <>
      <div className="overidePersonal">
        {isProfileSignatureLoading && <Loader />}

        <Grid
          item
          lg={6}
          xs={12}
          sm={12}
          md={4}
          pl={1}
          textAlign="left"
          className="headingStyleConatiner"
        >
          <div className="headingstyles">Conduct Hazard Analysis</div>
        </Grid>
      </div>
      <Grid container item md={10} xs={9} className="addShadowBox">
        <Grid item container mt={2} spacing={3}>
          <Grid item lg={5} xs={12} sm={12} md={6} pl={2}>
            <FormControl fullWidth>
              <FormLabels label="Select Asset Type" isRequired={true} />
              <SelectMenu
                listData={ASSET_TYPE_JHA}
                value={assetType}
                onchangehandler={(e) => onChangeAssetType(e)}
                placeholder="Please Select"
                disabled={dueJHAData?.assetType ? true : false}
              />
            </FormControl>
            {assetTypeError && !assetType && (
              <div className="errorMsg">
                Selection is required for this field
              </div>
            )}
          </Grid>
          {assetType == "1" && (
            <Grid item lg={5} xs={12} sm={12} md={4}>
              {dueJHAData?.assetName ? (
                <InputField
                  isRequired={true}
                  type="text"
                  label="Jobsite/Project"
                  placeholder={""}
                  value={projectValue}
                  // onChange={(e) => onChangeLocation(e)}
                  disabled={true}
                />
              ) : (
                <FormControl fullWidth>
                  <FormLabels label="Project Name" isRequired={true} />
                  <SearchAutoComplete
                    name="credential"
                    optionsData={isLoading ? [] : assetOption ?? []}
                    typeOnchange={(event) => searchAsset(event.target.value, 1)}
                    isKeyValuePair={true}
                    value={projectNameValue}
                    onChange={(event, value) =>
                      onSelectSearchProject(event, value)
                    }
                    isLoading={isLoading}
                    noOptionsTextMsg="No Projects"
                  />
                </FormControl>
              )}

              {/* {projectValue && <div>{projectValue}</div>} */}
              {projectNameError && !projectName && (
                <div className="errorMsg">
                  Selection is required for this field
                </div>
              )}
            </Grid>
          )}

          {assetType == "4" && (
            <Grid item lg={5} xs={12} sm={12} md={4}>
              {dueJHAData?.assetName ? (
                <InputField
                  isRequired={true}
                  type="text"
                  label="Custom Asset Name"
                  placeholder={""}
                  value={assetValue}
                  // onChange={(e) => onChangeLocation(e)}
                  disabled={true}
                />
              ) : (
                <FormControl fullWidth>
                  <FormLabels label="Custom Asset Name" isRequired={true} />
                  <SearchAutoComplete
                    name="Custom Asset Name"
                    optionsData={isLoading ? [] : assetOption ?? []}
                    typeOnchange={(event) => searchAsset(event.target.value, 4)}
                    isKeyValuePair={true}
                    value={customAssetValue}
                    onChange={(event, value) =>
                      onSelectSearchAsset(event, value)
                    }
                    noOptionsTextMsg="No Custom Asset"
                    isLoading={isLoading}
                  />
                </FormControl>
              )}
              {/* {assetValue && <div>{assetValue}</div>} */}
              {customAssetNameError && !asset && (
                <div className="errorMsg">
                  Selection is required for this field
                </div>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item container mt={1} spacing={3}>
          <Grid item lg={10} xs={12} sm={12} md={6} pl={2}>
            <FormControl fullWidth>
              <FormLabels label="Hazard Analysis Template" isRequired={true} />
              <SelectMenu
                listData={templateList ?? []}
                value={templete}
                onchangehandler={(e) => onChangeTemplete(e)}
                placeholder="Please Select"
                disabled={dueJHAData?.jhaTemplateId ? true : false}
              />
            </FormControl>
            {templeteError && !templete && (
              <div className="errorMsg">
                Selection is required for this field
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item container mt={1} spacing={3}>
          <Grid item lg={5} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <InputField
                isRequired={false}
                type="text"
                label="Location"
                placeholder={"Add Location Here..."}
                value={locations}
                onChange={(e) => onChangeLocation(e)}
              />
            </FormControl>
            {/* {locationsError && !locations && (
              <div className="errorMsg">Please enter this required field</div>
            )} */}
          </Grid>
        </Grid>
        <Grid item container mt={2} spacing={3}>
          <Grid item lg={10} xs={12} sm={12} md={6} pl={2}>
            <FormControl fullWidth>
              <FormLabels label="Select Categories" isRequired={true} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={3} xs={8} sm={12} md={4} mt={2}>
            {getJhaTempleteDetsilsData?.map((category, index) => {
              return (
                <div className="typeTxtWrapper" key={index}>
                  <div className="typeTxt">{category?.value}</div>
                  <div className="typeTxtCheck">
                    <Checkbox
                      checked={category.showActivity}
                      onChange={() => onChnageCat(category)}
                      label={""}
                    />
                  </div>
                </div>
              );
            })}
            {catogerysError && (
              <div className="errorMsg">
                Selection is required for this field
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={3}>
          <Grid item lg={11} xs={12} sm={12} md={4} mt={2}>
            {getJhaTempleteDetsilsData?.map((category) => {
              return category.showActivity ? (
                <Accordion className="jhAccordianParent" key={category?.key}>
                  <AccordionSummary
                    className="jhAccordian"
                    expandIcon={<ExpandMoreIcon className="expandIconjha" />}
                  >
                    <div className="cateTitlejha">
                      <Typography className="accoTitle">
                        {category?.value}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {category?.activitiesTemplate.map((activity) => {
                      return (
                        <Accordion
                          className="jhAccordianChild"
                          key={activity?.key}
                        >
                          <AccordionSummary
                            className="jhChildAccordian"
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <div className="jhsubCatButton">
                              <Typography className="accoTitle">
                                <div className="typeTxt">
                                  <Checkbox
                                    checked={activity.showSubActivity}
                                    onChange={() =>
                                      onChnageSubCat(category, activity)
                                    }
                                    label={activity?.value}
                                  />
                                </div>
                              </Typography>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="accordinDetails">
                            {activity?.hazards?.map((controls) => {
                              return (
                                <div
                                  className="hazardContainer"
                                  key={controls?.key}
                                >
                                  <div className="hazardMainContainer">
                                    <div className="hazardName">Hazard :</div>
                                    <div className="hazardNameValue">
                                      {controls?.value}{" "}
                                    </div>
                                  </div>

                                  {controls?.controls?.map(
                                    (controlsItem, index) => {
                                      return (
                                        <div
                                          className="controleName"
                                          key={controlsItem?.key}
                                        >
                                          <div className="controleStyle">
                                            Control {index + 1}-{" "}
                                          </div>
                                          <div className="controleMainDiv">
                                            {parse(
                                              unescape(controlsItem?.value)
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              ) : null;
            })}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={11} md={12} xs={12} mt={3} className="msg">
            <p className="defaultMsg">
              "I certify that this report and all attachments were prepared
              under my direction or supervision. Based on my observations, or
              those persons directly responsible for gathering the information,
              the information submitted is, to the best of my knowledge and
              belief, true, accurate, and complete."
            </p>
          </Grid>
        </Grid>

        <Grid item className="profileTitle" sm={12}></Grid>
        <Grid item container mt={0} spacing={3} className="topHeaderProfile">
          <Grid item lg={6} xs={12} mt={2}>
            <Grid className="signatureImg">
              {inpectorsignature ? (
                <>
                  <img
                    className="borderSignatureImg"
                    src={inpectorsignature}
                    alt=""
                  />{" "}
                  <div className="authorizeSign">Authorized signature</div>
                </>
              ) : selectedSignature ? (
                <img
                  className="borderSignatureImg"
                  src={selectedSignature}
                  alt=""
                />
              ) : (
                <p className="signatureText"> + Add Signature</p>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!inpectorsignature && (
          <Grid item container mt={0} spacing={3} className="topHeaderProfile">
            <Grid item lg={3} xs={12} mt={2}>
              <input
                type="file"
                ref={hiddenSignatureInput}
                onChange={(event) => {
                  signatureUpload(event);
                }}
                style={{ display: "none" }}
                accept="image/*"
              />
              <label
                className="signatureBtn"
                onClick={() => {
                  hiddenSignatureInput.current.click();
                  setImageCheck(true);
                }}
                htmlFor="actual-btn"
              >
                + Add Signature
              </label>
            </Grid>
          </Grid>
        )}
        {assetType && (
          <Grid container mt={4} spacing={1} ml={0.1}>
            <Grid lg={4} xs={12} sm={4} md={4} mt={1.5}>
              <Buttons
                varientContained={true}
                label={"Assign Attendees"}
                onClick={() => OnClickAssignAttendee()}
              />
            </Grid>
          </Grid>
        )}
        {showAssign && (
          <>
            <Grid
              container
              item
              lg={12}
              xs={12}
              sm={12}
              md={4}
              mt={3}
              ml={0.5}
              className="selectUserStyle"
            >
              <Grid item lg={12} xs={12} sm={12} md={4} textAlign="left">
                <div className="headingstyle">Select User Type</div>
              </Grid>
            </Grid>
            <Grid item container mt={0.5} spacing={2} className="jhaReport">
              <Grid item lg={12} xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    <RadioButton
                      checked={userType === "0"}
                      value="0"
                      label="Individual"
                      onChange={(e) => handleUserType(e.target.value)}
                    ></RadioButton>

                    <RadioButton
                      checked={userType === "1"}
                      value="1"
                      label="Group"
                      onChange={(e) => handleUserType(e.target.value)}
                    ></RadioButton>
                    <RadioButton
                      checked={userType === "2"}
                      value="2"
                      label="Additional Attendees"
                      onChange={(e) => handleUserType(e.target.value)}
                    ></RadioButton>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {userType === "0" && (
              <>
                {assetType == "1" && (
                  <>
                    <Grid container item mt={3}>
                      <Grid item lg={5} md={6} sm={9} xs={12} pl={0.5}>
                        <FormLabels label="Add Attendees" />
                        <SearchAutoComplete
                          name="Add Attendees"
                          optionsData={
                            isAutoSearchDataLoading ? [] : allUsers?.users ?? []
                          }
                          typeOnchange={(event, value) =>
                            typeOnchange(event, value, USER_TYPE.NONPRIVILEGED)
                          }
                          isKeyValuePair={true}
                          value={usersValue}
                          onChange={(e, value) => onSelectUsers(value)}
                          isLoading={isAutoSearchDataLoading}
                          autoCompClear={autoCompUser}
                        />
                      </Grid>

                      <Grid
                        className="smallAddBtn"
                        item
                        lg={6}
                        md={9}
                        sm={12}
                        xs={12}
                        mt={3.5}
                        ml={2}
                      >
                        <Buttons
                          aria-describedby="dd"
                          variant="contained"
                          type="button"
                          name="btn"
                          label={"Add"}
                          onClick={() => addUserData()}
                          id="btnAddEquipment"
                          varientAddIconBlue={true}
                        ></Buttons>
                      </Grid>
                    </Grid>
                    <Grid container item mt={3}>
                      <Grid item lg={10} md={10} sm={10} xs={12}>
                        <AttendeeList
                          hiddenAttendeeSignatureInput={
                            hiddenAttendeeSignatureInput
                          }
                          removeImage={removeImage}
                          attendeesignatureUpload={attendeesignatureUpload}
                          userList={userList}
                          handleDelete={(e) => deleteData(e)}
                          attendeeSignature={attendeeSignature}
                          setImageCheck={setImageCheck}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {assetType == "4" && (
                  <>
                    <Grid container item mt={3}>
                      <Grid item lg={5} md={6} sm={9} xs={12} pl={0.5}>
                        <FormLabels label="Add Attendees" />
                        <SearchAutoComplete
                          name="Add Attendees"
                          optionsData={
                            isAutoSearchDataLoading ? [] : allUsers?.users ?? []
                          }
                          typeOnchange={(event, value) =>
                            typeOnchange(event, value, USER_TYPE.EMPLOYEE)
                          }
                          isKeyValuePair={true}
                          value={users}
                          onChange={(e, value) => setUsers(value)}
                          isLoading={isAutoSearchDataLoading}
                          autoCompClear={autoCompUser}
                          noOptionsTextMsg="No user"
                        />
                      </Grid>

                      <Grid
                        className="smallAddBtn"
                        item
                        lg={1.5}
                        md={9}
                        sm={12}
                        xs={12}
                        mt={3.5}
                        ml={2}
                      >
                        <Buttons
                          aria-describedby="dd"
                          variant="contained"
                          type="button"
                          name="btn"
                          label={"Add"}
                          onClick={() => addUserData()}
                          id="btnAddEquipment"
                          varientAddIconBlue={true}
                        ></Buttons>
                      </Grid>
                    </Grid>
                    <Grid container item mt={3}>
                      <Grid item lg={10} md={10} sm={10} xs={12}>
                        <AttendeeList
                          hiddenAttendeeSignatureInput={
                            hiddenAttendeeSignatureInput
                          }
                          removeImage={removeImage}
                          attendeesignatureUpload={attendeesignatureUpload}
                          userList={userList}
                          handleDelete={(e) => deleteData(e)}
                          attendeeSignature={attendeeSignature}
                          setImageCheck={setImageCheck}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}

            {userType === "1" && (
              <Grid item container mt={1} spacing={2}>
                <Grid item lg={5} xs={12} sm={12} md={4}>
                  <FormLabels label="Add User Group" isRequired={false} />
                  <SearchAutoComplete
                    name="Add User Group"
                    optionsData={isLoading ? [] : userGroups?.groupList ?? []}
                    typeOnchange={(event) =>
                      handleAllGroupSearch(event.target.value, 0, true,assetType,assetType === 1 ? projectName : asset )
                    }
                    isKeyValuePair={true}
                    value={usersGroup}
                    onChange={(e, value) => onChangeUsersGroup(value)}
                    isLoading={isLoading}
                    autoCompClear={autoComp}
                    noOptionsTextMsg="No group"
                  />
                </Grid>
                <Grid
                  className="smallAddBtn"
                  item
                  lg={1.5}
                  xs={12}
                  sm={12}
                  mt={4}
                  md={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    onClick={() => addGroupUserData()}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                  ></Buttons>
                </Grid>
                <Grid container item mt={3}>
                  <Grid item lg={10} md={10} sm={10} xs={12}>
                    <AttendeeList
                      hiddenAttendeeSignatureInput={
                        hiddenAttendeeSignatureInput
                      }
                      removeImage={removeImage}
                      attendeesignatureUpload={attendeesignatureUpload}
                      userList={userList}
                      handleDelete={(e) => deleteData(e)}
                      attendeeSignature={attendeeSignature}
                      setImageCheck={setImageCheck}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {userType === "2" && (
              <Grid item container mt={1} spacing={2}>
                <Grid item lg={5} md={8} xs={8} mb={2}>
                  <InputField
                    type="text"
                    // isRequired={true}
                    value={adduser}
                    onChange={(e) => onChangeAdduser(e)}
                    label="Additional Attendees"
                    placeholder="Enter User Name Here..."
                  />
                </Grid>

                <Grid
                  className="smallAddBtn"
                  item
                  lg={1.5}
                  md={3}
                  xs={3}
                  ml={3}
                  mt={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                    onClick={addAdditionalUser}
                  ></Buttons>
                </Grid>
                <Grid container item mt={3}>
                  <Grid item lg={10} md={10} sm={10} xs={12}>
                    <AttendeeList
                      hiddenAttendeeSignatureInput={
                        hiddenAttendeeSignatureInput
                      }
                      removeImage={removeImage}
                      attendeesignatureUpload={attendeesignatureUpload}
                      userList={userList}
                      handleDelete={(e) => deleteData(e)}
                      attendeeSignature={attendeeSignature}
                      setImageCheck={setImageCheck}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container mt={3} spacing={1} ml={0.1}>
              <Grid lg={3.8} xs={12} sm={4} md={4} mt={1.5}>
                <Buttons
                  varientContained={true}
                  label={"Photo Verification"}
                  onClick={() => OnClickVerifyAll()}
                />
              </Grid>
            </Grid>
            {showVerify && (
              <Grid item container mt={2} spacing={2}>
                <Grid item lg={10} xs={12} sm={12} md={8}>
                  <>
                    <FormDropZone
                      paraText1={"Drop the files here ..."}
                      paraText2={"Drop Images here, "}
                      paraText3={"or Browse"}
                      handleFileChange={handleFileChange}
                      deleteImageURL={(url) => handleDeleteImageURL(url)}
                      isDynamics={true}
                      isRequired={true}
                      fileType={10}
                      isMuliple={true}
                      imageUrl={photos}
                      id="addPhoto"
                      key="addPhoto"
                      isDoc={false}
                      isImage={true}
                      allFiles={false}
                    />
                    <p class="fileTypeSupport">Supported file types: images</p>
                  </>
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid container mt={3} spacing={1}>
          <Grid lg={2} xs={12} sm={4} md={4}>
            <div className="">
              <BackBtn dataBack={dataBack} />
            </div>
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4}></Grid>
          <Grid lg={2} xs={12} sm={4} md={4} mt={1.5}>
            <Buttons
              varientText={true}
              label="Cancel"
              onClick={() => NavigateToList()}
            />
          </Grid>
          <Grid lg={3} xs={12} sm={4} md={4} mt={1.5}>
            <Buttons
              varientContained={true}
              label={"Submit"}
              onClick={() => submitData()}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ConductJha;
