import * as assetsAction from "../actions/assetsAction";
import {
  assetsType,
  deleteAssetType,
  getAssetDetailsType,
  postAddAssestType,
  updateAssetType,
} from "../constants/assetType";
import {
  deleteAsset,
  getAssetDetails,
  getAssetList,
  postAddAssest,
  updateAsset,
  getAssetListDynamo,
  getUserAssetList,
} from "../../services/assetsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* assetListSaga(params) {
  try {
    const { data, status, error } = yield call(getAssetList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = assetsType.CONTACT_PAGE;
    if (params.data.isSendMail) {
      yield put(assetsAction.getAssetListEmailSuccess());
    } else {
      yield put(
        assetsAction.getAssetListSuccess({
          page,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Asset List Failed" };
    yield put(assetsAction.getAssetListSuccess(data));
  }
}

export function* postAddAssestSaga(params) {
  try {
    const { data, status, error } = yield call(postAddAssest, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      assetsAction.postAddAssestSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Closed Ticket Failed" };
    yield put(assetsAction.postAddAssestFailure(data));
  }
}

function* deleteAssetSaga(payload) {
  try {
    const { data, status, error } = yield call(deleteAsset, payload);
    if (!data && status !== 200) throw apiError(error);
    const assetPage = deleteAssetType.ASSET_PAGE;
    yield put(
      assetsAction.deleteAssetSuccess({
        assetPage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Asset Failed" };
    yield put(assetsAction.deleteAssetFailure(data));
  }
}

export function* getAssetDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getAssetDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = getAssetDetailsType.ASSET_DETAILS_PAGE;
    yield put(
      assetsAction.getAssetDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Asset Details Failed" };
    yield put(assetsAction.getAssetDetailsSuccess(data));
  }
}

export function* updateAssetSaga(params) {
  try {
    const { data, status, error } = yield call(updateAsset, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      assetsAction.updateAssetSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Asset updation Failed" };
    yield put(assetsAction.updateAssetFailure(data));
  }
}

export function* getAssetListDynamoSaga(params) {
  console.debug("getAssetDynomoSaga");
  try {
    const { data, status, error } = yield call(getAssetListDynamo, params);
    if (!data && status !== 200) throw apiError(error);
    const page = assetsType.GET_ASSET_DYNOMO_LIST_PAGE;
    yield put(
      assetsAction.getAssetListDynomoSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Asset Dynamo List Failed" };
    yield put(assetsAction.getAssetListDynomoFailure(data));
  }
}
export function* getUserAssetListSaga(params) {
  try {
    const { data, status, error } = yield call(getUserAssetList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = assetsType.GET_USER_ASSET_LIST_PAGE;
    yield put(
      assetsAction.getUserAssetListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get User Asset List Failed" };
    yield put(assetsAction.getUserAssetListFailure(data));
  }
}

const myAssetsSaga = [
  takeLatest(assetsType.GET_ASSET_LIST, assetListSaga),
  takeLatest(postAddAssestType.POST_ADD_ASSET_PENDING, postAddAssestSaga),
  takeLatest(deleteAssetType.DELETE_ASSET_PENDING, deleteAssetSaga),
  takeLatest(getAssetDetailsType.GET_ASSET_DETAILS, getAssetDetailsSaga),
  takeLatest(updateAssetType.UPDATE_ASSET_PENDING, updateAssetSaga),
  takeLatest(assetsType.GET_ASSET_DYNOMO_LIST, getAssetListDynamoSaga),
  takeLatest(assetsType.GET_USER_ASSET_LIST, getUserAssetListSaga),
];

export default myAssetsSaga;
