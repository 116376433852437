import { DataArrayTwoTone } from "@mui/icons-material";
import { lmsType } from "../constants/lmsType";

export const getCoursesList = (data) => ({
  type: lmsType.GET_COURSES_LIST_PENDING,
  data,
});

export const getCoursesListSuccess = (data) => ({
  type: lmsType.GET_COURSES_LIST_SUCCESS,
  data,
});

export const getCoursesListEmailSuccess = (data) => ({
  type: lmsType.GET_COURSES_LIST_EMAIL_SUCCESS,
  data,
});

export const getCoursesListFailure = (error) => ({
  type: lmsType.GET_COURSES_LIST_FAILURE,
  error,
});

export const getLessonsList = (data) => ({
  type: lmsType.GET_LESSONS_LIST_PENDING,
  data,
});

export const getLessonsListSuccess = (data) => ({
  type: lmsType.GET_LESSONS_LIST_SUCCESS,
  data,
});

export const getLessonsListEmailSuccess = (data) => ({
  type: lmsType.GET_LESSONS_LIST_EMAIL_SUCCESS,
  data,
});

export const getLessonsListFailure = (data) => ({
  type: lmsType.GET_LESSONS_LIST_FAILURE,
  data,
});

export const getCategoryList = (data) => ({
  type: lmsType.GET_CATEGORY_LIST_PENDING,
  data,
});

export const getCategoryListSuccess = (data) => ({
  type: lmsType.GET_CATEGORY_LIST_SUCCESS,
  data,
});

export const getCategoryListFailure = (error) => ({
  type: lmsType.GET_CATEGORY_LIST_FAILURE,
  error,
});

export const createCourses = (data) => ({
  type: lmsType.POST_COURSES_PENDING,
  data,
});

export const createCoursesSuccess = (data) => ({
  type: lmsType.POST_COURSES_SUCCESS,
  data,
});

export const createCoursesFailure = (error) => ({
  type: lmsType.POST_COURSES_FAILURE,
  error,
});

export const updateCoursesData = (payload) => ({
  type: lmsType.UPDATE_COURSES_PENDING,
  payload: payload,
});

export const updateCoursesSuccess = (data) => ({
  type: lmsType.UPDATE_COURSES_SUCCESS,
  data,
});

export const updateCoursesFailure = (error) => ({
  type: lmsType.UPDATE_COURSES_FAILED,
  error,
});

export const getLessonDropDownList = (data) => ({
  type: lmsType.GET_LESSON_DROP_DOWN_LIST_PENDING,
  data,
});

export const getLessonDropDownListSuccess = (data) => ({
  type: lmsType.GET_LESSON_DROP_DOWN_LIST_SUCCESS,
  data,
});

export const getLessonDropDownListFailure = (error) => ({
  type: lmsType.GET_LESSON_DROP_DOWN_LIST_FAILURE,
  error,
});

export const revokeDataUnmount = () => ({
  type: lmsType.REVOKE_DATA_UNMOUNT,
});

// GET REPRIMABND DETAILS
export const getCourseDetails = (id) => ({
  type: lmsType.GET_COURSE_DETAILS_PENDING,
  payload: id,
});

export const getLessonDetails = (id) => ({
  type: lmsType.GET_LESSON_DETAILS_PENDING,
  payload: id,
});

// delete

export const deleteCourse = (payload) => ({
  type: lmsType.DELETE_COURSE_PENDING,
  payload: payload,
});

export const deleteCourseSuccess = (payload) => ({
  type: lmsType.DELETE_COURSE_SUCCESS,
  payload,
});

export const deleteCourseFailure = (error) => ({
  type: lmsType.DELETE_COURSE_FAILED,
  error,
});

export const createLesson = (data) => ({
  type: lmsType.POST_LESSON_PENDING,
  data,
});

export const createLessonSuccess = (data) => ({
  type: lmsType.POST_LESSON_SUCCESS,
  data,
});

export const createLessonFailure = (error) => ({
  type: lmsType.POST_LESSON_FAILURE,
  error,
});

export const updateLessonsData = (payload) => ({
  type: lmsType.UPDATE_LESSON_PENDING,
  payload: payload,
});

export const updateLessonsSuccess = (data) => ({
  type: lmsType.UPDATE_LESSON_SUCCESS,
  data,
});

export const updateLessonsFailure = (error) => ({
  type: lmsType.UPDATE_LESSON_FAILED,
  error,
});

export const deleteLesson = (payload) => ({
  type: lmsType.DELETE_LESSON_PENDING,
  payload: payload,
});

export const deleteLessonSuccess = (payload) => ({
  type: lmsType.DELETE_LESSON_SUCCESS,
  payload,
});

export const deleteLessonFailure = (error) => ({
  type: lmsType.DELETE_LESSON_FAILED,
  error,
});

export const publishLesson = (data) => ({
  type: lmsType.POST_PUBLISH_LESSON_PENDING,
  data,
});

export const publishLessonSuccess = (data) => ({
  type: lmsType.POST_PUBLISH_LESSON_SUCCESS,
  data,
});

export const publishLessonFailure = (error) => ({
  type: lmsType.POST_PUBLISH_LESSON_FAILURE,
  error,
});

export const publishCourse = (data) => ({
  type: lmsType.POST_PUBLISH_COURSES_PENDING,
  data,
});

export const publishCourseSuccess = (data) => ({
  type: lmsType.POST_PUBLISH_COURSES_SUCCESS,
  data,
});

export const publishCourseFailure = (error) => ({
  type: lmsType.POST_PUBLISH_COURSES_FAILURE,
  error,
});

export const getAssignedList = (data) => ({
  type: lmsType.GET_COURSE_ASSIGN_LIST_PENDING,
  data,
});

export const getAssignedListSuccess = (data) => ({
  type: lmsType.GET_COURSE_ASSIGN_LIST_SUCCESS,
  data,
});

export const getAssignedListFailure = (error) => ({
  type: lmsType.GET_COURSE_ASSIGN_LIST_FAILURE,
  error,
});

export const getAssignedListClear = (data) => ({
  type: lmsType.GET_COURSE_ASSIGN_LIST_CLEAR,
  DataArrayTwoTone,
});

export const assignLesson = (data) => ({
  type: lmsType.POST_ASSIGN_LESSON_PENDING,
  data,
});

export const assignLessonSuccess = (data) => ({
  type: lmsType.POST_ASSIGN_LESSON_SUCCESS,
  data,
});

export const assignLessonFailure = (error) => ({
  type: lmsType.POST_ASSIGN_LESSON_FAILURE,
  error,
});

export const assignCourse = (data) => ({
  type: lmsType.POST_ASSIGN_COURSE_PENDING,
  data,
});

export const assignCourseSuccess = (data) => ({
  type: lmsType.POST_ASSIGN_COURSE_SUCCESS,
  data,
});

export const assignCourseFailure = (error) => ({
  type: lmsType.POST_ASSIGN_COURSE_FAILURE,
  error,
});

export const getAssignedLessonList = (data) => ({
  type: lmsType.GET_LESSON_ASSIGN_LIST_PENDING,
  data,
});

export const getAssignedLessonListSuccess = (data) => ({
  type: lmsType.GET_LESSON_ASSIGN_LIST_SUCCESS,
  data,
});

export const getAssignedLessonListFailure = (error) => ({
  type: lmsType.GET_LESSON_ASSIGN_LIST_FAILURE,
  error,
});

export const getAssignedLessonListClear = (error) => ({
  type: lmsType.GET_LESSON_ASSIGN_LIST_CLEAR,
  error,
});

export const completeLesson = (data) => ({
  type: lmsType.POST_COMPLETE_LESSON_PENDING,
  data,
});

export const completeLessonSuccess = (data) => ({
  type: lmsType.POST_COMPLETE_LESSON_SUCCESS,
  data,
});

export const completeLessonFailure = (error) => ({
  type: lmsType.POST_COMPLETE_LESSON_FAILURE,
  error,
});

// alert mail actions==================================

export const getAlertmailAssigncourseLesson = (data) => ({
  type: lmsType.GET_LESSON_ASSIGN_ALERT_LIST_PENDING,
  //payload: id,
  data,
});

export const getAlertmailAssigncourseLessonSuccess = (data) => ({
  type: lmsType.GET_LESSON_ASSIGN_ALERT_LIST_SUCCESS,
  data,
});

export const getAlertmailAssigncourseLessonFailure = (error) => ({
  type: lmsType.GET_LESSON_ASSIGN_ALERT_LIST_FAILURE,
  error,
});
// completed status check ============

export const getCompletedLessonStatuscheck = (data) => ({
  type: lmsType.GET_LESSONS_COMPLETED_STATUS_PENDING,
  data,
});

export const getCompletedLessonStatuscheckSuccess = (data) => ({
  type: lmsType.GET_LESSONS_COMPLETED_STATUS_SUCCESS,
  data,
});

export const getCompletedLessonStatuscheckFailure = (error) => ({
  type: lmsType.GET_LESSONS_COMPLETED_STATUS_FAILURE,
  error,
});
export const clearAssignlessonStatus = () => ({
  type: lmsType.CLEAR_ASSIGN_LESSON_STATUS,
  data: "",
});

// unassign lesson action

export const unAssignLesson = (data) => ({
  type: lmsType.POST_LESSON_UNASSIGN_PENDING,
  data,
});

export const unAssignLessonSuccess = (data) => ({
  type: lmsType.POST_LESSON_UNASSIGN_SUCCESS,
  data,
});

export const unAssignLessonFailure = (error) => ({
  type: lmsType.POST_LESSON_UNASSIGN_FAILURE,
  error,
});

// unassign Course action

export const unAssignCourse = (data) => ({
  type: lmsType.POST_COURSE_UNASSIGN_PENDING,
  data,
});

export const unAssignCourseSuccess = (data) => ({
  type: lmsType.POST_COURSE_UNASSIGN_SUCCESS,
  data,
});

export const unAssignCourseFailure = (error) => ({
  type: lmsType.POST_COURSE_UNASSIGN_FAILURE,
  error,
});

export const getCompletedCourseStatuscheck = (data) => ({
  type: lmsType.GET_COURSES_COMPLETED_STATUS_PENDING,
  data,
});

export const getCompletedCourseStatuscheckSuccess = (data) => ({
  type: lmsType.GET_COURSES_COMPLETED_STATUS_SUCCESS,
  data,
});

export const getCompletedCourseStatuscheckFailure = (error) => ({
  type: lmsType.GET_COURSES_COMPLETED_STATUS_FAILURE,
  error,
});
export const clearAssigncourseStatus = () => ({
  type: lmsType.CLEAR_ASSIGN_COURSE_STATUS,
  data: "",
});
//UNENROLL

export const unEnrollLesson = (data) => ({
  type: lmsType.POST_LESSON_UNENROLL_PENDING,
  data,
});

export const unEnrollLessonSuccess = (data) => ({
  type: lmsType.POST_LESSON_UNENROLL_SUCCESS,
  data,
});

export const unEnrollLessonFailure = (error) => ({
  type: lmsType.POST_LESSON_UNENROLL_FAILURE,
  error,
});

export const unEnrollCourse = (data) => ({
  type: lmsType.POST_COURSE_UNENROLL_PENDING,
  data,
});

export const unEnrollCourseSuccess = (data) => ({
  type: lmsType.POST_COURSE_UNENROLL_SUCCESS,
  data,
});

export const unEnrollCourseFailure = (error) => ({
  type: lmsType.POST_COURSE_UNENROLL_FAILURE,
  error,
});

// lESSON sEARCH
export const searchLessons = (searchText) => ({
  type: lmsType.GET_SEARCH_LESSONS,
  searchText,
});

export const searchLessonsSuccess = (data) => ({
  type: lmsType.GET_SEARCH_LESSONS_SUCCESS,
  data,
});

export const searchLessonsFailure = (error) => ({
  type: lmsType.GET_SEARCH_LESSONS_FAILURE,
  error,
});

// Course sEARCH
export const searchCourses = (searchText) => ({
  type: lmsType.GET_SEARCH_COURSES,
  searchText,
});

export const searchCoursesSuccess = (data) => ({
  type: lmsType.GET_SEARCH_COURSES_SUCCESS,
  data,
});

export const searchCoursesFailure = (error) => ({
  type: lmsType.GET_SEARCH_COURSES_FAILURE,
  error,
});

// MAPPED gLOBAL Lesson api call
export const getMappedGlobalLessonList = (data) => ({
  type: lmsType.MAPPED_GLOBAL_LESSON_PENDING,
  data,
});

export const getMappedGlobalLessonListSuccess = (data) => ({
  type: lmsType.GET_MAPPED_GLOBAL_LESSON_SUCCESS,
  data,
});

export const getMappedGlobalLessonListFailure = (error) => ({
  type: lmsType.GET_MAPPED_GLOBAL_LESSON_FAILURE,
  error,
});
export const clearGetMappedGlobalLessonList = () => ({
  type: lmsType.CLEAR_DATA_MAPPING_LMSSTATUS,
  dataresult: "",
});

// MAPPED gLOBAL Course api call
export const getMappedGlobalCourseList = (data) => ({
  type: lmsType.MAPPED_GLOBAL_COURSE_PENDING,
  data,
});

export const getMappedGlobalCourseListSuccess = (data) => ({
  type: lmsType.GET_MAPPED_GLOBAL_COURSE_SUCCESS,
  data,
});

export const getMappedGlobalCourseListFailure = (error) => ({
  type: lmsType.GET_MAPPED_GLOBAL_COURSE_FAILURE,
  error,
});
// LESSON  View Client
export const specifyLessonToUserViewClient = (data, clientId) => ({
  type: lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_CLIENT_PENDING,
  data,
  clientId,
});

export const specifyLessonToUserViewClientSuccess = (data) => ({
  type: lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_CLIENT_SUCCESS,
  data,
});

export const specifyLessonToUserViewClientFailure = (error) => ({
  type: lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_CLIENT_FAILURE,
  error,
});

// LESSON  View Lesson
export const specifyLessonToUserViewLesson = (data, lessonId) => ({
  type: lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_LESSON_PENDING,
  data,
  lessonId,
});

export const specifyLessonToUserViewLessonSuccess = (data) => ({
  type: lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_LESSON_SUCCESS,
  data,
});

export const specifyLessonToUserViewLessonFailure = (error) => ({
  type: lmsType.POST_SPECIFY_LESSON_TOUSER_VIEW_LESSON_FAILURE,
  error,
});

// Course  View Client
export const specifyCourseToUserViewClient = (data, clientId) => ({
  type: lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_CLIENT_PENDING,
  data,
  clientId,
});

export const specifyCourseToUserViewClientSuccess = (data) => ({
  type: lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_CLIENT_SUCCESS,
  data,
});

export const specifyCourseToUserViewClientFailure = (error) => ({
  type: lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_CLIENT_FAILURE,
  error,
});

// Course  View Course
export const specifyCourseToUserViewCourse = (data, courseId) => ({
  type: lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_COURSE_PENDING,
  data,
  courseId,
});

export const specifyCourseToUserViewCourseSuccess = (data) => ({
  type: lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_COURSE_SUCCESS,
  data,
});

export const specifyCourseToUserViewCourseFailure = (error) => ({
  type: lmsType.POST_SPECIFY_COURSE_TOUSER_VIEW_COURSE_FAILURE,
  error,
});
