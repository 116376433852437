import React from "react";
import AddFiles from "../component/utilityAdmin/addFiles";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";

function AddFilesContainer() {
  const navigate = useNavigate();
  return (
    <>{checkPrivileges([12, 13]) ? <AddFiles /> : navigate(ACCESS_DENIED)}</>
  );
}

export default AddFilesContainer;
