import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { Doughnut, Pie, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useThemeContext } from "../../../../context";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  LineElement,

  PointElement
);
const DonoughtChart = ({
  dashboardTickets,
  itemListData,
  DonutChart,
  PieChart1,
  PieChartReport,
  lmsReportData,
  SwItemPieChart,
  LineChart,
  datahistoricalData,
}) => {
  const { mode } = useThemeContext();
  const graphData = {
    labels: ["Open", "In Progress", "Closed"],
    datasets: [
      {
        label: "Tickets",
        data: [
          dashboardTickets?.openTickets,
          dashboardTickets?.inProgressTickets,
          dashboardTickets?.closedTickets,
        ],
        backgroundColor: ["#276088", "#F69326", "#34A853"],
        borderColor: ["#276088", "#F69326", "#34A853"],
        borderWidth: 1,
        borderRadius: "25px",
      },
    ],
  };
  // for Rainfall LineChart
  const RainfallDate = datahistoricalData?.map((item) => item.precipDateFormat);

  const RainFallAmount = datahistoricalData?.map(
    (item) => item.evenTrackPrecipAmt
  );

  const LineData = {
    labels: RainfallDate,
    datasets: [
      {
        label: "Rain Amt",
        data: RainFallAmount,
        backgroundColor: "white",
        borderColor: "aqua",
        pointBorderColor: "aqua",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const PieChart = {
    datasets: [
      {
        data: [
          itemListData?.result?.noOfViolation,
          itemListData?.result?.noOfKudos,
          itemListData?.result?.noOfReprimand,
          itemListData?.result?.noOfAction,
        ],
        backgroundColor: ["#F69326", "#34A853", "#D30D25", "#276088"],
        borderColor: ["#F69326", "#34A853", "#D30D25", "#276088"],
        borderWidth: 1,
        borderRadius: "25px",
      },
    ],
    labels: ["Corrective", "Kudos", "Reprimand", "Action"],
  };

  const SwItemValuePieChart = {
    datasets: [
      {
        data: [
          itemListData?.totalFourDaysOpen,
          itemListData?.totalSevenDaysOpen,
          itemListData?.totalMoreThanSevenDaysOpen,
        ],
        backgroundColor: ["#FFCC4D", "#FF3600", "#D30D25"],
        borderColor: ["#FFCC4D", "#FF3600", "#D30D25"],
        borderWidth: 1,
        borderRadius: "25px",
      },
    ],
    labels: ["Open 1-4 Days", "Open 4-7 Days", "Open > 7 Days"],
  };

  const PieChartReportData = {
    datasets: [
      {
        data: [
          lmsReportData?.Completed,
          lmsReportData?.InProgress,
          lmsReportData?.InCompleted,
        ],
        backgroundColor: ["#F69326", "#34A853", "#D30D25"],
        borderColor: ["#F69326", "#34A853", "#D30D25"],
        borderWidth: 1,
        borderRadius: "25px",
      },
    ],
    labels: ["Completed", "In Progress", "Incomplete"],
  };

  const options = {
    tooltips: {
      enabled: false,
    },
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          useBorderRadius: true,
          pointStyleWidth: 11,
          boxHeight: 8,
          height: "5px",
          borderWidth: 1,
          padding: 20,
          font: {
            size: "12px",
            weight: "500",
          },
          color: mode === "dark" ? "#fff" : "#333333",
        },
      },

      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage === "0%" ? "" : percentage;
        },
        color: mode === "dark" ? "#fff" : "#333333",
        font: {
          size: 12,
          weight: 500,
        },
      },

      onHover: function (e) {
        e.native.target.style.cursor = "pointer";
      },
      rotation: 270,
      events: ["click"],
      onClick: function (event, element) {},
    },
  };

  const LineOptions = {
    ...options,

    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Date",
          color: "black",
          font: {
            size: 14,
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Rain Amount(Inches)",
          color: "black",
          font: {
            size: 14,
          },
        },
        min: 0,
      },
    },
    plugins: {
      ...options.plugins,
      legend: false,
    },
  };

  return (
    <>
      {DonutChart && <Doughnut data={graphData} options={options} />}
      {PieChart1 && <Pie data={PieChart} options={options} />}
      {PieChartReport && <Pie data={PieChartReportData} options={options} />}

      {SwItemPieChart && <Pie data={SwItemValuePieChart} options={options} />}
      {LineChart && <Line data={LineData} options={LineOptions} />}
    </>
  );
};

export default DonoughtChart;
