import React, { useEffect, useState } from "react";
import AddCategory from "../components/addCategory";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { PAGE_SIZE } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../../constant/routeContant";

const SwInspectionCategoriesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultData = {
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
  };

  const getCategoriesList = useSelector((state) => state.getSwCategoryList);

  const getCategoriesListData = (data) => {
    dispatch(swInspectionAction.getSwCategoryList(data));
  };

  const createSwCategory = useSelector((state) => state.createSwCategory);
  const updateSwCategory = useSelector((state) => state.updateSwCategory);

  const isLoading = useSelector((state) => state.getSwCategoryList?.isLoading);
  const showLoader = useSelector(
    (state) =>
      state.createSwCategory?.isLoading || state.updateSwCategory?.isLoading
  );
  const [clearCategory, setClearCategory] = useState(false);
  const [clearUpdateCategory, setClearUpdateCategory] = useState(false);

  const updateSwCategoryData = (data) => {
    setClearUpdateCategory(true);
    dispatch(swInspectionAction.updateSwCategory(data));
  };

  const onSubmit = (data) => {
    setClearCategory(true);
    dispatch(swInspectionAction.createSwCategory(data));
  };
  useEffect(() => {
    if (
      createSwCategory?.status === "SUCCESS" &&
      createSwCategory?.isLoading === false &&
      clearCategory
    ) {
      toast("Category Created successfully");
    }
  }, [createSwCategory]);

  useEffect(() => {
    if (
      updateSwCategory?.status === "SUCCESS" &&
      updateSwCategory?.isLoading === false &&
      clearUpdateCategory
    ) {
      toast("Category Updated successfully");
    }
  }, [updateSwCategory]);

  useEffect(() => {
    getCategoriesListData(defaultData);
  }, []);

  return (
    <>
      {checkPrivileges([12, 93, 94, 95]) ? (
        <>
          {showLoader && <Loader />}

          <AddCategory
            getCategoriesList={
              isLoading ? [] : getCategoriesList?.data?.result?.categoryList
            }
            recordsCount={getCategoriesList?.data?.result?.categoryCount}
            getCategoriesListData={getCategoriesListData}
            onSubmit={onSubmit}
            createSwCategory={createSwCategory}
            updateSwCategoryData={updateSwCategoryData}
            isLoading={isLoading}
            updateSwCategory={updateSwCategory}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default SwInspectionCategoriesContainer;
