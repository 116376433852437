import vehicleAccident from "../constant/accidentConstant";
import {
  API_STATUS,
  GET_COUNT_TYPE,
  ORG_ID,
  QUESTION_ANSWER_RESPONSE_TYPE,
  QUESTION_RESPONSE_TYPE_VALUE,
} from "../constant/constant";
import { getFormatedDate } from "./helper";
import { checkPrivileges } from "./rolesHelper";
const HBNUser = checkPrivileges([12, 139, 144]);

export const listInspectionCategoryObject = (data) => {
  return data?.map((categoryItem) => {
    return {
      key: categoryItem.key,
      value: categoryItem.value,
      catPercent: 0,
      howManyCat: 0,
      catAns: false,
      catError: false,
      sequenceNumber: categoryItem.sequenceNumber,
      isSkippable: categoryItem?.isSkippable,
      isSkipped: categoryItem?.isSkippable === false ? null : false,
      listInspectionSubCategory: categoryItem.subcategories?.map(
        (subCategoryItem) => {
          return {
            key: subCategoryItem.key,
            isAnswerCat: false,
            subCatError: false,
            howManySub: 0,
            value: subCategoryItem.value,
            subPercent: 0,
            sequenceNumber: subCategoryItem.sequenceNumber,
            showBth: subCategoryItem.questions.some(
              (findItem) => !findItem.isTextResponse
            ),
            isSkippable: subCategoryItem?.isSkippable,
            isSkipped: subCategoryItem?.isSkippable === false ? null : false,
            subCatAns: false,
            listInspectionQuestionAnswer: subCategoryItem.questions
              ?.filter((x) => x.isSelected)
              ?.map((questionItem) => {
                const dataForm = {
                  acceptable: [
                    {
                      id: Date.now() + Math.floor(Math.random() * 100),
                      comment: "",
                      files: "",
                      media: [],
                      involdevPaty: "",
                      employee: [],
                      employeeCurrent: "",
                      subcontractor: [],
                      subcontractorCurrent: "",
                      projectReq: "",
                    },
                  ],
                  notacceptable: [
                    {
                      id: Date.now() + Math.floor(Math.random() * 100),
                      comment: "",
                      involdevPaty: "",
                      employee: [],
                      employeeCurrent: "",
                      subcontractor: [],
                      subcontractorCurrent: "",
                      escalatetoreprimandIsChecked: "No",
                      escalatetoreprimandComment: "",
                      itemStatusIsChecked: "",
                      itemStatusComment: "",
                      itemStatusCommentUser: "",
                      itemStatusCommentUserCurrent: "",
                      files: "",
                      media: [],
                      empCount: [],
                      projectReq: "",
                    },
                  ],
                  notapplicable: [],
                };

                return {
                  key: questionItem.key,
                  answerMstId: questionItem.responsetype,
                  isAnswerSubCat: false,
                  quesNo: questionItem.index,
                  value: questionItem.value,
                  options: questionItem.optionList,
                  responsetype: questionItem.responsetype,
                  questionSequenceNumber: questionItem.questionSequenceNumber,
                  radioSelectiovalue: "",
                  textValueQuestion: "",
                  singleSelect: 0,
                  multiSelect: [],
                  textAreaDetail: "",
                  responsData: dataForm,
                  isQuestionComplete: false,
                  isQuestionError: false,
                  isAnswerQuestion: false,
                  involvedPartyShow: questionItem?.isInvolvedParty,
                };
              }),
          };
        }
      ),
    };
  });
};

export const formchangeHandler = (
  targetValue,
  questionValue,
  subCategoryValue,
  categoryValue,
  targetName,
  notAppce,
  listInspectionCategory,
  setListInspectionCategory,
  inspectionViolationCounts,
  inspectionViolationCountsData
) => {
  const listInspectionCategoryClone = [...listInspectionCategory];
  listInspectionCategoryClone?.forEach((category) => {
    if (category?.key === categoryValue?.key) {
      category.listInspectionSubCategory?.forEach((subCategory) => {
        if (subCategoryValue?.key === subCategory?.key) {
          subCategory.isAnswerCat = true;
          subCategory.listInspectionQuestionAnswer?.forEach((question) => {
            if (parseInt(question?.key) === parseInt(questionValue?.key)) {
              question.isAnswerQuestion = true;
              if (targetName === "textAreaDetail") {
                question.isAnswerSubCat = true;
                question.textAreaDetail = targetValue;
              }
              if (targetName === "multiSelect") {
                question.isAnswerSubCat = true;
                question.multiSelect = targetValue;
              }
              if (targetName === "radioSelectiovalue") {
                question.isAnswerSubCat = true;
                question.radioSelectiovalue = targetValue;
              }
              if (targetName === "singleSelect") {
                question.isAnswerSubCat = true;
                question.singleSelect = targetValue;
              }
              if (targetName === "deleteKudos") {
                if (question?.responsData?.acceptable) {
                  question.responsData.acceptable =
                    question.responsData.acceptable.filter((notAcc) => {
                      return notAcc.id !== targetValue?.id;
                    });
                }
              }
              if (targetName === "deleteCorrective") {
                if (question?.responsData?.notacceptable) {
                  question.responsData.notacceptable =
                    question.responsData.notacceptable.filter((notAcc) => {
                      return notAcc.id !== targetValue?.id;
                    });
                }
              }
              if (targetName === "addcorrectiveitem") {
                question?.responsData?.notacceptable.push({
                  id: Date.now() + Math.floor(Math.random() * 100),
                  comment: "",
                  involdevPaty: "",
                  employee: [],
                  employeeCurrent: "",
                  subcontractor: [],
                  subcontractorCurrent: "",
                  escalatetoreprimandIsChecked: "No",
                  escalatetoreprimandComment: "",
                  itemStatusIsChecked: "",
                  addAbatementNotes: "",
                  itemStatusCommentUser: "",
                  itemStatusCommentUserCurrent: "",
                  files: "",
                  media: [],
                  empCount: [],
                  projectReq: "",
                });
              }
              if (targetName === "kudos") {
                question?.responsData?.acceptable.push({
                  id: Date.now() + Math.floor(Math.random() * 100),
                  comment: "",
                  files: "",
                  media: [],
                  involdevPaty: "",
                  employee: [],
                  employeeCurrent: "",
                  subcontractor: [],
                  subcontractorCurrent: "",
                  projectReq: "",
                });
              }
              question?.responsData?.acceptable?.forEach((notAcc) => {
                if (notAcc?.id === notAppce?.id) {
                  if (targetName === "comment") {
                    notAcc.comment = targetValue;
                  }
                  if (targetName === "involvedpartytype") {
                    notAcc.involdevPaty = targetValue;
                  }
                  if (targetName === "involvedpartyemployee") {
                    if (
                      targetValue &&
                      targetValue.key &&
                      !notAcc?.employee?.some(
                        (emp) => parseInt(emp.key) === parseInt(targetValue.key)
                      )
                    ) {
                      notAcc.employee.push(targetValue);
                      notAcc.employeeCurrent = "";
                    }
                  }
                  if (targetName === "deleteEmployee") {
                    notAcc.employee = notAcc.employee.filter((notAcc) => {
                      return notAcc.key !== targetValue;
                    });
                  }
                  if (targetName === "addinvolvedcompany") {
                    if (
                      targetValue &&
                      targetValue.key &&
                      !notAcc?.subcontractor?.some(
                        (sub) => parseInt(sub.key) === parseInt(targetValue.key)
                      )
                    ) {
                      notAcc.subcontractor.push(targetValue);
                      notAcc.subcontractorCurrent = "";
                    }
                  }
                  if (targetName === "deleteSubcontractor") {
                    notAcc.subcontractor = notAcc.subcontractor.filter(
                      (notAcc) => {
                        return notAcc.key !== targetValue;
                      }
                    );
                  }
                }
              });
              question?.responsData?.notacceptable?.forEach((notAcc) => {
                if (notAcc?.id === notAppce?.id) {
                  if (targetName === "comment") {
                    notAcc.comment = targetValue;
                  }
                  if (targetName === "involvedpartytype") {
                    notAcc.involdevPaty = targetValue;
                  }
                  if (targetName === "involvedpartyemployee") {
                    if (
                      targetValue &&
                      targetValue.key &&
                      !notAcc?.employee?.some(
                        (emp) => parseInt(emp.key) === parseInt(targetValue.key)
                      )
                    ) {
                      notAcc.employee.push(targetValue);
                      notAcc.employeeCurrent = "";
                      const data = {
                        id: targetValue?.key,
                        organisationId: parseInt(ORG_ID()),
                        type: GET_COUNT_TYPE?.VIOLATION,
                      };
                      if (targetValue?.key) {
                        inspectionViolationCountsData(data);
                      }
                      if (
                        inspectionViolationCounts?.status === API_STATUS.SUCCESS
                      ) {
                        notAcc.empCount = [
                          ...notAcc.empCount,
                          {
                            count: inspectionViolationCounts?.data?.result,
                            key: targetValue?.key,
                          },
                        ];
                      }
                    }
                  }
                  if (targetName === "deleteEmployee") {
                    notAcc.employee = notAcc.employee.filter((notAcc) => {
                      return notAcc.key !== targetValue;
                    });
                    notAcc.empCount = notAcc.empCount.filter(
                      (items) => items.key !== targetValue
                    );
                  }
                  if (targetName === "addinvolvedcompany") {
                    if (
                      targetValue &&
                      targetValue.key &&
                      !notAcc?.subcontractor?.some(
                        (sub) => parseInt(sub.key) === parseInt(targetValue.key)
                      )
                    ) {
                      notAcc.subcontractor.push(targetValue);
                      notAcc.subcontractorCurrent = "";
                      const data = {
                        id: targetValue?.key,
                        organisationId: parseInt(ORG_ID()),
                        type: GET_COUNT_TYPE?.VIOLATION,
                      };
                      if (targetValue?.key) {
                        inspectionViolationCountsData(data);
                      }
                      if (
                        inspectionViolationCounts?.status === API_STATUS.SUCCESS
                      ) {
                        notAcc.empCount = [
                          ...notAcc.empCount,
                          {
                            count: inspectionViolationCounts?.data?.result,
                            key: targetValue?.key,
                          },
                        ];
                      }
                    }
                  }
                  if (targetName === "deleteSubcontractor") {
                    notAcc.subcontractor = notAcc.subcontractor.filter(
                      (notAcc) => {
                        return notAcc.key !== targetValue;
                      }
                    );
                    notAcc.empCount = notAcc.empCount.filter(
                      (items) => items.key !== targetValue
                    );
                  }
                  if (targetName === "escalatetoreprimand") {
                    notAcc.escalatetoreprimandIsChecked = targetValue;
                  }
                  if (targetName === "escalatetoreprimandComment") {
                    notAcc.escalatetoreprimandComment = targetValue;
                  }
                  if (targetName === "itemStatus") {
                    notAcc.itemStatusIsChecked = targetValue;
                  }
                  if (targetName === "addabatementnotes") {
                    notAcc.addAbatementNotes = targetValue;
                  }
                  if (targetName === "assignitemresponsibility") {
                    notAcc.itemStatusCommentUser = targetValue;
                    notAcc.itemStatusCommentUserCurrent = "";
                  }
                  if (targetName === "deleteItemStatusCommentUser") {
                    notAcc.itemStatusCommentUser = "";
                    notAcc.itemStatusCommentUserCurrent = "";
                  }
                }
              });
            }
          });
          const perQuestionSub =
            100 / subCategory?.listInspectionQuestionAnswer?.length;
          const completedSub = subCategory.listInspectionQuestionAnswer.filter(
            (items) => items.isAnswerSubCat
          );
          subCategory.subPercent = perQuestionSub * completedSub?.length;
          subCategory.howManySub = completedSub?.length;
          subCategory.subCatAns = true;
        }
      });
      const perQuestionCat = 100 / category?.listInspectionSubCategory?.length;
      const completedCat = category.listInspectionSubCategory.filter(
        (items) => items.isAnswerCat
      );
      category.catPercent = perQuestionCat * completedCat?.length;
      category.howManyCat = completedCat?.length;
      category.catAns = true;
    }
  });
  setListInspectionCategory(listInspectionCategoryClone);
};

export const fileUploadHandler = (
  files,
  single,
  questionValue,
  subCategoryValue,
  categoryValue,
  acc,
  action,
  listInspectionCategory,
  setListInspectionCategory
) => {
  const listInspectionCategoryClone = [...listInspectionCategory];
  listInspectionCategoryClone?.forEach((category) => {
    if (category?.key === categoryValue?.key) {
      category.listInspectionSubCategory?.forEach((subCategory) => {
        if (subCategoryValue?.key === subCategory?.key) {
          subCategory.listInspectionQuestionAnswer?.forEach((question) => {
            if (parseInt(question?.key) === parseInt(questionValue?.key)) {
              const responseType =
                action === "acceptable" ? "acceptable" : "notacceptable";
              question?.responsData?.[responseType]?.forEach((notAcc) => {
                if (notAcc?.id === acc?.id) {
                  if (!notAcc.files) notAcc.files = "";
                  if (!notAcc.media) notAcc.media = "";

                  if (files !== notAcc.files) {
                    notAcc.files = files;
                  }
                  if (Array.isArray(single)) {
                    single?.forEach((media) => {
                      if (!notAcc.media?.includes(media)) {
                        notAcc.media.push(media);
                      }
                    });
                  }
                }
              });
            }
          });
        }
      });
    }
  });
  setListInspectionCategory(listInspectionCategoryClone);
};

export const deleteImageHandler = (
  urls,
  index,
  questionValue,
  subCategoryValue,
  categoryValue,
  acc,
  action,
  listInspectionCategory,
  setListInspectionCategory
) => {
  const listInspectionCategoryClone = [...listInspectionCategory];

  listInspectionCategoryClone?.forEach((category) => {
    if (category?.key === categoryValue?.key) {
      category.listInspectionSubCategory?.forEach((subCategory) => {
        if (subCategoryValue?.key === subCategory?.key) {
          subCategory.listInspectionQuestionAnswer?.forEach((question) => {
            if (parseInt(question?.key) === parseInt(questionValue?.key)) {
              const responseType =
                action === "acceptable" ? "acceptable" : "notacceptable";
              question?.responsData?.[responseType]?.forEach((notAcc) => {
                if (notAcc?.id === acc?.id) {
                  const filesArray = notAcc.files.split("|");
                  const newFilesArray = filesArray.filter(
                    (url) => url !== urls
                  );
                  notAcc.files = newFilesArray.join("|");
                  if (Array.isArray(notAcc.media)) {
                    notAcc.media = notAcc.media.filter((_, i) => i !== index);
                  }
                }
              });
            }
          });
        }
      });
    }
  });

  setListInspectionCategory(listInspectionCategoryClone);
};

export const mapListInspectionCategory = (listInspectionCategory) => {
  const result = [];

  listInspectionCategory?.forEach((x) => {
    const item = {
      isSkipped: x.isSkipped,
      categoryId: x.key,
      sequenceNumber: x.sequenceNumber,
    };
    item.listInspectionSubCategory = x.listInspectionSubCategory?.map((y) => {
      const subCategory = {
        isSkipped: y.isSkipped,
        subCategoryId: y.key,
        sequenceNumber: y.sequenceNumber,
      };
      subCategory.listInspectionQuestionAnswer =
        y.listInspectionQuestionAnswer?.map((z) => {
          const questionAnswer = {
            sequenceId: z.quesNo,
            answerMstId:
              parseInt(z.responsetype) === 1
                ? parseInt(z.radioSelectiovalue) === 1
                  ? QUESTION_ANSWER_RESPONSE_TYPE.ACCEPTABLE
                  : parseInt(z.radioSelectiovalue) === 2
                  ? QUESTION_ANSWER_RESPONSE_TYPE.NOTACCEPTABLE
                  : parseInt(z.radioSelectiovalue) === 3
                  ? QUESTION_ANSWER_RESPONSE_TYPE.NOTAPPLICABLE
                  : undefined
                : parseInt(z.responsetype) === 3
                ? QUESTION_ANSWER_RESPONSE_TYPE.SINGLE_SELECT
                : parseInt(z.responsetype) === 2
                ? QUESTION_ANSWER_RESPONSE_TYPE.TEXTBOX
                : parseInt(z.responsetype) === 4
                ? QUESTION_ANSWER_RESPONSE_TYPE.MULTI_SELECT
                : null,
            questionId: parseInt(z.key),
          };

          if (parseInt(z.responsetype) === 4) {
            questionAnswer.listInspectionQuestionAnswerDet = [
              {
                comment: z.multiSelect?.toString(),
              },
            ];
          } else if (parseInt(z.responsetype) === 3) {
            questionAnswer.listInspectionQuestionAnswerDet = [
              {
                comment: z.singleSelect?.toString(),
              },
            ];
          } else if (parseInt(z.responsetype) === 2) {
            questionAnswer.listInspectionQuestionAnswerDet = [
              {
                comment: z.textAreaDetail,
              },
            ];
          } else if (parseInt(z.responsetype) === 1) {
            if (parseInt(z.radioSelectiovalue) === 1) {
              questionAnswer.listInspectionQuestionAnswerDet =
                z?.responsData?.acceptable?.map((detail) => {
                  const detailItem = {
                    comment: detail.comment,
                    correctiveFiles: detail?.media,
                    involvedPartyType:
                      detail?.involdevPaty === "Employee"
                        ? 0
                        : detail?.involdevPaty === "Sub-Contractor"
                        ? 1
                        : detail?.involdevPaty === "N/A"
                        ? 2
                        : 3, // Not Required
                    isCorrectiveFileAdded: detail?.media?.length ? true : false,
                  };
                  detailItem.listInspectionInvolvedUsers =
                    detail?.involdevPaty === "Employee"
                      ? detail.employee
                          ?.map((emp) => {
                            if (emp) {
                              const InvolvedUsersItem = {
                                involvedUser: parseInt(emp.key),
                              };
                              return InvolvedUsersItem;
                            }
                            return null;
                          })
                          .filter((item) => item !== null)
                      : detail.subcontractor
                          ?.map((emp) => {
                            if (emp) {
                              const InvolvedUsersItem = {
                                involvedUser: parseInt(emp.key),
                              };
                              return InvolvedUsersItem;
                            }
                            return null;
                          })
                          .filter((item) => item !== null);
                  return detailItem;
                });
            } else if (parseInt(z.radioSelectiovalue) === 2) {
              questionAnswer.listInspectionQuestionAnswerDet =
                z?.responsData?.notacceptable?.map((detail) => {
                  const detailItem = {
                    issue: detail.comment,
                    isReprimand:
                      detail?.escalatetoreprimandIsChecked === "Yes"
                        ? true
                        : false,
                    actionTaken:
                      detail?.escalatetoreprimandIsChecked === "Yes"
                        ? detail.escalatetoreprimandComment
                        : "",
                    itemStatus: detail.itemStatusIsChecked === "Open" ? 2 : 3,
                    itemResponsibility:
                      detail.itemStatusIsChecked === "Open"
                        ? parseInt(detail?.itemStatusCommentUser?.key)
                        : 0,
                    abatementNotes:
                      detail.itemStatusIsChecked === "Closed"
                        ? detail?.addAbatementNotes
                        : "",
                    correctiveFiles: detail?.media,
                    involvedPartyType:
                      detail?.involdevPaty === "Employee"
                        ? 0
                        : detail?.involdevPaty === "Sub-Contractor"
                        ? 1
                        : detail?.involdevPaty === "N/A"
                        ? 2
                        : 3, // Not Required
                    isCorrectiveFileAdded: detail?.media?.length ? true : false,
                  };
                  detailItem.listInspectionInvolvedUsers =
                    detail?.involdevPaty === "Employee"
                      ? detail.employee
                          ?.map((emp) => {
                            if (emp) {
                              const InvolvedUsersItem = {
                                involvedUser: parseInt(emp.key),
                              };
                              return InvolvedUsersItem;
                            }
                            return null;
                          })
                          .filter((item) => item !== null)
                      : detail.subcontractor
                          ?.map((emp) => {
                            if (emp) {
                              const InvolvedUsersItem = {
                                involvedUser: parseInt(emp.key),
                              };
                              return InvolvedUsersItem;
                            }
                            return null;
                          })
                          .filter((item) => item !== null);
                  return detailItem;
                });
            } else if (parseInt(z.radioSelectiovalue) === 3) {
              questionAnswer.listInspectionQuestionAnswerDet = [];
            }
          }
          return questionAnswer;
        });
      return subCategory;
    });
    result.push(item);
  });

  return result;
};

export const editListInspectionCategory = (
  listInspectionCategory,
  setListInspectionCategory
) => {
  const listInspectionCategoryClone = listInspectionCategory?.map(
    (category) => {
      const perQuestionCat = 100 / category?.subcategoriesView?.length;
      const completedCat = category?.subcategoriesView?.filter(
        (itqa) => !itqa.skipped
      );

      const categoryClone = {
        key: category.key,
        value: category.value,
        sequenceNumber: category.sequenceNumber,
        catAns: !category?.skipped
          ? category?.subcategoriesView?.some((subcategory) =>
              subcategory.insQuesAnsReportView?.some(
                (question) => question.answerMstId !== null
              )
            )
          : false,
        catError: false,
        catPercent: perQuestionCat * completedCat?.length,
        howManyCat: completedCat?.length,
        isSkipped: category.skipped,
        isSkippable: category.isSkippable,
        listInspectionSubCategory: category?.subcategoriesView?.map(
          (subCategory) => {
            const perQuestionSub =
              100 / subCategory?.insQuesAnsReportView?.length;
            const completedSub = subCategory?.insQuesAnsReportView?.filter(
              (items) => items.answerMstId
            );

            return {
              key: subCategory.key,
              value: subCategory.value,
              sequenceNumber: subCategory.sequenceNumber,
              subPercent: perQuestionSub * completedSub?.length,
              howManySub: completedSub?.length,
              isSkipped: subCategory.skipped,
              isSkippable: subCategory.isSkippable,
              listInspectionQuestionAnswer:
                subCategory?.insQuesAnsReportView?.map((question) => {
                  let responsData = {
                    acceptable: [],
                    notacceptable: [],
                    notapplicable: [],
                  };

                  if (question.reprimands) {
                    question.reprimands?.forEach((reprimand) => {
                      const baseData = {
                        id: Date.now() + Math.floor(Math.random() * 100),
                        comment: reprimand.comment,
                        files: reprimand.correctiveFiles
                          ?.map((file) => file.filePath)
                          .join("|"),
                        media: reprimand.correctiveFiles?.map(
                          (file) => file.fileId
                        ),
                        involdevPaty:
                          reprimand.employee_type === 0
                            ? "Employee"
                            : reprimand.employee_type === 1
                            ? "Sub-Contractor"
                            : "N/A",
                        employee:
                          reprimand.employee_type === 0
                            ? reprimand.involved_Ids
                            : null,
                        subcontractor:
                          reprimand.employee_type === 1
                            ? reprimand.involved_Ids
                            : null,
                      };

                      if (
                        reprimand.answer_id ===
                        QUESTION_ANSWER_RESPONSE_TYPE.ACCEPTABLE
                      ) {
                        responsData.acceptable.push(baseData);
                      } else if (
                        reprimand.answer_id ===
                        QUESTION_ANSWER_RESPONSE_TYPE.NOTACCEPTABLE
                      ) {
                        responsData.notacceptable.push({
                          ...baseData,
                          comment: reprimand.corrective_issue_dtl,
                          empCount: reprimand?.involved_Ids?.map((item) => ({
                            key: item.key,
                            count: item.count,
                          })),
                          escalatetoreprimandIsChecked:
                            reprimand?.escalate_reprimand ? "Yes" : "No",
                          escalatetoreprimandComment:
                            reprimand?.action_taken_dtl,
                          itemStatusIsChecked: reprimand?.item_status,
                          itemStatusComment: "",
                          itemStatusCommentUser: {
                            key: reprimand?.item_responsibility_id,
                            value: reprimand?.item_responsibility_name,
                          },
                          itemStatusCommentUserCurrent: "",
                          addAbatementNotes: reprimand?.abatement_notes,
                        });
                      } else if (
                        reprimand.answer_id ===
                        QUESTION_ANSWER_RESPONSE_TYPE.NOTAPPLICABLE
                      ) {
                        responsData.notacceptable = [];
                      }
                    });
                  }

                  return {
                    key: question.questionId,
                    value: question.question,
                    sequenceNumber: question.sequenceId,
                    quesNo: question.sequenceId,
                    responsetype: question.responsetype,
                    answerMstId: question.answerMstId,
                    options: question.optionList,
                    isAnswerQuestion: question?.answerMstId,
                    isQuestionError: false,
                    involvedPartyShow: question.isInvolvedParty,
                    multiSelect:
                      question.reprimands
                        ?.find(
                          (r) =>
                            r.answer_id ===
                            QUESTION_ANSWER_RESPONSE_TYPE.MULTI_SELECT
                        )
                        ?.comment?.split(",")
                        ?.map((item) => item.trim()) || [],
                    singleSelect:
                      parseInt(
                        question.reprimands?.find(
                          (r) =>
                            r.answer_id ===
                            QUESTION_ANSWER_RESPONSE_TYPE.SINGLE_SELECT
                        )?.comment
                      ) || null,
                    textAreaDetail:
                      question.reprimands?.find(
                        (r) =>
                          r.answer_id === QUESTION_ANSWER_RESPONSE_TYPE.TEXTBOX
                      )?.comment || null,
                    radioSelectiovalue: question.answerMstId,
                    responsData: responsData,
                  };
                }),
            };
          }
        ),
      };

      return categoryClone;
    }
  );

  setListInspectionCategory(listInspectionCategoryClone);
};

export const editFieldValues = (
  headerFieldsInfoResponse,
  setFieldValues,
  setInspectionHederFields
) => {
  headerFieldsInfoResponse?.forEach((headerField) => {
    if (headerFieldsInfoResponse?.length > 0) {
      headerFieldsInfoResponse?.forEach((matchingField) => {
        const responseType = parseInt(matchingField.responsetype);
        let newValue;
        if (parseInt(responseType) === 9 && HBNUser) {
          if (typeof matchingField?.ans === "string") {
            const jsonObject = JSON.parse(matchingField.ans);
            newValue = {
              key: jsonObject.key,
              value: jsonObject.ans,
            };
          }
        } else if (parseInt(responseType) === 9 && !HBNUser) {
          if (typeof matchingField?.ans === "string") {
            const jsonObjectNU = JSON.parse(matchingField.ans);
            newValue = jsonObjectNU.key;
          }
        } else if (responseType === 6 || responseType === 2) {
          newValue = matchingField.ans;
        } else if (responseType === 3) {
          newValue = matchingField.ans?.toString();
        } else if (responseType === 5) {
          newValue = matchingField.ans?.split(",");
        } else if (responseType === 10) {
          newValue = {
            key: matchingField.key,
            value: matchingField.ans,
          };
        } else {
          newValue = matchingField.ans;
        }
        setFieldValues((prevFieldValues) => ({
          ...prevFieldValues,
          [matchingField.key]: newValue,
        }));
        let selectAsset = {};
        setInspectionHederFields((prevAnswers) => {
          const answers = Array.isArray(prevAnswers) ? prevAnswers : [];

          const newAnswer = {
            insTypeFieldId: matchingField.key,
            ans:
              responseType === 2
                ? getFormatedDate(headerField.value)
                : responseType === 3 || responseType === 10
                ? headerField.value?.toString()
                : responseType === 9 && HBNUser
                ? JSON.stringify(newValue)
                : responseType === 9 && !HBNUser
                ? JSON.stringify(selectAsset)
                : headerField.value?.toString(),
          };

          const uniqueAnswers = new Set(
            [...answers, newAnswer]?.map((answer) => JSON.stringify(answer))
          );

          return Array.from(uniqueAnswers)?.map((answer) => JSON.parse(answer));
        });
      });
    }
  });
};

export const validateCategories = (listInspectionCategoryClone) => {
  let isError = false;
  listInspectionCategoryClone?.forEach((cat) => {
    if (!cat.isSkipped || cat.isSkipped === null) {
      if (!cat.catAns) {
        cat.catError = true;
        isError = true;
        return false;
      } else {
        cat.catError = false;
      }
    }
    if (!cat.isSkipped || cat.isSkipped === null) {
      cat.listInspectionSubCategory?.forEach((sub) => {
        if (!sub.isSkipped || sub.isSkipped === null) {
          sub.listInspectionQuestionAnswer?.forEach((question) => {
            if (!question.isAnswerQuestion) {
              question.isQuestionError = true;
              isError = true;
              return false;
            } else {
              question.isQuestionError = false;
            }
          });
        }
      });
    }
  });
  return isError;
};

export const toggleSkipCategory = (
  e,
  setListInspectionCategory,
  listInspectionCategory,
  categoryIndex
) => {
  e.stopPropagation();
  const newCategories = [...listInspectionCategory];
  newCategories[categoryIndex].isSkipped =
    !newCategories[categoryIndex].isSkipped;
  setListInspectionCategory(newCategories);
};

export const toggleSkipSubCategory = (
  e,
  setListInspectionCategory,
  listInspectionCategory,
  categoryIndex,
  subCategoryIndex
) => {
  e.stopPropagation();
  const newCategories = [...listInspectionCategory];

  if (newCategories[categoryIndex].isSkipped) {
    return;
  }

  const subCategory =
    newCategories[categoryIndex]?.listInspectionSubCategory[subCategoryIndex];

  if (subCategory) {
    subCategory.isSkipped = !subCategory.isSkipped;

    if (subCategory.listInspectionQuestionAnswer) {
      subCategory.listInspectionQuestionAnswer.forEach((question) => {
        question.isAnswerQuestion = !subCategory.isSkipped;
      });
    }
  }

  setListInspectionCategory(newCategories);
};

export const getAnswerValue = (
  responsetype,
  processedValue,
  selectAsset,
  HBNUser
) => {
  switch (parseInt(responsetype)) {
    case 2:
      return getFormatedDate(processedValue);
    case 3:
      return processedValue?.target?.value;
    case 10:
      return processedValue?.key?.toString();
    case 9:
      return HBNUser
        ? JSON.stringify(processedValue)
        : JSON.stringify(selectAsset);
    default:
      return processedValue?.toString();
  }
};

export const findValueByKeyWeather = (value) => {
  const foundItem = vehicleAccident?.find(
    (item) =>
      item?.value?.toLocaleLowerCase() === value?.toLocaleLowerCase() ||
      parseInt(item.key) === parseInt(value)
  );
  return foundItem ? foundItem?.value : "Not Found";
};

export const getResponseTypeValue = (key) => {
  const item = QUESTION_RESPONSE_TYPE_VALUE?.find((entry) => entry.key === key);
  return item ? item.value : null;
};

export const SIGNOFF_METHOD_TYPE_ADMIN = {
  SIGNATURE: "SIGNATURE",
  CERTIFY_STATEMENT: "CERTIFY_STATEMENT",
  NA: "NA",
};

export const getSignoffMethodValue = (signoffMethods) => {
  const { SIGNATURE, CERTIFY_STATEMENT, NA } = signoffMethods;

  if (SIGNATURE && CERTIFY_STATEMENT) return 4;
  if (SIGNATURE) return 1;
  if (CERTIFY_STATEMENT) return 2;
  if (NA) return 3;

  return 0;
};

export const updateSignoffMethods = (setSignoffMethods, signoffMethod) => {
  switch (signoffMethod) {
    case 1:
      setSignoffMethods({
        SIGNATURE: true,
        CERTIFY_STATEMENT: false,
        NA: false,
      });
      break;
    case 2:
      setSignoffMethods({
        SIGNATURE: false,
        CERTIFY_STATEMENT: true,
        NA: false,
      });
      break;
    case 3:
      setSignoffMethods({
        SIGNATURE: false,
        CERTIFY_STATEMENT: false,
        NA: true,
      });
      break;
    case 4:
      setSignoffMethods({
        SIGNATURE: true,
        CERTIFY_STATEMENT: true,
        NA: false,
      });
      break;
    default:
      setSignoffMethods({
        SIGNATURE: false,
        CERTIFY_STATEMENT: false,
        NA: false,
      });
      break;
  }
};
