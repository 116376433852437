import React, { useEffect, useState, useRef } from "react";
import { Box, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../../library/custom/button/Button";
import {
  API_STATUS,
  ASSIGN_TOOL_BOX_TALK_DROPDOWN,
  ORG_ID,
  USERID,
  USER_ID,
  USER_TYPE,
} from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { TOOL_BOX_LIST } from "../../../../constant/routeContant";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import { SAFETYMEETINGSEARCH } from "../../courseConstant";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import { InputField } from "../../../../library/custom/textBox/InputField";
import "./assignToolBoxTalk.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

export default function AssignToolBoxTalk({
  allUsers,
  isLoading,
  onSearch,
  getSafetyMeetingSearch,
  SafetyMeetingSearchData,
  onSubmit,
  toolboxTalkIdSelf,
  titleSafetyMeetingSelf,
  assignInstructorsUsersId,
  isAssetLoading,
  assetOption,
  searchAsset,
  editMode,
  getScheduleSaftyMeetingDetailsData,
  scheduleId,
  toolboxid,
  titleSafetyMeeting1,
  instructorLoading,
  editInstructorStatus, scheduleDate
}) {
  const initialState = {
    userType: 0,
    scheduleStartDate: null,
    scheduleEndDate: null,
    individualUserIds: [],
    createdBy: parseInt(USERID),
    startDate: new Date(),
    organisationId: ORG_ID(),
  };
  const [titleSafetyMeeting, setTitleSafetyMeeting] = useState("");
  useEffect(() => {
    if (editMode) {
      setTitleSafetyMeeting(
        getScheduleSaftyMeetingDetailsData?.titleSafetyMeeting
      );
      setMeetingscheduleid(getScheduleSaftyMeetingDetailsData?.frequencyId);
      setAssetType(getScheduleSaftyMeetingDetailsData?.assetType);
      setAssetName({
        key: getScheduleSaftyMeetingDetailsData?.assetId,
        value: getScheduleSaftyMeetingDetailsData?.assetName,
      });
      setAssetId(getScheduleSaftyMeetingDetailsData?.assetId);
      setIndividualUsersIds(
        ...individualUsersIds,
        getScheduleSaftyMeetingDetailsData?.users?.map((e) => e.key)
      );
      if (getScheduleSaftyMeetingDetailsData?.users?.length > 0) {
        setIndividualUsersList(
          ...individualUsersList,
          getScheduleSaftyMeetingDetailsData?.users?.map((e) => e)
        );
      }
      setFormValue({
        ...formValue,

        scheduleStartDate:
          getScheduleSaftyMeetingDetailsData?.scheduleTalkStartDate,
        scheduleEndDate:
          getScheduleSaftyMeetingDetailsData?.scheduleTalkEndDate,
        individualUserIds: [],
      });
    }
  }, [editMode]);

  const [searchSafetyMeetingId, setSearchSafetyMeetingId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [meetingscheduleid, setMeetingscheduleid] = useState("");
  const [meetingscheduleidError, setMeetingscheduleidError] = useState(false);
  const [formValue, setFormValue] = useState(initialState);
  const [formValueError, setFormValueError] = useState(false);
  const autoComp = useRef(null);
  const [isScheduleStartDate, setIsScheduleStartDate] = useState(false);
  const [isScheduleStartDateErrMsg, setIsScheduleStartDateErrMsg] =
    useState(false);
  const [isScheduleEndDate, setIsScheduleEndDate] = useState(false);
  const [isScheduleEndDateErrMsg, setIsScheduleEndDateErrMsg] = useState(false);
  const [indvialUserNameError, setIndvialUserNameError] = useState(false);
  const [searchsafetyvalue, setSearchsafetyvalue] = useState({
    key: toolboxid ? toolboxid : "",
    value: titleSafetyMeeting1 ? titleSafetyMeeting1 : "",
  });

  const [assetType, setAssetType] = useState(0);
  const [assetName, setAssetName] = useState({
    key: "",
    value: "",
  });
  const [assetId, setAssetId] = useState(0);
  const dataBack = {
    title: "Back to Safety Meeting",
    route: TOOL_BOX_LIST,
    routeProps: { tabId: 1 },
    backTabId: 1
  };

  const onChangeMeetingSchedule = (event) => {
    setMeetingscheduleid(event.target.value);
  };

  const onChangeSafetyMeeting = (event, data) => {
    const defaultData = {
      search: event.target.value,
    };
    getSafetyMeetingSearch(defaultData);
  };

  function mappedData() {
    let data;
    if (editMode) {
      data = {
        scheduleId: scheduleId,
        effectiveDate: editInstructorStatus === 1 ? scheduleDate : editInstructorStatus === 2 ? null : "",
        createdBy: parseInt(USERID),
        instructorUserContractor: individualUsersIds,
      };
    } else {
      data = {
        toolboxTalkId: searchsafetyvalue?.key
          ? searchsafetyvalue?.key
          : searchSafetyMeetingId
            ? searchSafetyMeetingId
            : "",
        frequencyId: meetingscheduleid,

        scheduleTalkStartDate:
          formValue.scheduleStartDate === null
            ? null
            : getFormatedDate(formValue?.scheduleStartDate),

        scheduleTalkEndDate:
          meetingscheduleid == 5
            ? getFormatedDate(formValue?.scheduleStartDate)
            : formValue.scheduleEndDate === null
              ? null
              : getFormatedDate(formValue?.scheduleEndDate),
        assetId: assetName?.key ? assetName.key : 0,
        assetType: assetName?.key
          ? assetType === 1 || assetType === 4
            ? assetType
            : 0
          : 0,
        projectId: 0,
        instructorTypeId: formValue.userType === 1 ? 2 : formValue.userType,
        instructorUserContractor:
          formValue.userType === 0 ? individualUsersIds : individualUsersIds,
        createdBy: parseInt(localStorage.getItem("userId")),
      };
    }

    let errors = false;

    if (meetingscheduleid?.length === 0) {
      setMeetingscheduleidError(true);
      errors = true;
    }

    if (individualUsersIds?.length === 0 && !toolboxTalkIdSelf) {
      errors = true;
      setIndvialUserNameError(true);
    }
    if (formValue?.scheduleStartDate === null) {
      setIsScheduleStartDate(true);
      errors = true;
      setIsScheduleStartDateErrMsg("Schedule Meeting Start Date is required");
    }

    if (formValue.scheduleStartDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.scheduleStartDate)
      );
      if (validDate === false) {
        setIsScheduleStartDate(true);
        errors = true;
        setIsScheduleStartDateErrMsg("Invalid Date");
      }
    }

    if (
      formValue?.scheduleEndDate < formValue.scheduleStartDate &&
      formValue?.scheduleEndDate != null
    ) {
      setIsScheduleStartDate(true);
      errors = true;
      setIsScheduleStartDateErrMsg("Invalid Date");
    }

    if (formValue?.scheduleEndDate === null && meetingscheduleid != 5) {
      setIsScheduleEndDate(true);
      errors = true;
      setIsScheduleEndDateErrMsg("Schedule Meeting End Date is required");
    }
    if (formValue.scheduleEndDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.scheduleEndDate)
      );
      if (validDate === false) {
        setIsScheduleEndDate(true);
        errors = true;
        setIsScheduleEndDateErrMsg("Invalid Date");
      }
    }

    if (errors) {
      return false;
    }
    return data;
  }
  const onSubmitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      if (data && editMode) {
        onSubmit(data);
      } else {
        try {
          onSubmit(data);
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const [individualUsersList, setIndividualUsersList] = useState([]);
  const [individualUsers, setIndividualUsers] = useState("");
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const deleteIndividualUsersData = (id) => {
    const updateIndividualUsersList = individualUsersList.filter(
      (list) => list.key !== id
    );

    const updateindividualUsersIds = individualUsersIds.filter((list) => {
      return list !== id;
    });
    setIndividualUsersList(updateIndividualUsersList);
    setIndividualUsersIds(updateindividualUsersIds);
  };
  const addIndiVidualUserData = () => {
    setFormValueError(false);
    if (!individualUsers.key) return;
    const index = individualUsersList.findIndex(
      (object) => object.key == individualUsers.key
    );
    if (index === -1) {
      setIndividualUsersIds([...individualUsersIds, individualUsers.key]);
      setIndividualUsersList([...individualUsersList, individualUsers]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const typeOnchange = (event, value) => {
    const previllageidInstructor = "91";
    onSearch(
      event.target.value,
      formValue?.userType,
      assetType,
      assetId,
      projectName,
      previllageidInstructor
    );
  };

  const onSelectSearchMeetings = (e, value, s) => {
    setSearchsafetyvalue({ key: value?.key ?? "", value: value?.value ?? "" });
    setSearchSafetyMeetingId(value?.key || null);
  };

  const onSelectAssetName = (e, value, s) => {
    setAssetName({ key: value?.key ?? "", value: value?.value ?? "" });
    setAssetId(value?.key || 0);
    setIndividualUsersIds([]);
    setIndividualUsersList([]);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const date = new Date();
  return (
    <div className="overidePersonal">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">Schedule Meeting</div>
      </Grid>

      <Grid item container mt={3} spacing={2} pl={4}>
        <Grid lg={12} className="addShadowBox">
          <Grid item container>
            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormControl fullWidth>
                {editMode ? (
                  <InputField
                    fullWidth={true}
                    disabled={true}
                    type="text"
                    label="Search Safety Meeting"
                    value={titleSafetyMeeting}
                    placeholder="Enter name"
                  />
                ) : (
                  <>
                    <div className="titlediv">
                      <FormLabels
                        label={"Search Safety Meeting"}
                        isRequired={true}
                      />
                    </div>

                    <SearchAutoComplete
                      name="credentials"
                      optionsData={
                        isLoading ? [] : SafetyMeetingSearchData ?? []
                      }
                      typeOnchange={(event, value) =>
                        onChangeSafetyMeeting(event, value)
                      }
                      isKeyValuePair={true}
                      onChange={(event, value) =>
                        onSelectSearchMeetings(event, value)
                      }
                      value={searchsafetyvalue ? searchsafetyvalue : ""}
                      disabled={true}
                      isLoading={isLoading}
                      noOptionsTextMsg="No Safety Meeting"
                    />
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormControl fullWidth>
                <FormLabels label="Meeting Frequency" isRequired={true} />
                <SelectMenu
                  placeholder={"Please Select"}
                  listData={SAFETYMEETINGSEARCH}
                  value={meetingscheduleid}
                  onchangehandler={(e) => onChangeMeetingSchedule(e)}
                  disabled={editMode}
                />
                {meetingscheduleidError && !meetingscheduleid && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </FormControl>
            </Grid>

            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormLabels
                label={
                  meetingscheduleid != 5
                    ? "Schedule Meeting From"
                    : "Schedule Meeting"
                }
                isRequired={true}
              />
              <DatePicker
                value={formValue.scheduleStartDate}
                maxDateEnable
                minDate={date}
                onChangeHandler={(e) => {
                  setFormValue({
                    ...formValue,
                    scheduleStartDate: e,
                  });
                  setIsScheduleStartDate(false);
                }}
                isError={isScheduleStartDate}
                errorMsg={isScheduleStartDate ? isScheduleStartDateErrMsg : ""}
                disabled={editMode}
              />
            </Grid>
            {meetingscheduleid != 5 && (
              <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
                <FormLabels label={"Schedule Meeting To"} isRequired={true} />
                <DatePicker
                  isRequired={true}
                  value={formValue.scheduleEndDate}
                  maxDateEnable
                  minDate={formValue.scheduleStartDate}
                  onChangeHandler={(e) => {
                    setFormValue({ ...formValue, scheduleEndDate: e });
                    setIsScheduleEndDate(false);
                  }}
                  isError={isScheduleEndDate}
                  errorMsg={isScheduleEndDate ? isScheduleEndDateErrMsg : ""}
                  disabled={editMode}
                />
              </Grid>
            )}

            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormControl fullWidth>
                <Box display="flex" alignItems="center" gap="1px">
                  <FormLabels label="Asset Type" />
                  <Tooltip
                    title={
                      <div
                        style={{
                          textAlign: "left",
                          fontFamily: "Lato, sans-serif",
                        }}
                      >
                        If an asset type is not selected here, <br />
                        instructors can still select one when <br />
                        conducting the safety meeting.
                      </div>
                    }
                    placement="bottom-start"
                    arrow
                    open={tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#ffffff",
                          color: "#333333",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "13px",
                          padding: "8px 12px",
                          borderRadius: "8px",
                          border: "2px solid #ccc",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "#ffffff",
                          "&::before": {
                            borderRadius: "2px",
                            border: "2px solid #ccc",
                          },
                        },
                      },
                    }}
                  ></Tooltip>
                  <InfoOutlinedIcon
                    fontSize="small"
                    style={{
                      marginBottom: "12px",
                      color: "#1b436e",
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                  />
                </Box>
                <SelectMenu
                  placeholder={"Please Select"}
                  listData={ASSIGN_TOOL_BOX_TALK_DROPDOWN}
                  value={assetType}
                  onchangehandler={(e) => {
                    setAssetType(e.target.value);

                    setIndividualUsersIds([]);
                    setIndividualUsersList([]);
                    setAssetName({
                      key: "",
                      value: "",
                    });
                    setAssetId(0);
                  }}
                  disabled={editMode}
                />
              </FormControl>
            </Grid>
            {assetType === 1 || assetType === 4 ? (
              <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
                <FormControl fullWidth>
                  <div className="titlediv">
                    <FormLabels
                      label={assetType === 1 ? "Project Name" : "Asset Name"}
                      isRequired={false}
                    />
                  </div>
                  <SearchAutoComplete
                    name="credentials"
                    optionsData={isAssetLoading ? [] : assetOption ?? []}
                    typeOnchange={(event, value) =>
                      searchAsset(event.target.value, assetType)
                    }
                    isKeyValuePair={true}
                    onChange={(event, value) => onSelectAssetName(event, value)}
                    value={assetName}
                    isLoading={isAssetLoading}
                    noOptionsTextMsg="No Data"
                    disabled={editMode}
                  />
                </FormControl>
              </Grid>
            ) : (
              ""
            )}

            {!titleSafetyMeetingSelf && !toolboxTalkIdSelf && (
              <>
                {" "}
                {formValue.userType === 0 && (
                  <Grid item container mt={3} spacing={2} pl={2}>
                    <Grid item lg={4} xs={12} sm={12} md={4}>
                      <FormLabels
                        label="Assign to Instructor"
                        isRequired={true}
                      />
                      <SearchAutoComplete
                        optionsData={isLoading ? [] : allUsers?.users ?? []}
                        isKeyValuePair={true}
                        value={formValue.employeeName}
                        onChange={(e, value) => setIndividualUsers(value)}
                        typeOnchange={(event, value) => {
                          const assetTypeFilter =
                            assetType === 1 || assetType === 4 ? assetType : 0;
                          onSearch(
                            event.target.value,
                            USER_TYPE.EMPLOYEE,
                            assetTypeFilter,
                            assetId
                          );
                        }}
                        isLoading={instructorLoading}
                        autoCompClear={autoComp}
                      />
                      {indvialUserNameError &&
                        individualUsersList?.length <= 0 && (
                          <div className="errorMsg">
                            Please enter this required field
                          </div>
                        )}
                    </Grid>
                    <Grid
                      className="smallAddBtn"
                      item
                      lg={1.2}
                      xs={12}
                      sm={12}
                      mt={4}
                      md={4}
                    >
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Add"}
                        onClick={() => addIndiVidualUserData()}
                        id="btnAddEquipment"
                        varientAddIconBlue={true}
                      ></Buttons>
                    </Grid>
                    <Grid pl={2} mt={3} container gap={1}>
                      <UsersChip
                        userList={individualUsersList ?? []}
                        handleDelete={(e) => deleteIndividualUsersData(e)}
                      />
                    </Grid>
                  </Grid>
                )}
                {formValue.userType === 1 && (
                  <Grid item container mt={3} spacing={2} pl={2}>
                    <Grid item lg={4} xs={12} sm={12} md={4}>
                      <FormLabels label="Assign to" isRequired={false} />
                      <SearchAutoComplete
                        optionsData={isLoading ? [] : allUsers?.users ?? []}
                        isKeyValuePair={true}
                        value={formValue.employeeName}
                        onChange={(e, value) => setIndividualUsers(value)}
                        typeOnchange={(event, value) =>
                          typeOnchange(event, value)
                        }
                        isLoading={isLoading}
                        autoCompClear={autoComp}
                      />
                      {formValueError && individualUsersList?.length <= 0 && (
                        <div className="errorMsg">
                          Please enter this required field
                        </div>
                      )}
                    </Grid>
                    <Grid
                      className="smallAddBtn"
                      item
                      lg={1.2}
                      xs={12}
                      sm={12}
                      mt={4}
                      md={4}
                    >
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Add"}
                        onClick={() => addIndiVidualUserData()}
                        id="btnAddEquipment"
                        varientAddIconBlue={true}
                      ></Buttons>
                    </Grid>
                    <Grid pl={2} mt={3} container gap={1}>
                      <UsersChip
                        userList={individualUsersList ?? []}
                        handleDelete={(e) => deleteIndividualUsersData(e)}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Grid container mb={5} mt={1} spacing={2} pl={2} pr={2}>
              <Grid item lg={4} xs={12} sm={12} md={4} textAlign="left"></Grid>
              <Grid item lg={12} xs={12} sm={12} md={4}>
                <div className="save_btn_with_back">
                  <div className="">
                    <BackBtn dataBack={dataBack} />
                  </div>
                  <div>
                    <div className="rightSideBtn">
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Assign"}
                        onClick={() => onSubmitData()}
                        varientContained={true}
                        disabled={assignInstructorsUsersId > 0 ? true : false}
                      ></Buttons>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
