export const contactType = {
  CONTACT_PAGE: "Contacts",
  GET_CONTACTS_LIST: "[GET_CONTACTS_LIST] Get contact List Data",
  GET_CONTACTS_LIST_SUCCESS:
    "[GET_CONTACTS_LIST_SUCCESS] Get contact List Data Successfull",
  GET_CONTACTS_LIST_EMAIL_SUCCESS:
    "[GET_CONTACTS_LIST_EMAIL_SUCCESS] Get contact List email sent Successfull",
  GET_CONTACTS_LIST_FAILURE:
    "[GET_CONTACTS_LIST_FAILURE] Get contact List Data Failed",

  POST_CONTACTS: "[POST_CONTACTS] Try to create contact Data",
  POST_CONTACTS_SUCCESS:
    "[POST_CONTACTS_SUCCESS] Create contact Data Successfull",
  POST_CONTACTS_FAILURE: "[POST_CONTACTS_FAILURE] Create contact  Data Failed",

  UPDATE_CONTACT_PENDING: "UPDATE_CONTACT_PENDING",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILED: "UPDATE_CONTACT_FAILED",
  UPDATE_PAGE: "UpdatePage",
  CLEAR_CONTACT: "[CLEAR_CONTACT][REQUEST] Clear CONTACT",
  EDIT_CONTACT: "[EDIT_EDIT_CONTACT][REQUEST] Edit CONTACT",

  GET_CONTACT_DETAILS_PENDING: "GET_CONTACT_DETAILS_PENDING",
  GET_CONTACT_DETAILS_SUCCESS: "GET_CONTACT_DETAILS_SUCCESS",
  GET_CONTACT_DETAILS_FAILED: "GET_CONTACT_DETAILS_FAILED",

  DELETE_CONTACT_PENDING: "DELETE_CONTACT",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILED: "DELETE_CONTACT_FAILED",

  CONVERT_NON_PRIVILEGE_CONTACT_LIST:
    "[REQUEST] Convert Non Privelege Contact List Data",
  CONVERT_NON_PRIVILEGE_CONTACT_LIST_SUCCESS:
    "[RESPONSE] Convert Non Privelege Contact List Data Successfull",
  CONVERT_NON_PRIVILEGE_CONTACT_LIST_FAILURE:
    "[RESPONSE] Convert Non Privelege Contact List Data Failed",
};
