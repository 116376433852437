import React, { useEffect, useState, useRef } from "react";
import { FormControl, Grid } from "@mui/material";
import DatePicker from "../../library/custom/datePicker/DatePicker";
import TImePicker from "../../library/custom/timePicker/TImePicker";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import TextArea from "../../library/custom/textArea/TextArea";
import Card from "../../library/custom/card/Card";
import { InputField } from "../../library/custom/textBox/InputField";
import SearchAutoComplete from "../../library/custom/searchAutoComplete/SearchAutoComplete2";
import SearchAutoComplete2 from "../../library/custom/searchAutoComplete/SearchAutoComplete";
import TextEditor from "../../library/custom/textEditor/TextEditor";
import SelectMenu from "../../library/custom/selectMenu/SelectMenu";
import { checkPrivileges } from "../../utils/rolesHelper";

const CommonForm = ({
  form,
  onChange,
  onChangeComment,
  onChangeDatePicker,
  cardClicStatickHandler,
  weather,
  dynamicFeild,
  commonFormError,
  searchAsset,
  assetOption,
  isLoading,
  onChangeAsset,
  assetTypeValue,
  assetName,
  assetNameKey,
  hideprojectNameEdit,
  edit,
  pathN,
  assetError,
  assetLocationError,
  characterLimit,
  assetdtaa,
}) => {
  const autoSearch = useRef(null);
  const [assetIdName, setAssetIdName] = useState(false);
  useEffect(() => {
    if (edit) {
      setAssetIdName(true);
    }
  }, [edit]);

  useEffect(() => {
    const ele = autoSearch?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  }, [pathN]);
  return (
    <>
      <Grid item container mt={4}>
        <Grid item lg={4} md={6} xs={12}>
          <InputField
            fullWidth={true}
            disabled={true}
            type="text"
            label="Prepared By"
            value={form?.preparedBy}
            name="preparedBy"
            placeholder="Enter name"
            onChange={onChange}
            isError={!!commonFormError?.preparedBy}
            errorMsg={commonFormError?.preparedBy ?? ""}
          />
        </Grid>
      </Grid>
      <Grid
        item
        className="addWitnessContainerGrid inputGrid"
        container
        mt={3}
        spacing={2}
      >
        <Grid item lg={4} md={6} xs={12}>
          <FormControl fullWidth>
            <FormLabels label={"Date of Inspection"} />
            <DatePicker
              value={form?.dateOfInspection}
              name="dateOfInspection"
              onChangeHandler={onChangeDatePicker}
              isError={!!commonFormError?.dateOfInspection}
              errorMsg={commonFormError?.dateOfInspection ?? ""}
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <FormControl fullWidth>
            <FormLabels label={"Time of Inspection"} />
            <TImePicker
              disabled={false}
              name="timeOfInspection"
              value={form?.timeOfInspection}
              onChangeTimeHandler={onChange}
              isError={!!commonFormError?.timeOfInspection}
              errorMsg={commonFormError?.timeOfInspection ?? ""}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        className="addWitnessContainerGrid inputGrid"
        container
        mt={3}
        spacing={2}
      >
        <Grid item lg={4} md={6} xs={12}>
          <FormLabels label={"Location"} isRequired={true} />
          <InputField
            fullWidth={true}
            disabled={false}
            value={form?.location}
            type="text"
            name="location"
            placeholder="Enter Location"
            onChange={onChange}
            // isError={!!commonFormError?.location}
            // errorMsg={commonFormError?.location ?? ""}
          />
          {assetLocationError && (
            <div className="error">{"Please enter this required field."}</div>
          )}
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <FormLabels
            label={
              assetTypeValue === 1
                ? "Project"
                : assetTypeValue === 3
                ? "Vehicle"
                : assetTypeValue === 2
                ? "Equipment"
                : assetTypeValue === 4
                ? "Custom asset"
                : ""
            }
            isRequired={true}
          />

          {checkPrivileges([12, 139, 144]) ? (
            <SearchAutoComplete2
              optionsData={isLoading ? [] : assetOption ?? []}
              isKeyValuePair={true}
              value={form?.assetId ?? ""}
              isLoading={isLoading}
              noOptionsTextMsg={
                assetTypeValue === 1
                  ? "No Project"
                  : assetTypeValue === 3
                  ? "No Vehicles"
                  : assetTypeValue === 2
                  ? "No Equipment"
                  : assetTypeValue === 4
                  ? "No Custom Assets"
                  : ""
              }
              typeOnchange={(event) =>
                searchAsset(
                  event.target.value,
                  assetTypeValue === 1
                    ? 1
                    : assetTypeValue === 2
                    ? 2
                    : assetTypeValue === 3
                    ? 3
                    : assetTypeValue === 4
                    ? 4
                    : ""
                )
              }
              onChange={(event, value) => onChangeAsset(event, value)}
              isError={!!commonFormError?.assetId}
              errorMsg={commonFormError?.assetId ?? ""}
              autoCompClear={autoSearch}
            />
          ) : (
            <SelectMenu
              placeholder="Please Select"
              listData={isLoading ? [] : assetdtaa?.data?.result ?? []}
              value={form?.assetId ?? ""}
              onchangehandler={(event, value) => onChangeAsset(event, value)}
            />
          )}

          {assetError && (
            <div className="error">{"Please enter this required field."}</div>
          )}
        </Grid>
      </Grid>

      <Grid container mt={4} mb={3}>
        <Grid item lg={8} md={12} xs={12}>
          <FormLabels label="Weather at Time of Inspection" />
          <Card
            staticCardData={true}
            cardData={weather}
            selectedVehicleAccident={form?.selectedVehicleAccident}
            cardClickHandler={cardClicStatickHandler}
          />
        </Grid>
      </Grid>

      <Grid container mt={4} mb={3}>
        <Grid item lg={12} md={12} xs={12}>
          <FormLabels label="General Comments" />
          <TextEditor
            value={form?.generalComment ?? " "}
            placeholderText={"Enter Here..."}
            onChange={(e) => onChangeComment(e)}
          />
          {!characterLimit && form?.generalComment?.length > 2000 && (
            <p className="error">Character limit exceeded.</p>
          )}
          {characterLimit && (
            <p className="error">Character limit exceeded 2000.</p>
          )}
        </Grid>
      </Grid>

      {/* dynamicfeild */}
      {/* {
        dynamicFeild && (
          <Grid
          item
          className="addWitnessContainerGrid inputGrid"
          container
          spacing={2}
          lg={8}
          mb={3}
        >
          {
            dynamicFeild && dynamicFeild.map((item, index) => (
              <Grid item key={index} mt={3} lg={6}>
                <InputField
                  fullWidth={true}
                  disabled={false}
                  value={item.value}
                  isReadonly={item.isReadonly}
                  type="text"
                  name={item?.label ?? ''}
                  label={item?.label ?? ''}
                  placeholder={item?.label ?? ''}
                  onChange={onChange}
                />
              </Grid>
            ))
          }
        </Grid>
        )
      } */}
    </>
  );
};

export default React.memo(CommonForm);
