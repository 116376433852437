import React from "react";
import UsersThatHaveCompleteLesson from "./usersThatHaveTheCompleteLesson";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import UsersWithHighestCourseCompletion from "./usersThatHaveCompletedCourse";
import UserTrainings from "./userTrainings";
import SubContractorWithMostParticipation from "./subContractorWithMostParticipation";
import SubcontractorCompletedTraingings from "./subContractorCompletedTrainings";
import NotCompletedTrainingUSers from "./notCompletedTrainingUSers";

const Items = (props) => {
  const dataBack = {
    title: "Back",
    route: -1,
    state: {
      apiData: props?.drillDetail?.apiData,
      backpage: "backpage",
    },
  };
  return (
    <div>
      <div onClick={() => props?.handleClear()}>
        <BackBtn dataBack={dataBack} />
      </div>
      {props.page === "UsersThatHaveCompleteLesson" ? (
        <UsersThatHaveCompleteLesson
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          getAssignLessonUserListData={props.lessonUserAssignListData}
          handleClear={props?.handleClear}
        />
      ) : props.page === "TrainingWithLowestParticipation" ? (
        <NotCompletedTrainingUSers
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          coursesUserAssignListData={props.coursesUserAssignListData}
          getAssignLessonUserListData={props.lessonUserAssignListData}
          getScheduleAssignUserStatusData={
            props?.getScheduleAssignUserStatusData
          }
          location={props?.location}
          handleClear={props?.handleClear}
        />
      ) : props.page === "UsersWithHighestCourseCompletion" ? (
        <UsersWithHighestCourseCompletion
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          coursesUserAssignListData={props.coursesUserAssignListData}
          handleClear={props?.handleClear}
        />
      ) : props.page === "UserTrainings" ||
        props.page === "UserTrainingsIncomplete" ? (
        <UserTrainings
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          coursesUserListData={props.coursesUserListData}
          lessionUserListData={props.lessionUserListData}
          getToolBoxTalkListResult={props.getToolBoxTalkListResult}
          page={props.page}
          userTRainingLoadin={props?.userTRainingLoadin}
          handleClear={props?.handleClear}
        />
      ) : props.page === "SubcontractorCompletedTraingings" ||
        props.page === "UsersWithMostSafetyMeetings" ? (
        <SubcontractorCompletedTraingings
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          getToolBoxTalkListResult={props.getToolBoxTalkListResult}
          userTRainingLoadin={props?.userTRainingLoadin}
          handleClear={props?.handleClear}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Items;
