export const incidentType = {
  GET_INCIDENT: "[REQUEST] Get Incident Data",
  GET_INCIDENT_SUCCESS: "[RESPONSE] Get Incident Data Successfull",
  GET_INCIDENT_FAILURE: "[RESPONSE] Get Incident Data Failed",
  INCIDENT_PAGE: "InicidentPage",
  CASES_PAGE: "CasesPage",
  CASEVIEW_PAGE: "case view page",

  // list of incidents
  DELETE_INCIDENT: "DELETE_INCIDENT",
  DELETE_INCIDENT_SUCCESS: "DELETE_INCIDENT_SUCCESS",
  DELETE_INCIDENT_FAILED: "DELETE_INCIDENT_FAILED",

  //GetIncidentList

  GET_INCIDENT_LIST: "[GET_INCIDENT_LIST] Get Incident List Data",
  GET_INCIDENT_LIST_SUCCESS:
    "[GET_INCIDENT_LIST_SUCCESS] Get Incident List Data Successfull",
  GET_INCIDENT_LIST_EMAIL_SUCCESS:
    "[GET_INCIDENT_LIST_EMAIL_SUCCESS] Send Incident List Data Successfull",
  GET_INCIDENT_LIST_FAILURE:
    "[GET_INCIDENT_LIST_FAILURE] Get Incident List Data Failed",

  GET_CASES_LIST: "[GET_CASES_LIST] Get Cases List Data",
  GET_CASES_LIST_SUCCESS:
    "[GET_CASES_LIST_SUCCESS] Get Cases List Data Successfull",
  GET_CASES_LIST_EMAIL_SUCCESS:
    "[GET_CASES_LIST_EMAIL_SUCCESS] Send Cases List Data Successfull",
  GET_CASES_LIST_FAILURE: "[GET_CASES_LIST_FAILURE] Get Cases List Data Failed",

  GET_CASE_DETAILS_PENDING: "[GET_CASE_DETAILS_PENDING] Get Cases Detail Data",
  GET_CASE_DETAILS_SUCCESS:
    "[GET_CASES_LIST_SUCCESS] Get Cases Detail Data Successfull",
  GET_CASE_DETAILS_FAILED:
    "[GET_CASES_LIST_FAILURE] Get Cases Detail Data Failed",

  GET_ACTION_LIST: "[GET_ACTION_LIST] Get Action List Data",
  GET_ACTION_LIST_SUCCESS:
    "[GET_ACTION_LIST_SUCCESS] Get Action List Data Successfull",
  GET_ACTION_LIST_FAILURE:
    "[GET_ACTION_LIST_FAILURE] Get Action List Data Failed",

  CLOSE_ACTION_ITEM: "[UPDATE_ACTION_LIST] Get Action List Data",
  CLOSE_ACTION_ITEM_SUCCESS:
    "[UPDATE_ACTION_LIST_SUCCESS] Get Action List Data Successfull",
  CLOSE_ACTION_ITEM_FAILURE:
    "[UPDATE_ACTION_LIST_FAILURE] Get Action List Data Failed",

  CLOSE_INCIDENT: "[CLOSE_INCIDENT] CLOSE_INCIDENT Data",
  CLOSE_INCIDENT_SUCCESS:
    "[CLOSE_INCIDENT_SUCCESS] CLOSE_INCIDENT Data Successfull",
  CLOSE_INCIDENT_FAILURE: "[CLOSE_INCIDENT_FAILURE] CLOSE_INCIDENT Data Failed",

  DELETE_CASE: "DELETE_CASE",
  DELETE_CASE_SUCCESS: "DELETE_CASE_SUCCESS",
  DELETE_CASE_FAILED: "DELETE_CASE_FAILED",
  REPORT_INCIDENT_PAGE: "REPORT_INCIDENT_PAGE",
  GET_INCIDENT_REPORT: "[GET_INCIDENT_REPORT] Get Incident Report",
  GET_INCIDENT_REPORT_SUCCESS:
    "[GET_INCIDENT_REPORT_SUCCESS] Get Incident Report Successfull",
  GET_INCIDENT_REPORT_FAILURE:
    "[GET_INCIDENT_REPORT_FAILURE] Get Incident Report Failed",

  GET_INCIDENT_REPORT_INJURED_BODYPART:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART] Get Incident Injured body part Report",
  GET_INCIDENT_REPORT_INJURED_BODYPART_SUCCESS:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_SUCCESS] Get Incident Injured body part Report Successfull",
  GET_INCIDENT_REPORT_INJURED_BODYPART_FAILURE:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_FAILURE] Get Incident Injured body part Report Failed",

  GET_INCIDENT_REPORT_INJURED_BODYPART_LIST:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_LIST] Get Incident Injured body lIST part Report",
  GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_SUCCESS] Get Incident Injured body lIST part Report Successfull",
  GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_FAILURE] Get Incident Injured body lIST part Report Failed",

  GET_INCIDENT_REPORT_MOST_COMMON_INJURY:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY] Get Most common Injury Type Report",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_SUCCESS:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_SUCCESS] Get Most common Injury Type Report Successfull",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_FAILURE:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_FAILURE] Get Most common Injury Type Report Failed",

  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST] Get Most common Injury lIST Type Report",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_SUCCESS] Get Most common Injury lIST Type Report Successfull",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_FAILURE] Get Most common Injury lIST Type Report Failed",

  GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY:
    "[GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY] Get Most common day Injury",
  GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_SUCCESS:
    "[GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_SUCCESS] Get Most common day Injury Successfull",
  GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_FAILURE:
    "[GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_FAILURE] Get Most common day Injury Failed",
  // iNCIDENT/action items  WITH USER
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS] Get USER WITH MOST incidents",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_SUCCESS] Get USER WITH MOST incidents Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_FAILURE] Get USER WITH MOST incidents Failed",

  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST] Get USER WITH MOST incidents LIST",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_SUCCESS] Get USER WITH MOST incidents LIST Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_FAILURE] Get USER WITH MOST incidents LIST Failed",

  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS] Get USER WITH MOST ACTION ITEMS",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_SUCCESS]  Get USER WITH MOST ACTION ITEMS Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_FAILURE]  Get USER WITH MOST ACTION ITEMS Failed",

  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST] Get USER WITH MOST ACTION ITEMS lIST",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_SUCCESS] Get USER WITH MOST ACTION ITEMS lIST Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_FAILURE] Get USER WITH MOST ACTION ITEMS lIST Failed",

  PROJECT_WITH_MOST_INCIDENT:
    "[PROJECT_WITH_MOST_INCIDENT] Get Project With Most Incident",
  PROJECT_WITH_MOST_INCIDENT_SUCCESS:
    "[PROJECT_WITH_MOST_INCIDENT_SUCCESS] Get Project With Most Incident Successfull",
  PROJECT_WITH_MOST_INCIDENT_FAILURE:
    "[PROJECT_WITH_MOST_INCIDENT_FAILURE] Get Project With Most Incident Failed",

  PROJECT_WITH_MOST_INCIDENT_LIST:
    "[PROJECT_WITH_MOST_INCIDENT_LIST] Project WithMostIncident lIST",
  PROJECT_WITH_MOST_INCIDENT_LIST_SUCCESS:
    "[PROJECT_WITH_MOST_INCIDENT_LIST_SUCCESS] Get Project With Most Incident lIST Successfull",
  PROJECT_WITH_MOST_INCIDENT_LIST_FAILURE:
    "[PROJECT_WITH_MOST_INCIDENT_LIST_FAILURE] Get Project With Most Incident lIST Failed",

  VEHICLE_WITH_MOST_INCIDENT:
    "[VEHICLE_WITH_MOST_INCIDENT] Get VEHICLE With Most Incident",
  VEHICLE_WITH_MOST_INCIDENT_SUCCESS:
    "[VEHICLE_WITH_MOST_INCIDENT_SUCCESS] Get VEHICLE With Most Incident Successfull",
  VEHICLE_WITH_MOST_INCIDENT_FAILURE:
    "[VEHICLE_WITH_MOST_INCIDENT_FAILURE] Get VEHICLE With Most Incident Failed",

  VEHICLE_WITH_MOST_INCIDENT_LIST:
    "[VEHICLE_WITH_MOST_INCIDENT_LIST] VEHICLE WithMostIncident lIST",
  VEHICLE_WITH_MOST_INCIDENT_LIST_SUCCESS:
    "[VEHICLE_WITH_MOST_INCIDENT_LIST_SUCCESS] Get VEHICLE With Most Incident lIST Successfull",
  VEHICLE_WITH_MOST_INCIDENT_LIST_FAILURE:
    "[VEHICLE_WITH_MOST_INCIDENT_LIST_FAILURE] Get VEHICLE With Most Incident lIST Failed",

  OSHA_ESTABLISHMENT_LIST: "[OSHA_ESTABLISHMENT_LIST] OSHA establishment lIST",
  OSHA_ESTABLISHMENT_LIST_SUCCESS:
    "[OSHA_ESTABLISHMENT_LIST_SUCCESS] Get OSHA establishment list Successfull",
  OSHA_ESTABLISHMENT_LIST_FAILURE:
    "[OSHA_ESTABLISHMENT_LIST_FAILURE] Get OSHA establishment lIST Failed",

  POST_OSHA_ESTABLISHMENT_ADD_PENDING:
    "[POST_OSHA_ESTABLISHMENT_ADD_PENDING] OSHA establishment Add",
  POST_OSHA_ESTABLISHMENT_ADD_SUCCESS:
    "[POST_OSHA_ESTABLISHMENT_ADD_SUCCESS] Get OSHA establishment Add Successfull",
  POST_OSHA_ESTABLISHMENT_ADD_FAILURE:
    "[POST_OSHA_ESTABLISHMENT_ADD_FAILURE] Get OSHA establishment Add Failed",
  CLEAR_OSHA_ESTABLISHMENT:
    "[CLEAR_OSHA_ESTABLISHMENT][REQUEST] Clear Update OSHA establishment",

  POST_OSHA_ESTABLISHMENT_UPDATE_PENDING:
    "[POST_OSHA_ESTABLISHMENT_UPDATE_PENDING] OSHA establishment Update",
  POST_OSHA_ESTABLISHMENT_UPDATE_SUCCESS:
    "[POST_OSHA_ESTABLISHMENT_UPDATE_SUCCESS] Get OSHA establishment Update Successfull",
  POST_OSHA_ESTABLISHMENT_UPDATE_FAILURE:
    "[POST_OSHA_ESTABLISHMENT_UPDATE_FAILURE] Get OSHA establishment Update Failed",

  POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_PENDING:
    "[POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_PENDING] OSHA establishment update status",
  POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_SUCCESS:
    "[POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_SUCCESS] Get OSHA establishment update status Successfull",
  POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_FAILURE:
    "[POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_FAILURE] Get OSHA establishment update status Failed",

  GET_OSHA_ESTABLISHMENT_DETAIL_PENDING:
    "[GET_OSHA_ESTABLISHMENT_DETAIL_PENDING] OSHA establishment detail",
  GET_OSHA_ESTABLISHMENT_DETAIL_SUCCESS:
    "[GET_OSHA_ESTABLISHMENT_DETAIL_SUCCESS] Get OSHA establishment detail Successfull",
  GET_OSHA_ESTABLISHMENT_DETAIL_FAILURE:
    "[GET_OSHA_ESTABLISHMENT_DETAIL_FAILURE] Get OSHA establishment detail Failed",

  GET_OSHA_LOG_PENDING: "[GET_OSHA_LOG_PENDING] OSHA Log",
  GET_OSHA_LOG_SUCCESS: "[GET_OSHA_LOG_SUCCESS] Get OSHA Log Successfull",
  GET_OSHA_LOG_FAILURE: "[GET_OSHA_LOG_FAILURE] Get OSHA Log Failed",

  GET_OSHA_DETAILS_PENDING: "[GET_OSHA_DETAILS_PENDING] OSHA Details",
  GET_OSHA_DETAILS_SUCCESS:
    "[GET_OSHA_DETAILS_SUCCESS] Get OSHA Details Successfull",
  GET_OSHA_DETAILS_FAILURE:
    "[GET_OSHA_DETAILS_FAILURE] Get OSHA Details Failed",

  POST_OSHA_DETAILS_PENDING: "[POST_OSHA_DETAILS_PENDING] OSHA osha details",
  POST_OSHA_DETAILS_SUCCESS:
    "[POST_OSHA_DETAILS_SUCCESS] OSHA osha details Successfull",
  POST_OSHA_DETAILS_FAILURE:
    "[POST_OSHA_DETAILS_FAILURE] OSHA osha details Failed",
  CLEAR_Add_OSHA_DETAILS: "[CLEAR_Add_OSHA_DETAILS] clearosha details",

  GET_LIST_ADDED_IN_OSHA_PENDING:
    "[GET_LIST_ADDED_IN_OSHA_PENDING] Get List Added In OSHA Log",
  GET_LIST_ADDED_IN_OSHA_SUCCESS:
    "[GET_LIST_ADDED_IN_OSHA_SUCCESS] Get List Added In OSHA Successfull",
  GET_LIST_ADDED_IN_OSHA_FAILURE:
    "[GET_LIST_ADDED_IN_OSHA_FAILURE] Get List Added In OSHA Failed",

  GET_SUBMIT_DETAILS_TO_OSHA_PENDING:
    "[GET_SUBMIT_DETAILS_TO_OSHA_PENDING] Get Submit Details To Osha",
  GET_SUBMIT_DETAILS_TO_OSHA_SUCCESS:
    "[GET_SUBMIT_DETAILS_TO_OSHA_SUCCESS] Get Submit Details To Osha Successfull",
  GET_SUBMIT_DETAILS_TO_OSHA_FAILURE:
    "[GET_SUBMIT_DETAILS_TO_OSHA_FAILURE] Get Submit Details To Osha Failed",

  POST_SUBMIT_GET_OSHA_LOG:
    "[POST_SUBMIT_GET_OSHA_LOG_PENDING] Submit Get OSHA Log details",
  POST_SUBMIT_GET_OSHA_LOG_SUCCESS:
    "[POST_SUBMIT_GET_OSHA_LOG_SUCCESS] Submit Get OSHA Log  Successfull",
  POST_SUBMIT_GET_OSHA_LOG_FAILURE:
    "[POST_SUBMIT_GET_OSHA_LOG_FAILURE] Submit Get OSHA Log Failed",
  CLEAR_SUBMIT_GET_OSHA_LOG: "[CLEAR_SUBMIT_GET_OSHA_LOG] clear Submit Get OSHA Log",
};
