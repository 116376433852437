import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClientList from "../component/client/clientList";
import * as clientAction from "../../../redux/actions/clientAction";
import {
  ACCESS_DENIED,
  ADMIN_ADD_CLIENT,
  DASHBOARD,
} from "../../../constant/routeContant";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { toast, ToastContainer } from "react-toastify";
import { InactiveClient } from "../../../utils/helper";

export default function ClientListContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useSelector((state) => state.state);
  const [sendEmail, setSendEmail] = useState(false);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  useEffect(() => {
    if (!checkPrivileges([12, 67, 68, 69])) {
      navigate(DASHBOARD);
    }
    getStateData();
  }, []);

  const NavigateAddClientMethode = () => {
    if (InactiveClient()) return;
    navigate(ADMIN_ADD_CLIENT);
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    productId: PRODUCT_ID(),
    statusIds: "",
    search: "",
    isSendMail: false,
  };
  const clientListData = useSelector((state) => state.getClientListData);

  const getClients = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(clientAction.getClientList(data));
  };

  useEffect(() => {
    getClients(defaultData);
  }, []);

  const industryType = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.industryTypes
  );
  const industryTypeStatus = useSelector(
    (state) => state.getDropdownDataKeyValue.isLoading
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 19,
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    if (clientListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Clients has been sent to registered email ID");
    }
  }, [clientListData]);

  return (
    <>
      {checkPrivileges([12, 67, 68, 69]) ? (
        <>
          <ToastContainer />
          <ClientList
            getClients={getClients}
            clientListsData={clientListData}
            NavigateAddClient={NavigateAddClientMethode}
            isLoading={
              clientListData.isLoading ||
              stateData.isLoading ||
              industryTypeStatus
            }
            industryType={industryType}
            state={stateData && stateData.data?.getState}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
