import { userType } from "../constants/userType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "user",
  isError: false,
  isLoading: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const userReducerList = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.GET_USER_LIST_SUCCESS_EMAIL:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const searchUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_SEARCH_USER:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_SEARCH_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_SEARCH_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const userGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_GROUP_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_GROUP_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_GROUP_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_GROUP_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.POST_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.POST_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.POST_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const addUserGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.POST_USER_GROUP_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.POST_USER_GROUP_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.POST_USER_GROUP_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserListDetsilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_LIST_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_LIST_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_LIST_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.UPDATE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.UPDATE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.UPDATE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_LIST:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const deleteUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.DELETE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.DELETE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.DELETE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const searchAllUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_ALL_USER:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_ALL_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateUserGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.UPDATE_USER_GROUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.UPDATE_USER_GROUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.UPDATE_USER_GROUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_LIST:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const deleteUserGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.DELETE_USER_GROUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.DELETE_USER_GROUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.DELETE_USER_GROUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserGroupDetsilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_GROUP_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_GROUP_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const searchAllGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_SEARCH_GROUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_SEARCH_GROUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_SEARCH_GROUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const leaveGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.LEAVE_GROUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.LEAVE_GROUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.LEAVE_GROUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const userGroupListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_INVOLVED_GROUP_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_INVOLVED_GROUP_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_INVOLVED_GROUP_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const userConfigurationReducerList = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_CONFIGURATION_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_CONFIGURATION_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };

    case userType.GET_USER_CONFIGURATION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateUserConfigurationListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.UPDATE_USER_CONFIGURATION_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.UPDATE_USER_CONFIGURATION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.UPDATE_USER_CONFIGURATION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserConfigurationDetsilsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.GET_USER_CONFIGURATION_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_CONFIGURATION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_CONFIGURATION_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_USER:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateUserOnboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.UPDATE_USER_ONBOARD:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.UPDATE_USER_ONBOARD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.UPDATE_USER_ONBOARD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_USER:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const updateUserOnboardStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.UPDATE_USER_ONBOARD_STATUS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.UPDATE_USER_ONBOARD_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.UPDATE_USER_ONBOARD_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_USER:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const NonPrivilegeuserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_NON_PRIVILEGE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_NON_PRIVILEGE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_NON_PRIVILEGE_USER_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case userType.GET_NON_PRIVILEGE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteNonPrivilegeUserListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.DELETE_NON_PRIVILEGE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.DELETE_NON_PRIVILEGE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.DELETE_NON_PRIVILEGE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addNonPrivilegeUserListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.POST_NON_PRIVILEGE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.POST_NON_PRIVILEGE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.POST_NON_PRIVILEGE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_LIST:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getNonPrivilegeUserListDetsilsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateNonPrivilegeUserListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.UPDATE_NON_PRIVILEGE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.UPDATE_NON_PRIVILEGE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.UPDATE_NON_PRIVILEGE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_LIST:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const convertNonPrivilegeUserListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case userType.CONVERT_NON_PRIVILEGE_USER_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.CONVERT_NON_PRIVILEGE_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.CONVERT_NON_PRIVILEGE_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const recreateUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.POST_RECREATE_USERS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.POST_RECREATE_USERS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.POST_RECREATE_USERS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_USER:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getAssetOnUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_ASSET_ON_USER:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_ASSET_ON_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_ASSET_ON_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSupervisorOnAssetReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_SUPERVISOR_ON_ASSET:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_SUPERVISOR_ON_ASSET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_SUPERVISOR_ON_ASSET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const checkUserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.CHECK_USER_INFO:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.CHECK_USER_INFO_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.CHECK_USER_INFO_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_CHECK_USER_INFO:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const userByRoleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_USER_BY_ROLE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_USER_BY_ROLE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_BY_ROLE_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case userType.GET_USER_BY_ROLE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_USER_BY_ROLE_LIST:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const userSyncWithProcoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_SYNC_PROCORE_USER:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case userType.GET_SYNC_PROCORE_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case userType.GET_SYNC_PROCORE_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case userType.CLEAR_SYNC_PROCORE_USER:
    case userType.CLEAR_USER_BY_ROLE_LIST:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
