import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./viewCredential.scss";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import Buttons from "../../../library/custom/button/Button";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import FormDropBox from "../../../library/custom/formDropBox/FormDropBox";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { useNavigate } from "react-router-dom";
import { LOGIN_ORG_ID, ORG_ID } from "../../../constant/constant";
import {
  dateValiadtor,
  getCurrentDate,
  getFormatedDate,
} from "../../../utils/helper";
import Checkbox from "../../../library/checkbox/checkbox";
import FormDropZone from "../../../library/dropZone/FormDropZone";

export default function AddUserCredentials({
  addUserCredentials,
  editMode,
  credentialListData,
  getCredentials,
  configureMode,
  getCredentialViewDetsilsData,
  credentialNameIdss,
  isLoading,
}) {
  const [credentialsName, SetcredentialsName] = useState("");
  const [selectCredentialsName, setSelectCredentialsName] = useState("");
  const [issueBody, SetIssueBody] = useState("");
  const [credentialType, setCredentialType] = useState([]);
  const [credentialNumber, setCredentialNumber] = useState("");
  const [passingDate, setPassingDate] = useState(getCurrentDate());
  const [classId, setClassId] = useState("");
  const [checkBox, setcheckBox] = useState(false);
  const [photos, setPhotos] = useState("");
  const [expirationDate, setExpirationDate] = useState(getCurrentDate());
  const [credentialNameId, setcredentialNameId] = useState();
  const [credentialsNameError, SetcredentialsNameError] = useState("");
  const [issueBodyError, SetIssueBodyError] = useState("");
  const [credentialNumberError, setCredentialNumberError] = useState("");
  const [passingDateError, setPassingDateError] = useState("");
  const [passingDateErrMsg, setPassingDateErrMsg] = useState("");
  const [newDate, setNewDate] = useState(null);

  useEffect(() => {
    setcheckBox("");
    if (getCredentialViewDetsilsData?.data && (editMode || configureMode)) {
      SetcredentialsName(
        getCredentialViewDetsilsData?.data?.result?.credentialName
      );
      setSelectCredentialsName({
        key: getCredentialViewDetsilsData?.data?.result?.credentialNameId,
        value: getCredentialViewDetsilsData?.data?.result?.credentialName,
      });
      SetIssueBody(getCredentialViewDetsilsData?.data?.result?.issuingBodyId);
      setCredentialType(
        getCredentialViewDetsilsData?.data?.result?.credentialTypeId
      );
      setCredentialNumber(
        getCredentialViewDetsilsData?.data?.result?.credentialNumber
      );
      setNewDate(getCredentialViewDetsilsData?.data?.result?.expirationPeriod);
      setPassingDate(getCredentialViewDetsilsData?.data?.result?.passingDate);
      setClassId(getCredentialViewDetsilsData?.data?.result?.classId);
      setExpirationDate(
        getCredentialViewDetsilsData?.data?.result?.expirationDate
      );
      // setcheckBox(
      //   !getCredentialViewDetsilsData?.data?.result?.isExpirationDate
      // );
      if (
        editMode &&
        getCredentialViewDetsilsData?.data?.result?.expirationPeriod === 0 &&
        getCredentialViewDetsilsData?.data?.result?.expirationDate === null
      ) {
        setcheckBox(true);
      }
      setissuingBodys([
        {
          key: getCredentialViewDetsilsData?.data?.result?.issuingBodyId,
          value: getCredentialViewDetsilsData?.data?.result?.issuingBody,
        },
      ]);
      setcredentialNameId(
        getCredentialViewDetsilsData?.data?.result?.credentialNameId
      );
      setPhotos(getCredentialViewDetsilsData?.data?.result?.imageUrls[0]);
    }
  }, [getCredentialViewDetsilsData?.data]);

  const [maxDate, setMaxxDate] = useState(null);
  useEffect(() => {
    if (newDate) {
      const getYears = new Date(passingDate).getFullYear() + newDate;
      const getmonths = new Date(passingDate).getMonth() + 1;
      const getdates = new Date(passingDate).getDate();
      setMaxxDate(`${getmonths}-${getdates}-${getYears}`);
    }
  }, [newDate, passingDate]);

  const history = useNavigate();

  const addUserCredentialAction = () => {
    let isFieldEmpty = false;
    if (!credentialsName) {
      SetcredentialsNameError(true);
      isFieldEmpty = true;
    }
    if (!issueBody) {
      SetIssueBodyError(true);
      isFieldEmpty = true;
    }
    console.log("credentialNumber", credentialNumber);
    if (!credentialNumber || credentialNumber.trim() === "") {
      setCredentialNumber("");
      setCredentialNumberError(true);
      isFieldEmpty = true;
    }
    if (!passingDate) {
      setPassingDateError(true);
      isFieldEmpty = true;
    }

    if (passingDate) {
      const validDate = dateValiadtor(getFormatedDate(passingDate));
      if (validDate === false) {
        setPassingDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setPassingDateErrMsg(false);
        // isFieldEmpty = false;
      }
    }

    if (!isFieldEmpty) {
      let modifiedsBy = editMode || configureMode ? "modifiedBy" : "createdBy";
      let credData = {
        credentialAssignId: 0,
        credentialNameId: credentialNameId,
        issuingBodyId: issueBody,
        credentialTypeId: credentialType,
        credentialNumber: credentialNumber,
        passingDate: passingDate ? getFormatedDate(passingDate) : "",
        classId: classId,
        isExpirationDate: !checkBox,
        imageUrls: photos,
        organisationId: ORG_ID(),
        credentialId: credentialNameIdss,
        [modifiedsBy]: localStorage.getItem("userId"),
      };
      if (!checkBox) {
        credData.expirationDate = expirationDate
          ? getFormatedDate(expirationDate)
          : "";
      }
      addUserCredentials(credData);
    }
  };

  useEffect(() => {
    setExpirationDate(maxDate);
  }, [maxDate]);

  const onChangeCredential = (event) => {
    const defaultData = {
      search: event.target.value,
      orgId: LOGIN_ORG_ID(),
    };
    getCredentials(defaultData);
  };

  const [issuingBodys, setissuingBodys] = useState([]);
  const onSelectSearchCredential = (v1, v2) => {
    SetcredentialsName(v2.value);
    setcredentialNameId(v2.key);
    setissuingBodys(v2?.issuingBodies);
    setCredentialType(v2?.credentialTypeId);
    setNewDate(v2.expirationPeriod);
    setSelectCredentialsName(v2);
  };
  const onChangeIssuingBody = (event) => {
    SetIssueBody(event.target.value);
  };

  const onChangeCredentialNumber = (e) => {
    setCredentialNumber(e.target.value);
  };

  const onchangePassingdatePicker = (e) => {
    setPassingDateErrMsg(false);
    setPassingDate(e);
    setExpirationDate(maxDate);
  };

  const onChangeClassId = (e) => {
    setClassId(e.target.value);
  };
  const onchangeExpirationdatePicker = (e) => {
    setExpirationDate(e);
  };

  const handleFileChange = (photos) => {
    setPhotos(photos);
  };

  const handleDeleteImageURL = (urls) => {
    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
  };

  return (
    <div className="overidePersonal">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">
          {configureMode
            ? "Configure Credential"
            : editMode
            ? "Edit Credential"
            : "Add Credential"}
        </div>
      </Grid>
      <Grid lg={12} className="addShadowBox">
        <Grid item container mt={2} spacing={2}>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Credential Name" isRequired={true} />
              {!configureMode && !editMode && (
                <SearchAutoComplete
                  name="credential"
                  optionsData={
                    isLoading ? [] : credentialListData?.data?.result ?? []
                  }
                  typeOnchange={(event, value) => onChangeCredential(event)}
                  isKeyValuePair={true}
                  disabled={configureMode && editMode}
                  value={selectCredentialsName}
                  onChange={(event, value) =>
                    onSelectSearchCredential(event, value)
                  }
                  isLoading={isLoading}
                  noOptionsTextMsg="No credential"
                />
              )}
            </FormControl>
            {credentialsNameError && !credentialsName && (
              <div className="errorMsg">Please enter this required field</div>
            )}
            {editMode && credentialsName && (
              <p className="credentialName">{credentialsName}</p>
            )}
            {configureMode && credentialsName && (
              <p className="credentialFieldStyle">{credentialsName}</p>
            )}
          </Grid>

          <Grid item lg={5} xs={12} sm={12} md={4} className="SelectMenuValue">
            <FormControl fullWidth>
              <FormLabels label="Issuing Body" isRequired={true} />
              <SelectMenu
                customClass={"dropCustom"}
                disabled={configureMode}
                listData={issuingBodys || []}
                value={issueBody}
                onchangehandler={(e) => onChangeIssuingBody(e)}
              />
            </FormControl>
            {issueBodyError && !issueBody && (
              <div className="errorMsg">
                Selection is required for this field
              </div>
            )}
          </Grid>
        </Grid>

        <Grid item container mt={1} spacing={3}>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <FormLabels
              label="Credential Type"
              isRequired={false}
              disabled={configureMode}
            />

            {credentialType === 0 && (
              <div className="credType">Certificate</div>
            )}
            {credentialType === 1 && <div className="credType">License</div>}
            {credentialType === 2 && <div className="credType">Degree</div>}
          </Grid>
        </Grid>
        <Grid item container mt={2} spacing={2}>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <InputField
              isRequired={true}
              type="text"
              label="Credential Number"
              value={credentialNumber}
              onChange={(e) => onChangeCredentialNumber(e)}
            />
            {credentialNumberError && !credentialNumber && (
              <div className="errorMsg">Please enter this required field</div>
            )}
          </Grid>
        </Grid>
        <Grid item container mt={2} spacing={2}>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <FormLabels label={"Passing Date"} isRequired={true} />
            <DatePicker
              maxDateEnable={false}
              value={passingDate}
              disabled={false}
              onChangeHandler={(e) => onchangePassingdatePicker(e)}
            />
            {passingDateError && !passingDate && (
              <div className="errorMsg">Passing Date is required</div>
            )}
            {passingDateErrMsg && passingDate != null && (
              <div className="errorMsg">Invalid Date</div>
            )}
          </Grid>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <InputField
              isRequired={false}
              type="text"
              label="Class ID"
              value={classId}
              onChange={(e) => onChangeClassId(e)}
            />
          </Grid>
        </Grid>

        <Grid item container mt={2} spacing={2}>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <FormLabels label={"Expiration Date"} isRequired={false} />
            <DatePicker
              value={expirationDate}
              minDate={passingDate}
              disabled={checkBox}
              onChangeHandler={(e) => onchangeExpirationdatePicker(e)}
              maxDate={maxDate}
            />
          </Grid>
          {newDate <= 0 ||
            (getCredentialViewDetsilsData?.data?.result?.expirationPeriod ===
              0 && (
              <Grid item lg={5} xs={12} sm={12} md={4} mt={4.5}>
                <div className="typeTxt">
                  <Checkbox
                    checked={checkBox}
                    onChange={() => setcheckBox(!checkBox)}
                    label={"No Expiration Date"}
                  />
                </div>
              </Grid>
            ))}
        </Grid>

        <Grid item container mt={2} spacing={2}>
          <Grid item lg={10} xs={12} sm={12} md={8}>
            <FormLabels label={"Add Certificate file"} />
            <>
              <FormDropZone
                paraText1={"Drop the files here ..."}
                paraText2={"Drop Images/files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange}
                deleteImageURL={(url) => handleDeleteImageURL(url)}
                isDynamics={true}
                isRequired={true}
                fileType={10}
                isMuliple={false}
                imageUrl={photos}
                id="addPhoto"
                key="addPhoto"
                isDoc={true}
                isImage={false}
                allFiles={true}
              />
              <p class="fileTypeSupport">Supported file types: images or pdf</p>
            </>
          </Grid>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left"></Grid>
          <Grid lg={2} xs={12} sm={6} md={4}>
            <Buttons
              varientText={true}
              label="Cancel"
              onClick={() => history(-1)}
            />
          </Grid>
          <Grid lg={2} xs={12} sm={6} md={4}>
            <Buttons
              varientContained={true}
              label={editMode || configureMode ? "Update" : "Save"}
              onClick={() => addUserCredentialAction()}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
