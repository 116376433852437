import React, { useState } from "react";
import SwSummaryreport from "../components/SwSummaryreport";
import { useDispatch } from "react-redux";
import * as reportsAction from "../../../../redux/storm-water/actions/reportsAction";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../../constant/routeContant";

function SwSummaryReportContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getOrganisationSummaryList = useSelector(
    (state) => state.organisationSummaryData
  );

  const getInspectorSummaryList = useSelector(
    (state) => state.inspectorSummaryData
  );

  const getDateWiseSummaryList = useSelector(
    (state) => state.dateWiseSummaryData
  );
  const getPerDayInspectorSummaryList = useSelector(
    (state) => state.perDayInspectorSummaryData
  );
  const getDateWiseInspectorSummaryList = useSelector(
    (state) => state.dateWiseInspectorSummaryData
  );

  const isLoading =
    getOrganisationSummaryList.isLoading ||
    getInspectorSummaryList.isLoading ||
    getDateWiseSummaryList.isLoading ||
    getPerDayInspectorSummaryList.isLoading ||
    getDateWiseInspectorSummaryList.isLoading;

  const getOrganisationSummaryListData = (data) => {
    dispatch(reportsAction.getOrganisationSummaryList(data));
  };
  const getInspectorSummaryListData = (data) => {
    dispatch(reportsAction.getInspectorSummaryList(data));
  };
  const getDateWiseSummaryListData = (data) => {
    dispatch(reportsAction.getDateWiseSummaryList(data));
  };
  const getPerDayInspectorSummaryListData = (data) => {
    dispatch(reportsAction.getPerDayInspectorSummaryList(data));
  };
  const getDateWiseInspectorSummaryListData = (data) => {
    dispatch(reportsAction.getDateWiseInspectorSummaryList(data));
  };
  const [isReportType, setIsReportType] = useState("");

  const checkReportType = (reportType) => {
    setIsReportType(reportType);
  };

  return (
    <>
      {checkPrivileges([12, 139, 142]) ? (
        <>
          <SwSummaryreport
            checkReportType={checkReportType}
            getOrganisationSummaryListData={getOrganisationSummaryListData}
            getOrganisationSummaryList={getOrganisationSummaryList}
            isLoading={isLoading}
            getInspectorSummaryListData={getInspectorSummaryListData}
            getInspectorSummaryList={getInspectorSummaryList}
            getDateWiseSummaryListData={getDateWiseSummaryListData}
            getDateWiseSummaryList={getDateWiseSummaryList}
            getPerDayInspectorSummaryListData={
              getPerDayInspectorSummaryListData
            }
            getPerDayInspectorSummaryList={getPerDayInspectorSummaryList}
            getDateWiseInspectorSummaryListData={
              getDateWiseInspectorSummaryListData
            }
            getDateWiseInspectorSummaryList={getDateWiseInspectorSummaryList}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default SwSummaryReportContainer;
