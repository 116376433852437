import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import {
  OPEN_ITEMS_LIST,
  VIEW_INCIDENT_REPORT,
} from "../../../constant/routeContant";
import TextArea from "../../../library/custom/textArea/TextArea";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import FormDropZone from "../../../library/dropZone/FormDropZone";
const NearMissesActionReport = ({
  nearMissData,
  actionReportId,
  closeAction,
  actionStatus,
  actionIncidentId,
  actionItemTypeId,
}) => {
  const imageSize = {
    size: "small",
  };

  const data = nearMissData?.actions.filter(
    (x) => x.actionId === actionReportId
  );

  const handleFileChange = (files, single) => {
    setCloseFilesData({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL = () => {
    setCloseFilesData([]);
  };
  const caseData = nearMissData?.incCase;
  const caseDetail = {
    involvedparty: caseData?.createdByName,
    dateOfIncident: caseData?.dateOfCase,
    timeOfIncident: caseData?.timeOfCase,
    location: caseData?.location,
    caseId: caseData?.caseId,
  };
  const location = useLocation();
  const locationState = location?.state;
  const dataBack = {
    title: locationState?.backRouteText
      ? locationState?.backRouteText
      : "Back to Open Items",
    route: locationState?.backRoute
      ? locationState?.backRoute
      : OPEN_ITEMS_LIST,
    state: locationState?.apiData,
  };
  const [isNote, setIsNote] = useState(false);
  const [closesFilesData, setCloseFilesData] = useState("");
  const [formValue, setFormValue] = useState({
    actionId: 0,
    note: "",
  });
  const navigate = useNavigate();
  function mappedData() {
    let errors = false;

    if (formValue.note?.length === 0) {
      setIsNote(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const closeData = {
      actionId: actionReportId,
      note: formValue.note,
      files: [closesFilesData?.fileId ? closesFilesData?.fileId : 0],
    };
    return closeData;
  }

  const submitData = () => {
    const submitData = mappedData();
    if (!submitData) {
    } else {
      closeAction(submitData);
    }
  };
  const correctiveMedia = nearMissData?.actions[0]?.closedFiles[0];
  const media = correctiveMedia ? correctiveMedia?.filePath?.split("|") : "";
  const IncReportId = nearMissData?.incidentId;
  const incidentDetailDetails = (id) => {
    navigate(VIEW_INCIDENT_REPORT, {
      state: {
        incidentId: IncReportId,
        caseId: actionIncidentId,
        incidentType: actionItemTypeId,
      },
    });
  };
  return (
    <div className="observationOveride">
      <div className="commonOveride">
        <Box className="commoninfoWrap">
          {data?.map((list) => {
            const image = list?.files
              ? list?.files[0]?.filePath?.split("|")
              : "";
            return (
              <>
                <Box className="commoninfoBox">
                  <Box className="commoninfoleft">
                    <Typography className="titleAction">
                      Action Assigned
                    </Typography>
                    <Typography className="CaseID" mt={4}>
                      Case ID:{" "}
                      <span className="caseNo">{caseDetail?.caseId}</span>
                    </Typography>
                    <Typography
                      className="reportid"
                      mt={4}
                      onClick={() => incidentDetailDetails()}
                    >
                      Report ID: <span>{IncReportId}</span>
                      <span className="redirectPage">
                        <RemoveRedEye />
                      </span>
                    </Typography>
                    <Box className="columns" mt={4}>
                      <Typography className="listView">
                        Corrective Party:
                        <span className="font-w400">
                          {list?.assignedPartyName}
                        </span>
                      </Typography>
                    </Box>
                    <Box className="columns">
                      <Typography className="listView">
                        Date of Incident:
                        <span className="font-w400">
                          {caseDetail?.dateOfIncident}
                        </span>
                      </Typography>
                      <Typography className="listView">
                        Time of Incident:
                        <span className="font-w400">
                          {caseDetail?.timeOfIncident}
                        </span>
                      </Typography>
                    </Box>
                    <Box className="columns">
                      <Typography className="listView">
                        Location:
                        <span className="font-w400">
                          {caseDetail?.location}
                        </span>
                      </Typography>
                    </Box>

                    <Typography className="listView ">
                      Action Assigned:
                      <span className="blockView">
                        {" "}
                        {parse(unescape(list?.description))}
                      </span>
                    </Typography>
                    <Typography className="listView ">
                      Media:
                      <span className="blockView">
                        <ViewImages image={image} imageSize={imageSize} />
                      </span>
                    </Typography>
                    {actionStatus === "Closed" ? (
                      <>
                        <Typography className="listView ">
                          Comments:
                          <span className="blockView">{list?.closeNote}</span>
                        </Typography>
                        <Typography className="listView ">
                          Media:
                          <span className="blockView">
                            <ViewImages image={media} imageSize={imageSize} />
                          </span>
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box className="commoninfoRight">
                    <Grid mt={4}>
                      <Typography className="incstatus">
                        Action Status
                      </Typography>
                      {actionStatus === "Closed" ? (
                        <Button
                          variant="contained"
                          className="statusbtn cursonDefault"
                        >
                          {actionStatus}
                        </Button>
                      ) : actionStatus === "Open" ? (
                        <Button
                          variant="contained"
                          className="statusbtnOpen cursonDefault"
                        >
                          {actionStatus}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Box>
                </Box>
              </>
            );
          })}
        </Box>

        {actionStatus === "Closed" ? (
          ""
        ) : (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Comments" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                value={formValue.note}
                onChange={(e) => {
                  if (e.target.value === " " && formValue.note.trim() === "")
                    setFormValue({
                      ...formValue,
                      note: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      note: e.target.value,
                    });
                  setIsNote(false);
                }}
                isError={isNote}
              />
              {isNote ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}
              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange}
                fileType={10}
                imageUrl={closesFilesData?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL()}
                id="addClosed"
                key="addClosed"
                isImage={false}
                isMultipleIcon={true}
                allImages={true}
              />
            </Grid>
          </Typography>
        )}

        <Box className="escalatereprimand">
          <Grid mt={4}>
            <BackBtn dataBack={dataBack} className="equipmentBack" />
          </Grid>
          <Grid mt={4} style={{ marginRight: "-240px" }}>
            {actionStatus === "Closed" ? (
              ""
            ) : (
              <Button
                varientContained={true}
                className="closereprimandbtn"
                onClick={submitData}
              >
                Close Item
              </Button>
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default NearMissesActionReport;
