import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { COLORS } from "../../../../constant/constant";
import { useThemeContext } from "../../../../context";
import { color } from "@mui/system";

export function LineChart({ data }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const { mode } = useThemeContext();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          font: {
            family: "Lato",
          },
        },
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
      },
    },
    scales: {
      x: {
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  const labels = data?.map((itm) => itm.date);

  const dataChart = {
    labels,
    datasets: [
      {
        fill: false,
        label: "Total Inspection",
        data: data?.map((itm) => itm.totalInspections),
        borderColor: "#21CBE2",
        backgroundColor: "#21CBE2",
        color: "white",
      },
    ],
  };

  return (
    <div style={{ width: "100%px", height: "400px" }}>
      <Line options={options} data={dataChart} />
    </div>
  );
}
