export const contractorType = {
  GET_CONTRACTOR_LIST_PENDING:
    "[GET_CONTRACTOR_LIST][REQUEST] Get CONTRACTOR list requested",
  GET_CONTRACTOR_LIST_FAILURE:
    "[GET_CONTRACTOR_LIST][RESPONSE] Get CONTRACTOR list Failed",
  GET_CONTRACTOR_LIST_SUCCESS:
    "[GET_CONTRACTOR_LIST][RESPONSE] Get CONTRACTOR list Successfull",
  GET_CONTRACTOR_LIST_EMAIL_SUCCESS:
    "[GET_CONTRACTOR_LIST_EMAIL_SUCCESS][RESPONSE] Send CONTRACTOR list on email Successfull",
  CASE_PAGE: "CONTRACTORPage",

  POST_CONTRACTOR_PENDING: "[CONTRACTOR][REQUEST] Create CONTRACTOR",
  POST_CONTRACTOR_FAILURE: "[CONTRACTOR][RESPONSE] CONTRACTOR Creation Failed",
  POST_CONTRACTOR_SUCCESS:
    "[CONTRACTOR][RESPONSE] CONTRACTOR Created Successfull",
  CREATE_PAGE: "CreatePage",

  UPDATE_CONTRACTOR_PENDING: "UPDATE_CONTRACTOR_PENDING",
  UPDATE_CONTRACTOR_SUCCESS: "UPDATE_CONTRACTOR_SUCCESS",
  UPDATE_CONTRACTOR_FAILED: "UPDATE_CONTRACTOR_FAILED",
  UPDATE_PAGE: "UpdatePage",
  CLEAR_CONTRACTOR: "[CLEAR_CONTRACTOR][REQUEST] Clear CONTRACTOR",

  GET_CONTRACTOR_DETAILS_PENDING: "GET_CONTRACTOR_DETAILS_PENDING",
  GET_CONTRACTOR_DETAILS_SUCCESS: "GET_CONTRACTOR_DETAILS_SUCCESS",
  GET_CONTRACTOR_DETAILS_FAILED: "GET_CONTRACTOR_DETAILS_FAILED",

  DELETE_CONTRACTOR_PENDING: "DELETE_CONTRACTOR",
  DELETE_CONTRACTOR_SUCCESS: "DELETE_CONTRACTOR_SUCCESS",
  DELETE_CONTRACTOR_FAILED: "DELETE_CONTRACTOR_FAILED",

  GET_PERSONAL_CONTRACTOR_LIST_PENDING:
    "[GET_PERSONAL_CONTRACTOR_LIST][REQUEST] Get CONTRACTOR list requested",
  GET_PERSONAL_CONTRACTOR_LIST_FAILURE:
    "[GET_PERSONAL_CONTRACTOR_LIST][RESPONSE] Get CONTRACTOR list Failed",
  GET_PERSONAL_CONTRACTOR_LIST_SUCCESS:
    "[GET_PERSONAL_CONTRACTOR_LIST][RESPONSE] Get CONTRACTOR list Successfull",
  GET_PERSONAL_CONTRACTOR_LIST_EMAIL_SUCCESS:
    "[GET_PERSONAL_CONTRACTOR_LIST_EMAIL_SUCCESS][RESPONSE] Send Personal CONTRACTOR list Successfull",
  // CASE_PAGE: "CONTRACTORPage",

  POST_PERSONAL_CONTRACTOR_PENDING:
    "[CONTRACTOR_PERSONAL][REQUEST] Create CONTRACTOR",
  POST_PERSONAL_CONTRACTOR_FAILURE:
    "[CONTRACTOR_PERSONAL][RESPONSE] CONTRACTOR Creation Failed",
  POST_PERSONAL_CONTRACTOR_SUCCESS:
    "[CONTRACTOR_PERSONAL][RESPONSE] CONTRACTOR Created Successfull",

  UPDATE_PERSONAL_CONTRACTOR_PENDING: "UPDATE_PERSONAL_CONTRACTOR_PENDING",
  UPDATE_PERSONAL_CONTRACTOR_SUCCESS: "UPDATE_PERSONAL_CONTRACTOR_SUCCESS",
  UPDATE_PERSONAL_CONTRACTOR_FAILED: "UPDATE_PERSONAL_CONTRACTOR_FAILED",
  // UPDATE_PAGE: "UpdatePage",
  // CLEAR_CONTRACTOR: "[CLEAR_PERSONAL_CONTRACTOR][REQUEST] Clear CONTRACTOR",

  GET_PERSONAL_CONTRACTOR_DETAILS_PENDING:
    "GET_PERSONAL_CONTRACTOR_DETAILS_PENDING",
  GET_PERSONAL_CONTRACTOR_DETAILS_SUCCESS:
    "GET_PERSONAL_CONTRACTOR_DETAILS_SUCCESS",
  GET_PERSONAL_CONTRACTOR_DETAILS_FAILED:
    "GET_PERSONAL_CONTRACTOR_DETAILS_FAILED",

  DELETE_PERSONAL_CONTRACTOR_PENDING: "DELETE_PERSONAL_CONTRACTOR",
  DELETE_PERSONAL_CONTRACTOR_SUCCESS: "DELETE_PERSONAL_CONTRACTOR_SUCCESS",
  DELETE_PERSONAL_CONTRACTOR_FAILED: "DELETE_PERSONAL_CONTRACTOR_FAILED",

  GET_ONBOARDED_CONTRACTOR_LIST_PENDING:
    "[GET_ONBOARDED_CONTRACTOR_LIST][REQUEST] Get Onboarded Contractor list requested",
  GET_ONBOARDED_CONTRACTOR_LIST_FAILURE:
    "[GET_ONBOARDED_CONTRACTOR_LIST][RESPONSE] Get Onboarded Contractor list Failed",
  GET_ONBOARDED_CONTRACTOR_LIST_SUCCESS:
    "[GET_ONBOARDED_CONTRACTOR_LIST][RESPONSE] Get Onboarded Contractor Successfull",
  GET_ONBOARDED_CONTRACTOR_LIST_EMAIL_SUCCESS:
    "[GET_ONBOARDED_CONTRACTOR_LIST_EMAIL_SUCCESS][RESPONSE] Send Onboarded Contractor Successfull",

  UPDATE_ONBOARDED_CONTRACTOR_LIST:
    "[REQUEST] Update Onboarded Contractor List Data",
  UPDATE_ONBOARDED_CONTRACTOR_LIST_SUCCESS:
    "[RESPONSE] Update Onboarded Contractor List Data Successfull",
  UPDATE_ONBOARDED_CONTRACTOR_LIST_FAILURE:
    "[RESPONSE] Update Onboarded Contractor List Data Failed",

  GET_CONTRACTOR_CONFIGURATION_DETAILS:
    "[REQUEST] Get Contractor Configuration Details Data",
  GET_CONTRACTOR_CONFIGURATION_DETAILS_SUCCESS:
    "[RESPONSE] Get Contractor Configuration Details Data Successfull",
  GET_CONTRACTOR_CONFIGURATION_DETAILS_FAILURE:
    "[RESPONSE] Get Contractor Configuration Details  Data Failed",

  UPDATE_CONTRACTOR_ONBOARD: "[REQUEST] Update Contractor Onboard Data",
  UPDATE_CONTRACTOR_ONBOARD_SUCCESS:
    "[RESPONSE] Update Contractor Onboard Data Successfull",
  UPDATE_CONTRACTOR_ONBOARD_FAILURE:
    "[RESPONSE] Update Contractor Onboard  Data Failed",

  UPDATE_CONTRACTOR_ONBOARD_STATUS:
    "[REQUEST] Update Contractor Onboard Status Data",
  UPDATE_CONTRACTOR_ONBOARD_STATUS_SUCCESS:
    "[RESPONSE] Update Contractor Onboard Status Data Successfull",
  UPDATE_CONTRACTOR_ONBOARD_STATUS_FAILURE:
    "[RESPONSE] Update Contractor Onboard Status Data Failed",

  // CLEAR_CONTRACTOR: "[CLEAR_CONTRACTOR][REQUEST] Clear Contractor",

  GET_SEARCH_SUBCONTRACTOR: "[REQUEST] Search Sub Contractor Data",
  GET_SEARCH_SUBCONTRACTOR_SUCCESS:
    "[RESPONSE] Seach Sub Contractor Data Successfull",
  GET_SEARCH_SUBCONTRACTOR_FAILURE:
    "[RESPONSE] Seacrh Sub Contractor Data Failed",

  POST_CONTRACTOR_CLIENT_WISE_PENDING:
    "[CONTRACTOR][REQUEST] Create CONTRACTOR Client wise",
  POST_CONTRACTOR_CLIENT_WISE_FAILURE:
    "[CONTRACTOR][RESPONSE] CONTRACTOR Client wise Creation Failed",
  POST_CONTRACTOR_CLIENT_WISE_SUCCESS:
    "[CONTRACTOR][RESPONSE] CONTRACTOR Client wise Created Successfull",
  CREATE_CONTACT_CLIENTWISE_PAGE: "CONTRACTCLIENTWISEORPage",
};
