import React, { useState, useEffect, useMemo } from "react";
import AddInspection from "../component/addInspection";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  ORG_ID,
  SIGNOFF_METHOD_TYPE,
  USER_ID,
} from "../../../constant/constant";
import {
  getQuestionSet,
  getQuesDynamicField,
  postInpectionData,
  getReferenceData,
} from "../../../redux/actions/incpectionAction";
import Loader from "../../../library/common/Loader";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import { logEvent, validateFileSize } from "../../../utils/helper";
import {
  deleteImageHandler,
  editFieldValues,
  editListInspectionCategory,
  fileUploadHandler,
  formchangeHandler,
  getAnswerValue,
  listInspectionCategoryObject,
} from "../../../utils/incpectionHelperNew";
import * as userAction from "../../../redux/actions/userAction";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { VIEW_INSPECTION_REPORT } from "../../../constant/routeContant";
import { useLocation } from "react-router-dom";
import * as viewInspectionAction from "../../../redux/actions/incpectionAction";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { fileUpload } from "../../../services/fileUploadService";

const AddInspectionNewContainer = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${formattedMinutes}`;
  const dispatch = useDispatch();
  const location = useLocation();
  const addInspection = location?.state?.addInspection;
  const inspectionId = location?.state?.row?.reportId ?? 0;
  const editMode = location?.state?.editMode ?? false;
  const [assetTypeValue, setAssetTypeValue] = useState();
  const [assets, setAssets] = useState();
  const [inspectionDate, setInspectionDate] = useState(date);
  const [inspectionTime, setInspectionTime] = useState(time);
  const [carouselValue, setcarouselValue] = useState({});
  const [questionSet, setQuestionSet] = useState(0);
  const [fieldValues, setFieldValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [listInspectionCategory, setListInspectionCategory] = useState();
  const HBNUser = checkPrivileges([12, 139, 144]);
  const [signoffMethodValue, setSignoffMethodValue] = useState();
  const [signatureUrl, setSignatureUrl] = useState();
  const [certifyStatementIdValue, setCertifyStatementIdValue] = useState();
  const SIGNATUREURL = localStorage.getItem("signatureUrl");
  const [signaureFileErrorMessage, setSignaureFileErrorMessage] = useState();
  const [signaureFileError, setSignaureFileError] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [inspectorSignUpload, setInspectorSignUpload] = useState(false);
  const [signatureError, setSignatureError] = useState(false);
  const [statusId, setStatusId] = useState();
  const [inspectionHederFields, setInspectionHederFields] = useState();
  const [acknowledgement, setAcknowledgement] = useState("");

  const initialCommonFormValue = {
    inspectionId: 0,
    questionSetId: questionSet,
    preparedBy: USER_ID(),
    assetType: assetTypeValue ?? 0,
    signature: "",
  };
  const [commonFormValue, setCommonFormValue] = useState(
    initialCommonFormValue
  );

  const allUsers = useSelector((state) => state.allUsers);

  const allAssets = useSelector((state) => state.getUserAssetList);
  const getAllAssets = (data) => {
    dispatch(assetsAction.getUserAssetList(data));
  };

  const inspectionAcknowledgementType = {
    type: 5,
  };

  const getInspectionAcknowledgement = useSelector(
    (state) => state.getInspectionAcknowledgement
  );
  const getInspectionAcknowledgementData = (data) => {
    dispatch(incpectionAction.getInspectionAcknowledgement(data));
  };

  function handleAllUserSearch(
    event,
    type,
    divisionId,
    assetType,
    assets,
    priviledgeId,
    productId,
    orgId,
    withSpace
  ) {
    if (assets) {
      dispatch(
        userAction.searchAllUsers(
          event.target.value,
          type,
          divisionId,
          assets,
          assetTypeValue,
          priviledgeId,
          productId,
          orgId,
          withSpace
        )
      );
    }
  }
  const isAutoSearchUserLoading = allUsers.isLoading;
  const handleAssetChange = (e) => {
    setAssetTypeValue(e.target.value);
    setcarouselValue({});
    setCommonFormValue({
      ...initialCommonFormValue,
      assetType: assetTypeValue ?? 0,
    });
    setFieldValues([]);
  };

  const quetionSetChange = (value) => {
    setFieldValues([]);
    setcarouselValue({ id: value.id });
    setQuestionSet(value.id);
    const data = {
      questionSetId: value.id,
    };
    getInspectionFieldsMap(data);
    setCommonFormValue({ ...commonFormValue, questionSetId: value.id });
    const getQuesDynamicFieldData = {
      questSetId: value.id,
    };
    dispatch(getQuesDynamicField({ getQuesDynamicFieldData }));
  };

  const {
    quesSet,
    dynamicFeild,
    isSuccess,
    successData,
    quesSetDetails,
    isInLoading,
    isLoading,
    signoffMethod,
    certifyStatementId,
    weather
  } = useSelector((state) => state.inspection);

  const inspectionFieldsMap = useSelector((state) => state.inspectionFieldsMap);

  const getInspectionFieldsMap = (data) => {
    dispatch(incpectionAction.getInspecionFieldsByQuestionSet(data));
  };

  const isQuestionSetLoading = useSelector(
    (state) => state.inspectionFieldsMap?.isLoading
  );
  const assetOption = useSelector((state) => state.getAssetDynomoList.data);
  const isAssetLoading = useSelector(
    (state) => state.getAssetDynomoList.isLoading
  );

  const searchAsset = (searchText, type) => {
    dispatch(assetsAction.getAssetDynomoList({ searchText, type }));
  };

  const inspectionViolationCounts = useSelector(
    (state) => state.getInspectionViolationCounts
  );
  const inspectionViolationCountsData = (data) => {
    dispatch(incpectionAction.getInspectionViolationCounts(data));
  };
  const countLoader = useSelector(
    (state) => state.getInspectionViolationCounts.isLoading
  );

  const assetLoader = useSelector((state) => state.getUserAssetList.isLoading);

  const getInspectionDetails = useSelector(
    (state) => state?.inspectionDetailData
  );

  const showInspectionReportLoader = useSelector(
    (state) => state?.getInspectionReport?.isLoading
  );

  const acknowledgementLoader = useSelector(
    (state) => state?.getInspectionAcknowledgement?.isLoading
  );

  const getInspectionReport = (data) => {
    dispatch(viewInspectionAction.getInspectionDetails({ data }));
  };
  useEffect(() => {
    if (assetTypeValue) {
      const data = {
        SubClassId: "",
        organisationId: ORG_ID(),
        assetTypeId: assetTypeValue,
        isActive: false,
        isAdmin: false,
      };
      dispatch(getQuestionSet({ data }));
      setCommonFormValue({ ...commonFormValue, assetType: assetTypeValue });
    }
  }, [assetTypeValue]);

  useEffect(() => {
    dispatch(getReferenceData());
  }, []);

  const sliderItems = useMemo(() => {
    if (!quesSet || quesSet.length === 0) {
      return [];
    }
    return quesSet
      ?.filter((q) => q.isActive)
      ?.map(({ key, imgUrl, value }) => ({
        id: key,
        img: imgUrl,
        title: value,
      }));
  }, [quesSet]);

  const itemMap = useMemo(() => {
    if (!inspectionFieldsMap?.data?.listInspectionTypeFields) return {};
    return inspectionFieldsMap?.data?.listInspectionTypeFields.reduce(
      (map, item) => {
        map[item.key] = item;
        return map;
      },
      {}
    );
  }, [inspectionFieldsMap]);

  const handleAssetOnChange = (val) => {
    setAssets(val);
  };
  const handleSelectOnChange = (e, selectValue) => {
    setAssets({ key: e?.target?.value, value: selectValue?.props?.children });
  };

  const handleFieldChange = (fieldId, value, selectValue, responsetype) => {
    let selectAsset = {};
    if (responsetype === 9 && !HBNUser) {
      selectAsset = {
        key: selectValue?.props?.value,
        value: selectValue?.props?.children,
      };
    }
    if (fieldId === undefined) {
      console.error("Undefined fieldId detected");
      return;
    }
    let processedValue = value;
    if (Array.isArray(value)) {
      processedValue = value;
    }
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldId]:
        responsetype === 3 ? processedValue?.target.value : processedValue,
    }));
    setValidationErrors((prevState) => {
      const updatedErrors = { ...prevState };
      if (itemMap[fieldId]?.isMandatory && !processedValue) {
        updatedErrors[fieldId] = "This field is required";
      } else {
        delete updatedErrors[fieldId];
      }
      return updatedErrors;
    });
    setInspectionHederFields((prevState) => {
      const existingFieldIndex = prevState?.findIndex(
        (field) => field.key === fieldId
      );

      if (existingFieldIndex !== -1) {
        const updatedFields = [...prevState];
        updatedFields[existingFieldIndex] = {
          ...updatedFields[existingFieldIndex],
          insTypeFieldId: fieldId,
          ans: getAnswerValue(
            responsetype,
            processedValue,
            selectAsset,
            HBNUser
          ),
        };
        return updatedFields;
      }

      const updatedFields = prevState?.map((field) =>
        field.key === fieldId
          ? {
              ...field,
              insTypeFieldId: fieldId,
              ans: getAnswerValue(
                responsetype,
                processedValue,
                selectAsset,
                HBNUser
              ),
            }
          : field
      );
      return updatedFields;
    });
  };

  const validateAllFields = () => {
    const errors = {};
    Object.keys(itemMap).forEach((fieldId) => {
      if (
        parseInt(fieldId) === 3 ||
        parseInt(fieldId) === 2 ||
        parseInt(fieldId) === 1
      ) {
        return;
      }

      const field = itemMap[fieldId];
      if (field.isMandatory && !fieldValues[fieldId]) {
        errors[fieldId] = "This field is required";
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (quesSetDetails) {
      setListInspectionCategory(listInspectionCategoryObject(quesSetDetails));
    }
  }, [quesSetDetails]);
  const handleFormChange = (
    targetValue,
    questionValue,
    subCategoryValue,
    categoryValue,
    targetName,
    notAppce
  ) => {
    formchangeHandler(
      targetValue,
      questionValue,
      subCategoryValue,
      categoryValue,
      targetName,
      notAppce,
      listInspectionCategory,
      setListInspectionCategory,
      inspectionViolationCounts,
      inspectionViolationCountsData
    );
  };

  const fileUploads = (
    files,
    single,
    question,
    subcategory,
    category,
    acc,
    action
  ) => {
    fileUploadHandler(
      files,
      single,
      question,
      subcategory,
      category,
      acc,
      action,
      listInspectionCategory,
      setListInspectionCategory
    );
  };

  const deleteImageURLS = (
    urls,
    index,
    question,
    subcategory,
    category,
    acc,
    action
  ) => {
    deleteImageHandler(
      urls,
      index,
      question,
      subcategory,
      category,
      acc,
      action,
      listInspectionCategory,
      setListInspectionCategory
    );
  };

  const onSubmit = (data) => {
    if (data?.inspectionId === 0) {
      dispatch(postInpectionData(data));
    } else {
      dispatch(postInpectionData(data));
    }
  };

  useEffect(() => {
    if (assetTypeValue) {
      const data = {
        userId: HBNUser ? 0 : USER_ID(),
        type: assetTypeValue,
      };
      getAllAssets(data);
    }
  }, [assetTypeValue]);

  useEffect(() => {
    if (inspectionId && editMode) {
      const data = {
        InspectionId: inspectionId,
        organisationId: ORG_ID(),
      };
      getInspectionReport(data);
      setAssetTypeValue(parseInt(location?.state?.row?.assetType));
    }
  }, [inspectionId, editMode, location?.state]);

  useEffect(() => {
    if (getInspectionDetails?.isSuccess) {
      setQuestionSet(getInspectionDetails?.inspEditDetails?.questionSet_Id);
      setcarouselValue({
        id: getInspectionDetails?.inspEditDetails?.questionSet_Id,
      });

      if (getInspectionDetails?.inspEditDetails?.questionSet_Id) {
        const data = {
          questionSetId: getInspectionDetails?.inspEditDetails?.questionSet_Id,
        };
        getInspectionFieldsMap(data);
      }
      editListInspectionCategory(
        getInspectionDetails?.inspEditDetails?.categoriesView,
        setListInspectionCategory
      );
      setStatusId(getInspectionDetails?.inspEditDetails?.status_Id);
      setSignoffMethodValue(
        getInspectionDetails?.inspEditDetails?.signoffMethod
      );
      setSignatureUrl(SIGNATUREURL);
      setCertifyStatementIdValue(
        getInspectionDetails?.inspEditDetails?.certifyStatementId
      );
      setAcknowledgement(
        getInspectionDetails?.inspEditDetails?.certifyStatement
      );
    }
  }, [getInspectionDetails, editMode]);

  useEffect(() => {
    if (editMode) {
      if (getInspectionDetails?.isSuccess && editMode) {
        editFieldValues(
          getInspectionDetails?.inspEditDetails?.headerFieldsInfoResponse,
          setFieldValues,
          setInspectionHederFields
        );
      }
      setAssets({
        key: getInspectionDetails?.inspEditDetails?.assetId,
        value: getInspectionDetails?.inspEditDetails?.asset,
      });
    }
  }, [getInspectionDetails, editMode]);

  useEffect(() => {
    if (addInspection && !editMode) {
      setAssetTypeValue();
      setAssets();
      setcarouselValue({});
      setQuestionSet(0);
      setFieldValues({});
      setValidationErrors({});
      setListInspectionCategory();
      setSignatureUrl();
      setSignoffMethodValue();
      setCertifyStatementIdValue();
    }
  }, [addInspection, editMode]);

  useEffect(() => {
    if (signoffMethod) {
      setSignoffMethodValue(signoffMethod);
    }
    if (SIGNATUREURL && signoffMethod) {
      setSignatureUrl(SIGNATUREURL);
    }
    if (certifyStatementId) {
      setCertifyStatementIdValue(certifyStatementId);
    }
  }, [signoffMethod, SIGNATUREURL, certifyStatementId]);

  useEffect(() => {
    if (
      certifyStatementId &&
      (signoffMethodValue === SIGNOFF_METHOD_TYPE.CERTIFY_STATEMENT ||
        signoffMethodValue === 4)
    ) {
      getInspectionAcknowledgementData(inspectionAcknowledgementType);
    }
  }, [signoffMethodValue, certifyStatementId]);

  const uploadInspectorsignatureFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setSignaureFileErrorMessage(validation.errorMessage);
      setSignaureFileError(true);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setSignatureUrl(files?.files[0]?.fileUrl);
        setSignaureFileError(false);
        setInspectorSignUpload(true);
        setSignatureError(false);
      })
      .catch((error) => {
        setSignaureFileErrorMessage("Error while uploading files.");
        setSignaureFileError(true);
      });
  };

  const inspectorsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleInspectorsignatureUploadMethod(fileUploaded);
  };
  const handleInspectorsignatureUploadMethod = (data) => {
    uploadInspectorsignatureFile(data);
  };

  const handleTimeHandler = (e) => {
    setInspectionTime(e?.target?.value);
  };

  const handleDateHandler = (e) => {
    setInspectionDate(e);
  };
  useEffect(() => {
    isSuccess &&
      logEvent(
        "Inspection",
        successData?.insAddEditFlag === "edit"
          ? "Edit Inspection"
          : "Add Inspection"
      );
  }, [isSuccess]);

  useEffect(() => {
    if (editMode && getInspectionDetails?.inspEditDetails) {
      setInspectionHederFields(
        getInspectionDetails?.inspEditDetails?.headerFieldsInfoResponse
      );
    } else {
      setInspectionHederFields(
        inspectionFieldsMap?.data?.listInspectionTypeFields
      );
    }
  }, [editMode, inspectionFieldsMap, getInspectionDetails]);

  return (
    <>
      {(isInLoading ||
        isLoading ||
        isQuestionSetLoading ||
        countLoader ||
        assetLoader ||
        showInspectionReportLoader ||
        acknowledgementLoader ||
        isLoader) && <Loader />}

      {isSuccess ? (
        <SuccessScreen
          id={successData?.inspectionId ?? null}
          isUpdate={
            successData?.insAddEditFlag === "edit"
              ? "Inspection Report Updated successfully"
              : "Inspection Report Saved successfully" ?? null
          }
          route={VIEW_INSPECTION_REPORT}
          label2={
            successData?.insAddEditFlag === "edit"
              ? "has been Updated"
              : "has been generated"
          }
        />
      ) : (
        <>
          <AddInspection
            assetTypeValue={parseInt(assetTypeValue)}
            handleAssetChange={handleAssetChange}
            sliderItems={sliderItems}
            activeSlide={carouselValue}
            editMode={editMode}
            quetionSetChange={quetionSetChange}
            inspectionFieldsMap={
              isQuestionSetLoading ? [] : inspectionHederFields
            }
            questionSet={questionSet}
            assetOption={assetOption}
            isAssetLoading={isAssetLoading}
            searchAsset={searchAsset}
            commonFormValue={commonFormValue}
            handleFieldChange={handleFieldChange}
            fieldValues={fieldValues}
            validationErrors={validationErrors}
            listInspectionCategory={listInspectionCategory}
            setListInspectionCategory={setListInspectionCategory}
            handleFormChange={handleFormChange}
            isAutoSearchUserLoading={isAutoSearchUserLoading}
            handleAllUserSearch={handleAllUserSearch}
            allUsers={allUsers?.data}
            setImage={fileUploads}
            deleteImageURLS={deleteImageURLS}
            validateAllFields={validateAllFields}
            onSubmit={onSubmit}
            allAsset={allAssets?.data?.result}
            inspectionId={inspectionId}
            HBNUser={HBNUser}
            signoffMethodValue={signoffMethodValue}
            signatureurlValue={SIGNATUREURL}
            certifyStatementIdValue={certifyStatementIdValue}
            inspectionAcknowledgement={
              getInspectionAcknowledgement?.data?.result
            }
            signatureUrl={signatureUrl}
            inspectorsignatureUpload={inspectorsignatureUpload}
            inspectorSignUpload={inspectorSignUpload}
            signaureFileError={signaureFileError}
            signaureFileErrorMessage={signaureFileErrorMessage}
            signatureError={signatureError}
            setSignatureError={setSignatureError}
            assets={assets}
            handleAssetOnChange={handleAssetOnChange}
            handleSelectOnChange={handleSelectOnChange}
            inspectionDate={inspectionDate}
            inspectionTime={inspectionTime}
            handleTimeHandler={handleTimeHandler}
            handleDateHandler={handleDateHandler}
            statusId={statusId}
            acknowledgement={acknowledgement}
            setAcknowledgement={setAcknowledgement}
            weather={weather}
          />
        </>
      )}
    </>
  );
};

export default AddInspectionNewContainer;
