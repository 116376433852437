import React, { useEffect } from "react";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { PAGE_SIZE } from "../../../../constant/constant";
import { useState } from "react";
import AddSubCategory from "../components/addSubCategory";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../../constant/routeContant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const SwInspectionSubCategoriesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultData = {
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
  };

  const getSubCategoriesList = useSelector(
    (state) => state.getSwSubCategoryList
  );

  const getSubCategoriesListData = (data) => {
    dispatch(swInspectionAction.getSwSubCategoryList(data));
  };

  const createSwSubCategory = useSelector((state) => state.createSwSubCategory);
  const updateSwSubCategory = useSelector((state) => state.updateSwSubCategory);

  const isLoading = useSelector(
    (state) => state.getSwSubCategoryList?.isLoading
  );
  const showLoader = useSelector(
    (state) =>
      state.createSwSubCategory?.isLoading ||
      state.updateSwSubCategory?.isLoading
  );
  const [clearSubCategory, setClearSubCategory] = useState(false);
  const [clearUpdateSubCategory, setClearUpdateSubCategory] = useState(false);

  const updateSwSubCategoryData = (data) => {
    setClearUpdateSubCategory(true);
    dispatch(swInspectionAction.updateSwSubCategory(data));
  };

  const onSubmit = (data) => {
    setClearSubCategory(true);
    dispatch(swInspectionAction.createSwSubCategory(data));
  };
  useEffect(() => {
    if (
      createSwSubCategory?.status === "SUCCESS" &&
      createSwSubCategory?.isLoading === false &&
      clearSubCategory
    ) {
      toast("Sub Category Added successfully");
    }
  }, [createSwSubCategory]);

  useEffect(() => {
    if (
      updateSwSubCategory?.status === "SUCCESS" &&
      updateSwSubCategory?.isLoading === false &&
      clearUpdateSubCategory
    ) {
      toast("Sub Category Updated successfully");
    }
  }, [updateSwSubCategory]);

  useEffect(() => {
    getSubCategoriesListData(defaultData);
  }, []);

  return (
    <>
      {checkPrivileges([12, 93, 94, 95]) ? (
        <>
          {showLoader && <Loader />}

          <AddSubCategory
            getSubCategoriesList={
              isLoading ? [] : getSubCategoriesList?.data?.result?.categoryList
            }
            recordsCount={getSubCategoriesList?.data?.result?.categoryCount}
            getSubCategoriesListData={getSubCategoriesListData}
            onSubmit={onSubmit}
            createSwSubCategory={createSwSubCategory}
            updateSwSubCategoryData={updateSwSubCategoryData}
            isLoading={isLoading}
            updateSwSubCategory={updateSwSubCategory}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </>
  );
};

export default SwInspectionSubCategoriesContainer;
