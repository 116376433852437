import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCESS_DENIED,
  ADMIN_CONTRACTOR_CONFIGURATION_LIST,
} from "../../../constant/routeContant";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { useState } from "react";
import ContractorConfigurationView from "../component/users/contractor/contractorConfigurationView";
import { checkPrivileges } from "../../../utils/rolesHelper";

function ContractorConfigurationViewContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const onboardUserId = location?.state?.onboardUserId;

  const getContractorConfigurationDetsilsData = useSelector(
    (state) => state?.getContractorConfigurationDetsilsData?.data
  );

  const updateContractorOnboardStatusData = useSelector(
    (state) => state.updateContractorOnboardStatusData
  );

  const updateContractorOnboardStatusDataStatus = useSelector(
    (state) => state.updateContractorOnboardStatusData.status
  );

  const updateOnboardedContractorListData = useSelector(
    (state) => state.updateOnboardedContractorListData
  );
  const updateOnboardedContractorListDataStatus = useSelector(
    (state) => state.updateOnboardedContractorListData.status
  );
  const showLoader = useSelector(
    (state) =>
      state?.getContractorConfigurationDetsilsData?.isLoading ||
      state?.updateOnboardedContractorListData.isLoading ||
      state?.updateContractorOnboardStatusData.isLoading
  );
  const isSuccess = useSelector(
    (state) =>
      state.getContractorConfigurationDetsilsData.status === API_STATUS.SUCCESS
  );
  const userConfigurationListData = useSelector(
    (state) => state.userConfigurationData
  );

  const updateOnboardedConfiguration = (data) => {
    setIsSuccess(true);
    dispatch(contractorAction.updateOnboardedContractorList(data));
  };

  const updateContractorOnboardStatus = (data) => {
    setIsSuccess(true);
    dispatch(contractorAction.updateContractorOnboardStatus(data));
  };

  useEffect(() => {
    dispatch(contractorAction.getContractorConfigurationDetsils(onboardUserId));
  }, [dispatch]);

  const dataBack = {
    title: "Back to Contractor Configuration List",
    route: ADMIN_CONTRACTOR_CONFIGURATION_LIST,
  };

  useEffect(() => {
    if (updateContractorOnboardStatusData.status === API_STATUS.SUCCESS) {
      dispatch(
        contractorAction.getContractorConfigurationDetsils(onboardUserId)
      );
    }
  }, [updateContractorOnboardStatusData]);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Contractor Configured Successfully"}
        route={ADMIN_CONTRACTOR_CONFIGURATION_LIST}
        label1=" "
        label2=" "
        label4="Contractor Configuration List"
      ></SuccessScreen>
    );
  };

  return (
    <>
      {checkPrivileges([12, 43, 44, 45]) ? (
        <>
          {showLoader && <Loader />}
          {isSuccess &&
          updateOnboardedContractorListDataStatus === API_STATUS.SUCCESS &&
          updateOnboardedContractorListData?.data &&
          successScreens ? (
            successScreen(updateOnboardedContractorListData?.data?.id)
          ) : (
            <ContractorConfigurationView
              dataBack={dataBack}
              getContractorConfigurationDetsilsData={
                getContractorConfigurationDetsilsData
              }
              onboardUserId={onboardUserId}
              updateOnboardedConfiguration={updateOnboardedConfiguration}
              userConfigurationListData={userConfigurationListData?.data}
              updateContractorOnboardStatus={updateContractorOnboardStatus}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default ContractorConfigurationViewContainer;
