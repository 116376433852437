import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { API_STATUS, ORG_ID, USER_ID } from "../../../../constant/constant";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { useNavigate } from "react-router-dom";
import AddNewCategory from "./addNewCategory";
import Loader from "../../../../library/common/Loader";
import {
  getQuestionList,
  getQestSetDetail,
} from "../../../../services/storm-water/swInspectionService";

const SetInspection = ({
  onSubmit,
  onSetSubmit,
  getCategoriesList,
  getSubCategoriesList,
  createSwQuestionSet,
  questsetId,
  editMode,
  onCategorySubmit,
  onSubCategorySubmit,
  onQuestionSubmit,
  createSwQuestion,
  stateData,
  getSwReference,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [questionSetValue, setQuestionSetValue] = useState();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const intialState = {
    inspeciontype: "",
  };

  const [formValue, setFormValue] = useState(intialState);

  const [isInspeciontype, setIsInspeciontype] = useState(false);
  const [subCategoryFindValues, setSubCategoryFindValues] = useState();
  const [categoryes, setCategoryes] = useState([]);
  const [selectCategory, setSelectCategory] = useState(false);
  const [selectCategoryMsg, setSelectCategoryMsg] = useState(false);
  const [selectSubCategory, setSelectSubCategory] = useState(false);
  const [selectSubCategoryMsg, setSelectSubCategoryMsg] = useState(false);

  const [isStateValue, setIsStateValue] = useState();
  const [stateValue, setStateValue] = useState();
  const [inspectionName, setInspectionName] = useState("");
  const [isInspectionName, setIsInspectionName] = useState();
  const [isGlobal, setIsGlobal] = useState(false);

  const handleStateChange = (e) => {
    setStateValue(e.target.value);
  };

  const handleOnclickCategory = (event) => {
    event.stopPropagation();

    let errors = false;
    if (!categorySetValue) {
      setSelectCategory(true);
      setSelectCategoryMsg("Category is required");
      errors = true;
    }
    if (errors) {
      // setShowLoader(true);
      return false;
    }
    const newCategoryes = [...categoryes];

    const index = newCategoryes?.findIndex(
      (obect) => obect.key === categoryFindValues?.key
    );
    if (index === -1) {
      setShowLoader(false);
      setSelectCategory(false);
      newCategoryes.push({
        key: categoryFindValues?.key,
        value: categoryFindValues?.value,
        categoryId: categoryFindValues?.key,
        subcategories: [],
      });
      setCategoryes(newCategoryes);
    } else {
      setSelectCategory(true);
      setSelectCategoryMsg("Category already added.");
    }
  };

  const handleOnChangeCategoryForm = (current, data, singleSubCat, type) => {
    let elementId = "";
    let value = "";

    value = current.target.value;
    elementId = current.target.id;

    const findSubCategoryValue = getSubCategoriesList.find(
      (item) => item.key === current.target.value
    );
    setSubCategoryFindValues(findSubCategoryValue);

    let updatedCategoryes = [...categoryes];
    updatedCategoryes.forEach((categoryess) => {
      if (categoryess.categoryId === data.categoryId) {
        if (elementId === "categorySetValue") {
          categoryess.categorySetValue = value;
        } else if (type === "subCategorySetValue") {
          categoryess.subCategorySetValue = value;
          setSelectSubCategory(false);
        }
      }
      if (singleSubCat) {
        categoryess.subcategories.forEach((subCategory) => {
          if (subCategory.subCategoryId === singleSubCat.subCategoryId) {
            if (elementId === "subCategorySetValue") {
              subCategory.subCategorySetValue = value;
            } else if (type === "questionValue") {
              subCategory.questionValue = value;
            } else if (type === "responseTypeAccN") {
              subCategory.responseTypeAccN = true;
            } else if (type === "responseTypeText") {
              subCategory.responseTypeText = true;
            }
          }
        });
      }
    });

    setCategoryes(updatedCategoryes);
  };
  const cancelCategory = (sinlgeCate) => {
    const removeCategory = categoryes.filter(
      (category) => category.key !== sinlgeCate.key
    );
    setCategoryes(removeCategory);
  };
  const [subCatAdd, setSubCatAdd] = useState();
  const [selectSubACategory, setSelectSubACategory] = useState();
  const [selectSubACategoryMsg, setSelectSubACategoryMsg] = useState();

  const addSubCategory = (singleSubCat) => {
    let updatedSubCategory = [...categoryes];
    updatedSubCategory.forEach(async (sinlgeSubCat) => {
      const index = sinlgeSubCat.subcategories?.findIndex(
        (obect) => obect.key === subCategoryFindValues?.key
      );
      if (index === -1) {
        setShowLoader(true);
        if (sinlgeSubCat.key === singleSubCat.key) {
          if (!sinlgeSubCat?.subCategorySetValue) {
            setSelectSubACategory(true);
            setSelectSubACategoryMsg("Sub Category is required");
          } else {
            setSelectSubACategory(false);
            const data = {
              subcatId: [subCategoryFindValues?.key],
            };
            const keyValueQues = [];
            const questionsData = await getQuestionList({ data });
            if (questionsData?.status === 200) {
              setShowLoader(false);
              setSelectSubCategory(false);
            }
            setSubCatAdd(questionsData);
            questionsData?.data?.questionList
              .filter((x) => x.status)
              .forEach((item) => {
                const quesSet = {
                  key: item.key,
                  value: item.value,
                  isActive: item.isActive,
                  isSelected: true,
                };
                keyValueQues.push(quesSet);
              });
            sinlgeSubCat.subcategories.push({
              key: subCategoryFindValues?.key,
              value: subCategoryFindValues?.value,
              subCategoryId: subCategoryFindValues?.key,
              isGlobal: subCategoryFindValues?.isGlobal,
              questions: keyValueQues,
            });
          }
        }
        setCategoryes(updatedSubCategory);
        setShowLoader(false);
      } else if (sinlgeSubCat.key === null) {
        setSelectSubACategory(true);
        setSelectSubACategoryMsg("Sub Category is required.");
      } else {
        setSelectSubCategory(true);
        setSelectSubCategoryMsg("Sub Category already added.");
      }
    });
  };

  const cancelSubCategory = (singQue, signAws) => {
    const cloneList = [...categoryes];
    cloneList.forEach((des) => {
      if (singQue.key === des.key) {
        let filterAns = des.subcategories.filter(
          (te) => te.key !== signAws.key
        );
        des.subcategories = filterAns;
      }
    });
    setCategoryes(cloneList);
  };
  const [categorySetValue, setCategorySetValue] = useState();
  const [categoryFindValues, setCategoryFindValues] = useState();
  const handleCategorySetChange = (e) => {
    setSelectCategory(false);
    const findCategoryValue = getCategoriesList.find(
      (item) => item.key === e.target.value
    );
    setCategoryFindValues(findCategoryValue);
    setCategorySetValue({
      categorySetValue: e.target.value,
    });
  };
  const handleQuestionCheck = (
    singlesubcategories,
    quesId,
    singleCategoryes
  ) => {
    const addQuesSet = JSON.parse(JSON.stringify(categoryes));
    addQuesSet.forEach((qes) => {
      if (qes.categoryId == singlesubcategories.categoryId) {
        qes.subcategories.forEach((seq) => {
          if (seq.subCategoryId === quesId.subCategoryId) {
            seq.questions.forEach((swq) => {
              if (swq.key == singleCategoryes.key) {
                swq.isSelected = !swq.isSelected;
              }
            });
          }
        });
      }
    });
    setCategoryes(addQuesSet);
  };

  const handleQuestionAdd = (singlesubcategories, quesId, singleCategoryes) => {
    const addQuesSet = JSON.parse(JSON.stringify(categoryes));
    addQuesSet.forEach((qes) => {
      if (qes.categoryId == singlesubcategories.categoryId) {
        qes.subcategories.forEach((seq) => {
          if (seq.subCategoryId === quesId.subCategoryId) {
            seq.show = !seq.show;
          }
        });
      }
    });
    setCategoryes(addQuesSet);
  };
  const handleQuestionOptionAdd = (
    singlesubcategories,
    quesId,
    singleCategoryes
  ) => {
    const addQuesSet = JSON.parse(JSON.stringify(categoryes));
    addQuesSet.forEach((qes) => {
      if (qes.categoryId == singlesubcategories.categoryId) {
        qes.subcategories.forEach((seq) => {
          if (seq.subCategoryId === quesId.subCategoryId) {
            if (singleCategoryes) {
              seq.optionShow = singleCategoryes;
            }
          }
        });
      }
    });
    setCategoryes(addQuesSet);
  };
  const [singlesubcategoriesId, setSinglesubcategoriesId] = useState();
  const [singlecategoriesId, setSinglecategoriesId] = useState();
  const [questionValueCheck, setQuestionValueCheck] = useState();
  const submitQuestionData = async (singlesubcategories, singleCategoryes) => {
    let existData = singlesubcategories?.questions.filter(
      (items) => items.value === singlesubcategories.questionValue
    );
    setShowLoader(true);
    setQuestionValueCheck(singlesubcategories.questionValue);

    if (existData.length >= 1) {
      const addQuesSet = JSON.parse(JSON.stringify(categoryes));
      addQuesSet.forEach((qes) => {
        if (qes.categoryId == singleCategoryes.categoryId) {
          qes.subcategories.forEach((seq) => {
            if (seq.subCategoryId === singlesubcategories.subCategoryId) {
              seq.errorTextField = true;
              seq.errorQuestionTextField = false;
              seq.errorQuestionOptionTextField = false;
            }
          });
        }
      });
      setShowLoader(false);

      setCategoryes(addQuesSet);
    } else if (
      singlesubcategories.questionValue === undefined ||
      singlesubcategories.questionValue === ""
    ) {
      const addQuesSet = JSON.parse(JSON.stringify(categoryes));
      addQuesSet.forEach((qes) => {
        if (qes.categoryId == singleCategoryes.categoryId) {
          qes.subcategories.forEach((seq) => {
            if (seq.subCategoryId === singlesubcategories.subCategoryId) {
              seq.errorQuestionTextField = true;
            }
          });
        }
      });
      setShowLoader(false);
      setCategoryes(addQuesSet);
    } else if (
      singlesubcategories?.optionShow === undefined ||
      singlesubcategories?.optionShow === ""
    ) {
      const addQuesSet = JSON.parse(JSON.stringify(categoryes));
      addQuesSet.forEach((qes) => {
        if (qes.categoryId == singleCategoryes.categoryId) {
          qes.subcategories.forEach((seq) => {
            if (seq.subCategoryId === singlesubcategories.subCategoryId) {
              seq.errorQuestionOptionTextField = true;
            }
          });
        }
      });
      setShowLoader(false);
      setCategoryes(addQuesSet);
    } else {
      const addQuesSet = JSON.parse(JSON.stringify(categoryes));
      addQuesSet.forEach((qes) => {
        if (qes.categoryId == singleCategoryes.categoryId) {
          qes.subcategories.forEach((seq) => {
            if (seq.subCategoryId === singlesubcategories.subCategoryId) {
              seq.errorTextField = false;
              seq.errorQuestionTextField = false;
              seq.errorQuestionOptionTextField = false;
              seq.questionValue = "";
              seq.optionShow = "";
            }
          });
        }
      });
      setCategoryes(addQuesSet);
      setSinglesubcategoriesId(singlesubcategories);
      setSinglecategoriesId(singleCategoryes);
      const questionList = [
        {
          value: singlesubcategories?.questionValue,
          status: true,
          responseType: singlesubcategories?.optionShow,
          options: [],
        },
      ];
      onQuestionSubmit({
        questionList,
        subCatId: singlesubcategories?.key,
        userId: parseInt(USER_ID()),
      });
    }
  };

  const getQuestionSetData = async () => {
    const data = {
      subcatId: [singlesubcategoriesId?.key],
    };
    const questionsData = await getQuestionList({ data });
    const cloneList = [...categoryes];
    cloneList.forEach((des) => {
      if (singlecategoriesId?.key === des?.key) {
        des.subcategories.forEach((subSeq) => {
          if (subSeq.key === singlesubcategoriesId.key) {
            questionsData?.data?.questionList?.forEach((question) => {
              const existingQuestion = subSeq.questions.find(
                (exitQuestion) => exitQuestion.key === question.key
              );
              if (!existingQuestion) {
                const newQuestion = {
                  isSelected: question.status,
                  key: question.key,
                  value: question.value,
                };
                subSeq.questions.push(newQuestion);
              }
            });
          }
        });
      }
    });
    setCategoryes(cloneList);
  };

  useEffect(() => {
    if (createSwQuestion?.status === API_STATUS?.SUCCESS) {
      setShowLoader(false);
      getQuestionSetData();
    } else {
    }
  }, [createSwQuestion]);

  function mappedSetData() {
    let errors = false;
    if (inspectionName?.trim() === "") {
      setIsInspectionName(true);
      setInspectionName("");
      errors = true;
    }
    if (!stateValue) {
      setIsStateValue(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const data = {
      questionSetId: questsetId?.key ? questsetId?.key : null,
      stateId: stateValue,
      name: inspectionName,
      userId: parseInt(USER_ID()),
      isGlobal: isGlobal,
      categories: categoryes,
    };
    return data;
  }

  const submitUpdateData = () => {
    const data = mappedSetData();
    if (!data) {
    } else {
      setIsSubmit(true);
      const newCategoryes = JSON.parse(JSON.stringify(data.categories));
      const sendData = newCategoryes?.map((x, indexCategories) => {
        delete x.value;
        delete x.id;
        x.sequenceNumber = indexCategories + 1;
        x.subcategories?.map((y, indexSubCategories) => {
          const questionSetIds = [];
          y.questions.forEach((items, indexQuestion) => {
            if (items.isSelected) {
              const reerr = {
                questionId: items.key,
                sequenceNumber: indexQuestion + 1,
              };
              questionSetIds.push(reerr);
            }
          });
          delete y.value;
          delete y.id;
          y.sequenceNumber = indexSubCategories + 1;
          y.questions = questionSetIds;
          return y;
        });
        return x;
      });
      data.categories = sendData;

      if (questsetId?.key) {
        onSetSubmit(data);
      } else {
        onSubmit(data);
      }
    }
  };

  useEffect(() => {
    if (
      createSwQuestionSet?.status === "SUCCESS" &&
      createSwQuestionSet?.isLoading === false
    ) {
      setIsSubmit(false);
      setIsInspeciontype(false);
      setFormValue({
        ...formValue,
        inspeciontype: "",
      });

      const defaultQuestionSetData = {
        organisationId: ORG_ID(),
        isActive: true,
      };
      // questionSetListData(defaultQuestionSetData);
    }
  }, [createSwQuestionSet]);

  const editInspection = async () => {
    const data = {
      questionSetId: questsetId?.key,
    };
    const categoryData = await getQestSetDetail(data);
    if (categoryData?.status === 200) {
      setShowLoader(false);
    }
    setIsGlobal(categoryData?.data?.isGlobal);
    const addQuesSet = JSON.parse(
      JSON.stringify(categoryData?.data?.questionSetCatDetails)
    );
    setStateValue(categoryData?.data?.stateId);
    setInspectionName(categoryData?.data?.value);
    addQuesSet.forEach((qes) => {
      qes.categoryId = qes.key;
      qes.subcategories.forEach((seq) => {
        seq.subCategoryId = seq.key;
      });
    });
    setCategoryes(addQuesSet);
  };

  useEffect(() => {
    if (questsetId) {
      setShowLoader(true);
      editInspection();
      setQuestionSetValue(questsetId?.key);
    }
  }, [editMode]);

  const categoryIntialState = {
    categoriesValue: "",
  };
  const [addCategoryBox, setAddCategoryBox] = useState(false);
  const [categoryFormValue, setCategoryFormValue] =
    useState(categoryIntialState);
  const [isCategoryName, setIsCategoryName] = useState(false);
  const [errorsCategoryMsgShowActive, setErrorsCategoryMsgShowActive] =
    useState(false);
  const [errorsCategoryMsgShow, setErrorsCategoryMsgShow] = useState(false);
  const [isCategorySubmit, setIsCategorySubmit] = useState(false);

  const handleOnclickAddCategory = () => {
    setAddCategoryBox(!addCategoryBox);
  };

  function mappedCategoryData() {
    let errors = false;
    if (categoryFormValue?.categoriesValue.trim() === "") {
      setFormValue({
        ...categoryFormValue,
        categoriesValue: "",
      });
      setErrorsCategoryMsgShowActive(false);
      setIsCategoryName(true);
      errors = true;
    } else {
      setIsCategoryName(false);
    }
    if (errors) {
      return false;
    }

    const index = getCategoriesList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() ===
        categoryFormValue?.categoriesValue?.toUpperCase()
    );
    if (index === -1) {
      setErrorsCategoryMsgShowActive(false);
      const list = [
        {
          value: categoryFormValue?.categoriesValue,
        },
      ];
      return { list, userId: parseInt(USER_ID()) };
    } else {
      setErrorsCategoryMsgShowActive(true);
      setErrorsCategoryMsgShow("Category Name already added");
    }
  }

  const submitCategoryData = () => {
    const data = mappedCategoryData();
    if (!data) {
    } else {
      setCategoryFormValue({
        ...categoryFormValue,
        categoriesValue: "",
      });
      setIsCategorySubmit(true);
      onCategorySubmit(data);
    }
  };

  return (
    <>
      <>{showLoader && <Loader />}</>

      <Grid className="inspectionOverRide">
        <section className="grid_main_sec">
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">
              {editMode ? "Edit Inspection Form" : "Create Inspection Form"}
            </div>
          </div>

          <Grid container mt={4}>
            <Grid item md={8} xs={12} mb={2}>
              <InputField
                type="text"
                isRequired={true}
                label="Inspection Name"
                placeholder="Enter here.."
                value={inspectionName}
                onChange={(e) => setInspectionName(e.target.value)}
                isError={isInspectionName}
                errorMsg={isInspectionName ? "Inspection Name is Required" : ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12} mb={2}>
              <FormControl fullWidth>
                <FormLabels label="Select State" isRequired={true} />
                <SelectMenu
                  placeholder="Please Select"
                  listData={stateData ?? []}
                  value={stateValue ? stateValue : null}
                  onchangehandler={(e) => handleStateChange(e)}
                  isError={isStateValue}
                  errorMsg={isStateValue ? "State is Required" : ""}
                  disabled={editMode}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid container item>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Category" />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={getCategoriesList ?? []}
                    value={categorySetValue}
                    onchangehandler={(e) => handleCategorySetChange(e)}
                  />
                  {selectCategory && (
                    <div className="errorMsg">{selectCategoryMsg}</div>
                  )}
                </FormControl>
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={2}
                xs={3}
                ml={2}
                mt={4}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                  onClick={handleOnclickCategory}
                ></Buttons>
              </Grid>

              <Grid
                item
                lg={3}
                md={2}
                xs={3}
                ml={1}
                mt={4}
                className="variantOutlineBorderSmall"
              >
                <Buttons
                  aria-describedby="dd"
                  variant="variantOutlineBorder"
                  type="button"
                  name="btn"
                  label={"Create New"}
                  id="btnAddEquipment"
                  variantOutlineBorder={true}
                  onClick={handleOnclickAddCategory}
                  iconToggle={addCategoryBox}
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>

          {addCategoryBox && (
            <Grid item container md={8} className="CategoriesBox">
              <Grid item md={8} xs={8} mb={2}>
                <InputField
                  type="text"
                  isRequired={true}
                  value={categoryFormValue?.categoriesValue}
                  onChange={(e) =>
                    setCategoryFormValue({
                      ...categoryFormValue,
                      categoriesValue: e.target.value,
                    })
                  }
                  label="Create New Category"
                  placeholder="Create Category Here..."
                  isError={isCategoryName}
                  errorMsg={isCategoryName ? "Category Name is Required" : ""}
                />
                {errorsCategoryMsgShowActive && (
                  <div className="errorMsg">{errorsCategoryMsgShow}</div>
                )}
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={3}
                xs={3}
                ml={3}
                mt={4}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Create"}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                  disabled={false}
                  onClick={submitCategoryData}
                ></Buttons>
              </Grid>
            </Grid>
          )}

          <Grid container mt={2}>
            {categoryes?.map((singleCategoryes, index) => (
              <AddNewCategory
                getSubCategoriesList={getSubCategoriesList}
                singleCategoryes={singleCategoryes}
                handleOnChangeCategoryForm={handleOnChangeCategoryForm}
                addSubCategory={addSubCategory}
                cancelCategory={(id) => cancelCategory(id)}
                cancelSubCategory={cancelSubCategory}
                handleQuestionCheck={handleQuestionCheck}
                onSubCategorySubmit={onSubCategorySubmit}
                handleQuestionAdd={handleQuestionAdd}
                handleQuestionOptionAdd={handleQuestionOptionAdd}
                submitQuestionData={submitQuestionData}
                selectSubCategory={selectSubCategory}
                selectSubCategoryMsg={selectSubCategoryMsg}
                selectSubACategory={selectSubACategory}
                selectSubACategoryMsg={selectSubACategoryMsg}
                key={index}
              />
            ))}
          </Grid>

          <Grid container mt={7}>
            <Grid item lg={2} xs={12} sm={3} md={3}>
              <Buttons
                varientText={true}
                label="Cancel"
                onClick={() => navigate(-1)}
              />
            </Grid>
            <Grid item lg={2} xs={12} sm={3} md={3}>
              <Buttons
                varientContained={true}
                label={editMode ? "Update" : "Save"}
                onClick={submitUpdateData}
              />
            </Grid>
          </Grid>
        </section>
      </Grid>
    </>
  );
};

export default SetInspection;
