import { jhaType } from "../constants/jhaType";

export const clearJha = () => ({
  type: jhaType.CLEAR_JHA,
  data: "",
});

export const addJhaCategory = (data) => ({
  type: jhaType.POST_JHA_ADD_CATEGORY,
  data,
});

export const addJhaCategorySuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_CATEGORY_SUCCESS,
  data,
});

export const addJhaCategoryFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_CATEGORY_FAILURE,
  error,
});

export const getJhaCategoryList = (data) => ({
  type: jhaType.GET_JHA_CATEGORY_LIST,
  data,
});

export const getJhaCategoryListSuccess = (data) => ({
  type: jhaType.GET_JHA_CATEGORY_LIST_SUCCESS,
  data,
});

export const getJhaCategoryListFailure = (error) => ({
  type: jhaType.GET_JHA_CATEGORY_LIST_FAILURE,
  error,
});

export const updateJhaCategory = (data) => ({
  type: jhaType.UPDATE_JHA_CATEGORY,
  data,
});

export const updateJhaCategorySuccess = (data) => ({
  type: jhaType.UPDATE_JHA_CATEGORY_SUCCESS,
  data,
});

export const updateJhaCategoryFailure = (error) => ({
  type: jhaType.UPDATE_JHA_CATEGORY_FAILURE,
  error,
});

export const addJhaActivity = (data) => ({
  type: jhaType.POST_JHA_ADD_ACTIVITY,
  data,
});

export const addJhaActivitySuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_ACTIVITY_SUCCESS,
  data,
});

export const addJhaActivityFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_ACTIVITY_FAILURE,
  error,
});

export const getJhaActivityList = (data) => ({
  type: jhaType.GET_JHA_ACTIVITY_LIST,
  data,
});

export const getJhaActivityListSuccess = (data) => ({
  type: jhaType.GET_JHA_ACTIVITY_LIST_SUCCESS,
  data,
});

export const getJhaActivityListFailure = (error) => ({
  type: jhaType.GET_JHA_ACTIVITY_LIST_FAILURE,
  error,
});

export const updateJhaActivity = (data) => ({
  type: jhaType.UPDATE_JHA_ACTIVITY,
  data,
});

export const updateJhaActivitySuccess = (data) => ({
  type: jhaType.UPDATE_JHA_ACTIVITY_SUCCESS,
  data,
});

export const updateJhaActivityFailure = (error) => ({
  type: jhaType.UPDATE_JHA_ACTIVITY_FAILURE,
  error,
});

export const addJhaHazard = (data) => ({
  type: jhaType.POST_JHA_ADD_HAZARD,
  data,
});

export const addJhaHazardSuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_HAZARD_SUCCESS,
  data,
});

export const addJhaHazardFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_HAZARD_FAILURE,
  error,
});

export const getJhaHazardsList = (data) => ({
  type: jhaType.GET_JHA_HAZARDS_LIST,
  data,
});

export const getJhaHazardsListSuccess = (data) => ({
  type: jhaType.GET_JHA_HAZARDS_LIST_SUCCESS,
  data,
});

export const getJhaHazardsListFailure = (error) => ({
  type: jhaType.GET_JHA_HAZARDS_LIST_FAILURE,
  error,
});

export const updateJhaHazard = (data) => ({
  type: jhaType.UPDATE_JHA_HAZARD,
  data,
});

export const updateJhaHazardSuccess = (data) => ({
  type: jhaType.UPDATE_JHA_HAZARD_SUCCESS,
  data,
});

export const updateJhaHazardFailure = (error) => ({
  type: jhaType.UPDATE_JHA_HAZARD_FAILURE,
  error,
});

export const getJhaControlList = (data) => ({
  type: jhaType.GET_JHA_CONTROL_LIST,
  data,
});

export const getJhaControlListSuccess = (data) => ({
  type: jhaType.GET_JHA_CONTROL_LIST_SUCCESS,
  data,
});

export const getJhaControlListFailure = (error) => ({
  type: jhaType.GET_JHA_CONTROL_LIST_FAILURE,
  error,
});

export const addJhaControl = (data) => ({
  type: jhaType.POST_JHA_ADD_CONTROL,
  data,
});

export const addJhaControlSuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_CONTROL_SUCCESS,
  data,
});

export const addJhaControlFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_CONTROL_FAILURE,
  error,
});

export const updateJhaControl = (data) => ({
  type: jhaType.UPDATE_JHA_CONTROL,
  data,
});

export const updateJhaControlSuccess = (data) => ({
  type: jhaType.UPDATE_JHA_CONTROL_SUCCESS,
  data,
});

export const updateJhaControlFailure = (error) => ({
  type: jhaType.UPDATE_JHA_CONTROL_FAILURE,
  error,
});

export const getConductJhaList = (data) => ({
  type: jhaType.GET_CONDUCT_JHA_LIST,
  data,
});

export const getConductJhaListSuccess = (data) => ({
  type: jhaType.GET_CONDUCT_JHA_LIST_SUCCESS,
  data,
});

export const getConductJhaListEmailSuccess = () => ({
  type: jhaType.GET_CONDUCT_JHA_LIST_EMAIL_SUCCESS,
});

export const getConductJhaListFailure = (error) => ({
  type: jhaType.GET_CONDUCT_JHA_LIST_FAILURE,
  error,
});

export const addJhaTemplate = (data) => ({
  type: jhaType.POST_JHA_ADD_TEMPLATE,
  data,
});

export const addJhaTemplateSuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_TEMPLATE_SUCCESS,
  data,
});

export const addJhaTemplateFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_TEMPLATE_FAILURE,
  error,
});

export const getJhaTemplateList = (data) => ({
  type: jhaType.GET_JHA_TEMPLATE_LIST,
  data,
});

export const getJhaTemplateListSuccess = (data) => ({
  type: jhaType.GET_JHA_TEMPLATE_LIST_SUCCESS,
  data,
});

export const getJhaTemplateListFailure = (error) => ({
  type: jhaType.GET_JHA_TEMPLATE_LIST_FAILURE,
  error,
});

export const updateJhaTemplateStatus = (data) => ({
  type: jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS,
  data,
});

export const updateJhaTemplateStatusSuccess = (data) => ({
  type: jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS_SUCCESS,
  data,
});

export const updateJhaTemplateStatusFailure = (error) => ({
  type: jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS_FAILURE,
  error,
});

export const addJhaConduct = (data) => ({
  type: jhaType.POST_JHA_ADD_CONDUCT,
  data,
});

export const addJhaConductSuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_CONDUCT_SUCCESS,
  data,
});

export const addJhaConductFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_CONDUCT_FAILURE,
  error,
});

export const getJhaTempeleteDetsils = (data) => ({
  type: jhaType.GET_JHA_TEMPLETE_DETAILS_PENDING,
  data,
});

export const getJhaTempeleteDetsilsSuccess = (data) => ({
  type: jhaType.GET_JHA_TEMPLETE_DETAILS_SUCCESS,
  data,
});

export const getJhaTempeleteDetailsFailure = (error) => ({
  type: jhaType.GET_JHA_TEMPLETE_DETAILS_FAILURE,
  error,
});

export const getJhaControlTypeList = (data) => ({
  type: jhaType.GET_JHA_CONTROL_TYPE_LIST,
  data,
});

export const getJhaControlTypeListSuccess = (data) => ({
  type: jhaType.GET_JHA_CONTROL_TYPE_LIST_SUCCESS,
  data,
});

export const getJhaControlTypeListFailure = (error) => ({
  type: jhaType.GET_JHA_CONTROL_TYPE_LIST_FAILURE,
  error,
});

export const getJhaControlDetails = (data) => ({
  type: jhaType.GET_JHA_CONTROL_DETAILS,
  data,
});

export const getJhaControlDetailsSuccess = (data) => ({
  type: jhaType.GET_JHA_CONTROL_DETAILS_SUCCESS,
  data,
});

export const getJhaControlDetailsFailure = (error) => ({
  type: jhaType.GET_JHA_CONTROL_DETAILS_FAILURE,
  error,
});

export const updateJhaTemplate = (data) => ({
  type: jhaType.UPDATE_JHA_TEMPLATE,
  data,
});

export const updateJhaTemplateSuccess = (data) => ({
  type: jhaType.UPDATE_JHA_TEMPLATE_SUCCESS,
  data,
});

export const updateJhaTemplateFailure = (error) => ({
  type: jhaType.UPDATE_JHA_TEMPLATE_FAILURE,
  error,
});

export const getJhaConductDetails = (data) => ({
  type: jhaType.GET_JHA_CONDUCT_DETAILS,
  data,
});

export const getJhaConductDetailsSuccess = (data) => ({
  type: jhaType.GET_JHA_CONDUCT_DETAILS_SUCCESS,
  data,
});

export const getJhaConductDetailsFailure = (error) => ({
  type: jhaType.GET_JHA_CONDUCT_DETAILS_FAILURE,
  error,
});

export const addJhaSetup = (data) => ({
  type: jhaType.POST_JHA_ADD_SETUP,
  data,
});

export const addJhaSetupSuccess = (data) => ({
  type: jhaType.POST_JHA_ADD_SETUP_SUCCESS,
  data,
});

export const addJhaSetupFailure = (error) => ({
  type: jhaType.POST_JHA_ADD_SETUP_FAILURE,
  error,
});

export const getJhaSetupList = (data) => ({
  type: jhaType.GET_JHA_SETUP_LIST,
  data,
});

export const getJhaSetupListSuccess = (data) => ({
  type: jhaType.GET_JHA_SETUP_LIST_SUCCESS,
  data,
});

export const getJhaSetupListFailure = (error) => ({
  type: jhaType.GET_JHA_SETUP_LIST_FAILURE,
  error,
});

export const activeInactiveJHASetup = (data) => ({
  type: jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP,
  data,
});

export const activeInactiveJHASetupSuccess = (data) => ({
  type: jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP_SUCCESS,
  data,
});

export const activeInactiveJHASetupFailure = (error) => ({
  type: jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP_FAILURE,
  error,
});

export const getJHAReferenceData = (data) => ({
  type: jhaType.GET_JHA_REFERENCE_DATA,
  data,
});

export const getJHAReferenceDataSuccess = (data) => ({
  type: jhaType.GET_JHA_REFERENCE_DATA_SUCCESS,
  data,
});

export const getJHAReferenceDataFailure = (error) => ({
  type: jhaType.GET_JHA_REFERENCE_DATA_FAILURE,
  error,
});

export const getJhaSetupDetails = (data) => ({
  type: jhaType.GET_JHA_SETUP_DETAILS,
  data,
});

export const getJhaSetupDetailsSuccess = (data) => ({
  type: jhaType.GET_JHA_SETUP_DETAILS_SUCCESS,
  data,
});

export const getJhaSetupDetailsFailure = (error) => ({
  type: jhaType.GET_JHA_SETUP_DETAILS_FAILURE,
  error,
});

export const updateJhaSetup = (data) => ({
  type: jhaType.UPDATE_JHA_SETUP,
  data,
});

export const updateJhaSetupSuccess = (data) => ({
  type: jhaType.UPDATE_JHA_SETUP_SUCCESS,
  data,
});

export const updateJhaSetupFailure = (error) => ({
  type: jhaType.UPDATE_JHA_SETUP_FAILURE,
  error,
});

export const getJHASetupDashboard = (data) => ({
  type: jhaType.GET_JHA_SETUP_DASHBOARD,
  data,
});

export const getJHASetupDashboardSuccess = (data) => ({
  type: jhaType.GET_JHA_SETUP_DASHBOARD_SUCCESS,
  data,
});

export const getJHASetupDashboardFailure = (error) => ({
  type: jhaType.GET_JHA_SETUP_DASHBOARD_FAILURE,
  error,
});

export const getSaftyLogJHAConductList = (data) => ({
  type: jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST,
  data,
});

export const getSaftyLogJHAConductListSuccess = (data) => ({
  type: jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST_SUCCESS,
  data,
});
export const getSaftyLogJHAConductListEmailSuccess = () => ({
  type: jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST_EMAIL_SUCCESS,
});

export const getSaftyLogJHAConductListFailure = (error) => ({
  type: jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST_FAILURE,
  error,
});

export const getJhaActiveTemplateList = (data) => ({
  type: jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST,
  data,
});

export const getJhaActiveTemplateListSuccess = (data) => ({
  type: jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST_SUCCESS,
  data,
});

export const getJhaActiveTemplateListFailure = (error) => ({
  type: jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST_FAILURE,
  error,
});
