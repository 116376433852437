import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./SwInspectionReports.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import Cards from "../../../../feature/reports/component/Cards";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import {
  getCurrentDate,
  getDateWithDayPast,
  getFormatedDate,
} from "../../../../utils/helper";
import { SW_REPORT_FILTER } from "./SwReportConstant";
import { REPORT_PAGE_SIZE } from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { LineChart } from "./LineChart";

function SwDateWiseSummary({
  isLoading,
  getDateWiseSummaryListData,
  getDateWiseSummaryList,
  areAllEqual,
  substract30Day,
}) {
  const [days, setDays] = useState("");
  const [startDate, setStartDate] = useState(getFormatedDate(substract30Day));
  const [endDate, setEndDate] = useState(getCurrentDate(new Date()));
  const [page, setPage] = useState(1);
  const orgId = localStorage.getItem("orgId");

  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
  };

  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
  };
  useEffect(() => {
    setDays(SW_REPORT_FILTER.find((item) => item.key === 30));
  }, []);

  useEffect(() => {
    if (startDate && endDate && days?.key === 12) {
      const data = {
        CustomerId: areAllEqual ? 0 : orgId,
        FromDate: startDate ? getFormatedDate(startDate) : "",
        ToDate: endDate ? getFormatedDate(endDate) : "",
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getDateWiseSummaryListData(data);
    }
  }, [startDate, endDate]);

  const dayCardOnClick = (value) => {
    setDays(value);
    if (days.key === value.key) {
      setDays("");
      const data = {
        CustomerId: areAllEqual ? 0 : orgId,
        FromDate: "",
        ToDate: "",
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getDateWiseSummaryListData(data);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const data = {
          CustomerId: areAllEqual ? 0 : orgId,
          FromDate: dateObj.pastData ? dateObj.pastData : "",
          ToDate: dateObj.getCurrentDate ? dateObj.getCurrentDate : "",
          Page: 1,
          PageSize: REPORT_PAGE_SIZE,
        };
        getDateWiseSummaryListData(data);
        setEndDate(dateObj.getCurrentDate);
        setStartDate(dateObj.pastData);
      }
      if (value.key === 13) {
        setStartDate("");
        setEndDate("");
        const data = {
          CustomerId: areAllEqual ? 0 : orgId,
          FromDate: "",
          ToDate: "",
          Page: 1,
          PageSize: REPORT_PAGE_SIZE,
        };
        getDateWiseSummaryListData(data);
      }
    }
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      CustomerId: areAllEqual ? 0 : orgId,
      FromDate: startDate ? getFormatedDate(startDate) : "",
      ToDate: endDate ? getFormatedDate(endDate) : "",
      Page: newPage + 1,
      PageSize: REPORT_PAGE_SIZE,
    };
    getDateWiseSummaryListData(data);
  };

  const credcloumns = [
    { field: "date", headerName: "Inspection date", flex: 5 },
    { field: "totalInspections", headerName: "Total inspections", flex: 4 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={getDateWiseSummaryList?.data?.count ?? 0}
          numberOfItemsPerPage={REPORT_PAGE_SIZE}
          incidentLists={
            getDateWiseSummaryList?.data?.inspectionDatewiseReportList ?? []
          }
          fileNameXL={"DateWiseSummaryList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  return (
    <>
      <Grid container className="SwSummaryReportContainer">
        <Grid item lg={6} xs={12} sm={12} md={4} mt={4} textAlign="left">
          <div className="heading_style_SwRePort">Inspection Date Summary</div>
        </Grid>

        <Grid item lg={10} xs={12} sm={12} md={8} mt={5}>
          <FormLabels label={"Select Time Period"} />
          <Cards
            staticCardData={true}
            cardData={SW_REPORT_FILTER}
            selectedVehicleAccident={days}
            cardClickHandler={dayCardOnClick}
          />
        </Grid>
        {days?.value === "Custom" ? (
          <>
            <Grid item container mt={1} spacing={1}>
              <Grid item lg={6} xs={12} sm={12}>
                <FormLabels label={"Custom Time Period"} isRequired={false} />
              </Grid>
            </Grid>

            <Grid item container spacing={2} mb={2}>
              <Grid item lg={4} xs={12} sm={12} md={4}>
                <FormLabels label={"From"} isRequired={false} />
                <DatePicker
                  maxDateEnable={false}
                  value={startDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeStartdatePicker(e)}
                />
              </Grid>
              <Grid item lg={4} xs={12} sm={12} md={4}>
                <FormLabels label={"To"} isRequired={false} />
                <DatePicker
                  maxDateEnable={false}
                  minDate={startDate}
                  value={endDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeEnddatePicker(e)}
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        <Grid item lg={12} xs={12} sm={12} md={8} mt={5}>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) =>
                  r?.date +
                  "|" +
                  r?.totalInspections +
                  "|" +
                  Date.now() +
                  Math.floor(Math.random() * 100)
                }
                rows={
                  getDateWiseSummaryList?.data?.inspectionDatewiseReportList ??
                  []
                }
                rowCount={getDateWiseSummaryList?.data?.count ?? 0}
                columns={credcloumns}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={REPORT_PAGE_SIZE}
                rowsPerPageOptions={[REPORT_PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={8} mt={5}>
          <LineChart
            data={getDateWiseSummaryList?.data?.inspectionDatewiseReportList}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SwDateWiseSummary;
