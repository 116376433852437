import React, { useEffect, useState } from "react";
import Button from "../../../library/custom/button/Button";
import { InputField } from "../../../library/custom/textBox/InputField";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../library/custom/textArea/TextArea";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { NumberField } from "../../../library/custom/numberField/numberField";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import { ESTABLISHMENT_TYPE, USER_ID } from "../../../constant/constant";
import Popover from "@mui/material/Popover";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./establishmentAdd.scss";
import { ADMIN_CATEGORY_INCIDENT } from "../../../constant/routeContant";

const EstablishmentAdd = (props) => {
  const [zipCode, setZipCode] = useState("");
  const [naics, setnaics] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idQuestion = open ? "simple-popover" : undefined;

  const intialState = {
    establishmentId: props?.editMode ? props.establishmentId : 0,
    name: "",
    street: "",
    city: "",
    stateId: "",
    indDesc: "",
    sic: "",
    naics: "",
    ein: "",
    zipCode: "",
    establishmentType: 0,
  };
  const [formValue, setFormValue] = useState(intialState);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });

    if (name === "naics" && value.length > 6) {
      setErrors({ ...errors, naics: "NAICS should be 6 digits." });
    } else if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formValue.name?.trim()) {
      newErrors.name = "Establishment is required.";
    } else if (formValue?.name?.length > 100) {
      newErrors.name =
        "Establishment Name must contain 100 or fewer characters.";
    }
    if (!formValue.street) {
      newErrors.street = "Street is required.";
    }
    if (!formValue.city) {
      newErrors.city = "City is required.";
    }

    if (!formValue.stateId) {
      newErrors.stateId = "state is required.";
    }
    if (!formValue.naics) {
      newErrors.naics = "NAICS is required.";
    }
    if (!formValue.ein) {
      newErrors.ein = "EIN is required.";
    }
    if (formValue.naics && formValue.naics.length !== 6) {
      newErrors.naics = "NAICS should be 6 digits.";
    }
    if (formValue.ein && formValue.ein.length !== 9) {
      newErrors.ein = "EIN should be 9 digits.";
    }
    if (formValue.indDesc && formValue.indDesc?.length > 250) {
      newErrors.indDescError =
        "Industry Description should be less than 250 characters.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const validationErrors = validate();
    if (zipCode?.length === 0) {
      setZipCodeError(true);
      validationErrors.zipCode = "Zip Code is required.";
    } else {
      setZipCodeError(false);
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const dataToSubmit = {
      ...formValue,
      createdBy: parseInt(USER_ID()),
    };
    if (props?.editMode) {
      dataToSubmit.modifiedBy = parseInt(USER_ID());
    }
    props?.addEstablishmentHandler(dataToSubmit);
  };

  const dataBack = {
    title: props?.routeText ? props?.routeText : "Back to Establishment",
    route: props?.route ? props?.route : ADMIN_CATEGORY_INCIDENT,
    state: {
      flag: 13,
      year: props?.year,
      establishmentId: props?.establishmentId,
    },
  };

  useEffect(() => {
    if (props?.establishmentDetail && props?.editMode) {
      setFormValue({
        ...formValue,
        establishmentId: props?.establishmentDetail?.establishmentId,
        name: props?.establishmentDetail?.establishmentName,
        street: props?.establishmentDetail?.street,
        city: props?.establishmentDetail?.city,
        stateId: props?.establishmentDetail?.stateId,
        indDesc: props?.establishmentDetail?.industryDescription,
        sic: props?.establishmentDetail?.sic,
        naics: props?.establishmentDetail?.naics,
        ein: props?.establishmentDetail?.ein,
        establishmentType: props?.establishmentDetail?.establishmentType,
        zipCode: props?.establishmentDetail?.zipCode,
      });
      setZipCode(props?.establishmentDetail?.zipCode);
    }
  }, [props?.editMode, props?.establishmentId, props?.establishmentDetail]);

  return (
    <>
      <div className="overidePersonal">
        <Grid container>
          <Grid item lg={9} md={12} xs={12} pr={2}>
            <Grid container item lg={12} xs={12} sm={12} md={9}>
              <Grid item lg={12} xs={12} sm={12} md={4} textAlign="left">
                <div className="heading_style">
                  {props?.editMode ? "Edit Establishment" : "Add Establishment"}
                </div>
              </Grid>
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12}>
              <Grid container item lg={12} md={12} xs={12} mt={5}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={true}
                      type="text"
                      label="Establishment"
                      name="name"
                      placeholder={"Add Establishment Here..."}
                      value={formValue?.name}
                      onChange={handleChange}
                      disabled={props?.editMode}
                    />
                    {errors.name && (
                      <div className="errorMsg">{errors.name}</div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} xs={12} mt={1} spacing={2}>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={true}
                      type="text"
                      label="Street"
                      name="street"
                      placeholder={"Add Street Here..."}
                      value={formValue?.street}
                      onChange={handleChange}
                    />
                    {errors.street && (
                      <div className="errorMsg">{errors.street}</div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={true}
                      type="text"
                      label="City"
                      name="city"
                      placeholder={"Add City Here..."}
                      value={formValue?.city}
                      onChange={handleChange}
                    />
                    {errors.city && (
                      <div className="errorMsg">{errors.city}</div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="State" isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={props?.state}
                      value={formValue?.stateId}
                      name="stateId"
                      onchangehandler={handleChange}
                    />
                    {errors.stateId && (
                      <div className="errorMsg">{errors.stateId}</div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <NumberField
                      isRequired={true}
                      type="text"
                      label={"Zip Code"}
                      maxLength="5"
                      name="zipCode"
                      placeholder={"Add Zip Code Here..."}
                      value={zipCode}
                      onChange={(value) => {
                        if (value.length <= 5) {
                          setZipCode(value);
                          setFormValue({ ...formValue, zipCode: value });
                        }
                      }}
                    />
                    {zipCodeError && !zipCode && (
                      <div className="errorMsg">{"ZipCode is required"}</div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item lg={12} md={12} xs={12} mt={2}>
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <FormLabels
                      label="Industry Desciption"
                      isRequired={false}
                    />
                    <TextArea
                      name="indDesc"
                      placeholder={"Add Industry Desciption Here..."}
                      value={formValue?.indDesc}
                      onChange={handleChange}
                    />
                  </FormControl>
                  {errors.indDescError && (
                      <div className="errorMsg">{errors.indDescError}</div>
                    )}
                </Grid>
              </Grid>

              <Grid container item lg={12} md={12} xs={12} mt={1} spacing={2}>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <NumberField
                      isRequired={true}
                      questionMark={false}
                      type="text"
                      label={"EIN"}
                      maxLength="9"
                      name="ein"
                      placeholder={"Add EIN Here..."}
                      value={formValue?.ein}
                      onChange={(value) => {
                        if (value.length <= 9) {
                          setnaics(value);
                          setFormValue({ ...formValue, ein: value });
                        }
                      }}
                    />
                    {((formValue.ein && formValue.ein.length !== 9) ||
                      !formValue?.ein) && (
                      <div className="errorMsg">{errors.ein}</div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Establishment Type" isRequired={false} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={ESTABLISHMENT_TYPE}
                      value={formValue?.establishmentType}
                      name="establishmentType"
                      onchangehandler={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item lg={12} md={12} xs={12} mt={2}>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={false}
                      type="text"
                      label="Standard Industrial Classification(SIC)"
                      name="sic"
                      placeholder={
                        "Add Standard Industrial Classification(SIC) Here..."
                      }
                      value={formValue?.sic}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container lg={12} md={12} xs={12} mt={2}>
                <Grid item lg={6} xs={12} sm={6} md={6}>
                  <div className="questionMarkContainer">
                    <span className="customLable">
                      North American Industrial Classification (NAICS)
                      <span>*</span>
                    </span>
                    <span
                      className="questionMark"
                      aria-describedby={idQuestion}
                      variant="contained"
                      onClick={handleClick}
                    >
                      <HelpOutlineIcon />
                    </span>
                    <Popover
                      id={idQuestion}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div className="questonMarkDes">
                        Find your NAICS number{" "}
                        <span>
                          <a
                            href="https://www.census.gov/naics/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            here
                          </a>
                        </span>
                      </div>
                    </Popover>{" "}
                  </div>
                  <FormControl fullWidth>
                    <NumberField
                      isRequired={true}
                      questionMark={true}
                      type="text"
                      label={false}
                      maxLength="10"
                      name="naics"
                      placeholder={
                        "Add North American Industrial Classification (NAICS)..."
                      }
                      value={formValue?.naics}
                      onChange={(value) => {
                        if (value.length <= 6) {
                          setnaics(value);
                          setFormValue({ ...formValue, naics: value });
                        }
                      }}
                    />
                    {((formValue.naics && formValue.naics.length !== 6) ||
                      !formValue?.naics) && (
                      <div className="errorMsg">{errors.naics}</div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={5}>
                <Grid container item lg={12} gap={12} alignItems="center">
                  <Grid
                    item
                    lg={4}
                    textAlign="center"
                    className="addcourseButton"
                  >
                    <BackBtn dataBack={dataBack} />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    textAlign="center"
                    className="addcourseButton"
                  >
                    <Button
                      varientContained={true}
                      label={props?.editMode ? "Update" : "Submit"}
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EstablishmentAdd;
