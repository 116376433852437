import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import Table from "../../../../library/custom/table/table";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import {
  downloadExcel,
  downloadPDF,
  getFormatedDate,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import {
  VIEW_OPEN_ITEMS_DETAILS,
  VIEW_INCIDENT_REPORT,
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
} from "../../../../constant/routeContant";
import { PAGE_SIZE_100 } from "../../../../constant/constant";

const ItemIncidents = (props) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    if (props?.incidentReportInjuredBodyPartList?.data?.result?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incidentReportInjuredBodyPartList?.data?.result?.records,
      ]);
    }
  }, [props?.incidentReportInjuredBodyPartList?.data?.result?.records]);
  useEffect(() => {
    if (props?.incidentReportMostCommonInjuryList?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incidentReportMostCommonInjuryList?.records,
      ]);
    }
  }, [props?.incidentReportMostCommonInjuryList?.records]);
  useEffect(() => {
    if (props?.vehiclewithmostincidentsLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.vehiclewithmostincidentsLists?.records,
      ]);
    }
  }, [props?.vehiclewithmostincidentsLists?.records]);
  useEffect(() => {
    if (props?.projectwithmostincidentsLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.projectwithmostincidentsLists?.records,
      ]);
    }
  }, [props?.projectwithmostincidentsLists?.records]);

  useEffect(() => {
    if (props?.incUserMostIncidentLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incUserMostIncidentLists?.records,
      ]);
    }
  }, [props?.incUserMostIncidentLists?.records]);

  useEffect(() => {
    if (props?.incUsrMostActionItemLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incUsrMostActionItemLists?.records,
      ]);
    }
  }, [props?.incUsrMostActionItemLists?.records]);
  const fetchMoreData = async (pageNumber) => {
    const { locationId, divisionId, startDate, endDate, incidentType } =
      props.propsItemsData?.drillDetail;
    try {
      let response;
      let data = {
        page: pageNumber,
        pageSize: PAGE_SIZE_100,
        divisionId: locationId,
        locationId: divisionId,
        fromDate: startDate,
        toDate: endDate,
        incidentType: incidentType > 0 ? incidentType : 0,
      };
      if (
        props?.propsItemsData?.drillDetailFull?.page ===
        "most_injured_body_part"
      ) {
        data.bodyPartId = props?.propsItemsData?.bodyPartId;
        response = await props.getinjuryBodyPartList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
      ) {
        data.InjuryId = props?.propsItemsData?.InjuryId;
        response = await props.getMostCommonInjuryList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "vehicle_most_incident"
      ) {
        data.VehicleId = props?.propsItemsData?.VehicleId;
        response = await props.getVehicleMostIncidentList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "project_most_incident"
      ) {
        data.ProjectId = props?.propsItemsData?.ProjectId;
        response = await props.getProjectMostIncidentList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "user_most_incident"
      ) {
        data.userId = props?.propsItemsData?.userId;
        response = await props.getUserwithMostIncidentList(data);
      } else if (props?.propsItemsData?.itemTypeValue === "user_most_action") {
        data.userId = props?.propsItemsData?.userId;
        response = await props.getUserwithMostItemList(data);
      }
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const columns1 = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "userInjured",
      label: "User Injured",
      sortable: true,
      isSorted: sortColumn === "userInjured",
      sortDirection,
    },

    {
      id: "enteredBy",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];

  const columnsForInjuryType = columns1?.map((column) => {
    if (column.id === "dateOfEntry") {
      return {
        ...column,
        id: "createdOn",
        label: "Date of Entry",
        isSorted: sortColumn === "createdOn",
        sortDirection,
      };
    }
    return column;
  });

  const columns2 = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
    },
    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },

    {
      id: "enteredByName",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredByName",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];
  const columns3 = [
    {
      id: "itemId",
      label: `Item ID`,
      sortable: true,
      isSorted: sortColumn === "itemId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "subRootId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "subRootId",
      sortDirection,
      className: "inc_case-id",
    },

    {
      id: "rootId",
      label: `Incident ID`,
      sortable: true,
      isSorted: sortColumn === "rootId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },

    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },

    {
      id: "enteredBy",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];
  const columns4 = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
    },

    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },

    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },

    {
      id: "enteredByName",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredByName",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];
  const columns5 = [
    //vehicle most incidents
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },

    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },

    {
      id: "unitNo",
      label: "Unit No.",
      sortable: true,
      isSorted: sortColumn === "unitNo",
      sortDirection,
    },

    {
      id: "enteredBy",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];

  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
        apiData: props?.propsItemsData,
        backRoute: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
        backRouteText: "Back to Reports & Analytics",
        incidentURL: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
      },
    });
  }
  const handleViewOnClick = (rows) => {
    if (
      rows?.incTypeId === 2 ||
      rows?.incTypeId === 1 ||
      rows?.incTypeId === 3 ||
      rows?.incTypeId === 4 ||
      rows?.incTypeId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incTypeId
      );
    }
  };
  const handleViewOnClickAction = (row) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: row?.itemId,
        itemTypeId: row?.itemTypeId,
        rootId: row?.rootId,
        subRootId: row?.subRootId,
        assignedPartyId: row?.assignedTo,
        status: row?.status,
        origin: row?.origin,
        itemType: row?.itemType,
        addedById: row?.enteredBy,
        involvedPartyName: row?.involvedPartyName,
        involvedPartyId: row?.involvedPartyId,
        incidentType: row?.incTypeId,
        apiData: props?.propsItemsData,
        backRoute: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
        backRouteText: "Back to Reports & Analytics",
      },
    });
  };
  // most injured and injurty type
  const row1 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td className="inc_row_reportId">{asset?.reportId}</td>
      <td className="inc_row_caseid">{asset?.caseId}</td>
      <td className="inc_row_dateOfEntry">
        {props?.propsItemsData?.drillDetailFull?.page ===
        "most_injured_body_part"
          ? asset?.dateOfEntry
            ? getFormatedDate(asset?.dateOfEntry)
            : ""
          : props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
          ? asset?.createdOn
            ? asset?.createdOn
            : ""
          : ""}
      </td>
      <td>{asset?.assetName}</td>
      <td>{asset?.userInjured}</td>
      <td>{asset?.enterByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  // user with most incidents
  const row2 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>
      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  // Users with Most no. of Open Action Items
  const row3 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.itemId}</td>
      <td className="inc_row_caseid">{asset?.subRootId}</td>
      <td className="inc_row_reportId">{asset?.rootId}</td>
      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.incidentType}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClickAction(asset)}
          viewLabel={"View Action"}
        />
      </td>
    </tr>
  ));
  //project with most incident
  const row4 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>
      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.incidentType}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredByName}</td>

      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  // vehicle with most incident
  const row5 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>

      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.incType}</td>
      <td>{asset?.unitNo}</td>
      <td>{asset?.enteredByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };
  const rowsPdf = data?.map((item) => [
    item?.reportId,
    item?.caseId,
    props?.propsItemsData?.drillDetailFull?.page === "most_injured_body_part"
      ? item?.dateOfEntry
        ? getFormatedDate(item?.dateOfEntry)
        : ""
      : props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
      ? item?.createdOn
        ? item?.createdOn
        : ""
      : "",

    item?.assetName,
    item?.userInjured,
    item?.enterByName,
    item?.status,
  ]);

  const rowsPdf_userincident = data?.map((item) => [
    // pdf data for user action
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.dateOfEntry),
    item?.assetName,
    item?.enteredByName,
    item?.status,
  ]);

  const rowsPdf_userAction = data?.map((item) => [
    // pdf data for user action
    item?.itemId,
    item?.subRootId,
    item?.rootId,
    getFormatedDate(item?.dateOfEntry),
    item?.incidentType,
    item?.assetName,
    item?.enteredByName,
    item?.status,
  ]);

  const rowsPdf_project = data?.map((item) => [
    // pdf data for vehicle
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.dateOfEntry),
    item?.incidentType,
    item?.assetName,
    item?.enteredByName,
    item?.status,
  ]);
  const rowsPdf_vehicle = data?.map((item) => [
    // pdf data for vehicle
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.dateOfEntry),
    item?.incType,
    item?.unitNo,
    item?.enteredByName,
    item?.status,
  ]);

  const dataBack = {
    title: "Back",
    route: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
    state: props.propsItemsData?.drillDetailFull,
  };

  return (
    <>
      <div>
        <BackBtn dataBack={dataBack} />
      </div>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">
            {props?.propsItemsData?.drillDetailFull?.page === "user_most_action"
              ? "View Action"
              : "View Incident Reports"}
          </div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={
                      props?.propsItemsData?.drillDetailFull?.page ===
                      "most_injured_body_part"
                        ? () =>
                            downloadPDF(
                              rowsPdf,
                              columns1.map((col) => col.label),
                              "mostInjuredBodyPart.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "mostCommonInjuryType"
                        ? () =>
                            downloadPDF(
                              rowsPdf,
                              columnsForInjuryType.map((col) => col.label),
                              "mostCommonInjuryType.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "user_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf_userincident,
                              columns2.map((col) => col.label),
                              "user_most_incident.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "vehicle_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf_vehicle,
                              columns5.map((col) => col.label),
                              "vehicle-most-incident.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "project_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf_project,
                              columns4.map((col) => col.label),
                              "project-most-incident.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "user_most_action"
                        ? () =>
                            downloadPDF(
                              rowsPdf_userAction,
                              columns3.map((col) => col.label),
                              "user_most_action.pdf"
                            )
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.5 8.5H6.5V7H7C7.28333 7 7.52083 6.90417 7.7125 6.7125C7.90417 6.52083 8 6.28333 8 6V5.5C8 5.21667 7.90417 4.97917 7.7125 4.7875C7.52083 4.59583 7.28333 4.5 7 4.5H5.5V8.5ZM6.5 6V5.5H7V6H6.5ZM8.5 8.5H10C10.2833 8.5 10.5208 8.40417 10.7125 8.2125C10.9042 8.02083 11 7.78333 11 7.5V5.5C11 5.21667 10.9042 4.97917 10.7125 4.7875C10.5208 4.59583 10.2833 4.5 10 4.5H8.5V8.5ZM9.5 7.5V5.5H10V7.5H9.5ZM11.5 8.5H12.5V7H13.5V6H12.5V5.5H13.5V4.5H11.5V8.5ZM4.5 13C4.0875 13 3.73438 12.8531 3.44062 12.5594C3.14687 12.2656 3 11.9125 3 11.5V1.5C3 1.0875 3.14687 0.734376 3.44062 0.440626C3.73438 0.146876 4.0875 0 4.5 0H14.5C14.9125 0 15.2656 0.146876 15.5594 0.440626C15.8531 0.734376 16 1.0875 16 1.5V11.5C16 11.9125 15.8531 12.2656 15.5594 12.5594C15.2656 12.8531 14.9125 13 14.5 13H4.5ZM1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3H1.5V14.5H13V16H1.5Z"
                        fill="none"
                      />
                    </svg>
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    //  onClick={() => downloadExcel(data, "All-items.xlsx")}
                    onClick={() =>
                      downloadExcel(
                        data,
                        `${props?.propsItemsData?.itemTypeValue}.xlsx`
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6635_4490)">
                        <path
                          d="M9.22 0.0100392L0.26 1.71004C0.10875 1.73879 0 1.87629 0 2.03004V13.97C0 14.1238 0.10875 14.2613 0.26 14.29L9.22 15.99C9.24 15.9938 9.26 16 9.28 16C9.35375 16 9.4225 15.9775 9.48 15.93C9.55375 15.8688 9.6 15.775 9.6 15.68V0.320039C9.6 0.225039 9.55375 0.131289 9.48 0.0700392C9.40625 0.0087892 9.31375 -0.00746081 9.22 0.0100392ZM10.24 1.92004V4.16004H10.88V4.80004H10.24V6.40004H10.88V7.04004H10.24V8.64004H10.88V9.28004H10.24V11.2H10.88V11.84H10.24V14.08H15.04C15.3925 14.08 15.68 13.7925 15.68 13.44V2.56004C15.68 2.20754 15.3925 1.92004 15.04 1.92004H10.24ZM11.52 4.16004H14.08V4.80004H11.52V4.16004ZM2.14 5.02004H3.78L4.64 6.81004C4.7075 6.95129 4.7675 7.12504 4.82 7.32004H4.83C4.86375 7.20379 4.92875 7.02129 5.02 6.79004L5.97 5.02004H7.47L5.68 7.98004L7.52 11H5.93L4.89 9.05004C4.85125 8.97754 4.81125 8.84379 4.77 8.65004H4.76C4.74 8.74129 4.6925 8.88379 4.62 9.07004L3.58 11H1.98L3.89 8.01004L2.14 5.02004ZM11.52 6.40004H14.08V7.04004H11.52V6.40004ZM11.52 8.64004H14.08V9.28004H11.52V8.64004ZM11.52 11.2H14.08V11.84H11.52V11.2Z"
                          fill="none"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6635_4490">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>

      <div className="customTableWrapper">
        <Table
          columns={
            props?.propsItemsData?.drillDetailFull?.page ===
            "most_injured_body_part"
              ? columns1
              : props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
              ? columnsForInjuryType
              : props?.propsItemsData?.itemTypeValue === "user_most_incident"
              ? columns2
              : props?.propsItemsData?.itemTypeValue === "user_most_action"
              ? columns3
              : props?.propsItemsData?.itemTypeValue === "project_most_incident"
              ? columns4
              : props?.propsItemsData?.itemTypeValue === "vehicle_most_incident"
              ? columns5
              : ""
          }
          rows={
            props?.propsItemsData?.drillDetailFull?.page ===
              "most_injured_body_part" ||
            props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
              ? row1
              : props?.propsItemsData?.itemTypeValue === "user_most_incident"
              ? row2
              : props?.propsItemsData?.itemTypeValue === "user_most_action"
              ? row3
              : props?.propsItemsData?.itemTypeValue === "project_most_incident"
              ? row4
              : props?.propsItemsData?.itemTypeValue === "vehicle_most_incident"
              ? row5
              : ""
          }
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default ItemIncidents;
