import React, { useEffect } from "react";
import {
  ACCESS_DENIED,
  CREDENTIAL_LOOKUP,
} from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as credentialAction from "../../../redux/actions/credentialAction";
import Loader from "../../../library/common/Loader";
import { API_STATUS } from "../../../constant/constant";
import ViewLookupCredential from "../component/viewLookupCredential";
import { checkPrivileges } from "../../../utils/rolesHelper";

function ViewLookupCredentialContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const credentialId = location?.state?.credentialId;

  const viewCredentialData = useSelector(
    (state) => state?.getCredentialViewDetsilsData?.data
  );

  const showLoader = useSelector(
    (state) => state?.getCredentialViewDetsilsData?.isLoading
  );
  const isSuccess = useSelector(
    (state) => state.getCredentialViewDetsilsData.status === API_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(credentialAction.getCredentialViewDetsils(credentialId));
  }, [dispatch]);

  const dataBack = {
    title: "Back to Credential",
    route: CREDENTIAL_LOOKUP,
  };

  return (
    <>
      {checkPrivileges([12, 13, 47, 48]) ? (
        <>
          {showLoader && <Loader />}
          {isSuccess && (
            <ViewLookupCredential
              dataBack={dataBack}
              viewCredentialData={viewCredentialData}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default ViewLookupCredentialContainer;
