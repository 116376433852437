import React from "react";
import "./oshaFormPdf.scss";
import commonImages from "../../../assets";

const OshaFormPdf = ({
  oshaEstDetails,
  oshaDetail,
  numberOfEmployees,
  hoursWorked,
}) => {
  const naicsString = oshaEstDetails?.naics;
  const digit1 = naicsString && naicsString[0] ? naicsString[0] : "";
  const digit2 = naicsString && naicsString[1] ? naicsString[1] : "";
  const digit3 = naicsString && naicsString[2] ? naicsString[2] : "";
  const digit4 = naicsString && naicsString[3] ? naicsString[3] : "";
  const digit5 = naicsString && naicsString[4] ? naicsString[4] : "";
  const digit6 = naicsString && naicsString[5] ? naicsString[5] : "";

  const sicString = oshaEstDetails?.sic;
  const sicDigit1 = sicString && sicString[0] ? sicString[0] : "";
  const sicDigit2 = sicString && sicString[1] ? sicString[1] : "";
  const sicDigit3 = sicString && sicString[2] ? sicString[2] : "";
  const sicDigit4 = sicString && sicString[3] ? sicString[3] : "";
  const sicDigit5 = sicString && sicString[4] ? sicString[4] : "";
  const sicDigit6 = sicString && sicString[5] ? sicString[5] : "";

  return (
    <>
      <div className="osha-pdf-container">
        <div className="header">
          <div className="left-section">
            <h1>
              OSHA Form 300A <span>(Rev. 01/2004)</span>
            </h1>
            <p>Summary of Work-Related Injuries and Illnesses</p>
          </div>
          <div className="right-section">
            <div className="oshaIconContainer">
              <div className="yearSection">
                <div>
                  Year <span className="year">{oshaDetail?.year}</span>
                </div>
              </div>
              <div className="oshaIconSection">
                <img src={commonImages.oshaPdfIcon} alt="icon" />
              </div>
            </div>
            <h1>U.S. Department of Labor</h1>
            <p>Occupational Safety and Health Administration</p>
          </div>
        </div>
        <div className="approvedDateStyle">Form approved OMB no. 1218-0176</div>

        <div className="note">
          <p>
            All establishments covered by Part 1904 must complete this Summary
            page, even if no work-related injuries or illnesses occurred during
            the year. Remember to review the Log to verify that the entries are
            complete and accurate before completing this summary. Using the Log,
            count the individual entries you made for each category. Then write
            the totals below, making sure you’ve added the entries from every
            page of the Log. If you had no cases, write “0.” <br /> Employees,
            former employees, and their representatives have the right to review
            the OSHA Form 300 in its entirety. They also have limited access to
            the OSHA Form 301 or its equivalent. See 29 CFR Part 1904.35, in
            OSHA’s recordkeeping rule, for further details on the access
            provisions for these forms.
          </p>
        </div>

        <div className="section">
          <h2 className="section-title">Number of Cases</h2>
          <div className="grid grid-4">
            <div className="field">
              <div className="label">Total number of deaths</div>
              <div className="line">
                <span className="line-value1">
                  {oshaDetail?.caseNumberOfDeath}
                </span>
                <span className="line-label1">(G)</span>
              </div>
            </div>
            <div className="field">
              <div className="label">
                Total number of cases with days away from work
              </div>
              <div className="line">
                <span className="line-value">
                  {oshaDetail?.caseNumberOfDaysAway}
                </span>
                <span className="line-label">(H)</span>
              </div>
            </div>
            <div className="field">
              <div className="label">
                Total number of cases with job transfer or restriction
              </div>
              <div className="line">
                <span className="line-value">
                  {oshaDetail?.caseNumberOfTransfer}
                </span>
                <span className="line-label">(I)</span>
              </div>
            </div>
            <div className="field">
              <div className="label">
                Total number of other recordable cases
              </div>
              <div className="line">
                <span className="line-value">
                  {oshaDetail?.caseNumberOtherRecordable}
                </span>
                <span className="line-label">(J)</span>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2 className="section-title">Number of Days</h2>
          <div className="grid grid-2">
            <div className="field">
              <div className="label">Total number of days away from work</div>
              <div className="line">
                <span className="line-value">
                  {oshaDetail?.numberOfDaysAway}
                </span>
                <span className="line-label">(K)</span>
              </div>
            </div>
            <div className="field">
              <div className="label">
                Total number of days of job transfer or restriction
              </div>
              <div className="line">
                <span className="line-value">
                  {oshaDetail?.numberOfTransfer}
                </span>
                <span className="line-label">(L)</span>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2 className="section-title">Injury and Illness Types</h2>
          <p className="totalNumber">Total number of.. (M)</p>
          <div className="injury-section">
            <div className="injury-grid">
              <div className="injury-field">
                <div className="injury-label">1. Injuries</div>
                <div className="injury-line">
                  <span className="injury-line-value">
                    {oshaDetail?.injuries}
                  </span>
                </div>
              </div>
              <div className="injury-field">
                <div className="injury-label">4. Poisonings</div>
                <div className="injury-line">
                  <span className="injury-line-value">
                    {oshaDetail?.poisoning}
                  </span>
                </div>
              </div>
              <div className="injury-field">
                <div className="injury-label">2. Skin Disorders</div>
                <div className="injury-line">
                  <span className="injury-line-value">
                    {oshaDetail?.skinDisorder}
                  </span>
                </div>
              </div>
              <div className="injury-field">
                <div className="injury-label">5. Hearing Loss</div>
                <div className="injury-line">
                  <span className="injury-line-value">
                    {oshaDetail?.hearingLoss}
                  </span>
                </div>
              </div>
              <div className="injury-field">
                <div className="injury-label">3. Respiratory Conditions</div>
                <div className="injury-line">
                  <span className="injury-line-value">
                    {oshaDetail?.respiratoryCondition}
                  </span>
                </div>
              </div>
              <div className="injury-field">
                <div className="injury-label">6. All other illnesses</div>
                <div className="injury-line">
                  <span className="injury-line-value">
                    {oshaDetail?.otherIllnesses}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="summary-section">
          <p class="summary-title">
            Post this Summary page from February 1 to April 30 of the year
            following the year covered by the
            <br /> form.
          </p>
          <p class="summary-text">
            Complete and review the collection of information. Persons are not
            required to respond to the collection of information unless it
            displays a currently valid OMB control number. If you have any
            comments about these estimates or any other aspects of this data
            collection, contact: US Department of Labor, OSHA Office of
            Statistical Analysis, Room N-3644, 200 Constitution Avenue, NW,
            Washington, DC 20210. Do not send the completed forms to this
            office.
          </p>
        </div>

        <div className="osha-form-container">
          <h2>Establishment Information</h2>
          <div className="osha-form-group">
            <label>Establishment Name</label>
            <span className="osha-line">
              {oshaEstDetails?.establishmentName}
            </span>
          </div>
          <div className="osha-form-group">
            <label>Street</label>
            <span className="osha-line">{oshaEstDetails?.street}</span>
            <label>City</label>
            <span className="osha-line">{oshaEstDetails?.city}</span>
          </div>
          <div className="osha-form-group">
            <label>State</label>
            <span className="osha-line">{oshaEstDetails?.state}</span>
            <label>Zip Code</label>
            <span className="osha-line">{oshaEstDetails?.zipCode}</span>
          </div>
          <div className="osha-form-group osha-full-line-group">
            <label>
              Industry description (e.g., Truck trailer manufacturer)
            </label>
            <div className="osha-line-full">
              {oshaEstDetails?.industryDescription}
            </div>
          </div>

          <div className="classification-group">
            <label>
              Standard Industrial Classification (SIC), if known (e.g., 7568)
            </label>
            <div className="line-container">
              <span className="line segment">{sicDigit1}</span>
              <span className="line segment">{sicDigit2}</span>
              <span className="line segment">{sicDigit3}</span>
              <span className="line segment">{sicDigit4}</span>
              <span className="line segment">{sicDigit5}</span>
              <span className="line segment">{sicDigit6}</span>
            </div>
          </div>
          <p className="or-text">OR</p>
          <div className="classification-group">
            <label>
              North American Industrial Classification (NAICS), if known (e.g.,
              336212)
            </label>
            <div className="line-container">
              <span className="line segment">{digit1}</span>
              <span className="line segment">{digit2}</span>
              <span className="line segment">{digit3}</span>
              <span className="line segment">{digit4}</span>
              <span className="line segment">{digit5}</span>
              <span className="line segment">{digit6}</span>
            </div>
          </div>

          <h3>Employment Information</h3>
          <p className="osha-info-text">
            (If you don't have these figures, see the Worksheet on the back of
            this page to estimate.)
          </p>
          <div className="osha-form-group">
            <label>Annual average number of employees</label>
            <span className="osha-line">
              {numberOfEmployees
                ? numberOfEmployees
                : oshaDetail?.avgNumberOfEmployees}
            </span>
          </div>
          <div className="osha-form-group">
            <label>Total hours worked by all employees last year</label>
            <span className="osha-line">
              {hoursWorked ? hoursWorked : oshaDetail?.totalManHours}
            </span>
          </div>
          <h3 className="signHere">Sign Here</h3>
          <p className="osha-info-text1">
            Knowingly falsifying this document may result in a fine.
          </p>
          <p className="osha-certify-text">
            I certify that I have examined this document and that to the best of
            my knowledge the entries are true, accurate, and complete.
          </p>
          <div className="signature-section">
            <div className="signature-row">
              <div className="signature-field">
                <span className="signature-line"></span>
                <label>Company Executive</label>
              </div>
              <div className="signature-field">
                <span className="signature-line"></span>
                <label>Title</label>
              </div>
            </div>
            <div className="signature-row">
              <div className="signature-field">
                <span className="signature-line"></span>
                <label>Phone</label>
              </div>
              <div className="signature-field">
                <span className="signature-line"></span>
                <label>Date</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OshaFormPdf;
