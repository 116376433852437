import { contactType } from "../constants/contactType";

export const getContactList = (data) => ({
  type: contactType.GET_CONTACTS_LIST,
  data,
});

export const getContactListSuccess = (data) => ({
  type: contactType.GET_CONTACTS_LIST_SUCCESS,
  data,
});

export const getContactListEmailSuccess = () => ({
  type: contactType.GET_CONTACTS_LIST_EMAIL_SUCCESS,
});

export const getContactListFailure = (error) => ({
  type: contactType.GET_CONTACTS_LIST_FAILURE,
  error,
});

export const createContact = (data) => ({
  type: contactType.POST_CONTACTS,
  data,
});

export const createContactSuccess = (data) => ({
  type: contactType.POST_CONTACTS_SUCCESS,
  data,
});

export const createsContactFailure = (error) => ({
  type: contactType.POST_CONTACTS_FAILURE,
  error,
});

export const updateContact = (payload) => ({
  type: contactType.UPDATE_CONTACT_PENDING,
  payload: payload,
});

export const updateContactSuccess = (data) => ({
  type: contactType.UPDATE_CONTACT_SUCCESS,
  data,
});

export const updateContactFailure = (error) => ({
  type: contactType.UPDATE_CONTACT_FAILED,
  error,
});

export const clearContact = () => ({
  type: contactType.CLEAR_CONTACT,
  data: "",
});

export const editContact = (data) => ({
  type: contactType.EDIT_CONTACT,
  data,
});

export const getContactDetails = (contactId) => ({
  type: contactType.GET_CONTACT_DETAILS_PENDING,
  contactId,
});

export const getContactDetailsSuccess = (data) => ({
  type: contactType.GET_CONTACT_DETAILS_SUCCESS,
  data,
});

export const getContactDetailsFailure = (error) => ({
  type: contactType.GET_CONTACT_DETAILS_FAILED,
  error,
});

export const deleteContacts = (payload) => ({
  type: contactType.DELETE_CONTACT_PENDING,
  payload: payload,
});

export const deleteContactsSuccess = (payload) => ({
  type: contactType.DELETE_CONTACT_SUCCESS,
  payload: payload,
});

export const deleteContactsFailure = (payload) => ({
  type: contactType.DELETE_CONTACT_FAILED,
  payload: payload,
});

export const convertNonPrivilegeContactList = (payload) => ({
  type: contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST,
  payload,
});

export const convertNonPrivilegeContactListSuccess = (data) => ({
  type: contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST_SUCCESS,
  data,
});

export const convertNonPrivilegeContactListFailure = (error) => ({
  type: contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST_FAILURE,
  error,
});
