import { credentialType } from "../constants/credentialType";

export const createCredential = (data) => ({
  type: credentialType.POST_CREDENTIAL_PENDING,
  data,
});

export const createCredentialSuccess = (data) => ({
  type: credentialType.POST_CREDENTIAL_SUCCESS,
  data,
});

export const createCredentialFailure = (error) => ({
  type: credentialType.POST_CREDENTIAL_FAILURE,
  error,
});

export const updateCredential = (data) => ({
  type: credentialType.PUT_CREDENTIAL_PENDING,
  data,
});

export const updateCredentialSuccess = (data) => ({
  type: credentialType.PUT_CREDENTIAL_SUCCESS,
  data,
});

export const updateCredentialFailure = (error) => ({
  type: credentialType.PUT_CREDENTIAL_FAILURE,
  error,
});

export const getCredentialList = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_PENDING,
  data,
});

export const getCredentialListSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_SUCCESS,
  data,
});

export const getCredentialListEmailSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_EMAIL_SUCCESS,
  data,
});

export const getCredentialListFailure = (error) => ({
  type: credentialType.GET_CREDENTIAL_LIST_FAILURE,
  error,
});

export const getCredentialDetsils = (data) => ({
  type: credentialType.GET_CREDENTIAL_DETAILS,
  data,
});

export const getCredentialDetsilsSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_DETAILS_SUCCESS,
  data,
});

export const getCredentialDetailsFailure = (error) => ({
  type: credentialType.GET_CREDENTIAL_DETAILS_FAILURE,
  error,
});

export const getAssignUserList = (data) => ({
  type: credentialType.GET_ASSIGN_USER_LIST_PENDING,
  data,
});

export const getAssignUserListSuccess = (data) => ({
  type: credentialType.GET_ASSIGN_USER_LIST_SUCCESS,
  data,
});

export const getAssignUserListFailure = (error) => ({
  type: credentialType.GET_ASSIGN_USER_LIST_FAILURE,
  error,
});

export const getUserCredentialList = (data) => ({
  type: credentialType.GET_USER_CREDENTIAL_LIST_PENDING,
  data,
});

export const getUserCredentialListSuccess = (data) => ({
  type: credentialType.GET_USER_CREDENTIAL_LIST_SUCCESS,
  data,
});

export const getUserCredentialListEmailSuccess = () => ({
  type: credentialType.GET_USER_CREDENTIAL_LIST_EMAIL_SUCCESS,
});

export const getUserCredentialListFailure = (error) => ({
  type: credentialType.GET_USER_CREDENTIAL_LIST_FAILURE,
  error,
});

export const createUserCredential = (data) => ({
  type: credentialType.POST_USER_CREDENTIAL_PENDING,
  data,
});

export const createUserCredentialSuccess = (data) => ({
  type: credentialType.POST_USER_CREDENTIAL_SUCCESS,
  data,
});

export const createUserCredentialFailure = (error) => ({
  type: credentialType.POST_USER_CREDENTIAL_FAILURE,
  error,
});

export const getSearchCredentialList = (data) => ({
  type: credentialType.GET_SEARCH_CREDENTIAL_PENDING,
  data,
});

export const getSearchCredentialSuccess = (data) => ({
  type: credentialType.GET_SEARCH_CREDENTIAL_SUCCESS,
  data,
});

export const getSearchCredentialFailure = (error) => ({
  type: credentialType.GET_SEARCH_CREDENTIAL_FAILURE,
  error,
});

export const assignCredential = (data) => ({
  type: credentialType.POST_ASSIGN_CREDENTIAL_PENDING,
  data,
});

export const assignCredentialSuccess = (data) => ({
  type: credentialType.POST_ASSIGN_CREDENTIAL_SUCCESS,
  data,
});

export const assignCredentialFailure = (error) => ({
  type: credentialType.POST_ASSIGN_CREDENTIAL_FAILURE,
  error,
});

export const getCredentialListByUsers = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_USERS_PENDING,
  data,
});

export const getCredentialListByUsersSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_USERS_SUCCESS,
  data,
});

export const getCredentialListByUsersEmailSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_USERS_EMAIL_SUCCESS,
  data,
});

export const getCredentialListByUsersFailure = (error) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_USERS_FAILURE,
  error,
});

export const getCredentialListByCredentials = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_PENDING,
  data,
});

export const getCredentialListByCredentialsSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_SUCCESS,
  data,
});

export const getCredentialListByCredentialsEmailSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_EMAIL_SUCCESS,
  data,
});

export const getCredentialListByCredentialsFailure = (error) => ({
  type: credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_FAILURE,
  error,
});

export const getCredentialViewDetsils = (credentialId, userId) => ({
  type: credentialType.GET_CREDENTIAL_VIEW_DETAILS_PENDING,
  credentialId,
  userId,
});

export const getCredentialViewDetsilsSuccess = (data) => ({
  type: credentialType.GET_CREDENTIAL_VIEW_DETAILS_SUCCESS,
  data,
});

export const getCredentialViewDetailsFailure = (error) => ({
  type: credentialType.GET_CREDENTIAL_VIEW_DETAILS_FAILURE,
  error,
});

export const updateUserCredential = (data) => ({
  type: credentialType.PUT_CREDENTIAL_USER_PENDING,
  data,
});

export const updateUserCredentialSuccess = (data) => ({
  type: credentialType.PUT_CREDENTIAL_USER_SUCCESS,
  data,
});

export const updateUserCredentialFailure = (error) => ({
  type: credentialType.PUT_CREDENTIAL_USER_FAILURE,
  error,
});

export const clearCredential = () => ({
  type: credentialType.CLEAR_CREDENTIAL,
  data: "",
});

export const createAdminCredential = (data) => ({
  type: credentialType.POST_ADMIN_CREDENTIAL_PENDING,
  data,
});

export const createAdminCredentialSuccess = (data) => ({
  type: credentialType.POST_ADMIN_CREDENTIAL_SUCCESS,
  data,
});

export const createAdminCredentialFailure = (error) => ({
  type: credentialType.POST_ADMIN_CREDENTIAL_FAILURE,
  error,
});

export const deleteCredential = (credentialId) => ({
  type: credentialType.DELETE_CREDENTIAL,
  credentialId,
});

export const deleteCredentialSuccess = (data) => ({
  type: credentialType.DELETE_CREDENTIAL_SUCCESS,
  data,
});

export const deleteCredentialFailure = (error) => ({
  type: credentialType.DELETE_CREDENTIAL_FAILURE,
  error,
});
