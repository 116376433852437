import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ADMIN_CONTRACTOR_CONFIGURATION_LIST,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import { useDispatch, useSelector } from "react-redux";
import ContractorConfigurationEdit from "../component/contractor/contractorConfigurationEdit";
import { checkPrivileges } from "../../../utils/rolesHelper";
const ContractorConfigurationEditContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editMode = location?.state?.editMode;
  const onboardUserId = location?.state?.onboardUserId;
  const updateContractorOnboardDatas = useSelector(
    (state) => state.updateContractorOnboardData
  );
  const getContractorConfigurationDetsilsData = useSelector(
    (state) => state.getContractorConfigurationDetsilsData
  );
  const stateData = useSelector((state) => state.state.data);
  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const contractorGrades = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorGrades
  );
  const contractorTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorTypes
  );
  const showLoader = useSelector(
    (state) =>
      state.state.isLoading ||
      state.getDropdownDataKeyValue.isLoading ||
      state.getContractorConfigurationDetsilsData.isLoading ||
      state.updateContractorOnboardData.isLoading
  );
  const userData = useSelector((state) => state.getUserListDetsilsData);
  const getDropdownDataKeyValueData = { orgId: 0, flags: "3,6,2"};
  const [successScreens, setSuccessScreens] = useState(false);
  const [setsubmit, setSetsubmit] = useState(false);
  const updateContractorOnboard = (data) => {
    setSetsubmit(true);
    dispatch(contractorAction.updateContractorOnboard(data));
    setSuccessScreens(true);
  };
  const getContractorConfigurationDetsils = () => {
    dispatch(contractorAction.getContractorConfigurationDetsils(onboardUserId));
  };

  useEffect(() => {
    dispatch(contractorAction.clearContractor());
    setSuccessScreens(false);
    dispatch(stateCityAction.getState());
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
    if (onboardUserId) {
      getContractorConfigurationDetsils();
    }
  }, []);

  const SuccessScree = (observationid, message1) => {
    return (
      <SuccessScreen
        isUpdate={
          editMode
            ? "Contractor Updated Successfully"
            : "Contractor Created Successfully"
        }
        route={ADMIN_CONTRACTOR_CONFIGURATION_LIST}
        label1="Contractor ID"
        label4="Contractor in "
        id={observationid}
        label2={message1}
      ></SuccessScreen>
    );
  };

  return (
    <>
      {checkPrivileges([12, 43, 44, 45]) ? (
        <>
          {updateContractorOnboardDatas.status === "SUCCESS" &&
          updateContractorOnboardDatas &&
          successScreens ? (
            SuccessScree(
              updateContractorOnboardDatas?.data?.id,
              "has been Updated"
            )
          ) : (
            <>
              {showLoader && <Loader />}
              <ToastContainer />
              <ContractorConfigurationEdit
                addContractor={updateContractorOnboard}
                divisions={division ?? []}
                contractorGrades={contractorGrades ?? []}
                contractorTypes={contractorTypes ?? []}
                stateData={stateData?.getState ?? []}
                userData={userData?.data}
                editMode={editMode}
                contractorId={onboardUserId}
                viewContractors={
                  editMode ? getContractorConfigurationDetsilsData : ""
                }
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ContractorConfigurationEditContainer;
