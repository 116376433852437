import * as sequenceLandingAction from "../actions/sequenceLandingAction";
import { sequenceLandingType } from "../constants/sequenceLandingType";
import {
  getSequenceLandingProjectPermitApi,
  getSequenceLandingPermitDetailApi,
  getSequenceLandingInspectionsdata,
  getSequenceLandingResourceList,
  getSequenceLandingInspectionsDetailsdata,
  getSequenceLandingInspectionsFieldTypedata,
  getSequenceLandingSamplingLogs
} from "../../services/sequenceLandingService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* qrProjectPermitListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getSequenceLandingProjectPermitApi,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = sequenceLandingType.PROJECT_PERMIT_PAGE;
    yield put(
      sequenceLandingAction.getSequenceLandingProjectPermitSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Project Permit List Failed" };
    yield put(
      sequenceLandingAction.getSequenceLandingProjectPermitFailure(data)
    );
  }
}
export function* qrPermitDetailSaga(params) {
  console.debug("resourceDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getSequenceLandingPermitDetailApi,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = sequenceLandingType.PROJECT_PERMIT_PAGE;
    yield put(
      sequenceLandingAction.getSequenceLandingProjectPermitDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Resource details Failed" };
    yield put(
      sequenceLandingAction.getSequenceLandingProjectPermitDetailsFailure(data)
    );
  }
}

// inspection
export function* getQrInspectionReportSaga(params) {
  console.debug("getQrInspectionReportSaga");
  try {
    const { data, status, error } = yield call(
      getSequenceLandingInspectionsdata,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = sequenceLandingType.QR_INSPECTION_REPORT_PAGE;
    yield put(
      sequenceLandingAction.getSequenceLandingInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Report  Failed" };
    yield put(sequenceLandingAction.getSequenceLandingInspectionFailure(data));
  }
}

export function* getQrInspectionReporFieldTypeSaga(params) {
  console.debug("getQrInspectionReporFieldTypeSaga");
  try {
    const { data, status, error } = yield call(
      getSequenceLandingInspectionsFieldTypedata,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = sequenceLandingType.QR_INSPECTION_REPORT_PAGE;
    yield put(
      sequenceLandingAction.getSequenceLandingInspectionFieldTypeSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || " Get Inspection Report Type Detail  Failed" };
    yield put(
      sequenceLandingAction.getSequenceLandingInspectionFieldTypeFailure(data)
    );
  }
}

export function* getQrInspectionReportDetailsSaga(params) {
  console.debug("getQrInspectionReportDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getSequenceLandingInspectionsDetailsdata,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = sequenceLandingType.QR_INSPECTION_REPORT_PAGE;
    let counter = 1;
    const result = data?.categoriesView?.map((x) => {
        x.subcategoriesView.forEach((y) => {
          y.insQuesAnsReportView.map((z) => {
            z.quesNo = counter;
            counter++;
            return z;
          });
          return y;
        });
        return x;
      });
    yield put(
      sequenceLandingAction.getSequenceLandingInspectionDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Report Detail  Failed" };
    yield put(
      sequenceLandingAction.getSequenceLandingInspectionDetailsFailure(data)
    );
  }
}

// Resource

export function* getQrResourceReportSaga(params) {
  console.debug("getQrResourceReportSaga");
  try {
    const { data, status, error } = yield call(
      getSequenceLandingResourceList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = sequenceLandingType.QR_RESOURCE_REPORT_PAGE;
    yield put(
      sequenceLandingAction.getSequenceLandingResourceSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Resource List  Failed" };
    yield put(sequenceLandingAction.getSequenceLandingResourceFailure(data));
  }
}

export function* qrSamplingLogsSaga(params) {
    try {
        const { data, status, error } = yield call(
            getSequenceLandingSamplingLogs,
            params
        );
        if (!data && status !== 200) throw apiError(error);
        const page = sequenceLandingType.PROJECT_PERMIT_PAGE;
        yield put(
            sequenceLandingAction.getSequenceLandingSamplingLogsSuccess({
                page,
                data,
            })
        );
    } catch (error) {
        const data =
            error && error.message
                ? error
                : { message: error || "Get Sampling Logs Failed" };
        yield put(
            sequenceLandingAction.getSequenceLandingSamplingLogsFailure(data)
        );
    }
}

const sequenceLandingSaga = [
  takeLatest(
    sequenceLandingType.GET_QR_PROJECT_PERMIT_PENDING,
    qrProjectPermitListSaga
  ),
  takeLatest(
    sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_PENDING,
    qrPermitDetailSaga
  ),
  takeLatest(
    sequenceLandingType.GET_QR_INSPECTION_REPORT_PENDING,
    getQrInspectionReportSaga
  ),
  takeLatest(
    sequenceLandingType.GET_QR_RESOURCE_PENDING,
    getQrResourceReportSaga
  ),
  takeLatest(
    sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_PENDING,
    getQrInspectionReportDetailsSaga
  ),
  takeLatest(
    sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_PENDING,
    getQrInspectionReporFieldTypeSaga
  ),
    takeLatest(
        sequenceLandingType.GET_QR_PROJECT_PERMIT_PENDING,
        qrProjectPermitListSaga
    ),
    takeLatest(
        sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_PENDING,
        qrPermitDetailSaga
    ),
    takeLatest(
        sequenceLandingType.GET_QR_SAMPLING_LOG_PENDING,
        qrSamplingLogsSaga
    ),
];

export default sequenceLandingSaga;
