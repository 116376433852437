import React, { useEffect, useState } from "react";
import DrillDown from "../component/drillDown";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as lmsReportAction from "../../../../redux/actions/lmsReportAction";
import { getFormatedDate } from "../../../../utils/helper";
import { useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import * as toolboxAction from "../../../../redux/actions/toolBoxAction";
const DrillDownContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const page = location?.state?.page;
  const apiData = location?.state?.apiData;
  const [selectedCard, setselectedCard] = useState(apiData?.card);

  useEffect(() => {
    dataClear()
  }, [])
  
  const getLmsDashboardReportDetails = useSelector(
    (state) => state.getLmsDashboardReportDetails
  );

  const getLMSDashboardReport = useSelector(
    (state) => state.getLmsDashboardReport
  );
  function getMoretDetails(data) {
    dispatch(lmsReportAction.getLmsDashboardReportDetails(data));
  }

  const getLmsDashboardReportData = (data) => {
    dispatch(lmsReportAction.getLmsDashboardReport(data));
    dispatch(lmsReportAction.clearDashboardDetails(data));
  };

  useEffect(() => {
    dispatch(lmsAction.getAssignedListClear());
    dispatch(lmsAction.getAssignedLessonListClear());
    dispatch(toolboxAction.scheduleUserCountStatusClear());
  }, []);

  useEffect(() => {
    if (page === "completedTraining") {
      const data = {
        startDate: getFormatedDate(apiData.startDate),
        endDate: getFormatedDate(apiData.endDate),
        divisionId:
          apiData.divisionId === "AllDivision" ? 0 : apiData.divisionId,
      };
      getLmsDashboardReportData(data);
    }
  }, []);
  useEffect(() => {
    const reportDetails =
      apiData.card === "lesson"
        ? 2
        : apiData.card === "course"
        ? 3
        : apiData.card === "safety"
        ? 4
        : apiData.card === "hazard"
        ? 5
        : apiData.reportDetails;
    getMoretDetails({
      startDate: getFormatedDate(apiData.startDate),
      endDate: getFormatedDate(apiData.endDate),
      divisionId: apiData.divisionId === "AllDivision" ? 0 : apiData.divisionId,
      reportDetails: reportDetails,
      page: 1,
      pageSize: 100,
    });
  }, []);
  function dataClear() {
    dispatch(lmsReportAction.clearDashboardDetails());
  }
  return (
    <div>
      {getLmsDashboardReportDetails.isLoading && <Loader />}
      <DrillDown
        page={page}
        getLmsDashboardReportDetails={getLmsDashboardReportDetails}
        getMoretDetails={getMoretDetails}
        apiData={apiData}
        getLMSDashboardReport={getLMSDashboardReport}
        selectedCard={selectedCard}
        setselectedCard={setselectedCard}
        dataClear={dataClear}
      />
    </div>
  );
};

export default DrillDownContainer;
