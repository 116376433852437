import * as projectAction from "../actions/projectAction";
import { getProjectDetailsType, projectType } from "../constants/projectType";
import {
  getProjectListService,
  postAddProjectService,
  postUpdateProjectService,
  deleteProjectService,
  getProjectDetails,
  getProcreProjectsListService,
  getSyncProcreProjectsListService,
} from "../../services/projectService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* projectListSaga(params) {
  console.debug("projectListSaga");
  try {
    const { data, status, error } = yield call(getProjectListService, params);
    if (!data && status !== 200) throw apiError(error);
    const page = projectType.GET_PROJECT_LIST_SUCCESS;
    if (params.data.isSendMail) {
      yield put(projectAction.getProjectListEmailSuccess());
    } else {
      yield put(
        projectAction.getProjectListSuccess({
          page,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get project List Failed" };
    yield put(projectAction.getProjectListFailure(data));
  }
}

export function* createProjectSaga(params) {
  console.debug("createProjectSaga");
  try {
    const { data, status, error } = yield call(postAddProjectService, params);
    if (!data && status !== 200) throw apiError(error);
    const page = projectType.POST_CREATE_PROJECT_SUCCESS;
    yield put(
      projectAction.postcreateProjectSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Project Failed" };
    yield put(projectAction.postcreatesProjectFailure(data));
  }
}

export function* projectDeleteSaga(params) {
  try {
    const { data, status, error } = yield call(deleteProjectService, params);
    if (!data && status !== 200) throw apiError(error);
    const page = projectType.DELETE_PROJECT_SUCCESS;
    yield put(
      projectAction.deleteProjectSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "delete project List Failed" };
    yield put(projectAction.deleteProjectFailure(data));
  }
}

export function* getProjectDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getProjectDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = getProjectDetailsType.PROJECT_PAGE;
    yield put(
      projectAction.getProjectDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Project Details Failed" };
    yield put(projectAction.getProjectDetailsFailure(data));
  }
}

export function* projectUpdateSaga(params) {
  console.debug("projectUpdateSaga");
  try {
    const { data, status, error } = yield call(
      postUpdateProjectService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = projectType.DELETE_PROJECT_SUCCESS;
    yield put(
      projectAction.postProjectUpdateSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update project page Failed" };
    yield put(projectAction.postProjectUpdateFailure(data));
  }
}

export function* getProcoreProjectsListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getProcreProjectsListService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.getProcoreProjectsListSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Procre project List Data Failed" };
    yield put(projectAction.getProcoreProjectsListFailure(data));
  }
}

export function* getSyncProcoreProjectsListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getSyncProcreProjectsListService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.getSyncProcoreProjectsListSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Sync Procre project List Data Failed" };
    yield put(projectAction.getSyncProcoreProjectsListFailure(data));
  }
}

const myProjectSaga = [
  takeLatest(projectType.POST_CREATE_PROJECT, createProjectSaga),
  takeLatest(projectType.GET_PROJECT_LIST, projectListSaga),
  takeLatest(projectType.DELETE_PROJECT, projectDeleteSaga),
  takeLatest(getProjectDetailsType.GET_PROJECT_DETAILS, getProjectDetailsSaga),
  takeLatest(projectType.POST_PROJECT_UPDATE, projectUpdateSaga),
  takeLatest(projectType.GET_PROCORE_PROJECT_LIST, getProcoreProjectsListSaga),
  takeLatest(
    projectType.GET_SYNC_PROCORE_PROJECT_LIST,
    getSyncProcoreProjectsListSaga
  ),
];

export default myProjectSaga;
