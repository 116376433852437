import React, { useEffect } from "react";
import ToolBoxTalkUserList from "../component/toolBoxTalkList/toolBoxTalkUserList";
import { useDispatch, useSelector } from "react-redux";
import { ORG_ID, USER_ID } from "../../../constant/constant";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";

const ToolboxTalkUserContainer = () => {
  const dispatch = useDispatch();
  const PAGE_SIZE = 6;

  const mySafetyMeetingListResult = useSelector(
    (state) => state.mySafetyMeetingList?.data
  );

  const dropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );

  const getAdminToolBoxTalkListData = useSelector(
    (state) => state.getAdminToolBoxTalkList
  );

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: parseInt(USER_ID()),
    tabId: 2,
  };

  useEffect(() => {
    getMySafetyMeetingListData(defaultData);
    dispatch(toolboxAction.getScheduleSafetyMeetingDetailsClear(""));
  }, []);
  const getMySafetyMeetingListData = (data) => {
    dispatch(toolboxAction.clearUserDetailTalk(data));
    dispatch(toolboxAction.getMySafetyMeetingList(data));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [20],
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const getAdminToolBoxTalkList = (data) => {
    dispatch(toolboxAction.getAdminToolBoxTalkList(data));
  };
  return (
    <>
      <ToolBoxTalkUserList
        categoryListData={dropdownDataKeyValue}
        getToolBoxTalkListResult={mySafetyMeetingListResult}
        getToolBoxTalkListData={getMySafetyMeetingListData}
        getToolBoxTalkListAdmin={getAdminToolBoxTalkList} // function
        getAdminToolBoxTalkListDataLoading={
          getAdminToolBoxTalkListData?.isLoading
        } // data
        getAdminToolBoxTalkListData={getAdminToolBoxTalkListData?.data}
      />
    </>
  );
};

export default ToolboxTalkUserContainer;
