import { getApiUrl } from "../config";
import { get, post, deletes } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const getEquipmentList = async ({ data }) => {
  const {
    page,
    search,
    divisionId = 0,
    statusIds,
    organisationId,
    userId,
    pageSize = 10,
    category = 0,
    isSendMail,
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Equipment/GetEquipmentList?organisationId=${organisationId}&isSendMail=${isSendMail}&statusIds=${statusIds}&divisionId=${divisionId}&page=${page}${
    userId || userId === 0 ? `&userId=${userId}` : ""
  }&pageSize=${pageSize}&search=${search}&category=${category} `;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: organisationId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postCreateEquipmentService = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Equipment/AddEquipment`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteEquipmentService = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Equipment/DeleteEquipment/${
    payload ? payload : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getEquipmentFormDetails = async (equipmentId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Equipment/GetEquipmentDetails?equipmentId=${equipmentId}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateEquipment = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Equipment/UpdateEquipment`;
  try {
    return await post(url, data.data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
