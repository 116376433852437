import React from "react";
import ReprimandList from "../component/reprimand/reprimandList";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const ReprimandListContiner = () => {
  const navigate = useNavigate();
  return (
    <>
      {checkPrivileges([12, 81, 82, 83]) ? (
        <ReprimandList filterShow={true} />
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ReprimandListContiner;
