import React, { useEffect, useState } from "react";
import AddCredentials from "../component/addCredentials";
import { useDispatch, useSelector } from "react-redux";
import * as credentialAction from "../../../redux/actions/credentialAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  SETUP_CREDENTIAL,
  DASHBOARD,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { ORG_ID } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function AddCredentialsContainer() {
  const [successScreens, setSuccessScreens] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const credentialNameId = location?.state?.credentialNameId;
  const isAllowed = checkPrivileges([12, 13]);
  const dispatch = useDispatch();

  const saveCredential = (data) => {
    setSuccessScreens(true);
    if (editMode) {
      dispatch(credentialAction.updateCredential(data));
    } else {
      dispatch(credentialAction.createCredential(data));
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.createCredentialData.isLoading ||
      state.getDropdownDataKeyValue.isLoading ||
      state.updateCredentialData.isLoading
  );
  const updateCredentialData = useSelector(
    (state) => state.updateCredentialData
  );

  const createCredentialData = useSelector(
    (state) => state.createCredentialData
  );
  const issuingBody = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.issuingBodies
  );
  const credentialTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.credentialTypes
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [7, 8],
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    setSuccessScreens(false);
  }, []);
  const successScreen = (id, message) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={message}
        route={SETUP_CREDENTIAL}
        label1=" "
        label4=" "
        label2=" "
      ></SuccessScreen>
    );
  };

  const getCredentials = (data) => {
    dispatch(credentialAction.getCredentialList(data));
  };
  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    dispatch(credentialAction.clearCredential());
    getCredentials();
  }, []);

  const getCredentialDetsils = () => {
    dispatch(credentialAction.getCredentialDetsils(credentialNameId));
  };

  const getCredentialDetsilsData = useSelector(
    (state) => state.getCredentialDetsilsData
  );
  useEffect(() => {
    if (editMode && credentialNameId) {
      getCredentialDetsils();
    }
  }, []);

  return (
    <>
      {checkPrivileges([12, 13]) ? (
        <>
          {showLoader && <Loader />}
          {createCredentialData.status === "SUCCESS" &&
          createCredentialData.data &&
          successScreens ? (
            successScreen(
              createCredentialData?.data?.id,
              "Credential Created Successfully"
            )
          ) : updateCredentialData.status === "SUCCESS" &&
            updateCredentialData?.data &&
            successScreens ? (
            successScreen(
              updateCredentialData?.data?.id,
              "Credential Updated Successfully"
            )
          ) : (
            <AddCredentials
              saveCredentials={saveCredential}
              credentialTypes={credentialTypes}
              issuingBody={issuingBody}
              editMode={editMode}
              getCredentialDetsilsData={getCredentialDetsilsData}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
