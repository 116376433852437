import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid } from "@mui/material";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import Table from "../../../../library/custom/table/table";
import {
  associatedIncidentType1,
  PAGE_SIZE_100,
} from "../../../../constant/constant";
import {
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
  VIEW_INCIDENT_REPORT,
} from "../../../../constant/routeContant";
import {
  downloadExcel,
  downloadPDF,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import * as incidentAction from "../../../../redux/actions/incidentAction";

const TotalIncidents = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(1);
  const [isreset, setIsreset] = useState(false);
  const [incvalue, setIncvalue] = useState(
    props?.drillDetail?.apiData?.incidentType > 0
      ? props?.drillDetail?.apiData?.incidentType
      : -1
  );
  const pageSize = PAGE_SIZE_100;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  const getIncidentListData = (data) => {
    dispatch(incidentAction.getIncidentList(data));
  };

  useEffect(() => {
    if (props?.incidentList && !isreset) {
      setData((prevData) => [...prevData, ...props?.incidentList]);
    } else {
      setData(props?.incidentList);
      setIsreset(false);
    }
  }, [props?.incidentList, isreset]);

  const fetchMoreData = async (pageNumber) => {
    const { startDate, endDate, incidentType, divisionId, locationId } =
      props.drillDetail?.apiData;
    const data = {
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE_100,
      incidentType: incvalue > 0 ? incvalue : 0,
      startDate: startDate,
      endDate: endDate,
      divisionId: divisionId,
      locationId: locationId,
    };
    try {
      const response = await props?.getIncidentListData(data);
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    setPage(pageNumber);
    const moreData = await fetchMoreData(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    console.log("sortedData", sortedData);
    setData(sortedData);
  };
  const columns = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "caseId",
      label: `Case ID`,
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "dateOfEntry",
      label: `Date of Entry`,
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },
    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "enteredBy",
      label: `Entered by`,
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },

    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },
    { id: "blank2", label: "", className: "inc_blank" },
  ];
  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
        apiData: props?.drillDetail,
        isIncident: true, // TODO
        page: "report_totalIncidents", // TODO
        reportDetails: 0, // TODO
        incidentURL: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
        tab: props?.tab,
        backRouteText: "Back to Total Incidents",
      },
    });
  }
  const handleViewOnClick = (rows) => {
    if (
      rows?.incidentId === 2 ||
      rows?.incidentId === 1 ||
      rows?.incidentId === 3 ||
      rows?.incidentId === 4 ||
      rows?.incidentId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incidentId
      );
    }
  };

  const rows = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td className="inc_row_reportId">{asset?.reportId}</td>
      <td className="inc_row_caseid">{asset?.caseId}</td>
      <td className="inc_row_dateOfEntry">{asset?.dateOfEntry}</td>
      <td>{asset?.incidentType}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredBy}</td>
      <td>{asset?.status}</td>
      <td className="inc_row_blank">
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident"}
        />
      </td>
    </tr>
  ));
  const handleIncidentTypeChange = (e) => {
    setData([]);

    setIncvalue(e?.target?.value);
    setPage(1);
    const data = {
      pageNumber: 1,
      incidentType: e?.target?.value > 0 ? e?.target?.value : 0,
      pageSize: pageSize,
      startDate: props?.drillDetail?.apiData?.startDate,
      endDate: props?.drillDetail?.apiData?.endDate,
      divisionId: props?.drillDetail?.apiData?.divisionId,
      locationId: props?.drillDetail?.apiData?.locationId,
    };
    getIncidentListData(data);
  };

  const rowsPdf = data.map((asset) => [
    asset?.reportId,
    asset?.caseId,
    asset?.dateOfEntry,
    asset?.incidentType,
    asset?.assetName,
    asset?.enteredBy,
    asset?.status,
  ]);
  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">{"Total Incidents"}</div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columns.map((col) => col.label),
                        "Incidents.pdf"
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.5 8.5H6.5V7H7C7.28333 7 7.52083 6.90417 7.7125 6.7125C7.90417 6.52083 8 6.28333 8 6V5.5C8 5.21667 7.90417 4.97917 7.7125 4.7875C7.52083 4.59583 7.28333 4.5 7 4.5H5.5V8.5ZM6.5 6V5.5H7V6H6.5ZM8.5 8.5H10C10.2833 8.5 10.5208 8.40417 10.7125 8.2125C10.9042 8.02083 11 7.78333 11 7.5V5.5C11 5.21667 10.9042 4.97917 10.7125 4.7875C10.5208 4.59583 10.2833 4.5 10 4.5H8.5V8.5ZM9.5 7.5V5.5H10V7.5H9.5ZM11.5 8.5H12.5V7H13.5V6H12.5V5.5H13.5V4.5H11.5V8.5ZM4.5 13C4.0875 13 3.73438 12.8531 3.44062 12.5594C3.14687 12.2656 3 11.9125 3 11.5V1.5C3 1.0875 3.14687 0.734376 3.44062 0.440626C3.73438 0.146876 4.0875 0 4.5 0H14.5C14.9125 0 15.2656 0.146876 15.5594 0.440626C15.8531 0.734376 16 1.0875 16 1.5V11.5C16 11.9125 15.8531 12.2656 15.5594 12.5594C15.2656 12.8531 14.9125 13 14.5 13H4.5ZM1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3H1.5V14.5H13V16H1.5Z"
                        fill="none"
                      />
                    </svg>
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() => downloadExcel(data, "Incidents.xlsx")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6635_4490)">
                        <path
                          d="M9.22 0.0100392L0.26 1.71004C0.10875 1.73879 0 1.87629 0 2.03004V13.97C0 14.1238 0.10875 14.2613 0.26 14.29L9.22 15.99C9.24 15.9938 9.26 16 9.28 16C9.35375 16 9.4225 15.9775 9.48 15.93C9.55375 15.8688 9.6 15.775 9.6 15.68V0.320039C9.6 0.225039 9.55375 0.131289 9.48 0.0700392C9.40625 0.0087892 9.31375 -0.00746081 9.22 0.0100392ZM10.24 1.92004V4.16004H10.88V4.80004H10.24V6.40004H10.88V7.04004H10.24V8.64004H10.88V9.28004H10.24V11.2H10.88V11.84H10.24V14.08H15.04C15.3925 14.08 15.68 13.7925 15.68 13.44V2.56004C15.68 2.20754 15.3925 1.92004 15.04 1.92004H10.24ZM11.52 4.16004H14.08V4.80004H11.52V4.16004ZM2.14 5.02004H3.78L4.64 6.81004C4.7075 6.95129 4.7675 7.12504 4.82 7.32004H4.83C4.86375 7.20379 4.92875 7.02129 5.02 6.79004L5.97 5.02004H7.47L5.68 7.98004L7.52 11H5.93L4.89 9.05004C4.85125 8.97754 4.81125 8.84379 4.77 8.65004H4.76C4.74 8.74129 4.6925 8.88379 4.62 9.07004L3.58 11H1.98L3.89 8.01004L2.14 5.02004ZM11.52 6.40004H14.08V7.04004H11.52V6.40004ZM11.52 8.64004H14.08V9.28004H11.52V8.64004ZM11.52 11.2H14.08V11.84H11.52V11.2Z"
                          fill="none"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6635_4490">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <Grid container mb={2}>
        <Grid md={4}>
          <FormControl fullWidth>
            <FormLabels label="Incident Type" isRequired={true} />
            <SelectMenu
              listData={associatedIncidentType1}
              value={incvalue}
              onchangehandler={(e) => handleIncidentTypeChange(e)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        {" "}
        <Grid md={12} xs={12}>
          <div className="customTableWrapper">
            <Table
              columns={columns}
              rows={rows}
              tableRef={tableRef}
              onSort={handleSort}
              className="totalinc_cls"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalIncidents;
