import { divisionsType } from "../constants/divisionsType";

export const getDivisionsList = (data) => ({
  type: divisionsType.GET_DIVISION_LIST_PENDING,
  data,
});

export const getDivisionsListSuccess = (data) => ({
  type: divisionsType.GET_DIVISION_LIST_SUCCESS,
  data,
});

export const getDivisionsListEmailSuccess = () => ({
  type: divisionsType.GET_DIVISION_LIST_EMAIL_SUCCESS,
});

export const getDivisionsListFailure = (error) => ({
  type: divisionsType.GET_DIVISION_LIST_FAILURE,
  error,
});

export const createDivisions = (data) => ({
  type: divisionsType.POST_DIVISION_PENDING,
  data,
});

export const createDivisionsSuccess = (data) => ({
  type: divisionsType.POST_DIVISION_SUCCESS,
  data,
});

export const createDivisionsFailure = (error) => ({
  type: divisionsType.POST_DIVISION_FAILURE,
  error,
});

export const updateDivisions = (payload) => ({
  type: divisionsType.UPDATE_DIVISION_PENDING,
  payload: payload,
});

export const updateDivisionsSuccess = (data) => ({
  type: divisionsType.UPDATE_DIVISION_SUCCESS,
  data,
});

export const updateDivisionsFailure = (error) => ({
  type: divisionsType.UPDATE_DIVISION_FAILED,
  error,
});

export const clearDivisions = () => ({
  type: divisionsType.CLEAR_DIVISION,
  data: "",
});

export const editDivisions = (data) => ({
  type: divisionsType.EDIT_DIVISION,
  data,
});

export const getDivisionsDetails = (divisionId) => ({
  type: divisionsType.GET_DIVISION_DETAILS_PENDING,
  divisionId,
});

export const getDivisionsDetailsSuccess = (data) => ({
  type: divisionsType.GET_DIVISION_DETAILS_SUCCESS,
  data,
});

export const getDivisionsDetailsFailure = (error) => ({
  type: divisionsType.GET_DIVISION_DETAILS_FAILED,
  error,
});

export const deleteDivisions = (payload) => ({
  type: divisionsType.DELETE_DIVISION_PENDING,
  payload: payload,
});

export const deleteDivisionsSuccess = (payload) => ({
  type: divisionsType.DELETE_DIVISION_SUCCESS,
  payload: payload,
});

export const deleteDivisionsFailure = (payload) => ({
  type: divisionsType.DELETE_DIVISION_FAILED,
  payload: payload,
});
