import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import commonImages from "../../../../assets";
import Typography from "@mui/material/Typography";
import "./toolBoxTalkDetail.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  TOOL_BOX_CREATE,
  TOOL_BOX_ASSIGN_USER,
} from "../../../../constant/routeContant";
import Popover from "@mui/material/Popover";
import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import DialogBox from "../../../../library/common/DialogBox";
import { downloadFullPDF, getFormatedDate } from "../../../../utils/helper";
import { ASSET_TYPE_JHA, USER_TYPE } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import AttendeeList from "../../../jha/component/attendeeList";
import ViewAttendesContainer from "../../container/viewAttendesContainer";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const ToolBoxTalkDetail = (props) => {
  const ToolBoxDetail = props?.getToolBoxTalkDetail;

  const Tabvalue = props?.Tabvalue;
  const dataBack = {
    title: "Back to Safety Meeting",
    route: -1,
    backTabId: Tabvalue,
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isClient = userInfo?.isClient;
  const isContractor = userInfo?.isContractor;
  const contractorProfileShow = isContractor === true && isClient === false;
  const toolboxId = props?.toolboxId;
  const instructorUserContractors = props?.instructorUserContractors;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const showAssign = props?.showAssign;
  const OnClickAssignAttendee = props?.OnClickAssignAttendee;
  const userType = props?.userType;
  const handleUserType = props?.handleUserType;
  const showVerify = props?.showVerify;
  const OnClickVerifyAll = props?.OnClickVerifyAll;
  const photos = props?.photos;
  const handleFileChange = props?.handleFileChange;
  const handleDeleteImageURL = props?.handleDeleteImageURL;
  const addUserData = props?.addUserData;

  const assetTypeError = props?.assetTypeError;
  const onChangeAssetType = props?.onChangeAssetType;
  const assetType = props?.assetType;
  const searchAsset = props?.searchAsset;
  const assetOption = props?.assetOption;
  const isLoading = props?.isLoading;
  const projectNameValue = props?.projectNameValue;
  const onSelectSearchProject = props?.onSelectSearchProject;
  const customAssetValue = props?.customAssetValue;
  const onSelectSearchAsset = props?.onSelectSearchAsset;
  //const safetyDetail = props?.safetyDetail;

  const isAutoSearchDataLoading = props?.isAutoSearchDataLoading;
  const allUsers = props?.allUsers;
  const typeOnchange = props?.typeOnchange;
  const onSelectUsers = props?.onSelectUsers;
  const usersValue = props?.usersValue;

  const userList = props?.userList;
  const hiddenAttendeeSignatureInput = props?.hiddenAttendeeSignatureInput;
  const removeImage = props?.removeImage;
  const attendeesignatureUpload = props?.attendeesignatureUpload;
  const deleteData = props?.deleteData;
  const userGroups = props?.userGroups;
  const handleAllGroupSearch = props?.handleAllGroupSearch;
  const usersGroup = props?.usersGroup;
  const onChangeUsersGroup = props?.onChangeUsersGroup;
  const autoComp = props?.autoComp;
  const addGroupUserData = props?.addGroupUserData;
  const attendeeSignature = props?.attendeeSignature;
  const setImageCheck = props?.setImageCheck;
  const adduser = props?.adduser;
  const onChangeAdduser = props?.onChangeAdduser;
  const addAdditionalUser = props?.addAdditionalUser;
  const users = props?.users;
  const setUsers = props?.setUsers;
  const autoCompUser = props?.autoCompUser;
  const submitData = props?.submitData;
  const alreadyScheduleDisable = props?.alreadyScheduleDisable;
  const alreadyScheduleAssetName = props?.alreadyScheduleAssetName;
  const deletehandle = () => {
    setOpenModal(false);
    const data = {
      toolboxTalkId: toolboxId,
    };
    props?.deleteToolBoxTalks(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filepath = ToolBoxDetail?.file?.path;
  const edittoolbox = (toolboxids) => {
    navigate(TOOL_BOX_CREATE, {
      state: { toolboxId: toolboxids, editMode: true },
    });
  };

  return (
    <>
      <Grid container>
        <Grid item md={6} xs={12} className="hideinpdf">
          <BackBtn dataBack={dataBack} />
        </Grid>
        {props?.showDownloadBtn && (
          <Grid item md={6} xs={12} className="hideinpdf">
            <Button
              onClick={() => {
                downloadFullPDF("pdf-content", "safety-meeting.pdf", [
                  "first-page",
                ]);
              }}
              variant="contained"
              startIcon={<DownloadIcon className="downloadIcon" />}
              className="downloadpdf sw marginRemove float_right"
            >
              Download as PDF
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container id="pdf-content">
        <div id="first-page" className="w-100">
          <Grid container className="toolboxtalkView" id="pdf-content">
            <Grid item xs={12}>
              {openModal && (
                <DialogBox
                  open={openModal}
                  rows={[]}
                  header={"Confirm Deletion"}
                  deletehandle={deletehandle}
                  handleCloseModal={() => setOpenModal(false)}
                  content={"Are you sure you want to delete this ToolBox Talk?"}
                  view
                />
              )}
              <Grid className="lessonImage">
                <div className="adminDetailBox">
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className="deletePop"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography className="deleteText">
                      Delete Meeting
                    </Typography>
                  </Popover>
                </div>
              </Grid>
              <Grid container>
                <Grid md={9}>
                  <Grid className="imageDetail">
                    <Typography className="imageDetailContent">
                      <span>
                        <img
                          className="iconImg"
                          src={commonImages?.category}
                          alt="category"
                        />
                      </span>
                      {ToolBoxDetail?.categoryName}
                    </Typography>
                    {instructorUserContractors?.id ? (
                      <Typography className="imageDetailContent">
                        <span>
                          <img src={commonImages?.alarm} alt="alarm" />
                        </span>
                        {getFormatedDate(
                          instructorUserContractors?.scheduleTalkStartDate
                        )}
                      </Typography>
                    ) : (
                      ""
                    )}
                    {/* {instructorUserContractors?.id &&
                instructorUserContractors?.scheduleTalkStartDate !=
                  instructorUserContractors?.scheduleTalkEndDate ? (
                  <Typography className="imageDetailContent">
                    <span>
                      <img src={commonImages?.alarm} alt="alarm" />
                    </span>
                    {getFormatedDate(
                      instructorUserContractors?.scheduleTalkEndDate
                    )}
                  </Typography>
                ) : (
                  ""
                )} */}
                  </Grid>
                </Grid>
                {checkPrivileges([12, 90]) && !props?.safetyDetail && (
                  <Grid md={3}>
                    <Grid className="imageDetail">
                      <Typography className="imageDetailContent">
                        <div
                          className="edittoolbox"
                          onClick={() => edittoolbox(toolboxId)}
                        >
                          <span>
                            <img src={commonImages?.editicon} alt="alarm" />
                          </span>
                          Edit Content
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {props?.showDownloadBtn && (
                <Grid container className="otherDescration" alignItems={"center"} gap={1}>
                  <Grid className="subTitle">Completed By: </Grid>
                  <Grid className="desc">
                    <p>{` ${props?.completedBy}`}</p>
                  </Grid>
                </Grid>
              )}
              <Grid>
                <Grid className="title">
                  {ToolBoxDetail?.titleSafetyMeeting}
                </Grid>
                <Grid className="otherDescration">
                  <Grid className="subTitle">Description</Grid>
                  <Grid className="desc">
                    <p>{ToolBoxDetail?.descriptionSafetyMeeting}</p>
                  </Grid>
                  <Grid className="subTitle"></Grid>
                  <Grid className="desc">
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ToolBoxDetail?.textSafetyMeeting,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {ToolBoxDetail?.file?.id && (
              <Grid container className="lessonwithDetail">
                <Grid item xs={12} md={12} className="lessonwithDetailBox">
                  <Grid className="otherDescration">
                    <Grid className="subTitle"></Grid>
                    <Grid className="pdfAttached">
                      <span>
                        {" "}
                        <a href={filepath} target="_blank" rel="noreferrer">
                          <img src={commonImages?.attachment} alt="" />
                          Download PDF/Image
                        </a>{" "}
                      </span>
                    </Grid>
                  </Grid>

                  <hr className="solid" />
                </Grid>
              </Grid>
            )}

            {props?.safetyDetail && !props?.fromCompletedTab && (
              <Grid container mt={4} spacing={1} ml={0.1}>
                <Grid lg={4} xs={12} sm={4} md={4} mt={1.5}>
                  <Buttons
                    varientContained={true}
                    label={"Assign Attendees"}
                    onClick={() => OnClickAssignAttendee()}
                  />
                </Grid>
              </Grid>
            )}
            {showAssign && (
              <>
                <Grid container mt={2} spacing={1}>
                  <Grid item lg={4} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels
                        label="Select Asset Type"
                        isRequired={false}
                      />
                      <SelectMenu
                        listData={ASSET_TYPE_JHA}
                        value={assetType}
                        onchangehandler={(e) => onChangeAssetType(e)}
                        placeholder="Please Select"
                        disabled={alreadyScheduleDisable}
                      />
                    </FormControl>
                    {assetTypeError && !assetType && (
                      <div className="errorMsg">
                        Selection is required for this field
                      </div>
                    )}
                  </Grid>
                  {assetType === 1 && (
                    <Grid item lg={4} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label="Asset Name" isRequired={false} />
                        <SearchAutoComplete
                          name="Asset Name"
                          optionsData={isLoading ? [] : assetOption ?? []}
                          typeOnchange={(event) =>
                            searchAsset(event?.target?.value, 1)
                          }
                          isKeyValuePair={true}
                          value={projectNameValue}
                          onChange={(event, value) =>
                            onSelectSearchProject(event, value)
                          }
                          noOptionsTextMsg="No Project"
                          isLoading={isLoading}
                          disabled={alreadyScheduleDisable}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {assetType === 4 && (
                    <Grid item lg={4} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels
                          label="Custom Asset Name"
                          isRequired={false}
                        />
                        <SearchAutoComplete
                          name="Custom Asset Name"
                          optionsData={isLoading ? [] : assetOption ?? []}
                          typeOnchange={(event) =>
                            searchAsset(event?.target?.value, 4)
                          }
                          isKeyValuePair={true}
                          value={customAssetValue}
                          onChange={(event, value) =>
                            onSelectSearchAsset(event, value)
                          }
                          noOptionsTextMsg="No Custom Asset"
                          isLoading={isLoading}
                          disabled={alreadyScheduleAssetName}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={4}
                  mt={3}
                  ml={0.5}
                  className="selectUserStyle"
                >
                  <Grid item lg={12} xs={12} sm={12} md={4} textAlign="left">
                    <div className="headingstyle">Select User Type</div>
                  </Grid>
                </Grid>
                <Grid item container mt={0.5} spacing={2} className="jhaReport">
                  <Grid item lg={12} xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="radioGroup"
                      >
                        <RadioButton
                          checked={userType === "0"}
                          value="0"
                          label="Individual"
                          onChange={(e) => handleUserType(e.target.value)}
                        ></RadioButton>

                        <RadioButton
                          checked={userType === "1"}
                          value="1"
                          label="Group"
                          onChange={(e) => handleUserType(e.target.value)}
                        ></RadioButton>
                        <RadioButton
                          checked={userType === "3"}
                          value="3"
                          label="Sub Contractors"
                          onChange={(e) => handleUserType(e.target.value)}
                        ></RadioButton>
                        <RadioButton
                          checked={userType === "2"}
                          value="2"
                          label="Additional Attendees"
                          onChange={(e) => handleUserType(e.target.value)}
                        ></RadioButton>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {userType === "0" && (
                  <>
                    <>
                      {assetType !== 4 && (
                        <>
                          {" "}
                          <Grid container item mt={3}>
                            <Grid item lg={5} md={6} sm={9} xs={12} pl={0.5}>
                              <FormLabels label="Add Attendees" />
                              <SearchAutoComplete
                                name="Add Attendees"
                                optionsData={
                                  isAutoSearchDataLoading
                                    ? []
                                    : allUsers?.users ?? []
                                }
                                typeOnchange={(event, value) =>
                                  typeOnchange(
                                    event,
                                    value,
                                    USER_TYPE.NONPRIVILEGED
                                  )
                                }
                                isKeyValuePair={true}
                                value={usersValue}
                                onChange={(e, value) => onSelectUsers(value)}
                                isLoading={isAutoSearchDataLoading}
                                autoCompClear={autoCompUser}
                              />
                            </Grid>

                            <Grid
                              className="smallAddBtn"
                              item
                              lg={6}
                              md={9}
                              sm={12}
                              xs={12}
                              mt={3.5}
                              ml={2}
                            >
                              <Buttons
                                aria-describedby="dd"
                                variant="contained"
                                type="button"
                                name="btn"
                                label={"Add"}
                                onClick={() => addUserData()}
                                id="btnAddEquipment"
                                varientAddIconBlue={true}
                              ></Buttons>
                            </Grid>
                          </Grid>
                          <Grid container item mt={3}>
                            <Grid item lg={10} md={10} sm={10} xs={12}>
                              <AttendeeList
                                hiddenAttendeeSignatureInput={
                                  hiddenAttendeeSignatureInput
                                }
                                removeImage={removeImage}
                                attendeesignatureUpload={
                                  attendeesignatureUpload
                                }
                                userList={userList}
                                handleDelete={(e) => deleteData(e)}
                                attendeeSignature={attendeeSignature}
                                setImageCheck={setImageCheck}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>

                    {assetType == "4" && (
                      <>
                        <Grid container item mt={3}>
                          <Grid item lg={5} md={6} sm={9} xs={12} pl={0.5}>
                            <FormLabels label="Add Attendees" />
                            <SearchAutoComplete
                              name="Add Attendees"
                              optionsData={
                                isAutoSearchDataLoading
                                  ? []
                                  : allUsers?.users ?? []
                              }
                              typeOnchange={(event, value) =>
                                typeOnchange(event, value, USER_TYPE.EMPLOYEE)
                              }
                              isKeyValuePair={true}
                              value={usersValue}
                              onChange={(e, value) => setUsers(value)}
                              isLoading={isAutoSearchDataLoading}
                              autoCompClear={autoCompUser}
                              noOptionsTextMsg="No user"
                            />
                          </Grid>

                          <Grid
                            className="smallAddBtn"
                            item
                            lg={1.5}
                            md={9}
                            sm={12}
                            xs={12}
                            mt={3.5}
                            ml={2}
                          >
                            <Buttons
                              aria-describedby="dd"
                              variant="contained"
                              type="button"
                              name="btn"
                              label={"Add"}
                              onClick={() => addUserData()}
                              id="btnAddEquipment"
                              varientAddIconBlue={true}
                            ></Buttons>
                          </Grid>
                        </Grid>
                        <Grid container item mt={3}>
                          <Grid item lg={10} md={10} sm={10} xs={12}>
                            <AttendeeList
                              hiddenAttendeeSignatureInput={
                                hiddenAttendeeSignatureInput
                              }
                              removeImage={removeImage}
                              attendeesignatureUpload={attendeesignatureUpload}
                              userList={userList}
                              handleDelete={(e) => deleteData(e)}
                              attendeeSignature={attendeeSignature}
                              setImageCheck={setImageCheck}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {userType === "3" && (
                  <>
                    {/* {assetType == "1" && ( */}
                    <>
                      {assetType !== 4 && (
                        <>
                          {" "}
                          <Grid container item mt={3}>
                            <Grid item lg={5} md={6} sm={9} xs={12} pl={0.5}>
                              <FormLabels label="Add Attendees" />
                              <SearchAutoComplete
                                name="Add Attendees"
                                optionsData={
                                  isAutoSearchDataLoading
                                    ? []
                                    : allUsers?.users ?? []
                                }
                                typeOnchange={(event, value) =>
                                  typeOnchange(
                                    event,
                                    value,
                                    USER_TYPE.CONTRACTOR
                                  )
                                }
                                isKeyValuePair={true}
                                value={usersValue}
                                onChange={(e, value) => onSelectUsers(value)}
                                isLoading={isAutoSearchDataLoading}
                                autoCompClear={autoCompUser}
                              />
                            </Grid>

                            <Grid
                              className="smallAddBtn"
                              item
                              lg={6}
                              md={9}
                              sm={12}
                              xs={12}
                              mt={3.5}
                              ml={2}
                            >
                              <Buttons
                                aria-describedby="dd"
                                variant="contained"
                                type="button"
                                name="btn"
                                label={"Add"}
                                onClick={() => addUserData()}
                                id="btnAddEquipment"
                                varientAddIconBlue={true}
                              ></Buttons>
                            </Grid>
                          </Grid>
                          <Grid container item mt={3}>
                            <Grid item lg={10} md={10} sm={10} xs={12}>
                              <AttendeeList
                                hiddenAttendeeSignatureInput={
                                  hiddenAttendeeSignatureInput
                                }
                                removeImage={removeImage}
                                attendeesignatureUpload={
                                  attendeesignatureUpload
                                }
                                userList={userList}
                                handleDelete={(e) => deleteData(e)}
                                attendeeSignature={attendeeSignature}
                                setImageCheck={setImageCheck}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                    {/* )} */}
                    {assetType == "4" && (
                      <>
                        <Grid container item mt={3}>
                          <Grid item lg={5} md={6} sm={9} xs={12} pl={0.5}>
                            <FormLabels label="Add Attendees" />
                            <SearchAutoComplete
                              name="Add Attendees"
                              optionsData={
                                isAutoSearchDataLoading
                                  ? []
                                  : allUsers?.users ?? []
                              }
                              typeOnchange={(event, value) =>
                                typeOnchange(
                                  event,
                                  value,
                                  USER_TYPE.DIVISION_CONTRACTOR
                                )
                              }
                              isKeyValuePair={true}
                              value={usersValue}
                              onChange={(e, value) => setUsers(value)}
                              isLoading={isAutoSearchDataLoading}
                              autoCompClear={autoCompUser}
                              noOptionsTextMsg="No user"
                            />
                          </Grid>

                          <Grid
                            className="smallAddBtn"
                            item
                            lg={1.5}
                            md={9}
                            sm={12}
                            xs={12}
                            mt={3.5}
                            ml={2}
                          >
                            <Buttons
                              aria-describedby="dd"
                              variant="contained"
                              type="button"
                              name="btn"
                              label={"Add"}
                              onClick={() => addUserData()}
                              id="btnAddEquipment"
                              varientAddIconBlue={true}
                            ></Buttons>
                          </Grid>
                        </Grid>
                        <Grid container item mt={3}>
                          <Grid item lg={10} md={10} sm={10} xs={12}>
                            <AttendeeList
                              hiddenAttendeeSignatureInput={
                                hiddenAttendeeSignatureInput
                              }
                              removeImage={removeImage}
                              attendeesignatureUpload={attendeesignatureUpload}
                              userList={userList}
                              handleDelete={(e) => deleteData(e)}
                              attendeeSignature={attendeeSignature}
                              setImageCheck={setImageCheck}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {userType === "1" && (
                  <Grid item container mt={1} spacing={2}>
                    <Grid item lg={5} xs={12} sm={12} md={4}>
                      <FormLabels label="Add User Group" isRequired={false} />
                      <SearchAutoComplete
                        name="Add User Group"
                        optionsData={
                          isLoading ? [] : userGroups?.groupList ?? []
                        }
                        typeOnchange={(event) =>
                          handleAllGroupSearch(
                            event?.target?.value,
                            0,
                            true,
                            assetType,
                            assetType === 1 ? props?.projectName : props?.asset
                          )
                        }
                        isKeyValuePair={true}
                        value={usersGroup}
                        onChange={(e, value) => onChangeUsersGroup(value)}
                        isLoading={isLoading}
                        autoCompClear={autoComp}
                        noOptionsTextMsg="No group"
                      />
                    </Grid>
                    <Grid
                      className="smallAddBtn"
                      item
                      lg={1.5}
                      xs={12}
                      sm={12}
                      mt={4}
                      md={4}
                    >
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Add"}
                        onClick={() => addGroupUserData()}
                        id="btnAddEquipment"
                        varientAddIconBlue={true}
                      ></Buttons>
                    </Grid>
                    <Grid container item mt={3}>
                      <Grid item lg={10} md={10} sm={10} xs={12}>
                        <AttendeeList
                          hiddenAttendeeSignatureInput={
                            hiddenAttendeeSignatureInput
                          }
                          removeImage={removeImage}
                          attendeesignatureUpload={attendeesignatureUpload}
                          userList={userList}
                          handleDelete={(e) => deleteData(e)}
                          attendeeSignature={attendeeSignature}
                          setImageCheck={setImageCheck}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {userType === "2" && (
                  <Grid item container mt={1} spacing={2}>
                    <Grid item lg={5} md={8} xs={8} mb={2}>
                      <InputField
                        type="text"
                        // isRequired={true}
                        value={adduser}
                        onChange={(e) => onChangeAdduser(e)}
                        label="Additional Attendees"
                        placeholder="Enter User Name Here..."
                      />
                    </Grid>

                    <Grid
                      className="smallAddBtn"
                      item
                      lg={1.5}
                      md={3}
                      xs={3}
                      ml={3}
                      mt={4}
                    >
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Add"}
                        id="btnAddEquipment"
                        varientAddIconBlue={true}
                        onClick={addAdditionalUser}
                      ></Buttons>
                    </Grid>
                    <Grid container item mt={3}>
                      <Grid item lg={10} md={10} sm={10} xs={12}>
                        <AttendeeList
                          hiddenAttendeeSignatureInput={
                            hiddenAttendeeSignatureInput
                          }
                          removeImage={removeImage}
                          attendeesignatureUpload={attendeesignatureUpload}
                          userList={userList}
                          handleDelete={(e) => deleteData(e)}
                          attendeeSignature={attendeeSignature}
                          setImageCheck={setImageCheck}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container mt={3} spacing={1} ml={0.1}>
                  <Grid lg={3.8} xs={12} sm={4} md={4} mt={1.5}>
                    <Buttons
                      varientContained={true}
                      label={"Photo Verification"}
                      onClick={() => OnClickVerifyAll()}
                    />
                  </Grid>
                  {showVerify && (
                    <Grid item container mt={2} spacing={2}>
                      <Grid item lg={10} xs={12} sm={12} md={8}>
                        <>
                          <FormDropZone
                            paraText1={"Drop the files here ..."}
                            paraText2={"Drop Images here, "}
                            paraText3={"or Browse"}
                            //   handleFileChange={handleFileChange}
                            handleFileChange={(photo, id) =>
                              handleFileChange(photo, id)
                            }
                            deleteImageURL={(url) => handleDeleteImageURL(url)}
                            isDynamics={true}
                            isRequired={true}
                            fileType={10}
                            isMuliple={true}
                            imageUrl={photos}
                            id="addPhoto"
                            key="addPhoto"
                            isDoc={false}
                            isImage={true}
                            allFiles={false}
                          />
                          <p class="fileTypeSupport">
                            Supported file types: images
                          </p>
                        </>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {(checkPrivileges([12, 90, 91, 92]) || contractorProfileShow) &&
            props?.safetyDetail &&
            !props?.fromCompletedTab ? (
              <>
                <Grid container className="complete_btn" mt={1}>
                  <Grid className="Btncomplete">
                    <Buttons
                      varientContained={true}
                      onClick={submitData}
                      TODO
                      USER
                      COMPALTE
                      TRANNING
                      label="Complete Training"
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>

          {props?.fromCompletedTab ? (
            <Grid mt={2} item lg={12}>
              <ViewAttendesContainer />
            </Grid>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </>
  );
};

export default ToolBoxTalkDetail;
