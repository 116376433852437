import React from "react";
import AssetManagementList from "../component/assetManagement/assetManagementList";
import {
  ADMIN_PROJECT,
  ADMIN_VEHICLES,
  ADMIN_EQUIPMENTS,
  ADMIN_ASSETS,
  ACCESS_DENIED,
  ADMIN_PROCORE_PROJECT_LIST,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

const AssetManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_PROJECT);
    } else if (id === 2) {
      navigate(ADMIN_VEHICLES);
    } else if (id === 3) {
      navigate(ADMIN_EQUIPMENTS);
    } else if (id === 4) {
      navigate(ADMIN_ASSETS);
    } else if (id === 5) {
      navigate(ADMIN_PROCORE_PROJECT_LIST);
    }
  };

  console.log(checkPrivileges([12, 37, 38, 39]));
  return (
    <>
      {checkPrivileges([12, 37, 38, 39, 52, 53, 54, 70, 71, 72, 78, 79, 80]) ? (
        <>
          <div className="dashTitle">Asset Management</div>
          <AssetManagementList onClick={onClickHandler} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default AssetManagementContainer;
