import {
  InspectionCardImage,
  SetupSiteProjectsManagement,
  accident,
} from "../../assets";
export const SETUPSITEMANAGEMENT = [
  { id: 1, title: "Site", image: SetupSiteProjectsManagement.siteIcon },
  { id: 2, title: "Project", image: SetupSiteProjectsManagement.projectsIcon },
];

const getUserInfo = () => {
  const userInfoJson = localStorage.getItem("userInfo");
  return userInfoJson ? JSON.parse(userInfoJson) : null;
};
const productstatus = () =>
  getUserInfo()?.product?.map((val) => {
    return val?.key;
  });
export const PRODUCT_ID = () =>
  localStorage?.getItem("serviceType") === null
    ? productstatus()?.[0]
    : localStorage?.getItem("serviceType");

export const INSPECTIONCARD = [
  {
    id: 1,
    title: "Inspection Forms",
    image: InspectionCardImage.inspectionconfigation,
  },
  {
    id: 2,
    title: "Category Configuration",
    image: InspectionCardImage.categories,
  },
  {
    id: 3,
    title: "Sub Category Configuration",
    image: InspectionCardImage.subcategories,
  },
  {
    id: 4,
    title: "Questions Configuration",
    image: InspectionCardImage.questions,
  },
  {
    id: 5,
    title: "Items Found Responses",
    image: InspectionCardImage.questions,
  },
  {
    id: 6,
    title: "Setup Inspection",
    image: InspectionCardImage.questions,
  },
  {
    id: 7,
    title: "Inspection Type",
    image: InspectionCardImage.questions,
  },
  {
    id: 8,
    title: "Inspection Header",
    image: InspectionCardImage.questions,
  },
  {
    id: 9,
    title: "Inspector Management",
    image: InspectionCardImage.inspectionconfigation,
  },
];
export const PROJECT_SW_GRID_COLOUMNS = [
  { field: "name", headerName: "Project Name", flex: 2 },
  { field: "location", headerName: "Location", flex: 2 },
];
export const SW_REPORTS_COUNT = 100;

export const STATUS = [
  { key: 1, value: "Active" },
  { key: 2, value: "In Active" },
];

export const ITEM_STATUS = [
  { key: 1, value: "Open" },
  { key: 2, value: "Closed" },
];

export const INSSTATUS = [
  { key: 5, value: "Submit" },
  { key: 1, value: "Draft" },
];

export const INSPECTION_REPORT_STATUS = [
  { key: 1, value: "Acceptable" },
  { key: 2, value: "NotAcceptable" },
  { key: 3, value: "NotAvailable" },
  { key: 4, value: "Freetext" },
];

export const WEEKDAYS = [
  { key: 1, value: "Monday" },
  { key: 2, value: "Tuesday" },
  { key: 3, value: "Wednesday" },
  { key: 4, value: "Thursday" },
  { key: 5, value: "Friday" },
  { key: 6, value: "Saturday" },
  { key: 7, value: "Sunday" },
];

export const SOILLIST = [
  { key: 6, value: "Wet", img: accident.wet },
  { key: 7, value: "Dry", img: accident.bounding },
  { key: 8, value: "Frozen", img: accident.acUnit },
  { key: 9, value: "Damp", img: accident.damp },
];

export const TIMERANGE = [
  { key: 7, value: "last 7 days" },
  { key: 15, value: "last 15 days" },
  { key: 30, value: "last 30 days" },
];

export const QUESTION_OPTION = [
  { key: 1, value: "Acceptable" },
  { key: 2, value: "Not Acceptable" },
  { key: 3, value: "Not Applicable" },
];

// time zone constant
export const TIMEZONE_OPTION = [
  { key: "EST", value: "Eastern Standard Time" },
  { key: "CST", value: "Central Standard Time" },
  { key: "MST", value: "Mountain Standard Time" },
  { key: "PST", value: "Pacific Standard Time" },
];

export const SW_QUESTION_RESPONSE_TYPE = [
  { key: 1, value: "Acceptable/Not Acceptable/NA" },
  { key: 2, value: "Text Box" },
  { key: 3, value: "Acceptable/Not Acceptable/NA - 3 Options" },
 ]
