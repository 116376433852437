import {
  assetsType,
  clearAssetType,
  deleteAssetType,
  getAssetDetailsType,
  postAddAssestType,
  updateAssetType,
} from "../constants/assetType";

export const getAssetList = (data) => ({
  type: assetsType.GET_ASSET_LIST,
  data,
});

export const getAssetListSuccess = (data) => ({
  type: assetsType.GET_ASSET_LIST_SUCCESS,
  data,
});

export const getAssetListEmailSuccess = () => ({
  type: assetsType.GET_ASSET_LIST_SUCCESS,
});

export const getAssetListFailure = (error) => ({
  type: assetsType.GET_ASSET_LIST_FAILURE,
  error,
});

// postAddAssest Action
export const postAddAssest = (data) => ({
  type: postAddAssestType.POST_ADD_ASSET_PENDING,
  data,
});

export const postAddAssestSuccess = (data) => ({
  type: postAddAssestType.POST_ADD_ASSET_SUCCESS,
  data,
});

export const postAddAssestFailure = (data) => ({
  type: postAddAssestType.POST_ADD_ASSET_FAILURE,
  data,
});

// deleteAsset action
export const deleteAsset = (payload) => ({
  type: deleteAssetType.DELETE_ASSET_PENDING,
  payload: payload,
});

export const deleteAssetSuccess = (payload) => ({
  type: deleteAssetType.DELETE_ASSET_SUCCESS,
  payload: payload,
});

export const deleteAssetFailure = (error) => ({
  type: deleteAssetType.DELETE_ASSET_FAILED,
  error,
});

export const getAssetDetails = (data) => ({
  type: getAssetDetailsType.GET_ASSET_DETAILS,
  data,
});

export const getAssetDetailsSuccess = (data) => ({
  type: getAssetDetailsType.GET_ASSET_DETAILS_SUCCESS,
  data,
});

export const getAssetDetailsFailure = (error) => ({
  type: getAssetDetailsType.GET_ASSET_DETAILS_FAILURE,
  error,
});

export const updateAsset = (payload) => ({
  type: updateAssetType.UPDATE_ASSET_PENDING,
  payload: payload,
});

export const updateAssetSuccess = (data) => ({
  type: updateAssetType.UPDATE_ASSET_SUCCESS,
  data,
});

export const updateAssetFailure = (error) => ({
  type: updateAssetType.UPDATE_ASSET_FAILED,
  error,
});

export const clearAsset = () => ({
  type: clearAssetType.CLEAR_ASSET,
  data: "",
});
// get asset in dynomo db===============

export const getAssetDynomoList = (data) => ({
  type: assetsType.GET_ASSET_DYNOMO_LIST,
  data,
});

export const getAssetListDynomoSuccess = (data) => ({
  type: assetsType.GET_ASSET_DYNOMO_SUCCESS,
  data,
});

export const getAssetListDynomoFailure = (error) => ({
  type: assetsType.GET_ASSET_DYNOMO_FAILURE,
  error,
});

// get asset in dynomo db=============== himanshu

export const getUserAssetList = (data) => ({
  type: assetsType.GET_USER_ASSET_LIST,
  data,
});

export const getUserAssetListSuccess = (data) => ({
  type: assetsType.GET_USER_ASSET_SUCCESS,
  data,
});

export const getUserAssetListFailure = (error) => ({
  type: assetsType.GET_USER_ASSET_FAILURE,
  error,
});
