import React from "react";
import SubContractorWithMostParticipation from "./subContractorWithMostParticipation";

import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { REPORTS_ANALYTICS_DASHBOARD } from "../../../../constant/routeContant";
import UsersWithHighestCompletedTraining from "./usersWithHighestCompletedTraining";
import LessonsWithHighestCompletion from "./lessonsWithHighestCompletion";
import TrainingWithLowestParticipation from "./trainingWithLowestPArticipation";
import CoursesWithHighestCompletion from "./coursesWithHighestCompletion";
import UsersWithMostSafetyMeetings from "./usersWithMostSafetyMeetings";
import UsersWithMostIncompletedTraining from "./usersWithMostIncompleteTraining";
import PastDueHazardAnalysis from "./pastDueHazardAnanlysis";
import CompletedTraining from "./completedTraining";
const DrillDown = (props) => {
  const dataBack = {
    title: "Back to Dashboard",
    route: REPORTS_ANALYTICS_DASHBOARD,
    state: {
      apiData: props?.apiData,
      backpage: "backpage",
      tab: props?.apiData?.tab,
    },
  };

  return (
    <div>
      <div onClick={() => props.dataClear()}>
        <BackBtn dataBack={dataBack} />
      </div>
      {props.page === "subContractorWithMostParticipation" ? (
        <SubContractorWithMostParticipation
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "usersWithHighestCompletedTraining" ? (
        <UsersWithHighestCompletedTraining
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "lessons" ? (
        <LessonsWithHighestCompletion
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "trainingWithLowestParticipation" ? (
        <TrainingWithLowestParticipation
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "coursesWithHighestCompletion" ? (
        <CoursesWithHighestCompletion
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "usersWithMostSafetyMeetings" ? (
        <UsersWithMostSafetyMeetings
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "usersWithMostIncompletedTraining" ? (
        <UsersWithMostIncompletedTraining
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "pastDueHazardAnalysis" ? (
        <PastDueHazardAnalysis
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          apiData={props.apiData}
          dataClear={props.dataClear}
        />
      ) : props.page === "completedTraining" ? (
        <CompletedTraining
          getMoretDetails={props.getMoretDetails}
          getLmsDashboardReportDetails={
            props.getLmsDashboardReportDetails?.data
          }
          getLMSDashboardReport={props.getLMSDashboardReport}
          apiData={props.apiData}
          selectedCard={props.selectedCard}
          setselectedCard={props.setselectedCard}
          dataClear={props.dataClear}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DrillDown;
