import React, { useEffect, useState } from "react";
import CourseDetail from "../component/courseDetail/courseDetail";
import { useDispatch, useSelector } from "react-redux";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import { USER_QUIZ, ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import * as userAction from "../../../redux/actions/userAction";
import { API_STATUS } from "../../../constant/constant";
import { USER_ID } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import * as lmsAction from "../../../redux/actions/lmsAction";
import { checkPrivileges } from "../../../utils/rolesHelper";

const CourseDetailContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = USER_ID();
  const queryString = window.location.search.slice(
    4,
    window.location.search?.length
  );
  const courseId = location?.state?.courseId ?? queryString;
  const isAdminCourse = location?.state?.isAdminCourse;
  const fromReportAnalytics = location?.state?.fromReportAnalytics;
  const navigate = useNavigate();
  const [comsucces, setComsucces] = useState(false);
  const [assignmsg, setAssignmsg] = useState(false);
  const [errCompltemsg, setErrCompltemsg] = useState("");
  const userCourseViewData = useSelector(
    (state) => state?.getUserCourseView.data
  );
  const userLessonViewData = useSelector(
    (state) => state?.getUserLessonView.data
  );
  const quizStatus = userLessonViewData?.lessons?.isQuizCompeted;
  const showLoaderc = useSelector((state) => state.getUserLessonView.isLoading);
  const isSuccessc = useSelector(
    (state) => state.getUserLessonView.status === API_STATUS.SUCCESS
  );

  const allUsers = useSelector((state) => state.allUsers?.data?.users);
  const assignCourserResult = useSelector((state) => state.assignCourse);
  const assigncourseStatus = assignCourserResult?.status;
  const isSuccess = useSelector(
    (state) => state.getUserCourseView.status === API_STATUS.SUCCESS
  );
  const [checkcoursestatus, setcheckcoursestatus] = useState(false);
  const [isSubmitAssign, setisSubmitAssign] = useState(false);
  const CompleteCourseStatusStatus = useSelector(
    (state) => state.getCompletedCourseStatuscheck?.status
  );
  const CompleteCourseStatusResult = useSelector(
    (state) =>
      state.getCompletedCourseStatuscheck?.dataresult?.completedCourseList
  );

  useEffect(() => {
    if (CompleteCourseStatusResult?.length > 0) {
      setcheckcoursestatus(true);
    } else {
      setcheckcoursestatus(false);
    }
  }, [CompleteCourseStatusResult]);

  const getUserLessonViews = (id) => {
    dispatch(lmsUserAction.getUserLessonView(id, courseId));
    setErrCompltemsg("");
  };
  const getCoursesViews = async (id) => {
    await dispatch(lmsUserAction.getUserCoursesView(userId, courseId));
  };
  useEffect(() => {
    if (userCourseViewData?.result?.lessonList[0]?.lessonId) {
      getUserLessonViews(userCourseViewData?.result?.lessonList[0]?.lessonId);
    }
  }, [isSuccess]);

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10, 9]);

    if (isAllowedDash === false) {
      navigate(ACCESS_DENIED);
    }
    getCoursesViews();
    dispatch(userAction.searchUsers("", ""));
  }, []);

  useEffect(() => {
    if (assigncourseStatus === API_STATUS.SUCCESS) {
      setAssignmsg(true);
      const timeout = setTimeout(() => {
        setAssignmsg(false);
      }, 2000);

      return () => clearTimeout(timeout);
    } else {
      setAssignmsg(false);
    }
  }, [assigncourseStatus]);

  const handleViewOnClick = (quizId, id, courseId) => {
    navigate(USER_QUIZ, {
      state: {
        quizId: quizId,
        lessonId: id,
        courseId: courseId,
        isAdminLms: isAdminCourse,
      },
    });
  };

  function searchUsers(searchText, type, divisionId) {
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  }

  const completeLessonCode = useSelector(
    (state) => state.completeLesson?.data?.responseMessages?.responseCode
  );
  const completeLessonIsloading = useSelector(
    (state) => state.completeLesson?.isLoading
  );
  const completeLessonIsStatus = useSelector(
    (state) => state.completeLesson?.status
  );
  const completeLessonresponseMessages = useSelector(
    (state) => state.completeLesson?.data?.responseMessages?.responseMessage
  );
  useEffect(() => {
    if (
      completeLessonIsStatus === API_STATUS.SUCCESS &&
      completeLessonCode === "HBNG002"
    ) {
      setErrCompltemsg(completeLessonresponseMessages);
    }
    if (
      completeLessonIsStatus === API_STATUS.SUCCESS &&
      completeLessonCode === "HBNG001"
    ) {
      setComsucces(true);
    }
  }, [completeLessonIsloading, completeLessonCode]);

  useEffect(() => {
    dispatch(lmsAction.clearAssigncourseStatus());
    setisSubmitAssign(false);
    setErrCompltemsg("");
    setComsucces(false);
    setTimeout(() => {
      setErrCompltemsg("");
    }, 1000);
  }, []);

  const successScree = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route="/user-courses"
        id={id}
        label2="Congrats! You have successfully completed the Lesson"
        label1="Course ID"
        label4="Lesson in "
      ></SuccessScreen>
    );
  };
  function handleAllGroupSearch(serachText, divisionId, alldivision) {
    dispatch(userAction.searchAllGroup(serachText, divisionId, alldivision));
  }
  const allGroups = useSelector((state) => state.searchAllGroup);
  const isLoading = useSelector((state) => state.searchAllGroup?.isLoading);
  const AlluserisLoading = useSelector((state) => state.allUsers?.isLoading);

  const getStatusCheckCoursecompleted = (data) => {
    dispatch(lmsAction.getCompletedCourseStatuscheck(data));
  };
  const showLoader = useSelector(
    (state) =>
      state.getUserCourseView.isLoading ||
      state.completeLesson?.isLoading ||
      state.assignCourse?.isLoading
  );
  return (
    <>
      {showLoader && <Loader />}
      {comsucces && completeLessonCode == "HBNG001" ? (
        successScree(courseId, "Lesson Completed Successfully")
      ) : (
        <CourseDetail
          userLessonViewData={userLessonViewData}
          showLoader={showLoaderc}
          isSuccess={isSuccessc}
          getUserLessonViews={(id) => getUserLessonViews(id)}
          viewData={userCourseViewData}
          viewOnClick={(quizId, id, courseId) =>
            handleViewOnClick(quizId, id, courseId)
          }
          onSearch={searchUsers}
          allUsers={allUsers}
          assigncourseStatus={assigncourseStatus}
          assignmsg={assignmsg}
          quizStatus={quizStatus}
          errCompltemsg={errCompltemsg}
          handleAllGroupSearch={handleAllGroupSearch}
          userGroups={allGroups?.data?.groupList}
          isLoading={isLoading}
          AlluserisLoading={AlluserisLoading}
          isAdminCourse={isAdminCourse}
          getStatusCheckCoursecompleted={getStatusCheckCoursecompleted} // reassign course
          checkcoursestatus={checkcoursestatus}
          setcheckcoursestatus={setcheckcoursestatus}
          completeCourseStatusResult={CompleteCourseStatusResult}
          completeCourseStatusStatus={CompleteCourseStatusStatus}
          setisSubmitAssign={setisSubmitAssign}
          isSubmitAssign={isSubmitAssign}
          fromReportAnalytics={fromReportAnalytics}
        />
      )}
    </>
  );
};
export default CourseDetailContainer;
