import { resourceType } from "../constants/resourceType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "resource",
  isError: false,
  isLoading: false,
};

export const resourceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceType.GET_RESOURCE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case resourceType.GET_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case resourceType.GET_RESOURCE_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case resourceType.GET_RESOURCE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceType.POST_UPDATE_RESOURCE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case resourceType.POST_UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case resourceType.POST_UPDATE_RESOURCE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case resourceType.CLEAR_RESOURCE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const createResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceType.POST_RESOURCE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case resourceType.POST_RESOURCE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case resourceType.POST_RESOURCE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case resourceType.CLEAR_RESOURCE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const resourceDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceType.GET_RESOURCE_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case resourceType.GET_RESOURCE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case resourceType.GET_RESOURCE_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case resourceType.DELETE_RESOURCE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case resourceType.DELETE_RESOURCE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case resourceType.DELETE_RESOURCE_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
