import { incidentType } from "../constants/incidentType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "incident",
  isError: false,
  isLoading: false,
};

export const incidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const incidentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteIncidentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.DELETE_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.DELETE_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.DELETE_INCIDENT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const actionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_ACTION_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_ACTION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_ACTION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const closeActionItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.CLOSE_ACTION_ITEM:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.CLOSE_ACTION_ITEM_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.CLOSE_ACTION_ITEM_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const casesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_CASES_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_CASES_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_CASES_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_CASES_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const viewCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_CASE_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_CASE_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const closeIncidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.CLOSE_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.CLOSE_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.CLOSE_INCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.DELETE_CASE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.DELETE_CASE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.payload,
        isError: false,
        isLoading: false,
      };
    case incidentType.DELETE_CASE_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const getIncidentReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportInjuredBodyPartReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportInjuredBodyPartListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// most injury

export const getIncidentReportMostCommonInjuryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportMostCommonInjuryListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
// most common day of injury
export const getIncReportMostCommonDayOfInjuryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportUserWithMostIncidentReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportUserWithMostIncidentListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportUserWithMostActionItemsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getIncidentReportUserWithMostActionItemsListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const projectwithmostincidentsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.PROJECT_WITH_MOST_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.PROJECT_WITH_MOST_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.PROJECT_WITH_MOST_INCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const projectwithmostincidentsListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.PROJECT_WITH_MOST_INCIDENT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.PROJECT_WITH_MOST_INCIDENT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.PROJECT_WITH_MOST_INCIDENT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const vehiclewithmostincidentsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.VEHICLE_WITH_MOST_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.VEHICLE_WITH_MOST_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.VEHICLE_WITH_MOST_INCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const vehiclewithmostincidentsListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getOSHAEstablishmentListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.OSHA_ESTABLISHMENT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.OSHA_ESTABLISHMENT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.OSHA_ESTABLISHMENT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addEstablishmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.POST_OSHA_ESTABLISHMENT_ADD_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.POST_OSHA_ESTABLISHMENT_ADD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.POST_OSHA_ESTABLISHMENT_ADD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incidentType.CLEAR_OSHA_ESTABLISHMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateEstablishmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.POST_OSHA_ESTABLISHMENT_UPDATE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.POST_OSHA_ESTABLISHMENT_UPDATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.POST_OSHA_ESTABLISHMENT_UPDATE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incidentType.CLEAR_OSHA_ESTABLISHMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateEstablishmentStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case incidentType.POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.POST_OSHA_ESTABLISHMENT_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incidentType.CLEAR_OSHA_ESTABLISHMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getEstablishmentDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_OSHA_ESTABLISHMENT_DETAIL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_OSHA_ESTABLISHMENT_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_OSHA_ESTABLISHMENT_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getOshaLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_OSHA_LOG_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_OSHA_LOG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_OSHA_LOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getOshaDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_OSHA_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_OSHA_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_OSHA_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addOshaDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.POST_OSHA_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.POST_OSHA_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.POST_OSHA_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incidentType.CLEAR_Add_OSHA_DETAILS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getListAddedInOSHAReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_LIST_ADDED_IN_OSHA_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_LIST_ADDED_IN_OSHA_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_LIST_ADDED_IN_OSHA_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSubmitDetailsToOshaReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_SUBMIT_DETAILS_TO_OSHA_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_SUBMIT_DETAILS_TO_OSHA_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_SUBMIT_DETAILS_TO_OSHA_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addGetOSHALogReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.POST_SUBMIT_GET_OSHA_LOG:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.POST_SUBMIT_GET_OSHA_LOG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.POST_SUBMIT_GET_OSHA_LOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case incidentType.CLEAR_SUBMIT_GET_OSHA_LOG:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
