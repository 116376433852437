import { Grid } from "@mui/material";
import React, { useState } from "react";
import "./userQuizDetail.scss";
import TabPanel from "../tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "../../../../library/custom/button/Button";
import { ORG_ID, USER_ID } from "../../../../constant/constant";
import { useEffect } from "react";

const UserQuizDetail = (props) => {
  const quizDetail = props?.quizListData?.quizDetail;
  const questionList = props?.quizListData?.questionList;
  const quizId = props?.quizListData?.quizDetail?.quizId;
  const lessonId = props?.lessonId;
  const courseId = props?.courseId;
  const errCompltemsg = props?.errCompltemsg;
  const setIsclickbtn = props?.setIsclickbtn;
  const isAdminLms = props?.isAdminLms;
  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    const dataSelect = rerutnIndexOfQuestionList();
    if (dataSelect) {
      setValue(newValue);
    }
  };
  const intialState = {
    lessonId: lessonId,
    createdBy: USER_ID(),
    quizId: quizId,
    courseId: courseId,
  };
  const [selectAnswer, setSelectAnswer] = useState([]);
  useEffect(() => {
    setSelectAnswer(props?.quizListData?.questionList);
  }, [props?.quizListData]);

  const [formValue, setFormValue] = useState(intialState);
  const [isSubmit, setIsSubmit] = useState(false);

  function rerutnIndexOfQuestionList() {
    const findData = selectAnswer[value];
    const index = findData.answers
      .map((object) => object.colorSelect)
      .indexOf(true);
    if (index == -1) {
      setIsAnsNotSelected(true);
      return false;
    } else {
      setIsAnsNotSelected(false);
      return true;
    }
  }

  function mappedData() {
    const dataSelect = rerutnIndexOfQuestionList();
    const selectAnswerClone = [...selectAnswer];
    const sendData = [];
    selectAnswerClone.forEach((quesData) => {
      quesData.answers.forEach((ans) => {
        if (ans.colorSelect) {
          const obj = {
            questionId: quesData.questionId,
            answerId: ans.answerId,
          };
          sendData.push(obj);
        }
      });
    });
    if (dataSelect) {
      const data = {
        lessonId: formValue?.lessonId,
        createdBy: formValue?.createdBy,
        quizId: formValue?.quizId,
        courseId: formValue?.courseId,
        orgId: ORG_ID(),
        submitQuestions: sendData,
      };
      return { data };
    }
  }
  const submitData = () => {
    setIsclickbtn(true);
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      props?.onSubmit(data);
    }
  };

  const selectAns = (tabPanel, ansItem) => {
    const cloneSelectAnswer = [...selectAnswer];

    cloneSelectAnswer.forEach((data) => {
      if (tabPanel.questionId == data.questionId) {
        data.answers.forEach((answerItem) => {
          if (answerItem.answerId == ansItem.answerId) {
            answerItem.colorSelect = true;
          } else {
            answerItem.colorSelect = false;
          }
        });
      }
      setSelectAnswer(cloneSelectAnswer);
    });
  };

  const [isAnsNotSelected, setIsAnsNotSelected] = useState(false);

  const handlePrev = () => {
    if (value == 0) {
      setValue(value);
    } else {
      setValue(value - 1);
    }
  };

  const handleNext = () => {
    const dataSelect = rerutnIndexOfQuestionList();
    if (dataSelect) {
      if (value == questionList.length - 1) {
        setValue(value);
      } else {
        setValue(value + 1);
      }
    }
  };

  return (
    <>
      <div className="quizDetailContainer">
        <Grid item container>
          <Grid item xs={12} md={10}>
            <div className="quizDetailBox">
              <div className="title">{props?.lessonName}</div>
              <div className="subtitle">{quizDetail?.title}</div>
              <div className="descraption">{quizDetail?.description}</div>
            </div>
          </Grid>
        </Grid>
        <div className="quizQuestionListBox">
          <Grid item container>
            <Grid item xs={12} md={3}>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
              >
                {questionList?.map((tabItem, i) => {
                  return (
                    <Tab
                      className="quizList"
                      label={`${"Question"} ${i + 1}`}
                      key={i}
                      {...a11yProps(i, tabItem?.questionId)}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Grid item xs={12} md={7} pl={2}>
              <Grid item className="quizWithDetailBox">
                {selectAnswer?.map((tabPanel, i) => {
                  return (
                    <>
                      <TabPanel
                        className="quizWithDetailAns"
                        value={value}
                        key={tabPanel?.id}
                        index={i}
                      >
                        <div className="quesTitle">{tabPanel?.question}</div>
                        {tabPanel?.isFileAdded && (
                          <div className="quesImg">
                            <img src={tabPanel?.files[0].value} alt="" />
                          </div>
                        )}

                        <div className="answerList">
                          <ul>
                            {tabPanel?.answers?.map((ansItem, i) => {
                              return (
                                <>
                                  <li
                                    key={i}
                                    className={
                                      ansItem.colorSelect ? "selected" : ""
                                    }
                                    onClick={() => selectAns(tabPanel, ansItem)}
                                  >
                                    {ansItem?.answer}
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                          {isAnsNotSelected ? (
                            <p className="answerError">Please Select Answer</p>
                          ) : null}
                        </div>
                      </TabPanel>
                    </>
                  );
                })}
                <div className="quizBtn">
                  <Button
                    onClick={handlePrev}
                    varientContained={true}
                    disabled={value === 0 ? true : false}
                    label={"Previous"}
                  />

                  {value === questionList?.length - 1  ? (
                    !isAdminLms && <Button
                      onClick={submitData}
                      varientContained={true}
                      disabled={isSubmit || isAdminLms ? true : false}
                      label={"Submit"}
                    />
                  ) : (
                    <Button
                      onClick={handleNext}
                      varientContained={true}
                      label={"Next"}
                    />
                  )}
                </div>

                {errCompltemsg ? (
                  <div className="show-error">{props?.errCompltemsg}</div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default UserQuizDetail;
