import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./rainfalldata.scss";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ToolBar from "../../../../library/storm-water/toolBar";
import { getFormatedDate } from "../../../../utils/helper";
import { ORG_ID, FILTER_RAINFALL_DAYS } from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import {
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_CONTACT_PROJECT,
  SW_PERMIT_LIST,
  SW_OPEN_ITEMS,
  ADMIN_PROJECTS_LIST,
  SW_ADMIN_SAMPLINGLOG,
  SW_INSPECTION_LIST,
  INSPECTION_AUTHORITY,
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_RESOURCE,
} from "../../../../constant/routeContant";
import Loader, { TableLoader } from "../../../../library/common/Loader";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import DonoughtChart from "../../../support/component/ticket/charts";
function RainFallData({
  swRainFallDatas,
  swGetRainFallDataList,
  projectName,
  customerName,
  projectId,
  projectNumber,
  stateId,
  customerId,
}) {
  const [page, setPage] = useState(1);
  const [rainFullDataType, setRainFullDataType] = useState("1");
  const [rainFallDateRange, setrainFallDateRange] = useState(0);
  const [calculatedDate, setCalculatedDate] = useState(new Date());
  const [formDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [formDateError, setformDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState("");

  const [swRainfalldataintial, setSwRainfalldataintial] = useState({
    page: page,
    pageSize: 30,
    rainfallType: rainFullDataType,
    projectId: projectId,
    fromDate: "",
    toDate: "",
    search: "",
    OrgId: customerId ? customerId : parseInt(ORG_ID()),
  });
  const [reversedData, setReversedData] = useState([]);

  const datahistoricalData = swRainFallDatas?.data?.historyPrecipList;

  useEffect(() => {
    const datahistoricalData = swRainFallDatas?.data?.historyPrecipList;
    if (Array.isArray(datahistoricalData)) {
      const reversed = [...datahistoricalData].reverse();
      setReversedData(reversed);
    }
  }, [swRainFallDatas]);

  const forecastData = swRainFallDatas?.data?.forecastData;
  const datahistoricalDataCount = swRainFallDatas?.data?.historyPrecipCount;
  const isLoading = swRainFallDatas?.isLoading;
  const todayDate = new Date();
  const history = useNavigate();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (rainFallDateRange > 0 && formDate && toDate) ||
      rainFullDataType === "1"
    ) {
      if (
        (formDate == "" && toDate == "") ||
        (formDate <= toDate && formDate !== "")
      ) {
        swGetRainFallDataList({
          ...swRainfalldataintial,
          fromDate: formDate,
          toDate: toDate,
        });
      }
    }
    if (rainFullDataType === "2") {
      const today = new Date();
      const calculated = new Date(today);

      swGetRainFallDataList({
        ...swRainfalldataintial,
        rainfallType: 2,
        fromDate: getFormatedDate(calculated.setDate(today.getDate())),
        toDate: getFormatedDate(calculated.setDate(today.getDate() + 7)),
      });
    }
  }, [rainFallDateRange, formDate, toDate, rainFullDataType]);

  const swRainfallcolumns = [
    { field: "projectNumber", headerName: "Project Number", flex: 0.8 },
    {
      field: "precipDate",
      headerName: "Date",
      flex: 1,
      renderCell: (cellValues) => {
        let cellval = cellValues;
        return (
          <>
            <div className="nameBox1">
              <div className="nameImg1">
                <p>
                  {cellval ? (
                    <div className="checksample">
                      {getFormatedDate(cellval?.row?.precipDateFormat)}
                    </div>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "evenTrackPrecipAmt",
      headerName: "Rain Amount (Inches)",
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.evenTrackPrecipAmt
              ? parseFloat(rows?.row?.evenTrackPrecipAmt.toFixed(2))
              : 0}
          </>
        );
      },
    },
    { field: "exceedance", headerName: "Exceedence", flex: 0.6 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={30 ?? 0}
          numberOfItemsPerPage={30}
          incidentLists={datahistoricalData ?? []}
          fileNameXL={"RainfallList"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  const toolBarData = {
    title: "Project Tools",
    list: [
      {
        id: 10,
        title: "Edit Project",
        link: "",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
        isshow: checkPrivileges([12, 99, 100]),
      },
      {
        id: 3,
        title: "Permits",
        link: "",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },

      {
        id: 4,
        title: "Sampling Log",
        link: "",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
        activeClass: "active",
        isshow: true,
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
        isshow: checkPrivileges([12, 73, 74, 75]),
      },
    ],
  };

  const onChangeDateChnage = (e) => {
    let formdata1 = "";
    let toDate1 = "";

    if (e?.target?.value !== 12) {
      const days = parseInt(e?.target?.value);
      const today = new Date();
      const calculated = new Date(today);
      calculated.setDate(today.getDate() - (days - 1));
      setCalculatedDate(calculated);
      formdata1 = getFormatedDate(calculated);
      toDate1 = getFormatedDate(todayDate);
    }
    setFormDate(formdata1);
    setToDate(toDate1);
    setrainFallDateRange(e?.target?.value);
  };
  const onchangeFormdatePicker = (e) => {
    setFormDate(getFormatedDate(e));
  };
  const onchangeTodatePicker = (e) => {
    setToDate(getFormatedDate(e));
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);

    swGetRainFallDataList({
      ...swRainfalldataintial,
      page: newPage + 1,
      fromDate: formDate,
      toDate: toDate,
    });
  };
  const today = new Date();
  const lastThreemonthDate = new Date(today);
  lastThreemonthDate.setDate(today.getDate() - 90);

  return (
    <>
      {/* {rainFullDataType == "2" && !forecastData ? <Loader /> : ""} */}
      <div className="overidePersonal">
        <Grid container>
          <Grid lg={8} md={8} xs={12}>
            <Grid container>
              <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
                <div className="heading_style">
                  {"Historical Rainfall Data"}
                </div>
              </Grid>
            </Grid>

            <Grid container lg={12} mt={3}>
              {rainFullDataType === "1" && (
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Date Range" isRequired={false} />
                    <SelectMenu
                      listData={FILTER_RAINFALL_DAYS}
                      value={rainFallDateRange}
                      onchangehandler={(e) => onChangeDateChnage(e)}
                      placeholder="Please Select"
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid container lg={12} mt={1} spacing={2}>
                {rainFallDateRange === 12 && (
                  <>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label={"From"} isRequired={true} />
                        <DatePickers
                          maxDateEnable={false}
                          value={formDate}
                          disabled={false}
                          onChangeHandler={(e) => onchangeFormdatePicker(e)}
                          minDate={lastThreemonthDate}
                        />
                      </FormControl>
                      {formDateError && !formDate && (
                        <div className="errorMsg">Form Date is required</div>
                      )}
                      {dateErrMsg && formDate != null && (
                        <div className="errorMsg">Invalid Date</div>
                      )}
                    </Grid>

                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label={"To"} isRequired={true} />
                        <DatePickers
                          maxDateEnable={false}
                          minDate={formDate}
                          value={toDate}
                          disabled={false}
                          onChangeHandler={(e) => onchangeTodatePicker(e)}
                        />
                      </FormControl>
                      {toDateError && !toDate && (
                        <div className="errorMsg">To Date is required</div>
                      )}
                      {dateErrMsg && toDate != null && (
                        <div className="errorMsg">Invalid Date</div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {/* //=========================================== */}

            <Grid mt={4} lg={12} className="LineChart">
              <div className="lineChart">
                <DonoughtChart LineChart datahistoricalData={reversedData} />
              </div>
            </Grid>

            <Grid container lg={12} mt={1} spacing={2}>
              <Grid container lg={12} xs={12} mt={3}>
                <Grid item lg={12} mt={2} xs={12}>
                  <section className="grid_main_sec">
                    <div className="grid_main_body">
                      <div className="desc_box row_uniq">
                        {rainFullDataType === "1" ? (
                          <div className="rainfalltable">
                            <div className="table_style_ui">
                              <GridTable
                                getRowId={(r) => r?.precipId}
                                rows={datahistoricalData ?? []}
                                rowCount={datahistoricalDataCount ?? 0}
                                columns={swRainfallcolumns}
                                loading={isLoading}
                                checkboxSelection={false}
                                page={1}
                                pagination
                                pageSize={30}
                                rowsPerPageOptions={[30]}
                                onPageChange={handlePagignation}
                                backIconButtonProps={{ disabled: false }}
                                disableSelectionOnClick
                                components={{
                                  LoadingOverlay: TableLoader,
                                  NoRowsOverlay: NORow,
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="rainfallTable">
                            <Grid container lg={12} xs={12} mt={3}>
                              <Grid item lg={12} mt={2} xs={12}>
                                <table>
                                  <thead>
                                    <tr className="header-rainfall">
                                      <th>Forecast</th>
                                      <th>Rain Amount (Inches)</th>
                                      <th>Account ID</th>
                                    </tr>
                                  </thead>
                                  <tbody className="body-rainfall">
                                    <tr>
                                      <td>1 Day</td>
                                      <td>
                                        {forecastData?.day1_forecast
                                          ? forecastData?.day1_forecast
                                          : 0}
                                      </td>
                                      <td>{forecastData?.account_id}</td>
                                    </tr>
                                    <tr>
                                      <td>2 Day</td>
                                      <td>
                                        {forecastData?.day2_forecast
                                          ? forecastData?.day2_forecast
                                          : 0}
                                      </td>
                                      <td>{forecastData?.account_id}</td>
                                    </tr>
                                    <tr>
                                      <td>3 Day</td>
                                      <td>
                                        {forecastData?.day3_forecast
                                          ? forecastData?.day3_forecast
                                          : 0}
                                      </td>
                                      <td>{forecastData?.account_id}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={4} md={4} xs={12} pl={1}>
            <div className="toolBarBox">
              <div className="otherDetail">
                <h3>Project Details</h3>
                <h2>{projectName}</h2>
                <h2>{projectNumber}</h2>
              </div>
              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>

              <ToolBar
                toolBarData={toolBarData}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 9) {
                    navigate(SETUP_INSPECTION, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                        rightSideMode: true,
                      },
                    });
                  }
                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 4) {
                    navigate(SW_ADMIN_SAMPLINGLOG, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 10) {
                    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                      state: {
                        editMode: true,
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default RainFallData;
