import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import {
  downloadExcel,
  downloadPDF,
  getFormatedDate,
  useInfiniteScroll,
  getDayNameAndDate,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import BarChart from "../../../../library/custom/charts/barchart";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import { useSelector } from "react-redux";
import { COLORS, PAGE_SIZE_100 } from "../../../../constant/constant";
import { useDispatch } from "react-redux";
import { getIncMostCommonDayOfInjury } from "../../../../redux/actions/incidentAction";
import {
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
  VIEW_INCIDENT_REPORT,
} from "../../../../constant/routeContant";
import Loader from "../../../../library/common/Loader";

function MostCommonDayofweek(props) {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [barDate, setBarDate] = useState();
  const incReportMostCommonDayOfInjuryData = useSelector(
    (state) => state.incReportMostCommonDayOfInjury
  );

  useEffect(() => {
    if (props?.incReportMostCommonDayOfInjuryData) {
      setData((prevData) => [
        ...prevData,
        ...props?.incReportMostCommonDayOfInjuryData,
      ]);
    }
  }, [props?.incReportMostCommonDayOfInjuryData]);

  const fetchMoreData = async (pageNumber) => {
    const data = {
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
      fromDate: props?.drillDetailFull?.startDate,
      toDate: props?.drillDetailFull?.endDate,
      divisionId: props?.drillDetail?.divisionId,
      locationId: props?.drillDetail?.locationId,
      Day: props?.drillDetail?.Day,
    };
    try {
      const response = await props?.getMostCommnDayOfInjury(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };
  const columns = [
    {
      id: "reportId",
      label: `Report Id`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "caseId",
      label: `Case Id`,
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "createdOn",
      label: `Date Of Entry`,
      sortable: true,
      isSorted: sortColumn === "createdOn",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "assetName",
      label: `Asset Name`,
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "userInjured",
      label: `User Injured`,
      sortable: true,
      isSorted: sortColumn === "userInjured",
      sortDirection,
    },
    {
      id: "enterByName",
      label: `Entered By`,
      sortable: true,
      isSorted: sortColumn === "enterByName",
      sortDirection,
    },

    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "blank1",
      label: "",
    },
  ];
  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
        apiData: props?.drillDetailFull,
        backRoute: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
        incidentURL: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
        backRouteText: "Back to Reports & Analytics",
      },
    });
  }
  const handleViewOnClick = (rows) => {
    if (
      rows?.incTypeId === 2 ||
      rows?.incTypeId === 1 ||
      rows?.incTypeId === 3 ||
      rows?.incTypeId === 4 ||
      rows?.incTypeId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incTypeId
      );
    }
  };

  const data1 = props?.drillDetailFull?.trendPassData || [];

  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 7;

  const getPaginatedData = () => {
    const start = currentPage * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return data1?.slice(start, end);
  };
  const paginatedData = getPaginatedData();

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if ((currentPage + 1) * ITEMS_PER_PAGE < data1.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };
  const handleBarClick = (evt, label) => {
    setBarDate(label);
  };
  const optionsDay = {
    indexAxis: "x",
    onClick: function (evt, element) {
      if (element.length > 0) {
        const elementIndex = element[0].index;
        const clickedLabel = IncidentDay.labels[elementIndex];
        handleBarClick(evt, clickedLabel);
      }
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
  };
  const IncidentDay = {
    labels: paginatedData?.map((item) => getDayNameAndDate(item?.date)),
    datasets: [
      {
        label: "Number of Injuries",
        data: paginatedData?.map((item) => item?.numberOfInjuries),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };
  const rows = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>
      <td className="inc_row_dateOfEntry">{asset?.createdOn}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.userInjured}</td>
      <td>{asset?.enterByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  const rowsPdf = data?.map((item) => [
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.createdOn),
    item?.assetName,
    item?.userInjured,
    item?.enterByName,
    item?.status,
  ]);
  // useEffect(() => {
  //   setData([]);
  //   if (barDate) {
  //     dispatch(
  //       getIncMostCommonDayOfInjury({
  //         page: 1,
  //         pageSize: PAGE_SIZE_100,
  //         fromDate: barDate && getFormatedDate(barDate),
  //         toDate: barDate && getFormatedDate(barDate),
  //         divisionId: props?.drillDetail?.divisionId,
  //         locationId: props?.drillDetail?.locationId,
  //       })
  //     );
  //   }
  // }, [barDate]);
  return (
    <div>
      {" "}
      <>
        {incReportMostCommonDayOfInjuryData?.isLoading && <Loader />}
        <Grid container className="overviewIns">
          <Grid item md={12} xs={12}>
            {/* <Grid className="commonBoxShodow">
              <h2 className="titletop">Most common day of the week</h2>
              <div className="date-range">
                Date Range{" "}
                <span>
                  {paginatedData ? paginatedData[0]?.date : ""} {"-"}{" "}
                  {paginatedData ? paginatedData[6]?.date : ""}
                </span>
              </div>
              <BarChart
                labels={IncidentDay?.labels}
                datasets={IncidentDay?.datasets}
                options={optionsDay}
                height="auto"
              />
              <div className="pagination-controls">
                <Buttons
                  label={"Previous"}
                  varientContained={true}
                  onClick={handlePrevious}
                  disabled={currentPage === 0}
                />

                <Buttons
                  label={"Next"}
                  varientContained={true}
                  onClick={handleNext}
                  disabled={(currentPage + 1) * ITEMS_PER_PAGE >= data1?.length}
                />
              </div>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid mt={4}>
          <div className="topHeaderDrill">
            <div className="topHeaderDrillLeft">
              <div className="title">
                {props?.drillDetail?.Day === 1
                  ? "Monday"
                  : props?.drillDetail?.Day === 2
                  ? "Tuesday"
                  : props?.drillDetail?.Day === 3
                  ? "Wednesday"
                  : props?.drillDetail?.Day === 4
                  ? "Thursday"
                  : props?.drillDetail?.Day === 5
                  ? "Friday"
                  : props?.drillDetail?.Day === 6
                  ? "Saturday"
                  : props?.drillDetail?.Day === 0
                  ? "Sunday"
                  : ""}
              </div>
            </div>
            <div className="topHeaderDrillRight">
              <div
                className="downloadBox"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <div className="downloadIcon">
                  <img src={commonImages?.downloadWhite} alt="Download" />
                </div>
                <div className="downloadText">Download Data</div>
              </div>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="downloadListBox">
                  <div className="downloadList">
                    <div className="downloads">
                      <div
                        className="downloadListIcon"
                        onClick={() =>
                          downloadPDF(
                            rowsPdf,
                            columns?.map((col) => col.label),
                            "most-common-day-for-injury.pdf"
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M5.5 8.5H6.5V7H7C7.28333 7 7.52083 6.90417 7.7125 6.7125C7.90417 6.52083 8 6.28333 8 6V5.5C8 5.21667 7.90417 4.97917 7.7125 4.7875C7.52083 4.59583 7.28333 4.5 7 4.5H5.5V8.5ZM6.5 6V5.5H7V6H6.5ZM8.5 8.5H10C10.2833 8.5 10.5208 8.40417 10.7125 8.2125C10.9042 8.02083 11 7.78333 11 7.5V5.5C11 5.21667 10.9042 4.97917 10.7125 4.7875C10.5208 4.59583 10.2833 4.5 10 4.5H8.5V8.5ZM9.5 7.5V5.5H10V7.5H9.5ZM11.5 8.5H12.5V7H13.5V6H12.5V5.5H13.5V4.5H11.5V8.5ZM4.5 13C4.0875 13 3.73438 12.8531 3.44062 12.5594C3.14687 12.2656 3 11.9125 3 11.5V1.5C3 1.0875 3.14687 0.734376 3.44062 0.440626C3.73438 0.146876 4.0875 0 4.5 0H14.5C14.9125 0 15.2656 0.146876 15.5594 0.440626C15.8531 0.734376 16 1.0875 16 1.5V11.5C16 11.9125 15.8531 12.2656 15.5594 12.5594C15.2656 12.8531 14.9125 13 14.5 13H4.5ZM1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3H1.5V14.5H13V16H1.5Z"
                            fill="none"
                          />
                        </svg>
                        <span>Download PDF</span>
                      </div>
                    </div>
                  </div>

                  <div className="downloadList">
                    <div className="downloads">
                      <div
                        className="downloadListIcon"
                        onClick={() =>
                          downloadExcel(data, "most-common-day-for-injury.xlsx")
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_6635_4490)">
                            <path
                              d="M9.22 0.0100392L0.26 1.71004C0.10875 1.73879 0 1.87629 0 2.03004V13.97C0 14.1238 0.10875 14.2613 0.26 14.29L9.22 15.99C9.24 15.9938 9.26 16 9.28 16C9.35375 16 9.4225 15.9775 9.48 15.93C9.55375 15.8688 9.6 15.775 9.6 15.68V0.320039C9.6 0.225039 9.55375 0.131289 9.48 0.0700392C9.40625 0.0087892 9.31375 -0.00746081 9.22 0.0100392ZM10.24 1.92004V4.16004H10.88V4.80004H10.24V6.40004H10.88V7.04004H10.24V8.64004H10.88V9.28004H10.24V11.2H10.88V11.84H10.24V14.08H15.04C15.3925 14.08 15.68 13.7925 15.68 13.44V2.56004C15.68 2.20754 15.3925 1.92004 15.04 1.92004H10.24ZM11.52 4.16004H14.08V4.80004H11.52V4.16004ZM2.14 5.02004H3.78L4.64 6.81004C4.7075 6.95129 4.7675 7.12504 4.82 7.32004H4.83C4.86375 7.20379 4.92875 7.02129 5.02 6.79004L5.97 5.02004H7.47L5.68 7.98004L7.52 11H5.93L4.89 9.05004C4.85125 8.97754 4.81125 8.84379 4.77 8.65004H4.76C4.74 8.74129 4.6925 8.88379 4.62 9.07004L3.58 11H1.98L3.89 8.01004L2.14 5.02004ZM11.52 6.40004H14.08V7.04004H11.52V6.40004ZM11.52 8.64004H14.08V9.28004H11.52V8.64004ZM11.52 11.2H14.08V11.84H11.52V11.2Z"
                              fill="none"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6635_4490">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Download.xlsx</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="mostCommon">
            <div className="customTableWrapper">
              <Table
                columns={columns}
                rows={rows}
                tableRef={tableRef}
                onSort={handleSort}
                customcls={"mostcmnday"}
              />
            </div>
          </div>
        </Grid>
      </>
    </div>
  );
}

export default MostCommonDayofweek;
