import React, { useEffect, useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import "./adminTicketContainer.scss";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "../component/ticket/ticket";
import FilterList from "../common/filterList";
import * as supportAction from "../../../redux/actions/supportAction";
import Loader from "../../../library/common/Loader";
import FilterButton from "../../../library/custom/filterButton/filterButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  ADMIN_TICKET_DETAILS,
  CREATE_TICKET,
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AdminTicketContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const tab = location?.state?.tab;
  const priority = location?.state?.priority;
  const type = location?.state?.type;
  const dashboardTickets = location?.state?.dashboardTickets;
  const [getAdminDeskData, setGetAdminDeskData] = useState({
    isAssigned: tab === 2 ? false : "",
    ticketTypes: type ? type : type === 0 ? 0 : "",
    priorityIds: priority ? priority : priority === 0 ? 0 : "",
    search: "",
  });

  // getTicketsAdminDesk
  const tickets = useSelector(
    (state) => state.getTicketsAdminDesk?.data?.ticketList
  );
  const [ticketsState, setTicketsState] = useState(null);
  useEffect(() => {
    setTicketsState(tickets);
  }, [tickets]);
  useEffect(() => {
    dispatch(supportAction.getTicketsAdminDesk(getAdminDeskData));
  }, [getAdminDeskData]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [priorityState, setpriorityState] = useState({
    low: priority === 3 ? true : false,
    medium: priority === 2 ? true : false,
    high: priority === 1 ? true : false,
  });
  const [statusState, setStatusState] = useState({
    openT: type === 0 ? true : false,
    closedT: type === 1 ? true : false,
    inProgress: type === 2 ? true : false,
  });

  const handleChange = (event) => {
    const newPriorityState = {
      ...priorityState,
      [event.target.name]: event.target.checked,
    };

    setpriorityState(newPriorityState);
    const arrPri = [];
    if (newPriorityState.high) {
      arrPri.push(1);
    }
    if (newPriorityState.medium) {
      arrPri.push(2);
    }
    if (newPriorityState.low) {
      arrPri.push(3);
    }
    const priorityIds = arrPri.join(",");
    setGetAdminDeskData({
      ...getAdminDeskData,
      priorityIds: priorityIds,
    });

    const newStatusState = {
      ...statusState,
      [event.target.name]: event.target.checked,
    };
    setStatusState(newStatusState);
    const statusArr = [];
    if (newStatusState.openT) {
      statusArr.push(0);
    }
    if (newStatusState.closedT) {
      statusArr.push(1);
    }
    if (newPriorityState.inProgress) {
      statusArr.push(2);
    }
    const ticketTypes = statusArr.join(",");
    setGetAdminDeskData({
      ...getAdminDeskData,
      ticketTypes: ticketTypes,
      priorityIds: priorityIds,
    });
  };

  const [tabValue, setTabValue] = React.useState(tab);

  function handleTabsChange(e, newTabValue) {
    setTicketsState(null);
    setTabValue(newTabValue);

    if (newTabValue === 0) {
      setGetAdminDeskData({
        // ...getAdminDeskData,
        isAssigned: "",
        ticketTypes: "",
        priorityIds: "",
        search: "",
      });
    }
    if (newTabValue === 1) {
      setGetAdminDeskData({
        // ...getAdminDeskData,
        isAssigned: true,
        ticketTypes: "",
        priorityIds: "",
        search: "",
      });
    }
    if (newTabValue === 2) {
      setGetAdminDeskData({
        // ...getAdminDeskData,
        isAssigned: false,
        ticketTypes: "",
        priorityIds: "",
        search: "",
      });
    }
    setpriorityState({
      low: false,
      medium: false,
      high: false,
    });
    setStatusState({
      openT: false,
      closedT: false,
      inProgress: false,
    });
  }
  const replyTicketSatus = useSelector((state) => state.replyTicket.status);

  useEffect(() => {
    if (replyTicketSatus === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketDetails.ticketId));
  }, [replyTicketSatus]);

  const ticketDetails = useSelector(
    (state) => state.getTicketDetails?.data?.ticketDetails
  );

  const postUserAssignData = useSelector((state) => state.postUserAssignTicket);
  const getTicketsAdminDeskData = useSelector(
    (state) => state.getTicketsAdminDesk
  );
  const isLoading =
    postUserAssignData?.isLoading || getTicketsAdminDeskData.isLoading;

  // useEffect(() => {
  //   dispatch(supportAction.getArticleCatSubcategory(""));
  // }, []);

  return (
    <div className="overrideAdminTicketContainer">
      {isLoading && <Loader />}
      {checkPrivileges([7, 8, 12]) ? (
        <div className="rightDiv">
          <div className="containerTkt">
            <div className="mainContainer">
              <div className="headerDiv">
                <h1>Support Dashboard</h1>
                <Buttons
                  // startIcon={<AddIcon />}
                  // primary={true}
                  id="btnAddProject"
                  varientAddIconBlue={true}
                  label={"New Ticket"}
                  onClick={() => {
                    navigate(CREATE_TICKET);
                  }}
                />
              </div>

              <Grid mt={2} className="menuDiv">
                <Grid className="leftMenu">
                  <Box>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabsChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        className="tabcustom"
                        icon={
                          <div className="totalV">
                            {dashboardTickets?.totalTickets}
                          </div>
                        }
                        iconPosition="end"
                        label="All Tickets"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="tabcustom"
                        icon={
                          <div className="totalV">
                            {dashboardTickets
                              ? dashboardTickets?.totalTickets -
                                dashboardTickets?.unassignedTickets
                              : ""}
                          </div>
                        }
                        iconPosition="end"
                        label="Assigned"
                        {...a11yProps(1)}
                      />
                      <Tab
                        className="tabcustom"
                        icon={
                          <div className="totalV">
                            {dashboardTickets?.unassignedTickets}
                          </div>
                        }
                        iconPosition="end"
                        label="Unassigned"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                </Grid>
                <Grid className="rightMenu">
                  <div className="searchBar">
                    <SearchBar
                      searchbarInput={getAdminDeskData.search}
                      handleSearch={(e) => {
                        setGetAdminDeskData({
                          ...getAdminDeskData,
                          search: e,
                        });
                      }}
                      placeholder="Search Text"
                    />
                  </div>
                  <div className="filter_btn">
                    <FilterButton handleClick={handleClick} />
                    <FilterList
                      id={id}
                      open={open}
                      handleClose={handleClose}
                      handleChange={handleChange}
                      anchorEl={anchorEl}
                      priorityState={priorityState}
                      statusState={statusState}
                      isAdmin={true}
                    />
                  </div>
                </Grid>
              </Grid>
              {/* {ticketsState ? ( */}
              <div style={{ width: "100%" }}>
                {tickets?.map((ticket, index) => {
                  return (
                    <Grid
                      mt={2}
                      onClick={() => {
                        navigate(ADMIN_TICKET_DETAILS, {
                          state: {
                            ticketId: ticket.ticketId,
                            status: ticket.status,
                            getAdminDeskData: getAdminDeskData,
                          },
                        });
                      }}
                      className="ticketsContainer"
                      key={ticket.ticketId}
                    >
                      <Ticket
                        ticketQuestion={ticket.title}
                        ticketAnswer={ticket.description}
                        priority={ticket.priority}
                        ticketId={ticket.ticketId}
                        category={ticket.category}
                        replyCount={ticket.replyCount}
                        createdDateTime={ticket.createdDateTime}
                        status={ticket.status}
                        assignedUserName={ticket.assignedUserName}
                        createdBy={ticket.createByNameTicket}
                        createdById={ticket.createByIdTicket}
                      />
                    </Grid>
                  );
                })}
              </div>
              {/* // ) : (
            //   <Loader />
            // )} */}
            </div>
          </div>
        </div>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </div>
  );
};

export default AdminTicketContainer;
