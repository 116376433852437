import React from "react";
import { Grid } from "@mui/material";
import "./northCarolina.scss";
import {
  TIME_LIMIT_FOR_GROUND_COVER,
  YES_NO,
} from "../../../../../constant/constant";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../../../library/custom/textBox/InputField";
import Checkbox from "../../../../../library/checkbox/checkbox";
import {
  generateInspectionGroundStabilization,
  generateSwInspectionLotDisturbance,
  generateSwInspectionObservation,
} from "../../../../../utils/swInspectionHelper";
import Button from "../../../../../library/custom/button/Button";
import { getFormatedDate } from "../../../../../utils/helper";
import DatePicker from "../../../../../library/custom/datePicker/DatePicker";

const NorthCarolina = (props) => {
  const date = new Date();
  const initials = `${props?.userInfo?.firstName?.charAt(
    0
  )}${props?.userInfo?.lastName?.charAt(0)}`;
  const twoQuesHandler = (value, insCQ) => {
    const confAns = value;
    props?.setSwInspectionConfQuestionAns((prevState) => {
      const existingQuestion = prevState.find(
        (item) => item.confQuesId === insCQ.key
      );
      if (existingQuestion) {
        return prevState.map((item) =>
          item.confQuesId === insCQ.key ? { ...item, confAns: confAns } : item
        );
      } else {
        return [
          ...prevState,
          {
            confQuesId: insCQ.key,
            confAns: confAns,
          },
        ];
      }
    });
  };

  const rainFallHandler = (value, insRainFallData) => {
    const confRainFallValue = value;

    props?.setSwInspectionRainfallData((prevState) => {
      const existingIndex = prevState.findIndex(
        (item) => item.precipDateFormat === insRainFallData.precipDateFormat
      );
      if (existingIndex !== -1) {
        const updatedData = [...prevState];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          day: insRainFallData?.precipDateFormat,
          rainfallAmt: insRainFallData?.evenTrackPrecipAmt,
          notes: confRainFallValue,
        };
        return updatedData;
      }
    });
  };

  const projectPhaseHandler = (isChecked, value, projectPhaseData) => {
    const projectPhaseValue = value !== null ? value : "";
    const isCheckedValue = isChecked;
    props?.setSwInspectionCurrentProjectPhase((prevState) => {
      const existingIndex = prevState.findIndex(
        (item) =>
          (item.key || item.optionId) ===
          (projectPhaseData.key || projectPhaseData.optionId)
      );

      if (existingIndex !== -1) {
        const updatedData = [...prevState];

        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          optionId: projectPhaseData?.key,
          isChecked: isCheckedValue,
          comment: projectPhaseValue,
        };

        return updatedData;
      }

      return prevState;
    });
  };

  const addMoreInspectionGroundStabilization = () => {
    const newObjects = generateInspectionGroundStabilization(5);
    props?.setSwInspectionGroundStabilization((prevState) => [
      ...prevState,
      ...newObjects,
    ]);
  };

  const groundStabilizationHandler = (
    index,
    area,
    dateVerified,
    timeLimit,
    groundCover,
    inspectionDate,
    describeAction,
    dateCorrecion
  ) => {
    props?.setSwInspectionGroundStabilization((prevState) => {
      const updatedData = [...prevState];
      updatedData[index] = {
        ...updatedData[index],
        area: area,
        dateVerified: dateVerified ? getFormatedDate(dateVerified) : "",
        timeLimit: timeLimit,
        groundCover: groundCover,
        inspectionDate: inspectionDate ? getFormatedDate(inspectionDate) : "",
        describeAction: describeAction,
        dateCorrecion: dateCorrecion ? getFormatedDate(dateCorrecion) : "",
      };

      return updatedData;
    });
  };

  const addMoreSwInspectionObservation = () => {
    const newObjects = generateSwInspectionObservation(5);
    props?.setSwInspectionObservation((prevState) => [
      ...prevState,
      ...newObjects,
    ]);
  };

  const swInspectionObservationHandler = (
    index,
    dischargeOutfall,
    proposedDimension,
    actualDimension,
    significantDeviation,
    anyIncreaseInStream,
    anyVisibleOil,
    anyVisibleErosion,
    anyVisibleSedimentation,
    recommendation
  ) => {
    props?.setSwInspectionObservation((prevState) => {
      const updatedData = [...prevState];

      updatedData[index] = {
        ...updatedData[index],
        dischargeOutfall,
        proposedDimension,
        actualDimension,
        significantDeviation,
        anyIncreaseInStream,
        anyVisibleOil,
        anyVisibleErosion,
        anyVisibleSedimentation,
        recommendation,
      };

      return updatedData;
    });
  };

  const addMoreSwInspectionLotDisturbance = () => {
    const newObjects = generateSwInspectionLotDisturbance(5);
    props?.setSwInspectionLotDisturbance((prevState) => [
      ...prevState,
      ...newObjects,
    ]);
  };

  const swInspectionLotDisturbanceHandler = (
    index,
    lotNumber,
    disturbanceDate,
    erosionControlDate,
    stabilizationDate
  ) => {
    props?.setSwInspectionLotDisturbance((prevState) => {
      const updatedData = [...prevState];

      updatedData[index] = {
        ...updatedData[index],
        lotNumber: lotNumber,
        disturbanceDate: disturbanceDate
          ? getFormatedDate(disturbanceDate)
          : "",
        erosionControlDate: erosionControlDate
          ? getFormatedDate(erosionControlDate)
          : "",
        stabilizationDate: stabilizationDate
          ? getFormatedDate(stabilizationDate)
          : "",
      };

      return updatedData;
    });
  };

  return (
    <>
      <Grid item md={12} className="northCarolinaOverride">
        <div className="twoQuestionBox">
          {props?.getSwReference?.InsConfQuesList?.map((insCQ) => {
            const selectedAnswer = props?.swInspectionConfQuestionAns?.find(
              (item) => item.confQuesId === insCQ.key
            )?.confAns;

            return (
              <div className="twoQuestionList" key={insCQ?.key}>
                <Grid item md={7} xs={12}>
                  <FormControl fullWidth>
                    <FormLabels label={insCQ?.value} isRequired={false} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={YES_NO ?? []}
                      value={selectedAnswer ?? ""}
                      onchangehandler={(e) =>
                        twoQuesHandler(e.target.value, insCQ)
                      }
                    />
                  </FormControl>
                </Grid>
              </div>
            );
          })}
        </div>

        <div className="commonTableBoxNorth">
          <div className="commonTableBoxNorthTitle">7 Day Rainfall Data</div>
          <table>
            <tr className="tableHead">
              <th>Day/Date </th>
              <th>Rain Amount (in)</th>
              <th>Initials</th>
              <th className="fourty">
                Notes:
                <div className="smallText">
                  Daily Rainfall Required. Except for Holidays or Weekends.If no
                  rain, indicate with a “zero”
                </div>
              </th>
            </tr>
            {props?.swRainFallData?.map((rainFall) => {
              const onchangeValue = props?.swInspectionRainfallData.find(
                (item) => item.precipDateFormat === rainFall.precipDateFormat
              )?.notes;

              return (
                <tr className="tableBody" key={rainFall?.precipId}>
                  <td>{rainFall?.precipDateFormat || rainFall.day}</td>
                  <td>{(Math.floor(rainFall?.evenTrackPrecipAmt * 100) / 100)?.toFixed(2)}</td>
                  <td>{initials}</td>
                  <td>
                    <InputField
                      isRequired={false}
                      type="text"
                      placeholder={"Enter Here..."}
                      value={onchangeValue}
                      onChange={(e) =>
                        rainFallHandler(e.target.value, rainFall)
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <div className="commonTableBoxNorth">
          <div className="commonTableBoxNorthTitle">
            Current Phase of Project
          </div>
          <table>
            <tr className="tableHead">
              <th className="sixty">
                Phase of Grading (Construction)
                <div className="smallText">
                  (Place a check in the box of the current project phase)
                </div>
              </th>
              <th>Comments:</th>
            </tr>
            {props?.swInspectionCurrentProjectPhase?.map((projectPhase) => {
              return (
                <tr className="tableBody" key={projectPhase?.key}>
                  <td className="projectPhaseLeft">
                    <div className="left">
                      {projectPhase?.value || projectPhase?.optionName}
                    </div>
                    <div className="right">
                      <Checkbox
                        checked={projectPhase?.isChecked}
                        onChange={(e) =>
                          projectPhaseHandler(
                            e.target.checked,
                            projectPhase?.comment,
                            projectPhase
                          )
                        }
                        label={""}
                      />
                    </div>
                  </td>
                  <td>
                    <InputField
                      isRequired={false}
                      type="text"
                      placeholder={"Enter Here..."}
                      value={projectPhase.comment || ""}
                      onChange={(e) =>
                        projectPhaseHandler(
                          projectPhase?.isChecked,
                          e.target.value,
                          projectPhase
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <div className="commonTableBoxNorth">
          <div className="commonTableBoxNorthTitle">
            Ground Stabilization:Must be recorded after each phase of grading
          </div>
          <div className="tableWrapper">
            <table>
              <tr className="tableHead">
                <th>
                  Area where temporary or permanant ground cover is required
                </th>
                <th>Date verified land disturbance stopped or completed</th>
                <th>Time Limit for Ground Cover 7 days or 14 days</th>
                <th>Ground Cover Sufficient to Restrain Erosion?(Y/N)</th>
                <th>Inspection Date</th>
                <th>Describe Actions Needed</th>
                <th>Date Corrections Verified</th>
              </tr>

              {props?.swInspectionGroundStabilization?.map(
                (groundStabilization, index) => {
                  return (
                    <tr className="tableBody" key={groundStabilization?.id}>
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={groundStabilization?.area || ""}
                          onChange={(e) =>
                            groundStabilizationHandler(
                              index,
                              e?.target?.value,
                              groundStabilization?.dateVerified,
                              groundStabilization?.timeLimit,
                              groundStabilization?.groundCover,
                              groundStabilization?.inspectionDate,
                              groundStabilization?.describeAction,
                              groundStabilization?.dateCorrecion,
                              groundStabilization?.id
                            )
                          }
                        />
                      </td>
                      <td>
                        <DatePicker
                          onChangeHandler={(e) =>
                            groundStabilizationHandler(
                              index,
                              groundStabilization?.area,
                              e,
                              groundStabilization?.timeLimit,
                              groundStabilization?.groundCover,
                              groundStabilization?.inspectionDate,
                              groundStabilization?.describeAction,
                              groundStabilization?.dateCorrecion,
                              groundStabilization?.id
                            )
                          }
                          value={groundStabilization?.dateVerified ?? ""}
                          maxDateEnable={false}
                        />
                      </td>
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={TIME_LIMIT_FOR_GROUND_COVER ?? []}
                            value={groundStabilization?.timeLimit}
                            onchangehandler={(e) =>
                              groundStabilizationHandler(
                                index,
                                groundStabilization?.area,
                                groundStabilization?.dateVerified,
                                e?.target?.value,
                                groundStabilization?.groundCover,
                                groundStabilization?.inspectionDate,
                                groundStabilization?.describeAction,
                                groundStabilization?.dateCorrecion,
                                groundStabilization?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={YES_NO ?? []}
                            value={groundStabilization?.groundCover}
                            onchangehandler={(e) =>
                              groundStabilizationHandler(
                                index,
                                groundStabilization?.area,
                                groundStabilization?.dateVerified,
                                groundStabilization?.timeLimit,
                                e?.target?.value,
                                groundStabilization?.inspectionDate,
                                groundStabilization?.describeAction,
                                groundStabilization?.dateCorrecion,
                                groundStabilization?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <DatePicker
                          onChangeHandler={(e) =>
                            groundStabilizationHandler(
                              index,
                              groundStabilization?.area,
                              groundStabilization?.dateVerified,
                              groundStabilization?.timeLimit,
                              groundStabilization?.groundCover,
                              e,
                              groundStabilization?.describeAction,
                              groundStabilization?.dateCorrecion,
                              groundStabilization?.id
                            )
                          }
                          value={groundStabilization?.inspectionDate ?? ""}
                          maxDateEnable={false}
                        />
                      </td>
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={groundStabilization?.describeAction || ""}
                          onChange={(e) =>
                            groundStabilizationHandler(
                              index,
                              groundStabilization?.area,
                              groundStabilization?.dateVerified,
                              groundStabilization?.timeLimit,
                              groundStabilization?.groundCover,
                              groundStabilization?.inspectionDate,
                              e?.target?.value,
                              groundStabilization?.dateCorrecion,
                              groundStabilization?.id
                            )
                          }
                        />
                      </td>
                      <td>
                        <DatePicker
                          onChangeHandler={(e) =>
                            groundStabilizationHandler(
                              index,
                              groundStabilization?.area,
                              groundStabilization?.dateVerified,
                              groundStabilization?.timeLimit,
                              groundStabilization?.groundCover,
                              groundStabilization?.inspectionDate,
                              groundStabilization?.describeAction,
                              e,
                              groundStabilization?.id
                            )
                          }
                          value={groundStabilization?.dateCorrecion ?? ""}
                          maxDateEnable={false}
                        />
                      </td>
                    </tr>
                  );
                }
              )}
            </table>
          </div>
          <Grid container mt={2}>
            <Button
              varientTextGreen={true}
              label={"Add More Rows"}
              onClick={addMoreInspectionGroundStabilization}
            />
          </Grid>
        </div>

        <div className="commonTableBoxNorth">
          <div className="commonTableBoxNorthTitle">
            OBSERVATIONS OF RUNNOFF AT STORMWATER DISCHARGE OUTFALLS: DETAILS OF
            RECOMMENDED CORRECTIVE ACTIONS
          </div>
          <div className="tableWrapper">
            <table>
              <tr className="tableHead">
                <th>Stormwater Discharge Outfall Identification</th>
                <th>Proposed Dimensions </th>
                <th>Actual Dimensions (ft)</th>
                <th>Significant deviation from plan?</th>
                <th>Any Increase in Stream Turbidity from Discharge?</th>
                <th>
                  Any visible oil sheen, floating or suspended solids or
                  discoloration?
                </th>
                <th>Any Visible Erosion below SDO?</th>
                <th>
                  Any Visible Sedimentation in Streams, Wetlands or Outside Site
                  Limits?
                </th>
                <th>Additional Recommendations</th>
              </tr>

              {props?.swInspectionObservation?.map(
                (inspectionObservation, index) => {
                  return (
                    <tr className="tableBody" key={inspectionObservation?.id}>
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={inspectionObservation?.dischargeOutfall || ""}
                          onChange={(e) =>
                            swInspectionObservationHandler(
                              index,
                              e?.target?.value,
                              inspectionObservation?.proposedDimension,
                              inspectionObservation?.actualDimension,
                              inspectionObservation?.significantDeviation,
                              inspectionObservation?.anyIncreaseInStream,
                              inspectionObservation?.anyVisibleOil,
                              inspectionObservation?.anyVisibleErosion,
                              inspectionObservation?.anyVisibleSedimentation,
                              inspectionObservation?.recommendation,
                              inspectionObservation?.id
                            )
                          }
                        />
                      </td>
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={inspectionObservation?.proposedDimension || ""}
                          onChange={(e) =>
                            swInspectionObservationHandler(
                              index,
                              inspectionObservation?.dischargeOutfall,
                              e?.target?.value,
                              inspectionObservation?.actualDimension,
                              inspectionObservation?.significantDeviation,
                              inspectionObservation?.anyIncreaseInStream,
                              inspectionObservation?.anyVisibleOil,
                              inspectionObservation?.anyVisibleErosion,
                              inspectionObservation?.anyVisibleSedimentation,
                              inspectionObservation?.recommendation,
                              inspectionObservation?.id
                            )
                          }
                        />
                      </td>
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={inspectionObservation?.actualDimension || ""}
                          onChange={(e) =>
                            swInspectionObservationHandler(
                              index,
                              inspectionObservation?.dischargeOutfall,
                              inspectionObservation?.proposedDimension,
                              e?.target?.value,
                              inspectionObservation?.significantDeviation,
                              inspectionObservation?.anyIncreaseInStream,
                              inspectionObservation?.anyVisibleOil,
                              inspectionObservation?.anyVisibleErosion,
                              inspectionObservation?.anyVisibleSedimentation,
                              inspectionObservation?.recommendation,
                              inspectionObservation?.id
                            )
                          }
                        />
                      </td>

                      {/* Similarly update other SelectMenu handlers with index */}
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={YES_NO ?? []}
                            value={inspectionObservation?.significantDeviation}
                            onchangehandler={(e) =>
                              swInspectionObservationHandler(
                                index,
                                inspectionObservation?.dischargeOutfall,
                                inspectionObservation?.proposedDimension,
                                inspectionObservation?.actualDimension,
                                e?.target?.value,
                                inspectionObservation?.anyIncreaseInStream,
                                inspectionObservation?.anyVisibleOil,
                                inspectionObservation?.anyVisibleErosion,
                                inspectionObservation?.anyVisibleSedimentation,
                                inspectionObservation?.recommendation,
                                inspectionObservation?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={YES_NO ?? []}
                            value={inspectionObservation?.anyIncreaseInStream}
                            onchangehandler={(e) =>
                              swInspectionObservationHandler(
                                index,
                                inspectionObservation?.dischargeOutfall,
                                inspectionObservation?.proposedDimension,
                                inspectionObservation?.actualDimension,
                                inspectionObservation?.significantDeviation,
                                e?.target?.value,
                                inspectionObservation?.anyVisibleOil,
                                inspectionObservation?.anyVisibleErosion,
                                inspectionObservation?.anyVisibleSedimentation,
                                inspectionObservation?.recommendation,
                                inspectionObservation?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={YES_NO ?? []}
                            value={inspectionObservation?.anyVisibleOil}
                            onchangehandler={(e) =>
                              swInspectionObservationHandler(
                                index,
                                inspectionObservation?.dischargeOutfall,
                                inspectionObservation?.proposedDimension,
                                inspectionObservation?.actualDimension,
                                inspectionObservation?.significantDeviation,
                                inspectionObservation?.anyIncreaseInStream,
                                e?.target?.value,
                                inspectionObservation?.anyVisibleErosion,
                                inspectionObservation?.anyVisibleSedimentation,
                                inspectionObservation?.recommendation,
                                inspectionObservation?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={YES_NO ?? []}
                            value={inspectionObservation?.anyVisibleErosion}
                            onchangehandler={(e) =>
                              swInspectionObservationHandler(
                                index,
                                inspectionObservation?.dischargeOutfall,
                                inspectionObservation?.proposedDimension,
                                inspectionObservation?.actualDimension,
                                inspectionObservation?.significantDeviation,
                                inspectionObservation?.anyIncreaseInStream,
                                inspectionObservation?.anyVisibleOil,
                                e?.target?.value,
                                inspectionObservation?.anyVisibleSedimentation,
                                inspectionObservation?.recommendation,
                                inspectionObservation?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <FormControl fullWidth>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={YES_NO ?? []}
                            value={
                              inspectionObservation?.anyVisibleSedimentation
                            }
                            onchangehandler={(e) =>
                              swInspectionObservationHandler(
                                index,
                                inspectionObservation?.dischargeOutfall,
                                inspectionObservation?.proposedDimension,
                                inspectionObservation?.actualDimension,
                                inspectionObservation?.significantDeviation,
                                inspectionObservation?.anyIncreaseInStream,
                                inspectionObservation?.anyVisibleOil,
                                inspectionObservation?.anyVisibleErosion,
                                e?.target?.value,
                                inspectionObservation?.recommendation,
                                inspectionObservation?.id
                              )
                            }
                          />
                        </FormControl>
                      </td>
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={inspectionObservation?.recommendation || ""}
                          onChange={(e) =>
                            swInspectionObservationHandler(
                              index,
                              inspectionObservation?.dischargeOutfall,
                              inspectionObservation?.proposedDimension,
                              inspectionObservation?.actualDimension,
                              inspectionObservation?.significantDeviation,
                              inspectionObservation?.anyIncreaseInStream,
                              inspectionObservation?.anyVisibleOil,
                              inspectionObservation?.anyVisibleErosion,
                              inspectionObservation?.anyVisibleSedimentation,
                              e?.target?.value,
                              inspectionObservation?.id
                            )
                          }
                        />
                      </td>
                    </tr>
                  );
                }
              )}
            </table>
          </div>
          <Grid container mt={2}>
            <Button
              varientTextGreen={true}
              label={"Add More Rows"}
              onClick={addMoreSwInspectionObservation}
            />
          </Grid>
        </div>

        <div className="commonTableBoxNorth">
          <div className="commonTableBoxNorthTitle">
            INDIVIDUAL LOT DISTURBANCE / STABILIZATION DATA LOG
          </div>
          <div className="tableWrapper">
            <table>
              <tr className="tableHead">
                <th>Lot Number</th>
                <th>Date the lot disturbance is verified</th>
                <th>Date erosion control installations are verified</th>
                <th>Date lot permanent stabilization verified</th>
              </tr>

              {props?.swInspectionLotDisturbance?.map(
                (inspectionLotDisturbance, index) => {
                  return (
                    <tr
                      className="tableBody"
                      key={inspectionLotDisturbance?.id}
                    >
                      <td>
                        <InputField
                          isRequired={false}
                          type="text"
                          placeholder={"Enter Here..."}
                          value={inspectionLotDisturbance?.lotNumber || ""}
                          onChange={(e) =>
                            swInspectionLotDisturbanceHandler(
                              index,
                              e?.target?.value,
                              inspectionLotDisturbance?.disturbanceDate,
                              inspectionLotDisturbance?.erosionControlDate,
                              inspectionLotDisturbance?.stabilizationDate
                            )
                          }
                        />
                      </td>
                      <td>
                        <DatePicker
                          onChangeHandler={(e) =>
                            swInspectionLotDisturbanceHandler(
                              index,
                              inspectionLotDisturbance?.lotNumber,
                              e,
                              inspectionLotDisturbance?.erosionControlDate,
                              inspectionLotDisturbance?.stabilizationDate
                            )
                          }
                          value={
                            inspectionLotDisturbance?.disturbanceDate || ""
                          }
                          maxDateEnable={false}
                        />
                      </td>
                      <td>
                        <DatePicker
                          onChangeHandler={(e) =>
                            swInspectionLotDisturbanceHandler(
                              index,
                              inspectionLotDisturbance?.lotNumber,
                              inspectionLotDisturbance?.disturbanceDate,
                              e,
                              inspectionLotDisturbance?.stabilizationDate
                            )
                          }
                          value={
                            inspectionLotDisturbance?.erosionControlDate || ""
                          }
                          maxDateEnable={false}
                        />
                      </td>
                      <td>
                        <DatePicker
                          onChangeHandler={(e) =>
                            swInspectionLotDisturbanceHandler(
                              index,
                              inspectionLotDisturbance?.lotNumber,
                              inspectionLotDisturbance?.disturbanceDate,
                              inspectionLotDisturbance?.erosionControlDate,
                              e
                            )
                          }
                          value={
                            inspectionLotDisturbance?.stabilizationDate || ""
                          }
                          maxDateEnable={false}
                        />
                      </td>
                    </tr>
                  );
                }
              )}
            </table>
          </div>
          <Grid container mt={2}>
            <Button
              varientTextGreen={true}
              label={"Add More Rows"}
              onClick={addMoreSwInspectionLotDisturbance}
            />
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default NorthCarolina;
