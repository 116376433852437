import React from "react";
import "./Card.scss";

export default function Card({
  cardData,
  cardClickHandler,
  staticCardData = false,
  selectedVehicleAccident,
}) {

  return (
    <div className="blueCardBody">
      {cardData.map((data) => {
        return staticCardData ? (
          <div
            key={data.key}
            onClick={() => cardClickHandler(data)}
            className={
              selectedVehicleAccident?.value === data?.value ||
              parseInt(selectedVehicleAccident?.value) === parseInt(data?.key)
                ? "staticDataCard borderGreen"
                : "staticDataCard"
            }
          >
            <div className="staticCardIcon">
              <img src={data.img || data.image} alt="icons" />
            </div>
            <div className="staticCardContent">
              <h5>{data.value}</h5>
            </div>
          </div>
        ) : (
          <div
            key={data.name}
            className={data.isSelected ? "selectedCard" : "defaultCard"}
            onClick={() => cardClickHandler(data)}
          >
            {data.value}
          </div>
        );
      })}
    </div>
  );
}
