import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BackBtn from "../../../../../library/custom/backBtn/backBtn";
import Buttons from "../../../../../library/custom/button/Button";
import verified from "../../../../../assets/admin/userManagement/verified.svg";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useEffect } from "react";
import DialogBox from "../../../../../library/common/ConfirmBox";
import { ISwitch } from "../../../../../constant/constant";
import { convertUTCtoLocalTimeZone } from "../../../../../utils/helper";

function ContractorConfigurationView({
  dataBack,
  getContractorConfigurationDetsilsData,
  updateOnboardedConfiguration,
  onboardUserId,
  updateContractorOnboardStatus,
}) {
  const data = getContractorConfigurationDetsilsData;

  const [checked, setChecked] = useState(false);
  const [isStatusId, setIsStatusId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const handleChange = (event) => {
    setOpenModal(true);
    setSelectedRow(onboardUserId);
  };

  const deletehandle = () => {
    setOpenModal(false);
    const userEnableDisableData = {
      userid: onboardUserId,
      isEnable: !checked,
    };
    setChecked(!checked);

    updateContractorOnboardStatus(userEnableDisableData);
  };

  const handleClickGrantAccess = () => {
    const accessData = {
      contractorIds: [onboardUserId],
    };
    updateOnboardedConfiguration(accessData);
  };
  useEffect(() => {
    if (data?.status === 4 || data?.status === 7) {
      setChecked(true);
    }
  }, [data]);
  return (
    <>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>

      <div className="viewOverideContact">
        <div className="commonOveride">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={checked ? "Confirm Disable" : "Confirm Enable"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={
                checked
                  ? "Are you sure you want to Disable this Contractor?"
                  : "Are you sure you want to Enable this Contractor?"
              }
              view
            />
          )}
          <Grid container justifyContent="flex-start" alignItems="baseline">
            <Typography className="titleDetail">
              {data?.contractorName}
            </Typography>
            <Grid item lg={7}>
              <Box display="flex" justifyContent="flex-end">
                <Buttons
                  disabled={
                    data?.status === 4 ||
                    data?.status === 6 ||
                    data?.status === 7
                  }
                  id="btnAddUsers"
                  varientContained={true}
                  label={"Grant Access"}
                  onClick={(e) => handleClickGrantAccess()}
                />
              </Box>
            </Grid>
          </Grid>
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Box className="columns">
                  <Typography className="listView">
                    Sub Contractor Type:
                    <span className="font-w400">{data?.contractorType}</span>
                  </Typography>
                  <Typography className="listView">
                    Grade:
                    <span className="font-w400">{data?.grade}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Division:
                    <span className="font-w400">
                      {data?.division?.map((item) => item?.value).join(", ")}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    First Name:
                    <span className="font-w400">{data?.pocFirstName}</span>
                  </Typography>
                  <Typography className="listView">
                    Last Name:
                    <span className="font-w400">{data?.pocLastName}</span>
                  </Typography>
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    Email ID:
                    <span className="font-w400">{data?.pocEmail}</span>
                  </Typography>
                  {data?.isEmailVerified && (
                    <Grid mr={5}>
                      <img src={verified} alt="verifiedlog" />
                    </Grid>
                  )}
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Phone Number:
                    <span className="font-w400">{data?.pocPhone}</span>
                  </Typography>
                  {data?.isPhoneNumberVerified && (
                    <Grid mr={5}>
                      <img src={verified} alt="verifiedlog" />
                    </Grid>
                  )}
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    State:
                    <span className="font-w400">{data?.state}</span>
                  </Typography>
                  <Typography className="listView">
                    City:
                    <span className="font-w400">{data?.pocCity}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Zip Code:
                    <span className="font-w400">{data?.pocZipCode}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Notes:
                    <span className="font-w400">{data?.pocNotes}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Created On:
                    <span className="font-w400">{convertUTCtoLocalTimeZone(data?.createdOn)}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Updated On:
                    <span className="font-w400">{data?.updatedOn}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Configuration Status:
                    <span className="font-w400">
                      {data?.status === 7
                        ? "Contractor Login Successfully"
                        : data?.status === 6
                          ? "Disable Contractor in Cognito"
                          : data?.status === 0
                            ? "Contractor Onboarded"
                            : data?.statusText}
                    </span>
                  </Typography>
                </Box>
                {(data?.status === 4 ||
                  data?.status === 6 ||
                  data?.status === 7) && (
                    <Box className="columns">
                      <Typography className="listView">
                        Account Status:
                        <span className="font-w400">
                          {
                            <Grid lg={5} xs={12} sm={6} md={4}>
                              <FormControl
                                component="fieldset"
                                variant="standard"
                                className="clientswitch"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <ISwitch
                                        checked={checked}
                                        onChange={handleChange}
                                        name="status"
                                      />
                                    }
                                    label={checked ? "Enabled" : "Disabled"}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          }
                        </span>
                      </Typography>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}

export default ContractorConfigurationView;
