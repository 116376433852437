import * as divisionsAction from "../actions/divisionsAction";
import { divisionsType } from "../constants/divisionsType";
import {
  getDivisionsList,
  createDivisions,
  deleteDivisions,
  getDivisionsReport,
  updateDivisions,
} from "../../services/divisionsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createDivisionsSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createDivisions, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = divisionsType.CREATE_PAGE;
    yield put(
      divisionsAction.createDivisionsSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(divisionsAction.createDivisionsFailure(data));
  }
}

export function* getDivisionsListSaga(params) {
  try {
    const { data, status, error } = yield call(getDivisionsList, params);
    if (!data && status !== 200) throw apiError(error);
    const divisionsPage = divisionsType.CASE_PAGE;
    if (params?.data?.isSendMail) {
      yield put(divisionsAction.getDivisionsListEmailSuccess());
    } else {
      yield put(
        divisionsAction.getDivisionsListSuccess({
          divisionsPage,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get divisions List Failed" };
    yield put(divisionsAction.getDivisionsListFailure(data));
  }
}

export function* divisionsReport({ divisionId }) {
  console.debug("divisionsReport");
  try {
    const { data, status, error } = yield call(getDivisionsReport, divisionId);
    if (!data && status !== 200) throw apiError(error);
    const divisionsReportPage = divisionsType.divisionsReportPage;
    yield put(
      divisionsAction.getDivisionsDetailsSuccess({
        divisionsReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get divisions Report Failed" };
    yield put(divisionsAction.getDivisionsDetailsFailure(data));
  }
}

export function* updateDivisionsSaga(params) {
  console.debug("updateDivisionsSaga");
  try {
    const { data, status, error } = yield call(updateDivisions, params);
    if (!data && status !== 200) throw apiError(error);
    const updatePage = divisionsType.UPDATE_PAGE;
    yield put(
      divisionsAction.updateDivisionsSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Division updation Failed" };
    yield put(divisionsAction.updateDivisionsFailure(data));
  }
}

function* deleteDivisionsSaga(payload) {
  try {
    const { data, status } = yield call(deleteDivisions, payload);
    const casePage = divisionsType.CASE_PAGE;

    yield put(
      divisionsAction.deleteDivisionsSuccess({
        casePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(divisionsAction.deleteDivisionsFailure(data));
  }
}

const myCaseSaga = [
  takeLatest(divisionsType.GET_DIVISION_LIST_PENDING, getDivisionsListSaga),
  takeLatest(divisionsType.POST_DIVISION_PENDING, createDivisionsSaga),
  takeLatest(divisionsType.UPDATE_DIVISION_PENDING, updateDivisionsSaga),
  takeLatest(divisionsType.DELETE_DIVISION_PENDING, deleteDivisionsSaga),
  takeLatest(divisionsType.GET_DIVISION_DETAILS_PENDING, divisionsReport),
];

export default myCaseSaga;
