import React, { useState } from "react";
//import "../../../incidents/cases/addCaseList.scss";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import {
  PAGE_SIZE,
  HBNEXT_ORG_ID,
  ORG_ID,
} from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import {
  DASHBOARD,
  ADMIN_ADD_NEWS,
  NEWSDETAIL,
} from "../../../../constant/routeContant";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import tableImg from "../../../../assets/noPublishImg.svg";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { useNavigate } from "react-router-dom";
import DialogBox from "../../../../library/common/DialogBox";
import "./listNews.scss";
import { checkPrivileges } from "../../../../utils/rolesHelper";

export default function ListsNews({
  getNewsListData,
  newsListData,
  isLoading,
  deleteNews,
}) {
  const dataBack = {
    title: "Back to Dashboard",
    route: DASHBOARD,
  };

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteItem = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const deletehandle = () => {
    setOpenModal(false);
    const data = {
      newsId: selectedRow?.newsId,
    };

    deleteNews(data);
  };

  const handleOnclick = (rows) => {
    navigate(ADMIN_ADD_NEWS, {
      state: { newsId: rows?.row?.newsId, editMode: true },
    });
  };
  const handleOnViewclick = (rows) => {
    navigate(NEWSDETAIL, {
      state: { newsId: rows?.row?.newsId, viewMode: true },
    });
  };

  const columns = [
    { field: "newsId", headerName: "ID", width: 50 },
    { field: "title", headerName: "Title", flex: 1 },

    {
      field: "coverImage",
      headerName: "Cover Image",
      flex: 1,
      renderCell: (cellValues) => {
        let img = cellValues?.row?.coverImage;

        return (
          <>
            <div className="news">
              <div className="nameBox">
                <div className="nameImg">
                  <img src={img ? img : tableImg} alt="" />
                </div>
                {/* <div className="nameBoxpara">{cellValues?.value}</div> */}
              </div>
            </div>
          </>
        );
      },
    },

    { field: "description", headerName: "Description", flex: 2.5 },
    {
      field: "isGlobal",
      headerName: "Global",
      flex: 1,
      renderCell: (cellVALUE) => {
        return (
          <>
            <div>{cellVALUE?.row?.isGlobal ? "Global" : "N/A"}</div>
          </>
        );
      },
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={newsListData?.newsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={newsListData?.news ?? []}
          fileNameXL={"NewsList"}
        />
      ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            usingComp={"Course"}
            deleted={
              (checkPrivileges([12, 87]) && rows?.row?.isGlobal === false) ||
              parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
                ? "deleted"
                : ""
            }
            edit={
              (checkPrivileges([12, 87, 88]) &&
                rows?.row?.isGlobal === false) ||
              parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
                ? "edit"
                : ""
            }
            editOnClick={() => handleOnclick(rows)}
            view
            viewLabel={"View News"}
            editLabel={"Edit News"}
            deleteLabel={"Delete News"}
            deleteOnClick={() => deleteItem(rows?.row)}
            viewOnClick={() => handleOnViewclick(rows)}
          />
        );
      },
    },
  ];
  const [page, setPage] = useState(1);
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getNewsListData({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
    });
  };

  return (
    <>
      <BackBtn dataBack={dataBack} />
      <div className="assignDivtitle"></div>

      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          deletehandle={deletehandle}
          handleCloseModal={() => setOpenModal(false)}
          content={"Are you sure you want to delete this News?"}
          view
        />
      )}
      <div className="desc_box row_uniq">
        <div className="table_style_ui">
          <GridTable
            getRowId={(r) => r?.newsId}
            rows={newsListData?.news ? newsListData?.news : []}
            rowCount={newsListData?.newsCount ?? 0}
            columns={columns}
            loading={isLoading}
            page={page}
            pagination
            pageSize={PAGE_SIZE}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            onPageChange={handlePagignation}
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
            CanUserAddRows="False"
            IsReadOnly="True"
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
          />
        </div>
      </div>
    </>
  );
}
