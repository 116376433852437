import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CreateQuiz from "../component/createQuiz/createQuiz";
import "./createQuizContainer.scss";
import * as quizAction from "../../../redux/actions/quizAction";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { LESSON, ACCESS_DENIED } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

const CreateQuizContainer = () => {
  const location = useLocation();
  const quizId = location?.state?.quizId;
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const createQuiz = useSelector((state) => state.createQuiz);
  const updateQuiz = useSelector((state) => state.updateQuiz);
  const updateQuizstatus = updateQuiz?.status;
  const statuss = createQuiz?.status;
  const isLoading = createQuiz?.isLoading;
  const isLoadingUpdate = updateQuiz?.isLoading;
  const qid = createQuiz?.data?.result?.quizId;
  const [successScreens, setSuccessScreens] = useState(false);
  const QuizDetail = useSelector((state) => state?.getQuizDetailReducer?.data);
  const QuizDetailIsloading = useSelector(
    (state) => state?.getQuizDetailReducer?.isLoading
  );

  const addAndUpdateQuiz = async (data) => {
    setSuccessScreens(true);

    if (quizId > 0) {
      dispatch(quizAction.updateQuizList(data));
    } else {
      dispatch(quizAction.createQuiz(data));
    }
  };

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route={LESSON}
        id={id}
        label2={labelmsg}
        label1="Quiz ID"
        label4="Quiz in "
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11]);

    if (isAllowedDash === false) {
      navigate(ACCESS_DENIED);
    }

    if (quizId) {
      dispatch(quizAction.getQuizDetails(quizId));
    }
  }, [quizId, dispatch]);
  return (
    <>
      {isLoading && <Loader />}
      {isLoadingUpdate && <Loader />}
      {QuizDetailIsloading && <Loader />}
      {statuss == API_STATUS.SUCCESS && successScreens && quizId === 0 ? (
        successScree(qid, "Quiz Created Successfully", "")
      ) : updateQuizstatus === API_STATUS.SUCCESS &&
        quizId > 0 &&
        successScreens ? (
        successScree(quizId, "Quiz Updated Successfully", "has been updated")
      ) : (
        <div className="createQuizContainer">
          <Grid container>
            <Grid item xs={12} className="pageTitle">
              {quizId > 0 ? "Update Quiz" : "Create Quiz"}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              xs={12}
              className="createQuizContainerLeft"
            >
              <CreateQuiz
                onSubmit={addAndUpdateQuiz}
                QuizDetail={QuizDetail}
                quizId={quizId}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default CreateQuizContainer;
