import * as observationAction from "../actions/observationAction";
import { observationType } from "../constants/observationType";
import {
  addObservationService,
  getObservationDetailService,
  getOpenItemListService,
  postUpdateObservationService,
  getListOfObservationService,
} from "../../services/observationService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

// POST add Observation
export function* addObservationSaga(params) {
  try {
    const { data, status, error } = yield call(addObservationService, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      observationAction.observationSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Observation Failed" };
    yield put(observationAction.observationFailure(data));
  }
}

// GET observation details
export function* getObservationDetailSaga({ insObservationId }) {
  try {
    const { data, status, error } = yield call(
      getObservationDetailService,
      insObservationId
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      observationAction.getObservtionDetailsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Observation Failed" };
    yield put(observationAction.getObservtionDetailsFailure(data));
  }
}

export function* getOpenItemListSaga(params) {
  try {
    const { data, status, error } = yield call(getOpenItemListService, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      observationAction.getOpenItemListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Observation Failed" };
    yield put(observationAction.getOpenItemListFailure(data));
  }
}

export function* postUpdateObservationSaga(params) {
  try {
    const { data, status, error } = yield call(
      postUpdateObservationService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      observationAction.getObservationUpdateSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Observation Failed" };
    yield put(observationAction.getObservationUpdateFailure(data));
  }
}

export function* getListOfObservationSaga(params) {
  try {
    const { data, status, error } = yield call(
      getListOfObservationService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    if (params.data.isSendMail) {
      yield put(observationAction.getListOfObservationEmailSuccess());
    } else {
      yield put(
        observationAction.getListOfObservationSuccess({
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Observation Failed" };
    yield put(observationAction.getListOfObservationFailure(data));
  }
}

const myObservationSaga = [
  takeLatest(observationType.POST_OBSERVATION_PENDING, addObservationSaga),
  takeLatest(
    observationType.GET_OBSERVATION_DETAILS_PENDING,
    getObservationDetailSaga
  ),
  takeLatest(observationType.GET_LIST_OPEN_ITEM_PENDING, getOpenItemListSaga),
  takeLatest(
    observationType.POST_UPDATE_OBSERVATION_PENDING,
    postUpdateObservationSaga
  ),
  takeLatest(
    observationType.GET_LIST_OF_OBSERVATION_PENDING,
    getListOfObservationSaga
  ),
];

export default myObservationSaga;
