import { observationType } from "../constants/observationType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "observation",
  isError: false,
  isLoading: false,
};
// POST add Observation Reducer
export const observationReducer = (state = initialState, action) => {
  switch (action.type) {
    case observationType.POST_OBSERVATION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case observationType.POST_OBSERVATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case observationType.POST_OBSERVATION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// get Observation Details Reducers
export const observationDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case observationType.GET_OBSERVATION_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case observationType.GET_OBSERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case observationType.GET_OBSERVATION_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// get Update Observation
export const updateObservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case observationType.POST_UPDATE_OBSERVATION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case observationType.POST_UPDATE_OBSERVATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case observationType.POST_UPDATE_OBSERVATION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// get Open Item List
export const openItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case observationType.GET_LIST_OPEN_ITEM_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case observationType.GET_LIST_OPEN_ITEM_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case observationType.GET_LIST_OPEN_ITEM_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// get List of Observation
export const ListOfObservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case observationType.GET_LIST_OF_OBSERVATION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case observationType.GET_LIST_OF_OBSERVATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case observationType.GET_LIST_OF_OBSERVATION_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case observationType.GET_LIST_OF_OBSERVATION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
