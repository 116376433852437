import React, { useState, useEffect } from "react";
import AddClient from "../component/client/addClient";
import {
  ACCESS_DENIED,
  ADMIN_CLIENT_LIST,
  DASHBOARD,
} from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import * as clientAction from "../../../redux/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ORG_ID } from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { API_STATUS } from "../../../constant/constant";
import {
  notifyError,
  notifyErrorClientContractor,
} from "../../../../src/library/custom/toastMessage/Toast";
import { ToastContainer } from "react-toastify";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";

export default function AddClientContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const cityData = useSelector((state) => state.city);
  const stateData = useSelector((state) => state.state);
  const Organisationid = location?.state?.Organisationid;
  const [setsubmit, setSetsubmit] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const product = userInfo?.product;
  const projectstatus = product?.map((val) => {
    return val?.key;
  });
  const dataBack = {
    title: "Back to Clients",
    route: ADMIN_CLIENT_LIST,
  };

  const showLoader = useSelector(
    (state) =>
      state.createClientData.isLoading ||
      state.getDropdownDataKeyValue.isLoading ||
      state.updateClientData.isLoading ||
      state.getClientDetsilsData.isLoading
  );

  const updateClientData = useSelector((state) => state.updateClientData);
  const createClientData = useSelector((state) => state.createClientData);
  const getClientDetsilsData = useSelector(
    (state) => state.getClientDetsilsData
  );

  const industryType = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.industryTypes
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 19,
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id, message, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={message}
        route={ADMIN_CLIENT_LIST}
        label1="Client ID"
        label4="Client in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const getCity = (stateId) => {
    dispatch(stateCityAction.getCity(stateId));
  };

  useEffect(() => {
    getStateData();
  }, []);

  const addClient = (data) => {
    setIsSuccess(true);
    if (editMode) {
      setSetsubmit(true);
      dispatch(clientAction.updateClient(data));
    } else {
      setSetsubmit(true);
      dispatch(clientAction.createClient(data));
    }
  };

  const getClientLists = () => {
    dispatch(clientAction.getClientList());
  };

  useEffect(() => {
    if (!checkPrivileges([12, 67, 68, 69])) {
      navigate(DASHBOARD);
    }
    dispatch(clientAction.clearClient());
    getClientLists();
  }, []);

  const getClientDetsils = (data) => {
    dispatch(clientAction.getClientDetsils(data));
  };

  useEffect(() => {
    if (editMode && Organisationid) {
      getClientDetsils(Organisationid);
    }
  }, []);

  useEffect(() => {
    if (createClientData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (createClientData?.status === API_STATUS.SUCCESS) {
        if (
          createClientData?.data?.responseMessages?.responseCode == "HBNG006" &&
          setsubmit
        ) {
          notifyErrorClientContractor();
        }
        if (
          createClientData?.data?.responseMessages?.responseCode == "HBNG001" &&
          setsubmit
        ) {
          setIsSuccess(true);
        }
      }
    }
  }, [createClientData]);

  return (
    <>
      {checkPrivileges([12, 67, 68]) ? (
        <>
          {showLoader && <Loader />}
          {createClientData.status === "SUCCESS" &&
          createClientData.data &&
          successScreens &&
          createClientData?.data?.responseMessages?.responseCode ==
            "HBNG001" ? (
            successScreen(
              createClientData?.data?.id,
              "Client Created Successfully"
            )
          ) : updateClientData.status === "SUCCESS" &&
            updateClientData?.data &&
            successScreens ? (
            successScreen(
              updateClientData?.data?.organisationId,
              "Client Updated Successfully",
              "has been Updated"
            )
          ) : (
            <>
              <div>
                <ToastContainer />
              </div>
              <AddClient
                dataBack={dataBack}
                editMode={editMode}
                state={stateData && stateData.data?.getState}
                getCity={getCity}
                city={cityData && cityData.data?.cities}
                addClients={addClient}
                getClientDetsilsData={getClientDetsilsData}
                industryType={industryType}
                Organisationid={Organisationid}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
