import React, { useEffect, useState } from "react";
import AddNewRoleForm from "../component/assignrole/addNewRoleForm";
import { useDispatch, useSelector } from "react-redux";
import * as roleAction from "../../../redux/actions/roleAction ";
import * as userAction from "../../../redux/actions/userAction";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ADMIN_ASSIGN_ROLE,
  DASHBOARD,
} from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ToastContainer, toast } from "react-toastify";

export default function AddNewRoleFormContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const roleId = location?.state?.roleId;
  const isAllowed = checkPrivileges([12, 14, 76]);

  const searchOption = useSelector((state) => state.users.data);

  const roleListData = useSelector((state) => state.getRoleListData);

  const creatRoleReducerData = useSelector(
    (state) => state.creatRoleReducerData
  );

  const updateRoleListReducerData = useSelector(
    (state) => state.updateRoleListReducerData
  );

  const isLoading = roleListData?.isLoading;
  const status =
    creatRoleReducerData.status || updateRoleListReducerData.status;

  const searchUsers = (searchType, searchText) => {
    dispatch(userAction.searchUsers(searchType, searchText));
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [clearAddRole, setClearAddRole] = useState(false);
  const [clearUpdateRole, setClearUpdateRole] = useState(false);
  useEffect(() => {
    setIsSuccess(false);
  }, []);

  const successScreen = (id, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Role Updated Successfully"
            : "Add Role Created Successfully"
        }
        route={ADMIN_ASSIGN_ROLE}
        label1="Add Role ID"
        label4="Add Role in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  const getroleLists = (data) => {
    dispatch(roleAction.getRoleList(data));
  };

  const defaultData = {
    orgId: "",
    search: "",
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    dispatch(roleAction.clearRole());
    getroleLists(defaultData);
  }, []);

  const adddRoles = (data) => {
    setIsSuccess(true);
    if (editMode) {
      dispatch(roleAction.updateRoleList(data));
      setClearUpdateRole(true);
    } else {
      dispatch(roleAction.createRole(data));
      setClearAddRole(true);
    }
  };

  useEffect(() => {
    if (
      creatRoleReducerData?.status === "SUCCESS" &&
      creatRoleReducerData?.isLoading === false &&
      creatRoleReducerData?.data?.responseMessages?.responseCode ===
        "HBNG010" &&
      clearAddRole
    ) {
      toast("Client default role already exist");

      setIsSuccess(false);
    }
  }, [creatRoleReducerData]);

  useEffect(() => {
    if (
      updateRoleListReducerData?.status === "SUCCESS" &&
      updateRoleListReducerData?.isLoading === false &&
      updateRoleListReducerData?.data?.responseMessages?.responseCode ===
        "HBNG010" &&
      clearUpdateRole
    ) {
      toast("Client default role already exist");
      setIsSuccess(false);
    }
  }, [updateRoleListReducerData]);
  const getUserListDetail = () => {
    dispatch(roleAction.getRoleListDetsils(roleId));
  };
  const roleListDetsilsData = useSelector((state) => state.roleListDetsilsData);

  useEffect(() => {
    if (editMode && roleId && roleListData?.status === "SUCCESS") {
      getUserListDetail();
    }
  }, [roleListData]);

  return (
    <>
      {checkPrivileges([12]) ? (
        <>
          {(roleListData.isLoading ||
            roleListDetsilsData.isLoading ||
            updateRoleListReducerData.isLoading ||
            creatRoleReducerData.isLoading) && <Loader />}

          {status === "SUCCESS" && creatRoleReducerData.data && isSuccess ? (
            successScreen(creatRoleReducerData?.data?.id)
          ) : status === "SUCCESS" &&
            updateRoleListReducerData.data &&
            isSuccess ? (
            successScreen(
              updateRoleListReducerData?.data?.id,
              "has been Updated"
            )
          ) : (
            <div className="container_wrapper">
              <AddNewRoleForm
                searchUsers={searchUsers}
                searchOption={searchOption?.users || []}
                roleListData={roleListData?.data}
                isLoading={isLoading}
                adddRoles={adddRoles}
                editMode={editMode}
                roleId={roleId}
                roleListDetsilsData={roleListDetsilsData}
                creatRoleReducerData={creatRoleReducerData}
              />
            </div>
          )}
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
