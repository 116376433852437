import React from "react";
import JhaActivities from "../component/jha/jhaActivities";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useSelector } from "react-redux";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";

const JhaActivitiesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultData = {
    search: "",
    isAll: 0,
    page: 1,
    pageSize: 1000,
  };
  const [formValue, setFormValue] = useState({
    categoryId: "",
    activityName: "",
    isGlobal: false,
    createdBy: parseInt(USER_ID()),
  });

  const categoriesListData = useSelector((state) => state.jhaCategoryList);
  const activitiesListData = useSelector((state) => state.getJhaActivityList);
  const [shwoToast, setshwoToast] = useState(false);
  const getCategoriesListData = (data) => {
    dispatch(jhaAction.getJhaCategoryList(data));
  };
  const getJhaActivityList = (data) => {
    dispatch(jhaAction.getJhaActivityList(data));
  };
  useEffect(() => {
    dispatch(jhaAction.clearJha());
    getCategoriesListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      categoriesListData.status === API_STATUS.SUCCESS &&
      categoriesListData?.isLoading === false
      // clearalert
    ) {
      mapCategoriesKeyValue();
    }
  }, [categoriesListData]);

  // let categoriesList = []
  const [categoriesList, setcategoriesList] = useState([]);
  //  categoriesList = mapCategoriesKeyValue();
  function mapCategoriesKeyValue() {
    let arr = [];
    categoriesListData?.data?.result?.itemList.map((i) =>
      arr.push({
        key: i.id,
        value: i.categoryName,
      })
    );
    setcategoriesList(arr);
  }

  // add Jha Activity
  const addJhaActivityData = useSelector((state) => state?.addJhaActivity);
  function addJhaActivity(data) {
    setshwoToast(true);
    dispatch(jhaAction.addJhaActivity(data));
  }
  useEffect(() => {
    if (
      addJhaActivityData.status === API_STATUS.SUCCESS &&
      addJhaActivityData?.isLoading === false &&
      shwoToast
      // clearalert
    ) {
      toast("Activity Added successfully");
      setshwoToast(false);
      getJhaActivityList({
        categoryId: formValue.categoryId,
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      });
    }
  }, [addJhaActivityData]);
  // edit
  const updateJhaActivityData = useSelector(
    (state) => state?.updateJhaActivity
  );
  function handleEditOnClick(data) {
    setshwoToast(true);
    dispatch(jhaAction.updateJhaActivity(data));
  }

  function handleStatusChange(data) {
    let newData = {
      categoryId: formValue.categoryId,
      activityName: data.activityName,
      isGlobal: false,
      activityId: data.id,
      isActive: data.isActive,
      modifiedBy: parseInt(USER_ID()),
    };
    setshwoToast(true);
    dispatch(jhaAction.updateJhaActivity(newData));
  }

  useEffect(() => {
    if (
      updateJhaActivityData.status === API_STATUS.SUCCESS &&
      updateJhaActivityData?.isLoading === false &&
      shwoToast
      // clearalert
    ) {
      toast("Activity Updated successfully");
      setshwoToast(false);

      getCategoriesListData(defaultData);
      getJhaActivityList({
        categoryId: formValue.categoryId,
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      });
    }
  }, [updateJhaActivityData]);

  const isLoading = useSelector(
    (state) =>
      state.jhaCategoryList?.isLoading ||
      state.addJhaActivityData?.isLoading ||
      state.updateJhaActivity?.isLoading ||
      state.getJhaActivityList?.isLoading
  );

  const showLoader = useSelector(
    (state) =>
      state.addJhaActivityData?.isLoading || state.updateJhaActivity?.isLoading
  );
  return (
    <div>
      {checkPrivileges([12, 58, 59, 60]) ? (
        <>
          {showLoader && <Loader />}
          <ToastContainer />
          <JhaActivities
            categoriesList={categoriesList ?? []}
            activityList={activitiesListData?.data?.result?.itemList ?? []}
            addJhaActivity={addJhaActivity}
            getJhaActivityList={getJhaActivityList}
            formValue={formValue}
            setFormValue={setFormValue}
            handleEditOnClick={handleEditOnClick}
            isLoading={isLoading}
            handleStatusChange={handleStatusChange}
            itemsCount={activitiesListData?.data?.result?.itemsCount}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </div>
  );
};

export default JhaActivitiesContainer;
