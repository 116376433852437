import { ORG_ID, PAGE_SIZE } from "../../constant/constant";
import { getApiUrl } from "../../config";
import { get, post, deletes } from "../axiosRequest";

export const createProject = async ({ data }) => {
  const clientOrg = data?.projectDetails[0]?.client;
  const url = `${getApiUrl().adminSWApiUrl}Project/AddProject`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: clientOrg ? clientOrg : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateProject = async ({ data }) => {
  const clientOrg = data?.client;

  const url = `${getApiUrl().adminSWApiUrl}Project/UpdateProject`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: clientOrg ? clientOrg : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getProjectDetail = async ({ data }) => {
  const { projectId, orgId } = data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Project/GetProjectDetail?projectId=${projectId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const swProjectList = async ({ data }) => {
  const {
    pageSize,
    page,
    search,
    customerId,
    endDate,
    startDate,
    siteId,
    status,
    divisionId = "",
  } = data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Project/GetProjectList?search=${search}&fromDate=${startDate}&toDate=${endDate}&customerId=${customerId}&page=${page}&pageSize=${pageSize}&siteId=${
    siteId ? siteId : ""
  }&divisionId=${divisionId}&statusId=${
    status == "1" ? true : status == "0" ? false : ""
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const postAddContactInProjectService = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Project/AddContactInProject`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getContactInProjectList = async ({ data }) => {
  const { projectId, page, userId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Project/GetContactInProjectList?projectId=${projectId}&userId=${userId}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateProjectContactStatus = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Project/UpdateContactInProject`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const createProjectSampleLog = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}SampleLog/AddSampleLog`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getprojectSample = async ({ data }) => {
  const { page, pageSize, projectId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }SampleLog/GetSampleLogList?page=${page}&pageSize=${pageSize}&projectId=${
    projectId ? projectId : ""
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const editOneContactInProjectService = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Project/EditOneContactInProject`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const deleteContactInProject = async ({ payload }) => {
  const url = `${getApiUrl().adminSWApiUrl}Project/DeleteContactInProject/${
    payload?.id
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// rain fall data service api
export const getRainfallData = async ({ data }) => {
  const { projectId, rainfallType, page, pageSize, OrgId, fromDate, toDate } =
    data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/GetRainfallDataLog?projectId=${projectId}&pageSize=${pageSize}&page=${page}&rainfallType=${rainfallType}&fromDate=${fromDate}&toDate=${toDate}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: OrgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteProject = async (data, AppTypeId, AppDetails) => {
  const projectId = data?.data;

  const url = `${getApiUrl().adminSWApiUrl}Project/DeleteProject/${projectId}`;

  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
        AppTypeId: AppTypeId,
        AppDetails: AppDetails,
      },
    });
  } catch (err) {
    return err;
  }
};
