import React, { useEffect, useState } from "react";
import AssignCredential from "../component/assignCredential";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import * as credentialAction from "../../../redux/actions/credentialAction";
import { API_STATUS } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  SETUP_CREDENTIAL,
  DASHBOARD,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function AssignCredentialConatiner() {
  const [successScreens, setSuccessScreens] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAllowed = checkPrivileges([12, 13, 47, 48]);

  const allUsers = useSelector((state) => state.allUsers);
  const searchUsers = (searchText, type, divisionId) => {
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  };

  const allGroups = useSelector((state) => state.searchAllGroup);

  function handleAllGroupSearch(serachText, divisionId, alldivision) {
    dispatch(userAction.searchAllGroup(serachText, divisionId, alldivision));
  }

  const credentialListData = useSelector(
    (state) => state.getSearchCredentialData
  );
  const isLoading =
    allUsers.isLoading || credentialListData.isLoading || allGroups.isLoading;

  const showLoader = useSelector(
    (state) => state.assignCredentialData.isLoading
  );
  const assignCredentialDataStatus = useSelector(
    (state) => state.assignCredentialData.status
  );
  const assignCredentialData = useSelector(
    (state) => state.assignCredentialData
  );

  const getCredentials = (data) => {
    dispatch(credentialAction.getSearchCredentialList(data));
  };

  const assignCredential = (data) => {
    setSuccessScreens(true);
    dispatch(credentialAction.assignCredential(data));
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(ACCESS_DENIED);
    }
  }, []);

  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate="Credential Assigned Successfully"
        route={SETUP_CREDENTIAL}
        label4="Assign Credential in "
        label1=" "
        label2=" "
      ></SuccessScreen>
    );
  };
  return (
    <>
      {checkPrivileges([12, 13, 47]) ? (
        <>
          {showLoader && <Loader />}
          {assignCredentialDataStatus === API_STATUS.SUCCESS &&
          assignCredentialData &&
          successScreens ? (
            successScreen(assignCredentialData?.data?.id)
          ) : (
            <AssignCredential
              onSearch={searchUsers}
              allUsers={allUsers}
              isLoading={isLoading}
              assignCredential={assignCredential}
              getCredentials={getCredentials}
              credentialListData={credentialListData ?? []}
              handleAllGroupSearch={handleAllGroupSearch}
              userGroups={allGroups?.data}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
