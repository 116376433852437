import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGE_SIZE,
  MODULES_NAME,
  API_STATUS,
  USER_ID,
} from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useState } from "react";
import ToolBoxAssignedUserStatus from "../component/toolBoxAssignUserStatus/toolBoxAssignUserStatus";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ToolboxScheduleUserStatusContainer = () => {
  const location = useLocation();
  const fieldid = location?.state?.fieldId;
  const scheduleId = location?.state?.scheduleId;
  const scheduleDate = location?.state?.scheduleDate;
  const flag = location?.state?.flag;
  const titleSafetyMeeting = location?.state?.title;
  const filterBy = location?.state?.filterBy;
  const Tabvalue = location?.state?.tabvalue;

  const toolboxTalkId = location.state?.toolboxTalkId;
  const toolboxTalkVersionId = location.state?.toolboxTalkVersionId;
  const getScheduleAssignUserStatusData = useSelector(
    (state) => state.getScheduleInstructorDetails
  );

  console.log("location", location);

  const isLoading = getScheduleAssignUserStatusData?.isLoading;
  const dispatch = useDispatch();

  const defaultData = {
    scheduleId: scheduleId,
    scheduleDate: scheduleDate,
    filterBy: filterBy,
    toolboxTalkId: location.state?.toolboxTalkId,
    toolboxTalkVersionId: location.state?.toolboxTalkVersionId,
  };
  let navigate = useNavigate();

  useEffect(() => {
    // if (!isAllowed) {
    //   navigate(DASHBOARD);
    // }
    scheduleUserCountStatusList(defaultData);
  }, []);
  const scheduleUserCountStatusList = async (data) => {
    await dispatch(toolboxAction.getScheduleInstructorDetails(data));
  };
  const [clearAlert, setClearAlert] = useState(false);
  const addSubmitAlert = async (data) => {
    setClearAlert(true);
    dispatch(toolboxAction.getAlertmailSafetyMeeting(data));
  };
  const postSafetyMeetingAlertData = useSelector(
    (state) => state?.postSafetyMeetingAlert
  );
  const isloadingalert = postSafetyMeetingAlertData?.isLoading;
  useEffect(() => {
    if (
      postSafetyMeetingAlertData.status == API_STATUS.SUCCESS &&
      postSafetyMeetingAlertData?.isLoading === false &&
      clearAlert
    ) {
      toast("Mail Sending successfully");
    }
  }, [postSafetyMeetingAlertData]);
  return (
    <>
      {isloadingalert && <Loader />}
      {isLoading ? (
        <Loader />
      ) : (
        <ToolBoxAssignedUserStatus
          getScheduleAssignUserStatusData={
            getScheduleAssignUserStatusData?.data?.instructorDetails ?? []
          }
          getScheduleAssignUserStatusDataLoading={
            getScheduleAssignUserStatusData?.isLoading
          }
          fieldid={fieldid}
          flag={flag}
          titleSafetyMeeting={titleSafetyMeeting}
          Tabvalue={Tabvalue}
          onSubmit={addSubmitAlert}
          scheduleId={scheduleId}
          filterBy={filterBy}
          toolboxTalkId={toolboxTalkId}
          toolboxTalkVersionId={toolboxTalkVersionId}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default ToolboxScheduleUserStatusContainer;
