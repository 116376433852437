import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { useNavigate } from "react-router-dom";
import { getFormatedDate, useInfiniteScroll } from "../../../../utils/helper";

const UsersWithHighestCourseCompletion = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    if (props?.coursesUserAssignListData) {
      setData((prevData) => [
        ...prevData,
        ...props.coursesUserAssignListData?.assignedUserAdminResponse,
      ]);
    }
  }, [props?.coursesUserAssignListData]);

  const fetchMoreData = async (pageNumber) => {
    const data = {
      pageNumber: pageNumber,
      pageSize: 100,
      lessonId: props.apiData?.lessonId,
      isCompleted: true,
    };
    try {
      const response = await props.getMoretDetails(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    fetchMoreData(pageNumber);
    setPage(pageNumber);
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const columns = [
    {
      id: "assignedTo",
      label: `Completed By`,
      sortable: true,
      isSorted: sortColumn === "assignedTo",
      sortDirection,
    },
    {
      id: "dateAssigned",
      label: `Date Completed`,
      sortable: true,
      isSorted: sortColumn === "dateAssigned",
      sortDirection,
    },
    {
      id: "timeEstimatedMins",
      label: "Time Estimated",
      sortable: true,
      isSorted: sortColumn === "timeEstimatedMins",
      sortDirection,
    },
    {
      id: "timeElapsedMins",
      label: "Time Elapsed",
      sortable: true,
      isSorted: sortColumn === "timeElapsedMins",
      sortDirection,
    },
    {
      id: "timeElapsedMins12",
      label: "Completed Times",
      sortable: true,
      isSorted: sortColumn === "timeElapsedMins12",
      sortDirection,
    },
    {
      id: "prevLessonDetailList",
      label: "Completed On",
      sortable: true,
      isSorted: sortColumn === "prevLessonDetailList",
      sortDirection,
    },
  ];

  const getcompletedlessondetail = (val) => {
    const dataArr = val?.map((data) => {
      if (data?.prevCourseDates) {
        return getFormatedDate(data?.prevCourseDates);
      }
    });

    return dataArr;
  };

  const rows = data?.map((lesson) => {
    const datacheckVal = getcompletedlessondetail(lesson?.prevCourseDetailList);

    const dtacheck = getcompletedlessondetail(lesson?.prevCourseDetailList);

    var tempvAL1 = [...dtacheck];
    tempvAL1.pop();

    var datacheck2 = tempvAL1?.join(",");
    return (
      <tr className="row" key={lesson?.id}>
        <td>{lesson.assignedTo}</td>
        <td>{lesson.dateAssigned}</td>
        <td>{lesson.timeEstimatedMins}</td>
        <td>{lesson.timeElapsedMins}</td>
        <td>
          {datacheckVal[0] === "undefined" ? (
            ""
          ) : (
            <>{datacheckVal?.length} times</>
          )}
        </td>
        <td>
          <>
            <div title={datacheck2} style={{ cursor: "pointer" }}>
              {dtacheck[dtacheck?.length - 1]}
            </div>{" "}
            <div title={datacheck2} className="morelessdetail">
              {dtacheck.length > 1
                ? ` +${dtacheck ? dtacheck?.length - 1 : ""}`
                : ""}
            </div>
          </>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="customTableWrapper">
        <Table
          columns={columns}
          rows={rows}
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default UsersWithHighestCourseCompletion;
