import React, { useState } from "react";
//import "../../../incidents/cases/addCaseList.scss";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import { LESSON } from "../../../../constant/routeContant";
import Button from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
import { getFormatedDate } from "../../../../utils/helper";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";

export default function AssignedLessonUser({
  getLessonAssignUserListData,
  lessonUserAssignListData,
  isLoading,
  lessonname,
  lessonId,
  fieldid,
  onSubmit,
  postunAssignLesson,
  setisunAssignbtn,
  setisdisable,
  isdisable,
}) {
  let titlelesson;
  if (fieldid === "true") {
    titlelesson = "COMPLETED USERS";
  } else if (fieldid === "false") {
    titlelesson = "NOT COMPLETED USERS";
  } else {
    titlelesson = "ASSIGNED USERS";
  }
  const dataBack = {
    title: "Back to LMS Dashboard",
    route: LESSON,
  };
  const [selectedUserId, setselectedUserId] = useState([]);
  const [customMessage, setCustomMessage] = useState(
    "This is a reminder that you have training that is incomplete."
  );
  const [open, setOpen] = React.useState(false);
  const [editInsName, setInsName] = useState(false);

  // handle alert mail==============================================
  const handleclickAssign = (userId) => {
    setOpen(true);
    setInsName(true);
    const useridArr = [];
    useridArr.push(userId);
    setselectedUserId(useridArr);
  };
  const handleAlertMail = () => {
    const data = {
      userId: selectedUserId,
      lessonCourseId: lessonId,
      lessonCoursetype: 1,
      dynamicText: customMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomMessage(
      "This is a reminder that you have training that is incomplete."
    );
  };

  const handleClose = () => {
    setCustomMessage(
      "This is a reminder that you have training that is incomplete."
    );
    setOpen(false);
    setselectedUserId([]);
  };

  const onchangeCustomMessage = (e) => {
    setCustomMessage(e);
  };
  // handle alert all mail==============================================
  const handleclickAssignAll = () => {
    setOpen(true);
    setInsName(true);
    setselectedUserId([]);
  };
  const handleAlertAll = () => {
    const UsersIds = lessonUserAssignListData?.assignedUserAdminResponse?.map(
      (val) => {
        return val?.assignedToId;
      }
    );
    const data = {
      userId: UsersIds,
      lessonCourseId: lessonId,
      lessonCoursetype: 1,
      dynamicText: customMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomMessage(
      "This is a reminder that you have training that is incomplete."
    );
  };
  // function for get lesson versions detail data
  const getcompletedlessondetail = (val) => {
    const dataArr = val?.map((data) => {
      if (data?.prevLessonDates) {
        return getFormatedDate(data?.prevLessonDates);
      }
    });

    return dataArr;
  };
  // column for Lesson assigned and Not Completed pages
  const handleunAssignLessonOnClick = (data) => {
    // assignedToId ======
    setisdisable(true);
    setisunAssignbtn(true);
    const dataResult = {
      orgId: parseInt(ORG_ID()),
      lessonId: lessonId,
      userId: data?.row?.assignedToId,
      modifiedBy: parseInt(USER_ID()),
    };

    postunAssignLesson(dataResult);
  };
  const columns = [
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 120,
      flex: 1,
      minWidth: 100,
    },

    {
      field: "dateAssigned",
      headerName: "Date Assigned",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "timeEstimatedMins",
      headerName: "Time Estimated",
      type: "text",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "timeElapsedMins",
      headerName: "Time Elapsed",
      type: "text",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "timeElapsedMins12",
      headerName: fieldid === "false" ? "Completed Times" : "Assigned Times",
      type: "text",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        const datacheckVal = getcompletedlessondetail(
          cellValues?.row?.prevLessonDetailList
        );

        return datacheckVal[0] === "undefined" ? (
          ""
        ) : (
          <div>{datacheckVal?.length} times</div>
        );
      },
    },
    {
      field: "prevLessonDetailList",
      headerName: "Assigned On",
      type: "text",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        const dtacheck = getcompletedlessondetail(
          cellValues?.row?.prevLessonDetailList
        );

        var tempvAL1 = [...dtacheck];
        tempvAL1.pop();
        var datacheck2 = tempvAL1?.join(",");

        return (
          <>
            <div title={datacheck2} style={{ cursor: "pointer" }}>
              {dtacheck[dtacheck?.length - 1]
                ? dtacheck[dtacheck?.length - 1]
                : null}
            </div>{" "}
            <div title={datacheck2} className="morelessdetail">
              {" "}
              {dtacheck.length > 1
                ? ` +${dtacheck ? dtacheck?.length - 1 : ""}`
                : ""}
            </div>
          </>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={lessonUserAssignListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            lessonUserAssignListData?.assignedUserAdminResponse ?? []
          }
          fileNameXL={
            fieldid === "false" ? "LessonAssigned" : "LessonCompleted"
          }
        />
      ),
      width: fieldid === "false" ? 150 : 100,
      minWidth: fieldid === "false" ? 150 : 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        if (fieldid === "false") {
          return (
            <>
              {" "}
              <div className="alertSet">
                <div className="alertbtn">
                  <Button
                    primaryalert={true}
                    onClick={(row) => handleclickAssign(cellValues?.id)}
                    label="Alert"
                  />
                </div>
                <ReportRowManipulte
                  rows={cellValues}
                  unAssignLesson={true}
                  unAssignLessonLabel={
                    <span className={isdisable ? "disableclss" : ""}>
                      {" "}
                      Unassign Lesson
                    </span>
                  }
                  unAssignLessonOnclick={() =>
                    !isdisable && handleunAssignLessonOnClick(cellValues)
                  }
                />
              </div>
            </>
          );
        }
      },
    },
  ];
  if (fieldid === "false") {
    columns.splice(4, 2);
  }
  // column for Lesson Complete pages
  const columnsLessonComplete = [
    {
      field: "assignedTo",
      headerName: "Completed By",
      width: 120,
      flex: 1,
      minWidth: 120,
    },

    {
      field: "dateAssigned",
      headerName: "Date Completed",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "timeEstimatedMins",
      headerName: "Time Estimated",
      type: "text",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "timeElapsedMins",
      headerName: "Time Elapsed",
      type: "text",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "timeElapsedMins12",
      headerName: "Completed Times",
      type: "text",
      flex: 1,
      minWidth: 120,
      renderCell: (cellValues) => {
        const datacheckVal = getcompletedlessondetail(
          cellValues?.row?.prevLessonDetailList
        );

        return <div>{datacheckVal?.length} times</div>;
      },
    },
    {
      field: "prevLessonDetailList",
      headerName: "Completed On",
      type: "text",
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        const dtacheck = getcompletedlessondetail(
          cellValues?.row?.prevLessonDetailList
        );

        var tempvAL1 = [...dtacheck];
        tempvAL1.pop();

        var datacheck2 = tempvAL1?.join(",");
        return (
          <>
            <div title={datacheck2} style={{ cursor: "pointer" }}>
              {dtacheck[dtacheck?.length - 1]}
            </div>{" "}
            <div title={datacheck2} className="morelessdetail">
              {dtacheck.length > 1
                ? ` +${dtacheck ? dtacheck?.length - 1 : ""}`
                : ""}
            </div>
          </>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={lessonUserAssignListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            lessonUserAssignListData?.assignedUserAdminResponse ?? []
          }
          fileNameXL={
            fieldid === "false" ? "LessonAssigned" : "LessonCompleted"
          }
        />
      ),
      width: 90,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (cellValues) => {
        if (fieldid === "false") {
          return (
            <div className="alertbtn">
              <Button
                primaryalert={true}
                onClick={(row) => handleclickAssign(cellValues?.id)}
                label="Alert"
              />
            </div>
          );
        }
      },
    },
  ];
  const handlePagignation = (newPage) => {
    getLessonAssignUserListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      lessonId: lessonId,
      isCompleted: fieldid,
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          {editInsName && (
            <Box className="modalBox">
              <div className="modalTitle">Send Custom Message</div>
              {/* <div className="modalInputBox"> */}
              <Grid container mt={2} lg={12} className="lesson-alert-model">
                <TextEditor
                  value={customMessage}
                  placeholderText={"Enter Here..."}
                  onChange={(e) => {
                    onchangeCustomMessage(e);
                  }}
                />
              </Grid>

              {/* </div> */}
              <Grid container className="modalFooterBox" mt={2}>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Button
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={3} xs={12} sm={3} md={3}>
                  <Button
                    redButton={true}
                    label={"Send Alert"}
                    onClick={
                      selectedUserId.length > 0
                        ? handleAlertMail
                        : handleAlertAll
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Modal>

      <BackBtn dataBack={dataBack} />
      <div className="assignDivtitle">
        <div className="assigntitle">
          <div className="lessonnme">{lessonname}</div>

          <div className="lessonnmeT">{titlelesson}</div>
        </div>
        {fieldid == "false" && (
          <div className="allertall">
            {lessonUserAssignListData?.assignedUserAdminResponse.length > 1 && (
              <Button
                primaryalertAll={true}
                onClick={(row) => handleclickAssignAll()}
                label="Alert All"
              />
            )}
          </div>
        )}
      </div>
      <div className="table_style_ui">
        <GridTable
          getRowId={(r) => r?.assignedToId}
          rows={
            lessonUserAssignListData?.assignedUserAdminResponse
              ? lessonUserAssignListData?.assignedUserAdminResponse
              : []
          }
          columns={
            fieldid === "false" || fieldid === ""
              ? columns
              : columnsLessonComplete
          }
          pageSize={PAGE_SIZE}
          rowsPerPageOptions={[10]}
          rowCount={lessonUserAssignListData?.noOfRecords}
          paginationMode="server"
          loading={isLoading}
          onPageChange={handlePagignation}
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          CanUserAddRows="False"
          IsReadOnly="True"
          components={{
            LoadingOverlay: TableLoader,
            NoRowsOverlay: NORow,
          }}
        />
      </div>
    </>
  );
}
