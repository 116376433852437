import React from "react";
import AddContact from "../component/contacts/createContact";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import * as contactsAction from "../../../redux/actions/contactsAction";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ACCESS_DENIED, ADMIN_CONTACTS } from "../../../constant/routeContant";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  notifyError,
  notifyErrorContactContractor,
} from "../../../../src/library/custom/toastMessage/Toast";
import { ToastContainer } from "react-toastify";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function AddContactsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useSelector((state) => state.state);
  const cityData = useSelector((state) => state.city);
  const contactData = useSelector((state) => state.createContact);
  const [successScreens, setSuccessScreens] = useState(false);
  const [isConvert, setIsConvert] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const product = userInfo?.product;
  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );

  const location = useLocation();
  const contactId = location?.state?.contactId;
  const editMode = location?.state?.editMode;

  const viewContactData = useSelector((state) => state?.viewContacts?.data);
  const updateContact = useSelector((state) => state.updateContact);
  const convertNonPrivilegeContactListData = useSelector(
    (state) => state.convertNonPrivilegeContactListData
  );

  const getCity = (stateId) => {
    dispatch(stateCityAction.getCity(stateId));
  };
  const [setsubmit, setSetsubmit] = useState(false);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  const showLoader = useSelector(
    (state) =>
      state.createContact?.isLoading ||
      state.stateData?.isLoading ||
      state?.viewContacts?.isLoading ||
      state.updateContact?.isLoading ||
      state.state?.isLoading ||
      state.convertNonPrivilegeContactListData?.isLoading
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "5",
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    getStateData();
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    setSuccessScreens(false);
    dispatch(contactsAction.clearContact());
    if (contactId) {
      dispatch(contactsAction.getContactDetails(contactId));
    }
  }, [dispatch]);

  const addAndUpdateContact = (data, checkBoxPrivilege) => {
    setSuccessScreens(true);
    setIsConvert(checkBoxPrivilege);
    if (editMode) {
      if (checkBoxPrivilege) {
        setSetsubmit(true);
        dispatch(contactsAction.convertNonPrivilegeContactList(data));
      } else {
        setSetsubmit(true);
        dispatch(contactsAction.updateContact(data));
      }
    } else {
      setSetsubmit(true);
      dispatch(contactsAction.createContact(data));
    }
  };
  const successScreen = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route={ADMIN_CONTACTS}
        label1=" "
        label2=" "
        label4=" Contact List in"
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (
      contactData?.status === API_STATUS.FAILURE ||
      updateContact?.status === API_STATUS.FAILURE ||
      convertNonPrivilegeContactListData?.status === API_STATUS.FAILURE
    ) {
      notifyError();
    } else {
      if (contactData?.status === API_STATUS.SUCCESS) {
        if (
          contactData?.data?.responseMessages?.responseCode == "HBNG006" &&
          setsubmit
        ) {
          notifyErrorContactContractor();
        }

        if (
          contactData?.data?.responseMessages?.responseCode == "HBNG001" &&
          setsubmit
        ) {
          //setIsSuccess(true);
          setSuccessScreens(true);
        }
      }

      if (updateContact?.status === API_STATUS.SUCCESS) {
        if (
          updateContact?.data?.responseMessages?.responseCode == "HBNG006" &&
          setsubmit
        ) {
          notifyErrorContactContractor();
        }
        if (
          updateContact?.data?.responseMessages?.responseCode == "HBNG001" &&
          setsubmit
        ) {
          //setIsSuccess(true);
          setSuccessScreens(true);
        }
      }
    }
  }, [contactData, updateContact]);

  useEffect(() => {
    if (convertNonPrivilegeContactListData?.status === API_STATUS.SUCCESS) {
      if (
        convertNonPrivilegeContactListData?.data?.responseMessages
          ?.responseCode == "HBNG006" &&
        setsubmit
      ) {
        notifyErrorContactContractor();
      }
    }
    if (convertNonPrivilegeContactListData?.status === API_STATUS.SUCCESS) {
      if (
        convertNonPrivilegeContactListData?.data?.responseMessages
          ?.responseCode == "HBNG001" &&
        setsubmit
      ) {
        setSuccessScreens(true);
      }
    }
  }, [convertNonPrivilegeContactListData, contactData, updateContact]);

  console.log(
    "convertNonPrivilegeContactListData",
    convertNonPrivilegeContactListData
  );
  return (
    <>
      {checkPrivileges([12, 40, 41]) ? (
        <>
          {showLoader && <Loader />}
          {convertNonPrivilegeContactListData.status === "SUCCESS" &&
          convertNonPrivilegeContactListData.data &&
          successScreens &&
          convertNonPrivilegeContactListData?.data?.responseMessages
            ?.responseCode == "HBNG001" ? (
            successScreen(
              convertNonPrivilegeContactListData?.data?.id,
              "Contact Converted into Privilege User Successfully"
            )
          ) : contactData.status === "SUCCESS" &&
            contactData.data &&
            successScreens &&
            contactData?.data?.responseMessages?.responseCode == "HBNG001" ? (
            successScreen(contactData?.data?.id, "Contact Created Successfully")
          ) : updateContact.status === "SUCCESS" &&
            updateContact?.data &&
            successScreens &&
            updateContact?.data?.responseMessages?.responseCode == "HBNG001" ? (
            successScreen(
              updateContact?.data?.id,
              "Contact Updated Successfully"
            )
          ) : (
            <>
              <div>
                <ToastContainer />
              </div>
              <div>
                <AddContact
                  onSubmit={addAndUpdateContact}
                  getCity={getCity}
                  city={cityData && cityData.data?.cities}
                  state={stateData && stateData.data?.getState}
                  viewContactData={editMode ? viewContactData?.result : ""}
                  editMode={editMode}
                  contactId={contactId}
                  products={product}
                  roles={roles}
                />
              </div>
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
