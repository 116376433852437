import { FormControl, Grid } from "@mui/material";
import React from "react";
import SelectMenu from "../../../../../library/custom/selectMenu/SelectMenu";
import { SIGNIFICAT_DEVIATION, YES_NO } from "../../../../../constant/constant";
import { InputField } from "../../../../../library/custom/textBox/InputField";
import FormLabels from "../../../../../library/custom/formLabel/FormLabels";
import "./threeQuestion.scss";

const ThreeQuestion = (props) => {
  return (
    <>
      <div className="threeQuestionBox">
        <div className="threeQuestionList">
          <FormControl fullWidth>
            <InputField
              isRequired={true}
              fullWidth={true}
              type="text"
              label={"Actual Dimension (ft)"}
              value={props?.question?.actualDimensionValue}
              onChange={(e) =>
                props?.formchangeThreeHnadle(
                  e.target.value,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  "actualDimension"
                )
              }
            />
          </FormControl>
          {props?.question?.actualDimensionValueError && !props?.question?.actualDimensionValue && (
            <p className="error">Please select this required field.</p>
          )}
        </div>
        <div className="threeQuestionList">
          <FormControl fullWidth>
            <InputField
              isRequired={true}
              fullWidth={true}
              type="text"
              label={"Proposed Dimension (ft)"}
              value={props?.question?.proposedDimensionValue}
              onChange={(e) =>
                props?.formchangeThreeHnadle(
                  e.target.value,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  "proposedDimension"
                )
              }
            />
          </FormControl>
          {props?.question?.proposedDimensionValueError && !props?.question?.proposedDimensionValue && (
            <p className="error">Please select this required field.</p>
          )}
        </div>
        <div className="threeQuestionList">
          <FormControl fullWidth>
            <FormLabels
              label="Significant Deviation from plan"
              isRequired={true}
            />
            <SelectMenu
              placeholder="Please Select"
              listData={SIGNIFICAT_DEVIATION ?? []}
              value={props?.question?.significantDeviationValue ?? null}
              onchangehandler={(e) =>
                props?.formchangeThreeHnadle(
                  e.target.value,
                  props?.question,
                  props?.subcategory,
                  props?.category,
                  "significantDeviation"
                )
              }
            />
          </FormControl>
          {props?.question?.significantDeviationValueError && !props?.question?.significantDeviationValue && (
            <p className="error">Please select this required field.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ThreeQuestion;
