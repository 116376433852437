import { PRODUCT_ID } from "../constant/storm-water/constant";
import CryptoJS from "crypto-js";

// generate random kry
const generateSecretKey = (length) => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return result;
};

// constants
export const SECRET_KEY_NAME = "app_secret_key";
const SECRET_KEY_LENGTH = 32;

// get secret key
export const getSecretKey = () => {
  const userIsLoggedIn = Boolean(localStorage.getItem("access_token"));
  if (!userIsLoggedIn) {
    return null;
  }

  let secretKey = localStorage.getItem(SECRET_KEY_NAME);
  if (!secretKey) {
    secretKey = generateSecretKey(SECRET_KEY_LENGTH);
    localStorage.setItem(SECRET_KEY_NAME, secretKey);
  }
  return secretKey;
};

// encryption code

export const encryptData = (data) => {
  const secretKey = getSecretKey();
  try {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encrypted;
  } catch (error) {
    console.error("Encryption error:", error);
    return null;
  }
};

// decryption code
export const decryptData = (encryptedData) => {
  try {
    const secretKey = getSecretKey();
    if (!secretKey) {
      console.error("Secret key is not available.");
      return null;
    }
    if (!encryptedData) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (!decrypted) {
      console.error("Failed to decrypt data.");
      return null;
    }
    return JSON.parse(decrypted);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

export const checkPrivileges = (privilegeIds) => {
  const allPrivilege = localStorage.getItem("privileges");
  const decryptPrivilege = decryptData(allPrivilege);
  let isAllow = false;
  privilegeIds?.every((id) => {
    if (decryptPrivilege?.includes(id)) {
      isAllow = true;
      return false;
    }
    return true;
  });
  return isAllow;
};

export const showRighSideMenu = () => {
  const serviceType = PRODUCT_ID();
  return {
    isSW: parseInt(serviceType) === 2,
    isSafety: parseInt(serviceType) === 1,
  };
};

export const getPrivilegeByServiceType = (privileges, serviceType) => {
  let allPrivileges = [];
  privileges?.forEach((item) => {
    if (
      allPrivileges.indexOf(item.key) === -1 &&
      item.serviceType === serviceType
    ) {
      allPrivileges.push(item.key);
    }
  });
  return allPrivileges;
};
