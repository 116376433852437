import React, { useEffect, useState } from "react";
import InspectionGenerator from "../components/inspectionGenerator";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../library/common/Loader";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../../constant/routeContant";

const InspectionGeneratorContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clearInspectionTypeFieldsMap, setClearInspectionTypeFieldsMap] =
    useState(false);

  const refData = {
    flags: [3],
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };
  const stateData = useSelector((state) => state.state);
  const updateInspectionTypeFieldsMap = useSelector(
    (state) => state.updateInspectionTypeFieldsMap
  );
  const updateInspectionTypeFieldsMapData = (data) => {
    setClearInspectionTypeFieldsMap(true);
    dispatch(swInspectionAction.updateInspectionTypeFieldsMap(data));
  };
  const getInTyFeByInTyList = useSelector((state) => state.getInTyFeByInTyList);
  const getInTyFeByInTyListData = (data) => {
    dispatch(swInspectionAction.getInTyFeByInTyList(data));
  };

  const inspectionTypeFields = useSelector(
    (state) => state.getInspectionTypeFieldsList
  );
  const isLoading = useSelector(
    (state) =>
      state.getInTyFeByInTyList?.isLoading ||
      state.getInspectionTypeFieldsList?.isLoading
  );

  const showLoader = useSelector(
    (state) =>
      state.getInspectionTypeFieldsList?.isLoading ||
      state.state?.isLoading ||
      state.getInTyFeByInTyList?.isLoading ||
      state.updateInspectionTypeFieldsMap?.isLoading
  );
  const getInspectionTypeFieldsListData = (data) => {
    dispatch(swInspectionAction.getInspectionTypeFieldsList(data));
  };
  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  useEffect(() => {
    getInspectionTypeFieldsListData();
    getStateData();
    getSwReferenceData(refData);
  }, []);
  useEffect(() => {
    if (
      updateInspectionTypeFieldsMap?.status === "SUCCESS" &&
      updateInspectionTypeFieldsMap?.isLoading === false &&
      clearInspectionTypeFieldsMap
    ) {
      toast("Inspection Generator Updated successfully");
    }
  }, [updateInspectionTypeFieldsMap]);

  return (
    <>
      {checkPrivileges([12, 93, 94, 95]) ? (
        <>
          {showLoader && <Loader />}
          <InspectionGenerator
            inspectionTypeFields={
              inspectionTypeFields?.data?.inspectionTypeFields
            }
            stateData={stateData && stateData.data?.getState}
            getInTyFeByInTyListData={getInTyFeByInTyListData}
            getInTyFeByInTyList={
              isLoading
                ? []
                : getInTyFeByInTyList?.data?.listInspectionTypeFields
            }
            updateInspectionTypeFieldsMapData={
              updateInspectionTypeFieldsMapData
            }
            updateInspectionTypeFieldsMap={updateInspectionTypeFieldsMap}
            getSwReference={getSwReference?.data?.insTypeMstList}
            getInTyFeByInTyLists={getInTyFeByInTyList?.data}
            getInspectionTypeFieldsListData={getInspectionTypeFieldsListData}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default InspectionGeneratorContainer;
