import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import commonImages from "../../../../assets";
import Typography from "@mui/material/Typography";
import "./lessonDetail.scss";
import Button from "../../../../library/custom/button/Button";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { USERLESSON, LESSON } from "../../../../constant/routeContant";
import Popover from "@mui/material/Popover";
import RadioGroup from "@mui/material/RadioGroup";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import {
  ORG_ID,
  API_STATUS,
  USER_ID,
  USER_TYPE,
} from "../../../../constant/constant";
import { useDispatch } from "react-redux";
import Buttons from "../../../../library/custom/button/Button";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const LessonDetail = (props) => {
  const { viewOnClick } = props;
  const lessonItem = props?.viewData?.lessons;
  const isAdminLesson = props?.isAdminLesson;
  //Reassign  code ================== commit
  const getStatusCheckLessoncompleted = props?.getStatusCheckLessoncompleted;
  const setchecklessonstatus = props?.setchecklessonstatus;
  const checklessonstatus = props?.checklessonstatus;
  const CompleteLessonStatusResult = props?.CompleteLessonStatusResult;
  const [usersValue, setUsersValue] = useState();
  const [usersGroupValue, setUsersGroupValue] = useState();
  const setisSubmitAssign = props?.setisSubmitAssign;
  const isSubmitAssign = props?.isSubmitAssign;
  const [Reassignbtndisable, setReassignbtndisable] = useState(false);

  const dataBack = {
    title: "Back to Lesson",
    route: -1,
    islesson: false,
  };
  const autoCompGroup = useRef(null);
  const autoCompUser = useRef(null);
  const dispatch = useDispatch();
  const lessionid = lessonItem?.id;
  const [anchorEl, setAnchorEl] = useState(null);
  const errCompltemsg = props.errCompltemsg;
  const [userOrGroupId, setUserOrGroupId] = useState("");
  const [statuccheck, setStatuccheck] = useState("1");
  const [isSubmit, setIsSubmit] = useState(true);
  const onSearch = props.onSearch;
  const allUsers = props.allUsers;
  const quizStatus = props.quizStatus;
  const handleAllGroupSearch = props?.handleAllGroupSearch;
  const userGroups = props?.userGroups;
  const isLoading = props?.isLoading;
  const assignLessonStatus = props?.assignLessonStatus;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElAssign, setAnchorElAssign] = useState(null);
  const [isassignName, setIsassignName] = useState(false);
  const handleClickAssign = (event) => {
    setReassignbtndisable(false);
    setchecklessonstatus(false);
    setUsersValue({ key: "", value: "" });
    setUserOrGroupId("");
    setAnchorElAssign(event.currentTarget);
    setIsSubmit(true);
  };

  const handleCloseAssign = () => {
    setAnchorElAssign(null);
  };

  const openAssign = Boolean(anchorElAssign);
  const idAssign = open ? "simple-popover" : undefined;
  useEffect(() => {
    console.log("PROD DPL LESSION");
    if (assignLessonStatus === API_STATUS.SUCCESS) {
      setIsSubmit(true);
      setTimeout(() => {
        setAnchorElAssign(null);
      }, 2000);
    }
  }, [assignLessonStatus]);

  const onChangeUsers = (e) => {
    setchecklessonstatus(false);
    setIsSubmit(true);
    setUsersValue({ key: e?.key ?? "", value: e?.value ?? "" });
    setUsersGroupValue({ key: e?.key ?? "", value: e?.value ?? "" });
    if (e == null) {
      setUserOrGroupId("");
    } else {
      setUserOrGroupId(e.key);
      setIsassignName(false);
    }
  };
  const handlecheckbtn = (e, value) => {
    console.log("PROD DPL LESSION direct check");

    setStatuccheck(e.target.value);
    setUserOrGroupId("");
    setIsassignName(false);
    setchecklessonstatus(false);
    setUsersValue({ key: "", value: "" });
    setUsersGroupValue({ key: "", value: "" });
    setIsSubmit(true);
    setIsassignName(false);
  };

  function mappedData() {
    let errors = false;
    if (userOrGroupId?.length === 0) {
      setIsassignName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    let data;
    data = {
      userType: statuccheck,
      lessonId: lessionid,
      userOrGroupId: userOrGroupId,
      createdBy: USER_ID(),
      orgId: ORG_ID(),
      reAssign: false,
    };

    return data;
  }
  useEffect(() => {
    if (
      checklessonstatus === false &&
      props?.CompleteLessonStatusStatus === API_STATUS.SUCCESS &&
      props?.CompleteLessonStatusResult?.length === 0 &&
      isSubmitAssign
    ) {
      const data = mappedData();
      if (data) {
        data.reAssign = false;
      }

      dispatch(lmsAction.assignLesson(data));
    }
  }, [props?.CompleteLessonStatusResult, props?.CompleteLessonStatusStatus]);

  const handleClickAdd = (e) => {
    console.log("LESSION  CHANGES CHECK");
    setisSubmitAssign(true);
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(false);

      if (statuccheck === "0") {
        getStatusCheckLessoncompleted(data);
      } else if (statuccheck === "1") {
        dispatch(lmsAction.assignLesson(data));
      }
    }
  };
  const handleClickAddReassign = (e) => {
    setReassignbtndisable(true);
    let data;
    data = {
      userType: parseInt(statuccheck),
      lessonId: lessionid,
      userOrGroupId: userOrGroupId,
      createdBy: USER_ID(),
      orgId: ORG_ID(),
      reAssign: true,
    };
    dispatch(lmsAction.assignLesson(data));
  };
  const [checked, setChecked] = useState(false);

  const completeLesson = () => {
    const data = {
      userId: USER_ID(),
      lessonId: lessionid,
      orgId: ORG_ID(),
    };
    dispatch(lmsAction.completeLesson(data));
  };
  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };
  const cancelreassign = (e) => {
    setAnchorElAssign(null);
    setchecklessonstatus(false);
  };
  console.log("lesson reassign");
  return (
    <>
      <BackBtn dataBack={dataBack} />
      <Grid container className="lessonView">
        <Grid item xs={12}>
          <Grid className="lessonImage">
            <img
              key={lessonItem?.file?.id}
              src={
                lessonItem?.file?.path
                  ? lessonItem?.file?.path
                  : commonImages?.noimagePublish
              }
              alt=""
            />

            <div className="adminDetailBox">
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="deletePop"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography className="deleteText">Delete Lesson</Typography>
              </Popover>
            </div>
          </Grid>
          <Grid container>
            <Grid lg={9} md={9} xs={12}>
              <Grid className="imageDetail">
                <Typography className="imageDetailContent">
                  <span>
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.8567 5.40665L6.12003 0.0666504L9.38336 5.40665H2.8567ZM9.38336 11.9333C8.6417 11.9333 8.01118 11.6738 7.49182 11.1549C6.97285 10.6355 6.71336 10.005 6.71336 9.26332C6.71336 8.52165 6.97285 7.89114 7.49182 7.37177C8.01118 6.8528 8.6417 6.59332 9.38336 6.59332C10.125 6.59332 10.7555 6.8528 11.2749 7.37177C11.7939 7.89114 12.0534 8.52165 12.0534 9.26332C12.0534 10.005 11.7939 10.6355 11.2749 11.1549C10.7555 11.6738 10.125 11.9333 9.38336 11.9333ZM0.780029 11.6367V6.88998H5.5267V11.6367H0.780029Z"
                        fill="#999999"
                      />
                    </svg>
                  </span>
                  {lessonItem?.category}
                </Typography>
                {checkPrivileges([12, 11, 10]) ? (
                  <>
                    <Typography className="imageDetailContent">
                      <span>
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.666748 10.8334V8.96675C0.666748 8.58897 0.764081 8.24164 0.958748 7.92475C1.15297 7.6083 1.41119 7.36675 1.73341 7.20008C2.4223 6.85564 3.1223 6.59719 3.83341 6.42475C4.54453 6.25275 5.26675 6.16675 6.00008 6.16675C6.73342 6.16675 7.45564 6.25275 8.16675 6.42475C8.87786 6.59719 9.57786 6.85564 10.2667 7.20008C10.589 7.36675 10.8472 7.6083 11.0414 7.92475C11.2361 8.24164 11.3334 8.58897 11.3334 8.96675V10.8334H0.666748ZM12.6667 10.8334V8.83342C12.6667 8.34453 12.5307 7.87497 12.2587 7.42475C11.9863 6.97497 11.6001 6.58897 11.1001 6.26675C11.6667 6.33341 12.2001 6.44719 12.7001 6.60808C13.2001 6.76941 13.6667 6.96675 14.1001 7.20008C14.5001 7.4223 14.8056 7.66941 15.0167 7.94141C15.2279 8.21386 15.3334 8.51119 15.3334 8.83342V10.8334H12.6667ZM6.00008 5.50008C5.26675 5.50008 4.63897 5.23897 4.11675 4.71675C3.59453 4.19453 3.33341 3.56675 3.33341 2.83341C3.33341 2.10008 3.59453 1.4723 4.11675 0.950082C4.63897 0.427859 5.26675 0.166748 6.00008 0.166748C6.73342 0.166748 7.36119 0.427859 7.88341 0.950082C8.40564 1.4723 8.66675 2.10008 8.66675 2.83341C8.66675 3.56675 8.40564 4.19453 7.88341 4.71675C7.36119 5.23897 6.73342 5.50008 6.00008 5.50008ZM12.6667 2.83341C12.6667 3.56675 12.4056 4.19453 11.8834 4.71675C11.3612 5.23897 10.7334 5.50008 10.0001 5.50008C9.87786 5.50008 9.7223 5.4863 9.53341 5.45875C9.34453 5.43075 9.18897 5.40008 9.06675 5.36675C9.36675 5.01119 9.59719 4.61675 9.75808 4.18341C9.91942 3.75008 10.0001 3.30008 10.0001 2.83341C10.0001 2.36675 9.91942 1.91675 9.75808 1.48341C9.59719 1.05008 9.36675 0.655637 9.06675 0.300081C9.2223 0.244526 9.37786 0.208304 9.53341 0.191415C9.68897 0.17497 9.84453 0.166748 10.0001 0.166748C10.7334 0.166748 11.3612 0.427859 11.8834 0.950082C12.4056 1.4723 12.6667 2.10008 12.6667 2.83341Z"
                            fill="#999999"
                          />
                        </svg>
                      </span>
                      Assigned{" "}
                      <span className="count">
                        ({lessonItem?.totaleAssignedToUser})
                      </span>
                    </Typography>{" "}
                    <Typography className="imageDetailContent">
                      <span>
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.06667 10.2001L9.76667 5.50008L8.83333 4.56675L5.06667 8.33341L3.16667 6.43341L2.23333 7.36675L5.06667 10.2001ZM1.33333 13.5001C0.966667 13.5001 0.652667 13.3696 0.391333 13.1087C0.130444 12.8474 0 12.5334 0 12.1667V2.83341C0 2.46675 0.130444 2.15275 0.391333 1.89141C0.652667 1.63053 0.966667 1.50008 1.33333 1.50008H4.13333C4.27778 1.10008 4.51956 0.777859 4.85867 0.533415C5.19733 0.28897 5.57778 0.166748 6 0.166748C6.42222 0.166748 6.80289 0.28897 7.142 0.533415C7.48067 0.777859 7.72222 1.10008 7.86667 1.50008H10.6667C11.0333 1.50008 11.3473 1.63053 11.6087 1.89141C11.8696 2.15275 12 2.46675 12 2.83341V12.1667C12 12.5334 11.8696 12.8474 11.6087 13.1087C11.3473 13.3696 11.0333 13.5001 10.6667 13.5001H1.33333ZM6 2.33341C6.14444 2.33341 6.264 2.28608 6.35867 2.19141C6.45289 2.09719 6.5 1.97786 6.5 1.83341C6.5 1.68897 6.45289 1.56941 6.35867 1.47475C6.264 1.38053 6.14444 1.33341 6 1.33341C5.85556 1.33341 5.73622 1.38053 5.642 1.47475C5.54733 1.56941 5.5 1.68897 5.5 1.83341C5.5 1.97786 5.54733 2.09719 5.642 2.19141C5.73622 2.28608 5.85556 2.33341 6 2.33341Z"
                            fill="#999999"
                          />
                        </svg>
                      </span>
                      Completed{" "}
                      <span className="count">
                        ({lessonItem?.totalCompletedByUser})
                      </span>
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                <Typography className="imageDetailContent">
                  <span>
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.99998 11.8333C6.27081 11.8333 5.58792 11.6949 4.95131 11.418C4.31431 11.1407 3.76014 10.7662 3.28881 10.2945C2.81709 9.82318 2.44259 9.26902 2.16531 8.63202C1.88842 7.9954 1.74998 7.31252 1.74998 6.58335C1.74998 5.85418 1.88842 5.1711 2.16531 4.5341C2.44259 3.89749 2.81709 3.34332 3.28881 2.8716C3.76014 2.40027 4.31431 2.02615 4.95131 1.74927C5.58792 1.47199 6.27081 1.33335 6.99998 1.33335C7.72914 1.33335 8.41222 1.47199 9.04922 1.74927C9.68584 2.02615 10.24 2.40027 10.7117 2.8716C11.1831 3.34332 11.5574 3.89749 11.8346 4.5341C12.1115 5.1711 12.25 5.85418 12.25 6.58335C12.25 7.31252 12.1115 7.9954 11.8346 8.63202C11.5574 9.26902 11.1831 9.82318 10.7117 10.2945C10.24 10.7662 9.68584 11.1407 9.04922 11.418C8.41222 11.6949 7.72914 11.8333 6.99998 11.8333ZM8.63331 9.03335L9.44998 8.21668L7.58331 6.35002V3.66668H6.41664V6.81668L8.63331 9.03335ZM3.26664 0.37085L4.08331 1.18752L1.60414 3.66668L0.787476 2.85002L3.26664 0.37085ZM10.7333 0.37085L13.2125 2.85002L12.3958 3.66668L9.91664 1.18752L10.7333 0.37085Z"
                        fill="#999999"
                      />
                    </svg>
                  </span>
                  {`${lessonItem?.completionTimeHr}hr ${lessonItem?.completionTimeMin}min`}
                </Typography>
              </Grid>
            </Grid>
            <Grid md={3} xs={12}>
              {checkPrivileges([12, 11, 10]) &&
                lessonItem?.statusId === 1 &&
                isAdminLesson && (
                  <div className="assignBox">
                    <>
                      <Buttons
                        type="button"
                        name="btn"
                        label={"Assign"}
                        primaryassign={true}
                        onClick={handleClickAssign}
                      ></Buttons>

                      <Popover
                        id={idAssign}
                        open={openAssign}
                        anchorEl={anchorElAssign}
                        onClose={handleCloseAssign}
                        className="assignPopBox"
                        anchorOrigin={{
                          vertical: "right",
                          horizontal: "bottom",
                        }}
                        transformOrigin={{
                          vertical: "right",
                          horizontal: "right",
                        }}
                      >
                        <div className="assignPopDetail">
                          <div className="title">User Type</div>
                          <div className="innerauto">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              gap={5}
                              className="radioGroup"
                            >
                              <RadioButton
                                checked={statuccheck === "1" ? true : false}
                                value="1"
                                label="Group"
                                disabled={false}
                                onChange={(e, value) =>
                                  handlecheckbtn(e, value)
                                }
                              ></RadioButton>

                              <RadioButton
                                checked={statuccheck === "0" ? true : false}
                                value="0"
                                label="Individual"
                                disabled={false}
                                onChange={(e, value) =>
                                  handlecheckbtn(e, value)
                                }
                              ></RadioButton>
                            </RadioGroup>

                            {statuccheck === "1" ? (
                              <>
                                <div className="searchauto">
                                  <SearchAutoComplete
                                    optionsData={
                                      isLoading ? [] : userGroups ?? []
                                    }
                                    isKeyValuePair={true}
                                    typeOnchange={(event, value) =>
                                      handleAllGroupSearch(
                                        event.target.value,
                                        0,
                                        true
                                      )
                                    }
                                    onChange={(e, value) =>
                                      onChangeUsers(value)
                                    }
                                    noOptionsTextMsg="No group"
                                    isLoading={isLoading}
                                    autoCompClear={autoCompGroup}
                                  />
                                </div>
                                {isassignName ? (
                                  <div className="show-error">
                                    Please enter this required field
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Grid
                                  container
                                  lg={12}
                                  mt={2}
                                  className="individualbtn"
                                >
                                  <Buttons
                                    variant="contained"
                                    type="button"
                                    name="btn"
                                    label={"Assign"}
                                    varientContained={true}
                                    onClick={(e) => handleClickAdd(e)}
                                  ></Buttons>
                                </Grid>
                                {props.assignmsg ? (
                                  <div className="success">
                                    Lesson Assign successfully
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <SearchAutoComplete
                                  optionsData={
                                    props?.AlluserisLoading
                                      ? []
                                      : allUsers ?? []
                                  }
                                  isKeyValuePair={true}
                                  typeOnchange={(event, value) =>
                                    onSearch(
                                      event.target.value,
                                      USER_TYPE.NONPRIVILEGED
                                    )
                                  }
                                  onChange={(e, value) => onChangeUsers(value)}
                                  isLoading={props?.AlluserisLoading}
                                  autoCompClear={autoCompUser}
                                  value={usersValue ? usersValue : ""}
                                />
                                {isassignName ? (
                                  <div className="show-error">
                                    Please enter this required field
                                  </div>
                                ) : (
                                  ""
                                )}

                                {checklessonstatus === true &&
                                CompleteLessonStatusResult?.length > 0 ? (
                                  <div className="errorMsg">
                                    This lesson has been completed by the
                                    assigned user. Do you want to re-assign this
                                    lesson ?
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Grid
                                  container
                                  lg={12}
                                  mt={2}
                                  className="individualbtn"
                                >
                                  {checklessonstatus === false && (
                                    <Buttons
                                      variant="contained"
                                      type="button"
                                      name="btn"
                                      label={"Assign"}
                                      varientContained={true}
                                      onClick={(e) => handleClickAdd(e)}
                                      disabled={isSubmit ? false : true}
                                    ></Buttons>
                                  )}
                                  {checklessonstatus && (
                                    <>
                                      {" "}
                                      <Buttons
                                        varientText={true}
                                        label="Cancel"
                                        onClick={(e) => cancelreassign(e)}
                                      />
                                      <Buttons
                                        variant="contained"
                                        type="button"
                                        name="btn"
                                        label={"Apply"}
                                        varientContained={true}
                                        onClick={(e) =>
                                          handleClickAddReassign(e)
                                        }
                                        disabled={
                                          Reassignbtndisable ? true : false
                                        }
                                      ></Buttons>
                                    </>
                                  )}
                                </Grid>
                                {props.assignmsg ? (
                                  <div className="success">
                                    Lesson Assign successfully
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </>
                  </div>
                )}
            </Grid>
          </Grid>

          <Grid>
            <Grid className="title">{lessonItem?.lesson}</Grid>
            <Grid className="otherDescration">
              <Grid className="subTitle">Description</Grid>
              <Grid className="desc">
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: lessonItem?.description,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="lessonwithDetail">
          {lessonItem?.sections?.map((lessonSection, i) => {
            const document = lessonSection?.document?.split("/");
            const documentName =
              document?.length > 0 && document.slice(-1).pop();
            return (
              <Grid
                item
                xs={12}
                md={12}
                className="lessonwithDetailBox"
                key={i}
              >
                {lessonSection?.filesImg?.length > 0 && (
                  <Grid className="lessonImage">
                    {lessonSection?.filesImg?.map((coverImage) => {
                      return (
                        <img
                          key={coverImage?.id}
                          src={
                            coverImage
                              ? coverImage?.path
                              : commonImages?.noimagePublish
                          }
                          alt=""
                        />
                      );
                    })}
                  </Grid>
                )}
                <Grid className="otherDescration">
                  <Grid className="desc">
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lessonSection?.text,
                      }}
                    />
                  </Grid>
                </Grid>
                {lessonSection?.videoLink?.length > 0 && (
                  <Grid className="otherDescration">
                    <Grid className="subTitle">
                      {/* Safety Report Guidelines 101.mp4 */}
                    </Grid>
                    <Grid className="lessonImage">
                      <iframe
                        className="videoLink"
                        title={lessonSection?.videoLink}
                        src={lessonSection?.videoLink}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </Grid>
                  </Grid>
                )}

                {lessonSection?.filesDoc?.length > 0 && (
                  <Grid className="otherDescration">
                    <Grid className="subTitle">
                      {/* Safety Report Guidelines 101 */}
                    </Grid>
                    <Grid className="pdfAttached">
                      <span>
                        {lessonSection?.filesDoc.map((item, index) => {
                          return (
                            <a
                              href={item?.path}
                              target="_blank"
                              rel="noreferrer"
                              key={index}
                            >
                              <svg
                                width="20"
                                height="12"
                                viewBox="0 0 20 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 11.5H16V10H5.5C4.4 10 3.45833 9.60833 2.675 8.825C1.89167 8.04167 1.5 7.1 1.5 6C1.5 4.9 1.89167 3.95833 2.675 3.175C3.45833 2.39167 4.4 2 5.5 2H16C16.7 2 17.2917 2.24167 17.775 2.725C18.2583 3.20833 18.5 3.8 18.5 4.5C18.5 5.2 18.2583 5.79167 17.775 6.275C17.2917 6.75833 16.7 7 16 7H6.5C6.21667 7 5.97933 6.904 5.788 6.712C5.596 6.52067 5.5 6.28333 5.5 6C5.5 5.71667 5.596 5.479 5.788 5.287C5.97933 5.09567 6.21667 5 6.5 5H16V3.5H6.5C5.8 3.5 5.20833 3.74167 4.725 4.225C4.24167 4.70833 4 5.3 4 6C4 6.7 4.24167 7.29167 4.725 7.775C5.20833 8.25833 5.8 8.5 6.5 8.5H16C17.1 8.5 18.0417 8.10833 18.825 7.325C19.6083 6.54167 20 5.6 20 4.5C20 3.4 19.6083 2.45833 18.825 1.675C18.0417 0.891667 17.1 0.5 16 0.5H5.5C3.96667 0.5 2.66667 1.03333 1.6 2.1C0.533333 3.16667 0 4.46667 0 6C0 7.53333 0.533333 8.83333 1.6 9.9C2.66667 10.9667 3.96667 11.5 5.5 11.5Z"
                                  fill="#1B436E"
                                />
                              </svg>
                              Download PDF
                            </a>
                          );
                        })}
                      </span>
                      <span className="pdfAttachedName">{documentName}</span>
                    </Grid>
                  </Grid>
                )}

                <hr className="solid" />
              </Grid>
            );
          })}

          <Grid item xs={12} md={12} className="lessonwithDetailBox">
            <Grid container className="takeQuiz">
              {lessonItem?.quizId !== 0 && (
                <Button
                  varientContained={true}
                  label="Take Quiz"
                  disabled={
                    quizStatus ||
                    lessonItem?.quizId <= 0 ||
                    props?.fromReportAnalytics
                      ? true
                      : false
                  }
                  onClick={() =>
                    viewOnClick(lessonItem?.quizId, lessonItem?.id)
                  }
                />
              )}

              {lessonItem?.quizId > 0 && !isAdminLesson ? (
                <div className="quizstartDiv">
                  {quizStatus ? (
                    <span className="quizstatuspass">Passed</span>
                  ) : quizStatus === false ? (
                    <span className="quizstatusfail">Failed</span>
                  ) : (
                    false
                  )}
                </div>
              ) : null}
            </Grid>

            {(lessonItem?.quizId === 0 &&
              lessonItem?.isLessonCompeted === true) ||
            props?.fromReportAnalytics ? (
              ""
            ) : lessonItem?.quizId === 0 && !isAdminLesson ? (
              <>
                <Grid className="checkingBox">
                  <Grid className="checkboxs">
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckedChange}
                      label={
                        "I confirm that I have successfully completed the lesson and taken the quiz."
                      }
                    />
                  </Grid>
                </Grid>
                <Grid className="Btncomplete">
                  <Button
                    varientContained={true}
                    onClick={completeLesson}
                    label="Complete"
                    disabled={checked ? false : true}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {errCompltemsg ? (
              <div className="show-error">{props?.errCompltemsg}</div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LessonDetail;
