import * as resourceAction from "../actions/resourceAction";
import { resourceType } from "../constants/resourceType";
import {
  getResourcesList,
  getResourcesDetails,
  createResources,
  updateResource,
  deleteResources,
} from "../../services/resourceService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* resourceListSaga(params) {
  console.debug("resourceListSaga");
  try {
    const { data, status, error } = yield call(getResourcesList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = resourceType.RESOURCE_PAGE;
    if (params.data?.isSendMail) {
      yield put(resourceAction.getResourceListEmailSuccess());
    } else {
      yield put(
        resourceAction.getResourceListSuccess({
          page,
          data,
        })
      );
    }
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Resource List Failed" };
    yield put(resourceAction.getResourceListFailure(data));
  }
}

export function* createResourceSaga(params) {
  console.debug("createResourceSaga");
  try {
    const { data, status, error } = yield call(createResources, params);
    if (!data && status !== 200) throw apiError(error);
    const page = resourceType.RESOURCE_PAGE;
    yield put(
      resourceAction.createResourceSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Resource  Failed" };
    yield put(resourceAction.createResourceFailure(data));
  }
}

export function* updateResourceSaga(params) {
  console.debug("updateResourceSaga");
  try {
    const { data, status, error } = yield call(updateResource, params);
    if (!data && status !== 200) throw apiError(error);
    const page = resourceType.RESOURCE_PAGE;
    yield put(
      resourceAction.updateResourceSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Resource  Failed" };
    yield put(resourceAction.updateResourceFailure(data));
  }
}

export function* resourceDetailsSaga(params) {
  console.debug("resourceDetailsSaga");
  try {
    const { data, status, error } = yield call(getResourcesDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = resourceType.RESOURCE_PAGE;
    yield put(
      resourceAction.getResourceDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Resource details Failed" };
    yield put(resourceAction.getResourceDetailsFailure(data));
  }
}
function* deleteResourceSaga(payload) {
  try {
    const { data, status } = yield call(deleteResources, payload);
    const casePage = resourceType.RESOURCE_PAGE;

    yield put(
      resourceAction.deleteResourceSuccess({
        casePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || " Resource Delete Failed" };
    yield put(resourceAction.deleteResourceFailure(data));
  }
}

const myResourceSaga = [
  takeLatest(resourceType.GET_RESOURCE_LIST, resourceListSaga),
  takeLatest(resourceType.POST_RESOURCE_PENDING, createResourceSaga),
  takeLatest(resourceType.POST_UPDATE_RESOURCE, updateResourceSaga),
  takeLatest(resourceType.GET_RESOURCE_DETAILS, resourceDetailsSaga),
  takeLatest(resourceType.DELETE_RESOURCE_PENDING, deleteResourceSaga),
];

export default myResourceSaga;
