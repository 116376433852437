import * as reportsAction from "../../storm-water/actions/reportsAction";
import { reportsType } from "../../storm-water/types/reportsType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../../src/utils/helper";
import {
  dateWiseInspectorSummaryList,
  dateWiseSummaryList,
  inspectorSummaryList,
  organisationMapList,
  organisationSummaryList,
  perDayInspectorSummaryList,
} from "../../../services/storm-water/reportsService";

export function* organisationSummaryListSaga(params) {
  try {
    const { data, status, error } = yield call(organisationSummaryList, params);
    if (!data && status !== 200) throw apiError(error);
    const reportsPage = reportsType.REPORTS_PAGE;
    yield put(
      reportsAction.getOrganisationSummaryListSuccess({
        reportsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Organisation Summary list Failed" };
    yield put(reportsAction.getOrganisationSummaryListFailure(data));
  }
}

export function* inspectorSummaryListSaga(params) {
  try {
    const { data, status, error } = yield call(inspectorSummaryList, params);
    if (!data && status !== 200) throw apiError(error);
    const reportsPage = reportsType.REPORTS_PAGE;
    yield put(
      reportsAction.getInspectorSummaryListSuccess({
        reportsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspector Summary list Failed" };
    yield put(reportsAction.getInspectorSummaryListFailure(data));
  }
}

export function* dateWiseSummaryListSaga(params) {
  try {
    const { data, status, error } = yield call(dateWiseSummaryList, params);
    if (!data && status !== 200) throw apiError(error);
    const reportsPage = reportsType.REPORTS_PAGE;
    yield put(
      reportsAction.getDateWiseSummaryListSuccess({
        reportsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Date wise Summary list Failed" };
    yield put(reportsAction.getDateWiseSummaryListFailure(data));
  }
}

export function* perDayInspectorSummaryListSaga(params) {
  try {
    const { data, status, error } = yield call(
      perDayInspectorSummaryList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const reportsPage = reportsType.REPORTS_PAGE;
    yield put(
      reportsAction.getPerDayInspectorSummaryListSuccess({
        reportsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Per Day Inspector Summary list Failed" };
    yield put(reportsAction.getPerDayInspectorSummaryListFailure(data));
  }
}

export function* dateWiseInspectorSummaryListSaga(params) {
  try {
    const { data, status, error } = yield call(
      dateWiseInspectorSummaryList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const reportsPage = reportsType.REPORTS_PAGE;
    yield put(
      reportsAction.getDateWiseInspectorSummaryListSuccess({
        reportsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || " Get Date wise Inspector Summary list Failed" };
    yield put(reportsAction.getDateWiseInspectorSummaryListFailure(data));
  }
}

export function* organisationMapListSaga(params) {
  try {
    const { data, status, error } = yield call(organisationMapList, params);
    if (!data && status !== 200) throw apiError(error);
    const reportsPage = reportsType.REPORTS_PAGE;
    yield put(
      reportsAction.getOrganisationMapListSuccess({
        reportsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Organisation Map list Failed" };
    yield put(reportsAction.getOrganisationMapListFailure(data));
  }
}

const myReportsSaga = [
  takeLatest(
    reportsType.GET_ORGANISATION_SUMMARY_LIST_PENDING,
    organisationSummaryListSaga
  ),

  takeLatest(
    reportsType.GET_INSPECTOR_SUMMARY_LIST_PENDING,
    inspectorSummaryListSaga
  ),

  takeLatest(
    reportsType.GET_DATEWISE_SUMMARY_LIST_PENDING,
    dateWiseSummaryListSaga
  ),

  takeLatest(
    reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_PENDING,
    perDayInspectorSummaryListSaga
  ),

  takeLatest(
    reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_PENDING,
    dateWiseInspectorSummaryListSaga
  ),
takeLatest(
    reportsType.GET_ORGANISATION_MAP_LIST_PENDING,
    organisationMapListSaga
  ),
];

export default myReportsSaga;
