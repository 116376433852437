import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../library/custom/textBox/InputField";
import { findValueMultipleSelect } from "../../../utils/helper";
import MultiSelectBox from "../../../library/custom/selectMenu/MultiSelectBox";
import { NumberField } from "../../../library/custom/numberField/numberField";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import Card from "../../../library/custom/card/Card";
import Witness from "../../../library/custom/witness/Witness";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import vehicleAccidents from "../../../constant/accidentConstant";
import Button from "../../../library/custom/button/Button";
import Vehicle from "./vehicle";
import CreateAction from "../../../library/custom/createAction/CreateAction";
import {
  API_STATUS,
  INCIDENT_STATUS,
  numberOfOccupants,
} from "../../../constant/constant";
import intl from "react-intl-universal";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import FormDropZone from "../../../library/dropZone/FormDropZoneIncident";
const todatDate = new Date();

const VehicleAccidentForm = ({
  incident,
  onSubmit,
  state,
  searchOption,
  city,
  getCity,
  accidentReportData,
  uploadFile,
  fileUploadData,
  incidentId,
  caseId,
  regNumberDatas,
  searchVehicle,
  isLoading,
  cityDataStatus,
  accidentReportDataStatus,
  searchAllUsers,
  allUsers,
  getAssetDetails,
  vehicleDetailsData,
  fileError,
  countError,
  weather
}) => {
  const {
    contributingFactorVehicle,
    associatedIncidentType,
    trafficAtTimeOfIncident,
  } = incident;
  const userId = localStorage.getItem("userId");

  const [associatedIncidentTypes, setAssociatedIncidentTypes] = useState(
    associatedIncidentType
  );

  const [states, setStates] = useState(0);
  const [witnessType, setWitnessType] = useState(false);
  const [disableEmpName, setDisableEmpName] = useState(false);
  const radioButtonOnchangeWitnessHandler = (e) => {
    setWitnessType(JSON.parse(e.target.value));
  };

  const [actionList, setActionlist] = useState([
    {
      assignedPartyName: "",
      file: [],
      isFileAdded: false,
      selectedFileName: "",
      description: "",
      id: Date.now() + Math.floor(Math.random() * 100),
      isCompleted: false,
      isError: false,
      isFileError: false,
    },
  ]);

  const contributingFactorss = (event) => {
    const {
      target: { value },
    } = event;
    setFormValue({
      ...formValue,
      contributingFactor: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleChangeCreateActionDesc = (value, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`description`] = value;
      }
    });
    setActionlist(updatedActionList);
  };

  const handleAddAssigneeCta = (event, users, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`assignedPartyName`] = users?.value || {};
        singleValue[`assignedPartyId`] = users?.key || 0;
        singleValue.isError = false;
        singleValue.isFileError = false;
      }
    });
    setActionlist(updatedActionList);
  };

  const [citys, setCitys] = useState(city);
  useEffect(() => {
    if (accidentReportData && incidentId) {
      const { witness, actions, vehicleAccident } = accidentReportData;
      const weatherDataFind = vehicleAccidents.find(
        (data) => data.key === vehicleAccident?.weatherTypeId
      );

      setWitnessType(vehicleAccident?.isWitness);
      if (accidentReportDataStatus?.status === API_STATUS.SUCCESS) {
        setDisableEmpName(true);
        setCitys([
          { key: vehicleAccident?.cityId, value: vehicleAccident?.city },
        ]);
        setFormValue({
          type: true,
          citys: vehicleAccident?.cityId,
          roadways: vehicleAccident?.roadway,
          interactios: vehicleAccident?.intersectionRoad,
          speedLimit: vehicleAccident?.postedSpeedLimit,
          selectedVehicleAccident: weatherDataFind,
          timeOfIncident: vehicleAccident?.trafficTypeId,
          contributingFactor: vehicleAccident?.contributingFactorsList?.map(
            (item) => item.value
          ),
          policeDepartment: vehicleAccident?.respondingPolice,
          numOfInjuryReport: vehicleAccident?.numberOfInjuries,
          addDetails: vehicleAccident?.additionalDetails,
          descOfIncident: vehicleAccident?.description,
          policeCaseNumber: vehicleAccident?.policeCaseNumber,
          filess: vehicleAccident?.files.map((file) => {
            return {
              fileUrl: file.value,
              id: file.key,
            };
          }),
        });

        const imageUrl = vehicleAccident?.files
          .map((item) => item.value)
          .join("|");
        const imageId = vehicleAccident?.files.map((item) => item.key);
        setFileds(imageId);

        setAddPhoto(imageUrl);
        if (actions.length) {
          const actionList = [];
          actions.forEach((items, index) => {
            actionList.push({
              isFileAdded: items.isFileAdded,
              files: items.files,
              description: items.description,
              assignedPartyName: items.assignedPartyName,
              assignedPartyId: items.assignedPartyId,
              id: items.actionId,
              fileId: items?.files?.map((files) => files.fileId),
            });
          });

          setActionlist(actionList);
        }
        if (witness.length > 0) {
          setIsWitnessDisplay(true);
          const witnessList = [];
          witness.forEach((items, index) => {
            witnessList.push({
              employerName: items.employerName,
              witnessName: items.witnessName,
              jobTitle: items.jobTitle,
              statement: items.statement,
              files: items.files,
              id: items.witnessId,
            });
          });
          setWitnessList(witnessList);
        }

        const stateValue = vehicleAccident?.stateId;
        setStates(stateValue);
        const newVehicleList = [];
        setIsVehicleDisplay(true);
        vehicleAccident?.vehicles?.forEach((items, index) => {
          newVehicleList.push({
            componentId: items.vehicleId,
            vehicleRegNum: items?.regNumber,
            unitNumber: items?.unitNumber,
            makeAndModel: items?.model,
            editModeDisable: items?.regNumber ? true : false,
            numberOfOccu: items?.numberOfOccupants,
            vehicleId: items?.vehicleId,
            incVehicleId: items?.incVehicleId,
            id: index,
            nameOfDerive: items?.nameOfDriver,
            vehicleTowed: items?.isVehicleTowed,
            ticketIssue: items?.isTicketIssuedToDriver,
            filess: items?.files.map((file) => {
              return {
                fileUrl: file.value,
                id: file.key,
              };
            }),
            addPicturesVehical: items?.files
              .map((item) => item.value)
              .join("|"),
            drugsAlcohol: items?.isInfluenceOfDrugs,
            vehiclePictuer: items?.files.map((item) => item.value).join("|"),
            filedIds: items.files.map((item) => item.key),
            selectRegistrationNumber: [
              {
                vehicleId: items?.vehicleId ?? "",
                regNumber: items?.regNumber ?? "",
              },
            ],
          });
        });
        setVehicleList(newVehicleList);
      }
    }
  }, [accidentReportDataStatus]);

  const [selectedData, setSelectedData] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileUploaded, setSelectedFileUploaded] = useState("");

  useEffect(() => {
    if (fileUploadData) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.file = selectedFileUploaded;
          actionLists.isFileAdded = true;
          actionLists.fileId = [fileUploadData?.files[0].id];
          actionLists.selectedFileName = selectedFileName;
          actionLists.isFileError = false;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (countError) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.isFileError = true;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [countError]);

  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    setSelectedData(data);
    setSelectedFileName(fileName);
    setSelectedFileUploaded(fileUploaded);
    uploadFile(fileUploaded);
  };

  const handleCreateActionCancelCta = (data) => {
    const newActionList = actionList.filter(
      (singleAction) => data.id !== singleAction.id
    );
    setActionlist(newActionList);
  };

  const removeActionImage = (singleAction) => {
    const actionListClone = [...actionList];
    actionListClone.forEach((Actionlist) => {
      if (Actionlist?.id === singleAction?.id) {
        Actionlist.fileId = null;
        Actionlist.file = [];
        Actionlist.isFileAdded = false;
        Actionlist.selectedFileName = "";
        Actionlist.isFileError = false;
      }
    });
    setActionlist(actionListClone);
  };

  const handleOnclickAddAction = () => {
    if (actionList.length < 4) {
      const newActionList = [...actionList];
      newActionList.push({
        assignedPartyName: "",
        file: null,
        selectedFileName: "",
        actionDesc: "",
        id: Date.now() + Math.floor(Math.random() * 100),
        isCompleted: false,
        isError: false,
        isFileError: false,
      });
      setActionlist(newActionList);
    }
  };

  const [isWitnessDisplay, setIsWitnessDisplay] = useState(false);
  const [witnessList, setWitnessList] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      witnessName: "",
      employerName: "",
      jobTitle: "",
      statement: "",
      signature: "",
      files: "",
      isErrorWitnessName: false,
      isErrorStatement: false,
      errorWitnessNameMsg: "",
      errorStatementMsg: "",
      filedIds: [],
    },
  ]);

  const handleOnclickWitness = () => {
    setIsWitnessDisplay(true);
    if (witnessList.length < 4) {
      const newWitnessList = [...witnessList];
      newWitnessList.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        witnessName: "",
        employerName: "",
        jobTitle: "",
        statement: "",
        signature: "",
        files: "",
        isErrorWitnessName: false,
        isErrorStatement: false,
        errorWitnessNameMsg: "",
        errorStatementMsg: "",
        filedIds: [],
      });
      setWitnessList(newWitnessList);
    }
  };

  const handleOnChangeWitnessForm = (current, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "uploadMedia" || type === "signature") {
      value = current;
      elementId = type;
    } else {
      value = current.target.value;
      elementId = current.target.id;
    }

    let updatedWitnessList = [...witnessList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === data.id) {
        if (elementId === "name") {
          witnessLists.witnessName = value;
        } else if (elementId === "jobTitle") {
          witnessLists.jobTitle = value;
        } else if (elementId === "statement") {
          witnessLists.statement = value;
        } else if (elementId === "employerName") {
          witnessLists.employerName = value;
        } else if (elementId === "uploadMedia") {
          witnessLists.files = value;
        }
      }
    });

    setWitnessList(updatedWitnessList);
  };
  const intialState = {
    dateOfCase: todatDate,
    timeOfCase: todatDate.getHours() + ":" + todatDate.getMinutes(),
    type: false,
    caseDescription: "",
    location: "",
    citys: 0,
    roadways: "",
    interactios: "",
    speedLimit: "",
    selectedVehicleAccident: "",
    timeOfIncident: 0,
    contributingFactor: "",
    policeDepartment: "",
    numOfInjuryReport: "",
    driveSignature: "",
    addDetails: "",
    descOfIncident: "",
    policeCaseNumber: "",
    imageUrl: "",
    filess: [],
  };

  const [formValue, setFormValue] = useState(intialState);

  const [isVehicleDisplay, setIsVehicleDisplay] = useState(true);
  const [vehicleList, setVehicleList] = useState([
    {
      vehicleRegNum: "",
      unitNumber: "",
      makeAndModel: "",
      numberOfOccu: 0,
      id: Date.now() + Math.floor(Math.random() * 100),
      nameOfDerive: "",
      vehicleTowed: null,
      ticketIssue: null,
      drugsAlcohol: null,
      vehiclePictuer: [],
      isDropDown: false,
      addPicturesVehical: "",
      vehicleId: null,
      incVehicleId: null,
      editModeDisable: false,
      filess: [],
      selectRegistrationNumber: [],
    },
  ]);
  const handleOnclickVehicle = () => {
    setIsVehicleDisplay(true);
    if (vehicleList.length < 4) {
      const newVehicleList = [...vehicleList];
      newVehicleList.push({
        vehicleRegNum: "",
        unitNumber: "",
        makeAndModel: "",
        numberOfOccu: 0,
        id: Date.now() + Math.floor(Math.random() * 100),
        nameOfDerive: "",
        vehicleTowed: null,
        ticketIssue: null,
        drugsAlcohol: null,
        vehiclePictuer: [],
        isDropDown: false,
        addPicturesVehical: "",
        vehicleId: null,
        incVehicleId: null,
        editModeDisable: false,
        filess: [],
        selectRegistrationNumber: [],
      });
      setVehicleList(newVehicleList);
    }
  };

  const vehicleOnChanges = (e, data, values) => {
    const name = e?.target?.name;
    const elementId = e?.target?.id;
    const value = e?.target?.value;
    let updatedvehicleList = [...vehicleList];
    updatedvehicleList.forEach((vehicleLists) => {
      if (values === null) {
        vehicleLists.vehicleRegNum = "";
        setVehicleList(updatedvehicleList);
      }
      if (vehicleLists.id === data.id) {
        if (name === "nameOfDriveData") {
          vehicleLists.numberOfOccu = value;
        }
        if (values) {
          if (values === "uploadFile") {
            vehicleLists.addPicturesVehical = e;
          } else {
            vehicleLists.vehicleRegNum = values?.regNumber;
            vehicleLists.unitNumber = values.unitNumber;
            vehicleLists.makeAndModel = values.makeAndModel;
            vehicleLists.vehicleId = values.vehicleId;
            vehicleLists.isDropDown = true;
            if (values.regNumber) {
              vehicleLists.isErrorVehicleRegNum = false;
              vehicleLists.errorVehicleRegNumMsg = "";
            }

            if (!vehicleLists.selectRegistrationNumber) {
              vehicleLists.selectRegistrationNumber = [];
            }
            vehicleLists.selectRegistrationNumber.push({
              vehicleId: values?.vehicleId ?? "",
              regNumber: values?.regNumber ?? "",
            });

            vehicleLists.selectRegistrationNumber =
              vehicleLists.selectRegistrationNumber.map((reg) => {
                return {
                  ...reg,
                  vehicleId: values?.vehicleId ?? "",
                  regNumber: values?.regNumber ?? "",
                };
              });
          }
        } else {
          vehicleLists.isDropDown = false;
          if (elementId === "unitNumber") {
            vehicleLists.unitNumber = value;
          } else if (elementId === "makeAndModel") {
            vehicleLists.makeAndModel = value;
          } else if (elementId === "vehicleReg") {
            vehicleLists.vehicleRegNum = value;
          }
        }
        if (elementId === "nameOfDrive") {
          vehicleLists.nameOfDerive = value;
        } else if (
          elementId === "vehicleTowedYes" ||
          elementId === "vehicleTowedNo"
        ) {
          vehicleLists.vehicleTowed = JSON.parse(value);
        } else if (
          elementId === "ticketIssueYes" ||
          elementId === "ticketIssueNo"
        ) {
          vehicleLists.ticketIssue = JSON.parse(value);
        } else if (
          elementId === "drugsAlcoholYes" ||
          elementId === "drugsAlcoholNo"
        ) {
          vehicleLists.drugsAlcohol = JSON.parse(value);
        }
      }
    });
    setVehicleList(updatedvehicleList);
  };

  const cancelWitnessList = (id) => {
    const removeWitnessList = witnessList.filter(
      (witness) => witness.id !== id
    );
    setWitnessList(removeWitnessList);
  };
  const cancelVehicleList = (id) => {
    const removevehicleList = vehicleList.filter((vehile) => vehile.id !== id);
    setVehicleList(removevehicleList);
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    getCity(stateId);
    setStates(stateId);
  };
  const [isErrorDescOfIncident, setIsErrorDescOfIncident] = useState(false);
  const [isErrorDescOfIncidentMsg, setIsErrorDescOfIncidentMsg] = useState("");
  const [addPhoto, setAddPhoto] = useState("");

  const handleDeleteWitnessImage = (urls, singleWitness) => {
    const cloneWitness = [...witnessList];
    cloneWitness.forEach((singleWitn) => {
      if (singleWitn.id === singleWitness.id) {
        singleWitn.files = singleWitn.files
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
      }
    });
    setWitnessList(cloneWitness);
  };

  const handleDeleteVehicleImage = (urls, index, singleVehicle) => {
    const cloneWitness = [...vehicleList];
    cloneWitness.forEach((singleWitn) => {
      if (singleWitn.id === singleVehicle.id) {
        singleWitn.addPicturesVehical = singleWitn.addPicturesVehical
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
        singleWitn.filedIds = singleWitn.filedIds.splice(index, 1);
      }
    });
    setVehicleList(cloneWitness);
  };
  const [fileIds, setFileds] = useState([]);
  const handleFileChange = (photos, filedIds) => {
    setFileds(filedIds);
    setAddPhoto(photos);
  };

  const handleDeleteImageURL = (urls, index) => {
    fileIds.splice(index, 1);
    setFileds(fileIds);
    const previousImg = addPhoto.split("|");
    setAddPhoto(previousImg.filter((url) => url !== urls).join("|"));
  };

  const [witnessFilesData, setWitnessFilesData] = useState("");
  const [witnessData, setwitnessData] = useState("");

  const witnessFileUpload = (files, single) => {
    setWitnessFilesData(files);
    setwitnessData(single);
  };
  useEffect(() => {
    let updatedWitnessList = [...witnessList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === witnessData.id) {
        witnessLists.files = witnessFilesData;
      }
    });
    setWitnessList(updatedWitnessList);
  }, [witnessFilesData]);

  const [filesVehicle, setFilesVehicle] = useState("");
  const [vehicleData, setVehicleData] = useState("");
  const [vehicleFileIds, setVehicleFileIds] = useState("");

  const vehicleUpload = (files, fileIds, single) => {
    setFilesVehicle(files);
    setVehicleData(single);
    setVehicleFileIds(fileIds);
  };
  useEffect(() => {
    let updatedWitnessList = [...vehicleList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === vehicleData.id) {
        witnessLists.addPicturesVehical = filesVehicle;
        witnessLists.filedIds = vehicleFileIds;
      }
    });
    setVehicleList(updatedWitnessList);
  }, [filesVehicle]);

  const [numbOfInjuryRecordeds, setNumbOfInjuryRecordeds] = useState(false);
  const [numbOfInjuryRecordedsMsg, setNumbOfInjuryRecordedsMsg] = useState("");

  function mappedData(isSaveAsDraft) {
    let errors = false;
    if (!isSaveAsDraft) {
      let updatedwitnessList = [...witnessList];

      if (updatedwitnessList.length > 0 && witnessType) {
        updatedwitnessList?.forEach((data) => {
          if (data.witnessName.trim() === "") {
            data.witnessName = "";
            data.isErrorWitnessName = true;
            data.errorWitnessNameMsg = "Required";
            errors = true;
          }
          if (data.statement.trim() === "") {
            data.statement = "";
            data.isErrorStatement = true;
            data.errorStatementMsg = "Required";
            errors = true;
          }
        });
      }
      setWitnessList(updatedwitnessList);

      let updatedvehicleList = [...vehicleList];
      updatedvehicleList?.forEach((data) => {
        if (data.nameOfDerive.trim() === "") {
          data.nameOfDerive = "";
          data.isErrorNameOfDrive = true;
          data.errorNameOfDriveMsg = "Please enter this required field.";
          errors = true;
        }
        if (!data.vehicleRegNum) {
          data.isErrorVehicleRegNum = true;
          data.errorVehicleRegNumMsg = "Please enter this required field.";
          errors = true;
        }
      });
      setVehicleList(updatedvehicleList);
      if (formValue?.descOfIncident.trim() === "") {
        setFormValue({
          ...formValue,
          descOfIncident: "",
        });
        setIsErrorDescOfIncident(true);
        setIsErrorDescOfIncidentMsg("Please enter this required field.");
        errors = true;
      }

      if (!formValue?.numOfInjuryReport) {
        setNumbOfInjuryRecordeds(true);
        setNumbOfInjuryRecordedsMsg("Add Number of Injory...");
        errors = true;
      }

      let cloneActionList = [...actionList];
      cloneActionList.forEach((items) => {
        if (items.description || items.isFileAdded) {
          if (!items.assignedPartyId) {
            items.isError = true;
            errors = true;
          } else {
            items.isError = false;
          }
        }
      });
      if (errors) {
        window.scrollTo({
          top: 1050,
          left: 0,
          behavior: "smooth",
        });
        return false;
      }
    }
    const associatedIncidentTypeData = [];
    associatedIncidentTypes.forEach((data) => {
      if (data.isSelected === true) {
        associatedIncidentTypeData.push(data.key);
      }
    });

    const contributingFactorsData = findValueMultipleSelect(
      contributingFactorVehicle,
      formValue.contributingFactor
    );

    const actionListData = [];
    actionList.forEach((data) => {
      actionListData.push({
        assignedPartyId: data.assignedPartyId,
        isFileAdded: data.isFileAdded,
        files: data.fileId,
        description: data.description,
      });
    });
    const filterActionData = actionListData?.filter(
      (item) => item?.assignedPartyId !== undefined
    );

    const witnessListData = [];
    witnessList.forEach((data) => {
      witnessListData.push({
        employerName: data.employerName,
        witnessName: data.witnessName,
        jobTitle: data.jobTitle,
        statement: data.statement,
        files: `${data.files}`,
      });
    });

    const vehicleListData = [];
    vehicleList.forEach((data) => {
      vehicleListData.push({
        nameOfDriver: data?.nameOfDerive,
        vehicleId: data.vehicleId,
        numberOfOccupants: data?.numberOfOccu,
        isVehicleTowed: data?.vehicleTowed,
        isTicketIssuedToDriver: data?.ticketIssue,
        isInfluenceOfDrugs: data?.drugsAlcohol,
        fileIds: data.filedIds,
        incVehicleId: data.incVehicleId,
      });
    });
    const data = {
      caseId: caseId,
      status: INCIDENT_STATUS.SUBMITTED,
      createdBy: parseInt(userId),
      incidentId: incidentId,
      vehicleAccident: {
        stateId: states,
        cityId: formValue.citys,
        roadway: formValue.roadways,
        intersectionRoad: formValue.interactios,
        isWitness: witnessType,
        postedSpeedLimit: formValue.speedLimit,
        weatherTypeId: formValue.selectedVehicleAccident.key,
        trafficTypeId: formValue.timeOfIncident,
        description: formValue.descOfIncident,
        respondingPolice: formValue.policeDepartment,
        policeCaseNumber: formValue.policeCaseNumber,
        numberOfInjuries: formValue.numOfInjuryReport.toString(),
        additionalDetails: formValue.addDetails,
        contributingFactors: contributingFactorsData,
        fileIds: fileIds,
        vehicles: vehicleListData.length > 0 ? vehicleListData : [],
      },
      witness: witnessType ? witnessListData : [],
      actions: filterActionData?.length > 0 ? filterActionData : [],
    };
    return { vehicleAccident: data };
  }

  const [isSubmit, setIsSubmit] = useState(false);
  const submitData = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const saveData = () => {
    const data = mappedData(true);
    data.vehicleAccident.status = INCIDENT_STATUS.DRAFT;
    setIsSubmit(true);
    onSubmit(data);
  };

  useEffect(() => {
    setFormValue({
      ...formValue,
      selectedVehicleAccident: vehicleAccidents[0],
    });
  }, [vehicleAccidents]);

  const editSerch = (data) => {
    getCity(states);
  };

  useEffect(() => {
    if (cityDataStatus.status === API_STATUS.SUCCESS) {
      setCitys(city);
      setDisableEmpName(false);
    }
  }, [cityDataStatus]);

  const searchUsersByProject = (type, text) => {
    searchAllUsers(type, text);
  };

  useEffect(() => {
    let arr = [...vehicleList];
    arr.forEach((i) => {
      if (i.vehicleId === vehicleDetailsData?.data?.vehicalId) {
        i.unitNumber = vehicleDetailsData?.data?.unitNumber ?? "";
        i.makeAndModel = vehicleDetailsData?.data?.makeAndModel ?? "";
      }
    });
    // }
    setVehicleList(arr);
  }, [vehicleDetailsData]);

  return (
    <>
      <div className="overidePersonal" style={{ marginTop: "40px" }}>
        <Grid item container mt={2}>
          <Grid lg={12} xs={12} sm={12} md={12}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.accidentOccur")}
              isRequired={false}
              editNameHandler={() => editSerch()}
              editEmpName={disableEmpName}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <SelectMenu
              listData={state}
              onchangehandler={(event) => handleStateChange(event)}
              value={states}
              disabled={disableEmpName}
              placeholder="Please Select"
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <SelectMenu
              listData={citys ? citys : []}
              onchangehandler={(event) =>
                setFormValue({ ...formValue, citys: event.target.value })
              }
              value={formValue.citys}
              placeholder="Please Select"
              disabled={disableEmpName}
            />
          </Grid>
          <Grid item container mt={0} spacing={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <InputField
                fullWidth={true}
                disabled={false}
                type="text"
                placeholder="Add Roadway Text Here..."
                onChange={(e) =>
                  setFormValue({ ...formValue, roadways: e.target.value })
                }
                value={formValue.roadways}
              />
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <InputField
                fullWidth={true}
                disabled={false}
                value={formValue.interactios}
                type="text"
                onChange={(e) =>
                  setFormValue({ ...formValue, interactios: e.target.value })
                }
                placeholder="Add Nearest Intersection Text Here..."
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <NumberField
              type="text"
              isRequired={false}
              label={intl.get("incident.vehicleAccident.postedSpeedLimit")}
              placeholder="Add Posted Speed Limit Number Here..."
              id="estimatedCost"
              value={formValue.speedLimit}
              onChange={(e) => {
                if (e >= 0 && e <= 900) {
                  setFormValue({ ...formValue, speedLimit: e });
                }
              }}
            />
          </Grid>
          <Grid item lg={8} xs={12} sm={12} md={8}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.weatherAtTime")}
            />
            <Card
              staticCardData={true}
              cardData={weather}
              selectedVehicleAccident={formValue.selectedVehicleAccident}
              cardClickHandler={(val) =>
                setFormValue({
                  ...formValue,
                  selectedVehicleAccident: val,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get(
                "incident.vehicleAccident.trafficAtTimeOfIncident"
              )}
            />
            <SelectMenu
              listData={trafficAtTimeOfIncident?.filter(
                (list) => list.isActive
              )}
              onchangehandler={(e) =>
                setFormValue({ ...formValue, timeOfIncident: e.target.value })
              }
              value={formValue.timeOfIncident}
              placeholder="Please select"
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.contributingFactor")}
            />
            <MultiSelectBox
              listData={contributingFactorVehicle?.filter(
                (list) => list.isActive
              )}
              onChange={contributingFactorss}
              value={formValue.contributingFactor || []}
              placeholder={"Please Select"}
            />
          </Grid>
        </Grid>
        <Grid item container mt={3}>
          <Grid item lg={8} xs={12} sm={12} md={8}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.descriptionOfIncident")}
              isRequired={true}
            />
            <TextEditor
              placeholder={"Add Description of Incident Text Here..."}
              value={formValue.descOfIncident}
              onChange={(value) => {
                setFormValue({ ...formValue, descOfIncident: value });
              }}
              isError={isErrorDescOfIncident}
              errorMsg={isErrorDescOfIncidentMsg}
            />
          </Grid>
        </Grid>
        <Grid item container mt={2} spacing={3}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <InputField
              fullWidth={true}
              disabled={false}
              value={formValue.policeDepartment}
              label={intl.get(
                "incident.vehicleAccident.respondingPoliceDepartment"
              )}
              type="text"
              onChange={(e) =>
                setFormValue({ ...formValue, policeDepartment: e.target.value })
              }
              placeholder="Add Responding Police Department Text Here..."
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <InputField
              fullWidth={true}
              disabled={false}
              value={formValue.policeCaseNumber}
              label={intl.get("incident.vehicleAccident.policeCaseNumber")}
              type="text"
              onChange={(e) =>
                setFormValue({ ...formValue, policeCaseNumber: e.target.value })
              }
              placeholder="Add Police Case Number Here..."
            />
          </Grid>
        </Grid>
        <Grid item container mt={2} spacing={3}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <NumberField
              type="text"
              isRequired={true}
              label={intl.get("incident.vehicleAccident.NumbOfInjuryRecorded")}
              value={formValue.numOfInjuryReport}
              placeholder="Add Number of Injuries Reported Here..."
              isError={!formValue.numOfInjuryReport && numbOfInjuryRecordeds}
              errorMsg={numbOfInjuryRecordedsMsg}
              onChange={(value) =>
                value.length <= 5
                  ? setFormValue({
                      ...formValue,
                      numOfInjuryReport: value,
                    })
                  : formValue.numOfInjuryReport
              }
            />
          </Grid>
        </Grid>
        <Grid item container mt={3}>
          <Grid item lg={8} xs={12} sm={12} md={8} mb={4} mt={2}>
            <FormDropZone
              label={intl.get("incident.vehicleAccident.addPicturesOfRoadway")}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop Files here, or Browse"}
              handleFileChange={handleFileChange}
              isDynamics={true}
              fileType={10}
              imageUrl={addPhoto}
              filess={formValue.filess}
              deleteImageURL={(url, index) => handleDeleteImageURL(url, index)}
              id="addPhoto"
              key="addPhoto"
            />
          </Grid>
        </Grid>
        <Grid className="inputGrid" container mb={2}>
          <Grid lg={4} xs={12} sm={12} md={4}>
            <FormLabels label={intl.get("incident.vehicleAccident.witness")} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={witnessType === true}
                value={true}
                label="Yes"
                onChange={radioButtonOnchangeWitnessHandler}
                name="witness"
              ></RadioButton>
              <RadioButton
                checked={witnessType === false}
                value={false}
                label="No"
                onChange={radioButtonOnchangeWitnessHandler}
                name="witness"
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>
        {witnessType &&
          witnessList.map((singleWitness, index) => (
            <Witness
              key={index}
              keyNo={index + 1}
              singleWitness={singleWitness}
              handleOnChangeWitnessForm={handleOnChangeWitnessForm}
              optionsData={searchOption?.users}
              isErrorWitnessName={singleWitness.isErrorWitnessName}
              isErrorStatement={singleWitness.isErrorStatement}
              errorWitnessNameMsg={singleWitness.errorWitnessNameMsg}
              errorStatementMsg={singleWitness.errorStatementMsg}
              id={singleWitness.id}
              cancelWitnessList={(id) => cancelWitnessList(id)}
              deleteImageURL={(url) =>
                handleDeleteWitnessImage(url, singleWitness)
              }
              witnessFileUpload={(photo) =>
                witnessFileUpload(photo, singleWitness)
              }
            />
          ))}
        {witnessType && (
          <div className="addBox">
            <Button
              varientTextGreen={true}
              disabled={witnessList.length === 4 ? true : false}
              label={intl.get("incident.vehicleAccident.addWitness")}
              onClick={handleOnclickWitness}
            />
          </div>
        )}
        <br />
        <br />
        <FormLabels label={intl.get("incident.vehicleAccident.vehicle")} />
        {isVehicleDisplay &&
          vehicleList.map((data, index) => (
            <Vehicle
              key={index}
              nameOfDriveValue={data.nameOfDerive}
              vehicleRegNum={data.selectRegistrationNumber[0]}
              unitNumber={data.unitNumber}
              makeAndModel={data.makeAndModel}
              filess={data.filess}
              numberOfOccu={data.numberOfOccu}
              vehicleTowed={data.vehicleTowed}
              ticketIssue={data.ticketIssue}
              drugsAlcohol={data.drugsAlcohol}
              editModeDisable={data.editModeDisable}
              vehicleListLength={index}
              vehiclePictuer={data.vehiclePictuer}
              keyNo={index + 1}
              vehicleOnChange={(e, value) => vehicleOnChanges(e, data, value)}
              searchVehicle={searchVehicle}
              numberOfOccupants={numberOfOccupants}
              isErrorNameOfDrive={data.isErrorNameOfDrive}
              errorNameOfDriveMsg={data.errorNameOfDriveMsg}
              isErrorVehicleRegNum={data.isErrorVehicleRegNum}
              errorVehicleRegNumMsg={data.errorVehicleRegNumMsg}
              regNumberDatas={regNumberDatas}
              isDropDown={data.isDropDown}
              addPicturesVehical={data.addPicturesVehical}
              vehicleUpload={(photo, fileIds) =>
                vehicleUpload(photo, fileIds, data)
              }
              id={data.id}
              deleteImageURL={(url, index) =>
                handleDeleteVehicleImage(url, index, data)
              }
              cancelVehicleList={(id) => cancelVehicleList(id)}
              incidentId={incidentId}
              isLoading={isLoading}
              getAssetDetails={getAssetDetails}
            />
          ))}
        <Grid container>
          <Grid item lg={5} xs={12} sm={12} md={5}>
            <div className="addBox">
              <Button
                varientTextGreen={true}
                disabled={vehicleList.length === 4 ? true : false}
                label={intl.get(
                  "incident.vehicleAccident.AddCompanyVehicleInvolved"
                )}
                onClick={handleOnclickVehicle}
              />
            </div>
          </Grid>
        </Grid>
        {actionList.map((singleAction, index) => (
          <CreateAction
            key={index}
            singleAction={singleAction}
            handleChangeActionDescription={handleChangeCreateActionDesc}
            handleAddAssigneeCta={handleAddAssigneeCta}
            handleUploadFile={handleUploadFileMethod}
            handleCancelCta={handleCreateActionCancelCta}
            optionsData={allUsers?.users ?? []}
            searchUsers={searchUsersByProject}
            incidentId={accidentReportData?.incidentId}
            isLoading={isLoading}
            isError={singleAction.isError}
            removeActionImage={removeActionImage}
            fileError={fileError}
          />
        ))}
        <div className="addBox">
          <Button
            varientTextGreen={true}
            disabled={actionList.length === 4 ? true : false}
            label={intl.get("incident.case.addAction")}
            onClick={handleOnclickAddAction}
          />
        </div>
        <Grid container mt={3}>
          <Grid item lg={8} xs={12} sm={12} md={8}>
            <FormLabels label={intl.get("incident.case.addAdditinalDetails")} />
            <TextEditor
              placeholder={"Add Additional Details Text Here..."}
              value={formValue.addDetails}
              onChange={(value) =>
                setFormValue({ ...formValue, addDetails: value })
              }
            />
          </Grid>
        </Grid>
        <Grid container mt={3} spacing={10} alignItems="center">
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="center">
            <Button
              varientText={true}
              disabled={isSubmit ? true : false}
              label={intl.get("incident.case.saveAsDraft")}
              onClick={saveData}
            />
          </Grid>
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="right">
            <Button
              varientContained={true}
              disabled={isSubmit ? true : false}
              label={intl.get("incident.case.submit")}
              onClick={submitData}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VehicleAccidentForm;
