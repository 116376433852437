import React, { useEffect } from "react";
//import ToolBoxTalkList from "../component/toolBoxTalkList/toolBoxTalkList";
import ToolBoxTalkAdminList from "../component/toolBoxTalkList/toolBoxTalkAdminList";
import { useDispatch, useSelector } from "react-redux";
import LmsHeader from "../component/lmsHeader/lmsHeader";
import {
  ACCESS_DENIED,
  TOOL_BOX_CREATE,
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import {
  PAGE_SIZE,
  ORG_ID,
  API_STATUS,
} from "../../../constant/constant";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const ToolboxContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const deleteToolBoxTalkData = useSelector((state) => state.deleteToolBoxTalk);
  const deleteStatus = deleteToolBoxTalkData?.status;
  const backTabId = location?.state?.backTabId;
  const tabId = location?.state?.routeProps?.tabId;
  const deleteScheduleToolBoxTalkData = useSelector(
    (state) => state.deleteScheduleToolBoxTalk
  );

  const deleteScheduleToolBoxTalkStatus = deleteScheduleToolBoxTalkData?.status;
  const deleteisloading = deleteScheduleToolBoxTalkData?.isLoading;
  window.history.replaceState({}, document.title);

  const getToolBoxTalkListResult = useSelector(
    (state) => state.getToolBoxTalkList?.data
  );

  const getAdminToolBoxTalkListData = useSelector(
    (state) => state.getAdminToolBoxTalkList?.data
  );

  const showLoader = useSelector(
    (state) => state.getAdminToolBoxTalkList?.isLoading
  );

  const showLoaderSchedule = useSelector(
    (state) => state.getToolBoxTalkList?.isLoading
  );
  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );
  const dataHeader = {
    title: "Safety Meetings",
    btnText: "Create Safety Meeting",
    btnLink: TOOL_BOX_CREATE,
    menu: [],
  };
  if (checkPrivileges([12, 90])) {
    dataHeader.btnText = "Create Safety Meeting";
  }
  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: 0,
    status: "",
    categoryIds: "",
    tabId: "0",
    isAssigned: ""
  };
  const defaultAdminData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: 0,
    status: "",
    categoryIds: "",
  };
  useEffect(() => {
    if (!tabId && backTabId != 1) {
      getAdminToolBoxTalkList(defaultAdminData);
    } else {
      getToolBoxTalkListData(defaultData);
    }
  }, []);
  const getToolBoxTalkListData = (data) => {
    dispatch(toolboxAction.clearScheduleDetailToolBoxTalk());
    dispatch(toolboxAction.getToolBoxTalkList(data));
  };

  const getAdminToolBoxTalkList = (data) => {
    dispatch(toolboxAction.getAdminToolBoxTalkList(data));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [20],
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  useEffect(() => {
    if (backTabId) {
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        userId: 0,
        tabId: backTabId == 1 ? "0" : "1",
        isAssigned: ""
      };

      getToolBoxTalkListData(data);
    }
  }, [backTabId]);

  // const deleteScheduleMeeting = (data) => {
  //   dispatch(toolboxAction.deleteScheduleToolBoxTalk(data));
  // };

  const deleteAdminToolBoxTalks = (toolboxId) => {
    dispatch(toolboxAction.deleteToolBoxTalk(toolboxId));
  };
  useEffect(() => {
    if (deleteStatus == API_STATUS.SUCCESS) {
      getAdminToolBoxTalkList({
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        userId: 0,
        status: "",
      });
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (deleteScheduleToolBoxTalkStatus == API_STATUS.SUCCESS) {
      getToolBoxTalkListData({
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        userId: 0,
        status: "",
        tabId: 0
      });
    }
  }, [deleteScheduleToolBoxTalkStatus]);

  getToolBoxTalkListResult?.saftyMeetingList.map((Dta, index) => {
    return (Dta["safetyid"] = index + 1);
  });
  const deleteScheduleToolBoxTalk = (data) => {
    dispatch(toolboxAction.deleteScheduleToolBoxTalk(data));
  };

  return (
    <>
      {checkPrivileges([12, 90, 91]) ? (
        <>
          {deleteisloading && <Loader />}
          <div className="toolboxadmin">
            {checkPrivileges([12, 90]) ? (
              <LmsHeader dataHeader={dataHeader} />
            ) : (
              ""
            )}
          </div>

          {/* Inspector View */}
          {/*
          TODO:
          {checkPrivileges([91]) && !checkPrivileges([12, 90]) && (
            <ToolBoxTalkList
              categoryListData={getDropdownDataKeyValue}
              getToolBoxTalkListResult={getToolBoxTalkListResult}
              getToolBoxTalkListData={getToolBoxTalkListData}
              deleteScheduleMeetings={deleteScheduleMeeting}
              showLoader={showLoaderSchedule}
            />
          )} */}
          {/* Admin View */}
          {checkPrivileges([12, 90, 91]) && (
            <ToolBoxTalkAdminList
              categoryListData={getDropdownDataKeyValue}
              ToolBoxTalkListData={getAdminToolBoxTalkListData} // data
              getToolBoxTalkListAdmin={getAdminToolBoxTalkList} // function
              showLoader={showLoader}
              deleteAdminToolBoxTalks={deleteAdminToolBoxTalks}
              ToolBoxTalkListDataSchedule={getToolBoxTalkListResult} // data for schedule
              getToolBoxTalkList={getToolBoxTalkListData} // function  for schedule
              deleteScheduleToolBoxTalk={deleteScheduleToolBoxTalk}
              showLoaderSchedule={showLoaderSchedule}
              tabId={tabId}
              backTabId={backTabId}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ToolboxContainer;
