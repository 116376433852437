import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";
import { PRODUCT_ID } from "../constant/storm-water/constant";

export const getContractorList = async ({ data }) => {
  const {
    pageSize,
    page,
    search,
    gradeId,
    divisionId,
    isSendMail,
    type = "",
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/GetContractorsList?organisationId=${ORG_ID()}&isSendMail=${isSendMail}&page=${page}&pageSize=${pageSize}&search=${search}&gradeId=${gradeId}&divisionId=${divisionId}&productId=${PRODUCT_ID()}&typeId=${type}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createContractor = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/AddContractor`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateContractor = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/UpdateContractor`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteContractor = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/DeleteContractor/${
    payload?.payload
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getContractorReport = async (contractorId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/GetContractorDetails?ContractorId=${contractorId}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPersonalContractorList = async ({ data }) => {
  const { pageSize, page, search, contractorId, isSendMail } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/GetContractorsPersonnelList?organisationId=${ORG_ID()}&isSendMail=${isSendMail}&contractorId=${contractorId}&page=${page}&pageSize=${pageSize}&search=${search}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createPersonalContractor = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/AddContractorPersonnel`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updatePersonalContractor = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/UpdateContractorPersonnel`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deletePersonalContractor = async (payload) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/DeleteContractorPersonnel/${payload.payload}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
      //data,
    });
  } catch (err) {
    return err;
  }
};
export const getPersonalContractorReport = async (contractorId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/GetContractorPersonnelDetails?ContractorPersonnelId=${contractorId}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getOnboardedContractorList = async ({ data }) => {
  const { pageSize, page, search, Status, isSendMail } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/GetOnboardedContractorList?Status=${Status}&isSendMail=${isSendMail}&Search=${search}&page=${page}&pageSize=${pageSize}&productId=${PRODUCT_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateOnboardedContractorList = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/UpdateContractorCognito`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getContractorConfigurationDetails = async (data) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Contractor/GetOnboardContractorDetails?OnboardUserId=${data.data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateContractorOnboardDetails = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/UpdateOnboardContractor`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateContractorOnboardStatus = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/ContractorEnableDisable`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// search sub contractor

export const searchSubContractor = async (serachText) => {
  if (serachText?.trim()) {
    const url = `${getApiUrl().searchApiUrl}/v1/searchcontractor?search=${
      serachText ? serachText?.trim() : ""
    }`;
    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};

export const addSubContractorinClient = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Contractor/AddSubContractorInClient`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
