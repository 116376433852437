import React, { useState, useEffect } from "react";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Box,
  Modal,
} from "@mui/material";
import Checkbox from "../../../../library/checkbox/checkbox";
import Buttons from "../../../../library/custom/button/Button";
import Button from "../../../../library/custom/button/Button";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./AddHeaderConfiguration.scss";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DialogBox from "../../../../library/common/DialogBox";

import {
  HBNEXT_ORG_ID,
  INSPECTION_HEADER_RESPONSE_TYPE,
  ISwitch,
  LOGIN_ORG_ID,
  ORG_ID,
  USER_ID,
} from "../../../../constant/constant";
import AddHeaderConfiguration from "./AddHeaderConfiguration";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { InputField } from "../../../../library/custom/textBox/InputField";

const HeaderConfiguration = (props) => {
  const dataBack = {
    title: "Header Configuration",
    route: INSPECTION_ADMIN,
  };
  const [newlistInspectionTypeFields, setNewlistInspectionTypeFields] =
    useState([]);
  const [questionSet, setQuestionSet] = useState();
  const [addCustomField, setAddCustomField] = useState(false);
  const [checked, setChecked] = useState();
  const [open, setOpen] = React.useState(false);
  const [openDefaultHeaderModal, setOpenDefaultHeaderModal] = useState(false);
  const [defaultHeaderModalValue, setDefaultHeaderModalValue] = useState();

  const handleQuestionSetListChange = (e) => {
    setQuestionSet(e?.target?.value);
    const data = {
      questionSetId: e?.target?.value,
    };
    props?.getInspectionFieldsMap(data);
  };

  const disbleSelectHandler = (item) => {
    let isSelectValue = false;

    if (
      parseInt(ORG_ID()) !== parseInt(HBNEXT_ORG_ID) &&
      item?.isDefault === true
    ) {
      isSelectValue = true;
    } else {
      isSelectValue = false;
    }
    return isSelectValue;
  };

  const checkBoxHandler = (item) => {
    let isValue = false;
    if (
      parseInt(ORG_ID()) !== parseInt(HBNEXT_ORG_ID) &&
      item?.isMandatory &&
      item?.isDefault &&
      ![4, 5, 6]?.includes(item?.key)
    ) {
      isValue = true;
    } else if (item?.isSelected && [4, 5, 6]?.includes(item?.key)) {
      isValue = false;
    } else if (!item?.isSelected) {
      isValue = true;
    } else {
      isValue = false;
    }
    return isValue;
  };

  const handleMandatoryChange = (item) => {
    const newCloneList = [...newlistInspectionTypeFields];
    newCloneList.forEach((lists) => {
      if (parseInt(lists.key) === parseInt(item.key)) {
        lists.isMandatory = !lists.isMandatory;
        lists.key = item?.key;
        lists.value = item?.value;
        lists.isDefault = item?.isDefault;
      }
    });
    setNewlistInspectionTypeFields(newCloneList);
  };
  const handleDefaultChange = (item) => {
    const newCloneList = [...newlistInspectionTypeFields];
    newCloneList.forEach((lists) => {
      if (parseInt(lists.key) === parseInt(item.key)) {
        lists.isDefault = !lists.isDefault;
        lists.key = item?.key;
        lists.value = item?.value;
        lists.isMandatory = item?.isMandatory;
      }
    });
    setNewlistInspectionTypeFields(newCloneList);
  };
  const handleChange = (item) => {
    const newCloneList = [...newlistInspectionTypeFields];
    newCloneList.forEach((lists) => {
      if (parseInt(lists.key) === parseInt(item.key)) {
        lists.isSelected = !lists.isSelected;
        lists.isDefault = false;
        lists.isMandatory = false;
        lists.key = item?.key;
        lists.value = item?.value;
      }
    });
    setNewlistInspectionTypeFields(newCloneList);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(newlistInspectionTypeFields);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);

    const formattedList = newOrder.map((item, index) => ({
      ...item,
      sequenceNumber: index + 1,
    }));
    setNewlistInspectionTypeFields(formattedList);
  };
  const onSave = () => {
    const data = {
      questionSetId: questionSet,
      userId: parseInt(USER_ID()),
      listInspectionTypeFields: newlistInspectionTypeFields,
    };
    props.updateInspectionFieldsMapData(data);
  };
  const defaulthandle = () => {
    setChecked(defaultHeaderModalValue);
    const data = {
      questionSetId: questionSet,
      userId: parseInt(USER_ID()),
      listInspectionTypeFields: null,
    };
    props.updateInspectionFieldsMapData(data);
    setOpenDefaultHeaderModal(false);
  };
  const handleChangeDefault = (value) => {
    if (parseInt(value) === 1) {
      setOpenDefaultHeaderModal(true);
      setDefaultHeaderModalValue(value);
    } else {
      setChecked(value);
      const data = {
        questionSetId: questionSet,
        userId: parseInt(USER_ID()),
        listInspectionTypeFields: null,
      };
      props.updateInspectionFieldsMapData(data);
    }
  };

  useEffect(() => {
    if (
      (props?.updateInspectionFieldsMap?.status === "SUCCESS" &&
        props?.updateInspectionFieldsMap?.isLoading === false &&
        props?.clearInspectionTypeFieldsMap) ||
      questionSet
    ) {
      const data = {
        questionSetId: questionSet,
      };
      props?.getInspectionFieldsMap(data);
    }

    if (
      props?.updateInspectionFieldsMap?.status === "SUCCESS" &&
      props?.updateInspectionFieldsMap?.isLoading === false &&
      props?.clearInspectionTypeFieldsMap
    ) {
      setAddCustomField(false);
    }
  }, [questionSet, props?.updateInspectionFieldsMap]);

  const onCancel = () => {
    setQuestionSet();
    setNewlistInspectionTypeFields([]);
  };
  
  useEffect(() => {
    if (props?.inspectionFieldsMap || props?.updateInspectionFieldsMap?.status === "SUCCESS") {
      setNewlistInspectionTypeFields(
        props?.inspectionFieldsMap?.listInspectionTypeFields
      );
    }
  }, [props?.inspectionFieldsMap, props?.updateInspectionFieldsMap]);

  useEffect(() => {
    setNewlistInspectionTypeFields();
  }, []);

  const addCustomFields = () => {
    setAddCustomField(!addCustomField);
  };
  const backHandler = () => {
    setAddCustomField(false);
  };

  const handleClose = () => setOpen(false);

  const [formValueEdit, setFormValueEdit] = useState({
    key: "",
    responsetype: "",
    optionList: [],
  });

  const [optionListError, setOptionListError] = useState(false);

  const editHeaderField = (options) => {
    const updatedOptionList = (options.optionList || []).map((option) => ({
      ...option,
      disabledField: true,
    }));

    setFormValueEdit({
      key: options.key,
      responsetype: options.responsetype,
      optionList: updatedOptionList,
    });
    setOpen(true);
  };

  const handleOptionEdit = (index, newValue) => {
    const updatedOptions = [...formValueEdit.optionList];
    updatedOptions[index].value = newValue;
    setFormValueEdit({
      ...formValueEdit,
      optionList: updatedOptions,
    });
  };

  const handleOptionRemove = (index) => {
    const updatedOptions = formValueEdit.optionList.filter(
      (_, i) => i !== index
    );
    setFormValueEdit({
      ...formValueEdit,
      optionList: updatedOptions,
    });
  };

  const handleAddOption = () => {
    const newOption = { key: 0, value: "", disabledFeild: false };
    setFormValueEdit({
      ...formValueEdit,
      optionList: [...formValueEdit.optionList, newOption],
    });
  };

  const onModalSave = () => {
    if (
      formValueEdit.optionList &&
      formValueEdit.optionList.some((item) => item.value === "")
    ) {
      setOptionListError(true);
      return;
    }
    const updatedList = newlistInspectionTypeFields.map((item) => {
      if (item.key === formValueEdit.key) {
        return {
          ...item,
          optionList: formValueEdit.optionList,
        };
      }
      return item;
    });
    setNewlistInspectionTypeFields(updatedList);
    const data = {
      questionSetId: questionSet,
      userId: parseInt(USER_ID()),
      listInspectionTypeFields: updatedList,
    };
    props.updateInspectionFieldsMapData(data);
    handleClose();
    setOptionListError(false);
  };

  useEffect(() => {
    setChecked(props?.inspectionFieldsMap?.isGlobalHeader ? 1 : 2);
  }, [props?.inspectionFieldsMap?.isGlobalHeader]);

  return (
    <>
      {openDefaultHeaderModal && (
        <DialogBox
          open={openDefaultHeaderModal}
          rows={[]}
          header={" "}
          deletehandle={defaulthandle}
          handleCloseModal={() => setOpenDefaultHeaderModal(false)}
          content={
            "Are you sure you want to go back to the default header? You will lose custom header fields."
          }
          deleteIcon={false}
          view
        />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer saftyFieldsModal">
          <Box className="modalBox">
            <div className="modalInputBox">
              {formValueEdit.optionList.length > 0 && (
                <div className="modalOptionBox">
                  <div className="optionTitle">
                    Edit{" "}
                    {formValueEdit?.responsetype === 4
                      ? "Single Select"
                      : formValueEdit?.responsetype === 5
                      ? "Multi Select"
                      : formValueEdit?.responsetype === 6
                      ? "Checkbox"
                      : formValueEdit?.responsetype === 7
                      ? "Radio"
                      : ""}{" "}
                    Option List
                  </div>
                  <div className="optionListBox">
                    {formValueEdit.optionList.map((option, index) => (
                      <div className="optionInnerList">
                        <div className="optionList" key={option.id}>
                          <InputField
                            type="text"
                            value={option.value}
                            onChange={(e) =>
                              handleOptionEdit(index, e.target.value)
                            }
                            label={`Option ${index + 1}`}
                            placeholder="Option Value"
                            disabled={option?.disabledField}
                          />
                        </div>

                        {formValueEdit?.optionList?.length > 1 && (
                          <div className="actions">
                            <Button
                              varientText={true}
                              label="Remove"
                              onClick={() => handleOptionRemove(index)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    <Grid container mb={1}>
                      {optionListError && (
                        <div className="error">Option is Required</div>
                      )}
                    </Grid>

                    <Button
                      varientContained={true}
                      label="Add Option"
                      onClick={handleAddOption}
                    />
                  </div>
                </div>
              )}
            </div>

            <Grid container className="modalFooterBox">
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={handleClose}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientContained={true}
                  label={"Save"}
                  onClick={onModalSave}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>

      {addCustomField ? (
        <AddHeaderConfiguration
          newlistInspectionTypeFields={newlistInspectionTypeFields}
          updateInspectionFieldsMapData={props.updateInspectionFieldsMapData}
          questionSet={questionSet}
          backHandler={backHandler}
        />
      ) : (
        <>
          <Grid className="inspectionOverRide inspectionAcknowledgement">
            <BackBtn dataBack={dataBack} />
          </Grid>

          <Grid className="inspectionOverRide safty">
            <Grid container item md={12} xs={12} className="addShadowBox">
              <Grid container>
                <Grid item md={10} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={12} xs={12} mb={3}>
                      <FormControl fullWidth>
                        <FormLabels label="Inspection Form" isRequired={true} />
                        <SelectMenu
                          placeholder="Please Select"
                          listData={props?.questionSetList ?? []}
                          value={questionSet ?? ""}
                          onchangehandler={(e) =>
                            handleQuestionSetListChange(e)
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid md={12} xs={12} item>
                      <div className="insTyF">
                        <div className="insTyFLeft insTyFLeftS">
                          Select Field
                        </div>
                        <div className="insTyFRight insTyFRightM">
                          Mandatory
                        </div>
                        {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                          <div className="insTyFRight insTyFRightD">
                            Default
                          </div>
                        )}
                        <div className="insTyFRight insTyFRightE"></div>
                      </div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="inspectionFields">
                          {(provided) => (
                            <div
                              className="insTyBox"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {newlistInspectionTypeFields &&
                                newlistInspectionTypeFields
                                  .sort(
                                    (a, b) =>
                                      a.sequenceNumber - b.sequenceNumber
                                  )
                                  .map((item, index) => (
                                    <Draggable
                                      key={item?.key}
                                      draggableId={item?.key.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          className="insTyList"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div className="insTyFLeft insTyFLeftS">
                                            <div className="dragIcon">
                                              <DragIndicatorIcon />
                                            </div>
                                            <Checkbox
                                              checked={
                                                item?.isSelected ? true : false
                                              }
                                              onChange={() =>
                                                handleChange(item)
                                              }
                                              label={item?.value}
                                              disabled={
                                                disbleSelectHandler(item) ||
                                                item?.isDisabled
                                              }
                                            />
                                          </div>
                                          <div className="insTyFRight insTyFRightM">
                                            <Checkbox
                                              checked={
                                                item?.isSelected &&
                                                item?.isMandatory
                                                  ? true
                                                  : false
                                              }
                                              onChange={() =>
                                                handleMandatoryChange(item)
                                              }
                                              label={""}
                                              disabled={
                                                checkBoxHandler(item) ||
                                                item?.isDisabled
                                              }
                                            />
                                          </div>
                                          {parseInt(ORG_ID()) ===
                                            parseInt(HBNEXT_ORG_ID) && (
                                            <div className="insTyFRight insTyFRightD">
                                              <Checkbox
                                                checked={
                                                  item?.isSelected &&
                                                  item?.isDefault
                                                    ? true
                                                    : false
                                                }
                                                onChange={() =>
                                                  handleDefaultChange(item)
                                                }
                                                label={""}
                                                disabled={
                                                  !item?.isSelected ||
                                                  parseInt(LOGIN_ORG_ID()) !==
                                                    parseInt(HBNEXT_ORG_ID) ||
                                                  item?.isDisabled
                                                }
                                              />
                                            </div>
                                          )}
                                          {![1, 2, 3, 4, 5, 6]?.includes(
                                            item?.key
                                          ) &&
                                            (item?.responsetype ===
                                              INSPECTION_HEADER_RESPONSE_TYPE.SINGLE_SELECT ||
                                              item?.responsetype ===
                                                INSPECTION_HEADER_RESPONSE_TYPE.MULTI_SELECT ||
                                              item?.responsetype ===
                                                INSPECTION_HEADER_RESPONSE_TYPE.RADIOBUTTON ||
                                              item?.responsetype ===
                                                INSPECTION_HEADER_RESPONSE_TYPE.CHECKBOX) && (
                                              <div className="insTyFRight insTyFRightE">
                                                <ReportRowManipulte
                                                  rows={item}
                                                  edit
                                                  editLabel={`Edit`}
                                                  editOnClick={() =>
                                                    editHeaderField(item)
                                                  }
                                                  className={
                                                    "customHeaderSafty"
                                                  }
                                                />
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Grid>

                    {questionSet && ORG_ID() != HBNEXT_ORG_ID && (
                      <Grid
                        container
                        spacing={2}
                        pl={1}
                        justifyContent="flex-end"
                      >
                        <Grid item lg={12} md={12} xs={12} mt={2}>
                          <Grid className="catListBox">
                            <Grid className="closeIcon">
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormControl fullWidth>
                                  <FormLabels
                                    label={"Status"}
                                    isRequired={false}
                                  />
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    className="radioGroup"
                                  >
                                    <RadioButton
                                      checked={parseInt(checked) === 1}
                                      value="1"
                                      label="Default Header"
                                      onChange={(e) => handleChangeDefault(1)}
                                      disabled={
                                        props?.inspectionFieldsMap
                                          ?.isGlobalHeader
                                      }
                                    ></RadioButton>

                                    <RadioButton
                                      checked={parseInt(checked) === 2}
                                      value="2"
                                      label="Custom Header"
                                      onChange={(e) => handleChangeDefault(2)}
                                      disabled={
                                        props?.inspectionFieldsMap
                                          ?.isGlobalHeader
                                      }
                                    ></RadioButton>
                                  </RadioGroup>
                                </FormControl>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {questionSet && (
                      <Grid container mt={2}>
                        <Grid item md={12} xs={12}>
                          <Button
                            varientContained={true}
                            label={"Add Custom Fields"}
                            onClick={addCustomFields}
                          />
                        </Grid>
                      </Grid>
                    )}

                    <Grid container mt={10} md={11} className="insGenBt">
                      <Grid item lg={6} md={12} xs={12}>
                        <div className="insBackBtn">
                          <BackBtn dataBack={dataBack} />
                        </div>
                      </Grid>
                      <Grid item lg={6} md={12} xs={12}>
                        <Grid
                          container
                          item
                          lg={12}
                          xs={12}
                          className="divisionBtnBox"
                        >
                          <Grid lg={6} xs={12} sm={6} item>
                            <Buttons
                              varientText={true}
                              onClick={onCancel}
                              label="Cancel"
                            />
                          </Grid>
                          <Grid lg={6} xs={12} sm={6} item>
                            <Button
                              varientContained={true}
                              label={"Save"}
                              onClick={onSave}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default HeaderConfiguration;
