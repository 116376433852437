import React, { useEffect, useState } from "react";
import {
  ORG_ID,
  PAGE_SIZE_100,
  USER_ID,
  USER_INVOLVEDPARTY,
  USER_TRAININGS_TYPE,
} from "../../../../constant/constant";
import { Grid, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../lms/component/tabPanel/tabPanel";
// import { Table } from "react-bootstrap";
import { getFormatedDate, useInfiniteScroll } from "../../../../utils/helper";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { useNavigate } from "react-router-dom";
import {
  COURSESDETAIL,
  LESSONDETAIL,
  TOOL_BOX_DETAIL,
} from "../../../../constant/routeContant";
import Loader from "../../../../library/common/Loader";
import Table from "../../../../library/custom/table/table";
// import UserKudosAsInvolvedParty from "./userKudosAsInvolvedParty";
// import UserCorrectiveAsInvolvedParty from "./userCorrectiveAsInvolvedParty";

const UserTrainings = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setData([]);
  }, []);

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }

  useEffect(() => {
    if (props?.coursesUserListData && value === 0) {
      console.log(value);
      setData((prevData) => [
        ...prevData,
        ...props.coursesUserListData?.courses,
      ]);
    }
  }, [props?.coursesUserListData]);
  useEffect(() => {
    if (props?.lessionUserListData && value === 1) {
      console.log(value);
      setData((prevData) => [
        ...prevData,
        ...props.lessionUserListData?.lessons,
      ]);
    }
  }, [props?.lessionUserListData]);

  useEffect(() => {
    if (props?.getToolBoxTalkListResult) {
      console.log(value);
      setData((prevData) => [
        ...prevData,
        ...props.getToolBoxTalkListResult?.saftyMeetingList,
      ]);
    }
  }, [props?.getToolBoxTalkListResult]);
  const handleChange = (event, newValue) => {
    console.log(props);
    setValue(newValue);
    setData([]);
    // const { apiData } = props?.drillDetail;
    const tab =
      newValue === 0 ? "course" : newValue === 1 ? "lesson" : "safety";
    const data =
      newValue === 2
        ? {
            pageNumber: 1,
            pageSize: PAGE_SIZE_100,
            search: "",
            catIds: "",
            userId: props.apiData?.userId,
            status: "",
            tabId: 1,
            fromDate: props?.apiData.startDate,
            toDate: props?.apiData.endDate,
            divisionId:props?.apiData?.divisionId,
          }
        : {
            pageNumber: 1,
            pageSize: PAGE_SIZE_100,
            search: "",
            catIds: "",
            statusId:
              props.page === "UsersWithHighestCourseCompletion" ||
              props.page === "UserTrainingsIncomplete"
                ? 2
                : 1,
            userId: props.apiData?.userId,
            orgId: ORG_ID(),
            fromDate: props?.apiData.startDate,
            toDate: props?.apiData.endDate,
            divisionId:props?.apiData?.divisionId,
          };
    props.getMoretDetails(data, tab);
  };

  const fetchMoreData = async (pageNumber) => {
    // const { apiData, reportDetails } = props.drillDetail;
    const data = {
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE_100,
      search: "",
      catIds: "",
      statusId: 2,
      userId: props.apiData?.userId,
      orgId: ORG_ID(),
      fromDate: props?.apiData.startDate,
      toDate: props?.apiData.endDate,
      divisionId:props?.apiData?.divisionId,
    };
    try {
      const response = await props.getMoretDetails(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    fetchMoreData(pageNumber);
    setPage(pageNumber);
  };

  const handleSortCourse = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const handleOnClickCourse = (id) => {
    props?.handleClear();
    navigate(COURSESDETAIL, {
      state: {
        courseId: id,
        userId: USER_ID(),
        isAdminCourse: false,
        fromReportAnalytics: true,
      },
    });
  };
  const columnsCourse = [
    {
      id: "courseDate",
      label:`${
        props.page === "UserTrainingsIncomplete" ? "Assigned On" : "Completed On"
      }`,
      sortable: true,
      isSorted: sortColumn === "courseDate",
      sortDirection,
    },
    {
      id: "type",
      label: `Training Type`,
      sortable: true,
      isSorted: sortColumn === "type",
      sortDirection,
    },
    {
      id: "course",
      label: `Training Name`,
      sortable: true,
      isSorted: sortColumn === "course",
      sortDirection,
    },

    {
      id: "userName",
      label: `${
        props.page === "UserTrainingsIncomplete" ? "Not " : ""
      }Completed By`,
      sortable: true,
      isSorted: sortColumn === "userName",
      sortDirection,
    },
    {
      id: "blank1",
      label: "",
    },
  ];

  const rowsCourse = data?.map((course) => (
    <tr className="row" key={course?.id}>
      {/* <td>{course.id}</td> */}
      <td>{getFormatedDate(course.completedOrAssignedOn)}</td>
      <td>Course</td>
      <td>{course.course}</td>
      <td>{props.apiData.completedBy}</td>
      <td>
        <ReportRowManipulte
          rows={course}
          view
          viewOnClick={() => handleOnClickCourse(course.id)}
          viewLabel={"View Course"}
        />
      </td>
    </tr>
  ));

  const handleOnClickLesson = (id) => {
    props?.handleClear();
    navigate(LESSONDETAIL, {
      state: {
        lessonId: id,
        userId: USER_ID(),
        isAdminCourse: false,
        fromReportAnalytics: true,
      },
    });
  };

  const rowsLesson = data?.map((lesson) => (
    <tr className="row" key={lesson?.id}>
      <td>{getFormatedDate(lesson?.completedOrAssignedOn)}</td>
      <td>Lesson</td>
      <td>{lesson?.lesson}</td>
      <td>{props.apiData.completedBy}</td>
      <td>
        <ReportRowManipulte
          rows={lesson}
          view
          viewOnClick={() => handleOnClickLesson(lesson?.id)}
          viewLabel={"View Lesson"}
        />
      </td>
    </tr>
  ));

  const handleOnCellClick = (params) => {
    console.log(params);
    let toolboxTalkId = params?.toolboxTalkId;
    let assignInstructorsUsersId = params?.assignInstructorsUsersId;
    let fieldName = params?.field;
    let scheduleId = params?.id;
    let assignedOn = params?.assignedOn;

    let finalid;
    if (fieldName === "titleSafetyMeeting") {
      finalid = "true";
    } else {
      finalid = "false";
    }

    // if (fieldName === "titleSafetyMeeting") {
    navigate(TOOL_BOX_DETAIL, {
      state: {
        fieldId: finalid,
        toolboxTalkId: toolboxTalkId,
        scheduleId: scheduleId,
        tabvalue: value,
      },
    });
  };

  const rowsSafety = data?.map((meeting) => (
    <tr className="row" key={meeting?.id}>
      {/* <td>{meeting.id}</td> */}
      <td>{getFormatedDate(meeting.assignedOn)}</td>
      <td>Safety Meeting</td>
      <td>{meeting.titleSafetyMeeting}</td>
      <td>{props.apiData.completedBy}</td>
      <td>
        <ReportRowManipulte
          rows={meeting}
          view
          viewOnClick={() => handleOnCellClick(meeting)}
          viewLabel={"View"}
        />
      </td>
    </tr>
  ));

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Tabs value={value} onChange={handleChange} className="tabsWrapper">
            {USER_TRAININGS_TYPE?.map((tabItem, i) => {
              return (
                <Tab
                  className="tabList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>
        {props.userTRainingLoadin ? (
          <Loader />
        ) : (
          <Grid item md={12} mt={3}>
            <TabPanel value={value} index={0}>
              <div className="customTableWrapper">
                <Table
                  columns={columnsCourse}
                  rows={rowsCourse}
                  tableRef={tableRef}
                  onSort={handleSortCourse}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="customTableWrapper">
                <Table
                  columns={columnsCourse}
                  rows={rowsLesson}
                  tableRef={tableRef}
                  onSort={handleSortCourse}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="customTableWrapper">
                <Table
                  columns={columnsCourse}
                  rows={rowsSafety}
                  tableRef={tableRef}
                  onSort={handleSortCourse}
                />
              </div>
            </TabPanel>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UserTrainings;
