import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./jhaReport.scss";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { getFormatedDate } from "../../../utils/helper";
import { useReactToPrint } from "react-to-print";
import parse from "html-react-parser";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";

function JhaReport({ getJhaConductDetailsData, conductId }) {
  const data = getJhaConductDetailsData;
  const date = getJhaConductDetailsData?.conductDateTime;
  const clientName = localStorage.getItem("loginOrganizationName");
  const imageSize = {
    size: "small",
  };

  const [image, setImage] = useState([]);
  useEffect(() => {
    setImage(
      data?.proofOfAttandanceUrl && data?.proofOfAttandanceUrl?.length > 0
        ? data?.proofOfAttandanceUrl?.split("|")
        : null
    );
  }, [data]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Conduct Hazard Analysis Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  const imageUrlConvertArray =
    data?.proofOfAttandanceUrl && data?.proofOfAttandanceUrl?.length > 0
      ? data?.proofOfAttandanceUrl?.split("|")
      : "";

  const heightRefs = useRef([]);

  useEffect(() => {
    const measureHeights = () => {
      const getHeightDivs = document.querySelectorAll(".getHeight");
      const col1Divs = document.querySelectorAll(".col1");

      getHeightDivs.forEach((getHeightDiv, index) => {
        const height = getHeightDiv.clientHeight;
        heightRefs.current[index] = height;

        if (col1Divs[index]) {
          col1Divs[index].style.height = `${height}px`;
        }
      });
    };
    measureHeights();

    window.addEventListener("resize", measureHeights);
    return () => {
      window.removeEventListener("resize", measureHeights);
    };
  }, []);

  return (
    <>
      <div ref={componentRef}>
        <Grid className="InspectionContainer overRideJhaPdf">
          <Grid container>
            <Grid item md={12}>
              <div className="title repotPdfHeading">
                View Hazard Analysis Report{" "}
              </div>
            </Grid>
          </Grid>
          <div className="overReportHeader">
            <Grid container className="reportHeader">
              <Grid md={6} xs={12} lg={8} className="reportHeaderLeft">
                <div className="reportTitle">
                  Hazard Analysis ID: {conductId}
                </div>
              </Grid>
              <Grid item lg={4} md={6} xs={12} className="jhadownload">
                <Button
                  onClick={handlePrint}
                  variant="contained"
                  startIcon={<DownloadIcon className="downloadIcon" />}
                  className="downloadpdf"
                >
                  Download as PDF
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className="basicOveride">
            <Box className="basicinfoWrap">
              <Box className="basicinfoBox">
                <Box className="basicinfoleft">
                  <Typography className="title">
                    {/* Daily  */}
                    {data?.templateName} for {data?.assetName}
                  </Typography>
                  <Box className="datetime">
                    <Typography className="listView">
                      Conducted by:
                      <span className="focusedText">{data?.conductBy}</span>
                    </Typography>
                    <Typography className="listView">
                      Client:
                      <span>{clientName}</span>
                    </Typography>
                  </Box>
                  <Box className="datetime">
                    <Typography className="listView">
                      Asset Type:
                      <span>{data?.assetType}</span>
                    </Typography>
                    <Typography className="listView">
                      Asset Name:
                      <span>{data?.assetName}</span>
                    </Typography>
                  </Box>
                  <Box className="datetime">
                    <Typography className="listView">
                      Date:
                      <span>{getFormatedDate(date.split("T")[0])}</span>
                    </Typography>
                    <Typography className="listView">
                      Time:
                      <span>{date.split("T")[1].split(".")[0]}</span>
                    </Typography>
                  </Box>

                  <Typography className="listView">
                    Location:
                    <span>{data?.location}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>

          <Grid container className="jhaReportTable" mb={2}>
            <Grid item md={12} xs={12} lg={12}>
              <table className="tableJHAReport">
                <tr className="tabletrHeader">
                  <th className="tableJhaHeader">Activity</th>
                  <th className="tableJhaHeaderS">
                    <table>
                      <th>Hazard</th>
                      <th>Control</th>
                    </table>
                  </th>
                </tr>

                {data?.activities?.map((x, index) => {
                  return (
                    <tr key={x?.activityId}>
                      <td className="tablejhabody">{x.activityName}</td>
                      <td className="tablejhabody">
                        <table>
                          {x.hazards?.map((y) => {
                            return (
                              <tr key={y?.hazardId}>
                                <td className="hazardNametd">{y.hazardName}</td>
                                <td className="controlstd">
                                  {" "}
                                  <table>
                                    {y.controls?.map((c) => {
                                      return (
                                        <tr key={c?.controlId}>
                                          <td>
                                            {parse(unescape(c.controlName))}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </table>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
          </Grid>

          <Grid container mt={5}>
            <Grid item md={12} className="AttendeesContainer">
              <div className="titleAttendee">List of Attendees</div>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item lg={7} md={8} sm={10} sx={12}>
              <div className="attendeesListTable">
                <div className="container">
                  <div className="heading">
                    <div className="col">Attendees</div>
                    <div className="col">Signature</div>
                  </div>

                  {data?.attendeesUsers?.map((user, index) => {
                    return (
                      <div className="table-row" key={index}>
                        <div className="col attendee">{user?.userName}</div>
                        <div className="col proof">
                          {user?.signatureUrl ? (
                            <img
                              className="attendeeProofImage"
                              src={user?.signatureUrl}
                              alt=""
                            />
                          ) : (
                            <div className="na"> N/A</div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item md={12} className="AttendeesContainer">
              <div className="titleAttendee">Photo Verification</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item lg={10} md={8} sm={10} sx={12}>
            {image !== null ? (
              <ViewImages
                image={image}
                imageSize={imageSize}
                showName={false}
              />
            ) : (
              <div className="na">N/A</div>
            )}
          </Grid>

          <Grid container>
            <Grid item lg={12} md={12} xs={12} mt={3} className="msg">
              <p className="defaultMsg">
                "I certify that this report and all attachments were prepared
                under my direction or supervision. Based on my observations, or
                those persons directly responsible for gathering the
                information, the information submitted is, to the best of my
                knowledge and belief, true, accurate, and complete."
              </p>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item lg={4} md={6} xs={12}>
              {data?.signatureUrl ? (
                <img
                  src={data?.signatureUrl}
                  alt="signature"
                  width="200"
                  height="100"
                />
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default JhaReport;
