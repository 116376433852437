import React, { useEffect, useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import "./ticketContainer.scss";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "../component/ticket/ticket";
import FilterList from "../common/filterList";
import * as supportAction from "../../../redux/actions/supportAction";
import Loader from "../../../library/common/Loader";
import { checkPrivileges} from "../../../utils/rolesHelper";
import { MODULES_NAME } from "../../../constant/constant";
import FilterButton from "../../../library/custom/filterButton/filterButton";
import {
  ADMIN_TICKET_DETAILS,
  CREATE_TICKET,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ResolutionTicketContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getAdminDeskData, setGetAdminDeskData] = useState({
    userID: localStorage.getItem("userId"),
    priorityID: "",
    searchbarInput: "",
    ticketType: "",
  });
  const tickets = useSelector((state) => state.tickets?.data?.ticketList);
  const [ticketsState, setTicketsState] = useState(null);
  useEffect(() => {
    setTicketsState(tickets);
  }, [tickets]);
  useEffect(() => {
    dispatch(supportAction.getTickets(getAdminDeskData));
  }, [getAdminDeskData]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [priorityState, setpriorityState] = useState({
    low: false,
    medium: false,
    high: false,
  });

  const handleChange = (event) => {
    const newPriorityState = {
      ...priorityState,
      [event.target.name]: event.target.checked,
    };
    setpriorityState(newPriorityState);
    const arr = [];
    if (newPriorityState.high) {
      arr.push(1);
    }
    if (newPriorityState.medium) {
      arr.push(2);
    }
    if (newPriorityState.low) {
      arr.push(3);
    }
    const priorityIds = arr.join(",");
    setGetAdminDeskData({
      ...getAdminDeskData,
      priorityID: priorityIds,
    });
  };

  const [tabValue, setTabValue] = React.useState(0);

  function handleTabsChange(e, newTabValue) {
    setTicketsState(null);
    setTabValue(newTabValue);

    if (newTabValue === 0) {
      setGetAdminDeskData({
        ...getAdminDeskData,
        ticketType: "",
      });
    }
    if (newTabValue === 1) {
      setGetAdminDeskData({
        ...getAdminDeskData,
        ticketType: 0,
      });
    }
    if (newTabValue === 2) {
      setGetAdminDeskData({
        ...getAdminDeskData,
        ticketType: 1,
      });
    }
    if (newTabValue === 3) {
      setGetAdminDeskData({
        ...getAdminDeskData,
        ticketType: 2,
      });
    }
  }

  const isLoading = useSelector((state) => state.tickets);

  return (
    <div className="overrideTicketContainer">
      <div className="rightDiv">
        {checkPrivileges([8,12]) ? (
          <>
            <div className="containerTkt">
              <div className="mainContainer">
                <div className="headerDiv">
                  <h1>Support Tickets</h1>
                  <Buttons
                    startIcon={<AddIcon />}
                    primary={true}
                    label={"New Ticket"}
                    onClick={() => {
                      navigate(CREATE_TICKET);
                    }}
                  />
                </div>

                <Grid mt={2} className="menuDiv">
                  <Grid className="leftMenu">
                    <Box>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabsChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                      >
                        <Tab
                          className="tabcustom"
                          icon={
                            ticketsState &&
                            getAdminDeskData.ticketTypes === "" && (
                              <div className="totalV">{tickets.length}</div>
                            )
                          }
                          iconPosition="end"
                          label="All Tickets"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tabcustom"
                          icon={
                            ticketsState &&
                            getAdminDeskData.ticketTypes === 0 && (
                              <div className="totalV">{tickets.length}</div>
                            )
                          }
                          iconPosition="end"
                          label="Open Tickets"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tabcustom"
                          icon={
                            ticketsState &&
                            getAdminDeskData.ticketTypes === 1 && (
                              <div className="totalV">{tickets.length}</div>
                            )
                          }
                          iconPosition="end"
                          label="Closed Tickets"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="tabcustom"
                          icon={
                            ticketsState &&
                            getAdminDeskData.ticketTypes === 2 && (
                              <div className="totalV">{tickets.length}</div>
                            )
                          }
                          iconPosition="end"
                          label="In progress"
                          {...a11yProps(3)}
                        />
                      </Tabs>
                    </Box>
                  </Grid>
                  <Grid className="rightMenu">
                    <div className="searchBar">
                      <SearchBar
                        searchbarInput={getAdminDeskData.searchbarInput}
                        handleSearch={(e) => {
                          setGetAdminDeskData({
                            ...getAdminDeskData,
                            searchbarInput: e,
                          });
                        }}
                        placeholder="Search Text"
                      />
                    </div>
                    <div className="filter_btn">
                      <FilterButton handleClick={handleClick} />
                      <FilterList
                        id={id}
                        open={open}
                        handleClose={handleClose}
                        handleChange={handleChange}
                        anchorEl={anchorEl}
                        priorityState={priorityState}
                        isUser={true}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ width: "100%" }}>
                  {isLoading.isLoading && <Loader />}
                  {tickets?.map((ticket, index) => {
                    return (
                      <Grid
                        mt={2}
                        onClick={() => {
                          navigate(ADMIN_TICKET_DETAILS, {
                            state: {
                              ticketId: ticket.ticketId,
                              status: ticket.status,
                              getAdminDeskData: getAdminDeskData,
                            },
                          });
                        }}
                        className="ticketsContainer"
                        key={ticket.ticketId}
                      >
                        <Ticket
                          ticketQuestion={ticket.title}
                          ticketAnswer={ticket.description}
                          priority={ticket.priority}
                          ticketId={ticket.ticketId}
                          category={ticket.category}
                          replyCount={ticket.replyCount}
                          createdDateTime={ticket.createdDateTime}
                          status={ticket.status}
                          createdBy={ticket.createByNameTicket}
                          createdById={ticket.createByIdTicket}
                        />
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <h1>Access Denied</h1>
        )}
      </div>
    </div>
  );
};

export default ResolutionTicketContainer;
