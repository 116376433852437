export const lmsUserType = {
  GET_USER_COURSES_LIST_PENDING:
    "[GET_COURSES_LIST][REQUEST] Get User Courses list requested",
  GET_USER_COURSES_LIST_FAILURE:
    "[GET_COURSES_LIST][RESPONSE] Get User Courses list Failed",
  GET_USER_COURSES_LIST_SUCCESS:
    "[GET_COURSES_LIST][RESPONSE] Get User Courses list Successfull",
  GET_USER_COURSES_LIST_EMAIL_SUCCESS:
    "[GET_USER_COURSES_LIST_EMAIL_SUCCESS] Send Get User Courses list Data Successfull",
  COURSES_USER__PAGE: "CoursesUserPage",
  CLEAR_COURSELIST: "clearcourse",

  GET_USER_LESSONS_LIST_PENDING:
    "[GET_LESSONS_LIST][REQUEST] Get User Lessons list requested",
  GET_USER_LESSONS_LIST_FAILURE:
    "[GET_LESSONS_LIST][RESPONSE] Get User Lessons list Failed",
  GET_USER_LESSONS_LIST_SUCCESS:
    "[GET_LESSONS_LIST][RESPONSE] Get User Lessons list Successfull",
  GET_USER_LESSONS_LIST_EMAIL_SUCCESS:
    "[GET_USER_LESSONS_LIST_EMAIL_SUCCESS:] SendGet User Lessons list Data Successfull",
  LESSONS_USER__PAGE: "LessonsUserPage",
  CLEAR_LESSONLIST: "clearlesson",
  GET_USER_CATEGORY_LIST_PENDING:
    "[GET_CATEGORY_LIST][REQUEST] Get User Category list requested",
  GET_USER_CATEGORY_LIST_FAILURE:
    "[GET_CATEGORY_LIST][RESPONSE] Get User Category list Failed",
  GET_USER_CATEGORY_LIST_SUCCESS:
    "[GET_CATEGORY_LIST][RESPONSE] Get User Category list Successfull",
  CATEGORY_USER__PAGE: "CategoryUserPage",

  GET_USER_COURSE_VIEW_SUCCESS:
    "[RESPONSE] Get User Course View Data Successfull",
  GET_USER_COURSE_VIEW_FAILURE: "[RESPONSE] Get User Course View Data Failed",
  GET_USER_COURSE_VIEW: "USERCOURSEVIEW",

  GET_USER_LESSON_VIEW_SUCCESS:
    "[RESPONSE] Get User Lesson View Data Successfull",
  GET_USER_LESSON_VIEW_FAILURE: "[RESPONSE] Get User Lesson View Data Failed",
  GET_USER_LESSON_VIEW: "USERLESSONVIEW",

  POST_START_COURSES_PENDING: "[START_COURSES][REQUEST] Start Course",
  POST_START_COURSES_FAILURE: "[START_COURSES][RESPONSE] Start Course Failed",
  POST_START_COURSES_SUCCESS:
    "[START_COURSES][RESPONSE] Start Course Successfull",
  START_COURSES_PAGE: "START_COURSES",

  POST_START_LESSON_PENDING: "[START_LESSON][REQUEST] Start Lesson",
  POST_START_LESSON_FAILURE: "[START_LESSON][RESPONSE] Start Lesson Failed",
  POST_START_LESSON_SUCCESS:
    "[START_LESSON][RESPONSE] Start Lesson Successfull",
  START_LESSON_PAGE: "START_LESSON",
};
