import { toolBoxTalkType } from "../constants/toolBoxType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "toolboxtalk",
  isError: false,
  isLoading: false,
  editToolBoxTalkData: {},
};

export const createToolBoxTalkReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.POST_TOOL_BOX_TALK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_TOOL_BOX_TALK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_TOOL_BOX_TALK_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateToolBoxTalkReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.UPDATE_TOOL_BOX_TALK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.UPDATE_TOOL_BOX_TALK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.UPDATE_TOOL_BOX_TALK_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const getToolBoxTalkListReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
export const getAdminToolBoxTalkListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
//detail api reducer
export const getToolBoxTalkViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteToolBoxTalkReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.DELETE_TOOL_BOX_TALK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.DELETE_TOOL_BOX_TALK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.DELETE_TOOL_BOX_TALK_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.CLEAR_TOOLTALK:
      return {
        ...state,
        status: "cleartooltalk",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
// assign page get search safety meeting
export const getToolSafetyMeetingSearchReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
export const getProjectSearchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.GET_PROJECT_SEARCH_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_PROJECT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_PROJECT_SEARCH_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const searchAllUserProjectWiseReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_ALL_USER_SEARCH:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_ALL_USER_SEARCH_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_ALL_USER_SEARCH_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.CLEAR_USERPROJECTWISETALK:
      return {
        ...state,
        status: "cleartooltalk",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getAssignTookBoxUserList = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_FAILURE:
      return {
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const postSafetyMeetingAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.POST_SAFETY_MEETING_ALERT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_SAFETY_MEETING_ALERT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_SAFETY_MEETING_ALERT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
/**
 *
 * @param {*} state
 * @param {*} action my safety meeting
 * @returns
 */
export const getMySafetyMeetingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.GET_MY_SAFETY_MEETING_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_MY_SAFETY_MEETING_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_MY_SAFETY_MEETING_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.GET_MY_MEETING_DATA_CLEAR:
      return {
        status: "clearsafetymeeting",
        isError: false,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const getScheduleInstructorDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_SCHEDULE_INSTRUCTOR_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_SCHEDULE_INSTRUCTOR_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_SCHEDULE_INSTRUCTOR_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getScheduleAttendeesDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_SCHEDULE_ATTENDEES_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_SCHEDULE_ATTENDEES_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_SCHEDULE_ATTENDEES_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getScheduleSaftyMeetingDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_SCHEDULE_SAFETY_MEETING_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_SCHEDULE_SAFETY_MEETING_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_SCHEDULE_SAFETY_MEETING_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateAdminAssignInstructorsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.POST_DATA_CLEAR:
      return {
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
