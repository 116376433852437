import { toolBoxTalkType } from "../constants/toolBoxType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "toolboxtalkAssign",
  isError: false,
  isLoading: false,
};

export const assignAdminToInstructorToolReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getScheduleMeetingDetailReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.CLEAR_SCHEDULETOOLTALK:
      return {
        ...state,
        status: "clearScheuletooltalk",
        isError: true,
        isLoading: false,
        data: "",
        users: "",
        assetName: "",
        responseMessages: "",
        id: "",
        toolboxTalkId: "",
        titleSafetyMeeting: "",
        frequencyId: "",
        scheduleTalkStartDate: "",
        scheduleTalkEndDate: "",
        assetId: null,
        assetType: null,
      };
    default:
      return state;
  }
};

// instructor to user assign

export const assignInstructorToUserToolReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
// self instructor to user

export const assignSelfInstructorToUserToolReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const verifyAttendeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.POST_VERIFY_ATTENDEE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_VERIFY_ATTENDEE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_VERIFY_ATTENDEE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getVerifyAttendeeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
//DELETE_SCHEDULE_TOOL_BOX_TALK_PENDING
export const deleteScheduleToolBoxTalkReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.CLEAR_TOOLTALK:
      return {
        ...state,
        status: "cleartooltalk",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const userMarkedCompletedToolBoxReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.CLEAR_USERTOOLTALK:
      return {
        ...state,
        status: "clearusercomplete",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getExtraUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.GET_EXTRA_USER_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.GET_EXTRA_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.GET_EXTRA_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const createExtraUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolBoxTalkType.POST_EXTRA_USER_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case toolBoxTalkType.POST_EXTRA_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case toolBoxTalkType.POST_EXTRA_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case toolBoxTalkType.CLEAR_CREATE_USER_EXTRA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
