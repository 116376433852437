import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/addCaseList.scss";
import PersonalInjuryForm from "../components/personalInjuryForm";
import Carousel from "../../../library/custom/carouselBox/Carousel";
import CarouselData from "../../../constant/carouselConstant";
import * as incidentAction from "../../../redux/actions/incidentAction";
import * as perosnalInjuryAction from "../../../redux/actions/perosnalInjuryAction";
import * as userAction from "../../../redux/actions/userAction";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_STATUS,
  INCIDENT_REPORT_ADDED,
  INCIDENT_REPORT_UPDATE,
} from "../../../constant/constant";

import {
  PERSONAL_INJURY,
  VEHICLE_ACCIDENT,
  UTILITY_DAMAGE,
  STOLEN_PROPERTY,
  NEAR_MISS,
  DASHBOARD,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import SuccessScreen from "../../incidents/add-incident/component/successScreen";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { logEvent, validateFileSize } from "../../../utils/helper";
import { fileUpload } from "../../../services/fileUploadService";

export default function PersonalInjuryContainer() {
  const location = useLocation();
  const existingCaseId = location?.state?.caseId;
  const incidentId = location?.state?.incidentId;
  const editMode = location?.state?.editMode;
  const isDirect = location?.state?.isDirect;
  const isAllowed = checkPrivileges([1, 2, 3, 12]);

  let navigate = useNavigate();
  const disptch = useDispatch();
  const incidentReferenceData = useSelector((state) => state.incident);
  const incident = useSelector((state) => state.incident.data);
  const status = useSelector((state) => state.addIncident.status);
  const updatePersonalInjuryStatus = useSelector(
    (state) => state.updatePersonalInjury.status
  );
  const updatePersonalInjuryData = useSelector(
    (state) => state.updatePersonalInjury.data
  );

  const addIncidentData = useSelector((state) => state.addIncident.data);
  const caseData = useSelector((state) => state.createdCase.data);
  const searchOption = useSelector((state) => state.users.data);
  const searchOptionStatus = useSelector((state) => state.users);
  const allUsers = useSelector((state) => state.allUsers.data);
  const assetOnUserList = useSelector((state) => state.getAssetOnUser.data);
  const supervisorOnAssetList = useSelector(
    (state) => state.getSupervisorOnAsset.data
  );

  const isLoading = useSelector((state) => state.users.isLoading);
  // const fileUploadData = useSelector((state) => state.fileUpload.data);
  const [successScreens, setSuccessScreens] = useState(false);
  const personalReportData = useSelector(
    (state) => state.getPersonalReport.data
  );
  const personalReportDataStatus = useSelector(
    (state) => state.getPersonalReport
  );

  const isReferenceSuccess = useSelector(
    (state) => state.incident.status === API_STATUS.SUCCESS && existingCaseId
  );

  const showLoader = useSelector(
    (state) =>
      state.incident.isLoading ||
      state.createdCase.isLoading ||
      state.addIncident.isLoading ||
      state.updatePersonalInjury.isLoading ||
      state.fileUpload.isLoading ||
      state.getPersonalReport.isLoading
  );
  const [dataId, setDataId] = useState("");
  const [payload, setpayload] = useState("");

  const addAndUpdatePersonalInjury = async (data) => {
    setSuccessScreens(true);
    setpayload(data);
    setDataId(data.personalInjury.incidentId);
    if (data.personalInjury.incidentId === 0) {
      disptch(perosnalInjuryAction.perosnalInjury(data.personalInjury));
    } else {
      disptch(perosnalInjuryAction.updatePerosnalInjury(data.personalInjury));
    }
  };

  const getIncidentData = () => {
    disptch(incidentAction.getIncidentData());
  };

  const searchUsers = (searchType, searchText) => {
    disptch(userAction.searchUsers(searchType, searchText));
  };

  const searchAllUsers = (searchType, searchText, projectId = 0) => {
    disptch(
      userAction.searchAllUsers(searchText, searchType, 0, projectId, 1, 0)
    );
  };

  const getPersonalReport = (caseId, incidentId) => {
    disptch(perosnalInjuryAction.getPersonalReportDetails(caseId, incidentId));
  };

  useEffect(() => {
    if (isReferenceSuccess && existingCaseId && incidentId) {
      getPersonalReport(existingCaseId, incidentId);
    }
  }, [isReferenceSuccess]);

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    disptch(perosnalInjuryAction.clearPerosnalInjury());
    setSuccessScreens(false);
    if (location.state === "Vehicle Accident") {
      const value = {
        id: 2,
        title: "Vehicle Accident",
      };
      setcarouselValue(value);
    }

    getIncidentData();
  }, []);

  const [carouselValue, setcarouselValue] = useState({
    id: isDirect ? 1 : 0,
    title: "",
  });

  const handleOnChangeMethod = (value) => {
    if (!editMode) {
      setcarouselValue(value);
      if (value.id === 1) {
        navigate(PERSONAL_INJURY, { state: { caseId: existingCaseId } });
      } else if (value.id === 2) {
        navigate(VEHICLE_ACCIDENT, { state: { caseId: existingCaseId } });
      } else if (value.id === 4) {
        navigate(UTILITY_DAMAGE, { state: { caseId: existingCaseId } });
      } else if (value.id === 3) {
        navigate(STOLEN_PROPERTY, { state: { caseId: existingCaseId } });
      } else if (value.id === 5) {
        navigate(NEAR_MISS, { state: { caseId: existingCaseId } });
      }
    }
  };

  const [fileError, setFileError] = useState(null);
  const [countError, setCountError] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [fileUploadData, setFileUploadData] = useState();

  const uploadFile = async (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileError(validation.errorMessage);
      setCountError(countError + 1);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
      })
      .catch((error) => {
        setCountError(countError + 1);
        setFileError("Error while uploading files.");
      });
  };
  const successScree = (id) => {
    logEvent(
      "Incident",
      editMode ? "Update Personal Injury" : "Add Personal Injury"
    );
    return (
      <SuccessScreen
        id={id}
        caseId={existingCaseId}
        isUpdate={dataId === 0 ? INCIDENT_REPORT_ADDED : INCIDENT_REPORT_UPDATE}
        routeProps={{
          route: location?.state?.route,
          routeText: location?.state?.routeText,
          establishmentId: location?.state?.establishmentId,
          year: location?.state?.year,
        }}
      ></SuccessScreen>
    );
  };

  function getAssetOnUser(params) {
    const data = {
      assetType: 1,
      userId: params.userId,
    };
    disptch(userAction.getAssetOnUser(data));
  }
  function getSupervisorOnAsset(params) {
    const data = {
      assetType: 1,
      assetId: params,
    };
    disptch(userAction.getSupervisorOnAsset(data));
  }

  return (
    <>
      {checkPrivileges([1, 2, 12]) ? (
        <>
          {status === "SUCCESS" && addIncidentData && successScreens ? (
            successScree(addIncidentData?.incidentId)
          ) : updatePersonalInjuryStatus === "SUCCESS" &&
            updatePersonalInjuryData &&
            successScreens ? (
            successScree(incidentId)
          ) : (
            <div className="dashboard-slider">
              <div>
                <div className="dashbord_heading">
                  <div className="insi-stitle">
                    <p>Select Incident Type</p>
                    <div className="caseId">
                      Case ID: EVE-
                      {existingCaseId ? existingCaseId : caseData?.caseId}
                    </div>
                  </div>
                </div>

                <Carousel
                  sliderItems={CarouselData}
                  activeSlide={carouselValue}
                  handleOnChange={handleOnChangeMethod}
                  edit={editMode ? true : false}
                />
              </div>
              <div></div>
              <div className="loader-wrapper">
                {(showLoader || isLoader) && <Loader />}
              </div>
              <div>
                {isReferenceSuccess && carouselValue.id == 1 && (
                  <PersonalInjuryForm
                    incident={incident}
                    caseId={existingCaseId ? existingCaseId : caseData?.caseId}
                    incidentId={incidentId ? incidentId : 0}
                    personalReportData={incidentId ? personalReportData : ""}
                    editMode={editMode}
                    status={status}
                    onSubmit={addAndUpdatePersonalInjury}
                    searchOption={searchOption}
                    uploadFile={uploadFile}
                    fileUploadData={fileUploadData}
                    searchUsers={searchUsers}
                    searchAllUsers={searchAllUsers}
                    allUsers={allUsers}
                    isLoading={isLoading}
                    searchOptionStatus={searchOptionStatus}
                    personalReportDataStatus={personalReportDataStatus}
                    getAssetOnUser={getAssetOnUser}
                    assetOnUserList={assetOnUserList?.jobSite ?? []}
                    assetOnUserData={assetOnUserList}
                    getSupervisorOnAsset={getSupervisorOnAsset}
                    supervisorOnAssetList={supervisorOnAssetList?.result ?? []}
                    fileError={fileError}
                    countError={countError}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
