import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SamplingLogs from "../components/samplingLogs";
import { useDispatch, useSelector } from "react-redux";
import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import { PAGE_SIZE, API_STATUS } from "../../../../constant/constant";
import Loader from "../../../../library/common/Loader";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../../constant/routeContant";

function SamplingLogContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = location?.state?.projectId;
  const stateId = location?.state?.stateId ?? location?.state?.stateid;
  const projectName = location?.state?.projectName;
  const projectNumber = location?.state?.projectNumber;
  const customer = location?.state?.customerName;
  const customerId = location?.state?.customerId ?? location?.state?.customerId;

  const createProjectSampleData = useSelector(
    (state) => state?.createSwProjectSampleLog
  );

  const ProjectSampleData = useSelector(
    (state) => state?.swprojectSampleList?.data
  );
  const ProjectSampleDataIsloading = useSelector(
    (state) => state?.swprojectSampleList?.isLoading
  );

  useEffect(() => {
    if (createProjectSampleData?.status === API_STATUS.SUCCESS) {
      getProjectSampleListData(data);
    } else {
      //setAssignmsg(false);
    }
  }, [createProjectSampleData]);
  const addAndUpdateSample = async (data) => {
    dispatch(projectAction.createProjectSample(data));
  };
  const data = {
    page: 1,
    pageSize: PAGE_SIZE,
    projectId: projectId,
  };
  const getProjectSampleListData = (data) => {
    dispatch(projectAction.swProjectSampleList(data));
  };

  useEffect(() => {
    getProjectSampleListData(data);
  }, []);

  return (
    <div>
      {checkPrivileges([12, 129, 130, 131]) ? (
        <>
          {createProjectSampleData?.isLoading && <Loader />}
          <SamplingLogs
            addAndUpdateSample={addAndUpdateSample}
            ProjectSampleResult={ProjectSampleData}
            projectId={projectId}
            getProjectSampleListData={getProjectSampleListData}
            isprojectsampleLoading={ProjectSampleDataIsloading}
            projectData={location?.state}
            stateId={stateId}
            projectName={projectName}
            projectNumber={projectNumber}
            customer={customer}
            customerId={customerId}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </div>
  );
}

export default SamplingLogContainer;
