import React, { useState, useRef } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { TableLoader } from "../../../../library/common/Loader";
import {
  EXPIRATION_TYPE,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USERID,
  USER_ID,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import DialogBox from "../../../../library/common/DialogBox";
import FilterCommonStatusSelect from "../../shared/FilterCommonStatusSelect";
import { getFormatedDate, isDateExpired } from "../../../../utils/helper";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import downloadIcon from "../../../../assets/downloadIcon.svg";

export default function ResourceList({
  isLoading,
  resources,
  addResource,
  getResourceListData,
  isShow,
  filterShow,
  handleDeleteOnClick,
  handleEditOnClick,
  resourceType,
  resourceCategory,
  isProfile = false,
  projectId,
  searchClient,
  autoSearchDataLoading,
  searchAllClientsReducerData,
  allProjects,
  handleAllProjectSearch,
  isProjectLoading,
  customerId,
  projectName,
  setClientsValue,
  clientsValue,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [clients, setClients] = useState(customerId || "");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const autoCompGroup = useRef(null);
  const [statusIds, setStatusIds] = useState();
  const [category, setCategory] = useState("");
  const [expirationType, setExpirationType] = useState("");
  const [isTableLoading, setisTableLoading] = useState(false);
  const [projects, setProjects] = useState({
    key: projectId ?? "",
    value: projectName ?? "",
  });
  const autoComp = useRef(null);
  const onchangeToDatePicker = (e) => {
    setToDate(e);
  };
  const onchangeFromDatePicker = (e) => {
    setFromDate(e);
  };
  const onSelectSearchCustomer = (e, value, s) => {
    setClients(value?.key || 0);
    setClientsValue({ key: value?.key ?? "", value: value?.value ?? "" });
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      resourceTypes: statusIds ? statusIds : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      endDate: toDate ? getFormatedDate(toDate) : "",
      catId: category ? category : 0,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      orgId: value?.key || ORG_ID(),
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      isSendMail: false,
    };
    getResourceListData(data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteResource = (resourceId) => {
    setOpenModal(true);
    setSelectedRow(resourceId);
  };
  const deletehandle = () => {
    setOpenModal(false);
    handleDeleteOnClick(selectedRow);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.7 },
    { field: "category", headerName: "Category", flex: 1.8 },
    { field: "resourceTitle", headerName: "Resource Name", flex: 1.7 },
    { field: "assetType", headerName: "Asset Type", flex: 1.4 },
    { field: "projectName", headerName: "Asset Name", flex: 1.6 },
    { field: "dateOfEntry", headerName: "Date of Entry", flex: 1.6 },
    { field: "resourceType", headerName: "Resource Type", flex: 1.5 },
    { field: "addedBy", headerName: "Added by", flex: 1.5 },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 1.4,
      renderCell: (rows) => {
        return rows?.row?.expirationDate === null ? (
          "None"
        ) : (
          <div
            className={
              isDateExpired(rows?.row?.expirationDate) ? "expDate" : ""
            }
          >
            {rows?.row?.expirationDate}
          </div>
        );
      },
    },

    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={resources?.noOfRecords ?? 0}
            numberOfItemsPerPage={PAGE_SIZE}
            incidentLists={resources?.resourceData ?? []}
            fileNameXL={"resourcesList"}
          />
        ) : (
          ""
        ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            openLink
            edit={checkPrivileges([73, 74, 12])}
            deleted={checkPrivileges([73, 12])}
            openLinkUrl={
              rows?.row?.resourceTypeId === 2
                ? `${rows?.row?.linkUrls[0]}`
                : `${rows?.row?.fileUrls[0]}`
            }
            openLinkLabel={
              rows?.row?.resourceTypeId === 2
                ? "Open Link"
                : "Download Resource"
            }
            deleteOnClick={(row) => deleteResource(row?.id)}
            editOnClick={(row) => handleEditOnClick(row)}
            deleteLabel="Delete Resource"
            editLabel="Edit Resource"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handleSearch = (data) => {
    setAnchorEl(null);
    getResourceListData(data);
  };

  const handleautoSearch = (event) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: searchText ? searchText : "",
      resourceTypes: statusIds ? statusIds : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      endDate: toDate ? getFormatedDate(toDate) : "",
      catId: category ? category : 0,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      orgId: clients ? clients : isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      expirationType: expirationType === "all" ? 0 : expirationType,
      isSendMail: false,
    };
    getResourceListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: pageSize,
      search: search,
      resourceTypes: statusIds ? statusIds : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      endDate: toDate ? getFormatedDate(toDate) : "",
      catId: category ? category : 0,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      orgId: clients ? clients : isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      expirationType: expirationType === "all" ? 0 : expirationType,
      isSendMail: false,
    };
    getResourceListData(data);
  };

  const handleApply = () => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    const data = {
      statusIds: "",
      page: 1,
      pageSize: pageSize,
      resourceTypes: statusIds ? statusIds : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      endDate: toDate ? getFormatedDate(toDate) : "",
      catId: category ? category : "",
      search: "",
      orgId: clients ? clients : isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? USERID : 0,
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      expirationType: expirationType === "all" ? 0 : expirationType,
      isSendMail: false,
    };
    handleSearch(data);
  };

  const handleClear = () => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setCategory("");
    setStatusIds("");
    setFromDate("");
    setToDate("");
    setExpirationType("");
    setSearch("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const data = {
      statusIds: "",
      page: 1,
      pageSize: pageSize,
      resourceTypes: "",
      startDate: "",
      endDate: "",
      catId: "",
      search: "",
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? USERID : 0,
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      expirationType: 0,
      isSendMail: false,
    };
    handleSearch(data);
  };

  const [filter, setFilter] = useState({
    searchType: [],
  });

  const categoryOnChanges = (e) => {
    setCategory(e.target.value);
  };

  const expirationTypeOnChanges = (e) => {
    setExpirationType(e.target.value);
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const typeOnchange = (event) => {
    if (event?.target?.value.trim()) {
      searchClient(event?.target?.value.trim());
    }
  };

  const onChangeProjects = (value) => {
    setProjects({ key: value?.key ?? "", value: value?.value ?? "" });

    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      resourceTypes: statusIds ? statusIds : "",
      catId: category ? category : 0,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      orgId: clients ? clients : isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      assetId: value?.key,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      isSendMail: false,
    };
    getResourceListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: page,
      pageSize: newPazeSize,
      search: search,
      resourceTypes: statusIds ? statusIds : "",
      catId: category ? category : 0,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      orgId: clients ? clients : isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      isSendMail: false,
    };
    getResourceListData(data);
  };

  const sendEmail = () => {
    const data = {
      statusIds: "",
      page: 1,
      pageSize: pageSize,
      resourceTypes: statusIds ? statusIds : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      endDate: toDate ? getFormatedDate(toDate) : "",
      catId: category ? category : "",
      search: search,
      orgId: clients ? clients : isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? USERID : 0,
      assetId: projects?.key ? projects?.key : 0,
      assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0,
      expirationType: expirationType === "all" ? 0 : expirationType,
      isSendMail: true,
    };
    getResourceListData(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={"Are you sure you want to delete this Resource?"}
            view
          />
        )}

        {isShow && checkPrivileges([12, 73, 74]) && (
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">Resource Library</div>
            <div>
              <Buttons
                id="btnAddResource"
                varientAddIconBlue={true}
                label={"Add Resource"}
                onClick={() => addResource()}
              />
            </div>
          </div>
        )}
        {checkPrivileges([12, 17]) && PRODUCT_ID() == 2 && ORG_ID() == 1 && (
          <Grid container gap={1}>
            <Grid item lg={4.5} xs={12} sm={12} md={6} mb={3}>
              <FormControl fullWidth>
                <FormLabels label="Client" isRequired={false} />
                <SearchAutoComplete
                  name="Customer"
                  optionsData={
                    autoSearchDataLoading
                      ? []
                      : searchAllClientsReducerData?.data ?? []
                  }
                  typeOnchange={(event, value) => typeOnchange(event)}
                  isKeyValuePair={true}
                  value={clientsValue ? clientsValue : ""}
                  onChange={(event, value, key) =>
                    onSelectSearchCustomer(event, value, key)
                  }
                  isLoading={autoSearchDataLoading}
                  noOptionsTextMsg="No Client"
                  autoCompClear={autoCompGroup}
                />
              </FormControl>
            </Grid>

            <Grid lg={4.5} xs={12} sm={12} md={6} item>
              <FormControl fullWidth>
                <FormLabels label="Project" isRequired={false} />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={allProjects ?? []}
                  typeOnchange={(event, value) =>
                    handleAllProjectSearch(event, value, clients)
                  }
                  isKeyValuePair={true}
                  value={projects}
                  onChange={(e, value) => onChangeProjects(value)}
                  isLoading={isProjectLoading}
                  autoCompClear={autoComp}
                  noOptionsTextMsg={"No Project"}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>

                <FilterCommonStatusSelect
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  statusType={resourceType}
                  categoryType={resourceCategory}
                  category={category}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filter={filter}
                  categoryOnChanges={categoryOnChanges}
                  expirationTypeList={EXPIRATION_TYPE}
                  expirationType={expirationType}
                  expirationTypeOnChanges={expirationTypeOnChanges}
                  onChangeType={onChangeType}
                  statusIds={statusIds}
                  filterSelect={true}
                  filterStatus={true}
                  resetBtn={true}
                  label="Resource Type"
                  filterDate={true}
                  toDate={toDate}
                  fromDate={fromDate}
                  onchangeToDatePicker={onchangeToDatePicker}
                  onchangeFromDatePicker={onchangeFromDatePicker}
                />
              </div>
            </div>
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              {isTableLoading ? (
                ""
              ) : (
                <GridTable
                  getRowId={(r) => r.id}
                  rows={
                    resources?.resourceData
                      ? resources.resourceData.map((x, index) => {
                          x.dateOfEntry = getFormatedDate(x.dateOfEntry);
                          return x;
                        })
                      : []
                  }
                  rowCount={resources?.noOfRecords ?? 0}
                  columns={columns}
                  checkboxSelection={false}
                  loading={isLoading}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
