import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssignToolbox from "../component/assignToolBoxTalk/assignToolBoxTalk";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { API_STATUS, USER_ID } from "../../../constant/constant";
import * as userAction from "../../../redux/actions/userAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import { useLocation } from "react-router-dom";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";

const AssignToolboxContainer = () => {
  const location = useLocation();
  const defaultData = {
    search: "",
  };
  const dispatch = useDispatch();
  const getToolSafetyMeetingSearchData = useSelector(
    (state) => state.getToolSafetyMeetingSearch?.data?.saftyMeetings
  );
  const getScheduleSaftyMeetingDetailsData = useSelector(
    (state) => state.getScheduleSaftyMeetingDetails
  );
  const updateAdminAssignInstructorsData = useSelector(
    (state) => state.updateAdminAssignInstructors
  );
  //assignAdminToInstructorTool
  const assignAdminToInstructorTool = useSelector(
    (state) => state.assignAdminToInstructorTool
  );
  const assignSelfInstructorToUserTool = useSelector(
    (state) => state.assignSelfInstructorToUserTool
  );

  const selfLoading = assignSelfInstructorToUserTool?.isLoading;
  const statusself = assignSelfInstructorToUserTool?.status;
  const getScheduleMeetingDetailData = useSelector(
    (state) => state.getScheduleSaftyMeetingDetails
  );
  const isLoadingSchedueleDetail = getScheduleMeetingDetailData?.isLoading;
  const AssignAdminToInstructorIsloading =
    assignAdminToInstructorTool?.isLoading;
  const statuss = assignAdminToInstructorTool?.status;
  const cid = assignAdminToInstructorTool?.data?.result?.scheduleId;
  const toolboxid = location?.state?.toolboxid;
  const toolboxIdinstructor = location?.state?.toolboxId;
  const detailPageNavigate = location?.state?.detailPageNavigate;
  const id = location?.state?.id;
  const titleSafetyMeeting = location?.state?.titleSafetyMeeting;
  const toolboxTalkIdSelf = location?.state?.toolboxTalkIdSelf;
  const titleSafetyMeetingSelf = location?.state?.titleSafetyMeetingSelf;
  const assignInstructorsUsersId = location?.state?.assignInstructorsUsersId;
  const assignedOnSelf = location?.state?.assignedOnSelf;
  const scheduleIdseld = location?.state?.scheduleIdseld;
  const editMode = location?.state?.editMode;
  const scheduleId = location?.state?.scheduleId;
  const scheduleDate = location?.state?.scheduleDate;  // assignondte
  const userId = location?.state?.userId;
  const editInstructorStatus = location?.state?.editInstructorStatus
  const getSafetyMeetingSearch = (data) => {
    dispatch(toolboxAction.getToolBoxSafetyMeetingSearchList(data));
  };

  useEffect(() => {
    dispatch(toolboxAction.updateAdminAssignClear());
    getSafetyMeetingSearch(defaultData);
  }, []);
  function getScheduleSaftyMeetingDetails1(params) {
    dispatch(toolboxAction.getScheduleSaftyMeetingDetails(params));
  }
  useEffect(() => {
    if (editMode) {
      getScheduleSaftyMeetingDetails1({
        scheduleId: scheduleId,
        scheduleDate: scheduleDate,
        userId: userId,
      });
    }
  }, [editMode]);

  const [successScreens, setSuccessScreens] = useState(false);
  const allUsersProjectWise = useSelector(
    (state) => state.searchAllUserProjectWise
  );
  const allUsers = useSelector((state) => state.allUsers);
  const isAutoSearchDataLoading = allUsersProjectWise.isLoading;

  const addAndUpdateAssign = async (data) => {
    setSuccessScreens(true);

    if (editMode) {
      dispatch(toolboxAction.updateAdminAssignInstructors(data));
    } else {
      dispatch(toolboxAction.assignAdminToInstructorToolBox(data));
    }
  };

  const assetOption = useSelector((state) => state.getAssetDynomoList.data);
  const isAssetLoading = useSelector(
    (state) => state.getAssetDynomoList.isLoading
  );

  const searchAsset = (searchText, type) => {
    dispatch(assetsAction.getAssetDynomoList({ searchText, type }));
  };

  const getScheduleSafetyMeetingDetails = (data) => {
    dispatch(toolboxAction.getScheduleSafetyMeetingDetails(data));
  };

  useEffect(() => {
    if (assignInstructorsUsersId) {
      const defaultData = {
        scheduleDate: assignedOnSelf,
        scheduleId: scheduleIdseld,
        userId: USER_ID(),
        assignType: 1,
      };
      getScheduleSafetyMeetingDetails(defaultData);
    }
  }, [assignInstructorsUsersId]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isContractor = userInfo?.isContractor;
  function getAllUsers(searchText, type, assetType, assetId, priviledgeId) {
    dispatch(
      userAction.searchAllUsers(searchText, type, 0, assetId, assetType, "91")
    );
  }

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={TOOL_BOX_LIST}
        id={id}
        label2={labelmsg}
        label1="Safety Meeting Assign ID"
        label4="Safety Meeting Assign in "
        routeProps={{ tabId: 1 }}
      ></SuccessScreencommon>
    );
  };
  return (
    <>
      {AssignAdminToInstructorIsloading && <Loader />}
      {selfLoading && <Loader />}
      {isLoadingSchedueleDetail && <Loader />}
      {updateAdminAssignInstructorsData.isLoading && <Loader />}
      {statuss === API_STATUS.SUCCESS && successScreens ? (
        successScree(cid, "Safety Meeting Assign  Successfully", "")
      ) : statusself === API_STATUS.SUCCESS &&
        successScreens &&
        assignSelfInstructorToUserTool ? (
        successScree(cid, "Safety Meeting Self Assign Successfully", "")
      ) : updateAdminAssignInstructorsData.status === API_STATUS.SUCCESS &&
        !updateAdminAssignInstructorsData.isLoading &&
        successScree ? (
        successScree(
          updateAdminAssignInstructorsData?.data?.result?.scheduleId,
          "Safety Meeting Updated Successfully",
          ""
        )
      ) : isLoadingSchedueleDetail ? (
        <Loader />
      ) : (
        <AssignToolbox
          getSafetyMeetingSearch={getSafetyMeetingSearch}
          SafetyMeetingSearchData={getToolSafetyMeetingSearchData}
          isLoading={isAutoSearchDataLoading}
          allUsers={allUsers && allUsers?.data}
          toolboxid={toolboxid}
          onSearch={getAllUsers}
          onSubmit={addAndUpdateAssign}
          idSafety={detailPageNavigate ? toolboxIdinstructor : id}
          titleSafetyMeeting1={titleSafetyMeeting}
          editScheduleMeetingDetailData={getScheduleMeetingDetailData}
          detailPageNavigate={detailPageNavigate}
          toolboxIdinstructor={toolboxIdinstructor}
          toolboxTalkIdSelf={toolboxTalkIdSelf}
          titleSafetyMeetingSelf={titleSafetyMeetingSelf}
          isContractor={isContractor}
          assignInstructorsUsersId={assignInstructorsUsersId}
          assetOption={assetOption ?? []}
          isAssetLoading={isAssetLoading}
          searchAsset={searchAsset}
          getScheduleSaftyMeetingDetailsData={
            getScheduleSaftyMeetingDetailsData?.data
          }
          editMode={editMode}
          scheduleId={scheduleId}
          instructorLoading={allUsers?.isLoading}
          editInstructorStatus={editInstructorStatus}
          scheduleDate={scheduleDate}
        />
      )}
    </>
  );
};

export default AssignToolboxContainer;
