export const resourceType = {
  RESOURCE_PAGE: "Resource",
  GET_RESOURCE_LIST: "[GET_RESOURCE_LIST] Get resource List Data",
  GET_RESOURCE_LIST_SUCCESS:
    "[GET_RESOURCE_LIST_SUCCESS] Get resource List Data Successfull",
  GET_RESOURCE_LIST_EMAIL_SUCCESS:
    "[GET_RESOURCE_LIST_EMAIL_SUCCESS] Send resource List Data Successfull",
  GET_RESOURCE_LIST_FAILURE:
    "[GET_RESOURCE_LIST_FAILURE] Get resource List Data Failed",

  POST_RESOURCE_PENDING: "[POST_RESOURCE_PENDING] Create resource  Data",
  POST_RESOURCE_SUCCESS:
    "[POST_RESOURCE_SUCCESS] Create resource Data Successfull",
  POST_RESOURCE_FAILURE: "[POST_RESOURCE_FAILURE] Create resource  Data Failed",

  POST_UPDATE_RESOURCE: "[POST_UPDATE_RESOURCE] Updates resource  Data",
  POST_UPDATE_RESOURCE_SUCCESS:
    "[POST_UPDATE_RESOURCE_SUCCESS] Update resource Data Successfull",
  POST_UPDATE_RESOURCE_FAILURE:
    "[POST_UPDATE_RESOURCE_FAILURE] Update resource  Data Failed",
  CLEAR_RESOURCE: "[CLEAR_RESOURCE][REQUEST] Clear RESOURCE",
  EDIT_RESOURCE: "[EDIT_EDIT_RESOURCE][REQUEST] Edit RESOURCE",

  GET_RESOURCE_DETAILS: "[GET_RESOURCE_DETAILS] Get resource Details Data",
  GET_RESOURCE_DETAILS_SUCCESS:
    "[GET_RESOURCE_DETAILS_SUCCESS] Get resource Details Data Successfull",
  GET_RESOURCE_DETAILS_FAILURE:
    "[GET_RESOURCE_DETAILS_FAILURE] Get resource Details Data Failed",

  DELETE_RESOURCE_PENDING: "DELETE_RESOURCE",
  DELETE_RESOURCE_SUCCESS: "DELETE_RESOURCE_SUCCESS",
  DELETE_RESOURCE_FAILED: "DELETE_RESOURCE_FAILED",
};
