import React, { useEffect, useState } from "react";
import UserSerchByRoleList from "../component/userSearchByRole/userSerchByRoleList";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import * as userAction from "../../../redux/actions/userAction";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";

function UserSearchByRoleContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState(false);
  const usersByRoleListData = useSelector((state) => state.userByRoleListData);
  const isLoading = usersByRoleListData?.isLoading;

  const getUserByRoleLists = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(userAction.getUserByRoleList(data));
  };

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      roleId: 0,
      isSendMail: false,
    };
    getUserByRoleLists(data);
  }, []);

  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 5,
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    if (usersByRoleListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("User role has been sent to registered email ID");
    }
  }, [usersByRoleListData]);

  return (
    <>
      {checkPrivileges([12, 139, 64, 65, 66, 148]) ? (
        <>
          <ToastContainer />
          <UserSerchByRoleList
            roles={roles}
            getUserByRoleLists={getUserByRoleLists}
            usersByRoleListData={usersByRoleListData}
            isLoading={isLoading}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default UserSearchByRoleContainer;
