import React, { useEffect } from "react";
import ReportTrainingSessions from "../component/reportTrainingSessions";
import { useDispatch, useSelector } from "react-redux";
import * as lmsReportAction from "../../../redux/actions/lmsReportAction";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
function ReportTrainingSessionsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lmsReport = useSelector((state) => state.getLmsReport);
  const lmsReportLesson = useSelector((state) => state.getLmsReportLesson);
  const lmsReportCourse = useSelector((state) => state.getLmsReportCourse);

  const showLoader = useSelector(
    (state) =>
      state.getLmsReport.isLoading ||
      state.getLmsReportLesson.isLoading ||
      state.getLmsReportCourse.isLoading
  );

  const getLmsReportData = (data) => {
    dispatch(lmsReportAction.getLmsReport(data));
  };
  const getLmsReportLessonData = (data) => {
    dispatch(lmsReportAction.getLmsReportLesson(data));
  };
  const getLmsReportCourseData = (data) => {
    dispatch(lmsReportAction.getLmsReportCourse(data));
  };

  useEffect(() => {
    if (!checkPrivileges([12, 114, 115, 116])) navigate(ACCESS_DENIED);
  }, []);
  return (
    <>
      {checkPrivileges([12, 114, 115, 116]) ? (
        <>
          {showLoader && <Loader />}

          <ReportTrainingSessions
            getLmsReportData={getLmsReportData}
            getLmsReportLessonData={getLmsReportLessonData}
            getLmsReportCourseData={getLmsReportCourseData}
            lmsReportData={showLoader ? [] : lmsReport?.data}
            lmsReportLesson={showLoader ? [] : lmsReportLesson?.data}
            lmsReportCourse={showLoader ? [] : lmsReportCourse?.data}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default ReportTrainingSessionsContainer;
