import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import "./DialogBox.scss";
import commonImages, { InspectionCardImage, modalImg } from "../../assets";
import { Grid } from "@mui/material";
import { useThemeContext } from "../../context";
import { Edit } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
export default function CustomizedDialogs(props) {
  const { mode } = useThemeContext();
  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        onClose={props.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className={
          props?.photoView
            ? "dialogBoxContainer fullWidth"
            : "dialogBoxContainer"
        }
      >
        {props?.deleteView && (
          <>
            {props?.deleteIcon && (
              <img
                className="deleteIcon"
                src={
                  props?.fileIcon
                    ? commonImages?.docIcon
                    : commonImages?.deleteIcon
                }
                width={props?.fileIcon ? "50" : "27"}
                height={props?.fileIcon ? "50" : "29"}
                alt="deleteIcon"
              />
            )}

            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={props.handleCloseModal}
              className="dialog-title"
            >
              {props.header ? props?.header : "Confirm Deletion"}
            </BootstrapDialogTitle>
            <DialogContent className="modalParaContent">
              <Typography gutterBottom className="modalPara">
                {props.content
                  ? props?.content
                  : "Are you sure you want to delete this"}
              </Typography>
            </DialogContent>
            <DialogActions className="dialogConfirm">
              <Button
                autoFocus
                onClick={
                  props?.customCloseShow
                    ? props?.customClose
                    : props.handleCloseModal
                }
                className="deleteno"
              >
                No
              </Button>
              <Stack direction="row" spacing={2}>
                <Button
                  className="deleteyes"
                  variant="outlined"
                  color="error"
                  onClick={() => props.deletehandle()}
                >
                  Yes
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}

        {props?.scheduleJustAndEntirePopup && (
          <>
            {props?.deleteIcon && props?.scheduleEdit === false && (
              <img
                className="deleteIcon"
                src={commonImages?.deleteIcon}
                width="27"
                height="29"
                alt="deleteIcon"
              />
            )}

            {props?.deleteIcon && props?.scheduleEdit === true && (
              <div className="deleteIcon">
                {" "}
                <Edit />
              </div>
            )}

            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={props.handleCloseModal}
              className="dialog-title"
            >
              {props.header ? props?.header : "Confirm Deletion"}
            </BootstrapDialogTitle>
            <DialogContent className="modalParaContent">
              <Typography gutterBottom className="modalPara">
                {props.content
                  ? props?.content
                  : "Are you sure you want to delete this"}
              </Typography>
            </DialogContent>
            <DialogActions className="dialogConfirmSafetyMeeting">
              <Button
                autoFocus
                onClick={() => props.handleJustscheduleAndEntirePopup(1)}
                className="deleteEntire"
              >
                Just This One
              </Button>

              <Stack direction="row" spacing={2}>
                <Button
                  className="deleteEntire"
                  variant="outlined"
                  onClick={() => props.handleJustscheduleAndEntirePopup(2)}
                >
                  Entire Series
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}

        {props?.photoView && (
          <>
            <Grid container spacing={2}>
              <Grid md={12} xs={12} item>
                <div className="closeBox" onClick={props.handleCloseModal}>
                  <img
                    className="closeIcon"
                    src={
                      mode === "dark"
                        ? InspectionCardImage?.close_dark
                        : InspectionCardImage?.close
                    }
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {props?.photos?.length > 0 ? (
                props?.photos?.map((photo) => {
                  return (
                    <Grid md={4} sm={4} xs={12} item key={photo?.fileId}>
                      <div className="photoBox">
                        <img
                          src={photo?.filePath}
                          alt={photo?.filePath}
                          className="photoIMg"
                        />
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <div className="nophoto">No Photo available</div>
              )}
            </Grid>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
}

CustomizedDialogs.defaultProps = {
  deleteIcon: true,
  deleteView: true,
  photoView: false,
  scheduleJustAndEntirePopup: false,
  scheduleEdit: false,
  fileIcon: false,
  customCloseShow: false
};
