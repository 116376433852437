import React, { useEffect } from "react";
import AssignedUserList from "../component/assignedUser/assignedUser";
import * as lmsAction from "../../../redux/actions/lmsAction";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, API_STATUS } from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const AsignComplUserCourseContainer = () => {
  const location = useLocation();
  const fieldid = location?.state?.fieldId;
  const courseId = location?.state?.courseId;
  const coursename = location?.state?.coursename;
  const dispatch = useDispatch();
  const [isunAssignbtn, setisunAssignbtn] = useState(false);

  const [isdisable, setisdisable] = useState(false);
  const coursesUserAssignListData = useSelector(
    (state) => state?.getAssignCourseUserList
  );

  // alert mail  for Course assign
  const lessonUserAssignAlertData = useSelector(
    (state) => state?.getAssignAlertUserListReducer
  );
  const assignUserloading = useSelector(
    (state) => state?.getAssignAlertUserListReducer?.isLoading
  );
  const unAssignCourseReducerData = useSelector(
    (state) => state?.unAssignCourse
  );

  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    courseId: courseId,
    isCompleted: fieldid,
  };
  let navigate = useNavigate();

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);
    if (isAllowedDash === false) {
      navigate(ACCESS_DENIED);
    }
    getCoursesAssignUserListData(defaultData);
  }, []);
  useEffect(() => {
    if (
      unAssignCourseReducerData.status === API_STATUS.SUCCESS &&
      isunAssignbtn
    ) {
      getCoursesAssignUserListData(defaultData);
    }
  }, [unAssignCourseReducerData]);
  const [clearalert, setClearalert] = useState(false);
  const getCoursesAssignUserListData = async (data) => {
    await dispatch(lmsAction.getAssignedList(data));
  };

  const addSubmitAlert = async (data) => {
    setClearalert(true);
    await dispatch(lmsAction.getAlertmailAssigncourseLesson(data));
  };

  useEffect(() => {
    if (
      lessonUserAssignAlertData.status == API_STATUS.SUCCESS &&
      lessonUserAssignAlertData?.isLoading == false &&
      clearalert
    ) {
      toast("Mail Sending successfully");
    }
  }, [lessonUserAssignAlertData]);

  const postunAssignCourse = async (data) => {
    setisdisable(true);
    dispatch(lmsAction.unAssignCourse(data));
  };
  return (
    <>
      {assignUserloading && <Loader />}

      <AssignedUserList
        getCoursesAssignUserListData={getCoursesAssignUserListData}
        coursesUserAssignListData={coursesUserAssignListData?.dataresult}
        isLoading={coursesUserAssignListData?.isLoading}
        coursename={coursename ?? ""}
        courseId={courseId}
        fieldid={fieldid}
        onSubmit={addSubmitAlert}
        postunAssignCourse={postunAssignCourse}
        setisunAssignbtn={setisunAssignbtn}
        setisdisable={setisdisable}
        isdisable={isdisable}
      />
      <ToastContainer />
    </>
  );
};

export default AsignComplUserCourseContainer;
