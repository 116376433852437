import tableImg from "../../assets/noPublishImg.svg";
import "./courseConstantStyle.scss";
export const COLUMNS_COURSES_VIEW = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "course",
    headerName: "Course Name",
    flex: 2.5,
    minWidth: 190,
    renderCell: (cellValues) => {
      let img = cellValues.row.coverImage;

      return (
        <>
          <div className="nameBox">
            <div className="nameImg">
              <img src={img ? img : tableImg} alt="" />
            </div>
            <div className="nameBoxpara">{cellValues?.value}</div>
          </div>
        </>
      );
    },
  },
  { field: "category", headerName: "Category", flex: 1.5, minWidth: 70 },
  {
    field: "totaleAssignedToUser",
    headerName: "Assigned to",
    type: "text",
    flex: 1,
    minWidth: 90,
    renderCell: (cellValues) => {
      return <>{cellValues.row.totaleAssignedToUser}</>;
    },
    cellClassName: "assigned-to--cell",
  },
  {
    field: "totalCompletedByUser",
    headerName: "Completed By",
    type: "text",
    flex: 1,
    minWidth: 90,
    cellClassName: "completed-byuser--cell",
  },
  {
    field: "totalNotCompletedByUser",
    headerName: "Not Completed By",
    type: "text",
    flex: 1.3,
    minWidth: 90,
    renderCell: (cellValues) => {
      return (
        <>
          {cellValues.row.totaleAssignedToUser -
            cellValues.row.totalCompletedByUser}
        </>
      );
    },
    cellClassName: "completed-byuser--cell",
  },
  {
    field: "isGlobal",
    headerName: "Global",
    type: "text",
    minWidth: 55,
    flex: 0.8,
    cellClassName: "completed-byuser--cell",
    renderCell: (cellValues) => {
      return <>{cellValues.row.isGlobal === true ? "Global" : "NA"}</>;
    },
  },
];

export const COLUMNS_LESSON_VIEW = [
  { field: "lessonId", headerName: "ID", width: 60 },
  {
    field: "lesson",
    headerName: "Lesson Name",
    flex: 2.5,
    minWidth: 190,
    renderCell: (cellValues) => {
      let img = cellValues.row.coverImage;

      return (
        <>
          <div className="nameBox">
            <div className="nameImg">
              <img src={img ? img : tableImg} alt="" />
            </div>
            <div className="nameBoxpara">{cellValues?.value}</div>
          </div>
        </>
      );
    },
  },
  { field: "category", headerName: "Category", flex: 1.2, minWidth: 70 },
  {
    field: "totaleAssignedToUser",
    headerName: "Assigned to",
    type: "text",
    minWidth: 90,
    flex: 1,
    cellClassName: "assigned-to--cell",
  },
  {
    field: "totalCompletedByUser",
    headerName: "Completed By",
    type: "text",
    flex: 1,
    minWidth: 90,
    cellClassName: "completed-byuser--cell",
  },
  {
    field: "totalNotCompletedByUser",
    headerName: "Not Completed By",
    type: "text",
    flex: 1.3,
    minWidth: 90,
    renderCell: (cellValues) => {
      return (
        <>
          {cellValues.row.totaleAssignedToUser -
            cellValues.row.totalCompletedByUser}
        </>
      );
    },
    cellClassName: "completed-byuser--cell",
  },
  {
    field: "isGlobal",
    headerName: "Global",
    type: "text",
    flex: 0.8,
    minWidth: 55,
    cellClassName: "completed-byuser--cell",
    renderCell: (cellValues) => {
      return <>{cellValues.row.isGlobal === true ? "Global" : "NA"}</>;
    },
  },
];

export const COLUMNS_TOOLBOXTALK_VIEW = [
  { field: "id", headerName: "ID", width: 70 },

  {
    field: "titleSafetyMeeting",
    headerName: "Safety Meeting Name",
    flex: 2,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },

  { field: "categoryName", headerName: "Category", flex: 1 },
  { field: "assignedOn", headerName: "Assigned On", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (cellValues) => {
      return (
        <>
          {cellValues.row.assignInstructorsUsersId == null
            ? "Not Assigned"
            : "Assigned"}
        </>
      );
    },
  },
];
// schedule admin
export const COLUMNS_TOOLBOXTALKADMIN_VIEW = [
  { field: "scheduleId", headerName: "ID", width: 100 },

  {
    field: "titleSafetyMeeting",
    headerName: "Safety Meeting Name",
    flex: 2,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },

  { field: "assignedOn", headerName: "Scheduled Date", flex: 1 },
  { field: "categoryName", headerName: "Category", flex: 1 },
  {
    field: "totalAssignedTo",
    headerName: "Assigned to",
    type: "text",
    flex: 1,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },
  // {
  //   field: "totalUserCompetedBy",
  //   headerName: "Completed By",
  //   type: "text",
  //   flex: 1,
  //   cellClassName: "ToolBoxTitle-assigned-to--cell",
  // },
  {
    field: "totalUserNotCompetedBy",
    headerName: "Not Completed By",
    type: "text",
    flex: 1,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },
];
export const COLUMNS_TOOLBOXTALKMYASSIGN_VIEW = [
  { field: "id", headerName: "ID", width: 100 },

  {
    field: "titleSafetyMeeting",
    headerName: "Safety Meeting Name",
    flex: 2,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },

  { field: "categoryName", headerName: "Category", flex: 1 },

  {
    field: "assignedOn",
    headerName: "Assigned On",
    type: "text",
    flex: 1,
  },
  {
    field: "totaleAssignedTo",
    headerName: "Assign to",
    type: "text",
    flex: 1,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },
  {
    field: "totalUserCompetedBy",
    headerName: "Completed By",
    type: "text",
    flex: 1,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },
  {
    field: "totalUserNotCompetedBy",
    headerName: "Not Completed By",
    type: "text",
    flex: 1,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },
];
export const SAFETYMEETINGSEARCH = [
  { key: 5, value: "Once" },
  { key: 1, value: "Weekly" },
  { key: 2, value: "Bi-weekly" },
  { key: 3, value: "Monthly" },
  { key: 4, value: "Quarterly" },
];
export const COLUMNS_ADMIN_TOOLBOXTALK_VIEW = [
  { field: "id", headerName: "ID", width: 100 },

  {
    field: "titleSafetyMeeting",
    headerName: "Safety Meeting Name",
    flex: 1.5,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },

  { field: "categoryName", headerName: "Category", flex: 1.5 },
];

export const COLUMNS_USER_TOOLBOXTALK_VIEW = [
  { field: "id", headerName: "ID", width: 100 },

  {
    field: "titleSafetyMeeting",
    headerName: "Safety Meeting Name",
    flex: 2,
    cellClassName: "ToolBoxTitle-assigned-to--cell",
  },

  { field: "categoryName", headerName: "Category", flex: 1.5 },
  {
    field: "assignedOn",
    headerName: "Assigned On",
    type: "text",
    flex: 1,
  },
];
export const SCHEDULEDTYPELIST = [
  { key: 1, value: "Yes" },
  { key: 2, value: "No" },
];