import React from "react";
import TableData from "./tableData";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";

const InspectionViewReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const detail = location?.state;
  return (
    <>
      {checkPrivileges([12, 16, 4, 5, 6]) ? (
        <TableData detail={detail} />
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default InspectionViewReport;
