import React, { useEffect, useState } from "react";
import ViewSite from "../components/viewSite";
import { useDispatch, useSelector } from "react-redux";
import * as siteAction from "../../../../redux/storm-water/actions/siteAction";
import { useLocation, useNavigate } from "react-router-dom";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import {
  ACCESS_DENIED,
  ADMIN_SITE_PROJECTS_LIST,
} from "../../../../constant/routeContant";
import Loader from "../../../../library/common/Loader";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../../constant/constant";
import * as swprojectAction from "../../../../redux/storm-water/actions/projectAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";

function ViewSiteContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const getSiteDetsilsData = useSelector((state) => state.getSiteDetsilsData);
  const getSiteData = useSelector((state) => state.getSite);
  const siteId = location?.state?.siteid;
  const viewMode = location?.state?.viewMode;
  const editMode = location?.state?.editMode;
  const isGlobal = location?.state?.isGlobal;

  const [showProjectDetails, setShowProjectDetails] = useState([]);
  const [ids, setIds] = useState();
  const dispatch = useDispatch();

  const swprojectListData = useSelector((state) => state?.swprojectList);
  const swProjectList = (data) => {
    dispatch(swprojectAction.swProjectList(data));
  };
  const isLoading = useSelector((state) => state?.swprojectList?.isLoading);

  const showLoader = useSelector(
    (state) =>
      state.getSiteDetsilsData.isLoading ||
      state.state.isLoading ||
      state.city.isLoading ||
      state.getSite.isLoading
  );
  const isSuccess = useSelector(
    (state) => state.getSiteDetsilsData.status === API_STATUS.SUCCESS
  );

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  useEffect(() => {
    getStateData();
  }, []);

  const getSiteDetsil = (data) => {
    dispatch(siteAction.getSiteDetsils(data));
  };

  useEffect(() => {
    if (viewMode && siteId) {
      getSiteDetsil(siteId);
    }
  }, []);

  const dataBack = {
    title: "Back to Site",
    route: ADMIN_SITE_PROJECTS_LIST,
  };

  const toggleProjectDetails = (id) => {
    const newShowProjectDetails = [...showProjectDetails];
    newShowProjectDetails[id] = !newShowProjectDetails[id];
    setShowProjectDetails(newShowProjectDetails);

    if (newShowProjectDetails[id]) {
      setIds(id);
      swProjectList({
        page: 1,
        pageSize: PAGE_SIZE,
        customerId: id,
        startDate: "",
        endDate: "",
        search: "",
        siteId: siteId,
        status: "",
      });
    }
  };

  return (
    <>
      {checkPrivileges([12, 108, 109, 110]) ? (
        <>
          {showLoader && <Loader />}
          {isSuccess && (
            <ViewSite
              getSiteDetsilsData={getSiteDetsilsData?.data?.result}
              dataBack={dataBack}
              getSiteData={getSiteData?.data?.result}
              toggleProjectDetails={toggleProjectDetails}
              showProjectDetails={showProjectDetails}
              swprojectListData={swprojectListData}
              isLoading={isLoading}
              siteId={siteId}
              ids={ids}
              swProjectList={swProjectList}
              editMode={editMode}
              isGlobal={isGlobal}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default ViewSiteContainer;
