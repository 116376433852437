import { userType } from "../constants/userType";

export const getUserDetails = (mobile) => ({
  type: userType.GET_USER,
  mobile,
});

export const getUserDetailsSuccess = (data) => ({
  type: userType.GET_USER_SUCCESS,
  data,
});

export const getUserDetailsFailure = (error) => ({
  type: userType.GET_USER_FAILURE,
  error,
});

export const searchUsers = (searchType, searchText) => ({
  type: userType.GET_SEARCH_USER,
  searchType,
  searchText,
});

export const searchUsersSuccess = (data) => ({
  type: userType.GET_SEARCH_USER_SUCCESS,
  data,
});

export const searchUsersFailure = (error) => ({
  type: userType.GET_SEARCH_USER_FAILURE,
  error,
});

export const searchAllUsers = (
  searchText,
  searchType,
  divisionId,
  assetType,
  assetId,
  priviledgeId,
  productId,
  orgId,
  withSpace
) => ({
  type: userType.GET_ALL_USER,
  searchText,
  searchType,
  divisionId,
  assetType,
  assetId,
  priviledgeId,
  productId,
  orgId,
  withSpace,
});

export const searchAllUsersSuccess = (data) => ({
  type: userType.GET_ALL_USER_SUCCESS,
  data,
});

export const searchAllUsersFailure = (error) => ({
  type: userType.GET_ALL_USER_FAILURE,
  error,
});

export const getUserList = (data) => ({
  type: userType.GET_USER_LIST,
  data,
});

export const getUserListSuccess = (data) => ({
  type: userType.GET_USER_LIST_SUCCESS,
  data,
});

export const getUserListEmailSuccess = () => ({
  type: userType.GET_USER_LIST_SUCCESS_EMAIL,
});

export const getUserListFailure = (error) => ({
  type: userType.GET_USER_LIST_FAILURE,
  error,
});

export const getUserListDetsils = (data) => ({
  type: userType.GET_USER_LIST_DETAILS,
  data,
});

export const getUserListDetsilsSuccess = (data) => ({
  type: userType.GET_USER_LIST_DETAILS_SUCCESS,
  data,
});

export const getUserListDetsilsFailure = (error) => ({
  type: userType.GET_USER_LIST_DETAILS_FAILURE,
  error,
});

export const updateUserList = (payload) => ({
  type: userType.UPDATE_USER_LIST,
  payload,
});

export const updateUserListSuccess = (data) => ({
  type: userType.UPDATE_USER_LIST_SUCCESS,
  data,
});

export const updateUserListFailure = (error) => ({
  type: userType.UPDATE_USER_LIST_FAILURE,
  error,
});

export const deleteList = (userId) => ({
  type: userType.DELETE_USER_LIST,
  userId,
});

export const deleteListSuccess = (data) => ({
  type: userType.DELETE_USER_LIST_SUCCESS,
  data,
});

export const deleteListFailure = (error) => ({
  type: userType.DELETE_USER_LIST_FAILURE,
  error,
});

export const addUserList = (payload) => ({
  type: userType.POST_USER_LIST,
  payload,
});

export const addUserListSuccess = (data) => ({
  type: userType.POST_USER_LIST_SUCCESS,
  data,
});

export const addUserListFailure = (error) => ({
  type: userType.POST_USER_LIST_FAILURE,
  error,
});

export const addUserListClear = () => ({
  type: userType.CLEAR_LIST,
  data: "",
});

export const getListGroupLists = (data) => ({
  type: userType.GET_USER_GROUP_LIST,
  data,
});

export const getListGroupListsSuccess = (data) => ({
  type: userType.GET_USER_GROUP_LIST_SUCCESS,
  data,
});

export const getListGroupListsEmailSuccess = () => ({
  type: userType.GET_USER_GROUP_LIST_EMAIL_SUCCESS,
});

export const getListGroupListsFailure = (error) => ({
  type: userType.GET_USER_GROUP_LIST_FAILURE,
  error,
});

export const addUserGroup = (payload) => ({
  type: userType.POST_USER_GROUP_LIST,
  payload,
});

export const addUserGroupSuccess = (data) => ({
  type: userType.POST_USER_GROUP_LIST_SUCCESS,
  data,
});

export const addUserGroupFailure = (error) => ({
  type: userType.POST_USER_GROUP_LIST_FAILURE,
  error,
});

export const getUserGroupDetails = (data) => ({
  type: userType.GET_USER_GROUP_DETAILS,
  data,
});

export const getUserGroupDetailsSuccess = (data) => ({
  type: userType.GET_USER_GROUP_DETAILS_SUCCESS,
  data,
});

export const getUserGroupDetailsFailure = (error) => ({
  type: userType.GET_USER_GROUP_DETAILS_FAILURE,
  error,
});

export const updateUserGroup = (payload) => ({
  type: userType.UPDATE_USER_GROUP,
  payload,
});

export const updateUserGroupSuccess = (data) => ({
  type: userType.UPDATE_USER_GROUP_SUCCESS,
  data,
});

export const updateUserGroupFailure = (error) => ({
  type: userType.UPDATE_USER_GROUP_FAILURE,
  error,
});

export const deleteUserGroup = (userId) => ({
  type: userType.DELETE_USER_GROUP,
  userId,
});

export const deleteUserGroupuccess = (data) => ({
  type: userType.DELETE_USER_GROUP_SUCCESS,
  data,
});

export const deleteUserGroupFailure = (error) => ({
  type: userType.DELETE_USER_GROUP_FAILURE,
  error,
});

export const getUserGroupListsData = (data) => ({
  type: userType.GET_USER_INVOLVED_GROUP_LIST,
  data,
});

export const getUserGroupListsDataSuccess = (data) => ({
  type: userType.GET_USER_INVOLVED_GROUP_LIST_SUCCESS,
  data,
});

export const getUserGroupListsDataFailure = (error) => ({
  type: userType.GET_USER_INVOLVED_GROUP_LIST_FAILURE,
  error,
});

export const leaveGroup = (groupId) => ({
  type: userType.LEAVE_GROUP,
  groupId,
});

export const leaveGroupuccess = (data) => ({
  type: userType.LEAVE_GROUP_SUCCESS,
  data,
});

export const leaveGroupFailure = (error) => ({
  type: userType.LEAVE_GROUP_FAILURE,
  error,
});

export const clearList = () => ({
  type: userType.CLEAR_LIST,
  data: "",
});

export const searchAllGroup = (
  searchText,
  divisionId,
  alldivision,
  orgId,
  assetType,
  assetId
) => ({
  type: userType.GET_SEARCH_GROUP,
  searchText,
  divisionId,
  alldivision,
  orgId,
  assetType,
  assetId,
});

export const searchAllGroupSuccess = (data) => ({
  type: userType.GET_SEARCH_GROUP_SUCCESS,
  data,
});

export const searchAllGroupFailure = (error) => ({
  type: userType.GET_SEARCH_GROUP_FAILURE,
  error,
});

export const getUserConfigurationList = (data) => ({
  type: userType.GET_USER_CONFIGURATION_LIST,
  data,
});

export const getUserConfigurationListSuccess = (data) => ({
  type: userType.GET_USER_CONFIGURATION_LIST_SUCCESS,
  data,
});

export const getUserConfigurationListEmailSuccess = () => ({
  type: userType.GET_USER_CONFIGURATION_LIST_EMAIL_SUCCESS,
});

export const getUserConfigurationListFailure = (error) => ({
  type: userType.GET_USER_CONFIGURATION_LIST_FAILURE,
  error,
});

export const updateUserConfigurationList = (payload) => ({
  type: userType.UPDATE_USER_CONFIGURATION_LIST,
  payload,
});

export const updateUserConfigurationListSuccess = (data) => ({
  type: userType.UPDATE_USER_CONFIGURATION_LIST_SUCCESS,
  data,
});

export const updateUserConfigurationListFailure = (error) => ({
  type: userType.UPDATE_USER_CONFIGURATION_LIST_FAILURE,
  error,
});

export const getUserConfigurationDetsils = (data) => ({
  type: userType.GET_USER_CONFIGURATION_DETAILS,
  data,
});

export const getUserConfigurationDetsilsSuccess = (data) => ({
  type: userType.GET_USER_CONFIGURATION_DETAILS_SUCCESS,
  data,
});

export const getUserConfigurationDetsilsFailure = (error) => ({
  type: userType.GET_USER_CONFIGURATION_DETAILS_FAILURE,
  error,
});

export const updateUserOnboard = (payload) => ({
  type: userType.UPDATE_USER_ONBOARD,
  payload,
});

export const updateUserOnboardSuccess = (data) => ({
  type: userType.UPDATE_USER_ONBOARD_SUCCESS,
  data,
});

export const updateUserOnboardFailure = (error) => ({
  type: userType.UPDATE_USER_ONBOARD_FAILURE,
  error,
});

export const updateUserOnboardStatus = (payload) => ({
  type: userType.UPDATE_USER_ONBOARD_STATUS,
  payload,
});

export const updateUserOnboardStatusSuccess = (data) => ({
  type: userType.UPDATE_USER_ONBOARD_STATUS_SUCCESS,
  data,
});

export const updateUserOnboardStatusFailure = (error) => ({
  type: userType.UPDATE_USER_ONBOARD_STATUS_FAILURE,
  error,
});

export const clearUser = () => ({
  type: userType.CLEAR_USER,
  data: "",
});

export const getNonPrivilegeUserList = (data) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST,
  data,
});

export const getNonPrivilegeListSuccess = (data) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST_SUCCESS,
  data,
});

export const getNonPrivilegeListEmailSuccess = (data) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST_EMAIL_SUCCESS,
});

export const getNonPrivilegeListFailure = (error) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST_FAILURE,
  error,
});

export const deleteNonPrivilegeList = (userId) => ({
  type: userType.DELETE_NON_PRIVILEGE_USER_LIST,
  userId,
});

export const deleteNonPrivilegeListSuccess = (data) => ({
  type: userType.DELETE_NON_PRIVILEGE_USER_LIST_SUCCESS,
  data,
});

export const deleteNonPrivilegeListFailure = (error) => ({
  type: userType.DELETE_NON_PRIVILEGE_USER_LIST_FAILURE,
  error,
});

export const addNonPrivilegeList = (payload) => ({
  type: userType.POST_NON_PRIVILEGE_USER_LIST,
  payload,
});

export const addNonPrivilegeListSuccess = (data) => ({
  type: userType.POST_NON_PRIVILEGE_USER_LIST_SUCCESS,
  data,
});

export const addNonPrivilegeListFailure = (error) => ({
  type: userType.POST_NON_PRIVILEGE_USER_LIST_FAILURE,
  error,
});

export const getNonPrivilegeListDetsils = (data) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS,
  data,
});

export const getNonPrivilegeListDetsilsSuccess = (data) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS_SUCCESS,
  data,
});

export const getNonPrivilegeListDetsilsFailure = (error) => ({
  type: userType.GET_NON_PRIVILEGE_USER_LIST_DETAILS_FAILURE,
  error,
});

export const updateNonPrivilegeList = (payload) => ({
  type: userType.UPDATE_NON_PRIVILEGE_USER_LIST,
  payload,
});

export const updateNonPrivilegeListSuccess = (data) => ({
  type: userType.UPDATE_NON_PRIVILEGE_USER_LIST_SUCCESS,
  data,
});

export const updateNonPrivilegeListFailure = (error) => ({
  type: userType.UPDATE_NON_PRIVILEGE_USER_LIST_FAILURE,
  error,
});

export const convertNonPrivilegeList = (payload) => ({
  type: userType.CONVERT_NON_PRIVILEGE_USER_LIST,
  payload,
});

export const convertNonPrivilegeListSuccess = (data) => ({
  type: userType.CONVERT_NON_PRIVILEGE_USER_LIST_SUCCESS,
  data,
});

export const convertNonPrivilegeListFailure = (error) => ({
  type: userType.CONVERT_NON_PRIVILEGE_USER_LIST_FAILURE,
  error,
});

export const recreateUsers = (payload) => ({
  type: userType.POST_RECREATE_USERS,
  payload,
});

export const recreateUsersSuccess = (data) => ({
  type: userType.POST_RECREATE_USERS_SUCCESS,
  data,
});

export const recreateUsersFailure = (error) => ({
  type: userType.POST_RECREATE_USERS_FAILURE,
  error,
});

export const getAssetOnUser = (data) => ({
  type: userType.GET_ASSET_ON_USER,
  data,
});

export const getAssetOnUserSuccess = (data) => ({
  type: userType.GET_ASSET_ON_USER_SUCCESS,
  data,
});

export const getAssetOnUserFailure = (error) => ({
  type: userType.GET_ASSET_ON_USER_FAILURE,
  error,
});

export const getSupervisorOnAsset = (data) => ({
  type: userType.GET_SUPERVISOR_ON_ASSET,
  data,
});

export const getSupervisorOnAssetSuccess = (data) => ({
  type: userType.GET_SUPERVISOR_ON_ASSET_SUCCESS,
  data,
});

export const getSupervisorOnAssetFailure = (error) => ({
  type: userType.GET_SUPERVISOR_ON_ASSET_FAILURE,
  error,
});

export const checkUserInfo = (payload) => ({
  type: userType.CHECK_USER_INFO,
  payload,
});

export const checkUserInfoSuccess = (data) => ({
  type: userType.CHECK_USER_INFO_SUCCESS,
  data,
});

export const checkUserInfoFailure = (error) => ({
  type: userType.CHECK_USER_INFO_FAILURE,
  error,
});

export const getUserByRoleList = (data) => ({
  type: userType.GET_USER_BY_ROLE_LIST,
  data,
});

export const getUserByRoleSuccess = (data) => ({
  type: userType.GET_USER_BY_ROLE_LIST_SUCCESS,
  data,
});

export const getUserByRoleEmailSuccess = () => ({
  type: userType.GET_USER_BY_ROLE_LIST_EMAIL_SUCCESS,
});

export const getUserByRoleFailure = (error) => ({
  type: userType.GET_USER_BY_ROLE_LIST_FAILURE,
  error,
});

export const getSyncProcoreUserList = (data) => ({
  type: userType.GET_SYNC_PROCORE_USER,
  data,
});

export const getSyncProcoreUserListSuccess = (data) => ({
  type: userType.GET_SYNC_PROCORE_USER_SUCCESS,
  data,
});

export const getSyncProcoreUserListFailure = (error) => ({
  type: userType.GET_SYNC_PROCORE_USER_FAILURE,
  error,
});
