import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { COLORS } from "../../../../constant/constant";
import { useThemeContext } from "../../../../context";

export function BarChart({ data, conName, height, postion }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const { mode } = useThemeContext();
  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };

  const options = {
    indexAxis: postion ? postion : "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: postion ? "left" : "right",

        title: {
          display: false,
          text: "Chart.js Horizontal Bar Chart",
        },
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          font: {
            family: "Lato",
          },
        },
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },

    scales: {
      x: {
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  const labels =
    conName === "orgSummaryReport"
      ? data?.map((itm) => itm.orgName)
      : conName === "projectsCorrective"
      ? data?.map((itm) => itm.assetName)
      : conName === "topCategory"
      ? data?.map((itm) => itm.catName)
      : conName === "topSubCategory"
      ? data?.map((itm) => itm.subCatName)
      : data?.map((itm) => itm.inspectorName);
  const getOrganisationSummaryList = {
    labels: labels?.map((label) => truncateLabel(label)),
    datasets: [
      {
        label:
          conName === "projectsCorrective"
            ? "Number of Corrective Items"
            : "Number Of Inspections",
        data:
          conName === "orgSummaryReport"
            ? data?.map((item) => item?.totalInspections)
            : conName === "projectsCorrective"
            ? data?.map((item) => item?.count)
            : conName === "topCategory"
            ? data?.map((item) => item?.count)
            : conName === "topSubCategory"
            ? data?.map((item) => item?.count)
            : data?.map((item) => item?.totalInspections),
        borderColor: "#1B436E",
        backgroundColor: "#1B436E",
        color: "white",
      },
    ],
  };

  return (
    <div style={{ width: "100%px", height: height ?? "400px" }}>
      <Bar options={options} data={getOrganisationSummaryList} />
    </div>
  );
}
