import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./SwInspectionReports.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import Cards from "../../../../feature/reports/component/Cards";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import {
  getDateWithDayPast,
  getFormatedDate,
  getCurrentDate,
} from "../../../../utils/helper";
import { SORT_BY, SW_REPORT_FILTER } from "./SwReportConstant";
import { REPORT_PAGE_SIZE } from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { BarChart } from "./BarChart";

function SwOrganisationSummary({
  getOrganisationSummaryList,
  getOrganisationSummaryListData,
  isLoading,
  conName,
  substract30Day,
}) {
  const [days, setDays] = useState("");
  const [startDate, setStartDate] = useState(getFormatedDate(substract30Day));
  const [endDate, setEndDate] = useState(getCurrentDate(new Date()));
  const [topSortBy, setTopSortBy] = useState("");
  const [page, setPage] = useState(1);

  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
  };

  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
  };

  useEffect(() => {
    setDays(SW_REPORT_FILTER.find((item) => item.key === 30));
  }, []);

  useEffect(() => {
    if (startDate && endDate && days?.key === 12) {
      const data = {
        Top: topSortBy?.key ? topSortBy?.key : 30000,
        FromDate: startDate ? getFormatedDate(startDate) : "",
        ToDate: endDate ? getFormatedDate(endDate) : "",
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getOrganisationSummaryListData(data);
    }
  }, [startDate, endDate]);

  const dayCardOnClick = (value) => {
    setDays(value);
    if (days.key === value.key) {
      setDays("");
      const data = {
        FromDate: "",
        ToDate: "",
        Top: topSortBy?.key ? topSortBy?.key : 30000,
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getOrganisationSummaryListData(data);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const data = {
          FromDate: dateObj.pastData ? dateObj.pastData : "",
          ToDate: dateObj.getCurrentDate ? dateObj.getCurrentDate : "",
          Top: topSortBy?.key ? topSortBy?.key : 30000,
          Page: 1,
          PageSize: REPORT_PAGE_SIZE,
        };
        getOrganisationSummaryListData(data);
        setEndDate(dateObj.getCurrentDate);
        setStartDate(dateObj.pastData);
      }
      if (value.key === 13) {
        setStartDate("");
        setEndDate("");
        const data = {
          FromDate: "",
          ToDate: "",
          Top: topSortBy?.key ? topSortBy?.key : 30000,
          Page: 1,
          PageSize: REPORT_PAGE_SIZE,
        };
        getOrganisationSummaryListData(data);
      }
    }
  };

  const topCardOnClick = (value) => {
    setTopSortBy(value);
    if (topSortBy.key === value.key) {
      setTopSortBy("");
      const data = {
        FromDate: startDate ? getFormatedDate(startDate) : "",
        ToDate: endDate ? getFormatedDate(endDate) : "",
        Top: 30000,
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getOrganisationSummaryListData(data);
    } else {
      if (value.key) {
        const data = {
          FromDate: startDate ? getFormatedDate(startDate) : "",
          ToDate: endDate ? getFormatedDate(endDate) : "",
          Top: value?.key ? value?.key : 30000,
          Page: 1,
          PageSize: REPORT_PAGE_SIZE,
        };
        getOrganisationSummaryListData(data);
      }
    }
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      FromDate: startDate ? getFormatedDate(startDate) : "",
      ToDate: endDate ? getFormatedDate(endDate) : "",
      Top: topSortBy ? topSortBy : 30000,
      Page: newPage + 1,
      PageSize: REPORT_PAGE_SIZE,
    };
    getOrganisationSummaryListData(data);
  };

  const credcloumns = [
    // { field: "orgId", headerName: " Report ID", flex: 2.8 },
    { field: "orgName", headerName: "Client Name", flex: 5 },
    { field: "totalInspections", headerName: "Total inspections", flex: 4 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={getOrganisationSummaryList?.data?.count ?? 0}
          numberOfItemsPerPage={REPORT_PAGE_SIZE}
          incidentLists={
            getOrganisationSummaryList?.data?.organizationList ?? []
          }
          fileNameXL={"OrganisationSummaryList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  return (
    <>
      <Grid container className="SwSummaryReportContainer">
        <Grid item lg={6} xs={12} sm={12} md={4} mt={4} textAlign="left">
          <div className="heading_style_SwRePort">Client Summary</div>
        </Grid>

        <Grid item lg={10} xs={12} sm={12} md={8} mt={5}>
          <FormLabels label={"Select Time Period"} />
          <Cards
            staticCardData={true}
            cardData={SW_REPORT_FILTER}
            selectedVehicleAccident={days}
            cardClickHandler={dayCardOnClick}
          />
        </Grid>
        {days?.value === "Custom" ? (
          <>
            <Grid item container mt={1} spacing={1}>
              <Grid item lg={6} xs={12} sm={12}>
                <FormLabels label={"Custom Time Period"} isRequired={false} />
              </Grid>
            </Grid>

            <Grid item container spacing={2} mb={2}>
              <Grid item lg={4} xs={12} sm={12} md={4}>
                <FormLabels label={"From"} isRequired={false} />
                <DatePicker
                  maxDateEnable={false}
                  value={startDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeStartdatePicker(e)}
                />
              </Grid>
              <Grid item lg={4} xs={12} sm={12} md={4}>
                <FormLabels label={"To"} isRequired={false} />
                <DatePicker
                  maxDateEnable={false}
                  minDate={startDate}
                  value={endDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeEnddatePicker(e)}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Grid item lg={10} xs={12} sm={12} md={8} mt={5}>
          <FormLabels label={"Sort Client"} />
          <Cards
            staticCardData={true}
            cardData={SORT_BY}
            selectedVehicleAccident={topSortBy}
            cardClickHandler={topCardOnClick}
          />
        </Grid>

        <Grid item lg={12} xs={12} sm={12} md={8} mt={5}>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.orgId}
                rows={getOrganisationSummaryList?.data?.organizationList ?? []}
                rowCount={getOrganisationSummaryList?.data?.count ?? 0}
                columns={credcloumns}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={REPORT_PAGE_SIZE}
                rowsPerPageOptions={[REPORT_PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item container>
          <Grid item lg={12} xs={12} sm={12} md={8} mt={5}>
            <BarChart
              conName={conName}
              data={getOrganisationSummaryList?.data?.organizationList}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SwOrganisationSummary;
