import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
  Grading,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../download_file/DownloadCsv";
import commonImages from "../../../assets";

export default function ReportRowManipulte(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    deleteOnClick,
    edit,
    download,
    view,
    deleted,
    editLabel = "Edit Incident",
    deleteLabel = "Delete Incident",
    downloadLable = "Download Report",
    viewLabel = "View Incident",
    openLink,
    openLinkLabel = "Link",
    openLinkUrl,
    configure = false,
    configureLabel,
    configureOnClick,
    safetyLog = false,
    safetyLogOnClick,
    safetyLogLabel = "Safety Log",
    addIncidentLabel = "Add Incident",
    addIncidentClick,
    addIncident = false,
    CloseIncident = false,
    CloseIncidentLabel,
    closeOnClick,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const statusClosed = row?.status === "Closed" ? true : false;
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui">
            <ul>
              {view && (
                <li onClick={() => viewOnClick(row)}>
                  <>
                    <RemoveRedEye /> <span>{viewLabel}</span>
                  </>
                </li>
              )}
              {openLink && (
                <li>
                  <div
                    onClick={() => {
                      window.open(openLinkUrl, "_blank");
                    }}
                    className="downloadresourse"
                  >
                    <FileDownload /> <span>{openLinkLabel}</span>
                  </div>
                </li>
              )}

              {edit && (
                <li
                  // className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => editOnClick(row)}
                >
                  <Edit /> <span>{editLabel}</span>
                </li>
              )}
              {safetyLog && (
                <li onClick={() => safetyLogOnClick(row)}>
                  <Grading /> <span>{safetyLogLabel}</span>
                </li>
              )}
              {configure && (
                <li onClick={() => configureOnClick(row)}>
                  <Edit /> <span>{configureLabel}</span>
                </li>
              )}
              {download && (
                <li onClick={() => dowloadCsv.current.link.click()}>
                  <FileDownload /> <span>{downloadLable}</span>
                </li>
              )}
              {addIncident && (
                <li
                  className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => addIncidentClick(row)}
                >
                  <AddIcon /> <span>{addIncidentLabel}</span>
                </li>
              )}
              {deleted && (
                <li
                  // className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => deleteOnClick(row)}
                  className="deleteIcon"
                >
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.83331 15.5C2.37498 15.5 1.98276 15.3369 1.65665 15.0108C1.32998 14.6842 1.16665 14.2917 1.16665 13.8333V3H0.333313V1.33333H4.49998V0.5H9.49998V1.33333H13.6666V3H12.8333V13.8333C12.8333 14.2917 12.6703 14.6842 12.3441 15.0108C12.0175 15.3369 11.625 15.5 11.1666 15.5H2.83331ZM4.49998 12.1667H6.16665V4.66667H4.49998V12.1667ZM7.83331 12.1667H9.49998V4.66667H7.83331V12.1667Z"
                      fill="#1B436E"
                    />
                  </svg>

                  <span>{deleteLabel}</span>
                </li>
              )}
              {CloseIncident && (
                <li onClick={() => closeOnClick(row)} className="closeIncident">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.938 11L11.896 6.062L10.833 5L6.938 8.875L5.167 7.125L4.104 8.188L6.938 11ZM8 16C6.90267 16 5.868 15.7917 4.896 15.375C3.924 14.9583 3.07333 14.3853 2.344 13.656C1.61467 12.9267 1.04167 12.076 0.625 11.104C0.208333 10.132 0 9.09733 0 8C0 6.88867 0.208333 5.85033 0.625 4.885C1.04167 3.92033 1.61467 3.07333 2.344 2.344C3.07333 1.61467 3.924 1.04167 4.896 0.625C5.868 0.208333 6.90267 0 8 0C9.11133 0 10.1497 0.208333 11.115 0.625C12.0797 1.04167 12.9267 1.61467 13.656 2.344C14.3853 3.07333 14.9583 3.92033 15.375 4.885C15.7917 5.85033 16 6.88867 16 8C16 9.09733 15.7917 10.132 15.375 11.104C14.9583 12.076 14.3853 12.9267 13.656 13.656C12.9267 14.3853 12.0797 14.9583 11.115 15.375C10.1497 15.7917 9.11133 16 8 16Z"
                      fill="#1B436E"
                    />
                  </svg>
                  <span> {CloseIncidentLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={[row]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
