import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { PAGE_SIZE_100 } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { TOOL_BOX_DETAIL } from "../../../../constant/routeContant";
import { useInfiniteScroll } from "../../../../utils/helper";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import Loader from "../../../../library/common/Loader";

const SubcontractorCompletedTraingings = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    if (props?.getToolBoxTalkListResult) {
      setData((prevData) => [
        ...prevData,
        ...props.getToolBoxTalkListResult?.saftyMeetingList,
      ]);
    }
  }, [props?.getToolBoxTalkListResult]);

  const fetchMoreData = async (pageNumber) => {
    const data = {
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE_100,
      search: "",
      catIds: "",
      userId: props.apiData?.userId,
      status: "",
      tabId: 1,
    };
    try {
      const response = await props.getMoretDetails(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    fetchMoreData(pageNumber);
    setPage(pageNumber);
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const handleOnCellClick = (params) => {
    console.log(params);
    let toolboxTalkId = params?.toolboxTalkId;
    let assignInstructorsUsersId = params?.assignInstructorsUsersId;
    let fieldName = params?.field;
    let scheduleId = params?.id;
    let assignedOn = params?.assignedOn;

    let finalid;
    if (fieldName === "titleSafetyMeeting") {
      finalid = "true";
    } else {
      finalid = "false";
    }

    // if (fieldName === "titleSafetyMeeting") {
    navigate(TOOL_BOX_DETAIL, {
      state: {
        fieldId: finalid,
        toolboxTalkId: toolboxTalkId,
        scheduleId: scheduleId,
        assignedOn: assignedOn,
        assignInstructorsUsersId: assignInstructorsUsersId,
        toolboxtalkuyserlist: true,
      },
    });
    // }
  };

  const columns = [
    {
      id: "dateAssigned",
      label: `Date Completed`,
      sortable: true,
      isSorted: sortColumn === "dateAssigned",
      sortDirection,
    },
    {
      id: "timeEstimatedMins",
      label: "Safety Meeting Name",
      sortable: true,
      isSorted: sortColumn === "timeEstimatedMins",
      sortDirection,
    },
    {
      id: "timeElapsedMins",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "timeElapsedMins",
      sortDirection,
    },
    {
      id: "totalUserCompetedBy",
      label: "Completed By",
      sortable: true,
      isSorted: sortColumn === "totalUserCompetedBy",
      sortDirection,
    },
    {
      id: "blank1",
      label: "",
    },
  ];

  const rows = data?.map((meeting) => {
    return (
      <tr className="row" key={meeting?.id}>
        <td>{meeting.assignedOn}</td>
        <td>{meeting.titleSafetyMeeting}</td>
        <td>{meeting.categoryName}</td>
        <td>{props.apiData.userName}</td>

        <td>
          <ReportRowManipulte
            rows={meeting}
            view
            viewOnClick={() => handleOnCellClick(meeting)}
            viewLabel={"View"}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      {props?.userTRainingLoadin ? (
        <Loader />
      ) : (
        <div className="customTableWrapper">
          <Table
            columns={columns}
            rows={rows}
            tableRef={tableRef}
            onSort={handleSort}
          />
        </div>
      )}
    </>
  );
};

export default SubcontractorCompletedTraingings;
