import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OpenTicket from "../component/ticket/openTicket";
import * as supportAction from "../../../redux/actions/supportAction";
import Loader from "../../../library/common/Loader";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fileUpload } from "../../../services/fileUploadService";

const OpenTicketContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString =  window.location.search.slice(10,window.location.search?.length);
  // const ticketId = location?.state?.ticketId ?? queryString;
  const [ticketId, setticketId] = useState(location?.state?.ticketId ?? queryString)
  useEffect(() => {
    setticketId(location?.state?.ticketId ?? queryString)
  }, [queryString])
  
  const status = location?.state?.status;
  function handleSubmit(data) {
    dispatch(supportAction.postCreateTicket(data));
  }
  function handleUserFeedback(data) {
    dispatch(supportAction.postUserFeedback(data));
  }
  // ticket reply
  function handleTicketReply(replyData, ticketDetails) {
    dispatch(supportAction.replyTicket(replyData));
  }
  const replyTicketSatus = useSelector((state) => state.replyTicket.status);
  const userFeedbackOnTicketStatus = useSelector(
    (state) => state.userFeedbackOnTicket.status
  );
  const showLoader = useSelector(
    (state) =>
      state.userFeedbackOnTicket?.isLoading || state.getTicketDetails?.isLoading
  );

  useEffect(() => {
    if (userFeedbackOnTicketStatus === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketDetails?.ticketId));
  }, [userFeedbackOnTicketStatus, dispatch]);
  useEffect(() => {
    if (replyTicketSatus === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketDetails?.ticketId));
  }, [replyTicketSatus, dispatch]);

  const [fileUploadData, setfileUploadData] = useState("")
  
  const [isLoader, setIsLoader] = useState(false);
  
  const uploadFile = async (data) => {
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setfileUploadData(files);
        console.log(files)
      })
      .catch((error) => {
      });
  };
  const ticketDetails = useSelector(
    (state) => state.getTicketDetails?.data?.ticketDetails
  );
  function handleTicketDetails(params) {
    dispatch(supportAction.getTicketDetails(params));
  }
  useEffect(() => {
    if (replyTicketSatus === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketDetails.ticketId));
  }, [replyTicketSatus, dispatch]);
  useEffect(() => {
    handleTicketDetails(ticketId);
  }, [ticketId]);
  return (
    <div>
      {showLoader ? (
        <Loader />
      ) : (
        <OpenTicket
          handleSubmit={handleSubmit}
          handleUserFeedback={handleUserFeedback}
          handleTicketReply={handleTicketReply}
          uploadFile={uploadFile}
          fileUploadData={fileUploadData}
          ticketDetails={ticketDetails}
          status={status}
          handleTicketDetails={handleTicketDetails}
          isLoader={isLoader}
        />
      )}
    </div>
  );
};

export default OpenTicketContainer;