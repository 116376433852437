export const userType = {
  GET_USER: "[REQUEST] Get User Data",
  GET_USER_SUCCESS: "[RESPONSE] Get User Data Successfull",
  GET_USER_FAILURE: "[RESPONSE] Get User Data Failed",

  GET_SEARCH_USER: "[REQUEST] Search User Data",
  GET_SEARCH_USER_SUCCESS: "[RESPONSE] Seach User Data Successfull",
  GET_SEARCH_USER_FAILURE: "[RESPONSE] Seacrh User Data Failed",

  GET_ALL_USER: "[REQUEST GET_ALL_USER] Search all user Data",
  GET_ALL_USER_SUCCESS:
    "[RESPONSE GET_ALL_USER_SUCCESS] Seach all user Data Successfull",
  GET_ALL_USER_FAILURE:
    "[RESPONSE GET_ALL_USER_FAILURE] Seacrh all user Data Failed",

  GET_USER_LIST: "[REQUEST] Get User List Data",
  GET_USER_LIST_SUCCESS: "[RESPONSE] Get User List Data Successfull",
  GET_USER_LIST_SUCCESS_EMAIL:
    "[RESPONSE] Get User List Email Sent Successfull",
  GET_USER_LIST_FAILURE: "[RESPONSE] Get User List  Data Failed",

  DELETE_USER_LIST: "[REQUEST]Delete User List Data",
  DELETE_USER_LIST_SUCCESS: "[RESPONSE]Delete User List Data Successfull",
  DELETE_USER_LIST_FAILURE: "[RESPONSE]Delete User List  Data Failed",

  GET_USER_LIST_DETAILS: "[REQUEST] Get User List Details Data",
  GET_USER_LIST_DETAILS_SUCCESS:
    "[RESPONSE] Get User List Details Data Successfull",
  GET_USER_LIST_DETAILS_FAILURE:
    "[RESPONSE] Get User List Details  Data Failed",

  POST_USER_LIST: "[REQUEST] Post User Data",
  POST_USER_LIST_SUCCESS: "[RESPONSE] Post User Data Successfull",
  POST_USER_LIST_FAILURE: "[RESPONSE] Post User Data Failed",
  USER_PAGE: "User",

  GET_USER_GROUP_LIST: "[REQUEST] Get User group Data",
  GET_USER_GROUP_LIST_SUCCESS: "[RESPONSE] Get User Group Data Successfull",
  GET_USER_GROUP_LIST_EMAIL_SUCCESS: "[GET_USER_GROUP_LIST_EMAIL_SUCCESS] Send User Group Data Successfull",
  GET_USER_GROUP_LIST_FAILURE: "[RESPONSE] Get UserGroup Data Failed",
  USER_PAGE_GROUP: "Group",

  POST_USER_GROUP_LIST: "[REQUEST] Post User group Data",
  POST_USER_GROUP_LIST_SUCCESS: "[RESPONSE] Post User Group Data Successfull",
  POST_USER_GROUP_LIST_FAILURE: "[RESPONSE] Post UserGroup Data Failed",

  UPDATE_USER_LIST: "[REQUEST] Update user List Data",
  UPDATE_USER_LIST_SUCCESS: "[RESPONSE] Update user List Data Successfull",
  UPDATE_USER_LIST_FAILURE: "[RESPONSE] Update user List Data Failed",

  DELETE_USER_GROUP: "[REQUEST]Delete User Group Data",
  DELETE_USER_GROUP_SUCCESS: "[RESPONSE]Delete User Group Data Successfull",
  DELETE_USER_GROUP_FAILURE: "[RESPONSE]Delete User Group  Data Failed",

  UPDATE_USER_GROUP: "[REQUEST] Update user Group Data",
  UPDATE_USER_GROUP_SUCCESS: "[RESPONSE] Update user Group Data Successfull",
  UPDATE_USER_GROUP_FAILURE: "[RESPONSE] Update user Group Data Failed",

  GET_USER_GROUP_DETAILS: "[REQUEST] Get User group Details Data",
  GET_USER_GROUP_DETAILS_SUCCESS:
    "[RESPONSE] Get User Group Details Data Successfull",
  GET_USER_GROUP_DETAILS_FAILURE:
    "[RESPONSE] Get UserGroup Data Details Failed",

  LEAVE_GROUP: "[REQUEST]Leave Group Data",
  LEAVE_GROUP_SUCCESS: "[RESPONSE]Leave  Group Data Successfull",
  LEAVE_GROUP_FAILURE: "[RESPONSE]Leave Group  Data Failed",
  GET_SEARCH_GROUP: "[REQUEST GET_ALL_GROUP] Search all group Data",
  GET_SEARCH_GROUP_SUCCESS:
    "[RESPONSE GET_ALL_GROUP_SUCCESS] Seach all user group Successfull",
  GET_SEARCH_GROUP_FAILURE:
    "[RESPONSE GET_ALL_GROUP_FAILURE] Seacrh all group Data Failed",

  GET_USER_INVOLVED_GROUP_LIST: "[REQUEST] Get Individual User group Data",
  GET_USER_INVOLVED_GROUP_LIST_SUCCESS:
    "[RESPONSE] Get Individual User Group Data Successfull",
  GET_USER_INVOLVED_GROUP_LIST_FAILURE:
    "[RESPONSE] Get Individual UserGroup Data Failed",

  CLEAR_LIST: "[REQUEST] Clear user List Data",

  GET_USER_CONFIGURATION_LIST: "[REQUEST] Get User Configuration List Data",
  GET_USER_CONFIGURATION_LIST_SUCCESS:
    "[RESPONSE] Get User Configuration List Data Successfull",
  GET_USER_CONFIGURATION_LIST_EMAIL_SUCCESS:
    "[RESPONSE] Get User Configuration List Email Successfull",
  GET_USER_CONFIGURATION_LIST_FAILURE:
    "[RESPONSE] Get User Configuration List Data Failed",

  UPDATE_USER_CONFIGURATION_LIST:
    "[REQUEST] Update User Configuration List Data",
  UPDATE_USER_CONFIGURATION_LIST_SUCCESS:
    "[RESPONSE] Update User Configuration List Data Successfull",
  UPDATE_USER_CONFIGURATION_LIST_FAILURE:
    "[RESPONSE] Update UserConfiguration List Data Failed",

  GET_USER_CONFIGURATION_DETAILS:
    "[REQUEST] Get User Configuration Details Data",
  GET_USER_CONFIGURATION_DETAILS_SUCCESS:
    "[RESPONSE] Get User Configuration Details Data Successfull",
  GET_USER_CONFIGURATION_DETAILS_FAILURE:
    "[RESPONSE] Get User Configuration Details  Data Failed",

  UPDATE_USER_ONBOARD: "[REQUEST] Update User Onboard Data",
  UPDATE_USER_ONBOARD_SUCCESS:
    "[RESPONSE] Update User Onboard Data Successfull",
  UPDATE_USER_ONBOARD_FAILURE: "[RESPONSE] Update User Onboard  Data Failed",

  UPDATE_USER_ONBOARD_STATUS: "[REQUEST] Update User Onboard Status Data",
  UPDATE_USER_ONBOARD_STATUS_SUCCESS:
    "[RESPONSE] Update User Onboard Status Data Successfull",
  UPDATE_USER_ONBOARD_STATUS_FAILURE:
    "[RESPONSE] Update User Onboard Status Data Failed",

  CLEAR_USER: "[CLEAR_CREDENTIAL][REQUEST] Clear User",

  GET_NON_PRIVILEGE_USER_LIST: "[REQUEST] Get Non Privelege List Data",
  GET_NON_PRIVILEGE_USER_LIST_SUCCESS:
    "[RESPONSE] Get Non Privelege List Data Successfull",
  GET_NON_PRIVILEGE_USER_LIST_EMAIL_SUCCESS:
    "[GET_NON_PRIVILEGE_USER_LIST_EMAIL_SUCCESS] Send Non Privelege List Data Successfull",
  GET_NON_PRIVILEGE_USER_LIST_FAILURE:
    "[RESPONSE] Get Non Privelege List  Data Failed",

  DELETE_NON_PRIVILEGE_USER_LIST: "[REQUEST]Delete Non Privelege List Data",
  DELETE_NON_PRIVILEGE_USER_LIST_SUCCESS:
    "[RESPONSE]Delete Non Privelege List Data Successfull",
  DELETE_NON_PRIVILEGE_USER_LIST_FAILURE:
    "[RESPONSE]Delete Non Privelege List  Data Failed",

  GET_NON_PRIVILEGE_USER_LIST_DETAILS:
    "[REQUEST] Get Non Privelege List Details Data",
  GET_NON_PRIVILEGE_USER_LIST_DETAILS_SUCCESS:
    "[RESPONSE] Get Non Privelege List Details Data Successfull",
  GET_NON_PRIVILEGE_USER_LIST_DETAILS_FAILURE:
    "[RESPONSE] Get Non Privelege List Details  Data Failed",

  POST_NON_PRIVILEGE_USER_LIST: "[REQUEST] Post Non Privelege Data",
  POST_NON_PRIVILEGE_USER_LIST_SUCCESS:
    "[RESPONSE] Post Non Privelege Data Successfull",
  POST_NON_PRIVILEGE_USER_LIST_FAILURE:
    "[RESPONSE] Post Non Privelege Data Failed",

  UPDATE_NON_PRIVILEGE_USER_LIST:
    "[REQUEST] Update Non Privelege User List Data",
  UPDATE_NON_PRIVILEGE_USER_LIST_SUCCESS:
    "[RESPONSE] Update Non Privelege User List Data Successfull",
  UPDATE_NON_PRIVILEGE_USER_LIST_FAILURE:
    "[RESPONSE] Update Non Privelege User List Data Failed",

  CONVERT_NON_PRIVILEGE_USER_LIST:
    "[REQUEST] Convert Non Privelege User List Data",
  CONVERT_NON_PRIVILEGE_USER_LIST_SUCCESS:
    "[RESPONSE] Convert Non Privelege User List Data Successfull",
  CONVERT_NON_PRIVILEGE_USER_LIST_FAILURE:
    "[RESPONSE] Convert Non Privelege User List Data Failed",

  POST_RECREATE_USERS: "[REQUEST] Post Recreate Users Data",
  POST_RECREATE_USERS_SUCCESS:
    "[RESPONSE] Post Recreate Users Data Successfull",
  POST_RECREATE_USERS_FAILURE: "[RESPONSE] Post Recreate Users Data Failed",

  GET_ASSET_ON_USER: "[REQUEST] Get Asset on user Data",
  GET_ASSET_ON_USER_SUCCESS: "[RESPONSE] Get Asset on user Data Successfull",
  GET_ASSET_ON_USER_FAILURE: "[RESPONSE] Get Asset on user  Data Failed",

  GET_SUPERVISOR_ON_ASSET: "[REQUEST] Get SUPERVISOR on_ASSET Data",
  GET_SUPERVISOR_ON_ASSET_SUCCESS:
    "[RESPONSE] Get SUPERVISOR on_ASSET Data Successfull",
  GET_SUPERVISOR_ON_ASSET_FAILURE:
    "[RESPONSE] Get SUPERVISOR on_ASSET Data Failed",

  CHECK_USER_INFO: "[REQUEST] Post Check User Info  Data",
  CHECK_USER_INFO_SUCCESS: "[RESPONSE] Post Check User Info  Data Successfull",
  CHECK_USER_INFO_FAILURE: "[RESPONSE] Post Check User Info  Data Failed",
  CLEAR_CHECK_USER_INFO: "[REQUEST] Clear Check User Info Data",
  CHECK_USER_INFO_PAGE: "CHECK USER INFO Page",

  GET_USER_BY_ROLE_LIST: "[REQUEST] Get User by role List Data",
  GET_USER_BY_ROLE_LIST_SUCCESS: "[RESPONSE] Get User by role List Successfull",
  GET_USER_BY_ROLE_LIST_EMAIL_SUCCESS:
    "[GET_USER_BY_ROLE_LIST_EMAIL_SUCCESS] Send User by role List Successfull",
  GET_USER_BY_ROLE_LIST_FAILURE: "[RESPONSE] Get User by role List Data Failed",
  CLEAR_USER_BY_ROLE_LIST: "[REQUEST] Clear User By Role ListData",

  GET_SYNC_PROCORE_USER:
    "[REQUEST GET_SYNC_PROCORE_USER] Get Sync Procore User",
  GET_SYNC_PROCORE_USER_SUCCESS:
    "[RESPONSE GET_SYNC_PROCORE_USER_SUCCESS] Get Sync Procore User Successfull",
  GET_SYNC_PROCORE_USER_FAILURE:
    "[RESPONSE  GET_SYNC_PROCORE_USER_FAILURE] Get Sync Procore User Failed",
  CLEAR_SYNC_PROCORE_USER: "[REQUEST] Clear Sync Procore User ListData",
};
