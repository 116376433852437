import { sequenceLandingType } from "../constants/sequenceLandingType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "projectPermit",
  isError: false,
  isLoading: false,
};

export const sequenceLandingProjectpermitListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_PROJECT_PERMIT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_PROJECT_PERMIT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_PROJECT_PERMIT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const sequenceLandingPermitDetailReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_PROJECT_PERMIT_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// inspection

export const qrSequenceLandingInspectionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_INSPECTION_REPORT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_INSPECTION_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_INSPECTION_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const qrSequenceLandingInspectionTypeFieldReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_INSPECTION_TYPE_FIELD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const qrSequenceLandingInspectionDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_INSPECTION_REPORT_VIEW_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// Resource

export const qrSequenceLandingResourecListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_RESOURCE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_RESOURCE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_RESOURCE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const sequenceLandingSamplingLogsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case sequenceLandingType.GET_QR_SAMPLING_LOG_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case sequenceLandingType.GET_QR_SAMPLING_LOG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case sequenceLandingType.GET_QR_SAMPLING_LOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
