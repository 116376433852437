import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";

export const getProjectListService = async ({ data }) => {
  const {
    pageSize,
    page,
    divisionId = 0,
    search,
    userId,
    organisationId,
    status = "",
    isSendMail,
  } = data;
  const url = `${getApiUrl().adminApiUrl}/Project/GetProjectsList?${
    organisationId ? `organisationId=${organisationId}` : ""
  }&divisionId=${divisionId}&isSendMail=${isSendMail}&status=${status}${
    search ? `&search=${search}` : ""
  }${page ? `&page=${page}` : ""}${pageSize ? `&pageSize=${pageSize}` : ""}${
    userId || userId === 0 ? `&userId=${userId}` : ""
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: organisationId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postAddProjectService = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Project/AddProject`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getProjectDetails = async ({ data }) => {
  const { projectId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Project/GetProjectDetails?projectId=${projectId}&orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const postUpdateProjectService = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Project/UpdateProject`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteProjectService = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Project/DeleteProject/${
    payload ? payload : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getProcreProjectsListService = async ({ data }) => {
  const { orgId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Procore/GetProcoreCompanyProject?orgId=${orgId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSyncProcreProjectsListService = async ({ data }) => {
  const { projectId, procoreProjectId, orgId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Procore/SyncProcoreCompanyProject?orgId=${orgId}&projectId=${projectId}&procoreProjectId=${procoreProjectId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
