import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_ORG_ID, ORG_ID } from "../../../constant/constant";
import * as assetsAction from "../../../redux/actions/assetsAction";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ACCESS_DENIED, ADMIN_ASSETS } from "../../../constant/routeContant";
import AddAsset from "../component/assets/addAsset";
import Loader from "../../../library/common/Loader";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as userAction from "../../../redux/actions/userAction";
import { useLocation, useNavigate } from "react-router-dom";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import ReactGA from "react-ga4";
export default function AddAssetsContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const assetData = useSelector((state) => state.postAddAssest);
  const assetDetails = useSelector((state) => state.getAssetDetails.isLoading);
  const allUsers = useSelector((state) => state.allUsers);
  const allGroups = useSelector((state) => state.searchAllGroup);
  const status = assetData.status;
  const updateAsset = useSelector((state) => state.updateAsset);
  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue
  );
  const isLoading =
    assetData?.isLoading ||
    updateAsset.isLoading ||
    assetDetails ||
    getDropdownDataKeyValue.isLoading;
  const isAutoSearchDataLoading = allUsers?.isLoading || allGroups?.isLoading;
  const [isSuccess, setIsSuccess] = useState(false);
  const updateAssetStatus = useSelector((state) => state.updateAsset.status);
  useEffect(() => {
    setIsSuccess(false);
    dispatch(assetsAction.clearAsset());
  }, []);
  const onSubmit = (data) => {
    setIsSuccess(true);
    if (editMode) {
      dispatch(assetsAction.updateAsset(data));
    } else dispatch(assetsAction.postAddAssest(data));
  };
  const successScreen = (id, message, message1) => {
    ReactGA.event({
      category: "Post API call",
      action: "Add Asset",
      label: "API CALL",
    });
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode ? "Asset Updated Successfully" : "Asset Created Successfully"
        }
        route={ADMIN_ASSETS}
        label1=" "
        label4="Assets in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const assetCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.assetTypes
  );

  const phone = localStorage.getItem("mobile");
  useEffect(() => {
    dispatch(userAction.getUserDetails(phone));
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;
  const [isUserData, setIsUserData] = useState([]);

  useEffect(() => {
    setIsUserData(allUsers?.data);
  }, [allUsers?.data]);
  function handleAllUserSearch(
    event,
    type,
    divisionId,
    assetId,
    assetType,
    priviledgeId
  ) {
    setIsUserData([]);
    dispatch(
      userAction.searchAllUsers(
        event.target.value,
        type,
        divisionId,
        assetId,
        assetType,
        priviledgeId
      )
    );
  }
  function handleAllGroupSearch(event, divisionId) {
    dispatch(userAction.searchAllGroup(event.target.value, divisionId));
  }
  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [6, 13, 17],
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  const assetId = location?.state?.id;
  const editMode = location?.state?.editMode;

  const assetDetailsData = useSelector((state) => state?.getAssetDetails?.data);
  useEffect(() => {
    setIsSuccess(false);
    if (assetId) {
      dispatch(assetsAction.getAssetDetails(assetId));
    }
  }, []);
  const handleonfcs = () => {
    setIsUserData([]);
  };
  return (
    <>
      {checkPrivileges([12, 37, 38]) ? (
        <>
          {isLoading && <Loader />}
          {status === "SUCCESS" && assetData && isSuccess ? (
            successScreen(assetData.data?.id, "Asset Created Successfully")
          ) : updateAssetStatus === "SUCCESS" && updateAsset && isSuccess ? (
            successScreen(
              updateAsset?.data.id,
              "Asset Updated Successfully",
              "has been Updated"
            )
          ) : isLoading ? (
            <Loader />
          ) : (
            <AddAsset
              onSubmit={onSubmit}
              division={divisionList}
              assetCategory={assetCategory}
              userGroups={allGroups?.data?.groupList}
              // allUsers={allUsers?.data}
              allUsers={isUserData}
              handleAllUserSearch={handleAllUserSearch}
              editMode={editMode}
              assetDetailsData={editMode ? assetDetailsData : ""}
              isLoading={isAutoSearchDataLoading}
              assetId={assetId ? assetId : ""}
              handleAllGroupSearch={handleAllGroupSearch}
              handleonfcs={handleonfcs}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
