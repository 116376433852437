import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  DASHBOARD,
  SWDASHBOARD,
  ONBOARDING,
  LANDING_PAGE,
  QR_LANDING_PAGE,
  QR_LANDING_PAGE_INSPECTION_REPORT,
  QR_LANDING_PAGE_RESOURCES,
  QR_LANDING_PAGE_PERMITS,
  QR_LANDING_PAGE_SAMPLINGLOGS,
  QR_LANDING_PAGE_PERMITS_DETAIL,
  QR_LANDING_PAGE_INSPECTION_VIEW,
} from "../constant/routeContant";
import { useNavigate } from "react-router-dom";
import * as loginActions from "../redux/actions/loginActions";
// for public
const PublicRoutes = () => {
  const token = localStorage.getItem("id_token");
  const isAuth = useSelector((state) => state.login.userSuccess);
  const loginData = useSelector((state) => state.login);
  const isOnboarded = useSelector((state) => state.login.isOnboarded || false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //list of specific routes to skip authentication
  const specificRoutes = [
    QR_LANDING_PAGE,
    QR_LANDING_PAGE_INSPECTION_REPORT,
    QR_LANDING_PAGE_RESOURCES,
    QR_LANDING_PAGE_PERMITS,
    QR_LANDING_PAGE_SAMPLINGLOGS,
    QR_LANDING_PAGE_PERMITS_DETAIL,
    QR_LANDING_PAGE_INSPECTION_VIEW,
  ];

  // if the current route matches any of the specific routes
  const isSpecificRoute = specificRoutes.includes(location.pathname);

  useEffect(() => {
    if (!isSpecificRoute && loginData?.url?.pathName && data == undefined) {
      dispatch(loginActions.setRiderctUrls(""));
    }
  }, [loginData, isSpecificRoute, dispatch, data]);

  const AUTH_PUBLIC_ROUTES = [
    "/signup",
    "/verify-mobile",
    "/reset-password",
    "/change-password",
    "/terms-of-conditions",
    "/",
  ];

  const clearLocalStorage = () => {
    const keysToClear = [
      "mobile",
      "access_token",
      "id_token",
      "app_secret_key",
    ];
    keysToClear.forEach((key) => localStorage.removeItem(key));
  };

  useEffect(() => {
    const isReloaded = localStorage.getItem("isReloaded");
    const isLoginViaOTP = location.pathname === "/loginViaOTP";
    if (
      !isOnboarded &&
      !isLoginViaOTP &&
      !isReloaded &&
      !AUTH_PUBLIC_ROUTES.includes(location.pathname)
    ) {
      clearLocalStorage();
      localStorage.setItem("isReloaded", "true");
      window.location.reload();
    } else if (isReloaded) {
      localStorage.removeItem("isReloaded");
      navigate("/login");
    }
  }, [isOnboarded, navigate, location.pathname]);

  if (isSpecificRoute) {
    return <Outlet />;
  }

  return !isAuth && !token ? (
    <Outlet />
  ) : isOnboarded ? (
    loginData?.url?.pathName ? (
      <Navigate to={loginData?.url?.pathName} replace={false} />
    ) : data?.length > 1 ? (
      <Navigate to={LANDING_PAGE} replace={true} />
    ) : data?.length === 1 && data?.includes(2) ? (
      <Navigate to={SWDASHBOARD} replace={true} />
    ) : (
      <Navigate to={DASHBOARD} replace={true} />
    )
  ) : location.pathname !== "/signup" ? (
    <Navigate to="/login" replace={true} />
  ) : (
    <Navigate to={ONBOARDING} replace={true} />
  );
};
export default PublicRoutes;
