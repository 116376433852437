import { getApiUrl } from "../config";
import { get, post } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const getLmsReport = async (data) => {
  const url = `${getApiUrl().reportApiUrl}/LmsReport/LmsReport`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLmsReportCourse = async (data) => {
  const url = `${getApiUrl().reportApiUrl}/LmsReport/GetCourse`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLmsReportLesson = async (data) => {
  const url = `${getApiUrl().reportApiUrl}/LmsReport/GetLesson`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLmsDashboardReport = async (data) => {
  const url = `${
    getApiUrl().reportApiUrl
  }/LmsReport/LMSDashboardReport?fromDate=${data.startDate}&toDate=${
    data.endDate
  }&divisionId=${data.divisionId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLmsDashboardReportDetails = async (data) => {
  const url = `${
    getApiUrl().reportApiUrl
  }/LmsReport/LMSDashboardReportDetails?fromDate=${data.startDate}&toDate=${
    data.endDate
  }&divisionId=${data.divisionId}&reportDetails=${data.reportDetails}&page=${
    data.page
  }&pageSize=${data.pageSize}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
