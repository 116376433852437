import React from "react";
import {
  ACCESS_DENIED,
  ADMIN_CONTRACTOR_CONFIGURATION_LIST,
  ADMIN_CONTRACTOR_LIST,
  ADMIN_CONTRACTOR_MAPPING,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import ContractorManagementList from "../component/contractorManagement/contractorManagementList";
import { checkPrivileges } from "../../../utils/rolesHelper";

const ContractorManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_CONTRACTOR_LIST);
    } else if (id === 2) {
      navigate(ADMIN_CONTRACTOR_CONFIGURATION_LIST);
    } else if (id === 3) {
      navigate(ADMIN_CONTRACTOR_MAPPING);
    }
  };

  return (
    <>
      {checkPrivileges([12, 43, 44, 45]) ? (
        <>
          <div className="dashTitle"> Contractor Management</div>
          <ContractorManagementList onClick={onClickHandler} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ContractorManagementContainer;
