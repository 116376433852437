import React from "react";
import "./reports.scss";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  SELECT_USER_TYPE,
} from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import {
  REPORTS_ANALYTICS_DASHBOARD,
  REPORTS_CREDENTIAL_LIST,
} from "../../../constant/routeContant";
import ReportCredentialListConatiner from "../containers/reportCredentialListConatiner";
import ReportCredentialList from "./reportCredentialList";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { getFormatedDate } from "../../../utils/helper";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import { Popover } from "@mui/material";
function ReportCredentialCount({
  NavigateToLookup,
  CredentialReportData,
  userGroups,
  getCredentialReports,
  getLookupCredentialReportData,
  getLookupCredentialReports,
  isLoading,
  PAGE_SIZES,
  handleAllGroupSearch,
  location,
}) {
  const [userType, setUserType] = useState(SELECT_USER_TYPE[0].key);
  const dataBack = {
    title: "Back",
    route: location?.state?.apiData?.tab ? REPORTS_ANALYTICS_DASHBOARD : -1,
    state: {
      apiData: location?.state?.apiData,
      backpage: "backpage",
      tab: location?.state?.apiData?.tab,
    },
  };

  const navigate = useNavigate();
  const [credentialTabId, SetcredentialTabId] = useState("");
  const [names, Setnames] = useState("");
  const [groupId, SetgroupId] = useState("");
  const [showList, setShowList] = useState(false);
  const [group, setgroup] = useState(0);
  const [groupName, setgroupName] = useState("");

  useEffect(() => {
    if (location?.state?.tabId)
      OnClickHnadler(
        group,
        location?.state?.tabId,
        location?.state?.name,
        "",
        "",
        location?.state?.divisionId ?? ""
      );
  }, [location]);

  const onChangeSelectUserType = (e) => {
    setgroup("");
    setgroupName("");
    const defaultData = {
      groupId: 0,
    };
    getCredentialReports(defaultData);
    setUserType(e.target.value);
  };

  const onChangeUsersGroup = (value) => {
    if (value) {
      const defaultData = {
        groupId: value.key,
      };
      getCredentialReports(defaultData);
      setgroup(value.key);
      setgroupName({
        key: value.key,
        value: value.value,
      });
    }
  };

  useEffect(() => {
    if (getLookupCredentialReportData.status === API_STATUS.SUCCESS) {
      setShowList(true);
    }
  }, [getLookupCredentialReportData]);

  const OnClickHnadler = (
    group,
    tabId,
    name,
    startDate,
    endDate,
    divisionId
  ) => {
    SetcredentialTabId(tabId);
    Setnames(name);
    SetgroupId(group);
    getLookupCredentialReports(
      group,
      tabId,
      startDate ? getFormatedDate(startDate) : "",
      endDate ? getFormatedDate(endDate) : "",
      divisionId === "AllDivision" ? 0 : divisionId ?? 0
    );
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Credential Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  console.log("deployed");
  return (
    <>
      <div>
        <BackBtn dataBack={dataBack} />
      </div>

      <div ref={componentRef} className="innerReportAndAnalyticsPdf">
        <section className="grid_main_sec">
          <Grid item md={12} className="clientPdfDetail">
            <Grid className="clientName">
              {isClient ? clientName : userInfo?.clientName}
            </Grid>
            <Grid className="clientLogo">
              {logoCheck && <img src={logoCheck} alt="client Log" />}
            </Grid>
          </Grid>
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style repotPdfHeading">
              Credential Reports
            </div>

            <div>
              <div className="filter_btn removeElement">
                <Grid ml={50} lg={6} md={6}>
                  <Buttons
                    primary={true}
                    id="btnAddClient"
                    label={"Credential Lookup"}
                    onClick={NavigateToLookup}
                  ></Buttons>
                </Grid>
              </div>
            </div>
            <Grid className="removeElement">
              <Buttons
                primary={true}
                id="btnAddClient"
                label={"Download Report"}
                onClick={handlePrint}
              ></Buttons>
            </Grid>
          </div>
        </section>

        <Grid container mt={6} className="removeElement">
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Select User Type" isRequired={false} />
              <SelectMenu
                placeholder="Please Select"
                listData={SELECT_USER_TYPE}
                value={userType}
                onchangehandler={(e) => onChangeSelectUserType(e)}
              />
            </FormControl>
          </Grid>
        </Grid>
        {userType == 2 && (
          <Grid container mt={2} className="removeElement">
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels label="Search Group" isRequired={false} />

                <SearchAutoComplete
                  name="Search User Group"
                  optionsData={isLoading ? [] : userGroups?.groupList ?? []}
                  typeOnchange={(event) =>
                    handleAllGroupSearch(event.target.value, 0, true)
                  }
                  isKeyValuePair={true}
                  value={groupName}
                  onChange={(e, value) => onChangeUsersGroup(value)}
                  isLoading={isLoading}
                  noOptionsTextMsg="No Groups"
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        <div className="reportBody">
          <div
            className="reportCardBody"
            onClick={() => {
              OnClickHnadler(
                group,
                "0",
                "Total Credential",
                "",
                "",
                location?.state?.divisionId ?? ""
              );
            }}
          >
            <div className="text">Total Credentials</div>
            <div className="number">
              {CredentialReportData?.data?.result?.totalCredentials}
            </div>
          </div>

          <div
            className="reportCardBody"
            onClick={() => {
              OnClickHnadler(
                group,
                "1",
                "Expiring Credentials",
                "",
                "",
                location?.state?.divisionId ?? ""
              );
            }}
          >
            <div className="text">Expiring Credentials</div>
            <div className="number">
              {CredentialReportData?.data?.result?.expiringCredentials}
            </div>
          </div>

          <div
            className="reportCardBody"
            onClick={() => {
              OnClickHnadler(
                group,
                "2",
                "Expired Credentials",
                "",
                "",
                location?.state?.divisionId ?? ""
              );
            }}
          >
            <div className="text">Expired Credentials</div>
            <div className="number">
              {CredentialReportData?.data?.result?.expiredCredentials}
            </div>
          </div>

          <div
            className="reportCardBody"
            onClick={() => {
              OnClickHnadler(
                group,
                "3",
                "Assigned Credentials",
                "",
                "",
                location?.state?.divisionId ?? ""
              );
            }}
          >
            <div className="text">Assigned Credentials</div>
            <div className="number">
              {CredentialReportData?.data?.result?.assignedCredentials}
            </div>
          </div>

          <div
            className="reportCardBody"
            onClick={() => {
              OnClickHnadler(
                group,
                "4",
                "Configured Credentials",
                "",
                "",
                location?.state?.divisionId ?? ""
              );
            }}
          >
            <div className="text">Configured Credentials</div>
            <div className="number">
              {CredentialReportData?.data?.result?.configuredCredentials}
            </div>
          </div>
          <div
            className="reportCardBody"
            onClick={() => {
              OnClickHnadler(
                group,
                "5",
                "Incomplete Credentials",
                "",
                "",
                location?.state?.divisionId ?? ""
              );
            }}
          >
            <div className="text">Incomplete Credentials</div>
            <div className="number">
              {CredentialReportData?.data?.result?.incompleteCredentials}
            </div>
          </div>
        </div>
        {showList && (
          <Grid>
            <ReportCredentialList
              getLookupCredentialReportData={getLookupCredentialReportData}
              getLookupCredentialReports={getLookupCredentialReports}
              // isLoading={isLoading}
              credentialTabId={credentialTabId}
              groupId={groupId}
              PAGE_SIZES={PAGE_SIZES}
              namess={names}
              location={location}
            />
          </Grid>
        )}
      </div>
    </>
  );
}

export default ReportCredentialCount;
