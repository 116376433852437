import React, { useEffect, useState } from "react";
import LmsClientMapping from "../component/lmsClientMapping/lmsClientMapping";
import * as clientAction from "../../../redux/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import * as lmsAction from "../../../redux/actions/lmsAction";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyError } from "../../../../src/library/custom/toastMessage/Toast";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { Navigate, useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";
const LmsClientMappingContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trainigtype, setTrainigtype] = useState(1);
  const [issaveclicklms, setissaveclicklms] = useState(false);
  const [issaveclickclient, setissaveclickclient] = useState(false);
  const [issubsuccess, setissubsuccess] = useState(false);
  const [issubsuccessclient, setissubsuccessclient] = useState(false);
  const [lessons, setlessons] = useState("");
  const [changesearchauto, setchangesearchauto] = useState({
    key: "",
    value: "",
  });
  const [clients, setClients] = useState("");
  // Final Grid Data
  const [FinalaData, setFinalaData] = useState([]);
  const [FinalaCourseData, setFinalaCourseData] = useState([]);
  const [FinalaDataClientSide, setFinalaDataClientSide] = useState([]);
  const [FinalaCourseDataClientSide, setFinalaCourseDataClientSide] = useState(
    []
  );

  const allClients = useSelector((state) => state?.searchAllClientsReducerData);
  const isSearchClientLoading = allClients?.isLoading;

  const searchLessonsData = useSelector(
    (state) => state?.searchLessons?.data?.users
  );
  const searchCoursesData = useSelector(
    (state) => state?.searchCourses?.data?.users
  );
  const MappedGlobalLessonListData = useSelector(
    (state) => state?.MappedGlobalLessonList ?? []
  );

  const MappedGlobalCourseListData = useSelector(
    (state) => state?.MappedGlobalCoursesList ?? []
  );

  // specify  tab 1 lesson Submit Api Final Data reducer
  const lmsviewLessonData = useSelector(
    (state) => state?.specifyLessontoUserViewClientData
  );

  // specify  tab 1 course Submit Api Final Data reducer
  const lmsviewCourseData = useSelector(
    (state) => state?.specifyCoursetoUserViewClientData
  );

  // specify  tab 2 lesson Submit Api Final Data reducer
  const lmsviewLessonClientData = useSelector(
    (state) => state?.specifyLessontoUserViewLessonData
  );

  // specify  tab 2 Course Submit Api Final Data reducer
  const specifyCoursetoUserViewCourseDataData = useSelector(
    (state) => state?.specifyCoursetoUserViewCourseData
  );

  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };

  const searchLessons = (searchText) => {
    dispatch(lmsAction.searchLessons(searchText));
  };
  const searchCourses = (searchText) => {
    dispatch(lmsAction.searchCourses(searchText));
  };

  const getMappedGlobalLessonListApi = (data) => {
    dispatch(lmsAction.getMappedGlobalLessonList(data));
  };

  const getMappedGlobalCourseListApi = (data) => {
    dispatch(lmsAction.getMappedGlobalCourseList(data));
  };

  function renameKey(arr, oldKey, newKey) {
    return arr?.map((obj) => {
      const { [oldKey]: oldVal, ...rest } = obj;
      return { [newKey]: oldVal, ...rest };
    });
  }
  useEffect(() => {
    dispatch(lmsAction.clearGetMappedGlobalLessonList(""));
    setFinalaData([]);
    setFinalaCourseData([]);
    setFinalaDataClientSide([]);
    setFinalaCourseDataClientSide([]);
  }, []);

  useEffect(() => {
    if (MappedGlobalLessonListData?.status === API_STATUS.SUCCESS) {
      //changesearchauto?.key
      const FinalUpdateData = renameKey(
        MappedGlobalLessonListData?.data?.globalLessonList,
        "clientOrgId",
        "clientId"
      );
      if (changesearchauto?.key) {
        setFinalaData(FinalUpdateData);
      } else {
        setFinalaDataClientSide(FinalUpdateData);
      }
    }
  }, [MappedGlobalLessonListData]);

  useEffect(() => {
    if (MappedGlobalCourseListData?.status === API_STATUS.SUCCESS) {
      const FinalUpdateData = renameKey(
        MappedGlobalCourseListData?.data?.globalCourseList,
        "clientOrgId",
        "clientId"
      );
      if (changesearchauto?.key) {
        setFinalaCourseData(FinalUpdateData);
      } else {
        setFinalaCourseDataClientSide(FinalUpdateData);
      }
    }
  }, [MappedGlobalCourseListData]);

  // Tab One Lesson/Course useeffect
  useEffect(() => {
    if (lmsviewLessonData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      lmsviewLessonData.status === API_STATUS.SUCCESS &&
      issaveclicklms &&
      trainigtype === 1
    ) {
      toast("Lesson Mapped Successfully");
      setissubsuccess(true);
      setTimeout(() => {
        setissubsuccess(false);
      }, 2000);
    }
    if (lmsviewCourseData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      lmsviewCourseData.status === API_STATUS.SUCCESS &&
      issaveclicklms &&
      trainigtype === 2
    ) {
      toast("Course Mapped Successfully");
      setissubsuccess(true);
      setTimeout(() => {
        setissubsuccess(false);
      }, 2000);
    }
  }, [lmsviewLessonData, lmsviewCourseData]);

  // Tab Two Client useeffect
  useEffect(() => {
    if (lmsviewLessonClientData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      (lmsviewLessonClientData.status === API_STATUS.SUCCESS &&
        issaveclickclient &&
        trainigtype === 1) ||
      (specifyCoursetoUserViewCourseDataData.status === API_STATUS.SUCCESS &&
        issaveclickclient &&
        trainigtype === 2)
    ) {
      toast("Client Mapped Successfully");
      setissubsuccessclient(true);
      setTimeout(() => {
        setissubsuccessclient(false);
      }, 2000);
    }
  }, [lmsviewLessonClientData, specifyCoursetoUserViewCourseDataData]);

  // final submit for client
  const clientLessonToUserView = async (data, clientId) => {
    setissaveclicklms(true);

    dispatch(lmsAction.specifyLessonToUserViewClient(data, clientId));
  };
  const clientCourseToUserView = async (data, clientId) => {
    setissaveclicklms(true);
    dispatch(lmsAction.specifyCourseToUserViewClient(data, clientId));
  };
  // Final Submit Function lms
  const specifyLessonToUserViewLesson = async (data, lessonId) => {
    setissaveclickclient(true);
    dispatch(lmsAction.specifyLessonToUserViewLesson(data, lessonId));
  };
  const specifyCourseToUserViewCourse = async (data, courseId) => {
    setissaveclickclient(true);
    dispatch(lmsAction.specifyCourseToUserViewCourse(data, courseId));
  };
  const showLoader = useSelector(
    (state) =>
      state?.specifyLessontoUserViewClientData?.isLoading ||
      state?.specifyCoursetoUserViewClientData?.isLoading ||
      state?.specifyLessontoUserViewLessonData?.isLoading ||
      state?.specifyLessontoUserViewLessonData?.isLoading ||
      state?.specifyCoursetoUserViewCourseData?.isLoading
  );

  return (
    <>
      {checkPrivileges([12]) ? (
        <>
          {showLoader && <Loader />}

          <LmsClientMapping
            searchClient={searchClient}
            allClients={allClients}
            isSearchClientLoading={isSearchClientLoading}
            changesearchauto={changesearchauto}
            setchangesearchauto={setchangesearchauto}
            setFinalaData={setFinalaData}
            FinalaData={FinalaData}
            FinalaCourseData={FinalaCourseData}
            setFinalaCourseData={setFinalaCourseData}
            searchLessons={searchLessons}
            searchLessonsData={searchLessonsData}
            searchCourses={searchCourses}
            searchCoursesData={searchCoursesData}
            getMappedGlobalLessonListApi={getMappedGlobalLessonListApi}
            getMappedGlobalCourseListApi={getMappedGlobalCourseListApi}
            MappedGlobalLessonListData={MappedGlobalLessonListData}
            MappedGlobalCourseListData={MappedGlobalCourseListData}
            lessonMappingsubmit={specifyLessonToUserViewLesson}
            courseMappingCoursesubmit={specifyCourseToUserViewCourse}
            clientLessonToUserViewsubmit={clientLessonToUserView}
            clientCourseToUserViewsubmit={clientCourseToUserView}
            issubsuccess={issubsuccess}
            issubsuccessclient={issubsuccessclient}
            setClients={setClients}
            clients={clients}
            setlessons={setlessons}
            lessons={lessons}
            issaveclicklms={issaveclicklms}
            issaveclickclient={issaveclickclient}
            setTrainigtype={setTrainigtype}
            trainigtype={trainigtype}
            FinalaDataClientSide={FinalaDataClientSide}
            FinalaCourseDataClientSide={FinalaCourseDataClientSide}
            setFinalaCourseDataClientSide={setFinalaCourseDataClientSide}
            setFinalaDataClientSide={setFinalaDataClientSide}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default LmsClientMappingContainer;
