import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResetPasswordImage from "../../assets/otpPageImage.png";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import { maxLengthCheck } from "../../utils/helper";
import "../reset-password/reset-password.scss";
import Loader from "../../library/common/Loader";
import { logos } from "../../assets";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../constant/routeContant";
import * as loginActions from "../../redux/actions/loginActions";
import { useSelector } from "react-redux";
import { getLoginOTP } from "../../services/loginService";
import { useThemeContext } from "../../context";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Grid } from "@mui/material";
import { DARK } from "../../constant/constant";
const LoginViaOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const { state } = location;
  const [mobile, setmobile] = useState(state?.mobile);
  const [Session, setSession] = useState(state?.Session);

  const loginData = useSelector((state) => state.login);
  const INITIAL_COUNT = 30;
  const twoDigits = (num) => String(num).padStart(2, "0");
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  /* Initially everything is invalid */

  useEffect(() => {
    if (!loginData?.isLoading && loginData?.error?.Session) {
      setSession(loginData?.error?.Session);
      setErrorMsg("Invalid One Time Password");
    }
  }, [loginData]);

  const handleStart = async () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
  };

  const STATUS = {
    STARTED: "Started",
    STOPPED: (
      <b>
        <a type="button" onClick={handleStart} className="show-danger">
          Resend code
        </a>
      </b>
    ),
  };

  const [status, setStatus] = useState(STATUS.STOPPED);

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // set up the interval
    useEffect(() => {
      function tick() {
        savedCallback?.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const resendCode = async () => {
    // alert(mobile)
    const data = mobile;
    try {
      const result = await getLoginOTP({ data });
      if (result) {
        setMsg("One Time Password Successfully Sent!");
        setSession(result?.data?.Session);
      }
    } catch (err) {
      setErrorMsg("One Time Password Sent Failed!!");
    }
  };

  let defaultErrorStates = {
    otp: true,
  };

  /*
Dynamically update the
form whenever user inputs stuff
*/
  const [errorList, setErrorList] = useState(defaultErrorStates);

  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();
    setMsg("");
    if (mobile.length < 6) {
      setErrorList({
        ...errorList,
        otp: true,
      });
      return;
    }
    dispatch(loginActions.login(mobile, otp, Session));
  };

  const otpFilter = (event, setter) => {
    setErrorMsg("");
    setter(event.target.value.replace(/[^\d]+$/g, ""));
  };

  function goToSignUp(params) {
    navigate("/signup");
  }
  function gotToLogin(params) {
    navigate(LOGIN);
  }

  const { mode, toggleTheme } = useThemeContext();
  useEffect(() => {
    document.body.className = mode === "light" ? "light-mode" : "dark-mode";
  }, [mode]);
  return (
    <div className="reset-password">
      {loginData?.isLoading && <Loader />}
      <Grid item md={1} xs={1} sm={1} className="themeToggle">
        {
          <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
            {mode === DARK ? (
              <Brightness7Icon className="iconWhite" />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        }
      </Grid>
      <div className="reset-password-container">
        {/* left */}
        <div className="reset-password-left">
          <Grid className="logo">
            {mode === DARK ? (
              <img
                src={logos?.logoWhite}
                width="180px"
                className="logo"
                alt="no logo"
              />
            ) : (
              <img
                src={logos?.logo}
                width="180px"
                className="logo"
                alt="no logo"
              />
            )}
          </Grid>
          <h2 className="reset-password-title">OTP Verification</h2>
          <form onSubmit={submitButton}>
            <div
              className={[
                "form-wrapper",
                errorList.password ? null : "has-error",
              ].join(" ")}
            >
              <div className="password-container">
                <p>
                  Enter the 6 digit Verification Code sent to your Email
                </p>
                <input
                  className={[
                    errorList.confirmPassword
                      ? "input-control"
                      : "input-control border-red",
                  ]}
                  type="text"
                  value={otp}
                  maxLength="6"
                  inputMode="numeric"
                  pattern="[0-9]"
                  onInput={maxLengthCheck}
                  onChange={(e) => otpFilter(e, setOtp)}
                />
                {errorList.otp ? null : (
                  <div className="show-error">
                    This field cannot be left blank
                  </div>
                )}
              </div>
              {status === STATUS.STARTED ? (
                <div className="forgot-password">
                  <b style={{ color: "#666666" }}>Resend code</b>
                  <b style={{ color: "#666666", marginLeft: "2px" }}>
                    {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
                  </b>
                </div>
              ) : (
                <h2 onClick={resendCode} className="forgot-password">
                  {status}{" "}
                </h2>
              )}
            </div>

            <div className="login-button">
              {!loading ? (
                <Button
                  label="Verify Code and Login"
                  onButtonClickHandler={submitButton}
                />
              ) : (
                <Loader />
              )}

              {msg && <p className="success">{msg}</p>}

              {errorMsg && <p className="show-error">{errorMsg}</p>}
            </div>
            <p className="signup-txt">
              Not a Sequence User?{" "}
              <span onClick={() => goToSignUp()}>Sign up Now</span>
              <br /> or{" "}
              <span onClick={() => gotToLogin()}>{`Login via Password`}</span>
            </p>
          </form>
        </div>
        <div className="reset-password-right">
          <img src={ResetPasswordImage} alt="welcome" />
        </div>
      </div>
      <Footer fixed="fixed" mode={mode} />
    </div>
  );
};

export default LoginViaOtp;
