import React, { useEffect, useState } from "react";
import "./createNews.scss";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Button from "../../../../library/custom/button/Button";
import { formValidation } from "../../../../utils/incpectionHelper";
import { useNavigate } from "react-router-dom";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import Checkbox from "../../../../library/checkbox/checkbox";
import { ORG_ID, HBNEXT_ORG_ID } from "../../../../constant/constant";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
const CreateNews = ({ editNewsData, onSubmit, editMode, newsId }) => {
  const [addPhoto, setAddPhoto] = useState("");
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [dropboxError, setDropboxError] = useState(false);
  const [checkBox, setcheckBox] = useState(false);

  const [formValue, setFormValue] = useState({
    title: "",
    description: "",
    imageUrl: "",
    fileId: "",
  });
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].key);
    }
  }, [serviceTypes]);
  const selectedServiceTypeData = localStorage?.getItem("serviceType");

  useEffect(() => {
    if (editNewsData?.data && editMode) {
      setAddPhoto(
        editNewsData?.data?.news?.file?.path
          ? editNewsData?.data?.news?.file?.path
          : ""
      );
      setcheckBox(editNewsData?.data?.news?.isGlobal);
      setFormValue({
        description: editNewsData?.data?.news?.description,
        title: editNewsData?.data?.news?.title,
        fileId: editNewsData?.data?.news?.file?.id
          ? editNewsData?.data?.news?.file?.id
          : "",
      });
    }
  }, [editNewsData?.data]);
  const history = useNavigate();
  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };
  const formHandler = (event, action) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    const formData = {
      title: formValue?.title ?? null,
      description: formValue?.description ?? null,
      fileId: formValue?.fileId ? formValue?.fileId : "",
      newsId: newsId ? newsId : "",
      productIds: selectedServiceType ? selectedServiceType : [serviceType],
      isGlobal: checkBox,
    };

    const { errorKey } = formValidation(
      {
        title: formValue.title,
        fileId: formValue?.fileId,
      },
      action
    );
    setErrors(errorKey);
    let isError = false;
    if (!formValue.title) {
      isError = true;
    }

    if (formValue?.fileId == undefined) {
      setDropboxError(true);
      isError = true;
    }

    if (!isError) {
      setIsSubmit(true);

      onSubmit(formData);
    } else {
      setIsSubmit(false);
    }
  };

  const handleTitleChange = (event, value) => {
    let fName = event.target.value;
    let fNameWhiteSpace = fName.replace(/\s+/g, " ");

    setFormValue({
      ...formValue,

      title: fNameWhiteSpace,
    });
  };

  const handleDescChange = (event, value) => {
    let Desc = event.target.value;
    setFormValue({
      ...formValue,

      description: Desc,
    });
  };

  useEffect(() => {
    setFormValue({
      ...formValue,
      fileId: images[0],
    });
  }, [images]);

  const handleDeleteImageURL = (urls) => {
    setImages([]);
    setAddPhoto("");
  };
  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = serviceTypes?.find(
        (x) => x.key == selectedServiceTypeData
      );

      setServiceType(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);
  return (
    <>
      <div className="overideNews">
        <div className="formContainer">
          {editNewsData ? (
            <div className="heading_style">
              <h1>Update News</h1>
            </div>
          ) : (
            <div className="heading_style">
              <h1>Add News</h1>
            </div>
          )}

          <div className="addnewsContainer">
            <div className="addnews">
              {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                <Grid container>
                  <Grid item lg={6} xs={12} sm={12} md={4} mt={2}>
                    <div className="typeTxt">
                      <Checkbox
                        checked={checkBox}
                        onChange={() => setcheckBox(!checkBox)}
                        label={"Is Global"}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}

              <Grid item container mt={4} lg={12}>
                <Grid container mt={1.5} lg={12} xs={12}>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={true}
                      fullWidth={true}
                      disabled={false}
                      placeholder="Enter title text here..."
                      type="text"
                      label="Headline"
                      value={formValue.title}
                      onChange={(event, value) =>
                        handleTitleChange(event, value)
                      }
                      isError={!!errors?.title ?? false}
                      errorMsg={errors?.title ?? ""}
                    />
                  </FormControl>
                </Grid>
                <Grid mt={3} spacing={2} lg={12} xs={12}>
                  <FormLabels label="Add Cover Image" isRequired={true} />
                  <FormDropZone
                    //label={"Add Cover Image"}
                    paraText1={"Drop the file here ..."}
                    paraText2={"Drop image here, "}
                    paraText3={"or Browse"}
                    // handleFileChange={(files, uploadfile) => {
                    //   setImages(...images, uploadfile);
                    //   setAddPhoto(...addPhoto, files);
                    // }}

                    handleFileChange={(files, uploadfile) => {
                      setAddPhoto(files);
                      setImages(uploadfile);
                    }}
                    isDynamics={true}
                    fileType={10}
                    imageUrl={addPhoto}
                    deleteImageURL={(url) => handleDeleteImageURL(url)}
                    id="addPhoto"
                    key="addPhoto"
                    isDoc={false}
                    isImage={true}
                    allFiles={false}
                    isMuliple={false}
                  />
                  <p class="fileTypeSupport">Supported file types: images </p>
                  {!addPhoto && dropboxError && (
                    <div className="errorMsg">Add Cover Image is Required</div>
                  )}
                </Grid>
                <Grid mt={3} spacing={2} lg={12} xs={12} md={12}>
                  <FormLabels label={"News Description"} />

                  <TextArea
                    name={"issuefound"}
                    placeholder="Add text Enter here..."
                    value={formValue.description}
                    onChange={(event, value) => handleDescChange(event, value)}
                  />
                </Grid>

                <Grid mt={0} item container spacing={2}>
                  {serviceTypes?.length > 1 && (
                    <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                      <FormControl fullWidth>
                        <FormLabels label="Service Type" isRequired={true} />

                        <MultiSelectBox
                          placeholder={"Please Select"}
                          listData={serviceTypes}
                          onChange={(e) => onChangeServiceType(e)}
                          value={serviceType || []}
                          isError={serviceTypeError}
                          errorMessage="Selection is required for this field"
                          disableSelect={true}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {serviceTypes?.length === 1 && (
                    <Grid item lg={4} xs={12} sm={12} md={6} pl={2}>
                      <FormControl fullWidth>
                        <FormLabels label="Service Type" isRequired={true} />
                        {serviceTypes?.map((value, index) => {
                          return (
                            <div className="serviveTypeStyle" key={value?.key}>
                              {" "}
                              {value?.value}
                            </div>
                          );
                        })}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>

                <Grid container className="newsbutton">
                  <Grid item lg={6} xs={12} sm={12} md={4} mt={2}>
                    <div className="addnews_btn">
                      <div
                        className="cancle_heading_style"
                        onClick={() => history(-1)}
                      >
                        Cancel
                      </div>

                      <Button
                        varientContained={true}
                        disabled={isSubmit ? true : false}
                        label={editNewsData ? "Update" : "publish"}
                        onClick={(e) =>
                          formHandler(e, editNewsData ? "update" : "submit")
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateNews;
