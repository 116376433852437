import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import ContractorForm from "../component/contractor/contractorForm";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ADMIN_CONTRACTOR_LIST,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import {
  notifyError,
  notifyErrorContractor,
} from "../../../../src/library/custom/toastMessage/Toast";

import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import DownloadCsv from "../../download_file/DownloadCsv";
import { useRef } from "react";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const ContractorFormContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dowloadCsv = useRef();
  const navigate = useNavigate();

  const createContractordatas = useSelector(
    (state) => state.createContractordata
  );
  const viewContractors = useSelector((state) => state.viewContractor);
  const updateContractor = useSelector((state) => state.updateContractor);
  const stateData = useSelector((state) => state.state.data);
  const cityData = useSelector((state) => state.city);

  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const contractorGrades = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorGrades
  );
  const contractorTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorTypes
  );
  const showLoader = useSelector(
    (state) =>
      state.state.isLoading ||
      state.getDropdownDataKeyValue.isLoading ||
      state.createContractordata.isLoading ||
      viewContractors.isLoading ||
      state.updateContractor.isLoading ||
      state.city.isLoading
  );

  const contractorId = location?.state?.contractorId;
  const editMode = location?.state?.editMode;

  const userData = useSelector((state) => state.getUserListDetsilsData);
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "3,6,2",
    productId: PRODUCT_ID(),
  };

  const [successScreens, setSuccessScreens] = useState(false);
  const [setsubmit, setSetsubmit] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isSingle, setIsSingle] = useState(false);

  const ismultipleUpload = (isBulkUpload) => {
    setIsSingle(isBulkUpload);
  };
  const [createContractorList, setcreateContractorList] = useState([]);
  const createContractors = (data) => {
    if (editMode) {
      setSetsubmit(true);
      dispatch(contractorAction.updateContractor(data));
      setSuccessScreens(true);
    } else {
      setSetsubmit(true);
      dispatch(contractorAction.createContractor(data));
      setcreateContractorList(data);
    }
  };

  const [duplicateContractor, setDuplicateContractor] = useState("");

  useEffect(() => {
    if (createContractordatas?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (
        createContractordatas?.data?.userStatusResponses?.length <= 1 &&
        !isSingle
      ) {
        if (
          createContractordatas?.data?.userStatusResponses?.[0]?.status ===
            false &&
          setsubmit
        ) {
          notifyErrorContractor();
        }
        if (
          createContractordatas?.data?.userStatusResponses?.[0]?.status ===
            true &&
          setsubmit
        ) {
          setSuccessScreens(true);
        }
      } else if (
        createContractordatas?.data?.userStatusResponses?.length >= 1 &&
        isSingle &&
        setsubmit
      ) {
        setIsDownload(true);
        setTimeout(() => {
          setSuccessScreens(true);
        }, 1000);
      }
    }
  }, [createContractordatas]);

  useEffect(() => {
    if (createContractordatas?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (createContractordatas?.status === API_STATUS.SUCCESS) {
        const duplicateContractorList =
          createContractordatas?.data?.userStatusResponses?.filter(
            (user) => !user.status
          );
        if (duplicateContractorList?.length >= 1 && isSingle) {
          setDuplicateContractor(duplicateContractorList);
        }
      }
    }
  }, [createContractordatas]);

  useEffect(() => {
    if (duplicateContractor && duplicateContractor?.length >= 1) {
      if (isDownload) {
        dowloadCsv?.current?.link?.click();
      }
    }
  }, [duplicateContractor]);

  const getContactDetail = () => {
    dispatch(contractorAction.getContractorDetails(contractorId));
  };

  const getCity = (stateId) => {
    dispatch(stateCityAction.getCity(stateId));
  };

  useEffect(() => {
    dispatch(contractorAction.clearContractor());
    setSuccessScreens(false);
    dispatch(stateCityAction.getState());
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
    if (contractorId) {
      getContactDetail();
    }
  }, []);

  const SuccessScree = (observationid, message1) => {
    setTimeout(() => {
      setcreateContractorList([]);
    }, 7000);
    return (
      <SuccessScreen
        isUpdate={
          editMode
            ? "Contractor Updated Successfully"
            : duplicateContractor?.length <
              createContractorList?.contractors?.length
            ? "Contractor Created Successfully"
            : "Contractor Already Exists"
        }
        route={ADMIN_CONTRACTOR_LIST}
        label1="Contractor ID"
        label4="Contractor in "
        id={observationid}
        label2={message1}
      ></SuccessScreen>
    );
  };

  return (
    <>
      {checkPrivileges([12, 43, 44]) ? (
        <>
          {(createContractordatas.status === "SUCCESS" &&
            createContractordatas?.data &&
            createContractordatas?.data?.userStatusResponses?.[0]?.status ===
              true) ||
          (createContractordatas?.data?.userStatusResponses?.length >= 1 &&
            successScreens) ? (
            SuccessScree()
          ) : updateContractor.status === "SUCCESS" &&
            updateContractor?.data &&
            successScreens ? (
            SuccessScree()
          ) : (
            <>
              {showLoader && <Loader />}
              <ToastContainer />
              <ContractorForm
                addContractor={createContractors}
                divisions={division ?? []}
                contractorGrades={contractorGrades ?? []}
                contractorTypes={contractorTypes ?? []}
                stateData={stateData?.getState ?? []}
                userData={userData?.data}
                editMode={editMode}
                contractorId={contractorId}
                viewContractors={editMode ? viewContractors : ""}
                city={cityData && cityData.data?.cities}
                getCity={getCity}
                ismultipleUpload={ismultipleUpload}
              />
              <DownloadCsv
                ref={dowloadCsv}
                data={duplicateContractor}
                filename={"Already Exit Contractor List"}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ContractorFormContainer;
