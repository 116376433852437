import React from "react";

function ReportUtilities() {
  return (
    <div>
      <h1 className="comingSoon" style={{ fontSize: 40 }}>
        Coming Soon...
      </h1>
    </div>
  );
}

export default ReportUtilities;
