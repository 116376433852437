import { lmsUserType } from "../constants/lmsUserType";

export const getUserCoursesList = (data) => ({
  type: lmsUserType.GET_USER_COURSES_LIST_PENDING,
  data,
});

export const getUserCoursesListSuccess = (data) => ({
  type: lmsUserType.GET_USER_COURSES_LIST_SUCCESS,
  data,
});

export const getUserCoursesListEmailSuccess = () => ({
  type: lmsUserType.GET_USER_COURSES_LIST_EMAIL_SUCCESS,
});
export const getUserCoursesListFailure = (error) => ({
  type: lmsUserType.GET_USER_COURSES_LIST_FAILURE,
  error,
});
export const clearCourseList = (data) => ({
  type: lmsUserType.CLEAR_COURSELIST,
  data,
});

//CLEAR_COURSELIST

export const getUserLessonsList = (data) => ({
  type: lmsUserType.GET_USER_LESSONS_LIST_PENDING,
  data,
});

export const getUserLessonsListSuccess = (data) => ({
  type: lmsUserType.GET_USER_LESSONS_LIST_SUCCESS,
  data,
});
export const getUserLessonsListEmailSuccess = () => ({
  type: lmsUserType.GET_USER_LESSONS_LIST_EMAIL_SUCCESS,
});

export const getUserLessonsListFailure = (error) => ({
  type: lmsUserType.GET_USER_LESSONS_LIST_FAILURE,
  error,
});
export const clearLesssonList = () => ({
  type: lmsUserType.CLEAR_LESSONLIST,
  data: "",
});

export const getUserCoursesView = (userId, courseId) => ({
  type: lmsUserType.GET_USER_COURSE_VIEW,
  userId,
  courseId,
});

export const getUserCoursesViewSuccess = (data) => ({
  type: lmsUserType.GET_USER_COURSE_VIEW_SUCCESS,
  data,
});

export const getUserCoursesViewFailure = (error) => ({
  type: lmsUserType.GET_USER_COURSE_VIEW_FAILURE,
  error,
});

export const getUserLessonView = (lessonId, courseId) => ({
  type: lmsUserType.GET_USER_LESSON_VIEW,
  lessonId,
  courseId,
});

export const getUserLessonViewSuccess = (data) => ({
  type: lmsUserType.GET_USER_LESSON_VIEW_SUCCESS,
  data,
});

export const getUserLessonViewFailure = (error) => ({
  type: lmsUserType.GET_USER_LESSON_VIEW_FAILURE,
  error,
});

export const startCourse = (data) => ({
  type: lmsUserType.POST_START_COURSES_PENDING,
  data,
});

export const startCourseSuccess = (data) => ({
  type: lmsUserType.POST_START_COURSES_SUCCESS,
  data,
});

export const startCourseFailure = (error) => ({
  type: lmsUserType.POST_START_COURSES_FAILURE,
  error,
});

export const startLesson = (data) => ({
  type: lmsUserType.POST_START_LESSON_PENDING,
  data,
});

export const startLessonSuccess = (data) => ({
  type: lmsUserType.POST_START_LESSON_SUCCESS,
  data,
});

export const startLessonFailure = (error) => ({
  type: lmsUserType.POST_START_LESSON_FAILURE,
  error,
});
