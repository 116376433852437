import React, { useEffect } from "react";
import Lms from "../component/lms";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { ORG_ID } from "../../../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import * as lmsReportAction from "../../../../redux/actions/lmsReportAction";
import Loader from "../../../../library/common/Loader";
import { useLocation } from "react-router-dom";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import * as lmsUserAction from "../../../../redux/actions/lmsUserAction";
import * as toolboxAction from "../../../../redux/actions/toolBoxAction";

const LmsContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const divisions = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const backDetail = location?.state;

  useEffect(() => {
    handleClear();
  }, []);

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "6",
    productId: PRODUCT_ID(),
  };
  function getDivisionData(params) {
    dispatch(adminAction.getDropdownDataKeyValue(params));
  }
  useEffect(() => {
    getDivisionData(getDropdownDataKeyValueData);
  }, []);

  const getLMSDashboardReport = useSelector(
    (state) => state.getLmsDashboardReport
  );
  const getLmsDashboardReportData = (data) => {
    dispatch(lmsReportAction.getLmsDashboardReport(data));
  };
  const isLoading = useSelector(
    (state) => state.getLmsDashboardReport?.isLoading
  );

  function handleClear() {
    dispatch(lmsUserAction.clearCourseList());
    dispatch(lmsUserAction.clearLesssonList());
    dispatch(lmsAction.getAssignedLessonListClear());
    dispatch(lmsAction.getAssignedListClear());
    dispatch(toolboxAction.scheduleUserCountStatusClear());
    dispatch(lmsReportAction.clearDashboardDetails());
  }
  return (
    <>
      {isLoading && <Loader />}
      <Lms
        divisions={divisions}
        getLmsDashboardReportData={getLmsDashboardReportData}
        getLMSDashboardReport={getLMSDashboardReport}
        backDetail={backDetail}
        getDivisionData={getDivisionData}
        tab={props?.tab}
      />
    </>
  );
};

export default LmsContainer;
