import React, { useContext } from "react";

import FacebookIcon from "../../assets/facebook.svg";
import LinkedInIcon from "../../assets/Linkedin.svg";
import TwitterIcon from "../../assets/Twitter.svg";

import { ThemeContext } from "../../context";

import "./footer.scss";

const Footer = ({ fixed, mode }) => {
  let classes = "footer";
  if (fixed === "fixed") {
    classes = "footer fixed";
  }

  // const theme = useContext(ThemeContext);
  // const darkMode = theme.state.darkMode;

  // const toggleClick = () => {
  //   theme.dispatch({ type: "TOGGLE" });
  //   localStorage.setItem("theme", darkMode ? "light" : "dark");
  // };

  return (
    <div
      className={`${classes} ${
        mode === "dark" ? "dark-footer" : "light-footer"
      }`}
    >
      <div className="footer-contents">
        <div className="footer-contents-left">
          <h1>© Copyright 2022-2023 . All Rights Reserved</h1>
          <div className="footer-contents-left-icons">
            <img src={FacebookIcon} alt="facebook" />
            <img src={TwitterIcon} alt="twitter" />
            <img src={LinkedInIcon} alt="linkedin" />
          </div>
        </div>
        <div className="footer-contents-right">
          {/* <div className="toggle-switch">
            <label className="switch">
              <input
                onClick={toggleClick}
                type="checkbox"
                name="darkmode"
                value="darkmode"
              />
              <span className="slider"></span>
            </label>{" "}
            {darkMode ? "Light" : "Dark"}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
