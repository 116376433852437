import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "./safetyMeetingTableCard.scss";
import { Box } from "@mui/material";

const SafetyMeetingTableCard = (props) => {
  const { viewOnClick, rowData, tab } = props;

  return (
    <>
      <div className="cardBoxOver">
        <Card className="cardBox">
          <CardContent className="cardContentBox">
            <Typography className="cardTitle" gutterBottom>
              {rowData?.titleSafetyMeeting.length > 55
                ? rowData?.titleSafetyMeeting.substring(0, 55) + "..."
                : rowData?.titleSafetyMeeting}
            </Typography>
            <hr class="solid"></hr>
          </CardContent>
          <CardContent className="cardContentBox1">
            <Typography className="safetyUsercarddetail">
              <Box className={`safetybox ${tab == "1" ? "compTab" : ""}`}>
                {" "}
                <div class="detail">
                  <div className="detailspan">
                    <span>ID:</span>{" "}
                    {tab === 2 ? rowData?.id : rowData?.scheduleId}
                  </div>
                </div>
                <div class="detail">
                  <div className="detailspan">
                    <span>Category:</span>{" "}
                    {rowData?.categoryName.length > 55
                      ? rowData?.lesson.substring(0, 55) + "..."
                      : rowData?.categoryName}
                  </div>
                </div>{tab !== 2 &&
                <div class="detail">
                  <div className="detailspan">
                    <span>Assigned On:</span> {rowData?.assignedOn}
                  </div>
                </div>}
                {tab == 1 && (
                  <>
                    <div class="detail">
                      <div className="detailspan">
                        <span>Date Completed:</span> {rowData?.completedOn}
                      </div>
                    </div>
                    <div class="detail">
                      <div className="detailspan">
                        <span>Attendees:</span> {rowData?.attendeesCount}
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </Typography>
          </CardContent>
          <CardActions className={["cardBtn enroll"]}>
            {tab === 0 || tab === 2 ? (
              <>
                {" "}
                <Button
                  className={["cardButton enroll"]}
                  size="small"
                  onClick={() => viewOnClick(rowData?.id)}
                >
                  {"Start"}
                </Button>
              </>
            ) : (
              ""
            )}

            {tab === 1 && (
              <Button
                className={["cardButton cardbtncomp"]}
                size="small"
                onClick={() => viewOnClick(rowData?.id)}
              >
                {"Completed"}
              </Button>
            )}
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default SafetyMeetingTableCard;
