import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../library/custom/button/Button";
import GridTable from "../../../library/custom/gridTable/GridTable";
import {
  ASSET_TYPE_JHA,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../constant/constant";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import SettingTable from "../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../incidents/incidentList/ReportRowManipulte";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterCommonStatusSelect from "../../admin/shared/FilterCommonStatusSelect";
import { getFormatedDate } from "../../../utils/helper";
import { CONDUCT_JHA_REPORT } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import downloadIcon from "../../../assets/downloadIcon.svg";

export default function ConductJhaList({
  getConductJhaList,
  conductJhaListData,
  isLoading,
  NavigateAddConductjha,
}) {
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [asetType, setAssetType] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTableLoading, setisTableLoading] = useState(false);
  const [conductJhaListApiData, setConductJhaListApiData] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    endDate: toDate ? getFormatedDate(toDate) : "",
    startDate: fromDate ? getFormatedDate(fromDate) : "",
    userId: 0,
    assetType: 0,
    isSendMail: false,
  });

  const onchangeToDatePicker = (e) => {
    setToDate(e);
  };
  const onchangeFromDatePicker = (e) => {
    setFromDate(e);
  };

  const [filter, setFilter] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    endDate: toDate ? getFormatedDate(toDate) : "",
    startDate: fromDate ? getFormatedDate(fromDate) : "",
    userId: 0,
    assetType: asetType ? asetType : 0,
    isSendMail: false,
  });

  const assetTypeOnChanges = (e) => {
    setAssetType(e.target.value);
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    getConductJhaList(data);
  };

  const handleApply = (state) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);

    setConductJhaListApiData({
      ...conductJhaListApiData,
      endDate: toDate ? getFormatedDate(toDate) : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      assetType: asetType ? asetType : 0,
      isSendMail: false,
    });
    setAnchorEl(null);
    const data = {
      ...conductJhaListApiData,
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      endDate: toDate ? getFormatedDate(toDate) : "",
      startDate: fromDate ? getFormatedDate(fromDate) : "",
      userId: 0,
      assetType: asetType ? asetType : 0,
      isSendMail: false,
    };
    getConductJhaList(data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...conductJhaListApiData,
      page: newPage + 1,
      isSendMail: false,
    };
    setConductJhaListApiData({
      ...conductJhaListApiData,
      page: newPage + 1,
      isSendMail: false,
    });
    getConductJhaList(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...conductJhaListApiData,
      search: searchText ? searchText : "",
      page: 1,
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setConductJhaListApiData({
      ...conductJhaListApiData,
      search: searchText ? searchText : "",
    });
    getConductJhaList(data);
  };

  const handleClear = () => {
    setFromDate("");
    setToDate("");
    setAssetType("");
    setSearch("");
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      endDate: "",
      startDate: "",
      userId: 0,
      assetType: 0,
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setConductJhaListApiData(data);
    handleSearch(data);
  };

  const handleOnViewclick = (rows) => {
    navigate(CONDUCT_JHA_REPORT, {
      state: { id: rows.row.id, editMode: true },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(CONDUCT_JHA_REPORT, {
        state: { id: rows.row.id, editMode: true },
      });
    }
  };

  const credcloumns = [
    { field: "id", headerName: "ID", flex: 2.8 },
    { field: "templateName", headerName: "Templates Name", flex: 4 },
    { field: "dateOfEntry", headerName: "Date of Entry", flex: 4 },
    { field: "assetName", headerName: "Asset Name", flex: 4 },
    { field: "assetType", headerName: "Asset Type", flex: 3.5 },
    { field: "conductedBy", headerName: "Conducted by", flex: 3.4 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={conductJhaListData?.data?.result?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={conductJhaListData?.data?.result?.itemList ?? []}
          fileNameXL={"ConductJhaList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            viewOnClick={() => handleOnViewclick(rows)}
            edit={false}
            editLabel="Edit Client"
            download={false}
            view={checkPrivileges([12, 123, 124, 125])}
            viewLabel="View Hazard Analysis Report"
          />
        );
      },
    },
  ];

  const sendEmail = () => {
    const data = {
      ...conductJhaListApiData,
      isSendMail: true,
    };
    setConductJhaListApiData(data);
    getConductJhaList(data);
  };
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Hazard Analysis</div>
          <div>
            <div className="filter_btn">
              {checkPrivileges([123, 124, 12]) && (
                <Buttons
                  id="btnAddClient"
                  label={"Add Hazard Analysis Report"}
                  varientAddIconBlue={true}
                  onClick={NavigateAddConductjha}
                ></Buttons>
              )}
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>

              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <FilterCommonStatusSelect
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filter={filter}
                  selectLable={"Asset Type"}
                  categoryType={ASSET_TYPE_JHA}
                  category={asetType}
                  categoryOnChanges={assetTypeOnChanges}
                  filterSelect={true}
                  resetBtn={true}
                  filterDate={true}
                  toDate={toDate}
                  fromDate={fromDate}
                  onchangeToDatePicker={onchangeToDatePicker}
                  onchangeFromDatePicker={onchangeFromDatePicker}
                  minDate={true}
                />
              </div>
            </div>
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              {isTableLoading ? (
                ""
              ) : (
                <GridTable
                  getRowId={(r) => r.id}
                  rows={conductJhaListData?.data?.result?.itemList ?? []}
                  rowCount={conductJhaListData?.data?.result?.itemsCount ?? 0}
                  columns={credcloumns}
                  loading={isLoading}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZE}
                  onClick={(rows) => onRowClick(rows)}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
