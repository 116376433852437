import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lmsAction from "../../../redux/actions/lmsAction";
import AddCourse from "../component/addCourse/addCourse";
import { useLocation, useNavigate } from "react-router-dom";
import { API_STATUS } from "../../../constant/constant";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { ACCESS_DENIED, COURSES } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";
const AddCoursesContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const edit = pathname.split("/")[2] ?? null;
  const editid = pathname.split("/")[3] ?? null;
  const lessionListData = useSelector(
    (state) => state.getlessonsDropDownList?.data?.lessonList
  );
  let navigate = useNavigate();

  const createCourses = useSelector((state) => state.createCourses);
  const cid = createCourses?.data?.result?.courseId;
  const createCoursesIsloading = useSelector(
    (state) => state.createCourses?.isLoading
  );

  const statuss = createCourses?.status;
  const categoryListDataFIsLoading = useSelector(
    (state) => state.getCategoryList?.isLoading
  );
  const categoryListData = useSelector(
    (state) => state.getCategoryList?.data?.categoryList
  );
  const { editCourseData, status, isLoading, isSuccess } = useSelector(
    (state) => state.updateCourse
  );

  const [successScreens, setSuccessScreens] = useState(false);
  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);
    if (isAllowedDash === false) {
      navigate(ACCESS_DENIED);
    }
  }, []);

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);
    if (isAllowedDash === false) {
      navigate(ACCESS_DENIED);
    }
    if (!!edit && editid) {
      dispatch(lmsAction.getCourseDetails(editid));
    }
  }, [edit, editid, dispatch]);

  const addAndUpdateCourse = async (data) => {
    setSuccessScreens(true);
    if (editid) {
      dispatch(lmsAction.updateCoursesData(data));
    } else {
      dispatch(lmsAction.createCourses(data));
    }
  };

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={COURSES}
        id={id}
        label2={labelmsg}
        label1="Course ID"
        label4="Course in "
      ></SuccessScreencommon>
    );
  };
  return (
    <>
      {createCoursesIsloading && <Loader />}
      {isLoading && <Loader />}
      {categoryListDataFIsLoading && <Loader />}

      {statuss === API_STATUS.SUCCESS && !editid && successScreens ? (
        successScree(cid, "Course Created Successfully", "")
      ) : status === API_STATUS.SUCCESS && editid && successScreens ? (
        successScree(editid, "Course Updated Successfully", "has been updated")
      ) : (
        <AddCourse
          categoryListData={categoryListData}
          lessionListData={lessionListData}
          onSubmit={addAndUpdateCourse}
          editCourseData={editCourseData}
          isSuccess={isSuccess}
        />
      )}
    </>
  );
};

export default AddCoursesContainer;
