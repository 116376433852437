import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useThemeContext } from "../../../../context";
import { COLORS } from "../../../../constant/constant";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LineCharts({ dashboardTickets }) {
  const { mode } = useThemeContext();
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: "white",
      },
      legend: {
        position: "top",
        labels: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
          font: {
            family: "Lato",
          },
        },
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  const labels = ["Average Response Time, Average First Response Time"];

  const data = {
    labels,
    datasets: [
      {
        label: "Average response time",
        data: [dashboardTickets?.graphData?.averageResponseTimeMints],
        backgroundColor: "#1B436E",
      },
      {
        label: "Average first response time",
        data: [dashboardTickets?.graphData?.averageFirstResponseTimeMints],
        backgroundColor: "#195BE1",
        color: "#fff",
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
