import React, { useState, useEffect } from "react";
import InspectionAcknowledgment from "../component/inspection/inspectionAcknowledgment";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { ACCESS_DENIED, VIEW_MAPPING_ACKNOWLEDGMENT } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

const InspectionAcknowledgmentContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [clearInspectionAcknowledgement, setClearInspectionAcknowledgement] = useState(false);
  const [checkStatus, setCheckStatus] = useState(1);

  const defaultSubData = {
    type: 5,
  };

  const getInspectionAcknowledgement = useSelector(
    (state) => state.getInspectionAcknowledgement
  );

  const updateInspectionAcknowledgement = useSelector(
    (state) => state.updateInspectionAcknowledgement
  );

  const showLoader = useSelector(
    (state) =>
      state.getInspectionAcknowledgement?.isLoading ||
      state.updateInspectionAcknowledgement?.isLoading
  );

  const getInspectionAcknowledgementData = (data) => {
    dispatch(incpectionAction.getInspectionAcknowledgement(data));
  };

  const viewMapping = (data) => {
    navigate(VIEW_MAPPING_ACKNOWLEDGMENT, {
      state: { acknowledgment: data },
    });
  };

  const onSubmit = (data) => {
    setClearInspectionAcknowledgement(true);
    dispatch(incpectionAction.updateInspectionAcknowledgement(data));
  };

  useEffect(() => {
    getInspectionAcknowledgementData(defaultSubData);
  }, []);

  useEffect(() => {
    if (
      updateInspectionAcknowledgement?.status === "SUCCESS" &&
      updateInspectionAcknowledgement?.isLoading === false &&
      clearInspectionAcknowledgement
    ) {
      if (checkStatus === 1) {
        toast("Inspection Acknowledgement Added successfully");
      } else if (checkStatus === 2) {
        toast("Inspection Acknowledgement Updated successfully");
      }
      getInspectionAcknowledgementData(defaultSubData);
    }
  }, [updateInspectionAcknowledgement]);

  return (
    <>
      {checkPrivileges([12, 120, 121, 4]) ? (
        <>
          {showLoader && <Loader />}
          <InspectionAcknowledgment
            getInspectionAcknowledgement={getInspectionAcknowledgement}
            onSubmit={onSubmit}
            updateInspectionAcknowledgement={updateInspectionAcknowledgement}
            setCheckStatus={setCheckStatus}
            viewMapping={viewMapping}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default InspectionAcknowledgmentContainer;