export const divisionsType = {
  GET_DIVISION_LIST_PENDING:
    "[GET_DIVISION_LIST][REQUEST] Get Division list requested",
  GET_DIVISION_LIST_FAILURE:
    "[GET_DIVISION_LIST][RESPONSE] Get Division list Failed",
  GET_DIVISION_LIST_SUCCESS:
    "[GET_DIVISION_LIST][RESPONSE] Get Division list Successfull",
  GET_DIVISION_LIST_EMAIL_SUCCESS:
    "[GET_DIVISION_LIST_EMAIL_SUCCESS][RESPONSE] Send Division list Successfull",
  CASE_PAGE: "DivisionPage",

  POST_DIVISION_PENDING: "[DIVISION][REQUEST] Create Division",
  POST_DIVISION_FAILURE: "[DIVISION][RESPONSE] Division Creation Failed",
  POST_DIVISION_SUCCESS: "[DIVISION][RESPONSE] Division Created Successfull",
  CREATE_PAGE: "CreatePage",

  UPDATE_DIVISION_PENDING: "UPDATE_DIVISION_PENDING",
  UPDATE_DIVISION_SUCCESS: "UPDATE_DIVISION_SUCCESS",
  UPDATE_DIVISION_FAILED: "UPDATE_DIVISION_FAILED",
  UPDATE_PAGE: "UpdatePage",
  CLEAR_DIVISION: "[CLEAR_DIVISION][REQUEST] Clear DIVISION",
  EDIT_DIVISION: "[EDIT_EDIT_DIVISION][REQUEST] Edit Division",

  GET_DIVISION_DETAILS_PENDING: "GET_DIVISION_DETAILS_PENDING",
  GET_DIVISION_DETAILS_SUCCESS: "GET_DIVISION_DETAILS_SUCCESS",
  GET_DIVISION_DETAILS_FAILED: "GET_DIVISION_DETAILS_FAILED",

  DELETE_DIVISION_PENDING: "DELETE_DIVISION",
  DELETE_DIVISION_SUCCESS: "DELETE_DIVISION_SUCCESS",
  DELETE_DIVISION_FAILED: "DELETE_DIVISION_FAILED",
};
