import React, { useEffect, useState } from "react";
import ObservationList from "./observationList";
import * as observationAction from "../../../redux/actions/observationAction";
import { useDispatch, useSelector } from "react-redux";
import {
  VIEW_OBSERVATION_DETAILS,
  OBSERVATION,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { API_STATUS, PAGE_SIZE } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";

const ObservationListContainer = () => {
  const disptch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.observationList?.isLoading);
  const observationList = useSelector((state) => state.observationList);
  const [sendEmail, setSendEmail] = useState(false);
  useEffect(() => {
    const data = {
      pageSize: PAGE_SIZE,
      page: 1,
      searchText: "",
      fromdate: "",
      todate: "",
      location: "",
      Status: "",
      itemTypes: "",
      isSendMail: false,
    };
    getObservationListData(data);
  }, []);

  const getObservationListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    disptch(observationAction.getListOfObservationPending(data));
  };

  const handleViewOnClick = (
    insObservationId,
    observedType,
    observedEmployee,
    status
  ) => {
    navigate(VIEW_OBSERVATION_DETAILS, {
      state: {
        insObservationId: insObservationId,
        observedType: observedType,
        observedEmployee: observedEmployee,
        status: status,
      },
    });
  };

  const addObservation = () => {
    navigate(OBSERVATION);
  };
  useEffect(() => {
    if (observationList?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Observation has been sent to registered email ID");
    }
  }, [observationList]);

  return (
    <>
      {checkPrivileges([12, 84, 85, 86]) ? (
        <>
          {" "}
          <ToastContainer />
          <ObservationList
            observationList={observationList.data?.result}
            getObservationListData={getObservationListData}
            isLoading={isLoading}
            handleViewOnClick={handleViewOnClick}
            addObservation={addObservation}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ObservationListContainer;
