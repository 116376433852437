import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContractorMapping from "../component/contractor/contractorMapping";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useDispatch, useSelector } from "react-redux";
import * as clientAction from "../../../redux/actions/clientAction";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../library/common/Loader";
import {
  notifyError,
  notifyErrorContractor,
} from "../../../../src/library/custom/toastMessage/Toast";
import { ACCESS_DENIED } from "../../../constant/routeContant";
const ContractorMappingContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const clientsValue = location?.state?.clientsValue;

  const [changesearchauto, setchangesearchauto] = useState({
    key: clientsValue?.key ? clientsValue?.key : "",
    value: clientsValue?.value ? clientsValue?.value : "",
  });
  const [setsubmit, setSetsubmit] = useState(false);
  const [issubsuccess, setissubsuccess] = useState(false);
  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const contractorGrades = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorGrades
  );
  const contractorTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorTypes
  );

  const subContractor = useSelector((state) => state.searchSubcontractor);
  const subContractorDataList = useSelector(
    (state) => subContractor?.data?.users
  );
  const createContractorClientWiseData = useSelector(
    (state) => state.createContractorClientWise
  );

  const subcontractIsloading = createContractorClientWiseData?.isLoading;

  const ismessage =
    createContractorClientWiseData?.data?.responseMessages?.responseMessage;
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  const allClients = useSelector((state) => state.searchAllClientsReducerData);
  const isSearchClientLoading = allClients.isLoading;

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: changesearchauto?.key || clientsValue?.key || ORG_ID(),
      flags: "3,6,2",
      Clientid: changesearchauto?.key || clientsValue?.key || ORG_ID(),
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, [changesearchauto, dispatch]);

  useEffect(() => {
    if (createContractorClientWiseData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else if (
      createContractorClientWiseData.status == API_STATUS.SUCCESS &&
      setsubmit
    ) {
      setissubsuccess(true);
      if (ismessage === "Request was successful") {
        toast("Sub Contractor Added successfully");
      } else {
        toast(ismessage);
      }
      setTimeout(() => {
        setissubsuccess(false);
      }, 2000);
    }
  }, [createContractorClientWiseData]);

  const [FinalaData, setFinalaData] = useState([]);

  const searchSubcontractors = (searchText) => {
    dispatch(contractorAction.searchSubcontractor(searchText));
  };
  const postAddSubcontractorsClientWise = (data) => {
    dispatch(contractorAction.createSubContractorClientwise(data));
    setSetsubmit(true);
  };

  return (
    <>
      {checkPrivileges([12, 43, 44, 45]) ? (
        <>
          {subcontractIsloading && <Loader />}
          {/* {checkPrivileges([12, 43, 44, 45]) ? (
        <>
          <div className="dashTitle"> Contractor Management</div>
          <ContractorManagementList onClick={onClickHandler} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )} */}
          <ContractorMapping
            divisions={division ?? []}
            contractorGrades={contractorGrades ?? []}
            contractorTypes={contractorTypes ?? []}
            searchClient={searchClient}
            allClients={allClients}
            isSearchClientLoading={isSearchClientLoading}
            searchSubcontractors={searchSubcontractors}
            subContractor={subContractorDataList}
            setchangesearchauto={setchangesearchauto}
            changesearchauto={changesearchauto}
            postAddSubcontractorsClientWise={postAddSubcontractorsClientWise}
            setissubsuccess={setissubsuccess}
            issubsuccess={issubsuccess}
            setsubmit={setsubmit}
            setFinalaData={setFinalaData}
            FinalaData={FinalaData}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ContractorMappingContainer;
