import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { useNavigate } from "react-router-dom";
import { getFormatedDate, useInfiniteScroll } from "../../../../utils/helper";
import { USER_ID } from "../../../../constant/constant";

const NotCompletedTrainingUSers = (props) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    if (props?.coursesUserAssignListData) {
      setData((prevData) => [
        ...prevData,
        ...props.coursesUserAssignListData?.assignedUserAdminResponse,
      ]);
    }
  }, [props?.coursesUserAssignListData]);

  useEffect(() => {
    if (props?.getAssignLessonUserListData) {
      setData((prevData) => [
        ...prevData,
        ...props.getAssignLessonUserListData?.assignedUserAdminResponse,
      ]);
    }
  }, [props?.getAssignLessonUserListData]);
  useEffect(() => {
    if (props?.getScheduleAssignUserStatusData) {
      setData((prevData) => [
        ...prevData,
        ...props.getScheduleAssignUserStatusData,
      ]);
    }
  }, [props?.getScheduleAssignUserStatusData]);

  const fetchMoreData = async (pageNumber) => {
    const data =
      props?.location?.state?.type === "Lesson"
        ? {
            pageNumber: pageNumber,
            pageSize: 100,
            lessonId: props.apiData?.lessonId,
            isCompleted: false,
          }
        : props?.location?.state?.type === "Course"
        ? {
            pageNumber: pageNumber,
            pageSize: 100,
            courseId: props?.apiData?.courseId,
            isCompleted: false,
          }
        : props?.location?.state?.type === "Safety Meeting"
        ? {
            scheduleId: props?.apiData?.scheduleId,
            flag: 2,
            scheduleDate: "09-25-2024",
            userId: USER_ID(),
          }
        : "";
    try {
      const response = await props.getMoretDetails(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    fetchMoreData(pageNumber);
    setPage(pageNumber);
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const columns = [
    {
      id: "assignedTo",
      label: `Name`,
      sortable: true,
      isSorted: sortColumn === "assignedTo",
      sortDirection,
    },
    {
      id: "dateAssigned",
      label: `Date Assigned`,
      sortable: true,
      isSorted: sortColumn === "dateAssigned",
      sortDirection,
    },
  ];

  const getcompletedlessondetail = (val) => {
    const dataArr = val?.map((data) => {
      if (data?.prevCourseDates) {
        return getFormatedDate(data?.prevCourseDates);
      }
    });

    return dataArr;
  };

  const rows = data?.map((lesson) => {
    return props?.location?.state?.type === "Safety Meeting" ? (
      <tr className="row" key={lesson?.userId}>
        <td>{lesson.userName}</td>
        <td>{""}</td>
      </tr>
    ) : (
      <tr className="row" key={lesson?.id}>
        <td>{lesson.assignedTo}</td>
        <td>{lesson.dateAssigned}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="customTableWrapper">
        <Table
          columns={columns}
          rows={rows}
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default NotCompletedTrainingUSers;
