import React, { useEffect, useState } from "react";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../library/common/Loader";
import ItemsFoundResponses from "../components/ItemsFoundResponses";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../../constant/routeContant";

const ItemsFoundResponsesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultData = {
    search: "",
    page: 1,
    pageSize: 1000,
  };

  const getSubCategoriesList = useSelector(
    (state) => state.getSwSubCategoryList
  );

  const getSubCategoriesListData = (data) => {
    dispatch(swInspectionAction.getSwSubCategoryList(data));
  };
  const getQuestionList = useSelector((state) => state.getSwQuestionList);
  const getQuestionListData = (data) => {
    dispatch(swInspectionAction.getSwQuestionList(data));
  };
  const updateSwQuestion = useSelector((state) => state.updateSwQuestion);

  const showLoader = useSelector(
    (state) =>
      state.getSwSubCategoryList?.isLoading ||
      state.getSwQuestionList?.isLoading ||
      state.updateSwQuestion?.isLoading
  );
  const questionListStatus = useSelector(
    (state) => state.getSwQuestionList?.isLoading
  );

  useEffect(() => {
    getSubCategoriesListData(defaultData);
  }, []);

  const [clearQuestionOption, setClearQuestionOption] = useState(false);

  const onSubmit = (data) => {
    setClearQuestionOption(true);
    dispatch(swInspectionAction.updateSwQuestion(data));
  };
  useEffect(() => {
    if (
      updateSwQuestion?.status === "SUCCESS" &&
      updateSwQuestion?.isLoading === false &&
      clearQuestionOption
    ) {
      toast("Question Option Update successfully");
    }
  }, [updateSwQuestion]);

  return (
    <>
      {checkPrivileges([12, 93, 94, 95]) ? (
        <>
          {showLoader && <Loader />}

          <ItemsFoundResponses
            getSubCategoriesList={getSubCategoriesList?.data?.result?.categoryList?.filter(
              (x) => x.status
            )}
            getQuestionListData={getQuestionListData}
            getQuestionList={
              questionListStatus
                ? []
                : getQuestionList?.data?.questionList?.filter((x) => x.status)
            }
            isLoading={questionListStatus}
            onSubmit={onSubmit}
            updateSwQuestion={updateSwQuestion}
            questionListStatus={questionListStatus}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ItemsFoundResponsesContainer;
