import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import commonImages from "../../assets";
import ResetPasswordImage from "../../assets/reset-password.png";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./change-password.scss";
import Loader from "../../library/common/Loader";
import { changePassword } from "../../services/loginService";
import { logos } from "../../assets";
import { useThemeContext } from "../../context";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Grid } from "@mui/material";
import { DARK } from "../../constant/constant";
const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [pwdpopMsg, setPwdpopMsg] = useState(false);
  const location = useLocation();
  const mobile = location?.state?.mobile;
  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });

  let defaultErrorStates = {
    password: true,
    confirmPassword: true,
    oldPassword: true,
  };

  /*
Dynamically update the
form whenever user inputs stuff
*/
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);
  const [errorList, setErrorList] = useState(defaultErrorStates);

  const validateForm = () => {
    var capsClass = checks.capsLettercheck ? "valid" : "invalid";
    var numcheckClass = checks.numberCheck ? "valid" : "invalid";
    var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
    var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
    var specClass = checks.specchar ? "valid" : "invalid";
    var passwodval = false;
    if (
      capsClass === "valid" &&
      numcheckClass === "valid" &&
      pwdlenClass === "valid" &&
      sletterClass === "valid" &&
      specClass === "valid" &&
      password.trim().length !== 0
    ) {
      passwodval = true;
    }
    let newErrorList = {
      ...errorList,
      password: passwodval,
      confirmPassword: confirmPassword.trim() === password.trim(),
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
when we don't want to revalidate the
whole thing
*/
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  useEffect(() => {
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);
    } else {
      setPwdpopMsg(true);
    }
  }, [
    checks.capsLettercheck,
    checks.numberCheck,
    checks.pwdlength,
    checks.smalllettercheck,
    checks.specchar,
  ]);

  useEffect(() => {
    if (!mobile) {
      window.location.href = "/login";
    }
    if (untouchedForm.current) {
      return;
    }

    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword, mobile]);

  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();
    setMsg("");
    untouchedForm.current = false;
    if (validateForm()) {
      setLoading(true);
      untouchedForm.current = true;
      try {
        setLoading(true);
        const result = await changePassword(password);
        if (result) {
          setMsg("Password Reset Successfully!");
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setErrorMsg("Password Reset Failed!");
      }
    }
  };

  const handleOnKeyUp = (e) => {
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    // const numberCheck = /[0-9]/.test(value);
    const numberCheck = /\d/.test(value);
    const pwdlength = value.length >= 8;
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    const whitespace = /\s/g.test(value);
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);

      errorList.password = true;
    } else {
      setPwdpopMsg(true);
    }
  };
  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";

  const { mode, toggleTheme } = useThemeContext();
  useEffect(() => {
    document.body.className = mode === "light" ? "light-mode" : "dark-mode";
  }, [mode]);
  const PasswordToggleIcon = ({ isRevealPwd, mode }) => {
    const fillColor = mode === "dark" ? "#004ddd" : "#1b436e";

    return isRevealPwd ? (
      // Visibility off icon
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8 20.6L14.6 16.45C14.0167 16.6333 13.4293 16.771 12.838 16.863C12.246 16.9543 11.6333 17 11 17C8.48333 17 6.24167 16.3043 4.275 14.913C2.30833 13.521 0.883333 11.7167 0 9.49999C0.35 8.61665 0.791667 7.79565 1.325 7.03699C1.85833 6.27899 2.46667 5.59999 3.15 4.99999L0.4 2.19999L1.8 0.799988L20.2 19.2L18.8 20.6ZM11 14C11.1833 14 11.354 13.9917 11.512 13.975C11.6707 13.9583 11.8417 13.925 12.025 13.875L6.625 8.47499C6.575 8.65832 6.54167 8.82932 6.525 8.98799C6.50833 9.14599 6.5 9.31665 6.5 9.49999C6.5 10.75 6.93733 11.8127 7.812 12.688C8.68733 13.5627 9.75 14 11 14ZM18.3 14.45L15.125 11.3C15.2417 11.0167 15.3333 10.7293 15.4 10.438C15.4667 10.146 15.5 9.83332 15.5 9.49999C15.5 8.24999 15.0627 7.18732 14.188 6.31199C13.3127 5.43732 12.25 4.99999 11 4.99999C10.6667 4.99999 10.354 5.03332 10.062 5.09999C9.77067 5.16665 9.48333 5.26665 9.2 5.39999L6.65 2.84999C7.33333 2.56665 8.03333 2.35399 8.75 2.21199C9.46667 2.07065 10.2167 1.99999 11 1.99999C13.5167 1.99999 15.7583 2.69565 17.725 4.08699C19.6917 5.47899 21.1167 7.28332 22 9.49999C21.6167 10.4833 21.1127 11.396 20.488 12.238C19.8627 13.0793 19.1333 13.8167 18.3 14.45ZM13.675 9.84999L10.675 6.84999C11.1417 6.76665 11.571 6.80399 11.963 6.96199C12.3543 7.12065 12.6917 7.34999 12.975 7.64999C13.2583 7.94999 13.4627 8.29599 13.588 8.68799C13.7127 9.07932 13.7417 9.46665 13.675 9.84999Z"
          fill={fillColor}
        />
      </svg>
    ) : (
      // Visibility icon
      <svg
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 12.125C12.25 12.125 13.3127 11.6842 14.188 10.8027C15.0627 9.92058 15.5 8.84961 15.5 7.58984C15.5 6.33008 15.0627 5.25911 14.188 4.37694C13.3127 3.49544 12.25 3.05469 11 3.05469C9.75 3.05469 8.68733 3.49544 7.812 4.37694C6.93733 5.25911 6.5 6.33008 6.5 7.58984C6.5 8.84961 6.93733 9.92058 7.812 10.8027C8.68733 11.6842 9.75 12.125 11 12.125ZM11 10.3109C10.25 10.3109 9.61267 10.0462 9.088 9.51678C8.56267 8.98802 8.3 8.3457 8.3 7.58984C8.3 6.83398 8.56267 6.19134 9.088 5.6619C9.61267 5.13313 10.25 4.86875 11 4.86875C11.75 4.86875 12.3877 5.13313 12.913 5.6619C13.4377 6.19134 13.7 6.83398 13.7 7.58984C13.7 8.3457 13.4377 8.98802 12.913 9.51678C12.3877 10.0462 11.75 10.3109 11 10.3109ZM11 15.1484C8.56667 15.1484 6.35 14.4638 4.35 13.0945C2.35 11.7259 0.9 9.89102 0 7.58984C0.9 5.28867 2.35 3.45345 4.35 2.08416C6.35 0.715555 8.56667 0.03125 11 0.03125C13.4333 0.03125 15.65 0.715555 17.65 2.08416C19.65 3.45345 21.1 5.28867 22 7.58984C21.1 9.89102 19.65 11.7259 17.65 13.0945C15.65 14.4638 13.4333 15.1484 11 15.1484Z"
          fill={fillColor}
        />
      </svg>
    );
  };

  return (
    <div className="change-password">
      <Grid container justifyContent="right" mt={3} mr={3} className="themeToggle">
        {
          <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
            {mode === DARK ? (
              <Brightness7Icon className="iconWhite" />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        }
      </Grid>
      <div className="reset-password-container">
        {/* left */}
        <div className="reset-password-left">
          <div className="logoDark">
            <img className="logo" src={logos?.logoWhite} alt="logo" />
          </div>
          <div className="logoLight">
            <img className="logo" src={logos?.logo} alt="logo" />
          </div>
          <h2 className="reset-password-title">Change Password</h2>
          <form onSubmit={submitButton}>
            <div
              className={[
                "form-wrapper",
                errorList.confirmPassword ? null : "has-error",
              ].join(" ")}
            >
              <label>New Password</label>
              <div className="password-container">
                <input
                  className={[
                    errorList.confirmPassword
                      ? "input-control"
                      : "input-control border-red",
                  ]}
                  type="password"
                  placeholder="*******"
                  value={password}
                  maxLength="20"
                  onChange={(e) => setPassword(e.target.value.trim())}
                  onKeyUp={handleOnKeyUp}
                />

                {password && (
                  <div
                    style={{ display: pwdpopMsg === false ? "none" : "block" }}
                  >
                    <div className="validationPopup">
                      <form>
                        <p style={{ fontWeight: "bold" }}>
                          All checkmarks must turn green.
                        </p>
                        <p className={pwdlenClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 8 characters
                        </p>
                        <p className={capsClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 uppercase letter
                        </p>
                        <p className={sletterClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 lowercase letter
                        </p>
                        <p className={specClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 special character
                        </p>
                        <p className={numcheckClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 number{" "}
                        </p>
                      </form>
                    </div>
                  </div>
                )}

                {errorList.password ? null : (
                  <div className="show-error">
                    Please enter your new password
                  </div>
                )}
              </div>
            </div>
            <div
              className={[
                "form-wrapper",
                errorList.confirmPassword ? null : "has-error",
              ].join(" ")}
            >
              <label>Confirm Password</label>
              <div className="password-container">
                <input
                  className={[
                    errorList.confirmPassword
                      ? "input-control"
                      : "input-control border-red",
                  ]}
                  type={isRevealPwd ? "text" : "password"}
                  placeholder="Enter Your Password"
                  value={confirmPassword}
                  maxLength="20"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img
                  className="showHidePassword"
                  title={isRevealPwd ? "Hide password" : "show password"}
                  alt=""
                  src={
                    isRevealPwd
                      ? commonImages.visibilityoff
                      : commonImages.visibility
                  }
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />

                {errorList.confirmPassword ? null : (
                  <div className="show-error">Password do not match</div>
                )}
              </div>
            </div>
            <div className="login-button">
              {!loading ? (
                <Button
                  label="Change Password"
                  onButtonClickHandler={submitButton}
                />
              ) : (
                <Loader />
              )}
            </div>

            {msg && <p className="success">{msg}</p>}
            {errorMsg && <p className="show-error">{errorMsg}</p>}
          </form>
        </div>
        <div className="reset-password-right">
          <img src={ResetPasswordImage} alt="welcome" />
        </div>
      </div>
      <Footer fixed="fixed" mode={mode} />
    </div>
  );
};

export default ChangePassword;
