export const assetsType = {
  ASSETS_PAGE: "Assets",
  GET_ASSET_LIST: "[GET_ASSET_LIST] Get asset List Data",
  GET_ASSET_LIST_SUCCESS:
    "[GET_ASSET_LIST_SUCCESS] Get asset List Data Successfull",
  GET_ASSET_LIST_EMAIL_SUCCESS:
    "[GET_ASSET_LIST_EMAIL_SUCCESS] Send asset List Data Successfull",
  GET_ASSET_LIST_FAILURE: "[GET_ASSET_LIST_FAILURE] Get asset List Data Failed",

  GET_ASSET_DYNOMO_LIST_PAGE: "GET_ASSET_DYNOMO_LIST Page",
  GET_ASSET_DYNOMO_LIST: "[GET_ASSET_DYNOMO_LIST] Get Asset List Data",
  GET_ASSET_DYNOMO_SUCCESS:
    "[GET_ASSET_DYNOMO_SUCCESS] Get Asset List Data Successfull",
  GET_ASSET_DYNOMO_FAILURE:
    "[GET_ASSET_DYNOMO_FAILURE] Get Asset List Data Failed",

  GET_USER_ASSET_LIST: "[GET_USER_ASSET_LIST] Get Assets List Data",
  GET_USER_ASSET_SUCCESS:
    "[GET_USER_ASSET_SUCCESS] Get Users Asset List Data Successfull",
  GET_USER_ASSET_FAILURE:
    "[GET_USER_ASSET_FAILURE] Get Users Asset List Data Failed",
  GET_USER_ASSET_LIST_PAGE: "GET_USER_ASSET_LIST Page",
};

export const postAddAssestType = {
  POST_ADD_ASSET_PENDING: "[Add ASSET][REQUEST] POST_ADD_ASSET",
  POST_ADD_ASSET_FAILURE: "[Add ASSET][RESPONSE] POST_ADD_ASSET Failed",
  POST_ADD_ASSET_SUCCESS: "[Add ASSET][RESPONSE] POST_ADD_ASSET Successfull",
};

// list of assets
export const deleteAssetType = {
  DELETE_ASSET_PENDING: "DELETE_ASSET",
  DELETE_ASSET_SUCCESS: "DELETE_ASSET_SUCCESS",
  DELETE_ASSET_FAILED: "DELETE_ASSET_FAILED",
  Asset_Page: "Asset Page",
};

export const getAssetDetailsType = {
  ASSET_DETAILS_PAGE: "Asset Details",
  GET_ASSET_DETAILS: "[GET_ASSET_DETAILST] Get asset DETAILS Data",
  GET_ASSET_DETAILS_SUCCESS:
    "[GET_ASSET_DETAILS_SUCCESS] Get asset DETAILS Data Successfull",
  GET_ASSET_DETAILS_FAILURE:
    "[GET_ASSET_DETAILS_FAILURE] Get asset DETAILS Data Failed",
};

export const updateAssetType = {
  UPDATE_ASSET_PENDING: "UPDATE_ASSET_PENDING",
  UPDATE_ASSET_SUCCESS: "UPDATE_ASSET_SUCCESS",
  UPDATE_ASSET_FAILED: "UPDATE_ASSET_FAILED",
};

export const clearAssetType = {
  CLEAR_ASSET: "[CLEAR_ASSET][REQUEST] Clear Asset",
};
