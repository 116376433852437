import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Thumbs from "../custom/media/Image";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import "./FormDropZone.scss";
import { fileUpload } from "../../services/fileUploadService";
import {
  DOCUMENT_UPLOAD_MAX_NUM,
  DOCUMENT_UPLOAD_MAX_SIZE,
} from "../../constant/constant";
import File from "../custom/media/File";
import commonImages from "../../assets";
import ImageTable from "../custom/media/TableView";

const FormDropZone = ({
  key,
  id,
  label,
  paraText1 = "Drop the files here ...",
  paraText2 = "Drop Files here, ",
  paraText3 = "or Browse",
  handleFileChange,
  isImage = true,
  allFiles = false,
  allImages = false,
  imageUrl = [],
  deleteImageURL,
  isMuliple = true,
  acceptedFileType = { "image/*": [".png", ".jpeg", ".jpg"] },
  isDoc = false,
  isMultipleIcon = false,
  filess,
  iscancel = false,
  isTableView = false,
}) => {
  let acceptedFileTypes = acceptedFileType;
  let maxFiles = isMuliple ? DOCUMENT_UPLOAD_MAX_NUM : 1;
  if (allImages) {
    acceptedFileTypes = ["image/jpeg", "image/png", "image/gif"];
  }
  if (isDoc) {
    acceptedFileTypes = {
      "image/*": [".pdf", ".doc", "docx", ".ppt", ".pptx"],
    };
  }
  if (allFiles) {
    acceptedFileTypes = {
      "image/*": [
        ".zip",
        ".rar",
        ".ppt",
        ".pptx",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/docx",
        "application/pdf",
        "text/plain",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  }
  const [files, setFiles] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isMaxFile, setIsMaxFile] = useState(false);
  const [isMaxFiless, setIsMaxFiless] = useState([]);
  const [formDataState, setFormDataState] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [largeFileError, setLargeFileError] = useState(null);
  const handeleUpload = async (filesData) => {
    setFormDataState(filesData);
  };

  useEffect(() => {
    if (formDataState) {
      setIsMaxFile(false);
      const fileSizes = isMaxFiless?.length + formDataState?.length;
      if (fileSizes > maxFiles) {
        setIsMaxFile(true);
        return;
      }
      formDataState.forEach((singleFile) => {
        setIsLoader(true);
        fileUpload(singleFile)
          .then((res) => {
            setIsLoader(false);
            const uploadedfiles = [...files, ...res];
            setFiles(uploadedfiles);
          })
          .catch((error) => {
            setFileError("Error while uploading files.");
          });
      });
    }
  }, [formDataState]);

  useEffect(() => {
    if (iscancel) {
      setFiles([]);
      setIsMaxFiless([]);
      setFormDataState(null);
    }
  }, [iscancel]);

  useEffect(() => {
    if (files) {
      const ddd = [...isMaxFiless, ...files];
      setIsMaxFiless(ddd);
    }
  }, [files]);

  useEffect(() => {
    if (filess?.length > 0) {
      setIsMaxFiless(filess);
    }
  }, [filess]);

  useEffect(() => {
    if (isMaxFiless.length > 0) {
      let filesPath = "";
      const fileData = isMaxFiless && isMaxFiless.map((x) => x.fileUrl);
      const fileDataId = isMaxFiless && isMaxFiless.map((x) => x.id);
      filesPath = fileData && fileData.join("|");
      handleFileChange(filesPath, fileDataId, setFileError(null));
    }
  }, [isMaxFiless]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setIsMaxFile(false);
    setIsFileTooLarge(false);
    setFileError(null);

    const isImage = acceptedFiles.every((file) =>
      file.type.startsWith("image/")
    );

    if (!isImage && allImages) {
      setFileError("Supported image files only.");
      return;
    }

    if (rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0];
      const maxSizeBytes = DOCUMENT_UPLOAD_MAX_SIZE;
      const fileSizeBytes = rejectedFile.file.size;

      if (fileSizeBytes > maxSizeBytes) {
        setIsFileTooLarge(true);
        setLargeFileError("The uploaded files are larger than 50 MB in size.");
        return;
      }
    }

    if (rejectedFiles?.length > maxFiles) {
      setIsMaxFile(true);
      return;
    }
    if (isMaxFiless && isMaxFiless.length <= 2) {
      let FileSize = 0;
      let totalFileSize = acceptedFiles.reduce(
        (accumVariable, curValue) => accumVariable + curValue.size,
        FileSize
      );
      if (totalFileSize >= DOCUMENT_UPLOAD_MAX_SIZE) {
        setIsFileTooLarge(true);
        setLargeFileError("The uploaded files are larger than 50 MB in size.");
        return;
      } else {
        handeleUpload(acceptedFiles);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxFiles: maxFiles,
    maxSize: DOCUMENT_UPLOAD_MAX_SIZE,
    multiple: isMuliple,
  });

  const handleCloseFile = (img) => {
    deleteImageURL();
    const newFiles = files.filter((file) => file.id !== img.id);
    setFiles(newFiles);
  };
  const deleteImageURLs = (url, index) => {
    const newFiles = files.filter((file) => file.fileUrl !== url);
    setFiles(newFiles);

    setIsMaxFiless(isMaxFiless.filter((ites, index) => ites.fileUrl !== url));
    deleteImageURL(url, index);
  };

  const darkMode = localStorage.getItem("themeMode");

  return (
    <div className="overideFormDropBox" key={key} id={id}>
      <div className="dropBoxTitle">{label}</div>
      <div className="dropbox" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>
            <p className="dropText">{paraText1}</p>
          </div>
        ) : (
          <>
            {isMultipleIcon ? (
              <div className="multipleIconContainer">
                <div className="multipleIconImg">
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.83301 13.1667C5.37467 13.1667 4.98245 13.0037 4.65634 12.6776C4.32967 12.3509 4.16634 11.9584 4.16634 11.5001V2.33341C4.16634 1.87508 4.32967 1.48258 4.65634 1.15591C4.98245 0.829803 5.37467 0.666748 5.83301 0.666748H9.99967L11.6663 2.33341H17.4997C17.958 2.33341 18.3505 2.49675 18.6772 2.82341C19.0033 3.14953 19.1663 3.54175 19.1663 4.00008V11.5001C19.1663 11.9584 19.0033 12.3509 18.6772 12.6776C18.3505 13.0037 17.958 13.1667 17.4997 13.1667H5.83301ZM16.6663 16.5001H2.49967C2.04134 16.5001 1.64912 16.337 1.32301 16.0109C0.996341 15.6842 0.833008 15.2917 0.833008 14.8334V4.00008H2.49967V14.8334H16.6663V16.5001ZM7.49967 9.83341H15.833L13.0205 6.08341L10.833 9.00008L9.47884 7.18758L7.49967 9.83341Z"
                      fill="#1B436E"
                    />
                  </svg>
                </div>
                <div>Add Media</div>
              </div>
            ) : (
              <div>
                <CloudUploadOutlinedIcon className="clousIcon" />
                <p className="browseText">
                  {paraText2} <span>{paraText3}</span>
                </p>
                <p className="browse-file-message">
                  You can upload maximum 50 MB files.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {isFileTooLarge && largeFileError && (
        <div className="dropBoxError">{largeFileError}</div>
      )}
      {isMaxFile && (
        <div className="dropBoxError">
          {isMuliple
            ? "You can upload maximum 50 MB files."
            : "You can upload only 1 file."}
        </div>
      )}
      {fileError && !isFileTooLarge && (
        <div className="newErrorMsg">{fileError}</div>
      )}
      <div className="imageBody">
        {isImage ? (
          <>
            {isTableView ? (
              <ImageTable
                handleCloseFile={handleCloseFile}
                files={files}
                imageUrl={imageUrl}
                deleteImageURL={(url, index) => deleteImageURLs(url, index)}
                isLoader={isLoader}
              />
            ) : (
              <Thumbs
                handleCloseFile={handleCloseFile}
                files={files}
                imageUrl={imageUrl}
                deleteImageURL={(url, index) => deleteImageURLs(url, index)}
                isLoader={isLoader}
              />
            )}
          </>
        ) : (
          <File
            handleCloseFile={handleCloseFile}
            files={files}
            imageUrl={imageUrl}
            deleteImageURL={(url, index) => deleteImageURLs(url, index)}
            isLoader={isLoader}
          />
        )}
      </div>
    </div>
  );
};

export default FormDropZone;
