import React, { useState } from "react";
import CreateResource from "../component/resources/createResource";
import { useEffect } from "react";
import * as userAction from "../../../redux/actions/userAction";
import * as resourceAction from "../../../redux/actions/resourceAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ORG_ID,
  LOGIN_ORG_ID,
  ORG_NAME,
} from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { ACCESS_DENIED, ADMIN_RESOURCE } from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as clientAction from "../../../redux/actions/clientAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
export default function AddResourceContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = window.location.search.slice(
    4,
    window.location.search?.length
  );
  const resourcesId = location?.state?.resourceId ?? queryString;
  const editMode = location?.state?.editMode;
  const addMode = location?.state?.addMode;
  const resourceTypesId = location?.state?.resourceType;
  const clientsValue = location?.state?.clientsValue;

  const createResourceData = useSelector((state) => state.createResource);
  const allUsers = useSelector((state) => state.allUsers);
  const allGroups = useSelector((state) => state.searchAllGroup);

  const isUserLoading = allUsers?.isLoading;
  const isUserGroupLoading = allGroups?.isLoading;
  const [resload, setResload] = useState(false);
  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const [changesearchauto, setchangesearchauto] = useState({
    key: clientsValue?.key ? clientsValue?.key : "",
    value: clientsValue?.value ? clientsValue?.value : "",
  });
  const [clients, setClients] = useState();

  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };

  const handleAllUserSearch = (
    searchText,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId,
    productId,
    orgId = clients ? clients : parseInt(ORG_ID())
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        type,
        divisionId,
        assetType,
        assetId,
        0,
        PRODUCT_ID(),
        orgId
      )
    );
  };

  function handleAllGroupSearch(
    serachText,
    divisionId,
    alldivision,
    orgId = clients ? clients : parseInt(ORG_ID())
  ) {
    dispatch(
      userAction.searchAllGroup(serachText, divisionId, alldivision, orgId)
    );
  }

  const viewResourceData = useSelector((state) => state?.resourceDetails?.data);

  const updateResource = useSelector((state) => state.updateResource);

  const showLoader = useSelector(
    (state) =>
      state.createResource?.isLoading ||
      state?.resourceDetails?.isLoading ||
      state.getDropdownDataKeyValue?.isLoading ||
      state.updateResource?.isLoading
  );
  const [successScreens, setSuccessScreens] = useState(false);

  // const searchUsers = (searchText) => {
  //   dispatch(userAction.searchAllUsers(searchText));
  // };

  const resourceCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.resourceCategories
  );
  const resourceType = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.resourceTypes
  );

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: changesearchauto?.key || clientsValue?.key || ORG_ID(),
      flags: [14, 15],
      Clientid: changesearchauto?.key || clientsValue?.key || ORG_ID(),
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, [changesearchauto, clientsValue, dispatch]);

  const [switchclient, setswitchclient] = useState(false);
  useEffect(() => {
    if (ORG_ID() !== LOGIN_ORG_ID()) {
      setswitchclient(true);
      setchangesearchauto({ key: ORG_ID(), value: ORG_NAME() });
    }
  }, []);

  const onSubmit = (data) => {
    setSuccessScreens(true);
    if (data?.resourceId === 0) {
      dispatch(resourceAction.createResource(data));
    } else {
      dispatch(resourceAction.updateResource(data));
    }
  };
  useEffect(() => {
    setSuccessScreens(false);
    dispatch(resourceAction.clearResource());
    const data = {
      resourcesId: resourcesId,
      clientsValue: clientsValue ? clientsValue : ORG_ID(),
    };
    if (data?.resourcesId) {
      setResload(true);
      dispatch(resourceAction.getResourceDetails(data));
    }
  }, [dispatch]);

  const successScreen = (id, successMsg, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={ADMIN_RESOURCE}
        label1="Resource ID"
        label4="Resources in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  // const searchAsset = (searchText, type) => {
  //   dispatch(incpectionAction.getAssetList({ searchText, type }));
  // };
  const searchAsset = (searchText, type) => {
    dispatch(
      assetsAction.getAssetDynomoList({
        orgId: clients ? clients : parseInt(ORG_ID()),
        searchText,
        type,
      })
    );
  };

  const assetOption = useSelector((state) => state.getAssetDynomoList.data);
  //const isLoading = useSelector((state) => state.getAssetDynomoList.isLoading);
  return (
    <>
      {checkPrivileges([12, 73, 74]) ? (
        <>
          {createResourceData.status === "SUCCESS" &&
          createResourceData.data &&
          successScreens ? (
            successScreen(
              createResourceData?.data?.id,
              "Resource Created Successfully"
            )
          ) : updateResource.status === "SUCCESS" &&
            updateResource.data &&
            successScreens ? (
            successScreen(
              updateResource?.data?.id,
              "Resource Updated Successfully",
              "has been Updated"
            )
          ) : (
            <>
              {showLoader && <Loader />}
              <CreateResource
                addMode={addMode}
                onSubmit={onSubmit}
                allUsers={allUsers?.data}
                //  onSearch={searchUsers}
                isLoading={isUserLoading}
                viewResourceData={editMode ? viewResourceData?.result : ""}
                editMode={editMode}
                resourcesId={resourcesId}
                resourceTypesId={resourceTypesId}
                viewEditResourceData={viewResourceData?.result}
                resourceCategory={resourceCategory}
                resourceType={resourceType}
                handleAllUserSearch={handleAllUserSearch}
                allGroupsUser={allGroups?.data}
                isUserGropLoading={isUserGroupLoading}
                handleAllGroupSearch={handleAllGroupSearch}
                searchAsset={searchAsset}
                assetOption={assetOption}
                resload={resload}
                searchAllClientsReducerData={searchAllClientsReducerData}
                searchClient={searchClient}
                autoSearchDataLoading={autoSearchDataLoading}
                clientsValue={clientsValue}
                setClients={setClients}
                clients={clients}
                setchangesearchauto={setchangesearchauto}
                changesearchauto={changesearchauto}
                switchclient={switchclient}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </>
  );
}
