import React, { useState } from "react";
import ReprimandReportView from "../component/reprimand/reprimandReportView";
import { useDispatch, useSelector } from "react-redux";
import { getReprimandDetails } from "../../../redux/actions/reprimandAction";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useEffect } from "react";
import { GET_COUNT_TYPE, ORG_ID } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ACCESS_DENIED, REPRIMAND_LIST } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const ViewReprimandContainer = () => {
  const [successScreens, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const reprimandId = location?.state?.id;
  const status = location?.state?.status;
  const inspectionViolationCounts = useSelector(
    (state) => state.getInspectionViolationCounts
  );
  const data = useSelector(
    (state) => state.reprimand?.editReprimandData?.inspectionAndReprimandDetails
  );

  const ReprimandToClose = useSelector(
    (state) => state?.updateReprimandToClose
  );
  const involvedPartyUserId = location?.state?.involvedPartyUserId;
  const isLoading =
    data?.isLoading ||
    inspectionViolationCounts?.isLoading ||
    ReprimandToClose?.isLoading;
  function closeReprimandReprimand(data) {
    setIsSuccess(true);
    dispatch(incpectionAction.postReprimandToClose(data));
  }
  const submitData = (id) => {
    dispatch(getReprimandDetails(id));
  };

  const reprimandToCloseStatus = useSelector(
    (state) => state?.updateReprimandToClose?.status
  );

  const dataIns = {
    id: involvedPartyUserId,
    type: GET_COUNT_TYPE?.REPRIMAND,
    organisationId: ORG_ID(),
  };
  useEffect(() => {
    if (reprimandId) submitData(reprimandId);

    dispatch(incpectionAction.getInspectionViolationCounts(dataIns));
  }, [reprimandId]);
  const updateReprimandToClose = useSelector(
    (state) => state?.updateReprimandToClose
  );

  const successScreen1 = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Reprimand Closed Successfully"}
        route={REPRIMAND_LIST}
        label1=" "
        label3=" "
        label2=" "
      ></SuccessScreen>
    );
  };
  return (
    <>
      {checkPrivileges([12, 81, 82, 83]) ? (
        <>
          {isLoading && <Loader />}
          {reprimandToCloseStatus === "SUCCESS" &&
          updateReprimandToClose &&
          successScreens ? (
            successScreen1(updateReprimandToClose?.data?.id)
          ) : (
            <ReprimandReportView
              reprimandId={reprimandId}
              data={data}
              inspectionViolationCounts={inspectionViolationCounts}
              closeReprimandReprimand={closeReprimandReprimand}
              involvedPartyUserId={involvedPartyUserId}
              status={status}
            />
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};
export default ViewReprimandContainer;
