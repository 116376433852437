import React, { useState } from "react";
import IncidentReportFilter from "../shared/IncidentReportFilter";
import GridTable from "../../../library/custom/gridTable/GridTable";
import {
  INCIDENT_LIST_COLOUMNS,
  INCIDENT_TYPE,
  PAGE_SIZE,
} from "../../../constant/constant";
import SettingTable from "../shared/SettingTable";
import TableRowAction from "../../../library/custom/gridTable/TableRowAction";
import DialogIncidentBox from "../../../library/common/CloseDialogBox";
import { useNavigate } from "react-router-dom";
import {
  NEAR_MISS,
  PERSONAL_INJURY,
  STOLEN_PROPERTY,
  UTILITY_DAMAGE,
  VEHICLE_ACCIDENT,
  VIEW_INCIDENT_REPORT,
} from "../../../constant/routeContant";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import downloadIcon from "../../../assets/downloadIcon.svg";

const IncidentList = ({
  incidentList,
  getIncidentListData,
  deleteIncidents,
  isLoader,
  isFullAccess,
  isWriteAccess,
  isReadAccess,
  closeIncidents,
}) => {
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [incSearchText, setIncSearchText] = useState("");
  const [incidentFilterData, setIncidentFilterData] = useState({});
  const [isTableLoading, setisTableLoading] = useState(false);

  const resetData = () => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setIncSearchText();
    setIncidentFilterData({});
    getIncidentListData({ pageNumber: 1, isSendMail: false });
  };

  const navigate = useNavigate();
  const columns = React.useMemo(() => {
    const headers = [...INCIDENT_LIST_COLOUMNS];
    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={incidentList?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={incidentList?.incident ?? []}
          fileNameXL={"Incident-List"}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            actionData={["View Details", "Edit Incident", "Delete Incident"]}
            usingComp={"Incident"}
            edit={isFullAccess || isWriteAccess}
            view={isReadAccess || isFullAccess || isWriteAccess}
            deleted={isFullAccess}
            editLabel={"Edit Incident"}
            viewLabel={"View Incident"}
            deleteLabel={"Delete Incident"}
            CloseIncident={
              (rows?.row?.statusId !== "1" && rows?.row?.statusId !== "3"
                ? true
                : false) &&
              (isFullAccess || isWriteAccess)
            }
            CloseIncidentLabel={"Close Incident"}
            viewOnClick={(currentRow) => handleViewOnClick(currentRow)}
            editOnClick={(currentRow) => handleEditOnclick(currentRow)}
            deleteOnClick={() => deleteIncident(rows?.row)}
            closeOnClick={() => closeIncidentHandler(rows?.row)}
          />
        );
      },
    });
    return headers;
  }, []);

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  const handleSearchFilter = (event, state) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setAnchorEl(null);
    setIncidentFilterData(state);
    const data = {
      pageNumber: page,
      searchText: incSearchText,
      ...state,
      isSendMail: false,
    };
    getIncidentListData(data);
  };

  const handleInputSearch = (e) => {
    let searchitm = "";
    if (e.target.value) {
      searchitm = e.target.value;
    }
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setIncSearchText(searchitm);
    getIncidentListData({
      pageNumber: page,
      searchText: searchitm,
      isSendMail: false,
      ...incidentFilterData,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getIncidentListData({
      pageNumber: newPage + 1,
      searchText: incSearchText,
      isSendMail: false,
      ...incidentFilterData,
    });
  };

  const handleEditOnclick = (rows) => {
    if (rows?.incidentId === 2) {
      navigations(VEHICLE_ACCIDENT, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 1) {
      navigations(PERSONAL_INJURY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 4) {
      navigations(UTILITY_DAMAGE, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 3) {
      navigations(STOLEN_PROPERTY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 5) {
      navigations(NEAR_MISS, rows?.reportId, rows?.caseId);
    }
  };

  const handleViewOnClick = (rows) => {
    if (
      rows?.incidentId === 2 ||
      rows?.incidentId === 1 ||
      rows?.incidentId === 3 ||
      rows?.incidentId === 4 ||
      rows?.incidentId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incidentId
      );
    }
  };

  const [openIncidentModal, setOpenIncidentModal] = useState(false);
  const [selectedIncidentRow, setSelectedIncidentRow] = useState("");
  const [actionIds, setActionIds] = useState();
  const [closeDeleteMode, setCloseDeleteMode] = useState(1);

  const closehandle = () => {
    setOpenIncidentModal(false);
    closeIncidents(selectedIncidentRow);
  };

  const closeIncidentHandler = (rows) => {
    setOpenIncidentModal(true);
    setSelectedIncidentRow(rows);
    setActionIds(rows);
    setCloseDeleteMode(1);
  };

  const deletehandle = () => {
    setOpenIncidentModal(false);
    deleteIncidents(selectedIncidentRow);
  };

  const deleteIncident = (rows) => {
    setOpenIncidentModal(true);
    setSelectedIncidentRow(rows);
    setActionIds(rows);
    setCloseDeleteMode(2);
  };

  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
      },
    });
  }
  const onRowClick = (rows) => {
    if (rows.value) {
      handleViewOnClick(rows?.row);
    }
  };

  const sendEmail = () => {
    getIncidentListData({
      pageNumber: page,
      searchText: incSearchText,
      isSendMail: true,
      ...incidentFilterData,
    });
  };

  return (
    <>
      <section className="grid_main_sec">
        {openIncidentModal && (
          <DialogIncidentBox
            open={openIncidentModal}
            rows={[]}
            header={
              parseInt(closeDeleteMode) === 1
                ? "Close Incident"
                : "Delete Incident"
            }
            warningImg={parseInt(closeDeleteMode) === 2}
            closehandle={
              parseInt(closeDeleteMode) === 1 ? closehandle : deletehandle
            }
            handleCloseModal={() => setOpenIncidentModal(false)}
            content={
              parseInt(closeDeleteMode) === 1
                ? actionIds?.actionIds?.length > 0
                  ? `Following Action items ID: [${actionIds?.actionIds}] are still in open status, Do you still want to close this Incident?`
                  : "No Action Items Open status"
                : actionIds?.actionIds?.length > 0
                ? `Following Action items ID: [${actionIds?.actionIds}] are still in open status, Do you still want to Delete this Incident?`
                : "No Action Items Open status"
            }
            view
          />
        )}

        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Incident Reports</div>
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <IncidentReportFilter
              filterType={INCIDENT_TYPE ?? []}
              handleSearchFilter={handleSearchFilter}
              handleClose={handleClose}
              anchorEl={anchorEl}
              incReport={incSearchText}
              handleInputSearch={handleInputSearch}
              resetFilterAPI={resetData}
              setAnchorEl={setAnchorEl}
              singleSelect={true}
              singleCheckbox={false}
            />
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>
        </div>

        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            {isTableLoading ? (
              ""
            ) : (
              <GridTable
                getRowId={(r) => r.reportId}
                rows={incidentList?.incident ?? []}
                columns={columns}
                rowCount={incidentList?.noOfRecords ?? 0}
                checkboxSelection={false}
                page={page}
                onClick={(rows) => onRowClick(rows)}
                pagination
                loading={isLoader}
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default IncidentList;
