import React, { useEffect } from "react";
import "./adminTicketDashboard.scss";
import "../../../library/sidebar/sidebar.scss";
import { Grid } from "@mui/material";
import SentimentNeutralSharpIcon from "@mui/icons-material/SentimentNeutralSharp";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";
import * as supportAction from "../../../redux/actions/supportAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import DonoughtChart from "../component/ticket/charts";
import LineCharts from "../component/ticket/lineCharts";
import { getFormatedDate } from "../../../utils/helper";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  ADMIN_FEEDBACK_TICKET,
  ADMIN_TICKET,
} from "../../../constant/routeContant";

const AdminTicketDashboar = () => {
  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state) => state.getDashboardTickets.isLoading
  );
  useEffect(() => {
    dispatch(supportAction.getDashboardTickets(""));
  }, []);
  const dashboardTickets = useSelector(
    (state) => state.getDashboardTickets?.data?.result
  );
  const today = new Date();
  const navigate = useNavigate();
  function handleNavigate(route, tab, priority, type) {
    navigate(route, {
      state: {
        tab: tab,
        priority: priority,
        type,
        dashboardTickets: dashboardTickets,
      },
    });
  }
  return (
    <>
      <div className="overrideAdminTicketDashBoard">
        <div className="rightBarr">
          {checkPrivileges([7, 12]) ? (
            <>
              {dashboardTickets ? (
                <div className="container_wrapperr">
                  <Grid className="containerHeading">
                    <h1>Support Dashboard</h1>
                  </Grid>
                  <Grid mt={2} className="ticketNumbers" container gap={1.3}>
                    <Grid lg={2.1}>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, "", "")}
                        className="totalTickets"
                      >
                        <h1 className="headings">Total Tickets</h1>
                        <h1 className="value">
                          {dashboardTickets.totalTickets}
                        </h1>
                      </Grid>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, 3, "")}
                        mt={1}
                        className="lowCard pCard"
                      >
                        <h1 className="headings">Low Priority</h1>
                        <h1 className="value">
                          {dashboardTickets.lowPriorityTickets}
                        </h1>
                      </Grid>
                    </Grid>
                    <Grid lg={2.1}>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, "", 0)}
                        className="tktTypeCard"
                      >
                        <h1 className="headings">Open Tickets</h1>
                        <h1 className="value">
                          {dashboardTickets.openTickets}
                        </h1>
                      </Grid>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, "", 1)}
                        className="tktTypeCard"
                        mt={1.1}
                      >
                        <h1 className="headings">Closed Tickets</h1>
                        <h1 className="value">
                          {dashboardTickets.closedTickets}
                        </h1>
                      </Grid>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, 2, "")}
                        className="mediumCard pCard"
                        mt={1.1}
                      >
                        <h1 className="headings">Medium Priority</h1>
                        <h1 className="value">
                          {dashboardTickets.mediumPriorityTickets}
                        </h1>
                      </Grid>
                    </Grid>
                    <Grid lg={2.1}>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 2, "", "")}
                        className="tktTypeCard"
                      >
                        <h1 className="headings">Unassigned</h1>
                        <h1 className="value">
                          {dashboardTickets.unassignedTickets}
                        </h1>
                      </Grid>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, "", 2)}
                        className="tktTypeCard"
                        mt={1.1}
                      >
                        <h1 className="headings">In Progress</h1>
                        <h1 className="value">
                          {dashboardTickets.inProgressTickets}
                        </h1>
                      </Grid>
                      <Grid
                        onClick={() => handleNavigate(ADMIN_TICKET, 0, 1, "")}
                        className="highCard pCard"
                        mt={1.1}
                      >
                        <h1 className="headings">High Priority</h1>
                        <h1 className="value">
                          {dashboardTickets.highPriorityTickets}
                        </h1>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={3.5}
                      onClick={() => handleNavigate(ADMIN_TICKET, 0, "", "")}
                    >
                      <Grid item container lg={12}>
                        <div className="graphCardBody">
                          <DonoughtChart
                            DonutChart
                            dashboardTickets={dashboardTickets}
                          />
                          <div className="assignedDiv">
                            <h1>
                              {dashboardTickets.unassignedTickets
                                ? Math.round(
                                    ((dashboardTickets.totalTickets -
                                      dashboardTickets.unassignedTickets) /
                                      dashboardTickets.totalTickets) *
                                      100
                                  )
                                : 0}
                              %
                            </h1>
                            <p>Assigned</p>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container lg={10.2} mt={2} className="graphDiv">
                    <Grid mt={12} item lg={9} className="darkline">
                      <Grid>
                        <h1 className="headings">Today’s trends</h1>
                        <p className="headings">
                          {" "}
                          as of {getFormatedDate(today)}
                        </p>
                      </Grid>
                      <LineCharts dashboardTickets={dashboardTickets} />
                    </Grid>
                    <Grid item lg={3}>
                      <Grid item lg={12} className="graphCard">
                        <h2>{dashboardTickets?.graphData?.resolved}</h2>
                        <h1>Resolved</h1>
                      </Grid>
                      <Grid className="line" />
                      <Grid className="graphCard">
                        <h2>{dashboardTickets?.graphData?.received}</h2>
                        <h1>Received</h1>
                      </Grid>
                      <Grid className="line" />
                      <Grid className="graphCard">
                        <h2>
                          {
                            dashboardTickets?.graphData
                              ?.averageFirstResponseTimeMints
                          }{" "}
                          min
                        </h2>
                        <h1>Average first response time</h1>
                      </Grid>
                      <Grid className="line" />
                      <Grid className="graphCard">
                        <h2>
                          {
                            dashboardTickets?.graphData
                              ?.averageResponseTimeMints
                          }{" "}
                          min
                        </h2>
                        <h1>Average response time</h1>
                      </Grid>
                      <Grid className="line" />
                      <Grid className="graphCard">
                        <h2>
                          {dashboardTickets?.graphData?.resolutionWithinSLA}
                        </h2>
                        <h1>Resolution within SLA</h1>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    lg={10.2}
                    mt={2}
                    mb={2}
                    className="customerSatisfaction"
                  >
                    <Grid className="header">
                      <h1 className="h1">Customer Satisfaction</h1>
                      <h1 className="h2">This Week</h1>
                    </Grid>
                    <Grid
                      mt={2}
                      container
                      item
                      spacing={2}
                      gap={2}
                      className="satisFactionCards"
                    >
                      <Grid
                        onClick={() =>
                          handleNavigate(ADMIN_FEEDBACK_TICKET, "", "", 1)
                        }
                        className="tktTypeCard totalCard"
                        lg={2.75}
                      >
                        <h1 className="headings">Total Feedback</h1>
                        <h1 className="value blue">
                          {dashboardTickets.totalFeedbacks}
                        </h1>
                      </Grid>
                      <Grid
                        onClick={() =>
                          handleNavigate(ADMIN_FEEDBACK_TICKET, 2, "", 1)
                        }
                        className="tktTypeCard"
                        lg={2.75}
                      >
                        <Grid>
                          <h1 className="headings">Neutral</h1>
                          <h1 className="value blue">
                            {dashboardTickets.neutralFeedbacks
                              ? Math.round(
                                  (dashboardTickets.neutralFeedbacks /
                                    dashboardTickets.totalFeedbacks) *
                                    100
                                )
                              : 0}
                            %
                          </h1>
                        </Grid>
                        <Grid>
                          <SentimentNeutralSharpIcon className="icon neutrol" />
                        </Grid>
                      </Grid>
                      <Grid
                        onClick={() =>
                          handleNavigate(ADMIN_FEEDBACK_TICKET, 1, "", 1)
                        }
                        className="tktTypeCard"
                        lg={2.75}
                      >
                        <Grid>
                          <h1 className="headings">Satisfied</h1>
                          <h1 className="value green">
                            {dashboardTickets.satisfiedFeedbacks
                              ? Math.round(
                                  (dashboardTickets.satisfiedFeedbacks /
                                    dashboardTickets.totalFeedbacks) *
                                    100
                                )
                              : 0}
                            %
                          </h1>
                        </Grid>
                        <Grid>
                          <EmojiEmotionsIcon className="icon satisfied" />
                        </Grid>
                      </Grid>
                      <Grid
                        onClick={() =>
                          handleNavigate(ADMIN_FEEDBACK_TICKET, 0, "", 1)
                        }
                        lg={2.75}
                        className="tktTypeCard"
                      >
                        <Grid>
                          <h1 className="headings">Unsatisfied</h1>
                          <h1 className="value red">
                            {dashboardTickets.unsatisfiedFeedbacks
                              ? Math.round(
                                  (dashboardTickets.unsatisfiedFeedbacks /
                                    dashboardTickets.totalFeedbacks) *
                                    100
                                )
                              : 0}
                            %
                          </h1>
                        </Grid>
                        <Grid>
                          <SentimentDissatisfiedSharpIcon className="icon disSatisfied" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Loader className="loader" />
              )}
            </>
          ) : (
            navigate(ACCESS_DENIED)
          )}
        </div>
      </div>
    </>
  );
};

export default AdminTicketDashboar;
