import React from "react";
import DynamicTable from "../../../../../library/custom/dynamicTable/dynamicTable";
import {
  getValueByKey,
  groundStabilizationColumns,
  lotDisturbanceColumns,
  observationColumns,
  phaseProjectColumns,
  rainfallColumns,
} from "../../../../../utils/swInspectionHelper";
import { Grid } from "@mui/material";

const ViewNorthCarolina = (props) => {
  
  return (
    <>
      <Grid container mt={3}>
        {props?.swInspectionConfQuestionAns?.map((item) => {
          return (
            <Grid item xs={12} sm={12} className="confQuestionAns" mb={2} key={item?.confQuesId}>
              <strong>{item?.confQues}:</strong>
              {getValueByKey(item?.confQuesId)}
            </Grid>
          );
        })}
      </Grid>
      <DynamicTable
        title="7-Day Rainfall Data"
        columns={rainfallColumns}
        data={props?.swInspectionRainfallData}
      />

      <DynamicTable
        title="Current Phase of Project"
        columns={phaseProjectColumns}
        data={props?.swInspectionCurrentProjectPhase}
        checkbox={true}
      />

      <DynamicTable
        title="Ground Stabilization:Must be recorded after each phase of grading"
        columns={groundStabilizationColumns}
        data={props?.swInspectionGroundStabilization}
      />

      <DynamicTable
        title="OBSERVATIONS OF RUNNOFF AT STORMWATER DISCHARGE OUTFALLS: DETAILS OF RECOMMENDED CORRECTIVE ACTIONS"
        columns={observationColumns}
        data={props?.swInspectionObservation}
      />

      <DynamicTable
        title="INDIVIDUAL LOT DISTURBANCE / STABILIZATION DATA LOG"
        columns={lotDisturbanceColumns}
        data={props?.swInspectionLotDisturbance}
      />
    </>
  );
};

export default ViewNorthCarolina;
