import { getApiUrl } from "../config";
import { post, get, deletes } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const createCourse = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/AddCourse`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const updateCourseList = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/UpdateCourse`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getCoursesList = async ({ data }) => {
  const {
    pageNumber,
    pageSize,
    search,
    catIds,
    status,
    organisationId,
    isSendMail,
  } = data;

  let url = `${
    getApiUrl().lmsApiUrl
  }/Course/GetCourseList?pageSize=${pageSize}&isSendMail=${isSendMail}&organisationId=${organisationId}`;

  if (pageNumber) {
    url += `&page=${pageNumber}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (catIds) {
    url += `&catIds=${catIds}`;
  }
  if (status) {
    if (status === 2) {
      url += `&status=0`;
    } else {
      url += `&status=${status}`;
    }
  }
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// Remain ORG_ID()
export const getCategoryList = async ({ data }) => {
  console.log("data===========", data);
  const url = `${
    getApiUrl().lmsApiUrl
  }/Lms/GetCategory?orgId=${ORG_ID()}&isGlobal=${data?.isGlobal}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getlessonsDropDownList = async ({ data }) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/LessonList?orgId=${ORG_ID()}&isGlobal=${data?.isGlobal}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCourseDetails = async ({ payload }) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/GetCourseDetailsWithLessonList?courseId=${payload}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteCourse = async ({ payload }) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/DeleteCourse/${
    payload?.courseId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const publishCoursess = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/PublishCourse`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getCourseAssignedUserList = async (params) => {
  const { pageNumber, pageSize, courseId, isCompleted } = params?.data;

  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/GetAssignedUserList?page=${pageNumber}&pageSize=${pageSize}&courseId=${courseId}&isCompleted=${isCompleted}&orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const assignCourses = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/AssignCourseToUser`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const startCoursess = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/StartCourse`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getUserCoursesList = async ({ data }) => {
  const {
    pageNumber,
    pageSize,
    search,
    catIds,
    statusId,
    orgId,
    userId,
    fromDate = "",
    toDate = "",
    divisionId = 0,
    isSendMail,
  } = data;
  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/GetCourseListViaUser?page=${pageNumber}&pageSize=${pageSize}&search=${search}&catIds=${catIds}&statusId=${statusId}&userId=${userId}&orgId=${orgId}&fromDate=${fromDate}&toDate=${toDate}&divisionId=${divisionId}&isSendMail=${isSendMail}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId,
      },
    });
  } catch (err) {
    return err;
  }
};
// UnAssign Course
export const unAssignCourse = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/UnAssignCourseToUser`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getCompletedcourses = async (params) => {
  const { courseId, userOrGroupId, userType } = params?.data;

  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/GetCompletedCourse?courseId=${courseId}&userOrGroupId=${userOrGroupId}&userType=${userType}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// enEnroll Course
export const unEnrollCourse = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Course/UnEnrollCourseByUser`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
//searchCourses
// Course search Api call

export const searchCourses = async (serachText) => {
  if (serachText?.trim()) {
    const url = `${getApiUrl().searchApiUrl}/v1/searchcourse?search=${
      serachText ? serachText?.trim() : ""
    }`;
    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};
//  getMappedGlobalCourseList
export const getMappedGlobalCourseList = async (params) => {
  const { courseId, clientId } = params?.data;
  console.log("courseId, clientId", courseId, clientId);
  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/MappedGlobalCourseList?courseId=${courseId}&clientId=${clientId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

//Tab click1  map 1 Mapping Course Api
export const specifyCourseToUserViewClient = async (data, clientId) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/SpecifyCourseToUserViaClient?clientId=${clientId}`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

//tab click 2
export const specifyCourseToUserViewCourse = async (data, courseId) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/SpecifyCourseToUserViaCourse?courseId=${courseId}`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
