export const credentialType = {
  POST_CREDENTIAL_PENDING: "[CREDENTIAL][REQUEST] Create Credential",
  POST_CREDENTIAL_FAILURE: "[CREDENTIAL][RESPONSE] Credential Creation Failed",
  POST_CREDENTIAL_SUCCESS:
    "[CREDENTIAL][RESPONSE] Credential Created Successfull",

  PUT_CREDENTIAL_PENDING: "[CREDENTIAL][REQUEST] Update Credential",
  PUT_CREDENTIAL_FAILURE: "[CREDENTIAL][RESPONSE] Credential Updation Failed",
  PUT_CREDENTIAL_SUCCESS:
    "[CREDENTIAL][RESPONSE] Credential Updation Successfull",
  GET_CREDENTIAL_LIST_PENDING:
    "[GET_CREDENTIAL_LIST][REQUEST] Get Credential list requested",
  GET_CREDENTIAL_LIST_FAILURE:
    "[GET_CREDENTIAL_LIST][RESPONSE] Get Credential list Failed",
  GET_CREDENTIAL_LIST_SUCCESS:
    "[GET_CREDENTIAL_LIST][RESPONSE] Get Credential list Successfull",
  GET_CREDENTIAL_LIST_EMAIL_SUCCESS:
    "[GET_CREDENTIAL_LIST_EMAIL_SUCCESS][RESPONSE] Send Credential list Successfull",

  GET_CREDENTIAL_DETAILS:
    "[CREDENTIAL_LIST_DETAILS][REQUEST] Get Credential Details Data",
  GET_CREDENTIAL_DETAILS_SUCCESS:
    "[CREDENTIAL_LIST_DETAILS][RESPONSE] Get Credential Details Data Failed",
  GET_CREDENTIAL_DETAILS_FAILURE:
    "[CREDENTIAL_LIST_DETAILS][RESPONSE] Get Credential Details Data Successfull",

  GET_ASSIGN_USER_LIST_PENDING:
    "[GET_ASSIN_USER_LIST][REQUEST] Get Assign User list requested",
  GET_ASSIGN_USER_LIST_SUCCESS:
    "[GET_ASSIGN_USER_LIST][RESPONSE] Get Assign User list Failed",
  GET_ASSIGN_USER_LIST_FAILURE:
    "[GET_ASSIGN_USER_LIST][RESPONSE] Get Assign User list Successfull",

  GET_USER_CREDENTIAL_LIST_PENDING:
    "[GET_USER_CREDENTIAL_LIST][REQUEST] Get User Credential list requested",
  GET_USER_CREDENTIAL_LIST_FAILURE:
    "[GET_USER_CREDENTIAL_LIST][RESPONSE] Get User Credential list Failed",
  GET_USER_CREDENTIAL_LIST_SUCCESS:
    "[GET_USER_CREDENTIAL_LIST][RESPONSE] Get User Credential list Successfull",
  GET_USER_CREDENTIAL_LIST_EMAIL_SUCCESS:
    "[GET_USER_CREDENTIAL_LIST_EMAIL_SUCCESS][RESPONSE] Send User Credential list to email Successfull",

  POST_USER_CREDENTIAL_PENDING:
    "[USER_CREDENTIAL][REQUEST] Create User Credential",
  POST_USER_CREDENTIAL_FAILURE:
    "[USER_CREDENTIAL][RESPONSE] Credential User Creation Failed",
  POST_USER_CREDENTIAL_SUCCESS:
    "[USER_CREDENTIAL][RESPONSE] User Credential Created Successfull",

  GET_SEARCH_CREDENTIAL_PENDING:
    "[GET_USER_CREDENTIAL_LIST][REQUEST] Get Search Credential  requested",
  GET_SEARCH_CREDENTIAL_FAILURE:
    "[GET_USER_CREDENTIAL_LIST][RESPONSE] Get Search Credential  Failed",
  GET_SEARCH_CREDENTIAL_SUCCESS:
    "[GET_USER_CREDENTIAL_LIST][RESPONSE] Get Search Credential  Successfull",

  POST_ASSIGN_CREDENTIAL_PENDING:
    "[ASSIGN_CREDENTIAL][REQUEST] Assign Credential",
  POST_ASSIGN_CREDENTIAL_FAILURE:
    "[ASSIGN_CREDENTIAL][RESPONSE] Assign Credential Creation Failed",
  POST_ASSIGN_CREDENTIAL_SUCCESS:
    "[ASSIGN_CREDENTIAL][RESPONSE]  Assign Credential Created Successfull",

  // GET_CREDENTIAL_DETAILS:
  //   "[ROLE_LIST_DETAILS][REQUEST] Get Credential Details Data",
  // GET_CREDENTIAL_DETAILS_SUCCESS:
  //   "[ROLE_LIST_DETAILS][RESPONSE] Get Credential Details Data Failed",
  // GET_CREDENTIAL_DETAILS_FAILURE:
  //   "[ROLE_LIST_DETAILS][RESPONSE] Get Credential Details Data Successfull",

  CREDENTIAL_PAGE: "Credential",

  GET_CREDENTIAL_LIST_BY_USERS_PENDING:
    "[GET_CREDENTIAL_LIST_BY_USERS][REQUEST] Get Credential list by users requested",
  GET_CREDENTIAL_LIST_BY_USERS_FAILURE:
    "[GET_CREDENTIAL_LIST_BY_USERS][RESPONSE] Get Credential list  by users Failed",
  GET_CREDENTIAL_LIST_BY_USERS_SUCCESS:
    "[GET_CREDENTIAL_LIST_BY_USERS][RESPONSE] Get Credential list  by users Successfull",
  GET_CREDENTIAL_LIST_BY_USERS_EMAIL_SUCCESS:
    "[GET_CREDENTIAL_LIST_BY_USERS_EMAIL_SUCCESS][RESPONSE] Send Credential list  by users Successfull",

  GET_CREDENTIAL_LIST_BY_CREDENTIALS_PENDING:
    "[GET_CREDENTIAL_LIST_BY_CREDENTIALS][REQUEST] Get Credential list by credential requested",
  GET_CREDENTIAL_LIST_BY_CREDENTIALS_FAILURE:
    "[GET_CREDENTIAL_LIST_BY_CREDENTIALS][RESPONSE] Get Credential list  by credential Failed",
  GET_CREDENTIAL_LIST_BY_CREDENTIALS_SUCCESS:
    "[GET_CREDENTIAL_LIST_BY_CREDENTIALS][RESPONSE] Get Credential list  by credential Successfull",
  GET_CREDENTIAL_LIST_BY_CREDENTIALS_EMAIL_SUCCESS:
    "[GET_CREDENTIAL_LIST_BY_CREDENTIALS_EMAIL_SUCCESS][RESPONSE] Send Credential list  by credential Successfull",

  GET_CREDENTIAL_VIEW_DETAILS_PENDING:
    "[CREDENTIAL_vIEW_LIST_DETAILS][REQUEST] Get Credential Details Data",
  GET_CREDENTIAL_VIEW_DETAILS_SUCCESS:
    "[CREDENTIAL_vIEW_LIST_DETAILS][RESPONSE] Get Credential Details Data Failed",
  GET_CREDENTIAL_VIEW_DETAILS_FAILURE:
    "[CREDENTIAL_VIEW_LIST_DETAILS][RESPONSE] Get Credential Details Data Successfull",

  PUT_CREDENTIAL_USER_PENDING:
    "[CREDENTIAL_USER][REQUEST] Update User Credential",
  PUT_CREDENTIAL_USER_FAILURE:
    "[CREDENTIAL_USER][RESPONSE] User Credential Updation Failed",
  PUT_CREDENTIAL_USER_SUCCESS:
    "[CREDENTIAL_USER][RESPONSE] User Credential Updation Successfull",

  CLEAR_CREDENTIAL: "[CLEAR_CREDENTIAL][REQUEST] Clear Credential",

  POST_ADMIN_CREDENTIAL_PENDING:
    "[ADMIN_CREDENTIAL][REQUEST] Create Admin Credential",
  POST_ADMIN_CREDENTIAL_FAILURE:
    "[ADMIN_CREDENTIAL][RESPONSE] Credential Admin Creation Failed",
  POST_ADMIN_CREDENTIAL_SUCCESS:
    "[ADMIN_CREDENTIAL][RESPONSE] Credential Admin Created Successfull",

  DELETE_CREDENTIAL: "[REQUEST] Delete Credential",
  DELETE_CREDENTIAL_SUCCESS: "[RESPONSE] Delete Credential Successfull",
  DELETE_CREDENTIAL_FAILURE: "[RESPONSE] Delete Credential Failed",
};
