import React, { useEffect, useState } from "react";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import AddSubCategory from "../component/inspection/addSubCategory";
import { ToastContainer, toast } from "react-toastify";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";

const InspectionSubCategoriesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultData = {
    searchText: "",
    organisationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    allowPaging: true,
  };
  const getSubCategoriesList = useSelector(
    (state) => state.getSubCategoriesList
  );
  const getSubCategoriesListData = (data) => {
    dispatch(incpectionAction.getSubCategoriesList(data));
  };

  const adminAddUpdateSubcategory = useSelector(
    (state) => state.adminAddUpdateSubcategory
  );

  const isLoading = useSelector(
    (state) => state.getSubCategoriesList?.isLoading
  );

  const showLoader = useSelector(
    (state) => state.adminAddUpdateSubcategory?.isLoading
  );
  const [clearCategory, setClearCategory] = useState(false);

  const onSubmit = (data) => {
    setClearCategory(true);
    dispatch(incpectionAction.adminAddUpdateSubcategory(data));
  };

  useEffect(() => {
    dispatch(incpectionAction.clearAdminAddUpdateSubcategory());
    getSubCategoriesListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      adminAddUpdateSubcategory?.status === "SUCCESS" &&
      adminAddUpdateSubcategory?.isLoading === false &&
      clearCategory
    ) {
      toast("Sub Category Added successfully");
    }
  }, [adminAddUpdateSubcategory]);

  return (
    <>
      {checkPrivileges([12, 120, 121, 122, 4]) ? (
        <>
          {showLoader && <Loader />}

          <AddSubCategory
            getSubCategoriesList={
              isLoading ? [] : getSubCategoriesList?.data?.keyValues
            }
            recordsCount={getSubCategoriesList?.data?.recordsCount}
            onSubmit={onSubmit}
            getSubCategoriesListData={getSubCategoriesListData}
            adminAddUpdateSubcategory={adminAddUpdateSubcategory}
            isLoading={isLoading}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </>
  );
};

export default InspectionSubCategoriesContainer;
