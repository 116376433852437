import React from "react";
import ReportSafetyData from "../component/reportSafetyData";
import { useDispatch } from "react-redux";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { useEffect } from "react";
import {
  GET_ITEM_LOG,
  ORG_ID,
} from "../../../constant/constant";
import { useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

function ReportSafetyDataContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemLogCountData = useSelector((state) => state.getItemList?.data);
  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue
  );
  const getProjectSearchList = useSelector(
    (state) => state.getProjectSearchList?.data?.projects
  );
  const getItemLogData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };
  const showLoader = useSelector(
    (state) =>
      state.getItemList?.isLoading || state.getDropdownDataKeyValue.isLoading
    // ||
    // state.getProjectSearchList?.isLoading
  );

  useEffect(() => {
    getItemLogData({
      userId: 0,
      search: "",
      page: 1,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.All,
      statusIds: "",
      fromDate: "",
      toDate: "",
      status: "",
    });
  }, []);

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [6],
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const getProjectSearch = (data) => {
    dispatch(toolboxAction.getProjectSearchList(data));
  };
  return (
    <>
      {checkPrivileges([12, 114, 115, 116]) ? (
        <>
          {showLoader && <Loader />}
          <ReportSafetyData
            itemLogCountData={itemLogCountData?.result}
            divisons={getDropdownDataKeyValue?.data?.divisons}
            projectList={getProjectSearchList}
            getProjectSearch={getProjectSearch}
            getItemLogData={getItemLogData}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default ReportSafetyDataContainer;
