export const jhaType = {
  CLEAR_JHA: "[CLEAR_JHA][REQUEST] Clear JHA",
  JHA_PAGE: "JHA_PAGE",

  POST_JHA_ADD_CATEGORY: "[POST_JHA_ADD_CATEGORY] Try to create category Data",
  POST_JHA_ADD_CATEGORY_SUCCESS:
    "[POST_JHA_ADD_CATEGORY_SUCCESS] Create Category Data Successfull",
  POST_JHA_ADD_CATEGORY_FAILURE:
    "[POST_JHA_ADD_CATEGORY_FAILURE] Create Category  Data Failed",

  GET_JHA_CATEGORY_LIST: "[GET_JHA_CATEGORY_LIST] Get Jha Category List Data",
  GET_JHA_CATEGORY_LIST_SUCCESS:
    "[GET_JHA_CATEGORY_LIST_SUCCESS] Get Jha Category List Data Successfull",
  GET_JHA_CATEGORY_LIST_FAILURE:
    "[GET_JHA_CATEGORY_LIST_FAILURE] Get Jha Category List Data Failed",

  UPDATE_JHA_CATEGORY: "[UPDATE_JHA_CATEGORY] Try to Update category Data",
  UPDATE_JHA_CATEGORY_SUCCESS:
    "[UPDATE_JHA_CATEGORY_SUCCESS] Update Category Data Successfull",
  UPDATE_JHA_CATEGORY_FAILURE:
    "[UPDATE_JHA_CATEGORY_FAILURE] Update Category  Data Failed",

  POST_JHA_ADD_ACTIVITY: "[POST_JHA_ADD_ACTIVITY] Try to create ACTIVITY Data",
  POST_JHA_ADD_ACTIVITY_SUCCESS:
    "[POST_JHA_ADD_ACTIVITY_SUCCESS] Create ACTIVITY Data Successfull",
  POST_JHA_ADD_ACTIVITY_FAILURE:
    "[POST_JHA_ADD_ACTIVITY_FAILURE] Create ACTIVITY  Data Failed",

  GET_JHA_ACTIVITY_LIST: "[GET_JHA_ACTIVITY_LIST] Get Jha ACTIVITY List Data",
  GET_JHA_ACTIVITY_LIST_SUCCESS:
    "[GET_JHA_ACTIVITY_LIST_SUCCESS] Get Jha ACTIVITY List Data Successfull",
  GET_JHA_ACTIVITY_LIST_FAILURE:
    "[GET_JHA_ACTIVITY_LIST_FAILURE] Get Jha ACTIVITY List Data Failed",

  UPDATE_JHA_ACTIVITY: "[UPDATE_JHA_ACTIVITY] Try to Update ACTIVITY Data",
  UPDATE_JHA_ACTIVITY_SUCCESS:
    "[UPDATE_JHA_ACTIVITY_SUCCESS] Update ACTIVITY Data Successfull",
  UPDATE_JHA_ACTIVITY_FAILURE:
    "[UPDATE_JHA_ACTIVITY_FAILURE] Update ACTIVITY  Data Failed",

  POST_JHA_ADD_HAZARD: "[POST_JHA_ADD_HAZARD] Try to create HAZARD Data",
  POST_JHA_ADD_HAZARD_SUCCESS:
    "[POST_JHA_ADD_HAZARD_SUCCESS] Create HAZARD Data Successfull",
  POST_JHA_ADD_HAZARD_FAILURE:
    "[POST_JHA_ADD_HAZARD_FAILURE] Create HAZARD  Data Failed",

  GET_JHA_HAZARDS_LIST: "[GET_JHA_HAZARDS_LIST] Get Jha HAZARDS List Data",
  GET_JHA_HAZARDS_LIST_SUCCESS:
    "[GET_JHA_HAZARDS_LIST_SUCCESS] Get Jha HAZARDS List Data Successfull",
  GET_JHA_HAZARDS_LIST_FAILURE:
    "[GET_JHA_HAZARDS_LIST_FAILURE] Get Jha HAZARDS List Data Failed",

  UPDATE_JHA_HAZARD: "[UPDATE_JHA_HAZARD] Try to Update HAZARD Data",
  UPDATE_JHA_HAZARD_SUCCESS:
    "[UPDATE_JHA_HAZARD_SUCCESS] Update HAZARD Data Successfull",
  UPDATE_JHA_HAZARD_FAILURE:
    "[UPDATE_JHA_HAZARD_FAILURE] Update HAZARD  Data Failed",

  GET_JHA_CONTROL_LIST: "[GET_JHA_CONTROL_LIST] Get CONTROL List Data",
  GET_JHA_CONTROL_LIST_SUCCESS:
    "[GET_JHA_CONTROL_LIST_SUCCESS] Get Jha CONTROL List Data Successfull",
  GET_JHA_CONTROL_LIST_FAILURE:
    "[GET_JHA_CONTROL_LIST_FAILURE] Get Jha CONTROL List Data Failed",

  POST_JHA_ADD_CONTROL: "[POST_JHA_ADD_CONTROL] Try to create CONTROL Data",
  POST_JHA_ADD_CONTROL_SUCCESS:
    "[POST_JHA_ADD_CONTROL_SUCCESS] Create CONTROL Data Successfull",
  POST_JHA_ADD_CONTROL_FAILURE:
    "[POST_JHA_ADD_CONTROL_FAILURE] Create CONTROL  Data Failed",

  UPDATE_JHA_CONTROL: "[UPDATE_JHA_CONTROL] Try to Update CONTROL Data",
  UPDATE_JHA_CONTROL_SUCCESS:
    "[UPDATE_JHA_CONTROL_SUCCESS] Update CONTROL Data Successfull",
  UPDATE_JHA_CONTROL_FAILURE:
    "[UPDATE_JHA_CONTROL_FAILURE] Update CONTROL  Data Failed",

  GET_CONDUCT_JHA_LIST: "[GET_CONDUCT_JHA_LIST] Get Conduct jha List Data",
  GET_CONDUCT_JHA_LIST_SUCCESS:
    "[GET_CONDUCT_JHA_LIST_SUCCESS] Get Conduct jha List Data Successfull",
  GET_CONDUCT_JHA_LIST_EMAIL_SUCCESS:
    "[GET_CONDUCT_JHA_LIST_EMAIL_SUCCESS] Send Conduct jha List Data Successfull",
  GET_CONDUCT_JHA_LIST_FAILURE:
    "[GET_CONDUCT_JHA_LIST_FAILURE] Get Conduct jha List Data Failed",
  POST_JHA_ADD_TEMPLATE: "[POST_JHA_ADD_CONTROL] Try to create TEMPLATE Data",
  POST_JHA_ADD_TEMPLATE_SUCCESS:
    "[POST_JHA_ADD_TEMPLATE_SUCCESS] Create TEMPLATE Data Successfull",
  POST_JHA_ADD_TEMPLATE_FAILURE:
    "[POST_JHA_ADD_TEMPLATE_FAILURE] Create TEMPLATE  Data Failed",

  GET_JHA_TEMPLATE_LIST: "[GET_JHA_TEMPLATE_LIST] Get TEMPLATE List Data",
  GET_JHA_TEMPLATE_LIST_SUCCESS:
    "[GET_JHA_TEMPLATE_LIST_SUCCESS] Get Jha TEMPLATE List Data Successfull",
  GET_JHA_TEMPLATE_LIST_FAILURE:
    "[GET_JHA_TEMPLATE_LIST_FAILURE] Get Jha TEMPLATE List Data Failed",

  POST_JHA_UPDATE_TEMPLATE_STATUS:
    "[POST_JHA_UPDATE_TEMPLATE_STATUS] Try to create TEMPLATE Data",
  POST_JHA_UPDATE_TEMPLATE_STATUS_SUCCESS:
    "[POST_JHA_UPDATE_TEMPLATE_STATUS_SUCCESS] Create TEMPLATE Data Successfull",
  POST_JHA_UPDATE_TEMPLATE_STATUS_FAILURE:
    "[POST_JHA_ADD_TEMPLATE_FAILURE] Create TEMPLATE  Data Failed",

  POST_JHA_ADD_CONDUCT: "[POST_JHA_ADD_CONDUCT] Try to create Conduct Jha Data",
  POST_JHA_ADD_CONDUCT_SUCCESS:
    "[POST_JHA_ADD_CONDUCT_SUCCESS] Create Conduct  Jha Data Successfull",
  POST_JHA_ADD_CONDUCT_FAILURE:
    "[POST_JHA_ADD_CONDUCT_FAILURE] Create Conduct Jha  Data Failed",

  GET_JHA_TEMPLETE_DETAILS_PENDING:
    "[JHA_TEMPLETE_DETAILS][REQUEST] Get Jha Templete Details Data",
  GET_JHA_TEMPLETE_DETAILS_SUCCESS:
    "[JHA_TEMPLETE_DETAILS][RESPONSE] Get Jha Templete Details Data Successfull ",
  GET_JHA_TEMPLETE_DETAILS_FAILURE:
    "[JHA_TEMPLETE_DETAILS][RESPONSE] Get Jha Templete Details Data Failed ",
  GET_JHA_CONTROL_TYPE_LIST:
    "[GET_JHA_CONTROL_TYPE_LIST] Get CONTROL List Data",
  GET_JHA_CONTROL_TYPE_LIST_SUCCESS:
    "[GET_JHA_CONTROL_TYPE_LIST_SUCCESS] Get Jha CONTROL List Data Successfull",
  GET_JHA_CONTROL_TYPE_LIST_FAILURE:
    "[GET_JHA_CONTROL_TYPE_LIST_FAILURE] Get Jha CONTROL List Data Failed",

  GET_JHA_CONTROL_DETAILS: "[GET_JHA_CONTROL_DETAILS] Get CONTROL List Data",
  GET_JHA_CONTROL_DETAILS_SUCCESS:
    "[GET_JHA_CONTROL_DETAILS_SUCCESS] Get Jha CONTROL List Data Successfull",
  GET_JHA_CONTROL_DETAILS_FAILURE:
    "[GET_JHA_CONTROL_DETAILS_FAILURE] Get Jha CONTROL List Data Failed",

  GET_JHA_CONDUCT_DETAILS:
    "[GET_JHA_CONDUCT_DETAILS] Get Conduct List Details Data",
  GET_JHA_CONDUCT_DETAILS_SUCCESS:
    "[GET_JHA_CONDUCT_DETAILS_SUCCESS] Get Jha Conduct List Details Data Successfull",
  GET_JHA_CONDUCT_DETAILS_FAILURE:
    "[GET_JHA_CONDUCT_DETAILS_FAILURE] Get Jha Conduct Details List Data Failed",

  UPDATE_JHA_TEMPLATE: "[UPDATE_JHA_TEMPLATE] Try to Update TEMPLATE Data",
  UPDATE_JHA_TEMPLATE_SUCCESS:
    "[UPDATE_JHA_TEMPLATE_SUCCESS] Update TEMPLATE Data Successfull",
  UPDATE_JHA_TEMPLATE_FAILURE:
    "[UPDATE_JHA_TEMPLATE_FAILURE] Update TEMPLATE  Data Failed",

  POST_JHA_ADD_SETUP: "[POST_JHA_ADD_SETUP] Try to create SETUP Data",
  POST_JHA_ADD_SETUP_SUCCESS:
    "[POST_JHA_ADD_SETUP_SUCCESS] Create SETUP Data Successfull",
  POST_JHA_ADD_SETUP_FAILURE:
    "[POST_JHA_ADD_SETUP_FAILURE] Create SETUP  Data Failed",

  GET_JHA_SETUP_LIST: "[GET_JHA_SETUP_LIST] Get Setup List Data",
  GET_JHA_SETUP_LIST_SUCCESS:
    "[GET_JHA_SETUP_LIST_SUCCESS] Get Jha Setup List Data Successfull",
  GET_JHA_SETUP_LIST_FAILURE:
    "[GET_JHA_SETUP_LIST_FAILURE] Get Jha Setup List Data Failed",

  POST_JHA_ACTIVE_INACTIVE_SETUP:
    "[POST_JHA_ADD_SETUP] Try to ACTIVE INACTIVE SETUP Data",
  POST_JHA_ACTIVE_INACTIVE_SETUP_SUCCESS:
    "[POST_JHA_ACTIVE_INACTIVE_SETUP_SUCCESS] ACTIVE INACTIVE SETUP Data Successfull",
  POST_JHA_ACTIVE_INACTIVE_SETUP_FAILURE:
    "[POST_JHA_ACTIVE_INACTIVE_SETUP_FAILURE] ACTIVE INACTIVE SETUP  Data Failed",

  GET_JHA_REFERENCE_DATA: "[GET_JHA_REFERENCE_DATA] Get getJHAReference Data",
  GET_JHA_REFERENCE_DATA_SUCCESS:
    "[GET_JHA_REFERENCE_DATA_SUCCESS] Get Jha getJHAReference Data Successfull",
  GET_JHA_REFERENCE_DATA_FAILURE:
    "[GET_JHA_REFERENCE_DATA_FAILURE] Get Jha getJHAReference Data Failed",

  GET_JHA_SETUP_DETAILS: "[GET_JHA_SETUP_DETAILS] Get SETUP List Details Data",
  GET_JHA_SETUP_DETAILS_SUCCESS:
    "[GET_JHA_SETUP_DETAILS_SUCCESS] Get Jha SETUP List Details Data Successfull",
  GET_JHA_SETUP_DETAILS_FAILURE:
    "[GET_JHA_SETUP_DETAILS_FAILURE] Get Jha SETUP Details List Data Failed",

  UPDATE_JHA_SETUP: "[UPDATE_JHA_SETUP] Try to Update SETUP Data",
  UPDATE_JHA_SETUP_SUCCESS:
    "[UPDATE_JHA_SETUP_SUCCESS] Update SETUP Data Successfull",
  UPDATE_JHA_SETUP_FAILURE:
    "[UPDATE_JHA_SETUP_FAILURE] Update SETUP  Data Failed",

  GET_JHA_SETUP_DASHBOARD:
    "[GET_JHA_SETUP_DASHBOARD] Get Jha SETUP_DASHBOARD Data",
  GET_JHA_SETUP_DASHBOARD_SUCCESS:
    "[GET_JHA_SETUP_DASHBOARD_SUCCESS] Get Jha SETUP_DASHBOARD Data Successfull",
  GET_JHA_SETUP_DASHBOARD_FAILURE:
    "[GET_JHA_SETUP_DASHBOARD_FAILURE] Get Jha SETUP_DASHBOARD Data Failed",

  GET_JHA_SAFETYLOG_CONDUCTLIST:
    "[GET_JHA_SAFETYLOG_CONDUCTLIST] Get Jha SAFETYLOG_CONDUCTLIST Data",
  GET_JHA_SAFETYLOG_CONDUCTLIST_SUCCESS:
    "[GET_JHA_SAFETYLOG_CONDUCTLIST_SUCCESS] Get Jha SAFETYLOG_CONDUCTLIST Data Successfull",
    GET_JHA_SAFETYLOG_CONDUCTLIST_EMAIL_SUCCESS:
    "[GET_JHA_SAFETYLOG_CONDUCTLIST_EMAIL_SUCCESS] Get Jha SAFETYLOG_CONDUCTLIST  Email Data Successfull",
  GET_JHA_SAFETYLOG_CONDUCTLIST_FAILURE:
    "[GET_JHA_SAFETYLOG_CONDUCTLIST_FAILURE] Get Jha SAFETYLOG_CONDUCTLIST Data Failed",

  GET_JHA_ACTIVE_TEMPLATE_LIST:
    "[GET_JHA_ACTIVE_TEMPLATE_LIST] Get Active TEMPLATE List Data",
  GET_JHA_ACTIVE_TEMPLATE_LIST_SUCCESS:
    "[GET_JHA_ACTIVE_TEMPLATE_LIST_SUCCESS] Get Jha Active TEMPLATE List Data Successfull",
  GET_JHA_ACTIVE_TEMPLATE_LIST_FAILURE:
    "[GET_JHA_ACTIVE_TEMPLATE_LIST_FAILURE] Get Jha Active TEMPLATE List Data Failed",
};
