import { Grid } from "@mui/material";
import "./terms.scss";
import { logos } from "../../assets";
import { useThemeContext } from "../../context";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { DARK } from "../../constant/constant";
import { useEffect } from "react";
const Terms = () => {

  const { mode, toggleTheme } = useThemeContext();
  useEffect(() => {
    document.body.className = mode === "light" ? "light-mode" : "dark-mode";
  }, [mode]);

  return (
    <div className="overirdeTerms">
       <Grid item md={1} xs={1} sm={1} className="themeToggle">
       {
            <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
              {mode === DARK ? (
                <Brightness7Icon className="iconWhite" />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          }
          </Grid>
      <Grid container className="logoGrid">
        <Grid>
              {mode === DARK ? (
                <img
                  src={logos?.logoWhite}
                  width="250px"
                  className="logo"
                  alt="no logo"
                />
              ) : (
                <img
                  src={logos?.logo}
                  width="250px"
                  className="logo"
                  alt="no logo"
                />
              )}
            </Grid>
      </Grid>
      <Grid container className="container">
        <Grid mt={1} mb={2} className="flex">
          <h1 className="name">Sequence |</h1>{" "}
          <h1 className="service"> Terms of Service</h1>
        </Grid>
        <Grid
          justifyContent={"center"}
          alignItems="center"
          className="termsGrid"
        >
          <div className="termsHeader">
            <h1>
              HB NEXT Sequence
              <sup className="sup">SM</sup>
              Platform
            </h1>
            <h1>TERMS OF SERVICE</h1>
          </div>
          <Grid className="flex" item container lg={10}>
            <ol style={{ listStyleType: "decimal" }}>
              <li>
                <span>Acceptance of Terms. HB NEXT</span> grants you access to
                the Sequence
                <sup className="sup">SM</sup>
                application, subject to these Terms of Service, as updated and
                amended here from time to time. By accessing and using Sequence
                <sup className="sup">SM</sup>, you accept and agree to be bound
                by the terms and provisions of these Terms of Service. Continued
                use of the Sequence
                <sup className="sup">SM</sup>
                software by you shall be deemed acceptance by you of any updates
                and amendments. You accept these Terms by your Sign On
                Registration each time you use Sequence
                <sup className="sup">SM</sup>.
              </li>
              <li>
                <span>
                  Description of Sequence
                  <sup className="sup">SM</sup>.
                </span>
                <span> HB NEXT</span> provides users with access to Sequence
                <sup className="sup">SM</sup>. Unless explicitly stated
                otherwise, any new features that modify or add to Sequence
                <sup className="sup">SM</sup> are subject to these Terms of
                Service. You understand and agree that Sequence
                <sup className="sup">SM</sup> is provided "as-is" and that{" "}
                <sup className="sup">SM</sup> assumes no responsibility for the
                timeliness, deletion, misdelivery or failure to store any user
                communications or personalization settings.
              </li>
              <li>
                <span>Grant of Limited License.</span>
                <span> HB NEXT</span> grants you a personal, limited
                non-transferable, non-exclusive and terminable license and right
                to use Sequence<sup className="sup">SM</sup> in accordance with
                these Terms of Service. You may not transfer, assign or share
                this license. No agent acting under power of attorney may act on
                your behalf. There is no Right of Survivorship, and any rights
                to your username, password, or data or information terminate
                upon your death.
              </li>
              <li>
                <span>
                  Access to Sequence<sup className="sup">SM</sup>.
                </span>
                You agree not to access Sequence
                <sup className="sup">SM</sup>
                by any means other than through the interface that is authorized
                by, or provided by
                <span> HB NEXT</span>. You understand that delivery and
                processing of Sequence<sup className="sup">SM</sup>, including
                your input data, and any generated reports, may involve
                transmissions over networks operated by third parties. You are
                responsible for obtaining access to Sequence
                <sup className="sup">SM</sup>. Access may involve third-party
                fees, including, but not limited to Internet Service Provider,
                airtime, and network charges. You are responsible for those
                fees. In addition, you must, at your own expense, provide all
                computer hardware and other equipment necessary to access
                Sequence<sup className="sup">SM</sup>
                through browser software supporting the Secure Socket Layer
                (SSL) protocol or other protocols accepted by
                <span> HB NEXT</span>. You are responsible for any applicable
                hardware or software purchases or upgrades, or fixes or
                enhancements to any hardware or software, which may be required
                to enable access to and use of Sequence
                <sup className="sup">SM</sup>. You must comply with{" "}
                <span>HB NEXT</span> requirements for access to equipment and/or
                mode of access to and/or use of Sequence
                <sup className="sup">SM</sup>.
              </li>
              <li>
                <span>Registration Access and Security.</span> Your access to
                Sequence<sup className="sup">SM</sup> is authorized only through
                the interface authorized by
                <span> HB NEXT</span>. You agree to comply with
                <span> HB NEXT</span> requirements for registration including
                user names and passwords. You agree and understand that you are
                responsible for maintaining the confidentiality of passwords
                associated with any account you use to access Sequence
                <sup className="sup">SM</sup>. You agree to ensure that you exit
                from your account at the end of each session. You are
                responsible for notifying
                <span> HB NEXT</span> of the theft, loss or other unauthorized
                use of a user name, password, and/or any other identifying
                characteristics that may have been issued or authorized by{" "}
                <span>HB NEXT</span>.<span> HB NEXT</span> agrees to exercise
                reasonable care to prevent unauthorized use or access to user
                name, password, and/or any other identifying characteristics or
                billing and payment information. Security may be maintained by
                <span> HB NEXT</span> through the use of data encryption, data
                security protocols, passwords and/or other methods and
                procedures.
              </li>
              <li>
                <span>Confidentiality of Data and Information.</span>
                <span> HB NEXT</span> will not disclose any data or other
                information about you, or your account, or data or information
                belonging to you unless compelled to do so by law, or if there
                is a reasonable basis to believe that there is illegal activity,
                an emergency or to investigate a breach of the system by you. If
                disclosure is demanded of
                <span> HB NEXT</span>, then <span>HB NEXT</span> will make a
                reasonable effort to notify you to allow you the opportunity to
                oppose disclosure.
              </li>
              <li>
                <span>
                  Confidentiality for Sequence
                  <sup className="sup">SM</sup>.
                </span>
                Confidential Information means any trade secret or other
                confidential information, including but not limited to any
                version of the Sequence<sup className="sup">SM</sup>, general
                business workflows, page or report design/workflows, specific
                company data within a Sequence
                <sup className="sup">SM</sup>
                deployment, etc., which ownership resides with
                <span> HB NEXT</span>. You agree to protect all Confidential
                Information using at least the same degree of care that you use
                to protect your own confidential information, however not less
                than a reasonable degree of care. You agree to use Confidential
                Information solely for the purpose of utilizing Sequence
                <sup className="sup">SM</sup>
                to manage specific aspects of your business and related data or
                your authorized client’s business and related data. You further
                agree that you have not or will not use the Confidential
                Information or Intellectual Property contained in the Sequence
                <sup className="sup">SM</sup> platform for any other purpose
                including but not limited to, building a software platform to
                perform similar data and other business management services
                without the expressed written permission of <span>HB NEXT</span>
                .<br />
                <br />
                Furthermore, you agree not to make the Sequence
                <sup className="sup">SM</sup>
                platform available to a third party without the expressed
                written permission of <span>HB NEXT</span> and to take all
                reasonable measures to ensure that any Confidential Information
                is not divulged, disclosed or otherwise furnished, directly or
                indirectly, to any third party. Your confidentiality obligation
                shall not apply to Confidential Information which you can show:
                (i) is already known by you when received; (ii) is or has
                becomes public knowledge other than by breach of this agreement;
                (iii) is received from a third party who lawfully acquired it
                and who is under no obligation restricting its disclosure; or
                (iv) is to be made publicly available due to a court order, a
                decision by a public body or as otherwise required by mandatory
                law. You agree to impose on your employees and consultants if
                applicable, in an appropriate manner, the obligations regarding
                the use of the Sequence<sup className="sup">SM</sup>
                platform and the confidentiality set forth under these terms of
                service. If applicable, you shall be liable by
                <span> HB NEXT</span> for your employees’ and consultants’
                actions and for their observance of the above stated provisions.
                Your obligations of confidentiality shall be valid during the
                term of the original EULA and these Terms of Service and
                continue indefinitely, regardless of the reason therefore.
              </li>
              <li>
                <span>Liability for Unauthorized Use or Access.</span> You are
                entirely responsible for all data or information that you
                upload, transmit or otherwise make available via Sequence
                <sup className="sup">SM</sup>. You acknowledge and agree that
                <span> HB NEXT</span> shall not be liable for the accuracy,
                truthfulness or validity of any data or information entered by
                you or on your behalf through Sequence
                <sup className="sup">SM</sup>. You agree to take reasonable
                steps to insure the security of data and other information
                entered into the system.
                <span> HB NEXT</span> is not liable for any damages incurred by
                you, or any third party in connection with or potentially
                resulting from unauthorized access, or from disclosure of
                information or data resulting from your actions or your failure
                to act. <span>HB NEXT</span> is not liable for the failure of
                any electronic or other security measures.
                <span>HB NEXT</span> is not responsible for any compromise of
                data transmitted across computer networks or telecommunications
                facilities, including, but not limited to the Internet.{" "}
                <span>HB NEXT</span> exercises no control whatsoever over the
                data or information transmitted through Sequence
                <sup className="sup">SM</sup>.
              </li>
              <li>
                <span>Restrictions.</span> You agree not to a) use Sequence
                <sup className="sup">SM</sup> to transmit or otherwise make
                available any data or material that you do not have a right to
                make available under any law, applicable regulation, or
                contractual or fiduciary capacity such as proprietary and
                confidential information, or under nondisclosure agreements; b)
                interfere with or disrupt Sequence
                <sup className="sup">SM</sup> or servers or networks connected
                to Sequence<sup className="sup">SM</sup>, or disobey any
                requirements, procedures, policies or regulations of networks
                connected to Sequence
                <sup className="sup">SM</sup>.
              </li>
              <li>
                <span>Interstate Data Transmissions.</span> In using Sequence
                <sup className="sup">SM</sup> to send electronic communications,
                including but not limited to data entry, email, search queries,
                uploading or downloading photos, files and data, and other
                Internet activities, you acknowledge you are causing
                communications to be sent through
                <span> HB NEXT</span>'s computer networks, portions of which are
                located in different states in the United States. For technical
                reasons, communications that appear to be intrastate can result
                in the transmission of interstate communications regardless of
                where you are physically located at the time of transmission.
                You therefore acknowledge that use of the Sequence
                <sup className="sup">SM</sup>
                results in interstate data transmissions.
              </li>
              <li>
                <span>Export and Import Compliance.</span> You agree to comply
                with all applicable export and import laws and regulations. You
                are not a party identified on any government export exclusion
                list, including, nor will you transfer software, technology, and
                other technical data via Sequence
                <sup className="sup">SM</sup> to parties so identified.
              </li>
              <li>
                <span>Indemnity.</span> You agree to indemnify and hold
                <span> HB NEXT</span> and its subsidiaries, affiliates,
                officers, agents, employees, partners and licensors harmless
                from any claim or demand, including reasonable attorneys' fees,
                made by any third party due to or arising out of data or
                information you submit, transmit, modify or otherwise make
                available through Sequence<sup className="sup">SM</sup>, your
                use of Sequence
                <sup className="sup">SM</sup>, your connection to Sequence
                <sup className="sup">SM</sup>, your violation of these Terms of
                Service, or your violation of any rights of another.
              </li>
              <li>
                <span>General Administration.</span> You acknowledge that
                <span> HB NEXT</span> may establish general practices and limits
                concerning use of Sequence<sup className="sup">SM</sup>,
                including without limitation the period of time that data or
                information will be retained by Sequence
                <sup className="sup">SM</sup>, the maximum disk space that will
                be allotted on
                <span> HB NEXT</span>'s servers on your behalf, and the maximum
                number of times, and the maximum duration for which you may
                access Sequence<sup className="sup">SM</sup> in a given period
                of time. Upon notice of a date after which data or information
                will no longer be retained by Sequence
                <sup className="sup">SM</sup>, you agree that{" "}
                <span> HB NEXT</span> has no responsibility or liability for the
                deletion or failure to store any data or information maintained
                or transmitted by Sequence<sup className="sup">SM</sup>. You
                acknowledge that <span>HB NEXT</span> reserves the right to log
                off accounts that are inactive for an extended period of time.
                You further acknowledge that <span>HB NEXT</span> reserves the
                right to modify these general practices and limits from time to
                time.
              </li>
              <li>
                <span>Discontinuation or Modifications.</span>
                <span> HB NEXT</span> reserves the right at any time, and from
                time to time to modify or discontinue, temporarily or
                permanently, the form or function of Sequence
                <sup className="sup">SM</sup>
                with or without notice. You agree that
                <span> HB NEXT</span> shall not be liable to you or to any third
                party for any modification, suspension or discontinuance of
                Sequence<sup className="sup">SM</sup>.
              </li>
              <li>
                <span>Suspension and Termination.</span> You may terminate your{" "}
                <span> HB NEXT</span> account, and access to Sequence
                <sup className="sup">SM</sup> by submitting a termination
                request to <span>HB NEXT</span>.<span> HB NEXT</span> may suspend
                access to use of Sequence<sup className="sup">SM</sup> in the
                event of breach of this Terms of Service. Suspension will
                continue until the breach has been remedied to the reasonable
                satisfaction of <span>HB NEXT</span>. You agree that
                <span> HB NEXT</span> may, without prior notice, immediately
                terminate, limit your access to or suspend your account, and
                access to Sequence<sup className="sup">SM</sup>
                for a) breaches or violations of these Terms of Service, b)
                requests by law enforcement or other government agencies, c)
                discontinuance or material modification to Sequence
                <sup className="sup">SM</sup>, d) unexpected technical or
                security issues or problems, e) engagement by you in fraudulent
                or illegal activities, and/or for nonpayment of any fees owed by
                you in connection with Sequence
                <sup className="sup">SM</sup>. Further, you agree that all
                terminations, limitations of access and suspensions for cause
                shall be made in
                <span> HB NEXT</span>'s sole discretion and that
                <span> HB NEXT</span> shall not be liable to you or any third
                party for any termination of your account, or access to Sequence
                <sup className="sup">SM</sup>.
              </li>
              <li>
                <span>Intellectual & Other Proprietary Rights.</span> All
                ownership and intellectual property rights in Sequence
                <sup className="sup">SM</sup>
                and any copies and derivative works thereof (regardless of form
                or media in or on which the original or other copies may exist),
                including but not limited to patents, platform and other design
                rights, copyrights, trade marks, trade-secrets and proprietary
                know-how, shall be owned by and vested to
                <span> HB NEXT</span>. Nothing in EULA or these Terms of Service
                shall constitute or be interpreted as a transfer of such rights
                from <span>HB NEXT</span> to you. You are solely entitled to the
                limited license to the in Sequence
                <sup className="sup">SM</sup>
                platform specifically granted through the EULA and these Terms
                of Service. You acknowledge that the structure and code of the
                in Sequence<sup className="sup">SM</sup> platform valuable trade
                secrets of <span>HB NEXT</span> and which shall remain the sole
                property of <span>HB NEXT</span>. You acknowledge and agree that
                Sequence
                <sup className="sup">SM</sup>
                and any necessary software used in connection with Sequence
                <sup className="sup">SM</sup>, including any software that you
                may download in connection with the use of Sequence
                <sup className="sup">SM</sup>, contains proprietary and
                confidential information protected by applicable intellectual
                property and other laws of the United States and foreign
                countries. You agree not to distribute or transfer in any
                manner, whether for fee or not, any portion of Sequence
                <sup className="sup">SM</sup> for any purpose not specifically
                authorized by <span>HB NEXT</span>.
                <u>
                  You also agree that you have not previously nor will you copy,
                  reverse engineer, reverse assemble, disassemble, decompile,
                  modify or change Sequence<sup className="sup">SM</sup>
                  software in any manner or for any reason, use modified
                  versions of Sequence<sup className="sup">SM</sup>
                  software, attempt to determine the source code for Sequence
                  <sup className="sup">SM</sup>, or create derivative works in
                  order to mimic the functionality, design or workflows
                  contained within the Sequence
                  <sup className="sup">SM</sup>
                  software platform. Additionally, you shall not authorize or
                  otherwise enable any third party to do what you are proscribed
                  from doing under this Agreement. You further acknowledge that
                  any infringement on or of these rights would potentially cause
                  irreparable harm to
                  <span> HB NEXT</span>.
                </u>
              </li>
              <li>
                <span>Disclaimer of Warranties.</span> You expressly acknowledge
                and agree that use of Sequence
                <sup className="sup">SM</sup>
                is at your sole risk. Sequence
                <sup className="sup">SM</sup>
                is provided "as is" and "as available". You have examined,
                reviewed, studied and/or tested Sequence
                <sup className="sup">SM</sup>, and have determined its
                usefulness and applicability for yourself. <span>HB NEXT</span>{" "}
                expressly disclaims all <sup className="sup">SM</sup> of any
                kind, whether express or implied, including but not limited to
                any implied <sup className="sup">SM</sup> of merchantability,
                fitness for a particular purpose and non-infringement. You also
                acknowledge that advice or information obtained from
                <span> HB NEXT</span> either verbally or in writing or through
                the system shall not create any warranty which is not expressly
                stated in this Agreement. Sequence
                <sup className="sup">SM</sup>
                does not provide accounting, financial or legal advice, and none
                shall be deemed to have been provided or otherwise inferred or
                relied upon.
              </li>
              <li>
                <span>No Third-Party Beneficiaries.</span> You agree that,
                except as otherwise expressly provided in this Terms of Service,
                there are no third-party beneficiaries to this agreement.
              </li>
              <li>
                <span>Trademarks and Service Marks.</span> Any and all symbols,
                trademarks and Service marks adopted by
                <span> HB NEXT</span> to identify Sequence
                <sup className="sup">SM</sup>
                belong to <span>HB NEXT</span>. You have no rights in them
                except as expressly set forth in this Agreement, and may not use
                them in any manner without <span>HB NEXT</span>'s express prior
                written permission. You shall not remove, efface or obscure any
                copyright notices or other proprietary notices or legends from
                any <span>HB NEXT</span> materials. If for any reason, you
                distribute any Sequence
                <sup className="sup">SM</sup>
                materials, documentation, associated brochures, packaging and
                advertising, you shall display appropriate marks in accordance
                with <span>HB NEXT</span>'s reasonable instruction, and only in
                a form reasonably approved by
                <span> HB NEXT</span>. Upon <span>HB NEXT</span>'s reasonable
                request, samples of materials that may be distributed by you
                displaying the Trademarks shall be submitted to{" "}
                <span>HB NEXT</span> for review and approval.
              </li>
            </ol>
          </Grid>
        </Grid>
        <Grid className="copyrightGrid">
          <span>Copyright 2022 HB NEXT. All Rights Reserved.</span>
        </Grid>
      </Grid>
    </div>
  );
};
export default Terms;
