import React, { useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./reports.scss";
import GridTable from "../../../library/custom/gridTable/GridTable";
import { Grid } from "@mui/material";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import SettingTable from "../../../../src/feature/incidents/incidentList/SettingTable";
import NORow from "../../../../src/library/custom/noRow/noRow";
import { TableLoader } from "../../../../src/library/common/Loader";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import { convertUTCtoLocalTimeZone } from "../../../utils/helper";

function ReportCredentialView({
  dataBack,
  getConfiguredCredentialReportData,
  isLoading,
  getConfiguredCredentialReports,
  id,
  credentialTabId,
}) {
  const data = getConfiguredCredentialReportData?.data?.result;
  const [page, setPage] = useState(1);

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      userId: id,
      groupId: 0,
      orgId: ORG_ID(),
      tabId: credentialTabId,
      startDate: "",
      endDate: "",
    };
    getConfiguredCredentialReports(data);
  };

  const usercolumns = [
    { field: "credentialName", headerName: "Credential Name", width: 150 },
    { field: "credentialNameId", headerName: "Credential Number.", width: 150 },
    { field: "passingDate", headerName: "Passing Date", width: 200 },
    { field: "expirationDate", headerName: "Expiration Date", flex: 4 },
    { field: "issuingBody", headerName: "Issuing Body", flex: 3 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={
            getConfiguredCredentialReportData?.data?.result?.noOfRecords ?? 0
          }
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            getConfiguredCredentialReportData?.data?.result?.credentialData ??
            []
          }
          fileNameXL={"CredentialsByUser-list"}
        />
      ),
    },
  ];

  return (
    <>
      <div className="viewOverideContact">
        <div className="commonOveride">
          <Typography className="titleDetail">{data?.name}</Typography>
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Box className="columns">
                  <Typography className="listView">
                    Job Title:
                    <span className="font-w400">{data?.designation}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Division:
                    <span className="font-w400">{data?.division}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Added on:
                    <span className="font-w400">{convertUTCtoLocalTimeZone(data?.createdOn)}</span>
                  </Typography>
                </Box>
              </Box>)
            </Box>
          </Box>
        </div>
      </div>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Credential List</div>
        </div>
        <Grid mt={3}>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.id}
                rows={
                  getConfiguredCredentialReportData?.data?.result
                    ?.credentialData ?? []
                }
                rowCount={
                  getConfiguredCredentialReportData?.data?.result
                    ?.noOfRecords ?? 0
                }
                columns={usercolumns}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </Grid>
      </section>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>
    </>
  );
}

export default ReportCredentialView;
