import React, { useState } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import commonImages from "../../../../assets";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  USER_ID,
} from "../../../../constant/constant";
import { SW_QUESTION_RESPONSE_TYPE } from "../../../../constant/storm-water/constant";

const AddNewCategory = (props) => {
  const {
    getSubCategoriesList,
    cancelCategory,
    cancelSubCategory,
    singleCategoryes,
    addSubCategory,
    handleOnChangeCategoryForm,
    handleQuestionCheck,
    onSubCategorySubmit,
    handleQuestionAdd,
    handleQuestionOptionAdd,
    submitQuestionData,
    selectSubCategory,
    selectSubCategoryMsg,
    selectSubACategoryMsg,
    selectSubACategory,
  } = props;

  const [addSubCategoryBox, setAddSubCategoryBox] = useState(false);
  const handleOnclickAddSubCategory = () => {
    setAddSubCategoryBox(!addSubCategoryBox);
  };

  const intialState = {
    subCategoriesValue: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isSubCategoryName, setIsSubCategoryName] = useState(false);
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  function mappedData() {
    let errors = false;
    if (formValue?.subCategoriesValue.trim() === "") {
      setFormValue({
        ...formValue,
        subCategoriesValue: "",
      });
      seterrorsMsgShowActive(false);
      setIsSubCategoryName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = getSubCategoriesList?.findIndex(
      (obect) =>
        obect.value?.trim().toUpperCase() ===
        formValue?.subCategoriesValue?.trim().toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const list = [
        {
          value: formValue?.subCategoriesValue,
        },
      ];
      return { list, userId: parseInt(USER_ID()) };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Sub Category Name already added");
    }
  }

  const submitSubCategoryData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setFormValue({
        ...formValue,
        subCategoriesValue: "",
      });
      onSubCategorySubmit(data);
    }
  };

  return (
    <>
      <Grid container item>
        <Grid container item mt={3}>
          <Grid md={12} item className="categoryTitle">
            <Grid>{singleCategoryes?.value}</Grid>
            <Grid
              className="close"
              onClick={() => cancelCategory(singleCategoryes)}
            >
              X
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={6} item mt={3} px={3}>
              <FormControl fullWidth>
                <FormLabels label="Add Sub Category" />
                <SelectMenu
                  listData={getSubCategoriesList ?? []}
                  value={singleCategoryes?.subCategorySetValue ?? ""}
                  id="subCategorySetValue"
                  onchangehandler={(e) =>
                    handleOnChangeCategoryForm(
                      e,
                      singleCategoryes,
                      null,
                      "subCategorySetValue"
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={2}
              xs={3}
              ml={2}
              mt={7}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                onClick={() => addSubCategory(singleCategoryes)}
              ></Buttons>
            </Grid>
            <Grid
              item
              lg={3}
              md={2}
              xs={3}
              ml={1}
              mt={7}
              className="variantOutlineBorderSmall"
            >
              <Buttons
                aria-describedby="dd"
                variant="variantOutlineBorder"
                type="button"
                name="btn"
                label={"Create New"}
                id="btnAddEquipment"
                variantOutlineBorder={true}
                onClick={handleOnclickAddSubCategory}
                iconToggle={addSubCategoryBox}
              ></Buttons>
            </Grid>
            <Grid item md={12} px={3}>
              {selectSubCategory && (
                <div className="errorMsg">{selectSubCategoryMsg}</div>
              )}
              {selectSubACategory && (
                <div className="errorMsg">{selectSubACategoryMsg}</div>
              )}
            </Grid>
          </Grid>
          {addSubCategoryBox && (
            <Grid item container md={12} className="CategoriesBox">
              <Grid ml={3} item md={5} xs={8} mb={2}>
                <InputField
                  type="text"
                  isRequired={true}
                  value={formValue?.subCategoriesValue}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      subCategoriesValue: e.target.value,
                    })
                  }
                  label="Create Sub Category"
                  placeholder="Create Sub Category Here..."
                  isError={isSubCategoryName}
                  errorMsg={
                    isSubCategoryName ? "Sub Category Name is Required" : ""
                  }
                />
                {errorsMsgShowActive && (
                  <div className="errorMsg">{errorsMsgShow}</div>
                )}
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.6}
                md={3}
                xs={3}
                ml={3}
                mt={4}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                  disabled={false}
                  onClick={submitSubCategoryData}
                ></Buttons>
              </Grid>
            </Grid>
          )}
          {singleCategoryes?.subcategories?.map(
            (singlesubcategories, index) => (
              <Grid
                container
                px={3}
                mt={3}
                key={index}
                id={singlesubcategories?.key}
              >
                <Grid md={12} item className="subCategoryTitle">
                  <Grid>{singlesubcategories?.value}</Grid>
                  <Grid
                    className="close"
                    onClick={() =>
                      cancelSubCategory(singleCategoryes, singlesubcategories)
                    }
                  >
                    X
                  </Grid>
                </Grid>

                <Grid md={12} item className="questionBox">
                  {singlesubcategories?.questions?.map((quesItem) => {
                    return (
                      <>
                        <Grid className="questionItem">
                          <FormControlLabel
                            control={
                              <img
                                className="filterCheckbox"
                                title={
                                  quesItem?.isSelected
                                    ? "tickCheckbox"
                                    : "untickCheckbox"
                                }
                                alt=""
                                src={
                                  quesItem?.isSelected
                                    ? commonImages.check
                                    : commonImages.noCheck
                                }
                                onClick={() =>
                                  handleQuestionCheck(
                                    singleCategoryes,
                                    singlesubcategories,
                                    quesItem
                                  )
                                }
                              />
                            }
                            label={quesItem?.value}
                            className="filterLableCheckbox"
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                {singlesubcategories?.show && (
                  <>
                    <Grid className="addQuestionBox" item md={12}>
                      <Grid className="addQuestionInput" item mt={3} lg={9.8}>
                        <InputField
                          type="text"
                          isRequired={true}
                          value={singlesubcategories?.questionValue}
                          label={false}
                          placeholder="Add Question Text Here..."
                          onChange={(e) =>
                            handleOnChangeCategoryForm(
                              e,
                              singleCategoryes,
                              singlesubcategories,
                              "questionValue"
                            )
                          }
                        />
                      </Grid>

                      <Grid className="smallAddBtn" item lg={1.6} mt={3}>
                        <Buttons
                          aria-describedby="dd"
                          variant="contained"
                          type="button"
                          name="btn"
                          label={"Add"}
                          id="btnAddEquipment"
                          varientAddIconBlue={true}
                          // disabled={isSubCategorySubmit ? true : false}
                          onClick={() =>
                            submitQuestionData(
                              singlesubcategories,
                              singleCategoryes
                            )
                          }
                        ></Buttons>
                      </Grid>
                    </Grid>

                    <Grid className="addQuestionBox" item md={12}>
                      <Grid
                        className="addQuestionInput responseTypeBoxSet"
                        item
                        lg={9.8}
                      >
                        <div className="responseTypeBox">
                          <div className="responseType">Response Type</div>
                          <div className="responseTypeRight">
                            {SW_QUESTION_RESPONSE_TYPE?.map((item) => {
                              return (
                                <div
                                  key={item?.key}
                                  onClick={() =>
                                    handleQuestionOptionAdd(
                                      singleCategoryes,
                                      singlesubcategories,
                                      item?.key
                                    )
                                  }
                                  className={
                                    singlesubcategories?.optionShow ===
                                    item?.key
                                      ? "responseTypeBtn active"
                                      : "responseTypeBtn"
                                  }
                                >
                                  {item?.value}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    {singlesubcategories?.errorTextField ? (
                      <Grid md={12} item>
                        <p className="errorMsg">{"Question Already added."}</p>
                      </Grid>
                    ) : null}

                    {singlesubcategories?.errorQuestionTextField ? (
                      <Grid md={12} item>
                        <p className="errorMsg">{"Please enter Question."}</p>
                      </Grid>
                    ) : null}

                    {singlesubcategories?.errorQuestionOptionTextField ? (
                      <Grid md={12} item>
                        <p className="errorMsg">
                          {"Please Select Response Type added."}
                        </p>
                      </Grid>
                    ) : null}
                  </>
                )}

                {(parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) ||
                  !singlesubcategories?.isGlobalSubCat) && (
                  <Grid container>
                    <Grid
                      item
                      lg={3}
                      md={2}
                      xs={3}
                      ml={1}
                      mt={3}
                      className="variantOutlineBorderSmall"
                    >
                      <Buttons
                        aria-describedby="dd"
                        variant="variantOutlineBorder"
                        type="button"
                        name="btn"
                        label={"Add Question"}
                        id="btnAddEquipment"
                        variantOutlineBorder={true}
                        onClick={() =>
                          handleQuestionAdd(
                            singleCategoryes,
                            singlesubcategories
                          )
                        }
                        iconToggle={singlesubcategories?.show}
                      ></Buttons>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewCategory;
