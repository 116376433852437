import React, { useEffect, useState } from "react";
import SetupCredentialList from "../../../feature/credential/component/setupCredentialList";
import {
  SETUP_ADD_CREDENTIAL,
  DASHBOARD,
  ACCESS_DENIED,
} from "../../../../src/constant/routeContant";
import { useNavigate } from "react-router-dom";
import * as credentialAction from "../../../redux/actions/credentialAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";

export default function SetupCredentialListContainer() {
  const navigate = useNavigate();
  const isAllowed = checkPrivileges([12, 13]);
  const [sendEmail, setSendEmail] = useState(false);

  const NavigateAddCredentialsMethode = () => {
    navigate(SETUP_ADD_CREDENTIAL);
  };

  const defaultData = {
    search: "",
    page: 1,
    organisationId: ORG_ID(),
    pageSize: PAGE_SIZE,
    credentialTypeId: "",
    userId: USER_ID(),
    isSendMail: false,
  };
  const credentialListData = useSelector(
    (state) => state.getCredentialListData
  );

  const dispatch = useDispatch();

  const getCredentials = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(credentialAction.getCredentialList(data));
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    getCredentials(defaultData);
  }, []);

  const credentialTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.credentialTypes
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 8,
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    if (credentialListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Setup credential has been sent to registered email ID");
    }
  }, [credentialListData]);

  return (
    <>
      {checkPrivileges([12, 13]) ? (
        <>
          <ToastContainer />
          <SetupCredentialList
            NavigateAddCredentials={NavigateAddCredentialsMethode}
            credentialListData={credentialListData?.data?.result}
            getCredentials={getCredentials}
            isLoading={credentialListData.isLoading}
            credentialTypes={credentialTypes}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
