import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { GET_ITEM_LOG } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { REPORTS_ANALYTICS_DASHBOARD_ITEMS } from "../../../../constant/routeContant";
import {
  calculatePercentageChange,
  downloadExcel,
  downloadPDF,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";

const TreandingCategories = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const tableRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (props?.trendData?.trendPassData) {
      setData((prevData) => [...prevData, ...props.trendData?.trendPassData]);
    }
  }, [props?.trendData?.trendPassData]);

  const columns = [
    "Trending Categories",
    "Previous Period",
    "Corrective - Current Period",
    "Trends",
    "Previous Period",
    "Kudos - Current Period",
    "Trends",
  ];
  const totals = data.reduce(
    (acc, trend) => {
      acc[0] += trend.correctivePreviousCount || 0;
      acc[1] += trend.correctiveTargetCount || 0;
      acc[2] += trend.kudosPreviousCount || 0;
      acc[3] += trend.kudosTargetCount || 0;
      return acc;
    },
    [0, 0, 0, 0]
  );

  const percentageChangeCorrective = calculatePercentageChange(
    totals[1],
    totals[0]
  );
  const percentageChangeKudos = calculatePercentageChange(totals[3], totals[2]);

  const rows = [
    ...data?.map((trend) => [
      trend.categoryName,
      trend.correctivePreviousCount,
      trend.correctiveTargetCount,
      calculatePercentageChange(
        trend.correctiveTargetCount,
        trend.correctivePreviousCount
      ),
      trend.kudosPreviousCount,
      trend.kudosTargetCount,
      calculatePercentageChange(
        trend.kudosTargetCount,
        trend.kudosPreviousCount
      ),
    ]),
    [
      "Total",
      totals[0],
      totals[1],
      percentageChangeCorrective !== null ? percentageChangeCorrective : "-",
      totals[2],
      totals[3],
      percentageChangeKudos !== null ? percentageChangeKudos : "-",
    ],
  ];

  const handleOnClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        categoryId: id,
        drillDetail: props.trendData,
        itemLog: GET_ITEM_LOG.Corrective,
        itemTypeValue: "Corrective",
      },
    });
  };

  const handleOnClickKudos = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        categoryId: id,
        drillDetail: props.trendData,
        itemLog: GET_ITEM_LOG.Kudos,
        itemTypeValue: "Kudos",
      },
    });
  };

  const handleOnPreClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        categoryId: id,
        drillDetail: props.trendData,
        itemLog: GET_ITEM_LOG.Corrective,
        itemTypeValue: "Corrective",
        previous: "previous",
      },
    });
  };

  const handleOnClickPreKudos = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        categoryId: id,
        drillDetail: props.trendData,
        itemLog: GET_ITEM_LOG.Kudos,
        itemTypeValue: "Kudos",
        previous: "previous",
      },
    });
  };

  const renderRows = () => {
    const dataRows = data?.map((trend) => (
      <tr className="row customRowColor" key={trend.categoryId}>
        <td>{trend.categoryName}</td>

        <td className="currectiveBg">
          <span
            className="link"
            onClick={() => handleOnPreClick(trend?.categoryId)}
          >
            {trend.correctivePreviousCount}
          </span>
        </td>
        <td className="currectiveBg">
          <span
            className="link"
            onClick={() => handleOnClick(trend?.categoryId)}
          >
            {trend.correctiveTargetCount}
          </span>
        </td>
        <td className="currectiveBg">
          <span
            className={`persantage ${
              parseInt(
                calculatePercentageChange(
                  trend.correctiveTargetCount,
                  trend.correctivePreviousCount
                )
              ) < 0
                ? "plus"
                : parseInt(
                    calculatePercentageChange(
                      trend.correctiveTargetCount,
                      trend.correctivePreviousCount
                    )
                  ) > 0
                ? "minus"
                : ""
            }`}
          >
            {`${calculatePercentageChange(
              trend.correctiveTargetCount,
              trend.correctivePreviousCount
            )}`}
          </span>
        </td>
        <td className="kudosBg">
          <span
            className="link"
            onClick={() => handleOnClickPreKudos(trend?.categoryId)}
          >
            {trend.kudosPreviousCount}
          </span>
        </td>
        <td className="kudosBg">
          <span
            className="link"
            onClick={() => handleOnClickKudos(trend?.categoryId)}
          >
            {trend.kudosTargetCount}
          </span>
        </td>

        <td className="kudosBg">
          <span
            className={`persantage ${
              parseInt(
                calculatePercentageChange(
                  trend.kudosTargetCount,
                  trend.kudosPreviousCount
                )
              ) < 0
                ? "minus"
                : parseInt(
                    calculatePercentageChange(
                      trend.kudosTargetCount,
                      trend.kudosPreviousCount
                    )
                  ) > 0
                ? "plus"
                : ""
            }`}
          >
            {`${calculatePercentageChange(
              trend.kudosTargetCount,
              trend.kudosPreviousCount
            )}`}
          </span>
        </td>
      </tr>
    ));

    const totalsRow = (
      <tr className="row customRowColor totalsRow">
        <td>Total</td>
        <td className="currectiveBg">{totals[0]}</td>
        <td className="currectiveBg">{totals[1]}</td>
        <td className="currectiveBg">
          <span
            className={`persantage ${
              parseInt(percentageChangeCorrective) < 0
                ? "plus"
                : parseInt(percentageChangeCorrective) > 0
                ? "minus"
                : ""
            }`}
          >
            {percentageChangeCorrective !== null
              ? `${percentageChangeCorrective}`
              : "-"}
          </span>
        </td>
        <td className="kudosBg">{totals[2]}</td>
        <td className="kudosBg">{totals[3]}</td>
        <td className="kudosBg">
          <span
            className={`persantage ${
              parseInt(percentageChangeKudos) < 0
                ? "minus"
                : parseInt(percentageChangeKudos) > 0
                ? "plus"
                : ""
            }`}
          >
            {percentageChangeKudos !== null ? `${percentageChangeKudos}` : "-"}
          </span>
        </td>
      </tr>
    );

    return (
      <>
        {dataRows}
        {totalsRow}
      </>
    );
  };

  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">Trending Categories</div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(rows, columns, "Trending-Categories.pdf")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M5.5 8.5H6.5V7H7C7.28333 7 7.52083 6.90417 7.7125 6.7125C7.90417 6.52083 8 6.28333 8 6V5.5C8 5.21667 7.90417 4.97917 7.7125 4.7875C7.52083 4.59583 7.28333 4.5 7 4.5H5.5V8.5ZM6.5 6V5.5H7V6H6.5ZM8.5 8.5H10C10.2833 8.5 10.5208 8.40417 10.7125 8.2125C10.9042 8.02083 11 7.78333 11 7.5V5.5C11 5.21667 10.9042 4.97917 10.7125 4.7875C10.5208 4.59583 10.2833 4.5 10 4.5H8.5V8.5ZM9.5 7.5V5.5H10V7.5H9.5ZM11.5 8.5H12.5V7H13.5V6H12.5V5.5H13.5V4.5H11.5V8.5ZM4.5 13C4.0875 13 3.73438 12.8531 3.44062 12.5594C3.14687 12.2656 3 11.9125 3 11.5V1.5C3 1.0875 3.14687 0.734376 3.44062 0.440626C3.73438 0.146876 4.0875 0 4.5 0H14.5C14.9125 0 15.2656 0.146876 15.5594 0.440626C15.8531 0.734376 16 1.0875 16 1.5V11.5C16 11.9125 15.8531 12.2656 15.5594 12.5594C15.2656 12.8531 14.9125 13 14.5 13H4.5ZM1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3H1.5V14.5H13V16H1.5Z"
                        fill="none"
                      />
                    </svg>
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadExcel(rows, "Trending-Categories.xlsx")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6635_4490)">
                        <path
                          d="M9.22 0.0100392L0.26 1.71004C0.10875 1.73879 0 1.87629 0 2.03004V13.97C0 14.1238 0.10875 14.2613 0.26 14.29L9.22 15.99C9.24 15.9938 9.26 16 9.28 16C9.35375 16 9.4225 15.9775 9.48 15.93C9.55375 15.8688 9.6 15.775 9.6 15.68V0.320039C9.6 0.225039 9.55375 0.131289 9.48 0.0700392C9.40625 0.0087892 9.31375 -0.00746081 9.22 0.0100392ZM10.24 1.92004V4.16004H10.88V4.80004H10.24V6.40004H10.88V7.04004H10.24V8.64004H10.88V9.28004H10.24V11.2H10.88V11.84H10.24V14.08H15.04C15.3925 14.08 15.68 13.7925 15.68 13.44V2.56004C15.68 2.20754 15.3925 1.92004 15.04 1.92004H10.24ZM11.52 4.16004H14.08V4.80004H11.52V4.16004ZM2.14 5.02004H3.78L4.64 6.81004C4.7075 6.95129 4.7675 7.12504 4.82 7.32004H4.83C4.86375 7.20379 4.92875 7.02129 5.02 6.79004L5.97 5.02004H7.47L5.68 7.98004L7.52 11H5.93L4.89 9.05004C4.85125 8.97754 4.81125 8.84379 4.77 8.65004H4.76C4.74 8.74129 4.6925 8.88379 4.62 9.07004L3.58 11H1.98L3.89 8.01004L2.14 5.02004ZM11.52 6.40004H14.08V7.04004H11.52V6.40004ZM11.52 8.64004H14.08V9.28004H11.52V8.64004ZM11.52 11.2H14.08V11.84H11.52V11.2Z"
                          fill="none"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6635_4490">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Download .xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="customTableWrapper">
        <div className="tableContainer autoHeight borderNone">
          <table>
            <thead>
              <tr className="column bgNone">
                <th className="minwithTreading"></th>
                <th className="corrective">Corrective</th>
                <th></th>
                <th></th>
                <th className="kudos">Kudos</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div className="customTableWrapper">
        <Table
          columns={() => (
            <tr className="column">
              <th className="minwithTreading">Category Name</th>
              <th className="currectiveBg">Previous Period</th>
              <th className="currectiveBg">Current Period</th>
              <th className="currectiveBg">Trends</th>
              <th className="kudosBg">Previous Period</th>
              <th className="kudosBg">Current Period</th>
              <th className="kudosBg">Trends</th>
            </tr>
          )}
          rows={renderRows()}
          tableRef={tableRef}
          className={"autoHeight"}
        />
      </div>
    </>
  );
};

export default TreandingCategories;
