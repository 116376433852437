import React from "react";
import {
  CATEGORYCARD,
  LOGIN_ORG_ID,
  ORG_ID,
} from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import {
  checkPrivileges,
  showRighSideMenu,
} from "../../../../utils/rolesHelper";

const Utilitydashboard = ({ onClick }) => {
  const showMenu = showRighSideMenu();
  console.log(showMenu);
  return (
    <>
      <Grid container className="CardListContaner">
        {showMenu?.isSafety &&
          checkPrivileges([12, 1, 2]) && ( //incident
            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={CATEGORYCARD[0]}
            />
          )}
        {checkPrivileges([12, 11, 90]) && ( //lms
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[1]}
          />
        )}
        {checkPrivileges([12, 73, 74]) && ( // resource
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[2]}
          />
        )}
        {checkPrivileges([12, 7]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[3]}
          />
        )}
        {checkPrivileges([12, 64, 43, 44, 78, 52]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[4]}
          />
        )}
        {checkPrivileges([12, 13]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[5]}
          />
        )}
        {showMenu?.isSafety && checkPrivileges([12, 37]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[6]}
          />
        )}
        {showMenu?.isSafety && checkPrivileges([12, 13]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[7]}
          />
        )}
        {showMenu?.isSW && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[8]}
          />
        )}
        {showMenu?.isSW && LOGIN_ORG_ID() == 1 && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CATEGORYCARD[9]}
          />
        )}

        {checkPrivileges([12]) &&
          parseInt(ORG_ID()) === 1 && ( //lms mapping
            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={CATEGORYCARD[10]}
            />
          )}
      </Grid>
    </>
  );
};

export default Utilitydashboard;
