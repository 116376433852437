import {
  equipmentsType,
  deleteEquipmentType,
  getEquipmentDetailType,
  postEquipmentUpdateType,
  clearEqiupmentType,
} from "../constants/equipmentType";

export const getEquipmentList = (data) => ({
  type: equipmentsType.GET_EQUIPMENT_LIST,
  data,
});

export const getEquipmentListSuccess = (data) => ({
  type: equipmentsType.GET_EQUIPMENT_LIST_SUCCESS,
  data,
});

export const getEquipmentListEmailSuccess = () => ({
  type: equipmentsType.GET_EQUIPMENT_LIST_SUCCESS,
});

export const getEquipmentListFailure = (error) => ({
  type: equipmentsType.GET_EQUIPMENT_LIST_FAILURE,
  error,
});

export const postCreateEquipment = (data) => ({
  type: equipmentsType.POST_CREATE_EQUIPMENT,
  data,
});

export const postCreateEquipmentSuccess = (data) => ({
  type: equipmentsType.POST_CREATE_EQUIPMENT_SUCCESS,
  data,
});

export const postCreateEquipmentFailure = (error) => ({
  type: equipmentsType.POST_CREATE_EQUIPMENT_FAILURE,
  error,
});

// deleteEquipment action
export const deleteEquipmentAction = (payload) => ({
  type: deleteEquipmentType.DELETE_EQUIPMENT_PENDING,
  payload: payload,
});

export const deleteEquipmentSuccess = (payload) => ({
  type: deleteEquipmentType.DELETE_EQUIPMENT_SUCCESS,
  payload: payload,
});

export const deleteEquipmentFailure = (error) => ({
  type: deleteEquipmentType.DELETE_EQUIPMENT_FAILED,
  error,
});

// Equipment Detail action
export const equipmentDetailAction = (equipmentId) => ({
  type: getEquipmentDetailType.EQUIPMENT_DETAIL_PENDING,
  equipmentId,
});

export const equipmentDetailSuccess = (data) => ({
  type: getEquipmentDetailType.EQUIPMENT_DETAIL_SUCCESS,
  data,
});

export const equipmentDetailFailure = (error) => ({
  type: getEquipmentDetailType.EQUIPMENT_DETAIL_FAILED,
  error,
});
//update action
export const updateEquipments = (data) => ({
  type: postEquipmentUpdateType.UPDATE_EQUIPMENT_PENDING,
  data,
});

export const updateEquipmentsSuccess = (data) => ({
  type: postEquipmentUpdateType.UPDATE_EQUIPMENT_SUCCESS,
  data,
});

export const updateEquipmentsFailure = (error) => ({
  type: postEquipmentUpdateType.UPDATE_EQUIPMENT_FAILED,
  error,
});

export const clearEquipment = () => ({
  type: clearEqiupmentType.CLEAR_EQUIPMENT,
  data: "",
});
