import { FormControl, Grid } from "@mui/material";
import React, { useState } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { INSPECTIONS_ACKNOWLEDGMENT } from "../../../../constant/routeContant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import "./viewStatementMapping.scss";
import { ORG_ID, PAGE_SIZE } from "../../../../constant/constant";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";

const ViewStatementMapping = (props) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  const dataBack = {
    title: "Inspection Acknowledgement Mapping List",
    route: INSPECTIONS_ACKNOWLEDGMENT,
  };

  const columns = [
    {
      field: "value",
      headerName: "Inspection Form",
      flex: 1,
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search ? search : "",
      organisationId: ORG_ID(),
      assetTypeId: "",
      SubClassId: "",
      certifyStatementId: props?.acknowledgment?.key,
      isActive: true,
      allowPagination: true,
    };
    props?.questionSetListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: page,
      pageSize: PAGE_SIZE,
      search: event.target.value,
      organisationId: ORG_ID(),
      assetTypeId: "",
      SubClassId: "",
      certifyStatementId: props?.acknowledgment?.key,
      isActive: true,
      allowPagination: true,
    };
    props?.questionSetListData(data);
  };

  return (
    <>
      <Grid className="inspectionOverRide inspectionAcknowledgement">
        <BackBtn dataBack={dataBack} />
        <Grid container className="CategoriesBox">
          <Grid item md={12} xs={12} className="questionBox mainQuestion">
            <div className="grid_main_body">
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
              </div>
              <Grid container className="statementStyleContainer">
                <Grid md={12} xs={12} item mb={2}>
                  <FormControl fullWidth>
                    <FormLabels label={props?.acknowledgment?.value} />
                    <p className="statementDescription">
                      {props?.acknowledgment?.description}
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="desc_box row_uniq">
                <div className="table_style_ui singleColumn">
                  <GridTable
                    getRowId={(r) => r.key}
                    rows={props?.questionSetList ?? []}
                    rowCount={props?.count ?? 0}
                    columns={columns}
                    checkboxSelection={false}
                    loading={props?.isLoading}
                    page={1}
                    pagination
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={[10]}
                    onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewStatementMapping;
