import { getApiUrl } from "../config";
import { get, post, deletes } from "./axiosRequest";
import { ORG_ID, PAGE_SIZE, USERID } from "../constant/constant";

export const getIncidentReferenceData = async () => {
  const url = `${
    getApiUrl().incidentApiUrl
  }/getreferencedata?orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addPersonalInjury = async ({ data }) => {
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/PersonalInjury/AddPersonalInjury`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updatePersonalInjury = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatepersonalinjury`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/PersonalInjury/UpdatePersonalInjury`;
  // try {

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPersonalInjury = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getpersonalInjury?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/PersonalInjury/GetPersonalInjury?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addVehicale = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/addvehicleaccident`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/VehicleAccident/AddVehicleAccident`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getVehicleAccidentReport = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getvehicleaccident?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/VehicleAccident/GetVehicleAccident?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateVehicleAccident = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatevehicleaccident`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/VehicleAccident/UpdateVehicleAccident`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addUtilityDamage = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrlNew}/UtilityDamage/AddUtilityDamage`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUtilityDamageReport = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getUtilitydamage?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/UtilityDamage/GetUtilityDamage?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUtilityDamage = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updateutilitydamage`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/UtilityDamage/UpdateUtilityDamage`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addNearMisses = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrlNew}/NearMiss/AddNearMiss`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getNearMisses = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getnearmiss?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/NearMiss/GetNearMiss?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateNearMisses = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatenearmiss`;

  const url = `${getApiUrl().incidentApiUrlNew}/NearMiss/UpdateNearMiss`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addStolenProperty = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/addstolenproperty`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/StolenProperty/AddStolenProperty`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getStolenProperty = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getstolenproperty?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/StolenProperty/GetStolenProperty?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateStolenProperty = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatestolenproperty`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/StolenProperty/UpdateStolenProperty`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getIncidentList = async ({ data }) => {
  const {
    pageNumber,
    searchText,
    startDate,
    endDate,
    caseId,
    incidentType,
    status,
    pageSize,
    divisionId,
    locationId,
    isSendMail,
  } = data;
  const pageNumbers = !!pageNumber ? `pageNumber=${pageNumber}&` : "";
  const searchTexts = !!searchText ? `searchText=${searchText}&` : "";

  const startDate6 = !!startDate ? `fromdate=${startDate}&` : "";
  const endDate7 = !!endDate ? `todate=${endDate}&` : "";
  const caseIds = !!caseId ? `caseId=${caseId}&` : "";
  const incidentTypes = !!incidentType ? `incidentType=${incidentType}&` : "";
  const statuss = !!status ? `status=${status}&` : "";
  const pageSizee = !!pageSize ? `pageSize=${pageSize ? pageSize : ""}&` : "";
  let url = `${
    getApiUrl().incidentApiUrlNew
  }/IncidentType/GetIncidentList?${pageNumbers}orgId=${ORG_ID()}&${searchTexts}${startDate6}${endDate7}${caseIds}${incidentTypes}${statuss}${pageSizee}&division=${
    divisionId ? divisionId : ""
  }&locationOfRecord=${locationId ? locationId : ""}&isSendMail=${isSendMail}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getRegNumber = async (searchText) => {
  const url = `${getApiUrl().searchApiUrl}/v1/getvehicle?regNumber=${
    searchText ? searchText?.trim() : ""
  }&orgId=${ORG_ID()}`;
  try {
    return await get(
      url,
      {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      },
      true
    );
  } catch (err) {
    return err;
  }
};

export const deleteIncident = async ({ payload }) => {
  const url = `${getApiUrl().incidentApiUrl}/deleteincident/${
    payload ? payload : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getActionsList = async ({ data }) => {
  const { page, search } = data;

  let url = `${
    getApiUrl().incidentApiUrl
  }/GetOpenActionList?organizationId=${ORG_ID()}&userId=${USERID}&search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// post update observation
export const closeActionService = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/CloseAction`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCasesList = async ({ data }) => {
  const {
    page,
    search,
    date_from,
    date_to,
    statusIds = "",
    pageSize,
    divisionId,
    locationId,
    isSendMail,
  } = data;

  let url = `${
    getApiUrl().incidentApiUrl
  }/getcaselist?pageNumber=${page}&pageSize=${
    pageSize ? pageSize : PAGE_SIZE
  }&statusIds=${statusIds}&date_from=${date_from}&isSendMail=${isSendMail}&date_to=${date_to}&orgId=${ORG_ID()}&searchText=${search}&division=${
    divisionId ? divisionId : ""
  }&locationOfRecord=${locationId ? locationId : ""}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCaseReport = async (caseId) => {
  const url = `${
    getApiUrl().incidentApiUrl
  }/getcase?orgId=${ORG_ID()}&CaseId=${caseId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    });
  } catch (err) {
    return err;
  }
};

export const closeIncident = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/CloseIncident`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteCase = async ({ payload }) => {
  const url = `${getApiUrl().incidentApiUrl}/deleteCase/${payload}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// reports apis getIncidentReport
export const getIncidentReport = async (data) => {
  const { incidentType, fromDate, toDate, orgId, divisionId, locationId } =
    data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/IncidentReport?incidentType=${incidentType}&fromDate=${fromDate}&toDate=${toDate}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
//Personal Injury Section==========INJURED-BODY-PART=====================================
export const getIncidentInjuredBodyPartReport = async (data) => {
  const {
    incidentType,
    fromDate,
    toDate,
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
  } = data?.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/InjuredBodyPart?incidentType=${
    incidentType ? incidentType : 0
  }&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getIncidentInjuredBodyPartListReport = async (data) => {
  const {
    bodyPartId,
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
    fromDate,
    toDate,
    incidentType,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/InjuredBodyPartList?incidentType=${
    incidentType ? incidentType : 0
  }&page=${page}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&bodyPartId=${bodyPartId}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// most common injury

export const getIncidentMostCommonInjury = async (data) => {
  const {
    incidentType,
    fromDate,
    toDate,
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/MostCommonInjury?incidentType=${
    incidentType ? incidentType : 0
  }&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getIncidentMostCommonInjuryList = async (data) => {
  const {
    InjuryId,
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
    fromDate,
    toDate,
    incidentType,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/MostCommonInjuryList?incidentType=${
    incidentType ? incidentType : 0
  }&page=${page}&pageSize=${pageSize}&InjuryId=${InjuryId}&division=${divisionId}&locationOfRecord=${locationId}&fromDate=${fromDate}&toDate=${toDate}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// most common day of injury
export const getIncidentMostCommonDayOfInjury = async (data) => {
  const {
    fromDate,
    toDate,
    orgId,
    pageSize,
    page,
    divisionId,
    locationId,
    Day,
  } = data?.data;

  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/MostCommonDayOfInjury?page=${page}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&division=${divisionId}&locationOfRecord=${locationId}&Day=${
    Day ? Day : ""
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// user with most no of incidents =============================
export const getIncidentUserWithmostNoofIncident = async (data) => {
  const {
    incidentType,
    fromDate,
    toDate,
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/UserWithMostNoOfIncident?incidentType=${incidentType}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getIncidentUserWithmostNoofIncidentList = async (data) => {
  const {
    userId,
    orgId,
    pageSize,
    page,
    divisionId,
    locationId,
    fromDate,
    toDate,
    incidentType,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/UserWithMostNoOfIncidentList?incidentType=${incidentType}&page=${page}&pageSize=${pageSize}&UserId=${userId}&division=${divisionId}&locationOfRecord=${locationId}&fromDate=${fromDate}&toDate=${toDate}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// user with most no of action items=============================
export const getIncidentUserWithmostNoofActionItems = async (data) => {
  const {
    incidentType,
    fromDate,
    toDate,
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/UserWithMostNoOfOpenActionItem?incidentType=${incidentType}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getIncidentUserWithmostNoofActionItemsList = async (data) => {
  const {
    userId,
    orgId,
    fromDate = "",
    toDate = "",
    pageSize,
    page,
    divisionId,
    locationId,
    incidentType,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/UserWithMostNoOfOpenActionItemList?incidentType=${incidentType}&page=${page}&pageSize=${pageSize}&userId=${userId}&division=${divisionId}&locationOfRecord=${locationId}&fromDate=${fromDate}&toDate=${toDate}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// project with most incidents
export const getProjectWithMostIncidents = async (data) => {
  const {
    incidentType,
    fromDate = "",
    toDate = "",
    orgId,
    page,
    pageSize,
    divisionId,
    locationId,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/ProjectWithMostIncident?incidentType=${incidentType}&page=${page}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&incidentType=${incidentType}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getProjectWithMostIncidentsList = async (data) => {
  const {
    ProjectId,
    orgId,
    pageSize,
    page,
    divisionId,
    locationId,
    fromDate,
    toDate,
    incidentType,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/ProjectWithMostIncidentList?incidentType=${incidentType}&page=${page}&pageSize=${pageSize}&ProjectId=${ProjectId}&division=${divisionId}&locationOfRecord=${locationId}&fromDate=${fromDate}&toDate=${toDate}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getVehicleWithMostIncidents = async (data) => {
  const {
    incidentType,
    orgId,
    pageSize,
    page,
    fromDate = "",
    toDate = "",
    divisionId,
    locationId,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/VehicleWithMostIncident?incidentType=${incidentType}&page=${page}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&incidentType=${incidentType}&division=${divisionId}&locationOfRecord=${locationId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getVehicleWithMostIncidentsList = async (data) => {
  const {
    VehicleId,
    fromDate = "",
    toDate = "",
    orgId,
    pageSize,
    page,
    divisionId,
    locationId,
    incidentType,
  } = data.data;
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/VehicleWithMostIncidentList?incidentType=${incidentType}&page=${page}&pageSize=${pageSize}&VehicleId=${VehicleId}&division=${divisionId}&locationOfRecord=${locationId}&fromDate=${fromDate}&toDate=${toDate}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getOSHAEstablishmentList = async (data) => {
  const { search, orgId, page, pageSize } = data.data;
  const url = `${
    getApiUrl().adminApiUrl
  }/OSHAEstablishment/GetOSHAEstablishmentList?search=${search}&page=${page}&pageSize=${pageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addEstablishment = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/OSHAEstablishment/AddOSHAEstablishment`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateEstablishment = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/OSHAEstablishment/UpdateOSHAEstablishment`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateEstablishmentStatus = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/OSHAEstablishment/UpdateStatusOSHAEstablishment`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getEstablishmentDetail = async (data) => {
  const { establishmentId, orgId } = data.data;
  const url = `${
    getApiUrl().adminApiUrl
  }/OSHAEstablishment/GetOSHAEstablishmentDetails?EstablishmentId=${establishmentId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getOshaLog = async (data) => {
  const { search, orgId, establishmentId, year, status, isIncidentRecordable } =
    data.data;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/Osha/GetOshaLog?search=${search}&establishmentId=${establishmentId}&year=${year}&status=${status}&isIncidentRecordable=${isIncidentRecordable}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getOshaDetails = async (data) => {
  const { establishmentId, year, orgId } = data.data;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/Osha/getOshaDetails?EstablishmentId=${establishmentId}&year=${year}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getListAddedInOSHA = async (data) => {
  const { search, page, pageSize, orgId, establishmentId, year, status } =
    data.data;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/Osha/GetListAddedInOSHA?search=${search}&establishmentId=${establishmentId}&year=${year}&status=${status}&page=${page}&pageSize=${pageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const submitDetailsToOsha = async (data) => {
  const { submitOshaId, orgId } = data.data;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/Osha/SubmitDetailsToOsha?submitOshaId=${submitOshaId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const addOshaDetails = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrlNew}/Osha/AddOshaDetails`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const submitGetOSHALog = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrlNew}/Osha/SubmitGetOSHALog`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};