import React, { useEffect, useState } from "react";
import * as projectAction from "../../../redux/actions/projectAction";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../constant/constant";
import {
  ACCESS_DENIED,
  ADMIN_ADD_PROJECT,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import ProjectList from "../component/project/projectList";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";

export default function ProjectListContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState(false);
  const projectsListData = useSelector((state) => state.projectList);
  // const isLoading =
  const projectListDelete = useSelector((state) => state.deleteProject.status);
  useEffect(() => {
    if (projectListDelete === "SUCCESS") getProjectListData(defaultData);
  }, [projectListDelete]);

  const getProjectListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(projectAction.getProjectList(data));
  };

  const isLoading = useSelector(
    (state) => state.deleteProject.isLoading || state.projectList.isLoading
  );
  const clientDivision = useSelector((state) => state.getDropdownDataKeyValue);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const divisions = userInfo?.divisions?.filter(
    (i) => i?.serviceType == PRODUCT_ID()
  );
  const isClient = userInfo?.isClient;

  const [clientDivisions, setClientDivisions] = useState();
  const valueCheck = clientDivisions || divisions;
  const clientSwitch = isClient || ORG_ID() !== LOGIN_ORG_ID();
  useEffect(() => {
    if (clientSwitch) {
      if (clientDivision?.status === API_STATUS.SUCCESS) {
        const divisions = clientDivision?.data?.divisons && [
          ...clientDivision?.data?.divisons,
        ];
        divisions?.unshift({ key: 1, value: "All Divisions" });
        setClientDivisions(divisions);
      }
    }
  }, [clientDivision, clientSwitch]);

  const defaultData = {
    organisationId: ORG_ID(),
    divisionId: clientSwitch && valueCheck ? 0 : divisions[0]?.key,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: 0,
    isSendMail: false,
  };

  useEffect(() => {
    dispatch(projectAction.clearProject());
    getProjectListData(defaultData);
  }, []);

  const addProject = () => {
    navigate(ADMIN_ADD_PROJECT);
  };
  function deleteProject(data) {
    dispatch(projectAction.deleteProject(data));
  }

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: "6",
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    if (projectsListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Projects has been sent to registered email ID");
    }
  }, [projectsListData]);

  return (
    <>
      {checkPrivileges([12, 70, 71, 72]) ? (
        <>
          <ToastContainer />
          <ProjectList
            projectsListData={isLoading ? [] : projectsListData?.data}
            getProjectListData={getProjectListData}
            addProject={addProject}
            isLoading={isLoading}
            deleteProject={deleteProject}
            isProjectsLoading={isLoading}
            divisionDropdown={
              clientSwitch && valueCheck ? clientDivisions : divisions
            }
            checkbox={false}
            onRowClick={false}
            onSelectionModelChange={false}
            selectionModel={false}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
