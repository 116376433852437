import React, { useState } from "react";
import TabPanel from "../../lms/component/tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import "./credentialLookup.scss";
import Tab from "@mui/material/Tab";
import {
  CREDENTIALLOOKUPTABS,
  ORG_ID,
  PAGE_SIZE,
  USER_TYPE,
} from "../../../constant/constant";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { TableLoader } from "../../../library/common/Loader";
import { Grid } from "@mui/material";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import NORow from "../../../library/custom/noRow/noRow";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import GridTable from "../../../library/custom/gridTable/GridTable";
import {
  SETUP_CREDENTIAL,
  VIEW_LOOKUP_CREDENTIAL,
} from "../../../constant/routeContant";
import SettingTable from "../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../incidents/incidentList/ReportRowManipulte";
import { useNavigate } from "react-router-dom";
import downloadIcon from "../../../assets/downloadIcon.svg";

export default function CredentialLookup({
  credentialsByUsers,
  credentialsByCredentialsData,
  onSearch,
  allUsers,
  getCredentials,
  credentialListData,
  isLoading,
  isUserLoading,
  isCredLoading,
  getCredListByUser,
  getCredListByCredential,
}) {
  const [users, setUsers] = useState();
  const [page, setPage] = useState(1);
  const [credentialsName, SetcredentialsName] = useState("");
  const [credentialsNameId, SetcredentialsNameId] = useState("");
  const navigate = useNavigate();

  const handleViewOnclick = (rows) => {
    navigate(VIEW_LOOKUP_CREDENTIAL, {
      state: { credentialId: rows.row.id, editMode: true },
    });
  };
  const usercolumns = [
    { field: "credentialName", headerName: "Credential Name", flex: 4.5 },
    { field: "credentialNumber", headerName: "Credential Number.", flex: 3.5 },
    { field: "passingDate", headerName: "Passing Date", flex: 2.5 },
    { field: "expirationDate", headerName: "Expiration Date", flex: 3 },
    { field: "issuingBody", headerName: "Issuing Body", flex: 3 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={credentialsByUsers?.result?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={credentialsByUsers?.result?.credentialData ?? []}
          fileNameXL={"CredentialsByUser-list"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            edit={false}
            viewOnClick={() => handleViewOnclick(rows)}
            viewLabel="View Credential"
            view={true}
          />
        );
      },
    },
  ];

  const credentialcolumns = [
    { field: "name", headerName: "Name", flex: 3 },
    { field: "designation", headerName: "Job Title", flex: 3 },
    { field: "division", headerName: "Division", flex: 3 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={credentialsByCredentialsData?.result?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={credentialsByCredentialsData?.result?.userData ?? []}
          fileNameXL={"UserByCredential-list"}
        />
      ),
    },
  ];

  const onSelectSearchCredential = (v1, v2) => {
    SetcredentialsName({
      key: v2 === null || undefined ? "" : v2?.key,
      value: v2 === null || undefined ? "" : v2?.value,
    });
    SetcredentialsNameId(v2?.key);
    const dataCred = {
      organisationId: ORG_ID(),
      pageSize: PAGE_SIZE,
      page: 1,
      credentialNameId: v2 === null || undefined ? 0 : v2?.key,
      search: "",
      isSendMail: false,
    };
    getCredListByCredential(dataCred);
  };

  const onChangeUsers = (value) => {
    setUsers(value);
    const data = {
      organisationId: ORG_ID(),
      pageSize: PAGE_SIZE,
      page: 1,
      userId: value === null || undefined ? 0 : value?.key,
      search: "",
      isSendMail: false,
    };

    getCredListByUser(data);
  };

  const handlePagignationUser = (newPage) => {
    setPage(newPage + 1);
    getCredListByUser({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      organisationId: ORG_ID(),
      userId: users?.key,
      search: "",
      isSendMail: false,
    });
  };

  const handlePagignationCredential = (newPage) => {
    setPage(newPage + 1);
    getCredListByCredential({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      organisationId: ORG_ID(),
      credentialNameId: credentialsNameId,
      search: "",
      isSendMail: false,
    });
  };

  const onChangeCredential = (event, data) => {
    const defaultData = {
      search: event.target.value,
      organisationId: ORG_ID(),
      isSendMail: false,
    };
    getCredentials(defaultData);
  };

  const dataBack = {
    title: "Back to Credentials",
    route: SETUP_CREDENTIAL,
  };
  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    SetcredentialsName("");
    SetcredentialsNameId("");
    setValue(newValue);
    setUsers("");
    if (newValue === 0) {
      const data = {
        organisationId: ORG_ID(),
        pageSize: PAGE_SIZE,
        page: 1,
        userId: 0,
        search: "",
        isSendMail: false,
      };
      getCredListByUser(data);
    } else if (newValue === 1) {
      const dataCred = {
        organisationId: ORG_ID(),
        pageSize: PAGE_SIZE,
        page: 1,
        credentialNameId: 0,
        search: "",
        isSendMail: false,
      };
      getCredListByCredential(dataCred);
    }
  };

  const sendEmail = () => {
    if (value === 0) {
      const data = {
        organisationId: ORG_ID(),
        pageSize: PAGE_SIZE,
        page: 1,
        userId: users?.key || 0,
        search: "",
        isSendMail: true,
      };
      getCredListByUser(data);
    } else if (value === 1) {
      const data = {
        organisationId: ORG_ID(),
        pageSize: PAGE_SIZE,
        page: 1,
        credentialNameId: credentialsNameId || 0,
        search: "",
        isSendMail: true,
      };
      getCredListByCredential(data);
    }
  };

  return (
    <section className="grid_lookup_cred_sec">
      <div className="grid_main_header two_grid">
        <div className="grid_heading_style">Credential Lookup</div>
      </div>
      <Grid item md={12} xs={12} className="addShadowBox">
        <div>
          <Tabs value={value} onChange={handleChange}>
            {CREDENTIALLOOKUPTABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </div>
        <div>
          <TabPanel value={value} index={0}>
            <>
              <div className="div-cred-filter-container">
                <div className="div-filter-left">
                  <Grid item container mt={3} spacing={2}>
                    <Grid item lg={5} xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <FormLabels label={"Search User"} />
                        <SearchAutoComplete
                          name="userSearch"
                          placeholderText="Enter User Name"
                          optionsData={isLoading ? [] : allUsers?.users ?? []}
                          typeOnchange={(event, value) =>
                            onSearch(
                              event.target.value,
                              USER_TYPE.NONPRIVILEGED
                            )
                          }
                          isKeyValuePair={true}
                          value={users}
                          onChange={(event, value) => onChangeUsers(value)}
                          isLoading={isLoading}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className="div-filter-right-download" onClick={sendEmail}>
                  <span>
                    <img
                      src={downloadIcon}
                      className="DownLoadLogo"
                      alt="DownLoad Logo"
                    />
                  </span>
                  Download Data
                </div>
              </div>
              <Grid mt={3}>
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      getRowId={(r) => r.id}
                      rows={credentialsByUsers?.result?.credentialData ?? []}
                      rowCount={credentialsByUsers?.result?.noOfRecords ?? 0}
                      columns={usercolumns}
                      loading={isUserLoading}
                      checkboxSelection={false}
                      page={page}
                      pagination
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      onPageChange={handlePagignationUser}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <>
              <div className="div-cred-filter-container">
                <div className="div-filter-left">
                  <Grid item container mt={3} spacing={2}>
                    <Grid item lg={5} xs={12} sm={12} md={4}>
                      <FormLabels label="Search Credential" />
                      <SearchAutoComplete
                        name="searchCredential"
                        placeholderText="Enter Credential Name"
                        optionsData={
                          isLoading
                            ? []
                            : credentialListData?.data?.result ?? []
                        }
                        typeOnchange={(event, value) =>
                          onChangeCredential(event, value)
                        }
                        isKeyValuePair={true}
                        value={credentialsName}
                        onChange={(event, value) =>
                          onSelectSearchCredential(event, value)
                        }
                        isLoading={isLoading}
                        noOptionsTextMsg={"No Credential"}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="div-filter-right-download" onClick={sendEmail}>
                  <span>
                    <img
                      src={downloadIcon}
                      className="DownLoadLogo"
                      alt="DownLoad Logo"
                    />
                  </span>
                  Download Data
                </div>
              </div>
              <Grid mt={3}>
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      getRowId={(r) => r.id}
                      rows={
                        credentialsByCredentialsData?.result?.userData ?? []
                      }
                      rowCount={
                        credentialsByCredentialsData?.result?.noOfRecords ?? 0
                      }
                      columns={credentialcolumns}
                      loading={isCredLoading}
                      checkboxSelection={false}
                      page={page}
                      pagination
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      onPageChange={handlePagignationCredential}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </>
          </TabPanel>
        </div>
        <div className="Back_btn_style">
          <BackBtn dataBack={dataBack} />
        </div>
      </Grid>
    </section>
  );
}
