import React, { useState, useEffect, useRef } from "react";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  ORG_NAME,
  TRAINING_OPTION,
} from "../../../../constant/constant";

import { useNavigate } from "react-router-dom";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import { getRandomNumber } from "../../../../utils/helper";
import GridTable from "../../../../library/custom/gridTable/GridTable";

const LmsClientMapping = ({
  searchClient,
  isSearchClientLoading,
  allClients,
  changesearchauto,
  setchangesearchauto,
  searchLessons,
  searchLessonsData,
  searchCourses,
  searchCoursesData,
  getMappedGlobalLessonListApi,
  getMappedGlobalCourseListApi,
  MappedGlobalLessonListData,
  MappedGlobalCourseListData,
  issubsuccess,
  issubsuccessclient,
  lessons,
  setlessons,
  issaveclicklms,
  issaveclickclient,
  clients,
  setClients,
  trainigtype,
  setTrainigtype,
  FinalaData,
  setFinalaData,
  FinalaCourseData,
  setFinalaCourseData,
  FinalaDataClientSide,
  setFinalaDataClientSide,
  FinalaCourseDataClientSide,
  setFinalaCourseDataClientSide,
  lessonMappingsubmit,
  courseMappingCoursesubmit,
  clientLessonToUserViewsubmit,
  clientCourseToUserViewsubmit,
}) => {
  const history = useNavigate();
  const autoComp = useRef(null);
  const autoCompCourses = useRef(null);
  const [tabclick, settabclick] = useState(1);
  const [lessonid, setlessonid] = useState("");
  const [lessonname, setlessonname] = useState("");
  const [courses, setcourses] = useState("");
  const [courseid, setcourseid] = useState("");
  const [coursename, setcoursename] = useState("");
  // Error and duplicate state code
  const [islessonSearchError, setislessonSearchError] = useState(false);
  const [isclientlessonSearchError, setisclientlessonSearchError] =
    useState(false);
  const [isclientCourseSearchError, setisclientCourseSearchError] =
    useState(false);
  const [iscourseSearchError, setiscourseSearchError] = useState(false);
  const [islmslessonDuplicate, setislmslessonDuplicate] = useState(false);
  const [islmscourseDuplicate, setislmscourseDuplicate] = useState(false);
  const [isclientlessonDuplicate, setisclientlessonDuplicate] = useState(false);
  const [isclientcourseDuplicate, setisclientcourseDuplicate] = useState(false);

  const autoCompLesson = useRef();

  const onclicktab = (val) => {
    settabclick(val);
    setTrainigtype(1);
    setFinalaData([]);
    setFinalaCourseData([]);
    setFinalaCourseDataClientSide([]);
    setFinalaDataClientSide([]);
    setlessons("");
    setlessonid("");
    setlessonname("");
    setcourses("");
    setcourseid("");
    setcoursename("");
    setislessonSearchError(false);
    setiscourseSearchError(false);
    setisclientlessonSearchError(false);
    setisclientCourseSearchError(false);
    setislmslessonDuplicate(false);
    setislmscourseDuplicate(false);
    setisclientlessonDuplicate(false);
    setisclientcourseDuplicate(false);
    setchangesearchauto({ key: "", value: "" });
    setClients("");
  };
  useEffect(() => {
    if (
      (issaveclicklms && issubsuccess) ||
      (issaveclickclient && issubsuccessclient)
    ) {
      setFinalaData([]);
      setFinalaCourseData([]);
      setFinalaCourseDataClientSide([]);
      setFinalaDataClientSide([]);
      setchangesearchauto({ key: "", value: "" });
      setClients("");
      setlessons("");
      setlessonid("");
      setlessonname("");
      setcourses("");
      setcourseid("");
      setcoursename("");
      setClients("");
      setislessonSearchError(false);
      setiscourseSearchError(false);
      setislmslessonDuplicate(false);
      setislmscourseDuplicate(false);
    }
  }, [issubsuccess, issubsuccessclient]);

  useEffect(() => {
    if (changesearchauto?.key && tabclick === 1) {
      const data = {
        clientId: changesearchauto?.key,
      };
      if (trainigtype === 1) {
        data.lessonId = 0;
        getMappedGlobalLessonListApi(data);
      } else {
        data.courseId = 0;
        getMappedGlobalCourseListApi(data);
      }
    }
  }, [changesearchauto, trainigtype]);

  useEffect(() => {
    if ((lessonid || courseid) && tabclick === 2) {
      const data = {
        clientId: 0,
      };
      if (trainigtype === 1) {
        data.lessonId = lessonid;
        getMappedGlobalLessonListApi(data);
      } else {
        data.courseId = courseid;
        getMappedGlobalCourseListApi(data);
      }
    }
  }, [lessonid, courseid, trainigtype]);

  const typeOnchange = (event, value) => {
    searchClient(event?.target?.value);
  };
  const onChangeClients = (value) => {
    if (value === null) {
      setFinalaData([]);
      setFinalaCourseData([]);
    }
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    setClients(value);
    setisclientlessonSearchError(false);
    setisclientCourseSearchError(false);
    setisclientlessonDuplicate(false);
    setisclientcourseDuplicate(false);
  };
  const onChangeTrainingType = (e) => {
    setTrainigtype(e?.target?.value);
    setFinalaData([]);
    if (tabclick === 1) {
      setlessons("");
      setcourses("");
      setlessonname("");
      setcoursename("");
    }
    if (tabclick === 2) {
      setlessons("");
      setcourses("");
      setlessonname("");
      setcoursename("");
      setchangesearchauto({ key: "", value: "" });
      setClients("");
    }
    setislessonSearchError(false);
    setiscourseSearchError(false);
    setisclientlessonSearchError(false);
    setisclientCourseSearchError(false);
    setFinalaCourseDataClientSide([]);
    setFinalaDataClientSide([]);
    setislmslessonDuplicate(false);
    setislmscourseDuplicate(false);
    setisclientlessonDuplicate(false);
    setisclientcourseDuplicate(false);
  };
  const handleChangeLmsDelete = (item, checklms) => {
    if (checklms === "1") {
      const removeUser = FinalaData?.filter(
        (x) => x.lessonId !== item.lessonId
      );
      setFinalaData(removeUser);
    } else if (checklms === "2") {
      const removeUserCourse = FinalaCourseData?.filter(
        (x) => x?.courseId !== item?.courseId
      );
      setFinalaCourseData(removeUserCourse);
    }
  };

  const handleChangeClientDelete = (item, checklms) => {
    if (checklms === "1") {
      const removeUser = FinalaDataClientSide?.filter(
        (x) => x?.clientId !== item?.clientId
      );
      setFinalaDataClientSide(removeUser);
    } else if (checklms === "2") {
      const removeUserCourse = FinalaCourseDataClientSide?.filter(
        (x) => x?.clientId !== item?.clientId
      );
      setFinalaCourseDataClientSide(removeUserCourse);
    }
  };

  const columnsLms = [
    {
      field: "clientName",
      headerName: "Client Name",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "lessonName",
      headerName: "Lesson Name",
      flex: 2,
      disableColumnMenu: true,
    },

    {
      field: "icon",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: false,
      renderHeader: () => "",
      renderCell: (rows) => {
        return (
          <div
            onClick={() => handleChangeLmsDelete(rows?.row, "1")}
            className="crossiconclass"
          >
            X
          </div>
        );
      },
    },
  ];
  const columnsLmsCourse = [
    {
      field: "clientName",
      headerName: "Client Name",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "courseName",
      headerName: "Course Name",
      flex: 2,
      disableColumnMenu: true,
    },

    {
      field: "icon",
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderHeader: () => "",
      renderCell: (rows) => {
        return (
          <div
            onClick={() => handleChangeLmsDelete(rows?.row, "2")}
            className="crossiconclass"
          >
            X
          </div>
        );
      },
    },
  ];
  const columnsClients = [
    {
      field: "lessonName",
      headerName: "Lesson Name",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      flex: 2,
      disableColumnMenu: true,
    },

    {
      field: "icon",
      flex: 1,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderHeader: () => "",
      renderCell: (rows) => {
        return (
          <div
            className="crossiconclass"
            onClick={() => handleChangeClientDelete(rows?.row, "1")}
          >
            X
          </div>
        );
      },
    },
  ];
  const columnsClientsCourse = [
    {
      field: "courseName",
      headerName: "Course Name",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      flex: 2,
      disableColumnMenu: true,
    },

    {
      field: "icon",
      flex: 1,
      disableClickEventBubbling: true,
      renderHeader: () => "",
      renderCell: (rows) => {
        return (
          <div
            onClick={() => handleChangeClientDelete(rows?.row, "2")}
            className="crosslmsmapping"
          >
            X
          </div>
        );
      },
    },
  ];
  const typeOnchangeLessons = (event, value) => {
    searchLessons(event?.target?.value);
  };
  const onChangeLessons = (value) => {
    if (value == null && tabclick === 2) {
      setFinalaDataClientSide([]);
      setchangesearchauto({ key: "", value: "" });
      setClients("");
      setisclientlessonDuplicate(false);
      setisclientcourseDuplicate(false);
    }
    setlessons(value);
    setlessonname(value?.value);
    setlessonid(value?.key);
    setislessonSearchError(false);
    setiscourseSearchError(false);
    setislmslessonDuplicate(false);
  };
  const typeOnchangeCourses = (event, value) => {
    searchCourses(event?.target?.value);
  };
  const onChangeCourses = (value) => {
    if (value == null && tabclick === 2) {
      setFinalaCourseDataClientSide([]);
      setchangesearchauto({ key: "", value: "" });
      setClients("");
      setisclientlessonDuplicate(false);
      setisclientcourseDuplicate(false);
    }
    setcourses(value);
    setcoursename(value?.value);
    setcourseid(value?.key);
    setiscourseSearchError(false);
    setislmscourseDuplicate(false);
    setisclientlessonDuplicate(false);
    setisclientcourseDuplicate(false);
  };

  // Tab one Add Lesson / Add course
  const handleClickAddLms = (value) => {
    let errors = false;
    if (!lessonname && trainigtype === 1) {
      setislessonSearchError(true);
      errors = true;
    }
    if (!coursename && trainigtype === 2) {
      setiscourseSearchError(true);
      errors = true;
    }
    if (!changesearchauto?.key && trainigtype === 1) {
      setisclientlessonSearchError(true);
      errors = true;
    }
    if (!changesearchauto?.key && trainigtype === 2) {
      setisclientCourseSearchError(true);
      errors = true;
    }
    if (errors === false) {
      const dataobj = {
        clientId: parseInt(changesearchauto?.key)
          ? parseInt(changesearchauto?.key)
          : parseInt(ORG_ID()),
        lessonId: parseInt(lessonid),
        clientName: changesearchauto?.value
          ? changesearchauto?.value
          : ORG_NAME(),
        lessonName: lessonname,
        courseName: coursename,
        courseId: parseInt(courseid),
      };
      const checkExistFinalData = FinalaData?.filter((val) => {
        return (
          val.lessonId === dataobj?.lessonId &&
          val.clientId === dataobj?.clientId
        );
      });
      const checkExistFinalaCourseData = FinalaCourseData?.filter((val) => {
        return (
          val.courseId === dataobj?.courseId &&
          val.clientId === dataobj?.clientId
        );
      });

      if (
        checkExistFinalData.length === 0 &&
        tabclick === 1 &&
        trainigtype === 1
      ) {
        setFinalaData([...FinalaData, dataobj]);
        setislmslessonDuplicate(false);
      } else {
        setislmslessonDuplicate(true);
      }
      if (
        checkExistFinalaCourseData.length === 0 &&
        tabclick === 1 &&
        trainigtype === 2
      ) {
        setFinalaCourseData([...FinalaCourseData, dataobj]);
        setislmscourseDuplicate(false);
      } else {
        setislmscourseDuplicate(true);
      }
    }
  };

  //  Final submit Data TAB One Lesson/Course
  const addlmsFinalData = () => {
    if (trainigtype === 1) {
      const removedKeys = [
        "lessonName",
        "clientName",
        "courseName",
        "courseId",
      ];
      const newArray = FinalaData.map((obj) => {
        const newObj = { ...obj };
        removedKeys.forEach((key) => delete newObj[key]);

        return newObj;
      });

      const fData = {
        globallessonSpecifyToUser: newArray,
      };

      clientLessonToUserViewsubmit(fData, changesearchauto?.key);
    } else if (trainigtype === 2) {
      const removedKeys = ["courseName", "clientName"];
      const newArray = FinalaCourseData?.map((obj) => {
        const newObj = { ...obj };
        removedKeys.forEach((key) => delete newObj[key]);

        return newObj;
      });

      const fDataCourse = {
        globalcourseSpecifyToUser: newArray,
      };

      clientCourseToUserViewsubmit(fDataCourse, changesearchauto?.key);
    }
  };
  //  Tab two Add Client
  const handleClickAddClient = (e) => {
    let errors = false;
    if (!lessonname && trainigtype === 1 && tabclick === 2) {
      setislessonSearchError(true);
      errors = true;
    }

    if (!coursename && trainigtype === 2 && tabclick === 2) {
      setiscourseSearchError(true);
      errors = true;
    }
    if (!changesearchauto?.key && trainigtype === 1 && tabclick === 2) {
      setisclientlessonSearchError(true);
      errors = true;
    }
    if (!changesearchauto?.key && trainigtype === 2 && tabclick === 2) {
      setisclientCourseSearchError(true);
      errors = true;
    }
    if (errors === false) {
      const dataobj = {
        clientId: parseInt(changesearchauto?.key)
          ? parseInt(changesearchauto?.key)
          : parseInt(ORG_ID()),
        lessonId: parseInt(lessonid),
        clientName: changesearchauto?.value
          ? changesearchauto?.value
          : ORG_NAME(),
        lessonName: lessonname,
        courseName: coursename,
        courseId: parseInt(courseid),
      };

      const checkExistFinalaDataClientSide = FinalaDataClientSide?.filter(
        (val) => {
          return (
            val.lessonId === dataobj?.lessonId &&
            val.clientId === dataobj?.clientId
          );
        }
      );
      const checkExistFinalaCourseDataClientSide =
        FinalaCourseDataClientSide?.filter((val) => {
          return (
            val.courseId === dataobj?.courseId &&
            val.clientId === dataobj?.clientId
          );
        });

      if (
        checkExistFinalaDataClientSide.length === 0 &&
        tabclick === 2 &&
        trainigtype === 1
      ) {
        setFinalaDataClientSide([...FinalaDataClientSide, dataobj]);
        setisclientlessonDuplicate(false);
      } else {
        setisclientlessonDuplicate(true);
      }
      if (
        checkExistFinalaCourseDataClientSide.length === 0 &&
        tabclick === 2 &&
        trainigtype === 2
      ) {
        setFinalaCourseDataClientSide([...FinalaCourseDataClientSide, dataobj]);
        setisclientcourseDuplicate(false);
      } else {
        setisclientcourseDuplicate(true);
      }
    }
  };

  //  Final submit Data TAB Two Client
  const addClientFinalData = () => {
    if (trainigtype === 1) {
      const removedKeys = [
        "lessonName",
        "clientName",
        "courseName",
        "courseId",
      ];

      const newArray = FinalaDataClientSide?.map((obj) => {
        const newObj = { ...obj };
        removedKeys.forEach((key) => delete newObj[key]);
        return newObj;
      });

      const fData = {
        globallessonSpecifyToUser: newArray,
      };
      lessonMappingsubmit(fData, lessonid);
    } else if (trainigtype === 2) {
      const removedKeys = ["courseName", "clientName"];
      const newArray = FinalaCourseDataClientSide?.map((obj) => {
        const newObj = { ...obj };
        removedKeys?.forEach((key) => delete newObj[key]);
        return newObj;
      });
      const fDataCourse = {
        globalcourseSpecifyToUser: newArray,
      };

      courseMappingCoursesubmit(fDataCourse, courseid);
    }
  };

  return (
    <>
      <div className="overidePersonal"></div>
      <div className="headerWrapper">
        <div className="headerTitle">{"Learning Management Mapping"}</div>
        <div className="headerBox">
          <div className="headerLeft">
            <ul>
              <li
                className={tabclick === 1 ? "lmsmapping active" : "lmsmapping"}
                onClick={() => onclicktab(1)}
              >
                {"Lesson/Course"}
              </li>
              <li
                className={tabclick === 2 ? "active" : ""}
                onClick={() => onclicktab(2)}
              >
                {"Clients"}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {tabclick === 1 ? (
        <>
          <Grid container>
            <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
              {ORG_ID() !== LOGIN_ORG_ID() ? (
                <FormControl fullWidth>
                  <FormLabels label="Select Client" isRequired={false} />
                  <InputField
                    isRequired={false}
                    type="text"
                    placeholder={"Add Client Name Here..."}
                    value={clients?.value}
                    disabled={ORG_ID() !== 1}
                  />
                </FormControl>
              ) : (
                <FormControl fullWidth>
                  <FormLabels label="Select Client" isRequired={true} />

                  <SearchAutoComplete
                    name="orgName"
                    placeholderText="Search Client"
                    noOptionsTextMsg="No Client"
                    optionsData={allClients?.data ?? []}
                    isKeyValuePair={true}
                    typeOnchange={(event, value) => typeOnchange(event, value)}
                    value={clients}
                    onChange={(event, value) => onChangeClients(value)}
                    isLoading={isSearchClientLoading}
                    autoCompClear={autoComp}
                    disabled={
                      parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)
                    }
                  />
                  {isclientlessonSearchError && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}

                  {isclientCourseSearchError && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </FormControl>
              )}
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
              <FormControl fullWidth={true}>
                <FormLabels label="Select Training Type" isRequired={true} />
                <SelectMenu
                  listData={TRAINING_OPTION}
                  value={trainigtype}
                  onchangehandler={(e) => onChangeTrainingType(e)}
                  placeholder="Please Select"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            {trainigtype === 1 ? (
              <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
                <FormControl fullWidth>
                  <FormLabels label="Select Lesson" isRequired={true} />

                  <SearchAutoComplete
                    name="orgName"
                    placeholderText="Search lesson"
                    noOptionsTextMsg="Search lesson"
                    optionsData={searchLessonsData ?? []}
                    isKeyValuePair={true}
                    typeOnchange={(event, value) =>
                      typeOnchangeLessons(event, value)
                    }
                    onChange={(event, value) => onChangeLessons(value)}
                    value={lessons}
                    autoCompClear={autoCompLesson}
                    isError={islessonSearchError}
                    errorMsg={
                      islessonSearchError
                        ? "Please enter this required field"
                        : islmslessonDuplicate
                        ? "Lesson Already Mapped"
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
                <FormControl fullWidth>
                  <FormLabels label="Select Course" isRequired={true} />

                  <SearchAutoComplete
                    name="orgName"
                    placeholderText="Search Courses"
                    noOptionsTextMsg="Search Courses"
                    optionsData={searchCoursesData ?? []}
                    isKeyValuePair={true}
                    typeOnchange={(event, value) =>
                      typeOnchangeCourses(event, value)
                    }
                    onChange={(event, value) => onChangeCourses(value)}
                    value={courses}
                    autoCompClear={autoCompCourses}
                    isError={iscourseSearchError}
                    errorMsg={
                      iscourseSearchError
                        ? "Please enter this required field"
                        : islmscourseDuplicate
                        ? "Course Already Mapped"
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item lg={2} xs={12} sm={12} md={6} mr={2} mt={5.4}>
              <div className="lmsmappingbtn">
                <Buttons
                  id="btnAddlmsmapping"
                  varientAddIconBlue={true}
                  label={trainigtype === 1 ? "Add Lesson" : "Add Course"}
                  onClick={(e) => handleClickAddLms(e)}
                />
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
            <FormControl fullWidth={true}>
              <FormLabels label="Select Training Type" isRequired={true} />
              <SelectMenu
                listData={TRAINING_OPTION}
                value={trainigtype}
                onchangehandler={(e) => onChangeTrainingType(e)}
                placeholder="Please Select"
              />
            </FormControl>
          </Grid>
          {trainigtype === 1 ? (
            <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
              <FormControl fullWidth>
                <FormLabels label="Select Lesson" isRequired={true} />

                <SearchAutoComplete
                  name="orgName"
                  placeholderText="Search lesson"
                  noOptionsTextMsg="Search lesson"
                  optionsData={searchLessonsData ?? []}
                  isKeyValuePair={true}
                  typeOnchange={(event, value) =>
                    typeOnchangeLessons(event, value)
                  }
                  onChange={(event, value) => onChangeLessons(value)}
                  value={lessons}
                  autoCompClear={autoCompLesson}
                  errorMsg={
                    islessonSearchError
                      ? "Please enter this required field"
                      : ""
                  }
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
              <FormControl fullWidth>
                <FormLabels label="Select Course" isRequired={true} />

                <SearchAutoComplete
                  name="orgName"
                  placeholderText="Search Courses"
                  noOptionsTextMsg="Search Courses"
                  optionsData={searchCoursesData ?? []}
                  isKeyValuePair={true}
                  typeOnchange={(event, value) =>
                    typeOnchangeCourses(event, value)
                  }
                  onChange={(event, value) => onChangeCourses(value)}
                  value={courses}
                  autoCompClear={autoCompCourses}
                  isError={iscourseSearchError}
                  errorMsg={
                    iscourseSearchError
                      ? "Please enter this required field"
                      : ""
                  }
                />
              </FormControl>
            </Grid>
          )}

          <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
            <FormControl fullWidth>
              <FormLabels label="Select Client" isRequired={true} />

              <SearchAutoComplete
                name="orgName"
                placeholderText="Search Client"
                noOptionsTextMsg="No Client"
                optionsData={allClients?.data ?? []}
                isKeyValuePair={true}
                typeOnchange={(event, value) => typeOnchange(event, value)}
                value={clients}
                onChange={(event, value) => onChangeClients(value)}
                isLoading={isSearchClientLoading}
                autoCompClear={autoComp}
                disabled={parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)}
              />
              {isclientlessonSearchError && trainigtype === 1 ? (
                <div className="errorMsg">Please enter this required field</div>
              ) : isclientlessonDuplicate && trainigtype === 1 ? (
                <div className="errorMsg">Client Already Mapped</div>
              ) : (
                ""
              )}

              {isclientCourseSearchError && trainigtype === 2 ? (
                <div className="errorMsg">Please enter this required field</div>
              ) : isclientcourseDuplicate && trainigtype === 2 ? (
                <div className="errorMsg">Client Already Mapped</div>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item lg={2} xs={12} sm={12} md={6} mr={2} mt={5.4}>
            <div className="lmsmappingbtn">
              <Buttons
                id="btnAddlmsmapping"
                varientAddIconBlue={true}
                label={"Add Client"}
                onClick={(e) => handleClickAddClient(e)}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {tabclick === 1 ? (
        <Grid mt={3}>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={
                  trainigtype === 1 ? (r) => r?.lessonId : (r) => r?.courseId
                }
                rows={
                  trainigtype === 1 ? FinalaData ?? [] : FinalaCourseData ?? []
                }
                columns={trainigtype === 1 ? columnsLms : columnsLmsCourse}
                disableRowSelectionOnClick
                rowsPerPageOptions={[]}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                loading={
                  trainigtype === 1 && tabclick === 1
                    ? MappedGlobalLessonListData.isLoading
                    : MappedGlobalCourseListData.isLoading
                }
              />
            </div>
          </div>
        </Grid>
      ) : (
        <Grid mt={3}>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r?.clientOrgId + getRandomNumber()}
                rows={
                  trainigtype === 1
                    ? FinalaDataClientSide
                    : FinalaCourseDataClientSide ?? []
                }
                columns={
                  trainigtype === 1 ? columnsClients : columnsClientsCourse
                }
                disableRowSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                rowsPerPageOptions={[]}
                loading={
                  trainigtype === 1 && tabclick === 2
                    ? MappedGlobalLessonListData.isLoading
                    : MappedGlobalCourseListData.isLoading
                }
              />
            </div>
          </div>
        </Grid>
      )}
      <Grid item container mt={3} spacing={2} className="lmssavecancelbtn">
        <Grid lg={2} xs={12} sm={6} md={4}>
          <Buttons
            varientText={true}
            label="Cancel"
            onClick={() => history(-1)}
          />
        </Grid>
        <Grid lg={2} xs={12} sm={6} md={4}>
          <Buttons
            varientContained={true}
            label={"Save"}
            disabled={
              (tabclick === 1 && !changesearchauto?.key) ||
              (tabclick === 2 && !lessonname && !coursename)
                ? true
                : false
            }
            onClick={
              tabclick === 1
                ? () => addlmsFinalData()
                : () => addClientFinalData()
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LmsClientMapping;
