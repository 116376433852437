import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../component/reprimand/reprimandForm.scss";
import ReprimandForm from "../component/reprimand/reprimandForm";
import * as userAction from "../../../redux/actions/userAction";
import { FullPageLoader } from "../../../library/common/Loader";
import {
  revokeDataUnmount,
  getReprimandDetails,
} from "../../../redux/actions/reprimandAction";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";

const AddReprimandContainer = () => {
  const disptch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const edit = pathname.split("/")[2] ?? null;
  const editId = pathname.split("/")[3] ?? null;

  const searchOption = useSelector((state) => state.users.data);
  const userIsLoading = useSelector((state) => state.allUsers.isLoading);
  const { isLoading, isSuccess } = useSelector((state) => state.reprimand);

  const isAutoSearchDataLoading = useSelector((state) => state.users.isLoading);
  const assetOnUserList = useSelector((state) => state.getAssetOnUser.data);
  const supervisorOnAssetList = useSelector(
    (state) => state.getSupervisorOnAsset.data
  );

  useEffect(() => {
    // disptch(userAction.searchUsers('', ''));

    // disptch(userAction.searchAllUsers(""));
    return () => {
      disptch(revokeDataUnmount());
    };
  }, [disptch]);

  useEffect(() => {
    if (!!edit && editId) {
      disptch(getReprimandDetails(editId));
    }
  }, [edit, editId, disptch]);
  const searchUsers = (searchType, searchText) => {
    disptch(userAction.searchUsers(searchType, searchText));
  };

  function getAssetOnUser(params) {
    const data = {
      assetType: 1,
      userId: params.userId,
    };
    disptch(userAction.getAssetOnUser(data));
  }
  function getSupervisorOnAsset(params) {
    const data = {
      assetType: 1,
      assetId: params,
    };
    disptch(userAction.getSupervisorOnAsset(data));
  }
  return (
    <>
      {checkPrivileges([12, 81, 82]) ? (
        <>
          <div>
            {(isLoading || userIsLoading) && !isSuccess ? (
              <FullPageLoader />
            ) : (
              <ReprimandForm
                searchOption={searchOption}
                searchUsers={searchUsers}
                isLoading={isLoading}
                isUserDetails={true}
                isAutoSearchDataLoading={isAutoSearchDataLoading}
                getAssetOnUser={getAssetOnUser}
                assetOnUserList={assetOnUserList?.jobSite ?? []}
                getSupervisorOnAsset={getSupervisorOnAsset}
                supervisorOnAssetList={supervisorOnAssetList?.result ?? []}
              />
            )}
          </div>
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default AddReprimandContainer;
