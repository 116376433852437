import React, { useRef, useMemo, useState } from "react";
import JoditEditor from "jodit-react";
import "./TextEditor.scss";
import { FormHelperText } from "@mui/material";
import { TEXT_EDITOR_CHARECTER_LIMIT } from "../../../constant/constant";

export default function TextEditor({
  id = "",
  value,
  onChange,
  isError = false,
  errorMsg = "",
  placeholder,
  enableDragAndDropFileToEditor = false,
  insertImageAsBase64URI = false,
  showXPathInStatusbar = false,
  showCharsCounter = false,
  showWordsCounter = false,
  toolbarAdaptive = false,
  toolbarSticky = false,
  removeDefaultButtons = [
    "brush",
    "file",
    "image",
    "fullsize",
    "link",
    "table",
    "paint",
    "source",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "image",
    "table",
    "link",
    "hr",
    "eraser",
    "fullsize",
    "undo",
    "redo",
    "ol",
  ],
  defaultButtons = [
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "|",
    "left",
    "center",
    "right",
    "justify",
  ],
}) {
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      width: "100%",
      enableDragAndDropFileToEditor: enableDragAndDropFileToEditor,
      placeholder: placeholder || "Start typings...",
      buttons: defaultButtons,
      uploader: { insertImageAsBase64URI: insertImageAsBase64URI },
      removeButtons: removeDefaultButtons,
      showXPathInStatusbar: showXPathInStatusbar,
      showCharsCounter: showCharsCounter,
      showWordsCounter: showWordsCounter,
      toolbarAdaptive: toolbarAdaptive,
      toolbarSticky: toolbarSticky,
      limitChars: TEXT_EDITOR_CHARECTER_LIMIT,
      insertImageAsBase64URI: false,
      replaceDataURIToBlobIdInView: false,
    }),
    []
  );

  const [charLimitError, setcharLimitError] = useState(false);
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    if (
      items
        .replace(/<[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/\s+/g, "")
        .trim().length >= TEXT_EDITOR_CHARECTER_LIMIT
    ) {
      setcharLimitError(true);
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.includes("image")) {
        event.preventDefault(); // Prevent pasting images
        break;
      }
    }
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const debouncedHandleChange = debounce((e) => {
    setcharLimitError(false);

    const length = e
      .replace(/<[^>]*>/g, "")
      .replace(/&nbsp;/g, "")
      .replace(/\s+/g, "")
      .trim().length;
    if (length >= TEXT_EDITOR_CHARECTER_LIMIT) {
      setcharLimitError(true);
      return;
    } else {
      onChange(e);
    }
  }, 500);

  function handleChange(e) {
    debouncedHandleChange(e);
  }

  // TODO:
  // function handleChange(e) {
  //   setcharLimitError(false);
  //   const length = e
  //     .replace(/<[^>]*>/g, "")
  //     .replace(/&nbsp;/g, "")
  //     .replace(/\s+/g, "")
  //     .trim().length;
  //   if (length >= TEXT_EDITOR_CHARECTER_LIMIT) {
  //     setcharLimitError(true);
  //     return;
  //   } else onChange(e);
  // }
  return (
    <div className="overideTextArea">
      <div className="textAreaBody" onPaste={handlePaste}>
        {isError && value?.length === 0 && (
          <FormHelperText className="errorText">{errorMsg}</FormHelperText>
        )}

        <JoditEditor
          id={id}
          ref={editor}
          value={value}
          config={config}
          tabIndex={1}
          // onBlur={(content) => onChange(content, editor)}
          onChange={(e) => handleChange(e)}
        />
      </div>
      {charLimitError ? (
        <p className="charLimitText">Max character limit reached</p>
      ) : (
        ""
      )}
    </div>
  );
}
