import React, { useState, useEffect, useRef } from "react";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  ORG_NAME,
} from "../../../../constant/constant";
import { DataGrid } from "@mui/x-data-grid";
import "./contractormapping.scss";
import { useNavigate } from "react-router-dom";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";

const ContractorMapping = ({
  divisions,
  contractorGrades,
  contractorTypes,
  searchClient,
  allClients,
  isSearchClientLoading,
  searchSubcontractors,
  subContractor,
  setchangesearchauto,
  changesearchauto,
  postAddSubcontractorsClientWise,
  setissubsuccess,
  issubsuccess,
  setsubmit,
  setFinalaData,
  FinalaData,
}) => {
  const history = useNavigate();
  const [clients, setClients] = useState("");
  const [subcontarctor, setSubcontarctor] = useState("");

  const [subcontarctorId, setSubcontarctorId] = useState("");
  const [subcontarctorname, setSubcontarctorName] = useState("");

  const [subcontractorTypes, setsubcontractorTypes] = useState({});
  const [grade, setgrade] = useState({});
  const [division, setDivision] = useState({});

  const autoComp = useRef(null);
  const autoCompSubContractor = useRef(null);
  //const [FinalaData, setFinalaData] = useState([]);
  // Error states
  const [isflag, setIsflag] = useState(false);
  const [isGradeError, setIsGradeError] = useState(false);
  const [issubConError, setIssubConError] = useState(false);
  const [issubcontarctorSearchError, setIssubcontarctorSearchError] =
    useState(false);
  const [issubcontarctorDuplicate, setissubcontarctorDuplicate] =
    useState(false);

  const onChangeDivision = (e) => {
    setDivision(divisions.filter((val) => val.key === e?.target?.value)[0]);
  };
  const onChangeContractType = (e) => {
    setsubcontractorTypes(
      contractorTypes.filter((val) => val.key === e?.target?.value)[0]
    );
  };
  const onChangeGrade = (e) => {
    setgrade(contractorGrades.filter((val) => val.key === e?.target?.value)[0]);
  };

  const onChangeClients = (value) => {
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    setClients(value);
    setDivision({});
  };
  const onChangeSubContractor = (value) => {
    setSubcontarctor(value);
    setSubcontarctorId(value?.key);
    setSubcontarctorName(value?.value);
    setIssubcontarctorSearchError(false);
    //setissubcontarctorDuplicate(false);
  };
  const typeOnchange = (event, value) => {
    searchClient(event?.target?.value);
  };
  const typeOnchangeSubcontractor = (event, value) => {
    searchSubcontractors(event?.target?.value);
  };
  useEffect(() => {
    if (parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)) {
      setClients({
        key: ORG_ID(),
        value: ORG_NAME(),
      });
      setchangesearchauto({ key: ORG_ID(), value: ORG_NAME() });
    }
  }, [LOGIN_ORG_ID(), ORG_NAME(), ORG_ID()]);
  useEffect(() => {
    if (ORG_ID() != LOGIN_ORG_ID()) {
      setClients({
        key: ORG_ID(),
        value: ORG_NAME(),
      });
      setchangesearchauto({ key: ORG_ID(), value: ORG_NAME() });
    }
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "client", headerName: "Client", flex: 2 },
    { field: "subcontactname", headerName: "Sub-Contractor Name", flex: 2 },
    { field: "type", headerName: "Type", flex: 2 },
    { field: "grade", headerName: "Grade", flex: 1 },

    {
      field: "",
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return <div onClick={() => handleChangeDelete(rows?.row)}>X</div>;
      },
    },
  ];

  const handleClickAddSubContractor = (value) => {
    let errors = false;

    if (!division.key) {
      setIsflag(true);
      errors = true;
    }
    if (!grade.key) {
      setIsGradeError(true);
      errors = true;
    }
    if (!subcontractorTypes.key) {
      setIssubConError(true);
      errors = true;
    }

    if (subcontarctor === null || subcontarctor === "") {
      setIssubcontarctorSearchError(true);
      errors = true;
    }
    if (errors === false) {
      const dataobj = {
        id: Math.floor(Math.random() * 100),
        userId: parseInt(subcontarctorId),
        clientId: parseInt(changesearchauto?.key)
          ? parseInt(changesearchauto?.key)
          : parseInt(ORG_ID()),
        divisionId: division?.key,
        gradeId: grade?.key,
        typeId: subcontractorTypes?.key,
        subcontactname: subcontarctorname,
        type: subcontractorTypes?.value,
        grade: grade?.value,
        client: changesearchauto?.value ? changesearchauto?.value : ORG_NAME(),
      };
      const checkExist = FinalaData?.filter((val) => {
        return (
          val.userId === dataobj?.userId && val.clientId === dataobj?.clientId
        );
      });
      if (checkExist.length === 0) {
        setFinalaData([...FinalaData, dataobj]);
        setissubcontarctorDuplicate(false);
      } else {
        setissubcontarctorDuplicate(true);
      }
    }
  };
  const handleChangeDelete = (item) => {
    const removeUser = FinalaData?.filter((x) => x.id !== item.id);
    setFinalaData(removeUser);
  };
  // Final Data Submit Function
  const addSubContractorFinalData = () => {
    const removedKeys = ["subcontactname", "type", "grade", "id"];

    const newArray = FinalaData.map((obj) => {
      const newObj = { ...obj };
      removedKeys.forEach((key) => delete newObj[key]);
      return newObj;
    });
    if (newArray.length > 0) {
      const fData = {
        mapContractorToClient: newArray,
      };

      postAddSubcontractorsClientWise(fData);
    }
  };
  useEffect(() => {
    if (issubsuccess && setsubmit) {
      setFinalaData([]);
      setsubcontractorTypes({});
      setgrade({});
      setDivision({});
      setClients("");
      setSubcontarctor("");
      setSubcontarctorId("");
      setSubcontarctorName("");
      setchangesearchauto({ key: "", value: "" });
      setIsflag(false);
      setIsGradeError(false);
      setIssubConError(false);
      setIssubcontarctorSearchError(false);
      setissubcontarctorDuplicate(false);
    }
  }, [issubsuccess, setsubmit]);

  return (
    <>
      <div className="overidePersonal">
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">Sub Contractor Mapping</div>
        </Grid>
      </div>

      <Grid container>
        <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
          {ORG_ID() !== LOGIN_ORG_ID() ? (
            <FormControl fullWidth>
              <FormLabels label="Client" isRequired={false} />
              <InputField
                isRequired={false}
                type="text"
                placeholder={"Add Client Name Here..."}
                value={clients?.value}
                disabled={ORG_ID() !== 1}
              />
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <FormLabels label="Select Client" isRequired={false} />

              <SearchAutoComplete
                name="orgName"
                placeholderText="Search Client"
                noOptionsTextMsg="No Client"
                optionsData={allClients?.data ?? []}
                isKeyValuePair={true}
                typeOnchange={(event, value) => typeOnchange(event, value)}
                value={clients}
                onChange={(event, value) => onChangeClients(value)}
                isLoading={isSearchClientLoading}
                autoCompClear={autoComp}
                disabled={parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
          <FormControl fullWidth>
            <FormLabels label="Search Sub contractor" isRequired={true} />

            <SearchAutoComplete
              name="orgName"
              placeholderText="Search Sub contractor"
              noOptionsTextMsg="Search Sub contractor"
              optionsData={subContractor ?? []}
              isKeyValuePair={true}
              typeOnchange={(event, value) =>
                typeOnchangeSubcontractor(event, value)
              }
              onChange={(event, value) => onChangeSubContractor(value)}
              value={subcontarctor}
              autoCompClear={autoCompSubContractor}
              isError={issubcontarctorSearchError}
              errorMsg={
                issubcontarctorSearchError
                  ? "Sub contractor is Required"
                  : issubcontarctorDuplicate
                  ? "Contractor Mapping Already exist"
                  : ""
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
          <FormControl fullWidth={true}>
            <FormLabels label="Sub-Contractor Type" isRequired={true} />
            <SelectMenu
              placeholder={"Please Select"}
              listData={contractorTypes}
              onchangehandler={(e) => onChangeContractType(e)}
              value={subcontractorTypes?.key ?? ""}
              isError={issubConError}
              errorMsg={issubConError ? "Sub contractor Type is Required" : ""}
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
          <FormControl fullWidth={true}>
            <FormLabels label="Grade" isRequired={true} />
            <SelectMenu
              placeholder={"Please Select"}
              listData={contractorGrades}
              value={grade?.key ?? ""}
              onchangehandler={(e) => onChangeGrade(e)}
              isError={isGradeError}
              errorMsg={isGradeError ? "Grade is Required" : ""}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} xs={12} sm={12} md={6} mr={2} mt={2}>
          <FormControl fullWidth={true}>
            <FormLabels label="Division" isRequired={true} />
            <SelectMenu
              placeholder={"Please Select"}
              listData={divisions}
              value={division?.key ?? ""}
              onchangehandler={(e) => onChangeDivision(e)}
              isError={isflag}
              errorMsg={isflag ? "Division is Required" : ""}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} xs={12} sm={12} md={6} mr={2} mt={5.4}>
          <div>
            <Buttons
              id="btnAddContractor"
              varientAddIconBlue={true}
              label={"Add Sub-Contractor"}
              onClick={(e) => handleClickAddSubContractor(e)}
            />
          </div>
        </Grid>
      </Grid>
      <Grid mt={3}>
        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            <DataGrid
             className="custom-data-grid"
              rows={FinalaData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
            />
          </div>
        </div>
      </Grid>

      <Grid item container mt={3} spacing={2} className="lmssavecancelbtn">
        <Grid lg={2} xs={12} sm={6} md={4}>
          <Buttons
            varientText={true}
            label="Cancel"
            onClick={() => history(-1)}
          />
        </Grid>
        <Grid lg={2} xs={12} sm={6} md={4}>
          <Buttons
            varientContained={true}
            label={"Save"}
            onClick={() => addSubContractorFinalData()}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContractorMapping;
