export const INSPECTION_REPORT_TYPE = [
  { key: 2, value: "Inspector Summary" },
  { key: 3, value: "Inspection Date Summary" },
  { key: 4, value: "Inspection Per Day Per Inspector" },
  { key: 5, value: "Inspection Date Inspector Summary" },
];

export const SUPER_ADMIN_INSPECTION_REPORT_TYPE = [
  { key: 1, value: "Client Summary" },
  { key: 2, value: "Inspector Summary" },
  { key: 3, value: "Inspection Date Summary" },
  { key: 4, value: "Inspection Per Day Per Inspector" },
  { key: 5, value: "Inspection Date Inspector Summary" },
];

export const SW_REPORT_FILTER = [
  {
    key: 7,
    value: "7 days",
  },
  {
    key: 14,
    value: "14 days",
  },
  {
    key: 30,
    value: "30 days",
  },
  {
    key: 60,
    value: "60 days",
  },
  {
    key: 12,
    value: "Custom",
  },
  {
    key: 13,
    value: "Reset",
  },
];

export const SORT_BY = [
  {
    key: 5,
    value: "Top 5",
  },
  {
    key: 10,
    value: "Top 10",
  },
  {
    key: 20,
    value: "Top 20",
  },
  // {
  //   key: 50,
  //   value: "Top 50",
  // },
];
