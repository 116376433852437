import { contractorType } from "../constants/contractorType";

export const getContractorList = (data) => ({
  type: contractorType.GET_CONTRACTOR_LIST_PENDING,
  data,
});

export const getContractorListSuccess = (data) => ({
  type: contractorType.GET_CONTRACTOR_LIST_SUCCESS,
  data,
});

export const getContractorListEmailSuccess = () => ({
  type: contractorType.GET_CONTRACTOR_LIST_EMAIL_SUCCESS,
});

export const getContractorListFailure = (error) => ({
  type: contractorType.GET_CONTRACTOR_LIST_FAILURE,
  error,
});

export const createContractor = (data) => ({
  type: contractorType.POST_CONTRACTOR_PENDING,
  data,
});

export const createContractorSuccess = (data) => ({
  type: contractorType.POST_CONTRACTOR_SUCCESS,
  data,
});

export const createContractorFailure = (error) => ({
  type: contractorType.POST_CONTRACTOR_FAILURE,
  error,
});

export const updateContractor = (payload) => ({
  type: contractorType.UPDATE_CONTRACTOR_PENDING,
  payload: payload,
});

export const updateContractorSuccess = (data) => ({
  type: contractorType.UPDATE_CONTRACTOR_SUCCESS,
  data,
});

export const updateContractorFailure = (error) => ({
  type: contractorType.UPDATE_CONTRACTOR_FAILED,
  error,
});

export const clearContractor = () => ({
  type: contractorType.CLEAR_CONTRACTOR,
  data: {},
});

export const getContractorDetails = (contractorId) => ({
  type: contractorType.GET_CONTRACTOR_DETAILS_PENDING,
  contractorId,
});

export const getContractorDetailsSuccess = (data) => ({
  type: contractorType.GET_CONTRACTOR_DETAILS_SUCCESS,
  data,
});

export const getContractorDetailsFailure = (error) => ({
  type: contractorType.GET_CONTRACTOR_DETAILS_FAILED,
  error,
});

export const deleteContractor = (payload) => ({
  type: contractorType.DELETE_CONTRACTOR_PENDING,
  payload: payload,
});

export const deleteContractorSuccess = (payload) => ({
  type: contractorType.DELETE_CONTRACTOR_SUCCESS,
  payload: payload,
});

export const deleteContractorFailure = (payload) => ({
  type: contractorType.DELETE_CONTRACTOR_FAILED,
  payload: payload,
});

export const getPersonalContractorList = (data) => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_LIST_PENDING,
  data,
});

export const getPersonalContractorListSuccess = (data) => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_LIST_SUCCESS,
  data,
});

export const getPersonalContractorListEmailSuccess = () => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_LIST_EMAIL_SUCCESS,
});

export const getPersonalContractorListFailure = (error) => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_LIST_FAILURE,
  error,
});

export const createPersonalContractor = (data) => ({
  type: contractorType.POST_PERSONAL_CONTRACTOR_PENDING,
  data,
});

export const createPersonalContractorSuccess = (data) => ({
  type: contractorType.POST_PERSONAL_CONTRACTOR_SUCCESS,
  data,
});

export const createPersonalContractorFailure = (error) => ({
  type: contractorType.POST_PERSONAL_CONTRACTOR_FAILURE,
  error,
});

export const updatePersonalContractor = (payload) => ({
  type: contractorType.UPDATE_PERSONAL_CONTRACTOR_PENDING,
  payload: payload,
});

export const updatePersonalContractorSuccess = (data) => ({
  type: contractorType.UPDATE_PERSONAL_CONTRACTOR_SUCCESS,
  data,
});

export const updatePersonalContractorFailure = (error) => ({
  type: contractorType.UPDATE_PERSONAL_CONTRACTOR_FAILED,
  error,
});

export const getPersonalContractorDetails = (contractorId) => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_PENDING,
  contractorId,
});

export const getPersonalContractorDetailsSuccess = (data) => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_SUCCESS,
  data,
});

export const getPersonalContractorDetailsFailure = (error) => ({
  type: contractorType.GET_PERSONAL_CONTRACTOR_DETAILS_FAILED,
  error,
});

export const deletePersonalContractor = (payload) => ({
  type: contractorType.DELETE_PERSONAL_CONTRACTOR_PENDING,
  payload: payload,
});

export const deletePersonalContractorSuccess = (payload) => ({
  type: contractorType.DELETE_PERSONAL_CONTRACTOR_SUCCESS,
  payload: payload,
});

export const deletePersonalContractorFailure = (payload) => ({
  type: contractorType.DELETE_PERSONAL_CONTRACTOR_FAILED,
  payload: payload,
});

export const getOnboardedContractorList = (data) => ({
  type: contractorType.GET_ONBOARDED_CONTRACTOR_LIST_PENDING,
  data,
});

export const getOnboardedContractorSuccess = (data) => ({
  type: contractorType.GET_ONBOARDED_CONTRACTOR_LIST_SUCCESS,
  data,
});

export const getOnboardedContractorEmailSuccess = () => ({
  type: contractorType.GET_ONBOARDED_CONTRACTOR_LIST_EMAIL_SUCCESS,
});

export const getOnboardedContractorFailure = (error) => ({
  type: contractorType.GET_ONBOARDED_CONTRACTOR_LIST_FAILURE,
  error,
});

export const updateOnboardedContractorList = (payload) => ({
  type: contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST,
  payload,
});

export const updateOnboardedContractorListSuccess = (data) => ({
  type: contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST_SUCCESS,
  data,
});

export const updateOnboardedContractorListFailure = (error) => ({
  type: contractorType.UPDATE_ONBOARDED_CONTRACTOR_LIST_FAILURE,
  error,
});

export const getContractorConfigurationDetsils = (data) => ({
  type: contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS,
  data,
});

export const getContractorConfigurationDetsilsSuccess = (data) => ({
  type: contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS_SUCCESS,
  data,
});

export const getContractorConfigurationDetsilsFailure = (error) => ({
  type: contractorType.GET_CONTRACTOR_CONFIGURATION_DETAILS_FAILURE,
  error,
});

export const updateContractorOnboard = (payload) => ({
  type: contractorType.UPDATE_CONTRACTOR_ONBOARD,
  payload,
});

export const updateContractorOnboardSuccess = (data) => ({
  type: contractorType.UPDATE_CONTRACTOR_ONBOARD_SUCCESS,
  data,
});

export const updateContractorOnboardFailure = (error) => ({
  type: contractorType.UPDATE_CONTRACTOR_ONBOARD_FAILURE,
  error,
});

export const updateContractorOnboardStatus = (payload) => ({
  type: contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS,
  payload,
});

export const updateContractorOnboardStatusSuccess = (data) => ({
  type: contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS_SUCCESS,
  data,
});

export const updateContractorOnboardStatusFailure = (error) => ({
  type: contractorType.UPDATE_CONTRACTOR_ONBOARD_STATUS_FAILURE,
  error,
});
export const searchSubcontractor = (searchText) => ({
  type: contractorType.GET_SEARCH_SUBCONTRACTOR,
  searchText,
});

export const searchSubcontractorSuccess = (data) => ({
  type: contractorType.GET_SEARCH_SUBCONTRACTOR_SUCCESS,
  data,
});

export const searchSubcontractorFailure = (error) => ({
  type: contractorType.GET_SEARCH_SUBCONTRACTOR_FAILURE,
  error,
});
// add sub conractor in client wise
export const createSubContractorClientwise = (data) => ({
  type: contractorType.POST_CONTRACTOR_CLIENT_WISE_PENDING,
  data,
});

export const createSubContractorClientwiseSuccess = (data) => ({
  type: contractorType.POST_CONTRACTOR_CLIENT_WISE_SUCCESS,
  data,
});

export const createSubContractorClientwiseFailure = (error) => ({
  type: contractorType.POST_CONTRACTOR_CLIENT_WISE_FAILURE,
  error,
});
