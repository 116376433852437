import React from "react";
import Popover from "@mui/material/Popover";
import { Card, CardContent, Box, CardActions } from "@mui/material";
import "./FilterCommonStatusSelect.scss";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/system";
import moment from "moment";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { ORG_ID } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationList = ({
  id,
  open,
  anchorEl,
  handleClose,
  NotifiactionListDatas,
  UpdateNotifiactionListData,
}) => {
  const navigate = useNavigate();
  const StyledPopover = styled(Popover)({
    "& .MuiPaper-root": {
      maxHeight: 400,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "0px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#eee",
      },
    },
  });
  const cardClickHandler = async (item) => {
    const data = {
      notificationId: [item?.notificationId],
      status: 1,
    };
    if (parseInt(ORG_ID()) === item.orgId) {
      try {
        await UpdateNotifiactionListData(data);
        handleClose();
        navigate(item?.browserUrl);
      } catch (error) {
        console.error("Error updating notification data:", error);
      }
    } else {
      localStorage.setItem("orgId", item.orgId);
      localStorage.setItem("divisionId", 0);
      localStorage.setItem("orgName", item.orgName);
      localStorage.setItem("orgLogo", item.orgLogo);
      localStorage.setItem("ProcoreCompanyId", item.procoreCompanyId);
      localStorage.setItem("IsProcoreConnected", item.procoreCompanyName);
      setTimeout(() => {
        toast("Organization Switched");
        navigate(item?.browserUrl);
      }, 3);
    }
  };

  function formatDate(dateTimeString) {
    let dateTime = moment(dateTimeString);
    return dateTime.format("DD MMMM HH:mm");
  }

  return (
    <>
      <ToastContainer />
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases">
          <CardContent>
            <Box
              className="custom_filter_box"
              sx={{
                width: 420,
                maxHeight: 500,
                pb: 2,
              }}
            >
              <div className="NotificationMainconatiner">
                <div className="NotificationHeading">Notifications</div>
                {NotifiactionListDatas?.notificationList?.map((item, index) => {
                  return (
                    <div
                      className="notificationConatiner"
                      key={index}
                      onClick={() => cardClickHandler(item)}
                    >
                      <div className="bellicon">
                        <NotificationsIcon className="notificationIcon"></NotificationsIcon>
                      </div>
                      <div
                        className="notificationTextContainer"
                        style={{
                          fontWeight: item?.status === 0 ? "bold" : "normal",
                        }}
                      >
                        <div className="NotificationTitleContainer">
                          <div className="notificationTitle">{item?.title}</div>
                        </div>
                        <div className="notificationSubTitle">
                          {item?.description}
                        </div>
                        <div
                          className="notificationSubTitle"
                          style={{
                            fontWeight: "normal",
                          }}
                        >
                          {item?.orgName}
                        </div>
                      </div>
                      <div className="notificationDateTimeUnread">
                        <div
                          className="notificationDateTime"
                          style={{
                            fontWeight: item?.status === 0 ? "bold" : "normal",
                          }}
                        >
                          {formatDate(item?.createdOnDt)}{" "}
                        </div>
                        {item?.status === 0 && (
                          <div className="unreadDot">
                            <Badge color="primary" variant="dot"></Badge>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Box>
          </CardContent>
        </Card>
      </StyledPopover>
    </>
  );
};

export default NotificationList;
