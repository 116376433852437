import { accident, weather } from "../assets/index";

const Weather = [
  {
    key: 1,
    value: "Dry",
    img: accident.bounding,
  },
  {
    key: 2,
    value: "Snowy",
    img: accident.acUnit,
  },
  {
    key: 3,
    value: "Windy",
    img: accident.air,
    // img: accident.bounding,
  },
  {
    key: 4,
    value: "Wet",
    img: accident.wet,
  },
  {
    key: 5,
    value: "Fog",
    img: accident.lensBlur,
  },
  {
    key: 6,
    value: "Cloudy",
    img: weather.cloud,
  },
  {
    key: 7,
    value: "Partly Cloudy",
    img: weather.partlycloud,
  },
  {
    key: 8,
    value: "Sunny",
    img: weather.lightmode,
  },
  {
    key: 9,
    value: "Raining",
    img: weather.rainyy,
  },
  {
    key: 10,
    value: "Snowing",
    img: accident.acUnit,
  },
];

export default Weather;
