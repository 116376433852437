import React, { useEffect, useState } from "react";
import DivisionList from "../component/divisions/divisionList";
import * as divisionsAction from "../../../redux/actions/divisionsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCESS_DENIED,
  ADMIN_ADD_DIVISION,
  ADMIN_EDIT_DIVISION,
  ADMIN_VIEW_DIVISION,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { API_STATUS } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";
import { InactiveClient } from "../../../utils/helper";

const DivisionContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState(false);

  const defaultData = {
    page: 1,
    pageSize: 10,
    search: "",
    isSendMail: false,
  };

  const divisionsListData = useSelector((state) => state.divisionsList);

  const getDivisionsListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(divisionsAction.getDivisionsList(data));
  };
  const isLoading = useSelector(
    (state) =>
      state.divisionsList?.isLoading || state.deleteDivisions?.isLoading
  );

  const deleteDivisions = useSelector((state) => state.deleteDivisions);

  useEffect(() => {
    getDivisionsListData(defaultData);
  }, []);
  useEffect(() => {
    if (deleteDivisions?.status === API_STATUS.SUCCESS) {
      getDivisionsListData(defaultData);
    }
  }, [deleteDivisions]);

  const addDivision = () => {
    if (InactiveClient()) return;
    navigate(ADMIN_ADD_DIVISION);
  };

  const handleViewOnClick = (divisionId) => {
    navigate(ADMIN_VIEW_DIVISION, {
      state: { divisionId: divisionId },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_VIEW_DIVISION, {
        state: { divisionId: rows.row.divisionId, editMode: true },
      });
    }
  };

  const handleEditOnClick = (divisionId) => {
    if (InactiveClient()) return;
    navigate(ADMIN_EDIT_DIVISION, {
      state: { divisionId: divisionId, editMode: true },
    });
  };
  const handleDeleteOnClick = (divisionId) => {
    if (InactiveClient()) return;
    dispatch(divisionsAction.deleteDivisions(divisionId));
  };

  useEffect(() => {
    if (divisionsListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Divisions has been sent to registered email ID");
    }
  }, [divisionsListData]);

  return (
    <>
      {checkPrivileges([12, 49, 50, 51]) ? (
        <>
          <ToastContainer />

          <DivisionList
            divisionsListData={isLoading ? [] : divisionsListData?.data}
            getDivisionsListData={getDivisionsListData}
            isLoading={isLoading}
            addDivision={addDivision}
            handleDeleteOnClick={handleDeleteOnClick}
            handleEditOnClick={handleEditOnClick}
            handleViewOnClick={handleViewOnClick}
            onRowClick={onRowClick}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default DivisionContainer;
