import { observationType } from "../constants/observationType";

// POST add Observation
export const observation = (data) => ({
  type: observationType.POST_OBSERVATION_PENDING,
  data,
});
export const observationSuccess = (data) => ({
  type: observationType.POST_OBSERVATION_SUCCESS,
  data,
});
export const observationFailure = (error) => ({
  type: observationType.POST_OBSERVATION_FAILURE,
  error,
});

// GET observation details
export const getObservtionDetails = (insObservationId) => ({
  type: observationType.GET_OBSERVATION_DETAILS_PENDING,
  insObservationId,
});

export const getObservtionDetailsSuccess = (data) => ({
  type: observationType.GET_OBSERVATION_DETAILS_SUCCESS,
  data,
});

export const getObservtionDetailsFailure = (error) => ({
  type: observationType.GET_OBSERVATION_DETAILS_FAILURE,
  error,
});

// GET open items List
export const getOpenItemsList = (data) => ({
  type: observationType.GET_LIST_OPEN_ITEM_PENDING,
  data,
});

export const getOpenItemListSuccess = (data) => ({
  type: observationType.GET_LIST_OPEN_ITEM_SUCCESS,
  data,
});

export const getOpenItemListFailure = (error) => ({
  type: observationType.GET_LIST_OPEN_ITEM_FAILURE,
  error,
});

// POST update Observation
export const getObservationUpdatePending = (data) => ({
  type: observationType.POST_UPDATE_OBSERVATION_PENDING,
  data,
});

export const getObservationUpdateSuccess = (data) => ({
  type: observationType.POST_UPDATE_OBSERVATION_SUCCESS,
  data,
});

export const getObservationUpdateFailure = (error) => ({
  type: observationType.POST_UPDATE_OBSERVATION_FAILURE,
  error,
});

// GET Observation list
export const getListOfObservationPending = (data) => ({
  type: observationType.GET_LIST_OF_OBSERVATION_PENDING,
  data,
});

export const getListOfObservationSuccess = (data) => ({
  type: observationType.GET_LIST_OF_OBSERVATION_SUCCESS,
  data,
});

export const getListOfObservationEmailSuccess = () => ({
  type: observationType.GET_LIST_OF_OBSERVATION_EMAIL_SUCCESS,
});

export const getListOfObservationFailure = (error) => ({
  type: observationType.GET_LIST_OF_OBSERVATION_FAILURE,
  error,
});
