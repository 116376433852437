import AddReport from "../components/addReport";
import React, { useEffect, useState, useRef } from "react";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import {
  API_STATUS,
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  ORG_NAME,
  USER_ID,
} from "../../../../constant/constant";
import { INSSTATUS } from "../../../../constant/storm-water/constant";
import {
  formatRainfall,
  getFormatedDate,
  validateFileSize,
} from "../../../../utils/helper";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import { ACCESS_DENIED, SWDASHBOARD } from "../../../../constant/routeContant";
import { useLocation } from "react-router-dom";
import PreviousOpenItems from "../components/addReport/previousOpenItems";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import * as clientAction from "../../../../redux/actions/clientAction";
import { useNavigate } from "react-router-dom";
import { fileUpload } from "../../../../services/fileUploadService";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import * as swprojectAction from "../../../../redux/storm-water/actions/projectAction";
import {
  generateInspectionGroundStabilization,
  generateSwInspectionLotDisturbance,
  generateSwInspectionObservation,
  getQuestionIdsFromCategories,
  listInspectionCategoryObject,
  processCategories,
  processProjectPhaseData,
  processRainfallData,
  transformCategoryData,
  transformProjectStatus,
  transformSampleTaken,
  transformSoilType,
  updateCategoryPercent,
  updateQuestion,
  updateSubCategoryPercent,
  validateInspectionCategories,
  validateSwInspectionGroundStabilization,
  validateSwInspectionLotDisturbance,
  validateSwInspectionObservation,
} from "../../../../utils/swInspectionHelper";
import { getReferenceData } from "../../../../redux/actions/incpectionAction";

const AddReportContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const reportDetail = location?.state?.row;
  const editMode = location?.state?.editMode;
  const addMode = location?.state?.addMode;
  const addDirectInspection = location?.state?.addDirectInspection ?? false;
  const [constructionActivityId, setConstructionActivityId] = useState(null);
  const [isConstructionActivityId, setIsConstructionActivityId] =
    useState(null);
  const [questionSetId, setQuestionSetId] = useState(null);
  const [questionSetUpId, setQuestionSetUpId] = useState(null);
  const [inspectionTypeId, setInspectionTypeId] = useState();
  const [stateValue, setStateValue] = useState();
  const [inspectionId, setInspectionId] = useState();
  const hiddenAttendeeSignatureInput = useRef([]);
  const [imageCheck, setImageCheck] = useState(false);
  const [isNotAddMedia, setIsNotAddMedia] = useState(false);
  const [listPermitte, setListPermitte] = useState();
  const [isFluid, setIsFluid] = useState(false);
  const [frequencyInDays, setFrequencyInDays] = useState(null);
  const [dateRangeError, setDateRangeError] = useState(false);
  const [errorFrequencyInDays, setErrorFrequencyInDays] = useState(false);
  const [fileUploadData, setFileUploadData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [uniqeFileError, setUniqeFileError] = useState(false);
  const [isNorthCarolina, setIsNorthCarolina] = useState(false);
  const [swInspectionConfQuestionAns, setSwInspectionConfQuestionAns] =
    useState([]);
  const [swInspectionRainfallData, setSwInspectionRainfallData] = useState([]);
  const [swInspectionCurrentProjectPhase, setSwInspectionCurrentProjectPhase] =
    useState([]);
  const [swInspectionGroundStabilization, setSwInspectionGroundStabilization] =
    useState(generateInspectionGroundStabilization(5));
  const [swInspectionObservation, setSwInspectionObservation] = useState(
    generateSwInspectionObservation(5)
  );
  const [swInspectionLotDisturbance, setSwInspectionLotDisturbance] = useState(
    generateSwInspectionLotDisturbance(5)
  );
  const [frequencyValueError, setFrequencyValueError] = useState(false);
  const swRainFallData = useSelector((state) => state?.swrainFallData);
  const swGetRainFallDataList = (data) => {
    dispatch(swprojectAction.swGetRainfallDataList(data));
  };

  const dateRangeHandler = (value) => {
    const newValue = parseInt(value, 10);
    if (
      newValue === null ||
      (!isNaN(newValue) && newValue >= 1 && newValue <= 365)
    ) {
      setFrequencyInDays(newValue);
      setDateRangeError(false);
    } else {
      setFrequencyInDays(null);
      setDateRangeError(true);
    }
  };
  const getSwQuestionSetDetail = useSelector(
    (state) => state.getSwQuestionSetDetail
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const date = new Date();
  const isSignatureProfile = userInfo?.signatureUrl;
  const [notAcceptable, setAcceptable] = useState("");
  const [routeSatus, setRouteSatus] = useState();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${hours}:${formattedMinutes}`;
  const mapId = reportDetail?.setupId;
  const [projects, setProjects] = useState();
  const [clients, setClients] = useState();
  const [firstClientError, setFirstClientError] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState();
  const [isMediaAllowed, setIsMediaAllowed] = useState(false);
  const [isGeorgiaState, setIsGeorgiaState] = useState(false);
  const [projectStatus, setProjectStatus] = useState();
  const [previousItem, setPreviousItem] = useState([]);
  const [listInspectionCategory, setListInspectionCategory] = useState([]);
  const [alsoSubmitRoutineInspection, setAlsoSubmitRoutineInspection] =
    useState(false);
  const [isDirectIns, setIsDirectIns] = useState(null);

  const handleChangeDefault = () => {
    setFrequencyValue();
    setAlsoSubmitRoutineInspection(!alsoSubmitRoutineInspection);
  };

  function handleAllProjectSearch(event, value) {
    if (clients?.key) {
      setFirstClientError(false);
      dispatch(
        assetsAction.getAssetDynomoList({
          orgId: clients?.key,
          searchText: event.target.value,
          type: 5,
        })
      );
    } else {
      setFirstClientError(true);
    }
  }

  useEffect(() => {
    if (parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)) {
      setClients({
        key: ORG_ID(),
        value: ORG_NAME(),
      });
      // setFormValue({ ...formValue, customerName: userInfo?.clientName });
    }
  }, [LOGIN_ORG_ID(), ORG_NAME(), ORG_ID()]);

  useEffect(() => {
    if (ORG_ID() != LOGIN_ORG_ID()) {
      setClients({
        key: ORG_ID(),
        value: ORG_NAME(),
      });
    }
  }, []);

  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  const allClients = useSelector((state) => state.searchAllClientsReducerData);
  const isSearchClientLoading = allClients.isLoading;
  const searchClient = (event) => {
    dispatch(clientAction.searchAllClients(event.target.value));
  };

  const getSwProjectDetailData = useSelector(
    (state) => state.getSwProjectDetail
  );

  const getProjectDetails = (data) => {
    dispatch(projectAction.getProjectDetails(data));
  };

  const inspectionQuestionSetList = useSelector(
    (state) => state.getQuestionSetOnProject
  );

  const isQuestionSetLoading = useSelector(
    (state) => state.swQuestionSetList?.isLoading
  );

  const getInspectionQuestionSetListData = (data) => {
    dispatch(swInspectionAction.getQuestionSetOnProject(data));
  };

  const inspectionHeaderDetails = useSelector(
    (state) => state.getInspectionHeaderDetails
  );
  const getInspectionHeaderDetailsData = (data) => {
    dispatch(swInspectionAction.getInspectionHeaderDetails(data));
  };

  let Flags;
  if (isNorthCarolina) {
    Flags = [6, 3, 4, 8, 9, 5, 11, 12];
  } else {
    Flags = [6, 3, 4, 8, 9, 5];
  }

  const refData = {
    flags: Flags,
    orgId: clients?.key,
  };
  const { weather } = useSelector((state) => state.inspection);
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };
  useEffect(() => {
    getSwReferenceData(refData);
  }, [clients, isNorthCarolina]);

  useEffect(() => {
    dispatch(getReferenceData());
  }, []);

  const getSwQuestionSetDetailData = (data) => {
    dispatch(swInspectionAction.getSwQuestionSetDetail(data));
  };

  const getSwInspectionDetail = useSelector(
    (state) => state.getSwInspectionDetail
  );
  const getSwInspectionDetailData = (data) => {
    dispatch(swInspectionAction.getSwInspectionDetail(data));
  };

  const getInTyFeByInTyList = useSelector((state) => state.getInTyFeByInTyList);
  const getInTyFeByInTyListData = (data) => {
    dispatch(swInspectionAction.getInTyFeByInTyList(data));
  };
  const createSwInspection = useSelector((state) => state.createSwInspection);
  const updateSwInspection = useSelector((state) => state.updateSwInspection);
  const swOpenItemDetail = useSelector((state) => state.swOpenItemDetail);
  const getOpenItemDetailData = (data) => {
    dispatch(swInspectionAction.createSwOpenItemDetail(data));
  };
  const createSwInspectionCloseItem = useSelector(
    (state) => state.createSwInspectionCloseItem
  );
  const createSwInspectionCloseItemData = (data) => {
    dispatch(swInspectionAction.createSwInspectionCloseItem(data));
  };

  const createSwInspectionItemComment = useSelector(
    (state) => state.createSwInspectionItemComment
  );
  const createSwInspectionItemCommentData = (data) => {
    dispatch(swInspectionAction.createSwInspectionItemComment(data));
  };

  const intialState = {
    questionSetName: "",
    projectName: "",
    projectId: "",
    customerName: "",
    projectNo: "",
    primaryInspector: "",
    dateofInspection: date,
    timeofInspection: formattedTime,
    WeatherAtTimeofInspection: "",
    soilTypeId: "",
    rainfall: "",
    constructionStageId: 0,
    sampleTaken: null,
    primaryPermitteeName: "",
    disturbedAcreage: "",
    permitteOrNPDESNumber: "",
    primaryPermitteName: "",
    generalComments: "",
  };
  const [formValue, setFormValue] = useState(intialState);

  const showLoader = useSelector(
    (state) =>
      state.getSwQuestionSetProjectMapList?.isLoading ||
      state.getSwQuestionSetDetail?.isLoading ||
      state.getInTyFeByInTyList?.isLoading ||
      state.createSwInspection.isLoading ||
      state.getSwInspectionDetail?.isLoading ||
      state.fileUpload?.isLoading ||
      state.updateSwInspection.isLoading ||
      state.swOpenItemDetail.isLoading ||
      state.createSwInspectionCloseItem.isLoading ||
      state.createSwInspectionItemComment.isLoading ||
      state.getSwReference.isLoading ||
      state.swQuestionSetList?.isLoading ||
      state.getSwProjectDetail?.isLoading ||
      state.getInspectionHeaderDetails?.isLoading ||
      state?.swrainFallData?.isLoading
  );

  const statusApi = useSelector(
    (state) =>
      state.getSwQuestionSetDetail?.isError ||
      state.getSwInspectionDetail?.isError
  );

  const handleFrequencyChange = (e) => {
    setFrequencyValue(e.target.value);
  };
  const onChangeProjects = (value) => {
    setInspectionTypeId();
    setFrequencyValue();
    setQuestionSetId();
    setPreviousItem([]);
    setListInspectionCategory([]);
    setQuestionSetUpId(null);
    setConstructionActivityId(null);
    setProjectStatus(null);
    setFormValue({
      ...formValue,
      projectId: value?.key,
      questionSetName: "",
      projectName: "",
      customerName: "",
      projectNo: "",
      primaryInspector: "",
      dateofInspection: date,
      timeofInspection: formattedTime,
      WeatherAtTimeofInspection: "",
      soilTypeId: "",
      rainfall: "",
      constructionStageId: 0,
      sampleTaken: null,
      primaryPermitteeName: "",
      disturbedAcreage: "",
      permitteOrNPDESNumber: "",
      primaryPermitteName: "",
      generalComments: "",
    });
    setProjects(value);
    if (value?.key) {
      const data = {
        projectId: value?.key,
        orgId: clients?.key,
      };
      getInspectionQuestionSetListData(data);
    }
    setSwInspectionConfQuestionAns([]);
    setSwInspectionRainfallData([]);
    setSwInspectionCurrentProjectPhase([]);
    setSwInspectionGroundStabilization([]);
    setSwInspectionObservation([]);
    setSwInspectionLotDisturbance([]);
  };

  const onChangeClients = (value) => {
    setInspectionTypeId();
    setFrequencyValue();
    setQuestionSetId();
    setPreviousItem([]);
    setListInspectionCategory([]);
    setQuestionSetUpId(null);
    setConstructionActivityId(null);
    setProjectStatus(null);
    setProjects();
    setFormValue({
      ...formValue,
      projectId: "",
      questionSetName: "",
      projectName: "",
      customerName: "",
      projectNo: "",
      primaryInspector: "",
      dateofInspection: date,
      timeofInspection: formattedTime,
      WeatherAtTimeofInspection: "",
      soilTypeId: "",
      rainfall: "",
      constructionStageId: 0,
      sampleTaken: null,
      primaryPermitteeName: "",
      disturbedAcreage: "",
      permitteOrNPDESNumber: "",
      primaryPermitteName: "",
      generalComments: "",
    });
    setClients(value);
    setSwInspectionConfQuestionAns([]);
    setSwInspectionRainfallData([]);
    setSwInspectionCurrentProjectPhase([]);
    setSwInspectionGroundStabilization([]);
    setSwInspectionObservation([]);
    setSwInspectionLotDisturbance([]);
  };

  const handleConstructionActivitySetChange = (event) => {
    setConstructionActivityId(event?.target?.value);
  };

  const handleInspectionTypeSetChange = (event) => {
    setInspectionTypeId(event?.target?.value);
    setFrequencyValue();
  };

  const handleQuestionSetChange = (event) => {
    setQuestionSetId(event.target.value);
    const data = {
      questionSetId: event.target.value,
      projectId: projects?.key,
      orgId: clients?.key,
    };
    getSwInspectionDetailData(data);
    setIsRainFallError(false);
  };

  useEffect(() => {
    if (addDirectInspection || !editMode) {
      setInspectionId();
      setInspectionTypeId();
      setQuestionSetId();
      setQuestionSetUpId();
      setFrequencyValue();
      setStateValue();
      setProjects();
      setPreviousItem([]);
      setListInspectionCategory([]);
      setConstructionActivityId(null);
      setProjectStatus(null);
      setFormValue({
        ...formValue,
        projectId: "",
        questionSetName: "",
        projectName: "",
        customerName: "",
        projectNo: "",
        primaryInspector: "",
        dateofInspection: date,
        timeofInspection: formattedTime,
        WeatherAtTimeofInspection: "",
        soilTypeId: "",
        rainfall: "",
        constructionStageId: 0,
        sampleTaken: null,
        primaryPermitteeName: "",
        disturbedAcreage: "",
        permitteOrNPDESNumber: "",
        primaryPermitteName: "",
        generalComments: "",
      });
    }
  }, [addDirectInspection, editMode]);

  const openItemObject = (data) => {
    return data.map((item) => {
      return {
        id: Date.now() + Math.floor(Math.random() * 100),
        isComment: false,
        isCloseItem: false,
        commentText: "",
        actionTakenText: "",
        dateClosedText: date,
        dateClosedError: false,
        actionTakenError: false,
        commentTextError: false,
        locationId: item?.locationId,
        questionId: item?.questionId,
        location: item?.location,
        itemDetail: item?.itemDetail,
        item: item?.item,
        inspectionId: item?.inspectionId,
        files: item?.files,
        dateRecorded: item?.dateRecorded,
        dateClosed: item?.dateClosed,
        comment: item?.comment,
        actionTaken: item?.actionTaken,
        closedId: "",
        closedIdValue: "",
        isClosedIdError: false,
        status: item?.status,
      };
    });
  };
  useEffect(() => {
    if (getSwInspectionDetail?.status === API_STATUS.SUCCESS) {
      if (
        (createSwInspectionItemComment.status === API_STATUS.SUCCESS ||
          createSwInspectionCloseItem.status === API_STATUS.SUCCESS) &&
        !getSwInspectionDetail?.data?.setupId &&
        projects &&
        questionSetId
      ) {
        const getQuestionIds =
          getSwQuestionSetDetail?.data?.questionSetCatDetails
            ?.map((x) =>
              x?.subcategories?.flatMap((y) =>
                y?.questions?.filter((z) => z.isSelected).map((xz) => xz.key)
              )
            )
            .flat()
            .filter((id) => id !== undefined);

        const data = {
          questionList: getQuestionIds,
          setupId: null,
          projectId: projects?.key,
          questionSetId: questionSetId,
          itemStatus: 2,
          orgId: clients?.key,
        };
        getOpenItemDetailData(data);
      }

      if (
        (createSwInspectionItemComment.status === API_STATUS.SUCCESS ||
          createSwInspectionCloseItem.status === API_STATUS.SUCCESS) &&
        getSwInspectionDetail?.data?.setupId
      ) {
        const getQuestionIds = getSwInspectionDetail?.data?.categoriesView
          ?.map((x) =>
            x?.subcategoriesView?.flatMap((y) =>
              y?.insQuesAnsReportView?.map((z) => z.questionId)
            )
          )
          .flat()
          .filter((id) => id !== undefined);

        const data = {
          questionList: getQuestionIds,
          setupId: null,
          projectId: getSwInspectionDetail?.data?.projectId,
          questionSetId: getSwInspectionDetail?.data?.questionSetId,
          itemStatus: 2,
          orgId: clients?.key,
        };
        getOpenItemDetailData(data);
      }
    }
  }, [
    getSwInspectionDetail,
    createSwInspectionItemComment,
    createSwInspectionCloseItem,
    projects,
    questionSetId,
    clients,
  ]);

  useEffect(() => {
    if (swOpenItemDetail.status === API_STATUS.SUCCESS) {
      setPreviousItem(openItemObject([...swOpenItemDetail.data.openItemList]));
    }
  }, [swOpenItemDetail]);

  const [isContractorProfileSignature, setIsContractorProfileSignature] =
    useState(false);
  const [selectedContractorSignature, setSelectedContractorSignature] =
    useState("");
  const [signaureFileError, setSignaureFileError] = useState(false);
  const [signaureFileErrorMessage, setSignaureFileErrorMessage] = useState("");

  const uploadContractorSignatureFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setSignaureFileErrorMessage(validation.errorMessage);
      setSignaureFileError(true);
      return;
    }
    setIsContractorProfileSignature(true);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
        setSignaureFileError(false);
      })
      .catch((error) => {
        setSignaureFileErrorMessage("Error while uploading files.");
        setSignaureFileError(true);
      });
  };

  const contractorsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleContractorSignatureUploadMethod(fileUploaded);
  };
  const handleContractorSignatureUploadMethod = (data) => {
    uploadContractorSignatureFile(data);
  };
  useEffect(() => {
    if (isContractorProfileSignature) {
      if (fileUploadData && imageCheck) {
        setSelectedContractorSignature(fileUploadData?.files[0]?.fileUrl);
      }
    }
  }, [fileUploadData, imageCheck]);
  useEffect(() => {
    if (isSignatureProfile) {
      setSelectedContractorSignature(isSignatureProfile);
    }
  }, [isSignatureProfile]);

  useEffect(() => {
    if (getSwQuestionSetDetail.status === API_STATUS.SUCCESS) {
      setListInspectionCategory(
        listInspectionCategoryObject(getSwQuestionSetDetail.data)
      );
      setPreviousItem([]);
      setSwInspectionGroundStabilization(
        generateInspectionGroundStabilization(5)
      );
      setSwInspectionObservation(generateSwInspectionObservation(5));
      setSwInspectionLotDisturbance(generateSwInspectionLotDisturbance(5));
    }
  }, [getSwQuestionSetDetail]);

  useEffect(() => {
    if (getSwInspectionDetail?.status === API_STATUS.SUCCESS) {
      if (
        !editMode &&
        questionSetId &&
        addMode &&
        !getSwInspectionDetail?.data?.setupId
      ) {
        const data = {
          questionSetId: questionSetId,
          orgId: clients?.key,
        };
        getSwQuestionSetDetailData(data);
      }
      if (questionSetId && !mapId && !getSwInspectionDetail?.data?.setupId) {
        const data = {
          questionSetId: questionSetId,
          orgId: clients?.key,
        };
        getSwQuestionSetDetailData(data);
      }
    }
  }, [
    reportDetail,
    questionSetId,
    editMode,
    addMode,
    getSwInspectionDetail,
    clients,
  ]);

  useEffect(() => {
    if (
      inspectionTypeId &&
      getSwInspectionDetail?.status === API_STATUS.SUCCESS
    ) {
      const data = {
        orgId: clients?.key ? clients?.key : ORG_ID(),
        inspectionTypeId: inspectionTypeId ? inspectionTypeId : "",
        stateId: stateValue ? stateValue : "",
        projectId: projects?.key ? projects?.key : "",
      };
      getInTyFeByInTyListData(data);
    }
  }, [inspectionTypeId, stateValue, projects, getSwInspectionDetail, clients]);

  useEffect(() => {
    if ((projects || mapId) && clients) {
      const headerDetail = {
        orgId: clients?.key ? clients?.key : ORG_ID(),
        setupId: mapId ? mapId : "",
        projectId: projects?.key ? projects?.key : "",
      };
      getInspectionHeaderDetailsData(headerDetail);
    }
  }, [projects, getSwInspectionDetail, clients]);

  const openItemHandler = (questionId, locationId, targetName, event) => {
    const openItemClone = [...previousItem];
    openItemClone.forEach((openItem) => {
      if (
        openItem?.questionId === questionId &&
        openItem?.locationId === locationId
      ) {
        if (targetName === "comments") {
          openItem.isComment = !openItem.isComment;
          openItem.isCloseItem = false;
        }
        if (targetName === "closeItem") {
          openItem.isCloseItem = !openItem.isCloseItem;
          openItem.isComment = false;
        }
        if (targetName === "commentText") {
          openItem.commentText = event;
        }
        if (targetName === "commentSubmit") {
          if (openItem.commentText === "") {
            openItem.commentTextError = true;
          } else {
            openItem.commentTextError = false;
            setAcceptable(parseInt(questionId));
            const data = {
              locationId: parseInt(locationId),
              comment: event,
              userId: parseInt(USER_ID()),
            };
            createSwInspectionItemCommentData(data);
          }
        }
        if (targetName === "dateClosedText") {
          openItem.dateClosedText = event;
        }
        if (targetName === "closedId") {
          const closevalue = getSwReference?.data?.ItemClosedActionList?.find(
            (item) => item.key === event
          );
          openItem.closedId = closevalue?.key;
          openItem.closedIdValue = closevalue?.value;
        }
        if (targetName === "actionTakenText") {
          openItem.actionTakenText = event;
        }
        if (targetName === "closeItemSubmit") {
          if (!openItem.dateClosedText) {
            openItem.dateClosedError = true;
          } else {
            openItem.actionTakenError = false;
            openItem.dateClosedError = false;
            openItem.isClosedIdError = false;
            setAcceptable(parseInt(questionId));
            const data = {
              linkedReportId: reportDetail?.reportId,
              listLocationId: [parseInt(locationId)],
              dateClosed: getFormatedDate(event),
              closedId: parseInt(openItem?.closedId),
              actionTaken: openItem.actionTakenText,
              userId: parseInt(USER_ID()),
            };
            createSwInspectionCloseItemData(data);
          }
        }
        if (targetName === "cancelComment") {
          openItem.isComment = false;
        }
        if (targetName === "cancelCloseItem") {
          openItem.isCloseItem = false;
        }
      }
    });
    setPreviousItem(openItemClone);
  };

  const handleRadioType = (
    targetValue,
    questionValue,
    subCategoryValue,
    categoryValue,
    targetName,
    event
  ) => {
    const listInspectionCategoryClone = [...listInspectionCategory];

    listInspectionCategoryClone.forEach((category) => {
      if (category?.key === categoryValue?.key) {
        category.listInspectionSubCategory.forEach((subCategory) => {
          if (subCategoryValue?.key === subCategory?.key) {
            subCategory.isAnswerCat = true;
            subCategory.listInspectionQuestionAnswer.forEach((question) => {
              updateQuestion(
                question,
                targetValue,
                targetName,
                questionValue,
                event,
                setAcceptable
              );
            });
            updateSubCategoryPercent(subCategory);
          }
        });
        updateCategoryPercent(category);
      }
    });
    setListInspectionCategory(listInspectionCategoryClone);
  };

  const formchangeHnadle = (
    targetValue,
    questionValue,
    subCategoryValue,
    categoryValue,
    targetName,
    notAppce
  ) => {
    const listInspectionCategoryClone = [...listInspectionCategory];
    listInspectionCategoryClone.forEach((category) => {
      if (category?.key === categoryValue?.key) {
        category.listInspectionSubCategory.forEach((subCategory) => {
          if (subCategoryValue?.key === subCategory?.key) {
            subCategory.listInspectionQuestionAnswer.forEach((question) => {
              if (question?.key == questionValue?.key) {
                if (targetName === "significantDeviation") {
                  question.significantDeviation = targetValue;
                }
                if (targetName === "proposedDimension") {
                  question.proposedDimension = targetValue;
                }
                if (targetName === "actualDimension") {
                  question.actualDimension = targetValue;
                }
                question.notAcceptableList.forEach((notAcc) => {
                  if (notAcc.id === notAppce.id) {
                    if (targetName === "textFiled") {
                      notAcc.addLocationText = targetValue;
                    }
                    if (targetName === "fileChange") {
                      notAcc.addLocatioImg = targetValue.photo;
                      notAcc.addLocatioImgId = targetValue.fileDataId;
                    }
                    if (targetName === "itemFound") {
                      const asasas = question.options.find(
                        (item) => item.key === targetValue
                      );
                      notAcc.itemFoundKey = asasas.key;
                      notAcc.itemFoundValue = asasas.value;
                    }
                    if (targetName === "textArea") {
                      notAcc.itemDetails = targetValue;
                    }
                    if (targetName === "pushData" && notAcc.addLocationText) {
                      notAcc.addLocatioList?.push({
                        textVaue: notAcc.addLocationText,
                        imgUrl: notAcc.addLocatioImg,
                        fileId: notAcc.addLocatioImgId,
                        id: Date.now() + Math.floor(Math.random() * 100),
                      });
                      notAcc.addLocationText = "";
                      notAcc.addLocatioImg = "";
                      notAcc.addLocatioImgId = "";
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
    setListInspectionCategory(listInspectionCategoryClone);
  };

  const formchangeThreeHnadle = (
    targetValue,
    questionValue,
    subCategoryValue,
    categoryValue,
    targetName
  ) => {
    const listInspectionCategoryClone = [...listInspectionCategory];
    listInspectionCategoryClone.forEach((category) => {
      if (category?.key === categoryValue?.key) {
        category.listInspectionSubCategory.forEach((subCategory) => {
          if (subCategoryValue?.key === subCategory?.key) {
            subCategory.listInspectionQuestionAnswer.forEach((question) => {
              if (question?.key == questionValue?.key) {
                if (targetName === "significantDeviation") {
                  question.significantDeviationValue = targetValue;
                }
                if (targetName === "proposedDimension") {
                  question.proposedDimensionValue = targetValue;
                }
                if (targetName === "actualDimension") {
                  question.actualDimensionValue = targetValue;
                }
              }
            });
          }
        });
      }
    });
    setListInspectionCategory(listInspectionCategoryClone);
  };
  const deleteform = (
    targetValue,
    questionValue,
    subCategoryValue,
    categoryValue
  ) => {
    const listInspectionCategoryClone = [...listInspectionCategory];
    listInspectionCategoryClone.forEach((category) => {
      if (category?.key === categoryValue?.key) {
        category.listInspectionSubCategory.forEach((subCategory) => {
          if (subCategoryValue?.key === subCategory?.key) {
            subCategory.listInspectionQuestionAnswer.forEach((question) => {
              if (question?.key == questionValue?.key) {
                question.notAcceptableList = question.notAcceptableList?.filter(
                  (notAcc) => {
                    return notAcc.id !== targetValue;
                  }
                );
              }
            });
          }
        });
      }
    });
    setListInspectionCategory(listInspectionCategoryClone);
  };

  const deleteHandler = (
    categorys,
    subcategorys,
    questions,
    notAppce,
    noteee,
    deleteEvent
  ) => {
    const listInspectionCategoryClone = [...listInspectionCategory];
    listInspectionCategoryClone.forEach((category) => {
      if (category?.key === categorys?.key) {
        category.listInspectionSubCategory.forEach((subCategory) => {
          if (subcategorys?.key === subCategory?.key) {
            subCategory.listInspectionQuestionAnswer.forEach((question) => {
              if (question?.key == questions?.key) {
                question.notAcceptableList.forEach((notAcc) => {
                  if (notAcc.id === notAppce.id) {
                    if (deleteEvent === "deleteText") {
                      notAcc.addLocatioList = notAcc.addLocatioList?.filter(
                        (item) => item.id !== noteee.id
                      );
                    }
                    notAcc.addLocatioList.forEach((addOcationLi) => {
                      if (addOcationLi.id === noteee.id) {
                        if (deleteEvent === "deleteImg") {
                          delete addOcationLi.imgUrl;
                          delete addOcationLi.fileId;
                          delete addOcationLi.uniqeFileErrorMessage;
                        }
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    setListInspectionCategory(listInspectionCategoryClone);
  };

  const [clearCreateSwInspection, setClearCreateSwInspection] = useState(false);
  const [successScreens, setSuccessScreens] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const onSubmit = (data) => {
    setSuccessScreens(true);
    setClearCreateSwInspection(true);
    if (data?.inspectionId === 0) {
      dispatch(swInspectionAction.createSwInspection(data));
    } else {
      dispatch(swInspectionAction.updateSwInspection(data));
    }
  };

  const [isWeatherAtTimeofInspection, setIsWeatherAtTimeofInspection] =
    useState(false);
  const [isSoilTypeId, setIsSoilTypeId] = useState(false);
  const [isStageOfConstruction, setIsStageOfConstruction] = useState(false);
  const [isSampleTaken, setIsSampleTaken] = useState(false);
  const [isProjectStatus, setIsProjectStatus] = useState(false);
  const [isRainFallError, setIsRainFallError] = useState(false);
  const [
    isCurrentApproximateDisturbedAcreage,
    setIsCurrentApproximateDisturbedAcreage,
  ] = useState(false);
  const [isProjectName, setIsProjectName] = useState(false);
  const [isProjectNo, setIsProjectNo] = useState(false);
  const [isCustomerName, setIsCustomerName] = useState(false);
  const [isPrimaryInspector, setIsPrimaryInspector] = useState(false);
  const [isDateofInspection, setIsDateofInspection] = useState(false);
  const [isTimeofInspection, setIsTimeofInspection] = useState(false);

  const [signatureError, setSignatureError] = useState(false);
  const [characterLimit, setCharaterLimit] = useState(false);

  function mappedData() {
    let errors = false;

    const setCharLimitError = () => {
      if (formValue?.generalComments.length > 2000) {
        setCharaterLimit(true);
        errors = true;
      } else {
        setCharaterLimit(false);
      }
    };

    const setSignatureErrorCheck = () => {
      if (!isSignatureProfile && !selectedContractorSignature) {
        setSignatureError(true);
        errors = true;
      }
    };

    const setInspectionCategoryError = () => {
      if (
        Array.isArray(listInspectionCategory) &&
        listInspectionCategory.length === 0
      ) {
        errors = true;
        const message = "something went wrong please try again";
        toast(message);
        setTimeout(() => {
          addDirectInspection
            ? window.location.reload()
            : navigate(SWDASHBOARD);
        }, 3000);
      }
    };

    const validateFrequencyInDays = () => {
      if (frequencyValue === 7 && !frequencyInDays) {
        setDateRangeError(true);
        errors = true;
      } else {
        setDateRangeError(false);
      }
    };

    const validateFrequency = () => {
      if (
        (inspectionTypeId === 1 || inspectionTypeId === 3) &&
        (frequencyValue === undefined ||
          frequencyValue === "" ||
          frequencyValue === 0 ||
          !frequencyValue)
      ) {
        setFrequencyValueError(true);
        errors = true;
      } else {
        setFrequencyValueError(false);
      }
    };
    const validateMandatoryFields = () => {
      getInTyFeByInTyList?.data?.listInspectionTypeFields?.forEach((field) => {
        const mandatoryFields = [
          { key: 1, value: formValue?.projectName, setter: setIsProjectName },
          { key: 2, value: formValue?.projectNo, setter: setIsProjectNo },
          {
            key: 4,
            value: formValue?.WeatherAtTimeofInspection,
            setter: setIsWeatherAtTimeofInspection,
          },
          {
            key: 5,
            value: formValue?.rainfall,
            setter: setIsRainFallError,
            checkEmpty: true,
          },
          { key: 6, value: formValue?.sampleTaken, setter: setIsSampleTaken },
          { key: 23, value: projectStatus, setter: setIsProjectStatus },
          {
            key: 18,
            value: constructionActivityId,
            setter: setIsConstructionActivityId,
          },
          { key: 9, value: formValue?.customerName, setter: setIsCustomerName },
          {
            key: 13,
            value: formValue?.primaryInspector,
            setter: setIsPrimaryInspector,
          },
          {
            key: 14,
            value: formValue?.constructionStageId,
            setter: setIsStageOfConstruction,
          },
          {
            key: 16,
            value: formValue?.disturbedAcreage,
            setter: setIsCurrentApproximateDisturbedAcreage,
          },
          { key: 17, value: formValue?.soilTypeId, setter: setIsSoilTypeId },
        ];

        mandatoryFields.forEach(
          ({ key, value, setter, checkEmpty = false }) => {
            if (
              field?.key === key &&
              field.isMandatory &&
              value === (checkEmpty ? "" : null)
            ) {
              setter(true);
              errors = true;
            }
          }
        );
      });
    };

    setCharLimitError();
    setSignatureErrorCheck();
    setInspectionCategoryError();
    validateFrequencyInDays();
    validateMandatoryFields();
    validateFrequency();

    if (
      errors ||
      validateInspectionCategories(
        listInspectionCategory,
        setListInspectionCategory
      )
    ) {
      return false;
    }

    const data = {
      inspectionId: inspectionId || 0,
      setupId: questionSetUpId || 0,
      status: INSSTATUS[0].key,
      inspectionDate: formValue.dateofInspection
        ? getFormatedDate(formValue.dateofInspection)
        : getFormatedDate(date),
      inspectionTime: formValue.timeofInspection || formattedTime,
      weatherTypeId: formValue.WeatherAtTimeofInspection?.key || 0,
      rainfall: formValue.rainfall || 0,
      constructionStageId: formValue.constructionStageId || 0,
      isSampleTaken:
        formValue.sampleTaken === "3"
          ? true
          : formValue.sampleTaken === "1"
          ? false
          : null,
      projectStatus:
        projectStatus === "1" ? true : projectStatus === "2" ? false : null,
      constructionActivityId: constructionActivityId || 0,
      verticalId: 0,
      disturbedAcreage: formValue.disturbedAcreage || "",
      comment: formValue?.generalComments || "",
      preparedBy: parseInt(USER_ID()),
      signature: selectedContractorSignature || isSignatureProfile,
      soilTypeId: formValue?.soilTypeId?.key,
      permitteOrNPDESNumber: formValue?.permitteOrNPDESNumber,
      primaryPermitteName: formValue?.primaryPermitteName,
      projectId: formValue?.projectId ? parseInt(formValue?.projectId) : 0,
      questionSetId: questionSetId ? parseInt(questionSetId) : 0,
      inspectionType: inspectionTypeId ? parseInt(inspectionTypeId) : 0,
      frequencyId: frequencyValue ? frequencyValue : 0,
      alsoSubmitRoutineInspection:
        parseInt(inspectionTypeId) === 3 && !isDirectIns
          ? true
          : alsoSubmitRoutineInspection,
      listInspectionCategory: listInspectionCategory,
      swInspectionConfQuestionAns,
      swInspectionRainfallData: processRainfallData(swInspectionRainfallData),
      swInspectionCurrentProjectPhase: processProjectPhaseData(
        swInspectionCurrentProjectPhase
      ),
      swInspectionGroundStabilization: validateSwInspectionGroundStabilization(
        swInspectionGroundStabilization
      ),
      swInspectionObservation: validateSwInspectionObservation(
        swInspectionObservation
      ),
      swInspectionLotDisturbance: validateSwInspectionLotDisturbance(
        swInspectionLotDisturbance
      ),
      orgId: clients?.key,
      frequencyInDays:
        frequencyInDays && frequencyValue === 7 ? parseInt(frequencyInDays) : 0,
    };

    return data;
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
      return;
    }

    setRouteSatus(2);
    setIsSubmit(true);

    const result = data.listInspectionCategory.map((category) => {
      const item = {
        isSkipped: false,
        categoryId: category.key,
        sequenceNumber: category.sequenceNumber,
        listInspectionSubCategory: category.listInspectionSubCategory.map(
          (subCategory) => {
            return {
              isSkipped: false,
              subCategoryId: subCategory.key,
              sequenceNumber: subCategory.sequenceNumber,
              listInspectionQuestionAnswer:
                subCategory.listInspectionQuestionAnswer.map((question) => {
                  return {
                    sequenceId: question.quesNo,
                    answerMstId: question.radioSelectiovalue
                      ? parseInt(question.radioSelectiovalue)
                      : question.responseType === 2
                      ? 4
                      : 0,
                    questionId: parseInt(question.key),
                    actualDimension: question.actualDimensionValue,
                    proposedDimension: question.proposedDimensionValue,
                    significantDeviation:
                      question.significantDeviationValue || 0,
                    listInspectionQuestionAnswerDet:
                      question?.responseType === 2
                        ? [
                            {
                              details: question.textValueQuestion,
                              itemFoundId: question.itemFoundKey
                                ? question.itemFoundKey
                                : 0,
                              listInspectionDetailsLocation: [],
                            },
                          ]
                        : Array.isArray(question.notAcceptableList)
                        ? question.notAcceptableList.map((detail) => {
                            return {
                              details:
                                question?.responseType === 2
                                  ? question.textValueQuestion
                                  : detail.itemDetails,
                              itemFoundId:
                                detail.itemFoundKey ||
                                question.itemFoundKey ||
                                0,
                              listInspectionDetailsLocation:
                                detail.addLocatioList
                                  ?.map((location) => {
                                    if (
                                      location.textVaue !== undefined &&
                                      parseInt(question.radioSelectiovalue) ===
                                        2
                                    ) {
                                      return {
                                        location: location.textVaue,
                                        files: [
                                          {
                                            subrootId: 0,
                                            fileId: location.addLocatioImgId
                                              ? parseInt(
                                                  location.addLocatioImgId
                                                )
                                              : location.fileId
                                              ? parseInt(location.fileId)
                                              : 0,
                                          },
                                        ],
                                      };
                                    }
                                    return null;
                                  })
                                  .filter((item) => item !== null),
                            };
                          })
                        : [],
                  };
                }),
            };
          }
        ),
      };
      return item;
    });

    data.listInspectionCategory = result;
    onSubmit(data);
  };

  const saveData = async () => {
    let errors = false;

    const handleError = (message, action) => {
      errors = true;
      toast(message);
      setTimeout(action, 3000);
    };

    if (
      Array.isArray(listInspectionCategory) &&
      listInspectionCategory?.length === 0
    ) {
      const action = addDirectInspection
        ? window?.location?.reload
        : () => navigate(SWDASHBOARD);
      handleError("Something went wrong, please try again", action);
    }

    if (errors) return false;

    const {
      dateofInspection,
      timeofInspection,
      WeatherAtTimeofInspection,
      rainfall,
      constructionStageId,
      sampleTaken,
      projectStatus,
      soilTypeId,
      disturbedAcreage,
      permitteOrNPDESNumber,
      primaryPermitteName,
      generalComments,
      projectId,
    } = formValue;

    const data = {
      inspectionId: inspectionId || 0,
      setupId: questionSetUpId || 0,
      status: INSSTATUS[1].key,
      inspectionDate: getFormatedDate(dateofInspection || date),
      inspectionTime: timeofInspection || formattedTime,
      weatherTypeId: WeatherAtTimeofInspection?.key || 0,
      rainfall: rainfall || 0,
      constructionStageId: constructionStageId || 0,
      isSampleTaken:
        sampleTaken === "3" ? true : sampleTaken === "1" ? false : null,
      projectStatus:
        projectStatus === "1" ? true : projectStatus === "2" ? false : null,
      constructionActivityId: constructionActivityId || 0,
      verticalId: 0,
      signature: selectedContractorSignature || isSignatureProfile,
      soilTypeId: soilTypeId?.key || 0,
      disturbedAcreage: disturbedAcreage || "",
      permitteOrNPDESNumber,
      primaryPermitteName,
      comment: generalComments || "",
      preparedBy: parseInt(USER_ID()),
      projectId: projectId ? parseInt(projectId) : 0,
      questionSetId: questionSetId ? parseInt(questionSetId) : 0,
      inspectionType: inspectionTypeId ? parseInt(inspectionTypeId) : 0,
      frequencyId: frequencyValue ? frequencyValue : 0,
      alsoSubmitRoutineInspection:
        parseInt(inspectionTypeId) === 3 && !isDirectIns
          ? true
          : alsoSubmitRoutineInspection,
      listInspectionCategory,
      orgId: clients?.key,
      frequencyInDays:
        frequencyInDays && frequencyValue === 7 ? parseInt(frequencyInDays) : 0,
      swInspectionConfQuestionAns,
      swInspectionRainfallData: processRainfallData(swInspectionRainfallData),
      swInspectionCurrentProjectPhase: processProjectPhaseData(
        swInspectionCurrentProjectPhase
      ),
      swInspectionGroundStabilization: validateSwInspectionGroundStabilization(
        swInspectionGroundStabilization
      ),
      swInspectionObservation: validateSwInspectionObservation(
        swInspectionObservation
      ),
      swInspectionLotDisturbance: validateSwInspectionLotDisturbance(
        swInspectionLotDisturbance
      ),
    };
    data.listInspectionCategory = processCategories(
      data.listInspectionCategory
    );
    onSubmit(data);
  };

  useEffect(() => {
    dispatch(swInspectionAction.clearSwInspection());
  }, []);

  const successScreen = (id, successMsg, label1, label4, label2) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={routeSatus === 1 ? SWDASHBOARD : SWDASHBOARD}
        label1={label1}
        label4={label4}
        label2={label2}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (reportDetail && clients && editMode) {
      setQuestionSetId(reportDetail?.questionSetId);
      setQuestionSetUpId(reportDetail?.setupId);
      setInspectionTypeId(reportDetail?.inspectionTypeId);
      setStateValue(reportDetail?.stateId);
      setListPermitte(reportDetail?.listPermitte);
      setInspectionId(reportDetail?.reportId);
      const data = {
        InspectionId: reportDetail?.reportId,
        orgId: clients?.key,
      };
      getSwInspectionDetailData(data);

      const dataProject = {
        projectId: reportDetail?.projectId,
        orgId: clients?.key,
      };
      getProjectDetails(dataProject);
    }
  }, [reportDetail, editMode, clients]);
  useEffect(() => {
    if (inspectionHeaderDetails?.status === API_STATUS?.SUCCESS) {
      setListPermitte(inspectionHeaderDetails?.data?.listPermitte);
    }

    if (inspectionHeaderDetails?.data && !editMode) {
      setFormValue({
        ...formValue,
        rainfall: inspectionHeaderDetails?.data?.rainfall
          ? formatRainfall(inspectionHeaderDetails?.data?.rainfall)
          : "",
      });
      setProjectStatus(
        inspectionHeaderDetails?.data?.projectStatus ? "1" : "2"
      );
    }
  }, [inspectionHeaderDetails, editMode, addMode]);

  useEffect(() => {
    if (
      inspectionHeaderDetails?.status === API_STATUS?.SUCCESS &&
      inspectionHeaderDetails?.data
    ) {
      setIsMediaAllowed(inspectionHeaderDetails?.data?.isMediaAllowed);
      setFrequencyInDays(inspectionHeaderDetails?.data?.frequencyInDays);
      setIsFluid(inspectionHeaderDetails?.data?.isFluid);
    }
  }, [inspectionHeaderDetails]);

  useEffect(() => {
    if (
      ((inspectionHeaderDetails?.status === API_STATUS?.SUCCESS &&
        inspectionHeaderDetails?.data) ||
        (getSwInspectionDetail?.status === API_STATUS.SUCCESS &&
          getSwInspectionDetail?.isLoading === false)) &&
      !addDirectInspection &&
      parseInt(inspectionTypeId) === 1 &&
      !editMode
    ) {
      setFrequencyValue(
        getSwInspectionDetail?.data?.frequencyId
          ? getSwInspectionDetail?.data?.frequencyId
          : inspectionHeaderDetails?.data?.frequencyId
      );
    }
    if (
      getSwInspectionDetail?.status === API_STATUS.SUCCESS &&
      getSwInspectionDetail?.isLoading === false &&
      editMode
    ) {
      setFrequencyValue(getSwInspectionDetail?.data?.frequencyId);
    }
  }, [inspectionHeaderDetails, getSwInspectionDetail, editMode]);

  useEffect(() => {
    if (addMode && reportDetail) {
      setQuestionSetId(reportDetail?.questionSetId);
      setQuestionSetUpId(reportDetail?.setupId);
      setInspectionTypeId(reportDetail?.inspectionTypeId);
      setStateValue(reportDetail?.stateId);
      setListPermitte(reportDetail?.listPermitte);
      setInspectionId(reportDetail?.reportId);
      setFormValue({
        ...formValue,
        questionSetName: reportDetail?.questionSetName,
        customerName: reportDetail?.customer,
        projectName: reportDetail?.projectName,
        projectId: reportDetail?.projectId,
        projectNo: reportDetail?.projectNumber,
        primaryInspector: `${userInfo?.firstName} ${userInfo?.lastName}`,
        reportId: reportDetail?.reportId,
        rainfall: formatRainfall(reportDetail?.rainfall),
      });
    }
  }, [reportDetail, addMode, inspectionHeaderDetails]);

  useEffect(() => {
    if (reportDetail && clients && addMode) {
      const data = {
        questionSetId: reportDetail?.questionSetId,
        projectId: reportDetail?.projectId,
        orgId: clients?.key,
      };
      getSwInspectionDetailData(data);
    }
  }, [reportDetail, addMode, clients]);
  useEffect(() => {
    if (reportDetail && editMode && getInTyFeByInTyList) {
      setFormValue({
        ...formValue,
        questionSetName: reportDetail?.questionSetName,
        permitteOrNPDESNumber: reportDetail?.permitteOrNPDESNumber,
        primaryPermitteName: reportDetail?.primaryPermitteName,
        reportId: reportDetail?.reportId,
      });
    }
  }, [reportDetail, editMode, getInTyFeByInTyList]);

  useEffect(() => {
    if (
      getSwInspectionDetail?.status === API_STATUS.SUCCESS &&
      getSwInspectionDetail?.isLoading === false &&
      addMode &&
      getInTyFeByInTyList?.status === API_STATUS.SUCCESS &&
      getInTyFeByInTyList?.isLoading === false
    ) {
      if (getSwInspectionDetail?.data?.setupId && addMode) {
        setQuestionSetUpId(getSwInspectionDetail?.data?.setupId);
        setFormValue({
          ...formValue,
          // rainfall: getSwInspectionDetail?.data?.rainfall,
          WeatherAtTimeofInspection: {
            key: getSwInspectionDetail?.data?.weatherTypeId,
            value: getSwInspectionDetail?.data?.weather,
          },
          soilTypeId: {
            key: getSwInspectionDetail?.data?.soilTypeId,
            value: getSwInspectionDetail?.data?.soilType,
          },
          constructionStageId: getSwInspectionDetail?.data?.constructionStageId,
          sampleTaken:
            getSwInspectionDetail?.data?.isSampleTaken === null
              ? null
              : getSwInspectionDetail?.data?.isSampleTaken
              ? "3"
              : "1",
          projectStatus: getSwInspectionDetail?.data?.projectStatus ? "1" : "2",
          primaryPermitteeName:
            getSwInspectionDetail?.data?.primaryPermitteName,
          disturbedAcreage: getSwInspectionDetail?.data?.disturbedAcreage,
          permitteOrNPDESNumber:
            getSwInspectionDetail?.data?.permitteOrNPDESNumber,
          generalComments: getSwInspectionDetail?.data?.comment,
        });
        setConstructionActivityId(
          getSwInspectionDetail?.data?.constructionActivityId
        );
        setProjectStatus(
          getSwInspectionDetail?.data?.projectStatus ? "1" : "2"
        );
        setIsDirectIns(getSwInspectionDetail?.data?.isDirectIns);
        const categoryData = getSwInspectionDetail?.data?.categoriesView;
        categoryData.forEach((category) => {
          category.categoryId = category.key;
          category.catAns = category?.subcategoriesView?.some(
            (itemss) => itemss.isAnswerGiven
          );
          const perQuestionCat = 100 / category?.subcategoriesView?.length;
          const completedCat = category.subcategoriesView?.filter(
            (items) => items.isAnswerGiven
          );
          category.catPercent = perQuestionCat * completedCat?.length;
          category.howManyCat = completedCat?.length;

          category.listInspectionSubCategory = category.subcategoriesView.map(
            (subcategory) => {
              subcategory.subCategoryId = subcategory.key;
              subcategory.showBth = subcategory?.insQuesAnsReportView?.some(
                (findItem) => (findItem?.answerMstId === 4 ? false : true)
              );
              subcategory.listInspectionQuestionAnswer =
                subcategory.insQuesAnsReportView.map((questions) => {
                  questions.questionSequenceNumber = questions.sequenceId;
                  questions.key = questions.questionId;
                  questions.value = questions.question;
                  questions.radioSelectiovalue = questions.answerMstId;
                  questions.textValueQuestion =
                    questions.detailsReportView[0]?.details;
                  questions.actualDimensionValue = questions.actualDimension;
                  questions.proposedDimensionValue =
                    questions.proposedDimension;
                  questions.significantDeviationValue =
                    questions.significantDeviation ?? 0;
                  questions.notAcceptableList =
                    questions.responseType === 1 || questions.responseType === 3
                      ? questions.detailsReportView?.length > 0
                        ? questions.detailsReportView.map((notAcc) => {
                            notAcc.id =
                              Date.now() + Math.floor(Math.random() * 100);
                            notAcc.itemFoundKey = notAcc.itemFoundId;
                            notAcc.itemFoundValue = notAcc.itemFound;
                            notAcc.itemDetails = notAcc.details;
                            notAcc.addLocatioList =
                              notAcc.locationsReportView?.map((locationV) => {
                                locationV.textVaue = locationV.location;
                                locationV.id = locationV.locationId;
                                locationV.imgUrl = locationV.files[0]?.filePath;
                                locationV.addLocatioImgId =
                                  locationV.files[0]?.fileId;
                                return locationV;
                              });
                            return notAcc;
                          })
                        : (questions.detailsReportView = [])
                      : [];
                  return questions;
                });
              const perQuestionSub =
                100 / subcategory?.insQuesAnsReportView?.length;
              const completedSub = subcategory.insQuesAnsReportView.filter(
                (items) => items.answerMstId
              );
              subcategory.subPercent = perQuestionSub * completedSub?.length;
              subcategory.howManySub = completedSub?.length;
              return subcategory;
            }
          );
        });

        setSwInspectionConfQuestionAns(
          getSwInspectionDetail?.data?.swInspectionConfQuestionAns
        );
        setSwInspectionGroundStabilization(
          getSwInspectionDetail?.data?.swInspectionGroundStabilization
        );
        setSwInspectionObservation(
          getSwInspectionDetail?.data?.swInspectionObservation
        );
        setSwInspectionLotDisturbance(
          getSwInspectionDetail?.data?.swInspectionLotDisturbance
        );
        setListInspectionCategory(categoryData);
      } else {
        setFormValue({
          ...formValue,
          rainfall: reportDetail?.rainfall
            ? formatRainfall(reportDetail?.rainfall)
            : "",
          WeatherAtTimeofInspection: "",
          soilTypeId: "",
          constructionStageId: "",
          sampleTaken: "",
          projectStatus: "",
          primaryPermitteeName: "",
          disturbedAcreage: "",
          permitteOrNPDESNumber: "",
          generalComments: "",
        });
        setConstructionActivityId();
      }
      if (getSwInspectionDetail?.data?.setupId) {
        const getQuestionIds = getSwInspectionDetail?.data?.categoriesView
          ?.map((x) =>
            x?.subcategoriesView?.flatMap((y) =>
              y?.insQuesAnsReportView?.map((z) => z.questionId)
            )
          )
          .flat()
          .filter((id) => id !== undefined);

        const data = {
          questionList: getQuestionIds,
          setupId: null,
          projectId: getSwInspectionDetail?.data?.projectId,
          questionSetId: getSwInspectionDetail?.data?.questionSetId,
          itemStatus: 2,
          orgId: clients?.key,
        };
        getOpenItemDetailData(data);
      }
    }
  }, [
    getSwInspectionDetail,
    addMode,
    getInTyFeByInTyList,
    reportDetail,
    clients,
  ]);
  useEffect(() => {
    if (
      getSwInspectionDetail?.status === API_STATUS.SUCCESS &&
      getSwInspectionDetail?.isLoading === false &&
      !reportDetail &&
      !editMode &&
      addDirectInspection &&
      questionSetId &&
      getInTyFeByInTyList?.status === API_STATUS.SUCCESS &&
      getInTyFeByInTyList?.isLoading === false
    ) {
      const inspectionData = getSwInspectionDetail?.data;

      if (inspectionData?.setupId) {
        setFormValue({
          questionSetName: inspectionData?.questionSetName,
          projectName: inspectionData?.projectName,
          projectId: inspectionData?.projectId,
          customerName: inspectionData?.inspectorCompanyName,
          projectNo: inspectionData?.projectNumber,
          primaryInspector: inspectionData?.inspectorName,
          dateofInspection: inspectionData?.dateOfInspection,
          timeofInspection: inspectionData?.timeOfInspection,
          // rainfall: inspectionData?.rainfall,
          WeatherAtTimeofInspection: {
            key: inspectionData?.weatherTypeId,
            value: inspectionData?.weather,
          },
          soilTypeId: {
            key: inspectionData.soilTypeId,
            value: inspectionData.soilType,
          },
          constructionStageId: inspectionData?.constructionStageId,
          sampleTaken: transformSampleTaken(inspectionData?.isSampleTaken),
          projectStatus: transformProjectStatus(inspectionData?.projectStatus),
          primaryPermitteeName: inspectionData?.primaryPermitteName,
          disturbedAcreage: inspectionData?.disturbedAcreage,
          permitteOrNPDESNumber: inspectionData?.permitteOrNPDESNumber,
          generalComments: inspectionData?.comment,
        });

        setConstructionActivityId(inspectionData?.constructionActivityId);
        setProjectStatus(transformProjectStatus(inspectionData?.projectStatus));
        setIsDirectIns(inspectionData?.isDirectIns);
        setQuestionSetUpId(inspectionData?.setupId);

        const categoryData = transformCategoryData(
          inspectionData?.categoriesView
        );

        setListInspectionCategory(categoryData);

        setSwInspectionConfQuestionAns(
          getSwInspectionDetail?.data?.swInspectionConfQuestionAns
        );

        if (
          inspectionData?.swInspectionCurrentProjectPhase &&
          inspectionData?.swInspectionCurrentProjectPhase?.length > 0
        ) {
          const updatedInsQuestionOptionList =
            getSwReference?.data?.InsQuestionOptionList?.map((option) => {
              const matchingInspectionData =
                getSwInspectionDetail?.data?.swInspectionCurrentProjectPhase?.find(
                  (inspection) => inspection.optionId === option.key
                );
              return {
                ...option,
                optionId: option.key,
                isChecked: matchingInspectionData
                  ? matchingInspectionData?.isChecked
                  : false,
                comment: matchingInspectionData
                  ? matchingInspectionData?.comment
                  : "",
              };
            });
          setSwInspectionCurrentProjectPhase(updatedInsQuestionOptionList);
        } else {
          const updatedOptions = getSwReference.data.InsQuestionOptionList?.map(
            (option) => ({
              ...option,
              optionId: option.key,
              isChecked: false,
              comment: "",
            })
          );
          setSwInspectionCurrentProjectPhase(updatedOptions);
        }
        setSwInspectionGroundStabilization(
          inspectionData?.swInspectionGroundStabilization
        );
        setSwInspectionObservation(inspectionData?.swInspectionObservation);
        setSwInspectionLotDisturbance(
          inspectionData?.swInspectionLotDisturbance
        );

        const getQuestionIds = getQuestionIdsFromCategories(
          inspectionData?.categoriesView
        );
        if (getQuestionIds?.length > 0) {
          const data = {
            questionList: getQuestionIds,
            setupId: null,
            projectId: inspectionData?.projectId,
            questionSetId: inspectionData?.questionSetId,
            itemStatus: 2,
            orgId: clients?.key,
          };
          getOpenItemDetailData(data);
        }
      } else {
        setFormValue({
          ...formValue,
          rainfall: "",
          WeatherAtTimeofInspection: "",
          soilTypeId: "",
          constructionStageId: "",
          sampleTaken: "",
          projectStatus: "",
          primaryPermitteeName: "",
          disturbedAcreage: "",
          permitteOrNPDESNumber: "",
          generalComments: "",
        });
        setConstructionActivityId(null);
        setProjectStatus(null);
        setQuestionSetUpId(0);
        setInspectionId(0);
      }
    }
  }, [
    getSwInspectionDetail,
    reportDetail,
    editMode,
    addDirectInspection,
    questionSetId,
    getInTyFeByInTyList,
    clients,
  ]);

  useEffect(() => {
    if (
      getSwInspectionDetail?.status === API_STATUS.SUCCESS &&
      getSwInspectionDetail?.isLoading === false &&
      editMode &&
      reportDetail &&
      getInTyFeByInTyList?.status === API_STATUS.SUCCESS &&
      getInTyFeByInTyList?.isLoading === false
    ) {
      setFormValue({
        ...formValue,
        questionSetName: reportDetail?.questionSet,
        projectName: getSwInspectionDetail?.data?.projectName,
        projectId: getSwInspectionDetail?.data?.projectId,
        customerName: getSwInspectionDetail?.data?.clientName,
        projectNo: getSwInspectionDetail?.data?.projectNumber,
        primaryInspector: getSwInspectionDetail?.data?.inspectorName,
        dateofInspection: getSwInspectionDetail?.data?.dateOfInspection,
        timeofInspection: getSwInspectionDetail?.data?.timeOfInspection,
        rainfall: getSwInspectionDetail?.data?.rainfall
          ? formatRainfall(getSwInspectionDetail?.data?.rainfall)
          : "",
        WeatherAtTimeofInspection: {
          key: getSwInspectionDetail?.data?.weatherTypeId,
          value: getSwInspectionDetail?.data?.weather,
        },
        soilTypeId: {
          key: getSwInspectionDetail?.data?.soilTypeId,
          value: getSwInspectionDetail?.data?.soilType,
        },
        constructionStageId: getSwInspectionDetail?.data?.constructionStageId,
        sampleTaken: transformSampleTaken(
          getSwInspectionDetail?.data?.isSampleTaken
        ),
        projectStatus: transformProjectStatus(
          getSwInspectionDetail?.data?.projectStatus
        ),
        primaryPermitteeName: getSwInspectionDetail?.data?.primaryPermitteName,
        disturbedAcreage: getSwInspectionDetail?.data?.disturbedAcreage,
        permitteOrNPDESNumber:
          getSwInspectionDetail?.data?.permitteOrNPDESNumber,
        generalComments: getSwInspectionDetail?.data?.comment,
      });
      setConstructionActivityId(
        getSwInspectionDetail?.data?.constructionActivityId
      );
      setAlsoSubmitRoutineInspection(
        getSwInspectionDetail?.data?.alsoSubmitRoutineInspection
      );
      setIsDirectIns(getSwInspectionDetail?.data?.isDirectIns);
      setFrequencyInDays(getSwInspectionDetail?.data?.frequencyInDays);
      setProjectStatus(
        transformProjectStatus(getSwInspectionDetail?.data?.projectStatus)
      );
      setSelectedContractorSignature(getSwInspectionDetail?.data?.signature);

      const categoryData = getSwInspectionDetail?.data?.categoriesView;
      const transformedCategoryData = transformCategoryData(categoryData);

      setListInspectionCategory(transformedCategoryData);

      setSwInspectionConfQuestionAns(
        getSwInspectionDetail?.data?.swInspectionConfQuestionAns
      );

      setSwInspectionCurrentProjectPhase(
        getSwInspectionDetail?.data?.swInspectionCurrentProjectPhase
      );
      setSwInspectionGroundStabilization(
        getSwInspectionDetail?.data?.swInspectionGroundStabilization
      );
      setSwInspectionObservation(
        getSwInspectionDetail?.data?.swInspectionObservation
      );
      setSwInspectionLotDisturbance(
        getSwInspectionDetail?.data?.swInspectionLotDisturbance
      );

      if (getSwInspectionDetail?.data?.setupId) {
        const getQuestionIds = getQuestionIdsFromCategories(
          getSwInspectionDetail?.data?.categoriesView
        );

        const data = {
          questionList: getQuestionIds,
          setupId: null,
          projectId: getSwInspectionDetail?.data?.projectId,
          questionSetId: getSwInspectionDetail?.data?.questionSetId,
          itemStatus: 2,
          orgId: clients?.key,
        };
        getOpenItemDetailData(data);
      }
    }
  }, [
    getSwInspectionDetail,
    editMode,
    reportDetail,
    getInTyFeByInTyList,
    clients,
  ]);

  const getOpenItem = (questionDetail) => {
    const findItems = Object?.keys(previousItem).reduce((result, key) => {
      const item = previousItem[key];
      if (
        item.questionId === questionDetail.key &&
        questionDetail.radioSelectiovalue === "2"
      ) {
        result[key] = item;
      }
      return result;
    }, {});
    return (
      <PreviousOpenItems
        openItemHandler={openItemHandler}
        previousItem={findItems ? findItems : []}
      />
    );
  };

  useEffect(() => {
    if (!editMode) {
      const preData = JSON.parse(JSON.stringify(previousItem));
      if (
        swOpenItemDetail.status === API_STATUS.SUCCESS &&
        getSwInspectionDetail.status === API_STATUS.SUCCESS &&
        !getSwInspectionDetail?.data?.setupId
      ) {
        const filterData = preData.filter(
          (prev) => prev.questionId !== notAcceptable
        );
        setPreviousItem(
          openItemObject([...swOpenItemDetail.data.openItemList])
        );
        const updatedList = listInspectionCategory?.map((category) => {
          const updatedSubCategories = category?.listInspectionSubCategory?.map(
            (subCategory) => {
              const updatedQuestions =
                subCategory?.listInspectionQuestionAnswer?.map((question) => {
                  const matchingOpenItem =
                    swOpenItemDetail?.data?.openItemList?.find(
                      (item) => item.questionId === question.key
                    );
                  if (matchingOpenItem) {
                    question.radioSelectiovalue = "2";
                  } else {
                    question.radioSelectiovalue = "";
                  }
                  return question;
                });
              return {
                ...subCategory,
                listInspectionQuestionAnswer: updatedQuestions,
              };
            }
          );
          return {
            ...category,
            listInspectionSubCategory: updatedSubCategories,
          };
        });
      }
    }
  }, [swOpenItemDetail, editMode, getSwInspectionDetail]);

  useEffect(() => {
    if (getSwInspectionDetail.status === API_STATUS.SUCCESS) {
      if (
        (addMode || addDirectInspection) &&
        getSwQuestionSetDetail.status === API_STATUS.SUCCESS &&
        questionSetId &&
        !getSwInspectionDetail?.data?.setupId &&
        (reportDetail?.projectId || projects)
      ) {
        const getQuestionIds =
          getSwQuestionSetDetail?.data?.questionSetCatDetails
            ?.map((x) =>
              x?.subcategories?.flatMap((y) =>
                y?.questions?.filter((z) => z.isSelected).map((xz) => xz.key)
              )
            )
            .flat()
            .filter((id) => id !== undefined);

        const data = {
          questionList: getQuestionIds,
          setupId: null,
          projectId: reportDetail?.projectId
            ? reportDetail?.projectId
            : projects?.key,
          questionSetId: questionSetId,
          itemStatus: 2,
          orgId: clients?.key,
        };
        getOpenItemDetailData(data);
      }
    }
  }, [
    getSwInspectionDetail,
    getSwQuestionSetDetail,
    reportDetail,
    addMode,
    questionSetId,
    addDirectInspection,
    clients,
  ]);

  useEffect(() => {
    if (
      editMode &&
      getSwInspectionDetail.status === API_STATUS.SUCCESS &&
      questionSetUpId &&
      projects?.key
    ) {
      const getQuestionIds = getSwInspectionDetail?.data?.categoriesView
        ?.map((x) =>
          x?.subcategoriesView?.flatMap((y) =>
            y?.insQuesAnsReportView?.map((z) => z.questionId)
          )
        )
        .flat()
        .filter((id) => id !== undefined);

      const data = {
        questionList: getQuestionIds,
        setupId: null,
        projectId: projects?.key,
        questionSetId: questionSetId,
        itemStatus: 2,
        orgId: clients?.key,
      };
      getOpenItemDetailData(data);
    }
  }, [
    getSwInspectionDetail,
    editMode,
    projects?.key,
    questionSetUpId,
    clients,
  ]);

  const addMediaNotAccUploadFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileError(validation.errorMessage);
      setUniqeFileError(1);
      return;
    }
    setIsNotAddMedia(true);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
        setUniqeFileError(2);
      })
      .catch((error) => {
        setFileError("Error while uploading files.");
        setUniqeFileError(1);
      });
  };

  const [notCategoryId, setNotCategoryId] = useState();
  const [notSubcategory, setNotSubcategory] = useState();
  const [notNotQuestionId, setNotQuestionId] = useState();
  const [notNotAppce, setNotNotAppce] = useState();
  const [actions, setActions] = useState(null);

  const addMediaNotAccUpload = (
    event,
    question,
    subcategory,
    category,
    notAppce,
    noteee
  ) => {
    setNotCategoryId(category?.key);
    setNotSubcategory(subcategory?.key);
    setNotQuestionId(question?.key);
    setNotNotAppce(notAppce?.id);
    setActions(noteee?.id);

    const fileUploaded = event.target.files[0];
    handleaddMediaNotAccUploadMethod(fileUploaded);
  };

  const handleaddMediaNotAccUploadMethod = (data) => {
    addMediaNotAccUploadFile(data);
  };

  useEffect(() => {
    if (isNotAddMedia && fileUploadData) {
      const listInspectionCategoryClone = [...listInspectionCategory];
      if (fileUploadData && !imageCheck) {
        listInspectionCategoryClone.forEach((category) => {
          if (category?.key === notCategoryId) {
            category.listInspectionSubCategory.forEach((subCategory) => {
              if (subCategory?.key === notSubcategory) {
                subCategory.listInspectionQuestionAnswer.forEach((question) => {
                  if (question?.key == notNotQuestionId) {
                    question.notAcceptableList.forEach((notAcc) => {
                      if (notAcc.id === notNotAppce) {
                        notAcc.addLocatioList.forEach((actio) => {
                          if (actio.id === actions) {
                            actio.imgUrl = fileUploadData?.files[0]?.fileUrl;
                            actio.fileId = fileUploadData?.files[0]?.id;
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }

      setListInspectionCategory(listInspectionCategoryClone);
    }
  }, [isNotAddMedia, fileUploadData]);

  useEffect(() => {
    if (uniqeFileError === 1) {
      const listInspectionCategoryClone = [...listInspectionCategory];
      listInspectionCategoryClone.forEach((category) => {
        if (category?.key === notCategoryId) {
          category.listInspectionSubCategory.forEach((subCategory) => {
            if (subCategory?.key === notSubcategory) {
              subCategory.listInspectionQuestionAnswer.forEach((question) => {
                if (question?.key == notNotQuestionId) {
                  question.notAcceptableList.forEach((notAcc) => {
                    if (notAcc.id === notNotAppce) {
                      notAcc.addLocatioList.forEach((actio) => {
                        if (actio.id === actions) {
                          actio.uniqeFileErrorMessage = true;
                        } else {
                          actio.uniqeFileErrorMessage = false;
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });

      setListInspectionCategory(listInspectionCategoryClone);
    }
  }, [uniqeFileError]);

  useEffect(() => {
    if (!mapId && getSwProjectDetailData) {
      setFormValue({
        ...formValue,
        primaryInspector: `${userInfo?.firstName} ${userInfo?.lastName}`,
        projectNo: getSwProjectDetailData?.data?.result?.projectNumber,
        isStandalone: getSwProjectDetailData?.data?.result?.isStandalone,
      });
    }
  }, [getSwProjectDetailData]);

  useEffect(() => {
    if (projects?.key) {
      const data = {
        projectId: projects?.key,
        orgId: clients?.key,
      };
      getProjectDetails(data);
    }
  }, [projects, clients]);

  useEffect(() => {
    if (inspectionQuestionSetList?.data) {
      setIsGeorgiaState(inspectionQuestionSetList?.data?.isGeorgiaState);
    }
  }, [inspectionQuestionSetList]);

  useEffect(() => {
    if (statusApi && !addDirectInspection) {
      toast("something went wrong please try again");
      setTimeout(function () {
        navigate(SWDASHBOARD);
      }, 3000);
    }
    if (statusApi && addDirectInspection) {
      toast("something went wrong please try again");
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  }, [statusApi, addDirectInspection]);

  useEffect(() => {
    if (reportDetail) {
      setClients({
        key: reportDetail?.customerId,
        value: reportDetail?.customer,
      });
    }
  }, [reportDetail]);

  const [showSuccessScreen, setShowSuccessScreen] = useState(true);

  useEffect(() => {
    if (
      createSwInspection.status === "SUCCESS" &&
      createSwInspection?.data?.responseMessages?.responseCode === "HBNG002"
    ) {
      toast(createSwInspection?.data?.responseMessages?.responseMessage);
      setShowSuccessScreen(false);
    }
  }, [createSwInspection]);

  useEffect(() => {
    if (inspectionHeaderDetails?.data?.stateId === 23) {
      setIsNorthCarolina(true);
    } else {
      setIsNorthCarolina(false);
    }
  }, [inspectionHeaderDetails]);

  useEffect(() => {
    if (isNorthCarolina && (projects || reportDetail?.projectId)) {
      const inspectionDate = formValue?.dateofInspection
        ? new Date(formValue?.dateofInspection)
        : new Date();
      const fromDate = new Date(inspectionDate);
      fromDate.setDate(inspectionDate?.getDate() - 7);
      const formattedFromDate = fromDate?.toISOString()?.split("T")[0];

      const defaultData = {
        page: 1,
        pageSize: 7,
        rainfallType: 1,
        projectId: projects?.key || reportDetail?.projectId,
        fromDate: getFormatedDate(formattedFromDate),
        toDate: getFormatedDate(inspectionDate?.toISOString()?.split("T")[0]),
        search: "",
        OrgId: parseInt(ORG_ID()),
      };

      swGetRainFallDataList(defaultData);
    }
  }, [isNorthCarolina, projects, formValue.dateofInspection]);

  useEffect(() => {
    if (
      isNorthCarolina &&
      swRainFallData?.status === API_STATUS?.SUCCESS &&
      swRainFallData?.data?.historyPrecipList &&
      !editMode
    ) {
      if (swRainFallData?.data?.historyPrecipList) {
        const updatedHistoryPrecipList =
          swRainFallData?.data?.historyPrecipList?.map((option) => {
            const matchingInspectionData =
              getSwInspectionDetail?.data?.swInspectionRainfallData?.find(
                (inspection) => inspection.day == option.precipDateFormat
              );

            return {
              ...option,
              day: option?.precipDateFormat,
              rainfallAmt: option?.evenTrackPrecipAmt,
              notes: matchingInspectionData ? matchingInspectionData.notes : "",
            };
          });
        setSwInspectionRainfallData(updatedHistoryPrecipList);
      } else {
        const updatedOptions = swRainFallData?.data?.historyPrecipList?.map(
          (option) => ({
            ...option,
            day: option?.precipDateFormat,
            rainfallAmt: option?.evenTrackPrecipAmt,
          })
        );
        setSwInspectionRainfallData(updatedOptions);
      }
    } else if (
      isNorthCarolina &&
      getSwInspectionDetail?.status === API_STATUS.SUCCESS &&
      getSwInspectionDetail?.data &&
      swRainFallData.status === API_STATUS.SUCCESS &&
      swRainFallData?.data?.historyPrecipList &&
      editMode
    ) {
      const updatedHistoryPrecipList =
        swRainFallData?.data?.historyPrecipList?.map((option) => {
          const matchingInspectionData =
            getSwInspectionDetail?.data?.swInspectionRainfallData?.find(
              (inspection) => inspection.day === option.precipDateFormat
            );
          return {
            ...option,
            day: option?.precipDateFormat,
            rainfallAmt: option?.evenTrackPrecipAmt,
            notes: matchingInspectionData ? matchingInspectionData.notes : "",
          };
        });
      setSwInspectionRainfallData(updatedHistoryPrecipList);
    }
  }, [
    isNorthCarolina,
    getSwInspectionDetail?.data,
    swRainFallData?.data?.historyPrecipList,
    editMode,
  ]);

  useEffect(() => {
    if (
      isNorthCarolina &&
      getSwReference?.data?.InsQuestionOptionList &&
      !editMode
    ) {
      if (getSwInspectionDetail?.data?.swInspectionCurrentProjectPhase) {
        const updatedInsQuestionOptionList =
          getSwReference?.data?.InsQuestionOptionList?.map((option) => {
            const matchingInspectionData =
              getSwInspectionDetail?.data?.swInspectionCurrentProjectPhase?.find(
                (inspection) => inspection.optionId === option.key
              );
            return {
              ...option,
              optionId: option.key,
              isChecked: matchingInspectionData
                ? matchingInspectionData?.isChecked
                : false,
              comment: matchingInspectionData
                ? matchingInspectionData?.comment
                : "",
            };
          });
        setSwInspectionCurrentProjectPhase(updatedInsQuestionOptionList);
      } else {
        const updatedOptions = getSwReference.data.InsQuestionOptionList?.map(
          (option) => ({
            ...option,
            optionId: option.key,
            isChecked: false,
            comment: "",
          })
        );
        setSwInspectionCurrentProjectPhase(updatedOptions);
      }
    } else if (
      isNorthCarolina &&
      getSwReference?.data?.InsQuestionOptionList &&
      getSwInspectionDetail?.data &&
      editMode
    ) {
      const updatedInsQuestionOptionList =
        getSwReference?.data?.InsQuestionOptionList?.map((option) => {
          const matchingInspectionData =
            getSwInspectionDetail?.data?.swInspectionCurrentProjectPhase?.find(
              (inspection) => inspection.optionId === option.key
            );
          return {
            ...option,
            optionId: option.key,
            isChecked: matchingInspectionData
              ? matchingInspectionData?.isChecked
              : false,
            comment: matchingInspectionData
              ? matchingInspectionData?.comment
              : "",
          };
        });
      setSwInspectionCurrentProjectPhase(updatedInsQuestionOptionList);
    }
  }, [
    isNorthCarolina,
    getSwReference?.data,
    getSwInspectionDetail?.data,
    editMode,
  ]);

  const disabledCheck = () => {
    if (editMode) {
      const frequencyId = getSwInspectionDetail?.data?.frequencyId;
      if (frequencyId === null || frequencyId === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {checkPrivileges([12, 102, 103]) ? (
        <>
          {(showLoader || isLoader) && <Loader />}

          {createSwInspection.status === "SUCCESS" &&
          createSwInspection.data &&
          createSwInspection &&
          createSwInspection?.data?.responseMessages?.responseCode !==
            "HBNG002" &&
          successScreens ? (
            successScreen(
              createSwInspection?.data?.inspectionId,
              "Inspection Added Successfully",
              "Inspection Id",
              "Inspection List in",
              " "
            )
          ) : updateSwInspection.status === "SUCCESS" &&
            updateSwInspection.data &&
            clearCreateSwInspection &&
            createSwInspection?.data?.responseMessages?.responseCode !==
              "HBNG002" &&
            successScreens ? (
            successScreen(
              updateSwInspection?.data?.inspectionId,
              "Inspection Updated Successfully",
              " ",
              "Inspection List in",
              "has been Updated"
            )
          ) : (
            <>
              <AddReport
                userInfo={userInfo}
                questionSetId={questionSetId}
                swQuestionSetDetail={listInspectionCategory}
                getInTyFeByInTyList={
                  getInTyFeByInTyList?.data?.listInspectionTypeFields
                }
                handleRadioType={handleRadioType}
                formchangeHnadle={formchangeHnadle}
                formValue={formValue}
                setFormValue={setFormValue}
                submitData={submitData}
                isSubmit={isSubmit}
                deleteform={deleteform}
                saveData={saveData}
                selectedContractorSignature={selectedContractorSignature}
                contractorsignatureUpload={contractorsignatureUpload}
                inspectionDetail={getSwInspectionDetail?.data ?? []}
                editMode={editMode}
                reportId={reportDetail?.reportId}
                deleteHandler={deleteHandler}
                isWeatherAtTimeofInspection={isWeatherAtTimeofInspection}
                isSoilTypeId={isSoilTypeId}
                isStageOfConstruction={isStageOfConstruction}
                isSampleTaken={isSampleTaken}
                isCurrentApproximateDisturbedAcreage={
                  isCurrentApproximateDisturbedAcreage
                }
                previousItem={previousItem}
                getOpenItem={getOpenItem}
                openItemHandler={openItemHandler}
                getSwReference={getSwReference?.data ?? []}
                signatureError={signatureError}
                isSignatureProfile={isSignatureProfile}
                hiddenAttendeeSignatureInput={hiddenAttendeeSignatureInput}
                addMediaNotAccUpload={addMediaNotAccUpload}
                setImageCheck={setImageCheck}
                listPermitte={listPermitte}
                isProjectName={isProjectName}
                isCustomerName={isCustomerName}
                isProjectNo={isProjectNo}
                isPrimaryInspector={isPrimaryInspector}
                isDateofInspection={isDateofInspection}
                isTimeofInspection={isTimeofInspection}
                mapId={mapId}
                handleAllProjectSearch={handleAllProjectSearch}
                isProjectLoading={isProjectLoading}
                allProjects={allProject?.data}
                projects={projects}
                onChangeProjects={onChangeProjects}
                inspectionQuestionSetList={
                  isQuestionSetLoading
                    ? []
                    : inspectionQuestionSetList?.data?.result
                }
                inspectionTypeId={inspectionTypeId}
                handleInspectionTypeSetChange={handleInspectionTypeSetChange}
                handleQuestionSetChange={handleQuestionSetChange}
                allClients={allClients?.data}
                clients={clients}
                searchClient={searchClient}
                onChangeClients={onChangeClients}
                isSearchClientLoading={isSearchClientLoading}
                firstClientError={firstClientError}
                inspectionHeaderDetails={inspectionHeaderDetails?.data}
                constructionActivityId={constructionActivityId}
                handleConstructionActivitySetChange={
                  handleConstructionActivitySetChange
                }
                isConstructionActivityId={isConstructionActivityId}
                frequencyValue={frequencyValue}
                handleFrequencyChange={handleFrequencyChange}
                isMediaAllowed={isMediaAllowed}
                isGeorgiaState={isGeorgiaState}
                projectStatus={projectStatus}
                setProjectStatus={setProjectStatus}
                alsoSubmitRoutineInspection={alsoSubmitRoutineInspection}
                handleChangeDefault={handleChangeDefault}
                characterLimit={characterLimit}
                isRainFallError={isRainFallError}
                isDirectIns={isDirectIns}
                isFluid={isFluid}
                frequencyInDays={frequencyInDays}
                dateRangeHandler={dateRangeHandler}
                dateRangeError={dateRangeError}
                errorFrequencyInDays={errorFrequencyInDays}
                fileError={fileError}
                signaureFileError={signaureFileError}
                signaureFileErrorMessage={signaureFileErrorMessage}
                isNorthCarolina={isNorthCarolina}
                swInspectionConfQuestionAns={swInspectionConfQuestionAns}
                setSwInspectionConfQuestionAns={setSwInspectionConfQuestionAns}
                swRainFallData={swInspectionRainfallData}
                swInspectionRainfallData={swInspectionRainfallData}
                setSwInspectionRainfallData={setSwInspectionRainfallData}
                swInspectionCurrentProjectPhase={
                  swInspectionCurrentProjectPhase
                }
                setSwInspectionCurrentProjectPhase={
                  setSwInspectionCurrentProjectPhase
                }
                swInspectionGroundStabilization={
                  swInspectionGroundStabilization
                }
                setSwInspectionGroundStabilization={
                  setSwInspectionGroundStabilization
                }
                swInspectionObservation={swInspectionObservation}
                setSwInspectionObservation={setSwInspectionObservation}
                swInspectionLotDisturbance={swInspectionLotDisturbance}
                setSwInspectionLotDisturbance={setSwInspectionLotDisturbance}
                formchangeThreeHnadle={formchangeThreeHnadle}
                frequencyValueError={frequencyValueError}
                disabledCheck={disabledCheck}
                weather={weather}
              />
              <ToastContainer />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default AddReportContainer;
