import React, { useEffect, useState } from "react";
import CreateDivision from "../component/divisions/createDivision";
import SuccessScreen from "../../../library/custom/success/successScreen";
import * as divisionsAction from "../../../redux/actions/divisionsAction";
import { useDispatch, useSelector } from "react-redux";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import * as userAction from "../../../redux/actions/userAction";
import Loader from "../../../library/common/Loader";
import { ACCESS_DENIED, ADMIN_DIVISION } from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

const AddDivisionsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = ADMIN_DIVISION;

  const location = useLocation();
  const divisionIdGet = location?.state?.divisionId;
  const editMode = location?.state?.editMode;

  const divisionId = divisionIdGet;

  const viewDivisionsData = useSelector((state) => state?.viewDivisions.data);

  const createDivision = useSelector((state) => state.createDivisions);
  const updateDivision = useSelector((state) => state.updateDivisions);
  const stateData = useSelector((state) => state.state);

  const [successScreens, setSuccessScreens] = useState(false);
  const [divisionExistError, setDivisionExistError] = useState(false);

  const allUsers = useSelector((state) => state.allUsers);
  const isUserLoading = allUsers?.isLoading;

  function handleAllUserSearch(event, value) {
    dispatch(userAction.searchAllUsers(event.target.value, 0, 0, 0, 0, 139));
  }

  const showLoader = useSelector(
    (state) =>
      state.createDivisions?.isLoading ||
      state.updateDivisions?.isLoading ||
      state.viewDivisions?.isLoading ||
      state.state?.isLoading
  );

  const addAndUpdateDivisions = async (data) => {
    setSuccessScreens(true);
    if (data?.data?.divisionId === 0) {
      dispatch(divisionsAction.createDivisions(data));
    } else {
      dispatch(divisionsAction.updateDivisions(data));
    }
  };

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  useEffect(() => {
    getStateData();
  }, []);

  useEffect(() => {
    if (
      createDivision?.status === "SUCCESS" &&
      createDivision?.isLoading === false &&
      createDivision?.data?.responseMessages?.responseCode === "HBNG012"
    ) {
      setDivisionExistError(true);
      setSuccessScreens(false);
    } else {
      setDivisionExistError(false);
      setSuccessScreens(true);
    }
  }, [createDivision]);

  useEffect(() => {
    if (
      updateDivision?.status === "SUCCESS" &&
      updateDivision?.isLoading === false &&
      updateDivision?.data?.responseMessages?.responseCode === "HBNG012"
    ) {
      setDivisionExistError(true);
      setSuccessScreens(false);
    } else {
      setDivisionExistError(false);
      setSuccessScreens(true);
    }
  }, [updateDivision]);

  useEffect(() => {
    dispatch(divisionsAction.clearDivisions());
    if (divisionId) {
      dispatch(divisionsAction.getDivisionsDetails(divisionId));
    }
  }, [dispatch]);

  const successScreen = (id, message, message1) => {
    return (
      <SuccessScreen
        route={route}
        id={id}
        isUpdate={message}
        label1="Division ID"
        label4="Division in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  return (
    <>
      {checkPrivileges([12, 49, 50]) ? (
        <>
          {showLoader && <Loader />}

          {createDivision.status === "SUCCESS" &&
          createDivision.data &&
          createDivision?.data?.responseMessages?.responseCode === "HBNG001" &&
          successScreens ? (
            successScreen(
              createDivision?.data?.id,
              "Division Created Successfully"
            )
          ) : updateDivision.status === "SUCCESS" &&
            updateDivision?.data &&
            updateDivision?.data?.responseMessages?.responseCode ===
              "HBNG001" &&
            successScreens ? (
            successScreen(
              updateDivision?.data?.id,
              "Division Updated Successfully",
              "has been Updated"
            )
          ) : (
            <>
              <CreateDivision
                state={stateData && stateData.data?.getState}
                onSubmit={addAndUpdateDivisions}
                viewDivisionsData={editMode ? viewDivisionsData : ""}
                editMode={editMode}
                divisionId={divisionId}
                allUsers={allUsers?.data}
                handleAllUserSearch={handleAllUserSearch}
                isUserLoading={isUserLoading}
                divisionExistError={divisionExistError}
                setDivisionExistError={setDivisionExistError}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </>
  );
};

export default AddDivisionsContainer;
