import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/addCaseList.scss";
import StolenPropertyForm from "../components/stolenPropertyForm";
import Carousel from "../../../library/custom/carouselBox/Carousel";
import CarouselData from "../../../constant/carouselConstant";
import * as incidentAction from "../../../redux/actions/incidentAction";
import * as stolenPropertyAction from "../../../redux/actions/stolenPropertyAction";
import * as userAction from "../../../redux/actions/userAction";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import Loader from "../../../library/common/Loader";
import {
  API_STATUS,
  INCIDENT_REPORT_ADDED,
  INCIDENT_REPORT_UPDATE,
} from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";

import {
  PERSONAL_INJURY,
  VEHICLE_ACCIDENT,
  UTILITY_DAMAGE,
  STOLEN_PROPERTY,
  NEAR_MISS,
  DASHBOARD,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import SuccessScreen from "../../incidents/add-incident/component/successScreen";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { logEvent, validateFileSize } from "../../../utils/helper";
import { fileUpload } from "../../../services/fileUploadService";

export default function StolenPropertyContainer({ props }) {
  const location = useLocation();
  const existingCaseId = location?.state?.caseId;
  const incidentId = location?.state?.incidentId;
  const editMode = location?.state?.editMode;
  const [successScreens, setSuccessScreens] = useState(false);
  let navigate = useNavigate();
  const disptch = useDispatch();
  const incident = useSelector((state) => state.incident.data);
  const status = useSelector((state) => state.addStolenProperty.status);
  const updateStolenPropertyDataStatus = useSelector(
    (state) => state.updateStolenPropertyData.status
  );
  const updateStolenPropertyData = useSelector(
    (state) => state.updateStolenPropertyData.data
  );

  const addStolenPropertyData = useSelector(
    (state) => state.addStolenProperty.data
  );
  const isLoading = useSelector((state) => state.users.isLoading);

  const lossPropertyReport = useSelector(
    (state) => state.getLossPropertyReport.data
  );

  const lossPropertyReportStatus = useSelector(
    (state) => state.getLossPropertyReport
  );

  const allUsers = useSelector((state) => state.allUsers.data);

  const searchAllUsers = (searchType, searchText, projectId = 0) => {
    disptch(
      userAction.searchAllUsers(searchText, searchType, 0, projectId, 1, 0)
    );
  };
  const caseData = useSelector((state) => state.createdCase.data);
  const searchOption = useSelector((state) => state.users.data);
  const searchOptionssss = useSelector((state) => state.users);
  const assetOnUserList = useSelector((state) => state.getAssetOnUser.data);
  const supervisorOnAssetList = useSelector(
    (state) => state.getSupervisorOnAsset.data
  );

  const showLoader = useSelector(
    (state) =>
      state.incident.isLoading ||
      state.createdCase.isLoading ||
      state.addStolenProperty.isLoading ||
      state.updateStolenPropertyData.isLoading ||
      state.fileUpload.isLoading ||
      state.getLossPropertyReport.isLoading
  );

  const isSuccess = useSelector(
    (state) =>
      state.incident.status === API_STATUS.SUCCESS &&
      (existingCaseId
        ? existingCaseId
        : state.createdCase.status === API_STATUS.SUCCESS)
  );
  const isAllowed = checkPrivileges([1, 2, 3, 12]);
  const [dataId, setDataId] = useState("");
  const addAndUpdateStolenProperty = async (data) => {
    setDataId(data.stolenProperty.incidentId);
    setSuccessScreens(true);

    if (data.stolenProperty.incidentId === 0) {
      disptch(stolenPropertyAction.stolenProperty(data.stolenProperty));
    } else {
      disptch(stolenPropertyAction.updateStolenProperty(data.stolenProperty));
    }
  };

  const [fileError, setFileError] = useState(null);
  const [countError, setCountError] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [fileUploadData, setFileUploadData] = useState();

  const uploadFile = async (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileError(validation.errorMessage);
      setCountError(countError + 1);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
      })
      .catch((error) => {
        setCountError(countError + 1);
        setFileError("Error while uploading files.");
      });
  };

  const getIncidentData = () => {
    disptch(incidentAction.getIncidentData());
  };
  const searchUsers = (searchType, searchText) => {
    disptch(userAction.searchUsers(searchType, searchText));
  };
  const getStolenReport = (caseId, incidentId) => {
    disptch(stolenPropertyAction.getStolenProperty(caseId, incidentId));
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }

    disptch(stolenPropertyAction.clearStolenProperty());

    if (location.state === "Vehicle Accident") {
      const value = {
        id: 2,
        title: "Vehicle Accident",
      };
      setcarouselValue(value);
    }
    if (existingCaseId && incidentId) {
      getStolenReport(existingCaseId, incidentId);
      getIncidentData();
    }
    // if (!existingCaseId) {
    //   createCase();
    // }
    if (!incidentId) {
      getIncidentData();
    }
  }, []);

  const [carouselValue, setcarouselValue] = useState({
    id: 3,
    title: "Stolen Property",
  });

  const handleOnChangeMethod = (value) => {
    if (!editMode) {
      setcarouselValue(value);
      if (value.id === 1) {
        navigate(PERSONAL_INJURY, {
          state: { caseId: existingCaseId, isDirect: true },
        });
      } else if (value.id === 2) {
        navigate(VEHICLE_ACCIDENT, { state: { caseId: existingCaseId } });
      } else if (value.id === 4) {
        navigate(UTILITY_DAMAGE, { state: { caseId: existingCaseId } });
      } else if (value.id === 3) {
        navigate(STOLEN_PROPERTY, { state: { caseId: existingCaseId } });
      } else if (value.id === 5) {
        navigate(NEAR_MISS, { state: { caseId: existingCaseId } });
      }
    }

    if (value.id === 2) {
      disptch(stateCityAction.getState());
    }
  };

  const successScree = (id) => {
    logEvent("Incident",editMode? "Update Stolen Property" : "Add Stolen Property",)
    return (
      <SuccessScreen
        id={id}
        caseId={existingCaseId}
        isUpdate={dataId === 0 ? INCIDENT_REPORT_ADDED : INCIDENT_REPORT_UPDATE}
      ></SuccessScreen>
    );
  };
  function getAssetOnUser(params) {
    console.log(params);
    const data = {
      assetType: 1,
      userId: params.userId,
    };
    disptch(userAction.getAssetOnUser(data));
  }
  function getSupervisorOnAsset(params) {
    const data = {
      assetType: 1,
      assetId: params,
    };
    disptch(userAction.getSupervisorOnAsset(data));
  }
  return (
    <>
      {checkPrivileges([1, 2, 12]) ? (
        <>
          {status === API_STATUS.SUCCESS &&
          addStolenPropertyData &&
          successScreens ? (
            successScree(addStolenPropertyData?.incidentId)
          ) : updateStolenPropertyDataStatus === API_STATUS.SUCCESS &&
            updateStolenPropertyData &&
            successScreens ? (
            successScree(incidentId)
          ) : (
            <div className="dashboard-slider">
              <div>
                <div className="dashbord_heading">
                  <div className="insi-stitle">
                    <p>Select Incident Type</p>
                    <div className="caseId">
                      Case ID: EVE-{" "}
                      {existingCaseId ? existingCaseId : caseData?.caseId}
                    </div>
                  </div>
                </div>

                <Carousel
                  sliderItems={CarouselData}
                  activeSlide={carouselValue}
                  handleOnChange={handleOnChangeMethod}
                  edit={editMode ? true : false}
                />
              </div>
              <div className="loader-wrapper">
                {(showLoader || isLoader) && <Loader />}
              </div>
              <div>
                {isSuccess && (
                  <StolenPropertyForm
                    incident={incident}
                    caseId={existingCaseId ? existingCaseId : caseData?.caseId}
                    lossPropertyData={incidentId ? lossPropertyReport : null}
                    incidentId={incidentId ? incidentId : 0}
                    caseData={caseData}
                    onSubmit={addAndUpdateStolenProperty}
                    searchOption={searchOption}
                    editMode={editMode}
                    uploadFile={uploadFile}
                    fileUploadData={fileUploadData}
                    searchUsers={searchUsers}
                    searchAllUsers={searchAllUsers}
                    allUsers={allUsers}
                    isLoading={isLoading}
                    existingCaseId={existingCaseId}
                    searchOptionssss={searchOptionssss}
                    lossPropertyDataStatus={lossPropertyReportStatus}
                    getAssetOnUser={getAssetOnUser}
                    assetOnUserList={assetOnUserList?.jobSite ?? []}
                    getSupervisorOnAsset={getSupervisorOnAsset}
                    supervisorOnAssetList={supervisorOnAssetList?.result ?? []}
                    fileError={fileError}
                    countError={countError}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </>
  );
}
