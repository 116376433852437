import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../library/custom/button/Button";
import FilterButton from "../../../library/custom/filterButton/filterButton";
import FilterList from "../shared/FilterCaseList";
import SettingTable from "../shared/SettingTable";
import ReportRowManipulte from "../shared/ReportRowManipulte";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import { CASESSTATUS, PAGE_SIZE } from "../../../constant/constant";
import { caseFormatDate } from "../../../utils/helper";
import { PERSONAL_INJURY } from "../../../constant/routeContant";
import DialogBox from "../../../library/common/DialogBox";
import DialogBoxOk from "../../../library/common/DialogBoxOk";
import { checkPrivileges } from "../../../utils/rolesHelper";
import downloadIcon from "../../../assets/downloadIcon.svg";

const CaseList = ({
  createCase,
  isFullAccess,
  isWriteAccess,
  isReadAccess,
  casesData,
  isLoading,
  getCasesListData,
  handleViewOnClick,
  handleEditOnClick,
  deleteCase,
  incedentPending,
  setIncedentPending,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateOfCaseFrom, setdateOfCaseFrom] = useState("");
  const [dateOfCaseTo, setdateOfCaseTo] = useState("");
  const [page, setPage] = useState(1);
  const [casesFilterData, setCasesFilterData] = useState({});
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [isTableLoading, setisTableLoading] = useState(false);

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getCasesListData({
      ...casesFilterData,
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      date_from: dateOfCaseFrom ? caseFormatDate(dateOfCaseFrom) : "",
      date_to: dateOfCaseTo ? caseFormatDate(dateOfCaseTo) : "",
      search: "",
      isSendMail: false,
    });
  };

  const handleautoSearch = (event) => {
    setSearch(event.target.value);
    const data = {
      ...casesFilterData,
      page: 1,
      pageSize: PAGE_SIZE,
      date_from: dateOfCaseFrom ? caseFormatDate(dateOfCaseFrom) : "",
      date_to: dateOfCaseTo ? caseFormatDate(dateOfCaseTo) : "",
      search: event.target.value,
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);

    setCasesFilterData(data);
    getCasesListData(data);
  };

  const [caseStatus, setCaseStatus] = useState("");

  const handleApply = () => {
    setAnchorEl(null);
    window.close();
    const fromdate = caseFormatDate(dateOfCaseFrom);
    const todate = caseFormatDate(dateOfCaseTo);
    const date_from = fromdate;
    const date_to = todate;
    setPage(1);
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: search,
      date_from: dateOfCaseFrom ? date_from : "",
      date_to: dateOfCaseTo ? date_to : "",
      statusIds: parseInt(caseStatus) === 3 ? 0 : caseStatus,
      isSendMail: false,
    };

    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);

    setCasesFilterData(data);
    getCasesListData(data);
  };

  const handleClear = () => {
    setAnchorEl(null);
    dateOfCaseHandlerTO("");
    setCaseStatus("");
    setSearch("");
    setdateOfCaseFrom("");
    setdateOfCaseTo("");
    setPage(1);
    const data = {
      page: 1,
      search: "",
      date_from: "",
      date_to: "",
      statusIds: "",
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setCasesFilterData(data);
    getCasesListData(data);
  };

  const dateOfCaseHandlerFrom = (e) => {
    setdateOfCaseFrom(e);
  };

  const dateOfCaseHandlerTO = (e) => {
    setdateOfCaseTo(e);
  };
  const addIncidentClick = (row) => {
    navigate(PERSONAL_INJURY, {
      state: {
        caseId: row?.row?.caseId,
        editMode: false,
        isDirect: true,
      },
    });
  };

  const deletehandle = () => {
    setOpenModal(false);
    deleteCase(selectedRow);
  };

  const deleteCaseHandler = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const columns = [
    { field: "caseId", headerName: "Case ID", flex: 1 },
    { field: "dateOfCase", headerName: "Date Of Case", flex: 1.5 },
    { field: "timeOfCase", headerName: "Time Of Case", flex: 1.5 },
    {
      field: "location",
      headerName: "Location",
      type: "text",
      flex: 1.5,
    },
    {
      field: "enteredBy",
      headerName: "Entered By",
      type: "text",
      flex: 1.7,
    },
    {
      field: "status",
      headerName: "Status",
      type: "text",
      flex: 1,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={casesData?.noOfRecords}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={casesData?.recentCases ?? []}
          fileNameXL={"Case-List"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            view={isReadAccess || isWriteAccess || isFullAccess}
            viewLabel="View Case"
            viewOnClick={() => goToForgetIncidentlist(rows)}
            edit={isWriteAccess || isFullAccess}
            editLabel="Edit Case"
            editOnClick={() => handleEdit(rows)}
            addIncident={checkPrivileges([1, 2, 12])}
            addIncidentLabel="Add Incident"
            addIncidentClick={() => addIncidentClick(rows)}
            deleteLabel={"Delete Case"}
            deleteOnClick={() => deleteCaseHandler(rows?.row)}
            deleted={isFullAccess}
          />
        );
      },
    },
  ];

  const goToForgetIncidentlist = (params) => {
    handleViewOnClick(params.id);
  };
  const onRowClick = (rows) => {
    if (rows.value) {
      handleViewOnClick(rows?.id);
    }
  };

  const handleEdit = (params) => {
    handleEditOnClick(params.id);
  };

  const onChangeSelectUserType = (e) => {
    setCaseStatus(e.target.value);
  };

  const sendEmail = () => {
    const data = {
      ...casesFilterData,
      page: 1,
      pageSize: PAGE_SIZE,
      date_from: dateOfCaseFrom ? caseFormatDate(dateOfCaseFrom) : "",
      date_to: dateOfCaseTo ? caseFormatDate(dateOfCaseTo) : "",
      search: search,
      isSendMail: true,
    };
    getCasesListData(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Cases</div>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Case?"}
              view
            />
          )}
          {incedentPending && (
            <DialogBoxOk
              open={incedentPending}
              rows={[]}
              header={"Delete Case"}
              handleCloseModal={() => setIncedentPending(false)}
              content={
                "Unable to delete the case until all incidents have been deleted"
              }
              view
            />
          )}
          <div>
            {(isFullAccess || isWriteAccess) && (
              <Buttons
                varientContained={true}
                label={"Create New Case"}
                startIcon={<AddIcon />}
                onClick={() => createCase()}
              />
            )}
          </div>
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search Case"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div className="filterbtn">
                <FilterButton
                  label={"Filters"}
                  handleClick={(e) => handleClick(e)}
                />
                <FilterList
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  dateOfCaseFrom={dateOfCaseFrom}
                  dateOfCaseHandlerFrom={dateOfCaseHandlerFrom}
                  dateOfCaseTo={dateOfCaseTo}
                  dateOfCaseHandlerTO={dateOfCaseHandlerTO}
                  resetFilter={handleClear}
                  handleSearch={handleApply}
                  caseStatus={caseStatus}
                  caseListStatus={CASESSTATUS}
                  onChangeSelectUserType={onChangeSelectUserType}
                />
              </div>
            </div>
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>
          <div className="desc_box row_uniq">
            <div
              className="table_style_ui"
              style={{ height: 400, width: "100%" }}
            >
              {isTableLoading ? (
                ""
              ) : (
                <GridTable
                  getRowId={(r) => r.caseId}
                  rows={casesData?.recentCases ?? []}
                  rowCount={casesData?.noOfRecords ?? 0}
                  columns={columns}
                  loading={isLoading}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  onClick={(rows) => onRowClick(rows)}
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseList;
